import { WeekSuggestionsResponse, WeekSuggestionsService } from '../../domain/WeekFlow'
import ApiClient from './ApiClient'

export default class ApiWeekSuggestionsService implements WeekSuggestionsService {
  constructor(private apiClient: ApiClient) { }

  async getWeekSuggestions(): Promise<WeekSuggestionsResponse> {
    const weekResponse = await this.apiClient.getWeekSuggestions()
    return weekResponse
  }

  async getWeekSuggestionsToJourney(): Promise<WeekSuggestionsResponse> {
    const weekResponse = await this.apiClient.getWeekSuggestionsToJourney()
    return weekResponse
  }
}
