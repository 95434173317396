import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { RegularButton, FontIcon, FormInput } from 'theia-web-ds'
import { Formik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import AppMainContainerOff from '../AppMainContainerOff'
import { postEmailUnlockAction, postLoginUnlockAction } from '../../state/authentication/main/actions'
import { AppState } from '../../apps/main/store'
import ErrorModal from '../common/ErrorModal'
import { LOGIN } from '../../routes/RoutesConstants'

interface Props {
  token: string;
  postLoginUnlock: (token: string) => void;
  isSuccess: boolean;
  isFailure: boolean;
  isUnlockingLogin: boolean;
  postEmailUnlock: (email: string) => void;
  unlockErrorMessage?: string;
  resendLink?: boolean;
  isSuccessEmail: boolean;
  redirect?: boolean;
  isFailureEmail: boolean;
  isLoading: boolean;
  defaultErrorVisible: boolean;
}

interface EmailUnlockValues {
  email: string;
}

function renderEmailUnlockForm(
  {
    handleSubmit,
    values,
    handleChange,
    errors,
    touched,
    handleBlur
  }: FormikProps<EmailUnlockValues>,
  isLoading: boolean
) {
  return (
    <form onSubmit={handleSubmit} className="mx-auto w-full lg:max-w-x" noValidate>
      <div className="mb-6">
        <FormInput
          value={values.email}
          onChange={handleChange}
          errorMessage={touched.email ? errors.email : ''}
          id="email"
          onBlur={handleBlur}
          label="Digite o e-mail que você usa para acessar a Theia"
          type="email"
          name="email"
          placeholder="Digite aqui seu e-mail"
        />
        <RegularButton
          label="Solicitar novo link"
          isSubmitting={isLoading}
          disabled={values.email === ''}
          type="submit"
          extraClass="mt-8"
        />
      </div>
    </form>
  );
}

function LoginUnlock({
  token,
  postLoginUnlock,
  isSuccess,
  isFailure,
  isUnlockingLogin,
  postEmailUnlock,
  unlockErrorMessage,
  resendLink,
  isSuccessEmail,
  redirect,
  isFailureEmail,
  isLoading,
  defaultErrorVisible
}: Props) {
  const history = useHistory()
  const [emailResend, setEmailResend] = useState<string>('')

  function onPostLoginUnlock() {
    postLoginUnlock(token)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onPostLoginUnlock()
    }, 3000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    let timeoutId: any
    if (redirect || isSuccess) {
      timeoutId = setTimeout(() => {
        history.push(LOGIN)
      }, 5000)
    }
    return () => clearTimeout(timeoutId)
  }, [redirect, isSuccess])

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-mail inválido')
      .required('E-mail obrigatório')
  })

  const initialValues = {
    email: ''
  }

  const successMessage = isSuccess
    ? 'Seu login foi desbloqueado com sucesso! Agora é só acessar sua conta novamente.'
    : 'Está quase pronto! Um novo link de desbloqueio foi enviado para o seu e-mail'

  const redirectMessage = (redirect || isSuccess) && (
    <span className="ml-2 block">
      Você será redirecionado para tela de login em 5 segundos...
    </span>
  )

  return (
    <AppMainContainerOff screenTitle="Recuperação de conta">
      <div className="buttons-content">
        {isUnlockingLogin && (
          <p className="mb-8">
            Aguarde, sua conta está sendo desbloqueada...
          </p>
        )}
        {(isSuccess || isSuccessEmail) && (
          <>
            <div className="mb-8 flex items-start w-full">
              <FontIcon
                iconType="icon-TickRoundBold"
                color="#1C8020"
                fontSize="20px"
              />
              <p className="flex flex-col">
                <span className="ml-4 block">
                  {successMessage}
                </span>
                {redirectMessage}
              </p>
            </div>
            {isSuccessEmail && (
              <>
                <p className="mb-4">Não consegue localizar o e-mail?</p>
                <RegularButton
                  label="Reenviar e-mail"
                  onClick={() => postEmailUnlock(emailResend)}
                  variant="subtle"
                  isSubmitting={isLoading}
                  maxWidth="280px"
                />
              </>
            )}
          </>
        )}
        {(isFailure || isFailureEmail) && (
          <>
            {unlockErrorMessage && (
              <div className="mb-8 flex items-start w-full">
                <FontIcon
                  iconType={`${redirect ? 'icon-TickRoundBold' : 'icon-PaperNegativeBold'}`}
                  color={`${redirect ? '#1C8020' : '#BB3949'}`}
                  fontSize="20px"
                />
                <p className="flex flex-col">
                  <span className="ml-2 block">
                    {unlockErrorMessage}
                  </span>
                  {redirectMessage}
                </p>
              </div>
            )}
            {resendLink && (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  postEmailUnlock(values.email)
                  setEmailResend(values.email)
                }}
              >
                {(props) => renderEmailUnlockForm(props, isLoading)}
              </Formik>
            )}
            {(!resendLink && !redirect && (
              <RegularButton
                label="Tentar novamente"
                onClick={onPostLoginUnlock}
                isSubmitting={isUnlockingLogin}
              />
            ))}
          </>
        )}
      </div>
      <ErrorModal
        visible={defaultErrorVisible}
      />
    </AppMainContainerOff>
  )
}

const mapStateToProps = ({ authentication, errorWithFriendlyMessage }: AppState) => ({
  isSuccess: authentication.isSuccess,
  isFailure: authentication.isFailure,
  isUnlockingLogin: authentication.isUnlockingLogin,
  unlockErrorMessage: authentication.unlockErrorMessage,
  resendLink: authentication.resendLink,
  isSuccessEmail: authentication.isSuccessEmail,
  isFailureEmail: authentication.isFailureEmail,
  redirect: authentication.redirect,
  isLoading: authentication.isLoading,
  defaultErrorVisible: errorWithFriendlyMessage.visible
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  postLoginUnlock: postLoginUnlockAction,
  postEmailUnlock: postEmailUnlockAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginUnlock)
