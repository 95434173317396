import React, { useState } from 'react'
import { NewListItem, Divider } from 'theia-web-ds'
import { primary } from '../../color'

interface Props {
  toggleTitle: any;
  toggleDescription?: string;
  children?: React.ReactNode;
  iconType?: string;
  extraAction?: () => void;
  iconColor?: string;
  arrowColor?: string;
  extraDescriptionClass?: string;
  extraDescriptionClassContainer?: string;
  hasDivider?: boolean;
}
export function FaqToggle({
  toggleTitle,
  toggleDescription,
  children,
  extraAction,
  iconType,
  iconColor,
  arrowColor,
  extraDescriptionClass,
  extraDescriptionClassContainer,
  hasDivider = true
}: Props) {
  const [isOpen, setIsOpen] = useState(false)
  function onOpenOrCloseFaqToggle() {
    setIsOpen(!isOpen)
    if (extraAction && !isOpen) extraAction()
  }

  return (
    <div>
      {hasDivider && (
        <Divider />
      )}
      <NewListItem
        iconType={iconType}
        onClick={onOpenOrCloseFaqToggle}
        iconColor={iconColor || primary}
        arrowColor={arrowColor || primary}
        title={toggleTitle}
        ariaLabel={toggleTitle}
        isLastItem
        isOpen={isOpen}
        isOpenAndCloseButton
        isItemFromList={false}
        key="user-data-health-mobile"
        extraTitleClass="text-textSecondary"
        iconSize="24px"
      />
      {isOpen && (
        <div>
          <p className={`text-textSecondary text-fontDefault px-4 ${extraDescriptionClass}`}>{toggleDescription}</p>
          <div className={`px-4 ${extraDescriptionClassContainer}`}>{children}</div>
        </div>
      ) }
      {hasDivider && (
        <Divider className={isOpen ? 'mt-4' : ''} />
      )}
    </div>
  )
}
