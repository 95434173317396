import * as Sentry from '@sentry/browser'
import { AsyncAction } from '../../utils'
import { UserProvider } from '../../../domain/User'

export const POST_RECAPTCHA_GOOGLE = 'POST_RECAPTCHA_GOOGLE'
export const SUCCESS_POST_RECAPTCHA_GOOGLE = 'SUCCESS_POST_RECAPTCHA_GOOGLE'
export const FAILURE_POST_RECAPTCHA_GOOGLE = 'FAILURE_POST_RECAPTCHA_GOOGLE'

interface PostRecaptchaActionType {
  type: typeof POST_RECAPTCHA_GOOGLE;
}

interface SuccessPostRecaptchaActionType {
  type: typeof SUCCESS_POST_RECAPTCHA_GOOGLE;
}

interface FailurePostRecaptchaActionType {
  type: typeof FAILURE_POST_RECAPTCHA_GOOGLE;
}

export type RecaptchaTypes = PostRecaptchaActionType
| SuccessPostRecaptchaActionType
| FailurePostRecaptchaActionType

export function postRecaptchaGoogleAction(token: string):
  AsyncAction<{}, { userProvider: UserProvider }> {
  return async (dispatch, _getState, { userProvider }) => {
    dispatch({ type: POST_RECAPTCHA_GOOGLE })
    try {
      await userProvider.postRecaptchaGoogle(token)
      dispatch({ type: SUCCESS_POST_RECAPTCHA_GOOGLE })
    } catch (error) {
      dispatch({ type: FAILURE_POST_RECAPTCHA_GOOGLE })
      Sentry.captureException(new Error('Failure to recaptcha'))
    }
  }
}
