/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { RegularButton } from 'theia-web-ds';
import './NotAcceptedHealthInsurance.scss'
import NegativeFeedback from '../../../assets/Plan-no.svg'
import { AppState } from '../../apps/main/store'
import { closeModalAction } from '../../state/onboarding/actions'
import { AppDispatch } from '../../state/utils'
import { HealthPlanFaqAndBenefits } from '../profile/healthplan/HealthplanFaqAndBenefits'
import { eventPage } from '../../../eventGenerate'
import { AGENDAR, INICIO } from '../../routes/RoutesConstants';

interface Props {
    benefits: any;
    healthInsurancePlan: any;
    closeModal: () => void;
}

function NotAcceptedHealthInsurance({
  benefits,
  healthInsurancePlan,
  closeModal
}: Props) {
  const [isPaused, setIsPaused] = useState(false)
  const [animationDelay, setAnimationDelay] = useState(false)
  const [showContent, setShowContent] = useState(false)

  const history = useHistory()

  useEffect(() => {
    const timer1: NodeJS.Timeout = setTimeout(() => {
      setIsPaused(true)
    }, 2500)
    const timer2: NodeJS.Timeout = setTimeout(() => {
      setAnimationDelay(true)
    }, 2500)
    const timer3: NodeJS.Timeout = setTimeout(() => {
      setShowContent(true)
    }, 2700)
    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
      clearTimeout(timer3)
    }
  }, [])

  function goToLocationUsingHistoryPush(link: string) {
    history.push(link)
    closeModal()
  }
  useEffect(() => {
    eventPage('feedback plano', 'Convenio sem Cobertura')
  }, [])
  return (
    <div className={`flex flex-col items-center health-plan-negative ${isPaused ? 'mt-4' : ''}`}>
      <div className={animationDelay ? 'animation-up' : 'not-accepted-icon'}>
        <NegativeFeedback />
      </div>
      {showContent && (
        <div className="show-content">
          <div className="mx-4">
            <p className="text-textMedium text-center font-normal justify-center">
              {healthInsurancePlan.company === 'Porto Seguro Saúde' && (
                `Ahh! Ainda não aceitamos o seu plano ${healthInsurancePlan.planName} da ${healthInsurancePlan.company}`
              )}
              {healthInsurancePlan.company === 'Fundação Saúde Itaú' && (
                `Ahh! Ainda não aceitamos o seu plano ${healthInsurancePlan.planName} da ${healthInsurancePlan.company}`
              )}
              {healthInsurancePlan.company !== 'Porto Seguro Saúde' && healthInsurancePlan.company !== 'Fundação Saúde Itaú' && (
                `Ahh! Ainda não aceitamos o seu plano ${healthInsurancePlan.company}`
              )}
            </p>
            <p className="text-fontDefault text-textSecondary text-center mt-4">
              Você pode continuar agendando uma consulta particular.
            </p>
            <RegularButton
              label="Agendar agora"
              extraClass="mt-6 mx-auto button-healthplan-feedback"
              onClick={() => goToLocationUsingHistoryPush(AGENDAR)}
            />
            <RegularButton
              label="Agendar depois"
              variant="text"
              extraClass="mt-6 mx-auto button-healthplan-feedback"
              onClick={() => goToLocationUsingHistoryPush(INICIO)}
            />
          </div>
          <div className="mt-10 w-full">
            <p className="text-fontDefault font-medium mb-4 text-left mx-4">
              Perguntas frequentes
            </p>
            <div className="accordion-wrapper mb-10">
              <HealthPlanFaqAndBenefits
                benefits={benefits}
                iconType="icon-ChatHelpLight"
                closeModal={closeModal}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({
  authentication
}: AppState) => ({
  healthInsurancePlan: authentication.currentUser?.healthInsurancePlanData.healthInsurancePlan,
  benefits: authentication.currentUser?.healthInsurancePlanData.benefits
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  closeModal: closeModalAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotAcceptedHealthInsurance)
