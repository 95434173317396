import React, { useEffect, useRef, useState } from 'react'
import {
  AccessibleModal,
  ErrorMessage,
  FormInput,
  IconButton,
  RegularButton
} from 'theia-web-ds'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AppDispatch } from '../../../state/utils'
import { sendVerificationCodeAction, getUserConfirmationInfosAction, clearVerificationCodeStatusAction } from '../../../state/authentication/main/actions'
import { AppState } from '../../../apps/main/store'
import { User } from '../../../domain/User'
import { textPrimary } from '../../../color'
import './ValidationModal.scss'
import Emoji from '../../common/atoms/Emoji'
import { GenericStatusType } from '../../../domain/Status'
import ValidationCheck from '../../../../assets/validation_check.svg'
import { clearValidateInformationErrorsAction, suppressValidateEmailModalAction, validateInformationAction } from '../../../state/profile/actions'
import { eventTrack } from '../../../../eventGenerate'
import { EMAIL_VALIDATION } from '../../../utils/EventCategories'
import { emailValidationRegex } from '../../../utils/helpers'

interface EmailValidationValues {
  email: string;
  verificationCode: string;
}

function renderSteps(
  {
    values,
    touched,
    isSubmitting,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setSubmitting,
    setFieldValue,
    setFieldTouched
  }: FormikProps<EmailValidationValues>,
  sendEmailVerificationCodeStatus: GenericStatusType,
  validateEmailStatus: GenericStatusType,
  isFromProfileEdit: boolean,
  sendEmailVerificationCode: (email: string) => void,
  suppressValidateEmailModal: () => void,
  clearValidateEmailErrors: () => void,
  getUserConfirmationInfos: () => void,
  clearSendEmailVerificationCodeStatus: () => void
) {
  const [validationStep, setValidationStep] = useState(1)
  const [seconds, setSeconds] = useState(45)
  const [showSuccessContent, setShowSuccessContent] = useState(false)
  const intervalRef: any = useRef()

  function startTimer() {
    intervalRef.current = (setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1
        }
        clearInterval(intervalRef.current)
        return prevSeconds
      })
    }, 1000))

    return () => clearInterval(intervalRef.current)
  }

  const resetTimer = () => {
    clearInterval(intervalRef?.current)
    setSeconds(45)
  }

  function handleOnSubmit(e?: React.FormEvent<EventTarget>) {
    e?.preventDefault()
    handleSubmit()
  }

  function handleClickResendCode() {
    sendEmailVerificationCode(values.email)
    resetTimer()
    eventTrack('clicou reenviar email', {
      category: EMAIL_VALIDATION,
      email: values.email
    })
  }

  function clearValidateEmailForm() {
    clearValidateEmailErrors()
    setFieldValue('verificationCode', '')
    setFieldTouched('verificationCode', false)
  }

  function handleNextStep() {
    setValidationStep(validationStep + 1)
    resetTimer()
  }

  function closeModal() {
    suppressValidateEmailModal()
    clearSendEmailVerificationCodeStatus()
  }

  function onGoBack() {
    if (validationStep > 1) {
      setValidationStep(validationStep - 1)
      resetTimer()
    } else closeModal()
  }

  function handleClickChangeEmail() {
    eventTrack('clicou alteracao email', {
      category: EMAIL_VALIDATION,
      email: values.email
    })
    onGoBack()
  }

  function handleSendValidationCode() {
    sendEmailVerificationCode(values.email)
  }

  useEffect(() => {
    if (sendEmailVerificationCodeStatus.success) {
      clearValidateEmailForm()
      if (validationStep === 1) {
        handleNextStep()
      }
      startTimer()
      eventTrack('email validacao enviado', {
        category: EMAIL_VALIDATION,
        email: values.email
      })
    }
    if (sendEmailVerificationCodeStatus.error) {
      eventTrack(`${validationStep === 1 ? 'erro envio email' : 'erro reenvio email'}`, {
        category: EMAIL_VALIDATION,
        email: values.email,
        motivo: sendEmailVerificationCodeStatus.errorMessage?.friendlyMessage,
        plataforma: 'web'
      })
    }
  }, [sendEmailVerificationCodeStatus])

  useEffect(() => {
    if (values.verificationCode.length === 6) handleOnSubmit()
  }, [values.verificationCode])

  useEffect(() => {
    if (validateEmailStatus.error) {
      setSubmitting(false)
      eventTrack('erro validação email', {
        category: EMAIL_VALIDATION,
        email: values.email,
        motivo: validateEmailStatus.errorMessage?.friendlyMessage,
        plataforma: 'web'
      })
    }
    if (validateEmailStatus.success) {
      setSubmitting(false)
      setShowSuccessContent(true)
      getUserConfirmationInfos()
      eventTrack('email verificado', {
        category: EMAIL_VALIDATION,
        email: values.email,
        source: isFromProfileEdit ? 'perfil' : 'card da home'
      })
    }
  }, [validateEmailStatus])

  useEffect(() => {
    if (validationStep) {
      clearValidateEmailErrors()
    }
  }, [validationStep])

  const hasRequestError = validateEmailStatus.errorMessage?.friendlyMessage
  || sendEmailVerificationCodeStatus?.errorMessage?.friendlyMessage

  /**
   * erro 409 indica que o valor enviado já está em uso por outra conta,
   * portanto o user precisa inserir outro valor e validar novamente
   */

  const step1ButtonLabel = sendEmailVerificationCodeStatus.errorMessage?.statusCode === '409' || !hasRequestError ? 'Continuar' : 'Tentar novamente'

  const step1 = (
    <div className="account-validation--content">
      <h1 className="account-validation--title">
        {isFromProfileEdit ? 'Alterar e-mail' : 'Verifique seu e-mail'}
      </h1>
      <p className="account-validation--text">
        {isFromProfileEdit
          ? 'O novo e-mail substituirá o que você usa atualmente para acessar o app da Theia.'
          : 'Para manter seu cadastro atualizado, verifique se o seu e-mail está correto, e em seguida vamos confirmar sua conta'}
      </p>
      <FormInput
        placeholder=""
        label={isFromProfileEdit ? 'Novo e-mail' : 'E-mail'}
        type="email"
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        errorMessage={touched.email ? errors.email : ''}
        id="email"
        onClick={clearSendEmailVerificationCodeStatus}
      />
      {sendEmailVerificationCodeStatus?.error && (
        <ErrorMessage error={sendEmailVerificationCodeStatus?.errorMessage?.friendlyMessage || 'Não foi possível concluir sua solicitação'} />
      )}
      <RegularButton
        label={step1ButtonLabel}
        type="button"
        onClick={handleSendValidationCode}
        extraClass="mt-8"
        isSubmitting={sendEmailVerificationCodeStatus.isLoading}
        disabled={!!errors.email}
      />
    </div>
  )

  const step2 = (
    <div className="account-validation--content">
      <h1 className="account-validation--title">
        Confirme a sua conta
      </h1>
      <p className="account-validation--text">
        Informe o código de 6 digitos que enviamos para
        &nbsp;<span className="user-data">{values.email}</span>&nbsp;
        – pode demorar uns minutos para chegar <Emoji symbol="😊" />
      </p>
      <FormInput
        placeholder="Digite o código"
        label="Código"
        type="text"
        name="verificationCode"
        value={values.verificationCode}
        onChange={handleChange}
        onBlur={handleBlur}
        errorMessage={touched.verificationCode ? errors.verificationCode : ''}
        id="verificationCode"
        disabled={isSubmitting || validateEmailStatus.isLoading}
        isSubmitting={validateEmailStatus.isLoading || isSubmitting}
        onClick={clearValidateEmailErrors}
      />
      {validateEmailStatus?.error && (
        <ErrorMessage error={validateEmailStatus?.errorMessage?.friendlyMessage || 'Não foi possível concluir sua solicitação'} />
      )}
      <div className="account-validation--footer">
        <h3 className="account-validation--text-footer">
          Ainda não recebeu o código?
        </h3>
        <RegularButton
          type="button"
          label={seconds > 0 ? `Aguarde ${seconds}s` : 'Reenviar código'}
          disabled={seconds > 0 || isSubmitting}
          isSubmitting={sendEmailVerificationCodeStatus.isLoading}
          onClick={handleClickResendCode}
        />
        <RegularButton
          type="button"
          label="Alterar e-mail"
          disabled={isSubmitting}
          onClick={handleClickChangeEmail}
        />
      </div>
    </div>
  )

  return showSuccessContent ? (
    <div className="legacy-account-validation--success-content">
      <ValidationCheck className="header-image" />
      <h1 className="success-title">
        Conta confirmada!
      </h1>
      <p className="success-content">
        Deu tudo certo, agora que seu e-mail está verificado,
        &nbsp;você já pode utilizar seu app da Theia à vontade!
      </p>
      <RegularButton
        label="Fechar"
        type="button"
        onClick={closeModal}
        variant="primary"
      />
    </div>
  ) : (
    <form
      onSubmit={handleOnSubmit}
      noValidate
      className="legacy-account-validation--form"
      name="emailValidationForm"
    >
      <div className="account-validation--header ">
        <IconButton
          variant="form-icon"
          iconColor={textPrimary}
          iconType="icon-ArrowLeft2Light"
          iconSize="24px"
          onClick={onGoBack}
          isDisabled={isSubmitting}
        />
      </div>
      {validationStep === 1 ? step1 : null}
      {validationStep === 2 ? step2 : null}
    </form>
  )
}

interface Props {
  currentUser?: User;
  sendEmailVerificationCodeStatus: GenericStatusType;
  validateEmailStatus: GenericStatusType;
  isFromProfileEdit: boolean;
  showValidateEmailModal: boolean;
  sendEmailVerificationCode: (email: string) => void;
  validateEmail: (
    verificationCode: string,
    email: string) => void
  suppressValidateEmailModal: () => void;
  clearValidateEmailErrors: () => void;
  getUserConfirmationInfos: () => void;
  clearSendEmailVerificationCodeStatus: () => void;
}

const validationSchema = Yup.object().shape({
  verificationCode: Yup.string().required('Código obrigatório')
    .length(6, 'O código deve conter 6 dígitos'),
  email: Yup.string().trim()
    .email('E-mail inválido')
    .matches(emailValidationRegex, 'Digite um e-mail válido pra continuar')
    .required('Email obrigatório'),
})

function EmailValidationModal({
  currentUser,
  sendEmailVerificationCodeStatus,
  validateEmailStatus,
  isFromProfileEdit,
  showValidateEmailModal,
  sendEmailVerificationCode,
  validateEmail,
  suppressValidateEmailModal,
  clearValidateEmailErrors,
  getUserConfirmationInfos,
  clearSendEmailVerificationCodeStatus
}: Props) {
  const initialValues: EmailValidationValues = {
    email: currentUser?.email || '',
    verificationCode: '',
  }

  function onSubmit(values: EmailValidationValues) {
    validateEmail(values.verificationCode, values.email)
  }

  function suppressModal() {
    suppressValidateEmailModal()
    clearSendEmailVerificationCodeStatus()
  }

  return (
    <AccessibleModal
      headerText=""
      onClose={suppressModal}
      visible={showValidateEmailModal}
      variant="centerBottom"
      extraOuterClassName={`legacy-account-validation--modal
        ${validateEmailStatus.success ? 'success' : ''}
      `}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => renderSteps(
          props,
          sendEmailVerificationCodeStatus,
          validateEmailStatus,
          isFromProfileEdit,
          sendEmailVerificationCode,
          suppressValidateEmailModal,
          clearValidateEmailErrors,
          getUserConfirmationInfos,
          clearSendEmailVerificationCodeStatus
        )}
      </Formik>
    </AccessibleModal>
  )
}

const mapStateToProps = ({ authentication, profile }: AppState) => ({
  currentUser: authentication.currentUser,
  sendEmailVerificationCodeStatus: authentication.sendVerificationCodeStatus,
  validateEmailStatus: profile.validateInformationStatus,
  isFromProfileEdit: profile.isFromProfileEdit,
  showValidateEmailModal: profile.showValidateEmailModal
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  sendEmailVerificationCode: sendVerificationCodeAction,
  validateEmail: validateInformationAction,
  suppressValidateEmailModal: suppressValidateEmailModalAction,
  clearValidateEmailErrors: clearValidateInformationErrorsAction,
  getUserConfirmationInfos: getUserConfirmationInfosAction,
  clearSendEmailVerificationCodeStatus: clearVerificationCodeStatusAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailValidationModal)
