import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Alert,
  Divider,
  FontIcon,
  IconButton,
  Loader
} from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import {
  textDisable,
  textPrimary,
} from '../../color'
import {
  ConsultationInfos,
  GuidedJourney,
  Quarter,
  QuarterConsultation
} from '../../domain/GuidedJourney'
import {
  checkJourneyStepAction,
  getConsultationInfosAction,
  getGuidedJourneyAction,
  getQuarterDetailsAction,
  promptCheckJourneyStepAction,
  promptUncheckJourneyStepAction,
  selectJourneyQuarterAction,
  setViewedGuidedJourneyAction,
  uncheckJourneyStepAction
} from '../../state/guidedJourney/actions'
import { AppDispatch } from '../../state/utils'
import ConsultationDetails from './ConsultationDetails'
import StackedCard from './StackedCard'
import { eventTrack } from '../../../eventGenerate'
import {
  isPediatricJourney,
  isPostPregnant,
  isPregnant,
  quartersNames
} from '../../utils/helpers'
import { ConsultantMoment } from '../../domain/Profile'
import { SpecialistCategories } from '../../domain/Specialist'
import {
  selectAttendanceTypeAction,
  setPatientTypeAction,
  setPreviousSelectedCategoryAction,
  setScheduleSourceAction
} from '../../state/scheduleFlow/actions'
import { AttendanceAvailabilityType, PatientType, SourceTypes } from '../../domain/ScheduleFlow'
import './QuarterDetailsView.scss'
import { AGENDAR, INICIO } from '../../routes/RoutesConstants'
import { PEDIATRIC_JOURNEY } from '../../utils/EventCategories'

interface Props {
  quarterDetails?: QuarterConsultation[];
  isLoading: boolean;
  isGettingConsultationInfos: boolean;
  activeConsultation?: ConsultationInfos;
  isChecking: boolean;
  activeCheckboxId: string;
  activeCard?: QuarterConsultation;
  guidedJourneyQuarters?: GuidedJourney;
  selectedQuarter?: Quarter;
  hasCurrentQuarter: boolean;
  pregnancyMoment?: ConsultantMoment;
  pregnancyMomentText?: string;
  getQuarterDetails: (id: string) => void;
  getConsultationInfos: (id: string, cardInfos: QuarterConsultation) => void;
  promptCheckItem: (id: string) => void;
  checkItem: (id: string) => void;
  promptUncheckItem: (id: string) => void;
  uncheckItem: (id: string) => void;
  setViewedGuidedJourney: () => void;
  getGuidedJourney: () => void;
  selectJourneyQuarter: (quarter: Quarter) => void;
  setPreviousSelectedCategory: (category: SpecialistCategories) => void;
  setPatientType: (patientType: PatientType) => void;
  setScheduleSource: (source: SourceTypes) => void;
  selectAttendanceType: (type: AttendanceAvailabilityType) => void;
}

function QuarterDetailsView({
  quarterDetails,
  isLoading,
  isGettingConsultationInfos,
  activeConsultation,
  isChecking,
  activeCheckboxId,
  activeCard,
  guidedJourneyQuarters,
  selectedQuarter,
  hasCurrentQuarter,
  pregnancyMoment,
  pregnancyMomentText,
  getQuarterDetails,
  getConsultationInfos,
  promptCheckItem,
  checkItem,
  promptUncheckItem,
  uncheckItem,
  setViewedGuidedJourney,
  getGuidedJourney,
  selectJourneyQuarter,
  setPreviousSelectedCategory,
  setPatientType,
  setScheduleSource,
  selectAttendanceType,
}: Props) {
  const history = useHistory()
  const [detailsVisible, setDetailsVisible] = useState(false)
  const [msgNoParams, setMsgNoParams] = useState(false)
  const currentQuarter = guidedJourneyQuarters?.list.find(
    (quarter) => quarter.currentQuarter === true
  )
  const defaultInitialQuarter = guidedJourneyQuarters?.list[0]
  const activeQuarter = selectedQuarter || currentQuarter || defaultInitialQuarter
  const pediatricJourney = isPediatricJourney(activeQuarter?.journeyType)
  const quarterIndex = guidedJourneyQuarters?.list.findIndex(
    (quarter) => quarter.id === activeQuarter?.id
  ) || -1

  if (!isPregnant(pregnancyMoment) && !isPostPregnant(pregnancyMoment, pregnancyMomentText)) {
    history.push(INICIO)
  }

  useEffect(() => {
    setMsgNoParams(false)
    if (activeQuarter?.id) {
      selectJourneyQuarter(activeQuarter)
      getQuarterDetails(activeQuarter.id)
    } else {
      setMsgNoParams(true)
    }
  }, [activeQuarter])

  useEffect(() => {
    if (!guidedJourneyQuarters?.list || guidedJourneyQuarters?.list?.length === 0) {
      getGuidedJourney()
    }
    if (guidedJourneyQuarters?.isFirstTime === true) {
      if (!hasCurrentQuarter && !activeQuarter?.currentQuarter) {
        setViewedGuidedJourney()
      }
      if (activeQuarter?.currentQuarter) {
        setViewedGuidedJourney()
      }
    }
  }, [guidedJourneyQuarters])

  useEffect(() => {
    if (pediatricJourney && quarterIndex && quarterIndex > -1) {
      eventTrack(`visualizou jornada ${quartersNames[quarterIndex]} pediatria`, {
        category: PEDIATRIC_JOURNEY
      })
    }
  }, [quarterIndex])

  function handleClickInfosAction(cardInfo: QuarterConsultation) {
    eventTrack('Clicou Pra quê serve essa consulta - jornada explicada')
    getConsultationInfos(cardInfo.id, cardInfo)
    setDetailsVisible(true)
  }

  function handleClickScheduleAction(cardInfo: QuarterConsultation) {
    const type = activeCard?.type === 'Online'
      ? AttendanceAvailabilityType.VIRTUAL
      : AttendanceAvailabilityType.PRESENTIAL
    const isForBaby = cardInfo.chipList?.find((chip) => chip.text === 'Para o bebê') || false
    if (pediatricJourney) {
      eventTrack('clicou agendar consulta jornada pediatrica', {
        category: PEDIATRIC_JOURNEY,
        trimestre: quartersNames[quarterIndex],
        especialidade: cardInfo.category,
        modalidade: cardInfo.type
      })
    } else {
      eventTrack('Clicou agendar consulta - jornada explicada', {
        trimestre: quartersNames[quarterIndex],
        especialidade: cardInfo.category,
        modalidade: cardInfo.type
      })
      eventTrack(`Clicou agendar consulta com ${cardInfo.category} - jornada explicada`)
    }
    selectAttendanceType(type)
    setPatientType(isForBaby ? PatientType.IS_CHILD : PatientType.IS_USER)
    setPreviousSelectedCategory(cardInfo.category)
    setScheduleSource(SourceTypes.JOURNEY)
    history.push(AGENDAR)
  }

  function handleClickCheckboxCard(
    checked: boolean,
    cardInfo: QuarterConsultation,
  ) {
    if (!isChecking && cardInfo.id && cardInfo.id !== '') {
      if (checked === true) {
        if (pediatricJourney) {
          eventTrack('deu check em consulta realizada', {
            category: PEDIATRIC_JOURNEY,
            trimestre: quartersNames[quarterIndex],
            especialidade: cardInfo.category,
            modalidade: cardInfo.type
          })
        } else {
          eventTrack('Clicou marcar consulta como realizada - jornada explicada', {
            trimestre: quartersNames[quarterIndex],
            especialidade: cardInfo.category,
            modalidade: cardInfo.type
          })
        }
        checkItem(cardInfo.id)
      } else {
        if (!isPediatricJourney) {
          eventTrack('Clicou marcar consulta como não realizada - jornada explicada')
        }
        uncheckItem(cardInfo.id)
      }
    }
  }

  function handleClickCheckboxDetailsView(checked: boolean, id: string) {
    if (!isChecking && id && id !== '') {
      if (checked === true) {
        promptCheckItem(id)
      } else {
        promptUncheckItem(id)
      }
    }
  }

  return (
    <div className="quarter-details-view">
      {guidedJourneyQuarters?.isFirstTime && guidedJourneyQuarters.firstTimeJourneyMessage && (
        <Alert
          message={guidedJourneyQuarters.firstTimeJourneyMessage}
          extraClass="absolute z-20 mt-6 self-center -translate-x-4 lg:self-start lg:ml-5"
          variant="flowting"
          indicator="left-bottom"
          id="first-time-consultations-alert"
          timer={10000}
        />
      )}
      <div className="quarter-details-header flex">
        <IconButton
          aria-label="Voltar"
          variant="text"
          width="44px"
          height="44px"
          iconType="icon-ArrowLeft2Light"
          iconColor={textPrimary}
          iconSize="20px"
          onClick={() => history.goBack()}
        />
        <p>{activeQuarter?.title}</p>
      </div>
      <Divider />
      {isLoading ? (
        <div className="flex h-screen justify-items-center items-center mx-auto">
          <Loader size="35px" />
        </div>
      ) : (
        <div className="quarter-details-content">
          <div className="card-list-view mb-10 lg:mb-0">
            {msgNoParams && <p>Você precisa selecionar um trimestre para visualizar a jornada</p>}
            {quarterDetails
              && quarterDetails.length > 0
              && quarterDetails.map((cardInfo, index) => (
                <StackedCard
                  cardInfo={cardInfo}
                  key={`stackedCard-${index}`}
                  isLastItem={index === quarterDetails.length - 1}
                  isLoading={isChecking && (activeCheckboxId === cardInfo.id)}
                  onClickArrowDesktopAction={() => {
                    handleClickInfosAction(cardInfo)
                  }}
                  onClickInfosAction={() => {
                    handleClickInfosAction(cardInfo)
                  }}
                  onClickScheduleAction={() => {
                    handleClickScheduleAction(cardInfo)
                  }}
                  onClickCheckbox={(checked: boolean) => handleClickCheckboxCard(
                    checked,
                    cardInfo
                  )}
                />
              ))}
          </div>

          {isGettingConsultationInfos && (
            <div className="quarter-details-info">
              <Loader />
            </div>
          )}

          {!isGettingConsultationInfos && activeCard
            && activeConsultation
            && activeConsultation !== undefined
            && detailsVisible
              && (
                <ConsultationDetails
                  onCloseAction={() => setDetailsVisible(false)}
                  isChecking={isChecking && (activeCheckboxId === activeCard?.id)}
                  consultationInfos={activeConsultation}
                  activeCard={activeCard}
                  onClickCheckbox={(
                    checked: boolean,
                    item: QuarterConsultation
                  ) => handleClickCheckboxDetailsView(checked, item.id)}
                  onClickScheduleAction={() => {
                    handleClickScheduleAction(activeCard)
                  }}
                />
              )}

          {!isGettingConsultationInfos
            && (!activeConsultation || activeConsultation === undefined) && (
              <div className="quarter-details-info">
                <FontIcon iconType="icon-InfoSquareLight" color={textDisable} fontSize="27px" extraClass="mb-4" />
                <p>
                  Descubra mais informações sobre nossas consultas selecionando uma delas.
                </p>
              </div>
          )}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({
  guidedJourney, authentication
}: AppState) => ({
  quarterDetails: guidedJourney.quarterDetails,
  isLoading: guidedJourney.isLoading,
  isGettingConsultationInfos: guidedJourney.isGettingConsultationInfos,
  activeConsultation: guidedJourney.activeConsultation,
  isChecking: guidedJourney.isCheckingStep,
  activeCheckboxId: guidedJourney.activeCheckboxId,
  activeCard: guidedJourney.activeCard,
  guidedJourneyQuarters: guidedJourney.guidedJourney,
  selectedQuarter: guidedJourney.selectedQuarter,
  hasCurrentQuarter: guidedJourney.hasCurrentQuarter,
  pregnancyMoment: authentication?.currentUser?.pregnancyMoment,
  pregnancyMomentText: authentication.currentUser?.pregnancyMomentText
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getQuarterDetails: (id: string) => {
    dispatch(getQuarterDetailsAction(id))
  },
  getConsultationInfos: (id: string, cardInfo: QuarterConsultation) => {
    dispatch(getConsultationInfosAction(id, cardInfo))
  },
  promptCheckItem: (id: string) => {
    dispatch(promptCheckJourneyStepAction(id))
  },
  checkItem: (id: string) => {
    dispatch(checkJourneyStepAction(id))
  },
  promptUncheckItem: (id: string) => {
    dispatch(promptUncheckJourneyStepAction(id))
  },
  uncheckItem: (id: string) => {
    dispatch(uncheckJourneyStepAction(id))
  },
  setViewedGuidedJourney: () => {
    dispatch(setViewedGuidedJourneyAction())
  },
  getGuidedJourney: () => {
    dispatch(getGuidedJourneyAction())
  },
  selectJourneyQuarter: (quarter: Quarter) => {
    dispatch(selectJourneyQuarterAction(quarter))
  },
  setPreviousSelectedCategory: (category: SpecialistCategories) => {
    dispatch(setPreviousSelectedCategoryAction(category))
  },
  setPatientType: (patientType: PatientType) => {
    dispatch(setPatientTypeAction(patientType))
  },
  setScheduleSource: (source: SourceTypes) => {
    dispatch(setScheduleSourceAction(source))
  },
  selectAttendanceType: (type: AttendanceAvailabilityType) => {
    dispatch(selectAttendanceTypeAction(type))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuarterDetailsView)
