import React from 'react'
import { RegularButton } from 'theia-web-ds'
import CalendarError from '../../../assets/calendar_error.svg'
import ExclamationError from '../../../assets/exclamation-error.svg'
import CardError from '../../../assets/card-error.svg'
import Modal from '../common/Modal'
import { TheiaError } from '../../domain/errors/TheiaError'
import './ScheduleErrorModal.scss'

interface Props {
  error?: TheiaError;
  textCloseButton?: string;
  showExtraButton?: boolean;
  extraButtonText?: string;
  extraButtonActionWithCloseModal?: boolean;
  closeModal: () => void;
  extraButtonAction?: () => void;
  actionToSkip?: () => void;
  extraOnCloseModal?: () => void;
}

function ScheduleErrorModal({
  error,
  textCloseButton,
  showExtraButton = false,
  extraButtonText,
  extraButtonActionWithCloseModal = true,
  closeModal,
  extraButtonAction,
  actionToSkip,
  extraOnCloseModal,
}: Props) {
  function closeModalWithExtraAction() {
    if (extraButtonAction) {
      extraButtonAction()
      if (extraButtonActionWithCloseModal) {
        closeModal()
      }
    } else {
      closeModal()
    }
  }

  function handleCloseModal() {
    if (actionToSkip) {
      actionToSkip()
      return
    }
    if (extraOnCloseModal) {
      extraOnCloseModal()
    }
    closeModal()
  }

  function errorMessage() {
    let message = error?.friendlyMessage
    if (!message) {
      if (error?.statusCode === 'PAYMENT-METHOD-FAILED') {
        message = 'Não foi possível concluir a solicitação com o meio de pagamento selecionado.'
      } else if (error?.message?.match(/Product Professional not found for professional id/)) {
        message = 'ProductId não encontrado para este profissional.'
      } else {
        message = 'Não foi possível concluir sua solicitação. Tente novamente mais tarde.'
      }
    }
    return message
  }

  function errorImage() {
    let image = <CalendarError className="self-center" />
    if (error?.statusCode === 'PAYMENT-METHOD-FAILED') {
      image = <CardError className="self-center" />
    }
    if (error?.message?.match(/Product Professional not found for professional id/)) {
      image = <ExclamationError className="self-center" />
    }
    return image
  }

  return (
    <Modal extraInnerClass="schedule-error-modal">
      {errorImage()}
      <h1 className="schedule-modal__title">
        {error?.friendlyMessageTitle || 'Ops, houve um problema'}
      </h1>
      <p className="schedule-error-modal__info">
        {errorMessage()}
      </p>
      <div className="flex flex-col justify-center gap-1">
        {showExtraButton && (
        <RegularButton
          height="36px"
          labelSize="14px"
          label={extraButtonText}
          onClick={closeModalWithExtraAction}
        />
        )}
        <RegularButton
          height="36px"
          label={textCloseButton || 'Fechar'}
          variant={showExtraButton ? 'text' : 'primary'}
          labelSize="14px"
          onClick={handleCloseModal}
        />
      </div>
    </Modal>
  )
}

export default ScheduleErrorModal
