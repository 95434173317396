import React, { useEffect, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import { RegularButton, Divider, FormInput } from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import { onUpdateHealthHistoryDataInProfileAction } from '../../state/profile/actions'
import { AppDispatch } from '../../state/utils'
import './HealthDataView.scss'
import BooleanInput from '../common/molecules/inputs/BooleanInput'
import { formCheckHasOneElementsEmpty } from '../../utils/helpers'
import FormMultiSelect from '../common/molecules/inputs/FormMultiSelect'
import { HistoryInfoForm, RelationshipOptions } from '../../domain/Profile'
import FormInputList from '../common/molecules/inputs/FormInputList'

function renderHistoryInfosForm({
  values,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  errors,
  touched,
  handleSubmit,
}: FormikProps<HistoryInfoForm>,
onGoBack: () => void,
isSubmitting: boolean) {
  const [
    aboutRelationshipOthersError,
    setAboutRelationshipOthersError
  ] = useState<{[index: number]: string}>()
  const disabledSubmit = !!aboutRelationshipOthersError

  useEffect(() => {
    setAboutRelationshipOthersError(formCheckHasOneElementsEmpty(values.aboutRelationshipOthers))
  }, [values.aboutRelationshipOthers])

  function onChangeHasKids(newValue: boolean) {
    setFieldValue('hasKids', `${newValue}`);
    setFieldTouched('hasKids', true);
  }

  function onChangeHasPregnancyLoss(newValue: boolean) {
    setFieldValue('hasPregnancyLoss', `${newValue}`);
    setFieldTouched('hasPregnancyLoss', true);
  }

  function onChangeHasAboutRelationship(newValue: boolean) {
    setFieldValue('hasAboutRelationship', `${newValue}`);
    setFieldTouched('hasAboutRelationship', true);
    if (!newValue) {
      setFieldValue('aboutRelationship', []);
      setFieldTouched('aboutRelationship', true);
      setFieldValue('aboutRelationshipOthers', []);
      setFieldTouched('aboutRelationshipOthers', true);
    }
  }

  function onChangeAboutRelationship(newList: string[]) {
    setFieldValue('aboutRelationship', newList);
    setFieldTouched('aboutRelationship', true);
    if (
      newList.includes(RelationshipOptions.OTHER)
      && values.aboutRelationshipOthers.length === 0
    ) {
      setFieldValue('aboutRelationshipOthers', ['']);
      setFieldTouched('aboutRelationshipOthers', true);
    }
    if (!newList.includes(RelationshipOptions.OTHER) && values.aboutRelationshipOthers.length > 0) {
      setFieldValue('aboutRelationshipOthers', []);
      setFieldTouched('aboutRelationshipOthers', true);
    }
  }

  function onChangeAboutRelationshipOthers(newList: string[]) {
    setFieldValue('aboutRelationshipOthers', newList);
    setFieldTouched('aboutRelationshipOthers', true);
  }

  return (
    <form onSubmit={handleSubmit} className="health-view-drawer-form" noValidate>
      <div className="health-view-drawer-inner-form">
        <div className="health-view-individual-container">
          <BooleanInput
            label="Você tem filhos?"
            name="hasKids"
            value={values.hasKids === '' ? undefined : values.hasKids === 'true'}
            onChange={onChangeHasKids}
          />
        </div>
        {values.hasKids === 'true' && (
          <div className="health-view-individual-container">
            <div className="individual-sub-topic">
              <FormInput
                id="numberOfKids"
                value={String(values.numberOfKids)}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={touched.numberOfKids ? errors.numberOfKids : ''}
                label="Quantos filhos tem?"
                type="number"
                name="numberOfKids"
              />
            </div>
          </div>
        )}
        <div className="health-view-individual-container">
          <BooleanInput
            label="Já teve alguma perda gestacional?"
            name="hasPregnancyLoss"
            value={values.hasPregnancyLoss === '' ? undefined : values.hasPregnancyLoss === 'true'}
            onChange={onChangeHasPregnancyLoss}
          />
        </div>
        <div className="health-view-individual-container">
          <BooleanInput
            label="Você tem uma rede de apoio?"
            name="hasAboutRelationship"
            value={values.hasAboutRelationship === '' ? undefined : values.hasAboutRelationship === 'true'}
            onChange={onChangeHasAboutRelationship}
          />
          {values.hasAboutRelationship === 'true' && (
            <div className="individual-sub-topic">
              <FormMultiSelect
                id="aboutRelationship"
                options={Object.values(RelationshipOptions).filter(
                  (element) => element !== RelationshipOptions.NONE
                )}
                onChange={onChangeAboutRelationship}
                initialValues={values.aboutRelationship}
              />
            </div>
          )}
          {values.hasAboutRelationship === 'true' && values.aboutRelationship.includes(RelationshipOptions.OTHER) && (
            <FormInputList
              placeholder="* Digite sua rede de apoio"
              ariaLabel="Digite sua rede de apoio"
              list={values.aboutRelationshipOthers}
              errors={aboutRelationshipOthersError}
              onChange={onChangeAboutRelationshipOthers}
              required
            />
          )}
        </div>
      </div>
      <div className="health-view-drawer-footer">
        <Divider />
        <div className="health-view-drawer-button">
          <RegularButton
            onClick={onGoBack}
            label="Agora não"
            variant="text"
            extraClass="booking-details-cancel-btn"
          />
          <RegularButton
            onClick={() => handleSubmit()}
            label="Salvar"
            type="submit"
            isSubmitting={isSubmitting}
            disabled={disabledSubmit}
          />
        </div>
      </div>
    </form>
  )
}

const emptyList: string[] = []

const initialValues: HistoryInfoForm = {
  hasKids: '',
  numberOfKids: 0,
  hasAboutRelationship: '',
  aboutRelationship: emptyList,
  aboutRelationshipOthers: emptyList,
  hasPregnancyLoss: '',
}

interface Props {
  isSubmitting: boolean;
  onGoBack: () => void;
  onUpdateHealthHistoryDataInProfile: (
    name: string,
    email: string,
    phone: string,
    historyInfo: HistoryInfoForm
  ) => void;
  name?: string;
  email?: string;
  phone?: string;
  hasKids?: string;
  numberOfKids?: number;
  hasPregnancyLoss?: string;
  hasAboutRelationship?: string;
  aboutRelationship?: string[];
  aboutRelationshipOthers?: string[];
  isUpdatingProfile: boolean;
}

function HistoryDataEdit({
  onUpdateHealthHistoryDataInProfile,
  isSubmitting,
  hasKids,
  numberOfKids,
  hasAboutRelationship,
  aboutRelationship,
  aboutRelationshipOthers,
  hasPregnancyLoss,
  onGoBack,
  name,
  email,
  phone,
  isUpdatingProfile
}: Props) {
  const [alreadySendNewInfos, setAlreadySendNewInfos] = useState(false)

  useEffect(() => {
    if (!isUpdatingProfile && alreadySendNewInfos) {
      setAlreadySendNewInfos(false)
      onGoBack()
    }
  }, [isUpdatingProfile])

  initialValues.hasKids = hasKids
      || initialValues.hasKids
  initialValues.numberOfKids = (
    numberOfKids || initialValues.numberOfKids
  )
  initialValues.hasAboutRelationship = hasAboutRelationship
    || initialValues.hasAboutRelationship
  initialValues.aboutRelationship = (
    aboutRelationship || initialValues.aboutRelationship
  )
  initialValues.aboutRelationshipOthers = (
    aboutRelationshipOthers || initialValues.aboutRelationshipOthers
  )
  initialValues.hasPregnancyLoss = hasPregnancyLoss
    || initialValues.hasPregnancyLoss
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(_value: HistoryInfoForm) => {
        if (name && email && phone) {
          setAlreadySendNewInfos(true)
          onUpdateHealthHistoryDataInProfile(
            name,
            email,
            phone,
            {
              hasKids: _value.hasKids,
              numberOfKids: _value.numberOfKids,
              hasPregnancyLoss: _value.hasPregnancyLoss,
              hasAboutRelationship: _value.hasAboutRelationship,
              aboutRelationship: _value.aboutRelationship,
              aboutRelationshipOthers: _value.aboutRelationshipOthers,
            }
          )
        }
      }}
    >
      {(props) => renderHistoryInfosForm(
        props,
        onGoBack,
        isSubmitting
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  profile, authentication
}: AppState) => ({
  name: authentication.currentUser?.name,
  email: authentication.currentUser?.email,
  phone: authentication.currentUser?.phone,
  isSubmitting: profile.isUpdatingProfile,
  hasKids: profile.profile?.hasKids,
  numberOfKids: profile.profile?.numberOfKids,
  hasAboutRelationship: profile.profile?.hasAboutRelationship,
  aboutRelationship: profile.profile?.aboutRelationship,
  aboutRelationshipOthers: profile.profile?.aboutRelationshipOthers,
  hasPregnancyLoss: profile.profile?.hasPregnancyLoss,
  isUpdatingProfile: profile.isUpdatingProfile
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onUpdateHealthHistoryDataInProfile: (
    name: string,
    email: string,
    phone: string,
    historyInfo: HistoryInfoForm
  ) => {
    dispatch(onUpdateHealthHistoryDataInProfileAction(
      name,
      email,
      phone,
      historyInfo
    ))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryDataEdit)
