import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { suppressPermissionAction } from '../../state/booking/actions'
import Modal from '../common/Modal'
import HeaderModal from '../common/HeaderModal'
import ConfirmButton from '../common/buttons/ConfirmButton'

interface Props {
  visible: boolean;
  closeModal: () => void;
}

function PermissionWebPromptModal({ visible, closeModal }: Props) {
  if (!visible) return null

  return (
    <Modal>
      <div className="flex flex-col text-center prompt-modal">
        <HeaderModal className="mt-4 mb-4 modal__title">Ei, antes de iniciar esta consulta você precisa permitir o uso da sua câmera e microfone</HeaderModal>
        <p className="text-textSecondary font-bold mb-2">Esta permissão é necessária para que a consulta seja iniciada</p>

        <ConfirmButton onClick={closeModal}>Solicitar permissão</ConfirmButton>
      </div>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModal: () => { dispatch(suppressPermissionAction()) },
})

export default connect(
  null,
  mapDispatchToProps
)(PermissionWebPromptModal)
