import React, { useEffect, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  RegularButton, Divider, FormInput, DateInput
} from 'theia-web-ds'
import BooleanInput from '../common/molecules/inputs/BooleanInput'
import FormInputList from '../common/molecules/inputs/FormInputList'
import { AppState } from '../../apps/main/store'
import { BloodTypeOptions } from '../../domain/Profile'
import FormSelect from '../common/FormSelect'
import { onUpdateHealthPersonalDataInProfileAction } from '../../state/profile/actions'
import { AppDispatch } from '../../state/utils'
import './HealthDataView.scss'
import { formCheckHasOneElementsEmpty } from '../../utils/helpers'

type PersonalInfosForm = {
  birthDate: string;
  hasAllergy: string;
  allergies: string[];
  height: number;
  bloodType: string;
}

function renderPersonalInfosForm(
  {
    values,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    errors,
    handleBlur,
    handleChange,
    touched
  }: FormikProps<PersonalInfosForm>,
  onGoBack: () => void,
  isSubmitting: boolean
) {
  const [allergiesError, setAllergiesError] = useState<{[index: number]: string}>()

  const disabledSubmit = (
    !!allergiesError
  )

  useEffect(() => {
    setAllergiesError(formCheckHasOneElementsEmpty(values.allergies))
  }, [values.allergies])

  function onChangeBirthDate(date: Date) {
    if (!date) return
    setFieldValue('birthDate', date.getTime());
    setFieldTouched('birthDate', true);
  }

  function onChangeHasAllergy(newValue: boolean) {
    setFieldValue('hasAllergy', `${newValue}`);
    setFieldTouched('hasAllergy', true);
    if (newValue) {
      setFieldValue('allergies', ['']);
      setFieldTouched('allergies', true);
    } else {
      setFieldValue('allergies', []);
      setFieldTouched('allergies', true);
    }
  }

  function onChangeAllergies(newList: string[]) {
    setFieldValue('allergies', newList);
    setFieldTouched('allergies', true);
  }

  return (
    <form onSubmit={handleSubmit} className="health-view-drawer-form" noValidate>
      <div className="health-view-drawer-inner-form">
        <div className="health-view-individual-container">
          <DateInput
            required
            id="birthDate"
            name="birthDate"
            onChange={onChangeBirthDate}
            blockFutureDates
            placeholder="Selecione uma data"
            label="Data de nascimento"
            value={values.birthDate}
          />
        </div>
        <div className="health-view-individual-container">
          <BooleanInput
            label="Possui alguma alergia (medicação, materiais)?"
            name="hasAllergy"
            value={values.hasAllergy === '' ? undefined : values.hasAllergy === 'true'}
            onChange={onChangeHasAllergy}
          />
          {values.hasAllergy === 'true' && (
            <FormInputList
              placeholder="* Digite qual alergia"
              ariaLabel="Digite qual alergia"
              list={values.allergies}
              errors={allergiesError}
              onChange={onChangeAllergies}
              required
            />
          )}
        </div>
        <div className="shared-container">
          <div className="first-container">
            <FormInput
              label="Altura"
              name="height"
              type="number"
              placeholder="0.00 (m)"
              id="height"
              value={String(values.height)}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={touched.height ? errors.height : ''}
            />
          </div>
          <div className="second-container">
            <FormSelect
              name="bloodType"
              label="Tipo sanguíneo"
              options={Object.values(BloodTypeOptions)}
            />
          </div>
        </div>
      </div>
      <div className="health-view-drawer-footer">
        <Divider />
        <div className="health-view-drawer-button">
          <RegularButton
            onClick={onGoBack}
            label="Agora não"
            variant="text"
            extraClass="booking-details-cancel-btn"
          />
          <RegularButton
            onClick={() => handleSubmit()}
            label="Salvar"
            type="submit"
            disabled={disabledSubmit}
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
    </form>
  )
}

const emptyList: string[] = []

const initialValues: PersonalInfosForm = {
  birthDate: '',
  height: 0,
  bloodType: '',
  hasAllergy: '',
  allergies: emptyList,
}

interface Props {
  isSubmitting: boolean;
  birthDate?: string;
  hasAllergy?: string;
  allergies?: string[];
  height?: number;
  bloodType?: string;
  onGoBack: () => void;
  onUpdateHealthPersonalDataInProfile: (
    name: string,
    email: string,
    phone: string,
    birthDate: string,
    hasAllergy: string,
    allergies: string[],
    height: number,
    bloodType: string,
  ) => void;
  name?: string;
  email?: string;
  phone?: string;
  isUpdatingProfile: boolean;
}

const validationSchema = Yup.object().shape({
  height: Yup.number()
    .min(0, 'Valor tem que ser positivo')
    .test('len', 'Máximo 4 digitos', (val) => val?.toString().length <= 4)
    .lessThan(3, 'Valor não permitido. Por favor, insira no formato 0.00 (m)')
})

function PersonalDataEdit({
  onUpdateHealthPersonalDataInProfile,
  isSubmitting,
  birthDate,
  hasAllergy,
  allergies,
  height,
  bloodType,
  onGoBack,
  name,
  email,
  phone,
  isUpdatingProfile
}: Props) {
  const [alreadySendNewInfos, setAlreadySendNewInfos] = useState(false)

  useEffect(() => {
    if (!isUpdatingProfile && alreadySendNewInfos) {
      setAlreadySendNewInfos(false)
      onGoBack()
    }
  }, [isUpdatingProfile])

  initialValues.birthDate = birthDate
    || initialValues.birthDate
  initialValues.hasAllergy = hasAllergy
    || initialValues.hasAllergy
  initialValues.allergies = (
    allergies && allergies !== null
      ? allergies
      : initialValues.allergies
  )
  initialValues.height = height || initialValues.height
  initialValues.bloodType = bloodType || initialValues.bloodType
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(_value: PersonalInfosForm) => {
        if (name && email && phone) {
          setAlreadySendNewInfos(true)
          onUpdateHealthPersonalDataInProfile(
            name,
            email,
            phone,
            _value.birthDate,
            _value.hasAllergy,
            _value.allergies,
            _value.height,
            _value.bloodType
          )
        }
      }}
    >
      {(props) => renderPersonalInfosForm(
        props,
        onGoBack,
        isSubmitting
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  profile, authentication
}: AppState) => ({
  hasAllergy: profile.profile?.hasAllergy,
  bloodType: profile.profile?.bloodType,
  allergies: profile.profile?.allergies,
  birthDate: profile.profile?.birthDate,
  height: profile.profile?.height,
  name: authentication.currentUser?.name,
  email: authentication.currentUser?.email,
  phone: authentication.currentUser?.phone,
  isSubmitting: profile.isUpdatingProfile,
  isUpdatingProfile: profile.isUpdatingProfile
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onUpdateHealthPersonalDataInProfile: (
    name: string,
    email: string,
    phone: string,
    birthDate: string,
    hasAllergy: string,
    allergies: string[],
    height: number,
    bloodType: string,
  ) => {
    dispatch(onUpdateHealthPersonalDataInProfileAction(
      name,
      email,
      phone,
      birthDate,
      hasAllergy,
      allergies,
      height,
      bloodType
    ))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDataEdit)
