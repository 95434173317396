import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { bindActionCreators, Dispatch } from 'redux'
import { RegularButton, Loader } from 'theia-web-ds';
import { AppState } from '../../../apps/main/store'

import './ScheduleConfirmSMS.scss'
import LogoHorizontal from '../../../../assets/horizontal-logo.svg'
import MainIllustration from '../../../../public/images/confirm-ilustra.png'
import {
  bookingProfessionalConfirmationInfoSMSAction,
  confirmBookingSMSAction,
  promtpConfirmBookingSMSModalAction
} from '../../../state/scheduleConfirmationSMS/actions'
import ScheduleConfirmationSmsModal from './ScheduleConfirmationSmsModal'
import { formatToHours } from '../../../utils/helpers';

interface Props {
  step: string;
  bookingConfirmationInfoSMS: (
    bookingId: string, token: string
  ) => void;
  confirmBookingSMS: (
    bookingId: string, token: string, participantType?: string, professionalStatus?: string
  ) => void;
  promtpConfirmBookingSMSModal: () => void;
  id: string;
  token: string;
  startDate?: string;
  professionalName: string;
  isModalOpened: boolean;
  isLoading: boolean;
  status?: string;
}

function ScheduleProfessionalConfirmSMS({
  step,
  bookingConfirmationInfoSMS,
  confirmBookingSMS,
  promtpConfirmBookingSMSModal,
  id,
  token,
  startDate,
  professionalName,
  isModalOpened,
  isLoading,
  status
}: Props) {
  const [stepConfirmation, setStepConfirmation] = useState('initial')
  const startDateString = startDate && moment(startDate).format('DD/MM')
  const startHourString = startDate && formatToHours(startDate)
  useEffect(() => {
    bookingConfirmationInfoSMS(id, token)
  }, [])
  useEffect(() => {
    if (status && (status === 'NoShow' || status === 'Done')) {
      setStepConfirmation('already-confirmed')
    }
  }, [status])
  useEffect(() => {
    if (step === 'just-confirmed') {
      setStepConfirmation('just-confirmed')
    }
  }, [step])
  const onConfirmNoShowSchedule = () => {
    confirmBookingSMS(id, token, 'Professional', 'NoShow')
  }

  function confirmBooking() {
    promtpConfirmBookingSMSModal()
  }
  function getConfirmMainText() {
    switch (stepConfirmation) {
      case 'initial':
        return `A consulta do dia ${startDateString} às ${startHourString} foi realizada?`
      case 'just-confirmed':
        return `Ok, ${professionalName}, obrigada pela confirmação!`
      case 'already-confirmed':
        return `Olá ${professionalName}, a realização dessa consulta já foi confirmada.`
      default:
        return `A consulta do dia ${startDateString} às ${startHourString} foi realizada?`
    }
  }

  return (
    <>
      {isModalOpened && <ScheduleConfirmationSmsModal />}
      <div className="ml-4 mt-6 lg:ml-20">
        <LogoHorizontal className="logo-schedule-confirm" />
      </div>
      {startDateString === undefined || isLoading
        ? (
          <div className="flex justify-center w-full">
            <div className="flex items-center">
              <Loader />
            </div>
          </div>
        )
        : (
          <div className="schedule-confirm pt-4">
            <div className="icon-welcome">
              <img src={MainIllustration} alt="ilustração Theia" />
            </div>
            {stepConfirmation === 'initial'
              && (
              <p className="font-medium text-headlineMedium text-primaryDark mt-12 mx-auto mb-6 text-center text-confirm">
                Olá,
                {' '}
                {professionalName}
                !
              </p>
              )}
            <p className={`${stepConfirmation === 'initial' ? ' mt-6' : 'mt-12'} font-medium text-headlineMedium text-primaryDark mx-auto mb-6 text-center text-confirm`}>
              {getConfirmMainText()}
            </p>
            {stepConfirmation === 'initial'
              ? (
                <p className="font-light text-primaryDark text-textMedium text-center text-confirm">
                  Essa informação serve como confirmação da consulta!
                </p>
              )
              : (
                <p className="font-light text-primaryDark text-textMedium text-center text-confirm">
                  Qualquer dúvida que houver, pode nos contatar pelo email
                  {' '}
                  <a href="mailto:especialista@theia.com.br">especialista@theia.com.br</a>
                </p>
              )}
            <div className={`${stepConfirmation === 'initial' ? 'full-container' : 'hidden'} confirm-button-container flex flex-col lg:flex-row items-center mt-8 mb-8`}>
              <RegularButton
                type="button"
                label="Sim, foi realizada"
                onClick={confirmBooking}
                maxWidth="100%"
              />
              {stepConfirmation === 'initial'
              && (
                <RegularButton
                  type="button"
                  label="A paciente não compareceu"
                  onClick={onConfirmNoShowSchedule}
                  variant="secondary"
                  maxWidth="100%"
                  extraClass="font-medium md:ml-4 my-6 md:my-0"
                />
              )}
            </div>
          </div>
        )}
    </>
  )
}

const mapStateToProps = ({ scheduleConfirmationSMS }: AppState) => ({
  startDate: scheduleConfirmationSMS.startDate,
  professionalName: scheduleConfirmationSMS.professionalName,
  isLoading: scheduleConfirmationSMS.isLoading,
  isModalOpened: scheduleConfirmationSMS.isModalOpened,
  confirmationAvailable: scheduleConfirmationSMS.confirmationAvailable,
  status: scheduleConfirmationSMS.status,
  step: scheduleConfirmationSMS.step
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  bookingConfirmationInfoSMS: bookingProfessionalConfirmationInfoSMSAction,
  confirmBookingSMS: confirmBookingSMSAction,
  promtpConfirmBookingSMSModal: promtpConfirmBookingSMSModalAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleProfessionalConfirmSMS)
