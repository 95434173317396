import { Retrier } from '@twilio/operation-retrier';
import { Transport } from 'twilsock';
import { Configuration } from '../configuration';

interface CacheEntry {
  response: Object;
  timestamp: number;
}

export interface NetworkServices {
  transport: Transport;
}

class Network {
  private readonly cacheLifetime: number;
  private readonly cache: Map<string, CacheEntry>;
  private timer: any;

  constructor(
    private readonly configuration: Configuration,
    private readonly services: NetworkServices,
  ) {
    this.cache = new Map<string, CacheEntry>();
    this.cacheLifetime = this.configuration.httpCacheInterval * 100;
    this.cleanupCache();
  }

  private isExpired(timestamp: number): boolean {
    return !this.cacheLifetime || (Date.now() - timestamp) > this.cacheLifetime;
  }

  private cleanupCache() {
    for (let [k, v] of this.cache) {
      if (this.isExpired(v.timestamp)) {
        this.cache.delete(k);
      }
    }

    if (this.cache.size === 0) {
      clearInterval(this.timer);
    }
  }

  pokeTimer() {
    this.timer = this.timer || setInterval(() => this.cleanupCache(), this.cacheLifetime * 2);
  }

  private async executeWithRetry(request, retryWhenThrottled = false): Promise<any> {
    return new Promise((resolve, reject) => {
      let codesToRetryOn = [502, 503, 504];
      if (retryWhenThrottled) {
        codesToRetryOn.push(429);
      }

      let retrier = new Retrier(this.configuration.backoffConfiguration);
      retrier.on('attempt', () => {
        request()
          .then(result => retrier.succeeded(result))
          .catch(err => {
            if (codesToRetryOn.indexOf(err.status) > -1) {
              retrier.failed(err);
            } else if (err.message === 'Twilsock disconnected') {
              // Ugly hack. We must make a proper exceptions for twilsock
              retrier.failed(err);
            } else {
              // Fatal error
              retrier.removeAllListeners();
              retrier.cancel();
              reject(err);
            }
          });
      });

      retrier.on('succeeded', result => { resolve(result); });
      retrier.on('cancelled', err => reject(err));
      retrier.on('failed', err => reject(err));

      retrier.start();
    });
  }

  public async get(url: string) {
    let cacheEntry = this.cache.get(url);
    if (cacheEntry && !this.isExpired(cacheEntry.timestamp)) {
      return cacheEntry.response;
    }

    const headers = {};
    let response = await this.executeWithRetry(
      () => this.services.transport.get(url, headers, this.configuration.productId), this.configuration.retryWhenThrottled);
    this.cache.set(url, { response, timestamp: Date.now() });
    this.pokeTimer();
    return response;
  }
}

export { Network };
