import React from 'react'
import { Tag } from '../../domain/Content'

const Abacate = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/abacate.png'
const Abacaxi = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/abacaxi.png'
const Abobora = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/abobora.png'
const AboboraCabotia = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/abobora_cabotia.png'
const AboboraDagua = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/abobora_dagua.png'
const AboboraGigante = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/abobora_gigante.png'
const AboboraMoranga = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/abobora_moranga.png'
const Alface = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/alface.png'
const Ameixa = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/ameixa.png'
const AmeixaSeca = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/ameixa_seca.png'
const Azeitona = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/azeitona.png'
const Banana = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/banana.png'
const BatataDoce = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/batata_doce.png'
const Beringela = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/beringela.png'
const Brocolis = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/brocolis.png'
const Cebola = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/cebola.png'
const Coco = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/coco.png'
const Couve = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/couve.png'
const Couveflor = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/couveflor.png'
const Ervilha = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/ervilha.png'
const Feijao = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/feijao.png'
const Framboesa = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/framboesa.png'
const Laranja = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/laranja.png'
const Lima = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/lima.png'
const LimaoSiciliano = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/limao_siciliano.png'
const Mamao = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/mamao.png'
const Manga = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/manga.png'
const Melancia = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/melancia.png'
const Melao = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/melao.png'
const MelaoAmarelo = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/melao_amarelo.png'
const MelaoPeleDeSapo = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/melao_pele_de_sapo.png'
const Pepino = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/pepino.png'
const Pessego = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/pessego.png'
const Roma = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/roma.png'
const SementeMaca = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/semente_maca.png'
const Toranja = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/toranja.png'
const Lentilha = 'https://theia-all-assets.s3.amazonaws.com/fruits-weeks/lentilha.png'

export function getPostsSlugByWeek(weekName: string) {
  switch (weekName) {
    case 'Pré-gestação':
      return 'pre-gestacao'
    case 'Pós-parto':
      return 'pos-parto'
    default:
      return `gravidez-semana-${weekName}`;
  }
}

export function hasTagOrSlug(tags: Tag[], tagName: string) {
  const filter = tags.filter((tag) => tag.name === tagName || tag.slug === tagName)
  return filter.length > 0
}

export function normalizeStringToSlug(str: string): string {
  return str.toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-')
}

export function getFruitInfos(weekName: string) {
  switch (weekName) {
    case '4':
      return {
        title: 'Seu bebê está do tamanho de uma Lentilha',
        picture: <img alt="" width={45} height={45} src={Lentilha} />
      }
    case '5':
      return {
        title: 'Seu bebê está do tamanho de uma Semente de Maçã',
        picture: <img alt="" width={45} height={45} src={SementeMaca} />
      }
    case '6':
      return {
        title: 'Seu bebê está do tamanho de uma Ervilha',
        picture: <img alt="" width={45} height={45} src={Ervilha} />
      }
    case '7':
      return {
        title: 'Seu bebê está do tamanho de um Grão de Feijão',
        picture: <img alt="" width={45} height={45} src={Feijao} />
      }
    case '8':
      return {
        title: 'Seu bebê está do tamanho de uma Framboesa',
        picture: <img alt="" width={45} height={45} src={Framboesa} />
      }
    case '9':
      return {
        title: 'Seu bebê está do tamanho de uma Azeitona',
        picture: <img alt="" width={45} height={45} src={Azeitona} />
      }
    case '10':
      return {
        title: 'Seu bebê está do tamanho de uma Ameixa Seca',
        picture: <img alt="" width={45} height={45} src={AmeixaSeca} />
      }
    case '11':
      return {
        title: 'Seu bebê está do tamanho de uma Lima',
        picture: <img alt="" width={45} height={45} src={Lima} />
      }
    case '12':
      return {
        title: 'Seu bebê está do tamanho de uma Ameixa',
        picture: <img alt="" width={45} height={45} src={Ameixa} />
      }
    case '13':
      return {
        title: 'Seu bebê está do tamanho de um Pêssego',
        picture: <img alt="" width={45} height={45} src={Pessego} />
      }
    case '14':
      return {
        title: 'Seu bebê está do tamanho de um Limão Siciliano',
        picture: <img alt="" width={45} height={45} src={LimaoSiciliano} />
      }
    case '15':
      return {
        title: 'Seu bebê está do tamanho de uma Laranja',
        picture: <img alt="" width={45} height={45} src={Laranja} />
      }
    case '16':
      return {
        title: 'Seu bebê está do tamanho de um Abacate',
        picture: <img alt="" width={45} height={45} src={Abacate} />
      }
    case '17':
      return {
        title: 'Seu bebê está do tamanho de uma Cebola',
        picture: <img alt="" width={45} height={45} src={Cebola} />
      }
    case '18':
      return {
        title: 'Seu bebê está do tamanho de uma Batata Doce',
        picture: <img alt="" width={45} height={45} src={BatataDoce} />
      }
    case '19':
      return {
        title: 'Seu bebê está do tamanho de uma Manga',
        picture: <img alt="" width={45} height={45} src={Manga} />
      }
    case '20':
      return {
        title: 'Seu bebê está do tamanho de uma Banana',
        picture: <img alt="" width={45} height={45} src={Banana} />
      }
    case '21':
      return {
        title: 'Seu bebê está do tamanho de um Romã',
        picture: <img alt="" width={45} height={45} src={Roma} />
      }
    case '22':
      return {
        title: 'Seu bebê está do tamanho de um Mamão',
        picture: <img alt="" width={45} height={45} src={Mamao} />
      }
    case '23':
      return {
        title: 'Seu bebê está do tamanho de uma Toranja',
        picture: <img alt="" width={45} height={45} src={Toranja} />
      }
    case '24':
      return {
        title: 'Seu bebê está do tamanho de um Melão',
        picture: <img alt="" width={45} height={45} src={Melao} />
      }
    case '25':
      return {
        title: 'Seu bebê está do tamanho de um Brócolis',
        picture: <img alt="" width={45} height={45} src={Brocolis} />
      }
    case '26':
      return {
        title: 'Seu bebê está do tamanho de uma Alface',
        picture: <img alt="" width={45} height={45} src={Alface} />
      }
    case '27':
      return {
        title: 'Seu bebê está do tamanho de uma Couve-Flor',
        picture: <img alt="" width={45} height={45} src={Couveflor} />
      }
    case '28':
      return {
        title: 'Seu bebê está do tamanho de uma Berinjela',
        picture: <img alt="" width={45} height={45} src={Beringela} />
      }
    case '29':
      return {
        title: 'Seu bebê está do tamanho de uma Abóbora Cabotiá',
        picture: <img alt="" width={45} height={45} src={AboboraCabotia} />
      }
    case '30':
      return {
        title: 'Seu bebê está do tamanho de um Pepino',
        picture: <img alt="" width={45} height={45} src={Pepino} />
      }
    case '31':
      return {
        title: 'Seu bebê está do tamanho de um Abacaxi',
        picture: <img alt="" width={45} height={45} src={Abacaxi} />
      }
    case '32':
      return {
        title: 'Seu bebê está do tamanho de uma Abóbora',
        picture: <img alt="" width={45} height={45} src={Abobora} />
      }
    case '33':
      return {
        title: 'Seu bebê está do tamanho de uma Couve',
        picture: <img alt="" width={45} height={45} src={Couve} />
      }
    case '34':
      return {
        title: 'Seu bebê está do tamanho de uma Moranga',
        picture: <img alt="" width={45} height={45} src={AboboraMoranga} />
      }
    case '35':
      return {
        title: 'Seu bebê está do tamanho de um Coco',
        picture: <img alt="" width={45} height={45} src={Coco} />
      }
    case '36':
      return {
        title: 'Seu bebê está do tamanho de um Melão Amarelo',
        picture: <img alt="" width={45} height={45} src={MelaoAmarelo} />
      }
    case '37':
      return {
        title: 'Seu bebê está do tamanho de uma Abóbora d\'água',
        picture: <img alt="" width={45} height={45} src={AboboraDagua} />
      }
    case '38':
      return {
        title: 'Seu bebê está do tamanho de um Melão pele-de-sapo',
        picture: <img alt="" width={45} height={45} src={MelaoPeleDeSapo} />
      }
    case '39':
      return {
        title: 'Seu bebê está do tamanho de uma Abóbora Gigante',
        picture: <img alt="" width={45} height={45} src={AboboraGigante} />
      }
    case '40':
      return {
        title: 'Seu bebê está do tamanho de uma Melancia',
        picture: <img alt="" width={45} height={45} src={Melancia} />
      }
    default:
      return undefined;
  }
}
