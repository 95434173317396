/* eslint-disable react/jsx-props-no-spreading */
import React, { HTMLAttributes } from 'react'
import { FontIcon } from '../FontIcon/FontIcon'
import { BaseButton } from '../BaseButton/BaseButton'
import './IconButton.scss'

export interface Props extends HTMLAttributes<HTMLButtonElement> {
  variant: 'primary' | 'secondary' | 'subtle' | 'text' | 'dark' | 'icon' | 'form-icon';
  isSubmitting?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>
  ) => void;
  isDisabled?: boolean;
  width?: string;
  height?: string;
  iconType?: string;
  iconSize?: string;
  iconColor?: string;
  type?: 'button' | 'submit' | 'reset';
  extraClass?: string;
  isMounting?: boolean;
}

export function IconButton({
  variant,
  isSubmitting,
  onClick,
  isDisabled,
  width,
  height,
  iconType,
  iconSize,
  iconColor,
  type,
  extraClass,
  isMounting,
  ...props
}: Props) {
  return (
    <BaseButton
      className={`
        ${isMounting ? 'icon__btn--placeholder' : 'icon-btn-material'}
        ${extraClass || ''}
        ${variant}
        ${isDisabled ? 'disabled' : ''}
      `}
      borderRadius="50%"
      height={height || '54px'}
      onClick={onClick}
      disabled={isSubmitting || isDisabled}
      width={width || '54px'}
      type={type}
      {...props}
    >
      {isSubmitting ? (
        <FontIcon
          iconType="icon-LoadingLight"
          animation="spin 2s linear infinite"
          fontSize={iconSize}
          color={iconColor}
        />
      ) : (
        <FontIcon
          iconType={iconType}
          fontSize={iconSize}
          color={iconColor}
        />
      )}
    </BaseButton>
  )
}
