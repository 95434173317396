import React, { useEffect } from 'react'
import FocusLock from 'react-focus-lock'
import { RegularButton, Loader } from 'theia-web-ds'
import './ResponsiveSplitModal.scss'

interface Props {
  children: React.ReactNode;
  visible: boolean;
  lastIdFocused?: string;
  ariaLabelledby: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
  isLoading?: boolean;
  showDesktopCloseButton?: boolean;
  showMobileCloseButton?: boolean;
  desktopCloseButtonText?: string;
  mobileCloseButtonText?: string;
  desktopCloseButtonAction?: () => void;
  mobileCloseButtonAction?: () => void;
  onClose: () => void;
}

export default function ResponsiveSplitModal({
  children,
  visible,
  lastIdFocused,
  ariaLabelledby,
  desktopImageUrl,
  mobileImageUrl,
  isLoading,
  showDesktopCloseButton,
  showMobileCloseButton,
  desktopCloseButtonText,
  mobileCloseButtonText,
  desktopCloseButtonAction,
  mobileCloseButtonAction,
  onClose,
}: Props) {
  function onCloseAndRefocused() {
    if (lastIdFocused !== undefined && lastIdFocused !== '') {
      if (document.getElementById(lastIdFocused)) {
        (document.getElementById(lastIdFocused) as any).focus()
      }
    }
    onClose()
  }

  function onKeyDown(event: KeyboardEvent) {
    if (event && event.key === 'Escape' && visible) {
      onCloseAndRefocused()
    }
  }

  useEffect(() => document.removeEventListener('keydown', onKeyDown, false))

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'
      document.addEventListener('keydown', onKeyDown, false);
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [visible])

  function handleClickDesktopCloseButton() {
    onCloseAndRefocused()
    if (desktopCloseButtonAction) {
      desktopCloseButtonAction()
    }
  }

  function handleClickMobileCloseButton() {
    onCloseAndRefocused()
    if (mobileCloseButtonAction) {
      mobileCloseButtonAction()
    }
  }

  return (
    visible ? (
      <FocusLock className="responsive-split-modal__container">
        <div
          className="responsive-split-modal__overlay"
          onClick={onCloseAndRefocused}
          aria-hidden="true"
          role="dialog"
          data-testid="modal-overlay"
        />
        <div
          role="dialog"
          className="responsive-split-modal__accessible-modal"
          aria-modal
          aria-labelledby={ariaLabelledby}
          tabIndex={-1}
          data-testid="accessible-modal"
        >
          <div className="responsive-split-modal__card">
            {isLoading ? (
              <div className="flex justify-center items-center h-full w-full">
                <Loader size="35px" />
              </div>
            ) : (
              <>
                {desktopImageUrl && (
                  <img
                    src={desktopImageUrl}
                    alt={desktopImageUrl}
                    className="hidden md:block"
                  />
                )}
                {mobileImageUrl && (
                  <img
                    src={mobileImageUrl}
                    alt={mobileImageUrl}
                    className="block md:hidden"
                  />
                )}
                {showDesktopCloseButton && (
                  <RegularButton
                    aria-label={desktopCloseButtonText || 'Fechar'}
                    variant="modal-btn"
                    width="auto"
                    btnSize="small"
                    iconType="icon-CloseLight"
                    iconSize="16px"
                    data-dismiss="modal"
                    onClick={handleClickDesktopCloseButton}
                    label={desktopCloseButtonText}
                    labelSize="14px"
                    direction="flex-row-reverse"
                    extraClass="desktop-close-button"
                  />
                )}
                {showMobileCloseButton && (
                  <RegularButton
                    aria-label={mobileCloseButtonText || 'Fechar'}
                    variant="modal-btn"
                    width="auto"
                    btnSize="small"
                    iconType="icon-CloseLight"
                    iconSize="16px"
                    data-dismiss="modal"
                    onClick={handleClickMobileCloseButton}
                    label={mobileCloseButtonText}
                    labelSize="14px"
                    direction="flex-row-reverse"
                    extraClass="mobile-close-button"
                  />
                )}
                <div className="responsive-split-modal__children-content">
                  {children}
                </div>
              </>
            )}
          </div>
        </div>
      </FocusLock>
    ) : null
  )
}
