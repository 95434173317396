import React from 'react'
import { ErrorMessage, IconButton } from 'theia-web-ds'
import { textPrimary } from '../../../../color'
import './FormInputList.scss'
import '../../Input.scss'

interface Props {
  label?: string;
  list: string[];
  onChange: (response: string[]) => void;
  errors?: {
    [index: number]: string;
  };
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  ariaLabel?: string;
}

export default function FormInputList({
  label,
  list,
  onChange,
  errors,
  required = false,
  placeholder,
  disabled,
  ariaLabel
}: Props) {
  function onAddNewElement() {
    const newResponseList = [...list, '']
    onChange(newResponseList)
  }

  function onRemoveElement(selectedIndex: number) {
    let newResponseList;
    if (list.length === 1) {
      newResponseList = ['']
    } else {
      newResponseList = list.filter((element, index) => index !== selectedIndex)
    }
    onChange(newResponseList)
  }

  function onUpdateValue(newContent: string, index: number) {
    const newResponseList = list.map((response, currentIndex) => (
      currentIndex === index ? newContent : response
    ))
    onChange(newResponseList)
  }

  return (
    <div
      className="form-input-list-container input-group"
      aria-required={required}
      aria-labelledby={label}
      aria-label={ariaLabel}
    >
      {label && <label className="label-text" id={label}>{label}</label>}
      {
        list.map((r, index) => (
          <div key={`input-list-container-${index}`} className="input-list-item-container">
            <div className="new-input-list-container">
              <input
                id={`input-list-${index}`}
                autoComplete="off"
                className={`input ${errors && errors[index] ? 'error' : ''}`}
                type="text"
                name={`input-list-${index}`}
                placeholder={placeholder || 'placeholder'}
                onChange={(e) => onUpdateValue(e.target.value, index)}
                value={r}
                disabled={disabled}
              />
              <div className="input-list-buttons-container">
                <IconButton
                  aria-label="Remover"
                  variant="text"
                  width="44px"
                  height="44px"
                  type="button"
                  iconType="icon-MinusLight"
                  iconColor={textPrimary}
                  iconSize="22px"
                  onClick={() => onRemoveElement(index)}
                  isDisabled={disabled}
                />
                <IconButton
                  aria-label="Adicionar"
                  variant="text"
                  width="44px"
                  height="44px"
                  type="button"
                  iconType="icon-PlusLight"
                  iconColor={textPrimary}
                  iconSize="22px"
                  onClick={onAddNewElement}
                  isDisabled={disabled}
                />
              </div>
            </div>
            {errors && errors[index] && (
              <ErrorMessage error={errors && errors[index]} />
            )}
          </div>
        ))
      }
    </div>
  )
}
