import React, { useEffect, useState } from 'react'
import { FontIcon, FormInput } from 'theia-web-ds';
import { eventTrack } from '../../../eventGenerate'
import { success, error as errorColor } from '../../color';
import { passwordSpecialCharacters } from '../../utils/helpers';

interface Props {
  password: string;
  error?: any;
  passwordLabel?: string;
  value?: string;
  eventText: string;
  errorMessage?: string;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onValidateField: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ValidatePasswordInput({
  password, error, passwordLabel, onBlur, eventText, onValidateField, onChange, value, errorMessage
}: Props) {
  const [isMinChar, setIsMinChar] = useState(false)
  const [isUpper, setIsUpper] = useState(false)
  const [isLower, setIsLower] = useState(false)
  const [isNumber, setIsNumber] = useState(false)
  const [isSpecialChar, setIsSpecialChar] = useState(false)
  const [displayMessage, setDisplayMessage] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  function validarSenha(senha: string) {
    if (senha?.length < 8) {
      setIsMinChar(false)
    } else {
      setIsMinChar(true)
    }
    if (!/[A-Z]/.test(senha)) {
      setIsUpper(false)
    } else {
      setIsUpper(true)
    }
    if (!/[a-z]/.test(senha)) {
      setIsLower(false)
    } else {
      setIsLower(true)
    }
    if (!/[0-9]/.test(senha)) {
      setIsNumber(false)
    } else {
      setIsNumber(true)
    }
    if (!passwordSpecialCharacters.test(senha)) {
      setIsSpecialChar(false)
    } else {
      setIsSpecialChar(true)
    }
  }
  const setFocused = () => {
    setIsFocused(true)
  }

  useEffect(() => {
    if (password !== undefined || password !== '') {
      validarSenha(password)
    } else {
      setIsMinChar(false)
      setIsUpper(false)
      setIsLower(false)
      setIsNumber(false)
      setIsSpecialChar(false)
    }
  }, [password])

  useEffect(() => {
    if (error !== undefined && isFocused) {
      setDisplayMessage(true)
    } else {
      setDisplayMessage(false)
    }
  }, [error, isFocused])

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    eventTrack(eventText)
    if (error === undefined) {
      setIsFocused(false)
    }
    onBlur(e)
  }

  useEffect(() => {
    onValidateField()
  }, [])

  return (
    <>
      <FormInput
        label={passwordLabel}
        placeholder="Senha com mais de 8 caracteres"
        name="password"
        id="password"
        onChange={onChange}
        onBlur={handleBlur}
        iconSize="24px"
        errorMessage={errorMessage}
        maxLength={64}
        value={value}
        isPassword
        onFocus={setFocused}
      />
      {displayMessage && (
        <div className="mt-2 text-fontExtraSmall text-error">
          <p className={`${isMinChar && 'text-success'} flex items-center`}>
            <FontIcon
              iconType={`${isMinChar ? 'icon-TickLight' : 'icon-CloseLight'}`}
              fontSize="14px"
              color={`${isMinChar ? success : errorColor}`}
              extraClass="mr-1"
            />
            Pelo menos 8 caracteres
          </p>
          <p className={`${isUpper && 'text-success'} flex items-center`}>
            <FontIcon
              iconType={`${isUpper ? 'icon-TickLight' : 'icon-CloseLight'}`}
              fontSize="14px"
              color={`${isUpper ? success : errorColor}`}
              extraClass="mr-1"
            />
            Letras maiúsculas
          </p>
          <p className={`${isLower && 'text-success'} flex items-center`}>
            <FontIcon
              iconType={`${isLower ? 'icon-TickLight' : 'icon-CloseLight'}`}
              fontSize="14px"
              color={`${isLower ? success : errorColor}`}
              extraClass="mr-1"
            />
            Letras minúsculas
          </p>
          <p className={`${isNumber && 'text-success'} flex items-center`}>
            <FontIcon
              iconType={`${isNumber ? 'icon-TickLight' : 'icon-CloseLight'}`}
              fontSize="14px"
              color={`${isNumber ? success : errorColor}`}
              extraClass="mr-1"
            />
            Números
          </p>
          <p className={`${isSpecialChar && 'text-success'} flex items-center`}>
            <FontIcon
              iconType={`${isSpecialChar ? 'icon-TickLight' : 'icon-CloseLight'}`}
              fontSize="14px"
              color={`${isSpecialChar ? success : errorColor}`}
              extraClass="mr-1"
            />
            Pelo menos um caractere especial (!@#?,etc...)
          </p>
        </div>
      )}
    </>
  )
}
