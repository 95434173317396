import ApiClient from './ApiClient'
import { Platform, PlatformProvider } from '../../domain/Platform'

export default class ApiPlatformProvider implements PlatformProvider {
  constructor(private apiClient: ApiClient) { }

  async getPlatform(): Promise<Platform> {
    const platform = await this.apiClient.getPlatform()
    return platform
  }
}
