import ApiClient from './ApiClient'

import { RescheduleService, ScheduleView } from '../../domain/Reschedule'

export default class ApiRescheduleService implements RescheduleService {
  constructor(private apiClient: ApiClient) { }

  reScheduleBooking(
    oldBookingId: string,
    availabilityId: string
  ): Promise<ScheduleView> {
    return this.apiClient.reScheduleBooking(oldBookingId, availabilityId)
  }
}
