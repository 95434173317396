/* eslint-disable no-prototype-builtins */
import { eventTrack } from '../../../eventGenerate'
import {
  HealthInsuranceCompany,
  HealthInsurancePlanData,
  HealthPlanData,
  HealthPlanFeedbackData
} from '../../domain/Healthplan'
import {
  REQUEST_HEALTH_INSURANCE_CIAS_PROFILE,
  SUCCESS_HEALTH_INSURANCE_CIAS_PROFILE,
  FAILURE_HEALTH_INSURANCE_CIAS_PROFILE,
  SELECT_HEALTH_INSURANCE_PROFILE,
  POST_HEALTH_INSURANCE_PROFILE,
  SUCCESS_POST_HEALTH_INSURANCE_PROFILE,
  FAILURE_POST_HEALTH_INSURANCE_PROFILE,
  GO_BACK_TO_PROFILE_HEALTH_PLAN_UPDATE,
  GET_HEALTH_INSURANCE_PLAN,
  SUCCESS_GET_HEALTH_INSURANCE_PLAN,
  FAILURE_GET_HEALTH_INSURANCE_PLAN,
  HealthPlanProfileTypes
} from './actions'

interface HealthplanState {
  isLoading: boolean;
  isSuccess: boolean;
  isSuccessFetchCompanies: boolean;
  benefits: any;
  insurancePlanCovered: boolean | null;
  healthInsurancePlanData: HealthInsurancePlanData;
  healthPlanFeedbackData?: HealthPlanFeedbackData;
  byCompanyName: {
    [companyName: string]: HealthInsuranceCompany;
  };
  companies?: HealthInsuranceCompany[];
  isSuccessDeleted: boolean;
  healthInsurancePlan: HealthPlanData | null;
}

const initialHealthplanProfileFlow: HealthplanState = {
  isLoading: false,
  isSuccess: false,
  isSuccessFetchCompanies: false,
  benefits: [{
  }],
  insurancePlanCovered: null,
  healthInsurancePlanData: {
    hasHealthInsurancePlan: null,
    healthInsurancePlan: null
  },
  healthPlanFeedbackData: undefined,
  byCompanyName: {},
  companies: undefined,
  isSuccessDeleted: false,
  healthInsurancePlan: null,
}

export function healthplan(
  state = initialHealthplanProfileFlow,
  action: HealthPlanProfileTypes
): HealthplanState {
  switch (action.type) {
    case SELECT_HEALTH_INSURANCE_PROFILE: {
      return {
        ...state,
        healthPlanFeedbackData: action.healthPlanFeedbackData,
      }
    }
    case REQUEST_HEALTH_INSURANCE_CIAS_PROFILE: {
      return {
        ...state,
        isLoading: true,
        isSuccessFetchCompanies: false,
        isSuccess: false
      }
    }
    case SUCCESS_HEALTH_INSURANCE_CIAS_PROFILE: {
      const allCompanies = action.companies.reduce(
        (byCompanyName: any, company) => {
          const newObj: any = byCompanyName
          if (!newObj.hasOwnProperty(company.company)) {
            newObj[company.company] = { company }
          } else {
            newObj[company.company] = {
              ...newObj[company.company],
              company
            }
          }
          return newObj
        }, {}
      )
      return {
        ...state,
        isLoading: false,
        isSuccessFetchCompanies: true,
        byCompanyName: allCompanies,
        companies: action.companies
      }
    }
    case FAILURE_HEALTH_INSURANCE_CIAS_PROFILE: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case POST_HEALTH_INSURANCE_PROFILE: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case SUCCESS_POST_HEALTH_INSURANCE_PROFILE: {
      let isSuccessDeleted: boolean
      const alreadyHasInsurancePlan = localStorage.getItem('healthPlanAdded')
      if (action.healthPlanData.hasHealthInsurancePlan === false) {
        isSuccessDeleted = true
      } else {
        isSuccessDeleted = false
      }
      if (isSuccessDeleted) {
        eventTrack('Removeu Plano')
      } else if (alreadyHasInsurancePlan) {
        eventTrack('Adicionou Plano')
      } else {
        eventTrack('Trocou Plano')
      }
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isSuccessDeleted,
        insurancePlanCovered: action.insurancePlanCovered,
        benefits: action.benefits,
        healthInsurancePlanData: action.healthPlanData
      }
    }
    case FAILURE_POST_HEALTH_INSURANCE_PROFILE: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case GO_BACK_TO_PROFILE_HEALTH_PLAN_UPDATE: {
      return {
        ...state,
        isSuccess: false,
        isSuccessDeleted: false
      }
    }
    case GET_HEALTH_INSURANCE_PLAN: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case SUCCESS_GET_HEALTH_INSURANCE_PLAN: {
      return {
        ...state,
        isLoading: false,
        healthInsurancePlan: action.healthInsurancePlan,
      }
    }
    case FAILURE_GET_HEALTH_INSURANCE_PLAN: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default: return state
  }
}
