/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, FormikProps } from 'formik'
import {
  RegularButton,
  Divider,
  ErrorMessage,
  FontIcon,
  FormInput,
  Loader,
  NewestCheckbox,
  MaskedFormInput
} from 'theia-web-ds'
import * as Yup from 'yup'

import { useHistory } from 'react-router-dom'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { isNotNullOrUndefined } from '../../utils/helpers'
import {
  ProfileForm,
  AddressForm
} from '../../domain/Profile'
import {
  fetchProfileAction,
  onUpdateAddressAction,
  onGoBackToProfileAfterUpdateAddressAction,
} from '../../state/profile/actions'
import { AddressByCep } from '../../utils/LocalAddress'
import { getAddressInfoAction } from '../../state/addressInfo/actions'
import { primary } from '../../color'
import Header from '../common/molecules/Header'
import { FaqToggle } from '../common/FaqToggle'
import './EditAddressForm.scss'
import { PROFILE } from '../../routes/RoutesConstants'

interface Props {
  profile?: ProfileForm;
  onUpdateAddress: (
    address: AddressForm
  ) => void;
  fetchProfile: () => void;
  isUpdatingProfile: boolean;
  isFetchingProfile: boolean;
  getAddressInfo: (cep: string) => void;
  addressFetchedFromCep?: AddressByCep;
  fullState: any;
  isAddressUpdated: boolean;
  onGoBackToProfileAfterUpdateAddress: () => void;
  isFetchindAddressData: boolean;
}

function renderEditProfileForm(
  {
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    errors,
    handleChange,
    touched,
    values
  }: FormikProps<any>,
  getAddressInfo: (cep: string) => void,
  onGoBackToProfileAfterUpdateAddress: () => void,
  fetchProfile: () => void,
  addressFetchedFromCep?: AddressByCep,
  fullState?: any,
  isUpdatingProfile?: boolean,
  isAddressUpdated?: boolean,
  isFetchindAddressData?: boolean
) {
  const [needChangeAddressInfos, setNeedChangeAddressInfos] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isNumberDisabled, setIsNumberDisabled] = useState(false)
  const [checkboxOutsideBrazil, setCheckboxOutsideBrazil] = useState(values.outsideBrazil)
  const [checkboxNumber, setCheckboxNumber] = useState(false)

  function handleDontKnowCep() {
    window.open('https://buscacepinter.correios.com.br/')
  }

  function onSearchCEP(e: any) {
    if (`${e.target.value}`.indexOf('_') === -1) {
      getAddressInfo(e.target.value)
      setNeedChangeAddressInfos(true)
      setFieldValue('postalCode', e.target.value)
      setFieldTouched('postalCode')
    }
  }

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (needChangeAddressInfos && addressFetchedFromCep) {
      setFieldValue('city', addressFetchedFromCep.localidade)
      setFieldTouched('city')
      setFieldValue('complement', addressFetchedFromCep.complemento)
      setFieldTouched('complement')
      timer = setTimeout(() => {
        setFieldValue('street', addressFetchedFromCep.logradouro)
        setFieldTouched('street')
        setFieldValue('district', addressFetchedFromCep.bairro)
        setFieldTouched('district')
      }, 500) as NodeJS.Timeout
      setNeedChangeAddressInfos(false)
      if (fullState) setFieldValue('stateName', fullState[0].nome)
      setFieldTouched('stateName')
      if (addressFetchedFromCep?.erro) {
        setFieldValue('city', '')
        setFieldValue('stateName', '')
        setFieldValue('street', '')
        setFieldValue('number', '')
        setFieldValue('complement', '')
        setFieldValue('district', '')
        setNeedChangeAddressInfos(false)
      }
    }
    return () => clearTimeout(timer)
  }, [addressFetchedFromCep])

  const history = useHistory()

  const onSubmit = () => {
    handleSubmit()
  }

  useEffect(() => {
    if (isAddressUpdated) {
      history.push(PROFILE)
      onGoBackToProfileAfterUpdateAddress()
      fetchProfile()
    }
  }, [isAddressUpdated])

  useEffect(() => {
    if (checkboxOutsideBrazil) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [checkboxOutsideBrazil])

  useEffect(() => {
    if (checkboxNumber || values.outsideBrazil) {
      setIsNumberDisabled(true)
    } else {
      setIsNumberDisabled(false)
    }
  }, [checkboxNumber, values.outsideBrazil])

  useEffect(() => {
    if (values.noHomeNumber === true && values.outsideBrazil === false) {
      setCheckboxNumber(true)
      setFieldValue('number', '')
      setFieldValue('noHomeNumber', true)
    }
  }, [])

  useEffect(() => {
    if (values.outsideBrazil === true) {
      setFieldValue('postalCode', '')
      setFieldValue('city', '')
      setFieldValue('stateName', '')
      setFieldValue('street', '')
      setFieldValue('number', '')
      setFieldValue('complement', '')
      setFieldValue('district', '')
    }
  }, [values.outsideBrazil])

  const checkboxCheck = () => {
    if (values.outsideBrazil === false) {
      setCheckboxOutsideBrazil(true)
      setFieldValue('outsideBrazil', true)
    }
    if (values.outsideBrazil === true) {
      setCheckboxOutsideBrazil(false)
      setFieldValue('outsideBrazil', false)
    }
  }

  const checkboxCheckHomeNumber = () => {
    if (values.noHomeNumber === false) {
      setCheckboxNumber(true)
      setFieldValue('number', '')
      setFieldValue('noHomeNumber', true)
    }
    if (values.noHomeNumber === true) {
      setCheckboxNumber(false)
      setFieldValue('number', '')
      setFieldValue('noHomeNumber', false)
    }
  }

  return (
    <div className="w-full flex flex-col">
      <Header
        title="Editar endereço"
        goBack={() => history.push(PROFILE)}
      />
      <Divider />
      <div className="address-form flex flex-1 relative">
        <div className="mx-auto w-full h-full">
          <div className="lg:max-w-xl mx-auto w-full">
            <div className="form-cep-input-container input-group">
              <FaqToggle
                toggleTitle="Porque é importante preencher seu endereço completo?"
                toggleDescription="Assim conseguimos saber saber quais médicos e especialistas atendem perto de você. Além disso, gostamos também de enviar brindes e lembranças pra você."
                iconType="icon-ChatHelpLight"
                extraDescriptionClass="py-4"
              />
              <div className="px-4 pt-3 lg:max-w-lg w-full mx-auto">
                <NewestCheckbox name="outsideBrazil" label="Moro fora do Brasil" value={values.outsideBrazil} defaultChecked={values.outsideBrazil} extraClassLabel="" hasDivider={false} onClick={checkboxCheck} />
                <div className="cep-container">
                  <div className="cep-input-container">
                    <MaskedFormInput
                      label="CEP"
                      name="postalCode"
                      id="postalCode"
                      onBlur={() => setFieldTouched('postalCode')}
                      errorMessage={touched.postalCode ? errors.postalCode?.toString() : ''}
                      type="text"
                      extraContainerClassName="input-group"
                      onChange={onSearchCEP}
                      value={values.postalCode}
                      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                      placeholder="00000-000"
                      disabled={isDisabled}
                    />
                    {isFetchindAddressData && (
                    <FontIcon
                      animation="spin 2s linear infinite"
                      iconType="icon-LoadingLight input-icon"
                      fontSize="20px"
                      color={primary}
                    />
                    )}
                  </div>
                  {addressFetchedFromCep?.erro && (
                    <ErrorMessage error="Informe um CEP válido para continuar" iconSize="12px" />
                  )}
                  <RegularButton
                    onClick={handleDontKnowCep}
                    label="Não sei meu CEP"
                    variant="text"
                    width="fit-content"
                    disabled={isDisabled}
                    extraClass="dont-know-cep-btn"
                  />
                </div>
                <div className="city-state-container">
                  <div className="state-container mb-4">
                    <FormInput
                      type="text"
                      label="Estado"
                      name="stateName"
                      placeholder="Adicione o CEP para preencher o Estado"
                      disabled
                      value={values.stateName}
                      id="stateName"
                    />
                  </div>
                  <div className="city-container mb-4">
                    <FormInput
                      type="text"
                      label="Cidade"
                      onChange={handleChange}
                      value={values.city}
                      name="city"
                      placeholder="Adicione o CEP para preencher a Cidade"
                      disabled
                      id="city"
                    />
                  </div>
                </div>
                <div className="district-container mb-4">
                  <FormInput
                    type="text"
                    label="Bairro"
                    name="district"
                    value={values.district}
                    errorMessage={
                      touched.district ? errors.district?.toString() : ''
                    }
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('district')}
                    placeholder="Ex.: Perdizes"
                    disabled={isDisabled}
                    id="district"
                    required
                  />
                </div>
                <div className="name-container mb-4">
                  <FormInput
                    id="street"
                    type="text"
                    label="Rua/Avenida"
                    value={values.street}
                    errorMessage={
                      touched.street ? errors.street?.toString() : ''
                    }
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('street')}
                    name="street"
                    placeholder="Ex.: Av. Paulista"
                    disabled={isDisabled}
                    required
                  />
                </div>
                <div className="number-complement-container">
                  <div className="number-container mb-4">
                    <FormInput
                      type="text"
                      label="Número"
                      value={values.number}
                      errorMessage={
                        touched.number ? errors.number?.toString() : ''
                      }
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('number')}
                      id="number"
                      name="number"
                      disabled={isDisabled || isNumberDisabled}
                    />
                    <NewestCheckbox
                      name="noHomeNumber"
                      label="Sem número"
                      value={values.noHomeNumber}
                      defaultChecked={values.noHomeNumber}
                      extraClassLabel=""
                      hasDivider={false}
                      onClick={checkboxCheckHomeNumber}
                      disabled={values.outsideBrazil}
                    />
                  </div>
                  <div className="complement-container mb-4">
                    <FormInput
                      type="text"
                      value={values.complement}
                      errorMessage={
                        touched.complement ? errors.complement?.toString() : ''
                      }
                      onChange={handleChange}
                      onBlur={() => setFieldTouched('complement')}
                      id="complement"
                      label="Complemento (opcional)"
                      name="complement"
                      placeholder="Andar/Apartamento"
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="button-confirm w-full">
                  <RegularButton
                    isSubmitting={isUpdatingProfile}
                    disabled={false}
                    onClick={onSubmit}
                    label="Salvar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface PropsInitialValues {
  postalCode: string;
  city: string;
  stateName?: string;
  street: string;
  number: null | string;
  complement: string;
  district?: string;
  outsideBrazil?: boolean;
  noHomeNumber: boolean;
}

const initialValues: PropsInitialValues = {
  postalCode: '',
  city: '',
  stateName: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  outsideBrazil: false,
  noHomeNumber: false
}

const validationSchema = Yup.object().shape({
  number: Yup.string()
    .when(['outsideBrazil', 'noHomeNumber'], {
      is: (val1, val2) => val1 === true || val2 === true,
      then: Yup.string()
        .notRequired(),
      otherwise: Yup.string().required('Informe o número da sua rua ou selecione a opção "Sem número"'),
    }),
  district: Yup.string()
    .when(['outsideBrazil'], {
      is: (val) => val === true,
      then: Yup.string()
        .notRequired(),
      otherwise: Yup.string().required('Informe seu bairro para continuar'),
    }),
  street: Yup.string()
    .when(['outsideBrazil'], {
      is: (val) => val === true,
      then: Yup.string()
        .notRequired(),
      otherwise: Yup.string().required('Informe sua rua para continuar'),
    }),
  postalCode: Yup.string()
    .when('outsideBrazil', {
      is: (val) => val === true,
      then: Yup.string()
        .notRequired(),
      otherwise: Yup.string().required('Informe seu CEP para continuar'),
    })
})

function EditAddressForm({
  profile,
  onUpdateAddress,
  isUpdatingProfile,
  fetchProfile,
  isFetchingProfile,
  getAddressInfo,
  addressFetchedFromCep,
  fullState,
  isAddressUpdated,
  onGoBackToProfileAfterUpdateAddress,
  isFetchindAddressData,
}: Props) {
  const [uf, setUf] = useState('')

  useEffect(() => {
    if (profile === undefined) fetchProfile()
  }, [])

  useEffect(() => {
    if (profile) {
      if (addressFetchedFromCep) {
        setUf(addressFetchedFromCep.uf)
      }
    }
  }, [addressFetchedFromCep])

  if (
    isFetchingProfile
  ) {
    return (
      <div className="flex justify-center h-screen w-full bg-bgCanvas">
        <div className="flex items-center h-full">
          <Loader />
        </div>
      </div>
    )
  }

  if (profile) {
    if (profile.address) {
      initialValues.postalCode = (
        isNotNullOrUndefined(profile.address.postalCode)
          ? profile.address.postalCode
          : initialValues.postalCode
      )
      initialValues.city = (
        isNotNullOrUndefined(profile.address.cityName)
          ? profile.address.cityName
          : initialValues.city
      )
      initialValues.stateName = (
        isNotNullOrUndefined(profile.address.stateName)
          ? profile.address.stateName
          : initialValues.stateName
      )
      initialValues.street = (
        isNotNullOrUndefined(profile.address.name)
          ? profile.address.name
          : initialValues.street
      )
      initialValues.number = (
        isNotNullOrUndefined(profile.address.number)
          ? profile.address.number
          : initialValues.number
      )
      initialValues.complement = (
        isNotNullOrUndefined(profile.address.complement)
          ? profile.address.complement
          : initialValues.complement
      )
      initialValues.district = (
        isNotNullOrUndefined(profile.address.districtName)
          ? profile.address.districtName
          : initialValues.district
      )
      initialValues.outsideBrazil = (
        isNotNullOrUndefined(profile.address.outerCountry)
          ? profile.address.outerCountry
          : initialValues.outsideBrazil
      )
      initialValues.noHomeNumber = (
        profile.address.outerCountry === true
          ? false
          : profile.address.number === ''
      )
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(_value: any) => {
        onUpdateAddress(
          {
            name: _value.street,
            district: {
              name: _value.district,
              city: {
                name: _value.city,
                state: {
                  name: _value.stateName,
                  abbreviation: uf,
                },
              },
            },
            districtName: _value.district,
            stateName: _value.stateName,
            stateAbbreviation: uf,
            cityName: _value.city,
            number: _value.number,
            postalCode: _value.postalCode,
            complement: _value.complement,
            outerCountry: _value.outsideBrazil
          }
        )
      }}
    >
      {(props) => renderEditProfileForm(
        props,
        getAddressInfo,
        onGoBackToProfileAfterUpdateAddress,
        fetchProfile,
        addressFetchedFromCep,
        fullState,
        isUpdatingProfile,
        isAddressUpdated,
        isFetchindAddressData
      )}
    </Formik>
  )
}

const mapStateToProps = ({ profile, addressInfo }: AppState) => ({
  profile: profile.profile,
  isUpdatingProfile: profile.isUpdatingProfile,
  isFetchingProfile: profile.isFetchingProfile,
  addressFetchedFromCep: addressInfo.addressFetchedFromCep,
  isFetchindAddressData: addressInfo.isFetchindAddressData,
  fullState: addressInfo.fullState,
  isAddressUpdated: profile.isAddressUpdated
})
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onUpdateAddress: (
    address: AddressForm
  ) => {
    dispatch(onUpdateAddressAction(address))
  },
  fetchProfile: () => { dispatch(fetchProfileAction()) },
  getAddressInfo: (cep: string) => { dispatch(getAddressInfoAction(cep)) },
  onGoBackToProfileAfterUpdateAddress: () => {
    dispatch(onGoBackToProfileAfterUpdateAddressAction())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAddressForm)
