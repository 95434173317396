/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import { BookingView } from '../../../domain/Booking'
import { User } from '../../../domain/User'
import { LocalDate } from '../../../utils/LocalDate'
import { isNotNullOrUndefined } from '../../../utils/helpers';

export type ScheduleMetadata = {
  isScheduleMessage: boolean;
  professionalId?: string;
  professionalCategory?: string;
}

export function extractScheduleMetadata(text: string): ScheduleMetadata {
  const matchSpecialist = text.match(
    /^https?:\/\/app\.theia\.com\.br\/agendar(\/([\w-]+)|\?category=([\wÀ-ÖØ-öø-ÿ ()%]+))$/
  )
  const match = text.match(
    /^https?:\/\/app\.theia\.com\.br\/professionals(\/([\w-]+)|\?category=([\wÀ-ÖØ-öø-ÿ ()%]+))$/
  )

  const metadata: ScheduleMetadata = { isScheduleMessage: !!match }

  const metadataSpecialist: ScheduleMetadata = { isScheduleMessage: !!matchSpecialist }

  if (matchSpecialist) {
    [, ,
      metadataSpecialist.professionalId,
      metadataSpecialist.professionalCategory] = matchSpecialist
    return metadataSpecialist
  }

  if (match) {
    [, , metadata.professionalId, metadata.professionalCategory] = match
  }
  return metadata
}

export function parseBookingView({ booking }: { booking: any }): BookingView {
  return {
    id: booking.id,
    consultantName: booking.consultantName,
    professionalName: booking.professionalName,
    startDate: new LocalDate(booking.startDate),
    endDate: new LocalDate(booking.endDate),
    token: booking.token,
    tokenError: booking.tokenError,
    videoRoomName: booking.videoRoomName,
    chatToken: booking.chatToken
  }
}

export function parseUser(user: any): User {
  let nextPaymentDate = null
  if (user?.plan?.nextPaymentDate && user?.plan?.nextPaymentDate !== null) {
    nextPaymentDate = new LocalDate(user.plan.nextPaymentDate)
  }
  return {
    ...user,
    plan: {
      ...user.plan,
      nextPaymentDate
    }
  }
}

export const mountUrlParams = (filter: any = null) => {
  let idx = 0
  return filter && Object.keys(filter).reduce((acu, key) => {
    if (isNotNullOrUndefined(filter[key]) && filter[key] !== '') {
      acu = idx === 0 ? acu += '?' : acu += '&'
      idx++
      acu += `${key}=${filter[key]}`
    }
    return acu
  }, '')
}
