import React from 'react'
import { FontIcon } from '../FontIcon/FontIcon'
import { BadgeNotification } from '../BadgeNotification/BadgeNotification'
import { error } from '../../colors'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { Divider } from '../Divider/Divider'
import './NewListItem.scss'

export interface Props {
  ariaLabel: string;
  title?: string;
  showCaptionFirst?: boolean;
  onClick?: () => void;
  iconType?: string;
  iconColor?: string;
  iconSize?: string;
  arrowColor?: string;
  caption?: string;
  className?: string;
  id?: string;
  extraButton?: React.ReactNode;
  isLastItem?: boolean;
  tagComponent?: React.ReactNode;
  isRisked?: boolean;
  isOpen?: boolean;
  isOpenAndCloseButton?: boolean;
  hideIconButton?: boolean;
  picture?: React.ReactNode;
  alertMessage?: string;
  isLoading?: boolean;
  isItemFromList?: boolean;
  extraComponent?: React.ReactNode;
  anotherIcon?: string;
  extraTitleClass?: string;
  extraClassClickableContainer?: string;
  imageIconBadgeComponent?: React.ReactNode;
  hasIconBadge?: boolean;
  isNoHover?: boolean;
  captionColor?: string;
  responsiveTag?: boolean;
  titleSize?: 'text-fontDefault' | 'text-base' | 'text-xl';
  onKeyPress?: (e: React.KeyboardEvent) => void;
}

export function NewListItem({
  showCaptionFirst,
  isLastItem,
  onClick,
  iconType,
  iconColor,
  iconSize,
  arrowColor,
  title,
  ariaLabel,
  className,
  id,
  caption,
  extraButton,
  tagComponent,
  isRisked,
  isOpen,
  isOpenAndCloseButton,
  hideIconButton,
  picture,
  alertMessage,
  isItemFromList = true,
  isLoading,
  extraComponent,
  anotherIcon,
  extraTitleClass,
  extraClassClickableContainer,
  imageIconBadgeComponent,
  hasIconBadge,
  isNoHover,
  captionColor,
  responsiveTag = true,
  titleSize,
  onKeyPress
}: Props) {
  const isClickable = !!onClick
  const titleComponent = (
    <p
      className={`text-textPrimary list-item-title pb-1
        ${titleSize || 'text-fontDefault'}
        ${extraTitleClass}
        ${isRisked ? 'line-through' : ''}`}
    >
      {title}
    </p>
  )
  const captionComponent = (
    <p
      id={`${id}-caption`}
      className={`list-item-caption
        ${captionColor || 'text-textSecondary'}
        ${isRisked ? 'line-through' : ''}
      `}
    >
      {caption}
    </p>
  )

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if ((e.code === '32' || e.code === '13') && onClick) {
      onClick()
    }
  }

  const bodyComponent = (
    <>
      {imageIconBadgeComponent && (
        imageIconBadgeComponent
      )}
      {(iconType && hasIconBadge) && (
        <div className="list-item-icon-container" data-testid="icon-badge-container">
          <div className="relative">
            <FontIcon
              iconType={iconType}
              color={iconColor}
              fontSize={iconSize}
            />
            <BadgeNotification
              color={error}
              width={8}
              height={8}
              borderRadius="50%"
              extraClass="badge-outline absolute right-0"
            />
          </div>
        </div>
      )}
      {(iconType && !hasIconBadge) && (
        <div className="list-item-icon-container">
          <FontIcon
            iconType={iconType}
            color={iconColor}
            fontSize={iconSize}
          />
        </div>
      )}
      {picture}
      <div className="list-item-body-container">
        <div className="body-container">
          <div className="title-container">
            {showCaptionFirst ? (
              <>
                {captionComponent}
                {titleComponent}
              </>
            ) : (
              <>
                {titleComponent}
                {captionComponent}
              </>
            )}
            {tagComponent && (
              <div
                className={responsiveTag
                  ? 'tag-component-small'
                  : 'hidden'}
              >
                {tagComponent}
              </div>
            )}
            {extraButton}
            {alertMessage && (
              <ErrorMessage error={alertMessage} iconSize="12px" />
            )}
          </div>
          {tagComponent && (
            <div className={responsiveTag
              ? 'tag-component-large'
              : 'block'}
            >
              {tagComponent}
            </div>
          )}
          {extraComponent && (
            <div className="list-item-arrow">
              {extraComponent}
            </div>
          )}
          {isClickable && !hideIconButton && isOpenAndCloseButton && (
            <div className="list-item-arrow">
              {!isOpen ? (
                <FontIcon
                  iconType="icon-ArrowDown2Light"
                  color={arrowColor || iconColor}
                  fontSize="25px"
                />
              ) : (
                <FontIcon
                  iconType="icon-ArrowDown2Light"
                  color={arrowColor || iconColor}
                  fontSize="25px"
                />
              )}
            </div>
          )}
          {isClickable && !hideIconButton && !isOpenAndCloseButton && (
            <div className="list-item-arrow">
              <FontIcon
                iconType={anotherIcon || 'icon-ArrowRight2Light'}
                color={arrowColor || iconColor}
                fontSize="25px"
              />
            </div>
          )}
        </div>
        {!isLastItem && <Divider className="list-item-divider" />}
      </div>
    </>
  )

  if (isLoading) {
    if (extraButton) {
      return (
        <div className="new-list-loading-container" data-testid="new-list-loading-container">
          {picture ? (
            <div className="new-list-loading-picture-container" />
          ) : (
            <div className="new-list-loading-icon-container" />
          )}
          <div className="new-list-loading-itens-container">
            <div className="new-list-caption-loading" />
            <div className="new-list-title-loading" />
            <div className="new-list-extra-button-loading" />
          </div>
        </div>
      )
    }

    return (
      <div className="new-list-loading-container" data-testid="new-list-loading-container">
        {picture ? (
          <div className="new-list-loading-picture-container" />
        ) : (
          <div className="new-list-loading-icon-container" />
        )}
        <div className="new-list-loading-itens-container">
          <div className="new-list-caption-loading" />
          <div className="new-list-title-loading" />
        </div>
      </div>
    )
  }

  return isItemFromList ? (
    <li
      id={id}
      aria-label={ariaLabel}
      className={`w-full item-from-list ${className}`}
    >
      {isClickable ? (
        <button
          onClick={onClick}
          onKeyDown={onKeyPress || handleKeyDown}
          className={`list-item-container
            ${extraClassClickableContainer}
            ${isNoHover ? 'noHover' : ''}
          `}
          data-testid="new-list-item-button"
          type="button"
        >
          {bodyComponent}
        </button>
      ) : (
        <div
          className={`list-item-container
            ${isNoHover ? 'noHover' : ''}
          `}
        >
          {bodyComponent}
        </div>
      )}
    </li>
  ) : (
    <div
      id={id}
      aria-label={ariaLabel}
      className={`w-full ${className}`}
    >
      {isClickable ? (
        <button
          onClick={onClick}
          onKeyDown={onKeyPress || handleKeyDown}
          className={`list-item-container
            ${extraClassClickableContainer}
            ${isNoHover ? 'noHover' : ''}
          `}
          data-testid="new-list-item-button"
          type="button"
        >
          {bodyComponent}
        </button>
      ) : (
        <div
          className={`list-item-container
            ${extraClassClickableContainer}
            ${isNoHover ? 'noHover' : ''}
          `}
        >
          {bodyComponent}
        </div>
      )}
    </div>
  )
}
