import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../../apps/main/store'
import ScheduleCancellationReasonModal from './ScheduleCancellationReasonModal'
import ScheduleCancellationConfimationModal from './ScheduleCancellationConfimationModal'
import ScheduleCancellationSuccessModal from './ScheduleCancellationSuccessModal'
import ErrorModal from '../../common/ErrorModal'

interface Props {
  step: any;
  defaultErrorVisible: boolean;
}

function ScheduleCancellationModalsGroup({
  step, defaultErrorVisible
}: Props) {
  if (step === 'closed') return null

  return (
    <>
      {step === 'prompt' && <ScheduleCancellationReasonModal />}
      {(step === 'confirmation' || step === 'loading') && <ScheduleCancellationConfimationModal isSubmitting={step === 'loading'} />}
      {step === 'success' && <ScheduleCancellationSuccessModal />}
      {step === 'failure' && <ErrorModal visible={defaultErrorVisible} />}
    </>
  )
}

const mapStateToProps = ({ scheduleCancellation, errorWithFriendlyMessage }: AppState) => ({
  step: scheduleCancellation.step,
  defaultErrorVisible: errorWithFriendlyMessage.visible,
})

export default connect(
  mapStateToProps,
)(ScheduleCancellationModalsGroup)
