import React from 'react'

import './EmptyView.scss'
import { RegularButton } from 'theia-web-ds'

interface Props {
  title: string;
  subtitle: string;
  extraActionButton: () => void;
  extraActionText: string;
  goToSpecialist?: () => void;
  icon: React.ReactNode;
  hasGoToSpecialist?: boolean;
}

export default function EmptyView({
  title,
  subtitle,
  extraActionButton,
  extraActionText,
  goToSpecialist,
  icon,
  hasGoToSpecialist = true
}: Props) {
  return (
    <div className="empty-view-container">
      {icon}
      <p className="empty-title">{title}</p>
      <p className="empty-description">{subtitle}</p>
      {hasGoToSpecialist && (
        <RegularButton
          onClick={goToSpecialist}
          label="Agendar consulta"
          variant="primary"
          maxWidth="197px"
        />
      )}
      <RegularButton
        onClick={extraActionButton}
        label={extraActionText}
        variant="text"
        maxWidth="220px"
        extraClass="empty-last-button"
      />
    </div>
  )
}
