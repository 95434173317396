import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Loader } from 'theia-web-ds'
import MessageList from './MessageList'
import MessageComposer from './MessageComposer'
import { AppState } from '../../apps/main/store'
import './Chat.scss'
import { AppDispatch } from '../../state/utils'
import {
  onShowChatAction,
  connectChatVideoAction,
  logConnectChatVideoErrorAction,
  setUpdateTokenChatVideoAction
} from '../../state/chatVideo/actions'
import ChipButton from '../common/buttons/ChipButton'

interface ChatViewProps {
  onShowChat: () => void;
  connectChatVideo: (chatToken: string, id: string) => void;
  hasCurrentUser: boolean;
  connectChatError: boolean;
  connectingChat: boolean;
  logConnectChatVideoError: () => void;
  forceReconnect?: boolean;
  isFetchingMessages: boolean;
  chatToken: string;
  id: string;
  uuid: string;
  setUpdateTokenChatVideo: (uuid: string, isFromRetry?: boolean) => void;
  isUpdatingToken?: boolean;
}

const ChatVideo = React.memo(({
  onShowChat,
  hasCurrentUser,
  connectChatVideo,
  connectChatError = true,
  connectingChat,
  logConnectChatVideoError,
  forceReconnect,
  isFetchingMessages,
  chatToken,
  id,
  setUpdateTokenChatVideo,
  uuid,
  isUpdatingToken
}: ChatViewProps) => {
  useEffect(() => {
    if (forceReconnect && !connectingChat) {
      (async () => {
        await connectChatVideo(chatToken, id)
      })()
    }
  }, [forceReconnect])

  useEffect(() => {
    onShowChat()
  }, [])
  useEffect(() => {
    if (connectChatError) {
      logConnectChatVideoError()
      setUpdateTokenChatVideo(uuid)
    }
  }, [connectChatError])

  function updateToken() {
    if (!hasCurrentUser) return
    setUpdateTokenChatVideo(uuid, true)
  }

  return (
    <div className="chat-video flex flex-1 min-h-0 relative">
      <div className="mx-auto w-full mt-10 lg:max-w-xl">
        <div className="flex flex-col h-full rounded">
          {(isFetchingMessages || connectingChat) && (
            <div className="flex items-center h-full">
              <Loader />
            </div>
          )}
          {!connectChatError ? (
            <>
              <MessageList />
              <MessageComposer />
            </>
          ) : (
            <div className="flex flex-col w-full h-full items-center justify-center">
              <p className="connect-error">Ops, estamos com uma indisponibilidade de sistema.</p>
              <p className="connect-error pt-4">Atualize a página ou tente novamente mais tarde.</p>
              {isUpdatingToken
                ? <div className="flex items-center h-full"><Loader /></div>
                : (
                  <ChipButton
                    onClick={updateToken}
                    className="try-button"
                    disabledColor="white"
                    opacity={connectingChat ? '0.6' : '1'}
                  >
                    <span className="try-button-text">Tentar novamente</span>
                  </ChipButton>
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
})

const mapStateToProps = ({ booking, chatVideo }: AppState) => ({
  hasCurrentUser: !!booking.chatToken,
  connectChatError: chatVideo.connectChatError,
  connectingChat: chatVideo.connectingChat,
  isFetchingMessages: chatVideo.isFetchingMessages,
  forceReconnect: chatVideo.chatVideoService?.needForceReconnect() || chatVideo.forceReconnect,
  chatToken: booking.chatToken,
  id: booking.id,
  uuid: booking.uuid,
  updatedChatToken: chatVideo.chatToken,
  isUpdatingToken: chatVideo.isUpdatingToken
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onShowChat: onShowChatAction,
  connectChatVideo: connectChatVideoAction,
  logConnectChatVideoError: logConnectChatVideoErrorAction,
  setUpdateTokenChatVideo: setUpdateTokenChatVideoAction
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ChatVideo)
