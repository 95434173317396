import React, { useEffect, useState } from 'react'
import { RegularButton, AccessibleModal } from 'theia-web-ds'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AcceptHealthPlan from '../../../assets/PlanCard-Yes-2.svg'
import { AppDispatch } from '../../state/utils'
import { updateCurrentUserInfosAction } from '../../state/authentication/main/actions'
import { onPostHealthInsuranceAction, openModalAction, closeModalAction } from '../../state/onboarding/actions'
import { AppState } from '../../apps/main/store'
import { User } from '../../domain/User'
import { eventTrack } from '../../../eventGenerate'
import { listOfPlansAcceptedByTheia } from '../../utils/helpers'
import { CHANGE_HEALTH_PLAN } from '../../routes/RoutesConstants'
import './HealthPlanModal.scss'

interface Props {
  onUpdateCurrentUser: () => void;
  onPostHealthInsurance: (
    hasHealthInsurancePlan: boolean,
    company: null,
    planName: null,
    memberId: null,
    companyId: null,
    planId: null,
    benefitsTitle: null,
    benefitsSubtitle: null,
  ) => void;
  currentUser?: User | null;
  openModal: () => void;
  isModal?: boolean;
  closeModal: () => void;
  isUserFetched?: boolean;
  shouldEnableModal?: boolean;
}

type ModalType = 'GENERIC' | 'LEGACYHEALTHPLAN'

function HealthPlanModal({
  onUpdateCurrentUser,
  onPostHealthInsurance,
  currentUser,
  openModal,
  isModal,
  closeModal,
  isUserFetched,
  shouldEnableModal
}: Props) {
  const history = useHistory()
  const hasInsurancePlan = currentUser?.healthInsurancePlanData?.hasHealthInsurancePlan
  const healthInsuranceCompany = currentUser?.healthInsurancePlanData?.healthInsurancePlan?.company
  const healthInsurancePlanName = currentUser?.healthInsurancePlanData.healthInsurancePlan?.planName
  const shouldOpenLegacyHealthPlanModal = healthInsuranceCompany && listOfPlansAcceptedByTheia.includes(healthInsuranceCompany) && healthInsurancePlanName === ''
  const [page, setModalType] = useState<ModalType>(
    shouldOpenLegacyHealthPlanModal ? 'LEGACYHEALTHPLAN' : 'GENERIC'
  )

  useEffect(() => {
    if (hasInsurancePlan === null) {
      setModalType('GENERIC')
    }
  }, [])

  useEffect(() => {
    if (shouldEnableModal && isUserFetched && (
      hasInsurancePlan === null || shouldOpenLegacyHealthPlanModal
    )) {
      openModal()
      if (page === 'LEGACYHEALTHPLAN') {
        eventTrack(`Abriu Modal ${healthInsuranceCompany} Legado`)
      } else {
        eventTrack('Abriu Modal Onboarding')
      }
    }
  }, [])

  const noHealthPlan = () => {
    onPostHealthInsurance(false, null, null, null, null, null, null, null)
    onUpdateCurrentUser()
    if (page === 'LEGACYHEALTHPLAN') {
      eventTrack(`Sem plano ${healthInsuranceCompany} Legado`)
    } else {
      eventTrack('Sem plano de saúde Modal Onboarding')
    }
    closeModal()
  }

  const addLegacyHealthPlan = () => {
    history.push(CHANGE_HEALTH_PLAN, { isStillLegacyPlan: true })
    eventTrack(`Adicionou plano ${healthInsuranceCompany} Legado`)
    closeModal()
  }

  const changeHealthPlan = () => {
    eventTrack(`Trocou plano ${healthInsuranceCompany} Legado`)
    history.push(CHANGE_HEALTH_PLAN, { isStillLegacyPlan: false })
    closeModal()
  }

  const addHealthPlan = () => {
    eventTrack('Adicionou Plano Modal Onboarding')
    history.push(CHANGE_HEALTH_PLAN)
    closeModal()
  }

  const onCloseModal = () => {
    if (page === 'LEGACYHEALTHPLAN') {
      eventTrack(`Fechou Modal ${healthInsuranceCompany} Legado`)
    } else {
      eventTrack('Fechou Modal Onboarding')
    }
    closeModal()
  }

  const modalLegacyHealthPlan = (
    <div className="flex flex-col items-center h-full">
      <AcceptHealthPlan className="health-plan-svg mb-2" />
      <h2 className="text-fontSmall text-center my-2">
        Boa notícia! Agora aceitamos o plano de saúde da {healthInsuranceCompany}
      </h2>
      <p className="text-fontDefault text-center my-2">Adicione seu plano e confira quais profissionais estão inclusos. </p>
      <div className="flex flex-col w-full h-full mx-2 justify-end">
        <RegularButton
          variant="primary"
          label="Adicionar Plano"
          extraClass="my-2"
          onClick={addLegacyHealthPlan}
        />
        <RegularButton
          variant="subtle"
          label="Troquei de plano"
          extraClass="my-2"
          onClick={changeHealthPlan}
        />
        <RegularButton
          variant="text"
          label="Não tenho mais plano de saúde"
          extraClass="my-2"
          onClick={noHealthPlan}
        />
      </div>
    </div>
  )

  const modalGeneric = (
    <div className="flex flex-col items-center h-full">
      <AcceptHealthPlan className="health-plan-svg mb-2" />
      <h2 className="text-fontSmall text-center my-2">Boa notícia! Agora aceitamos planos de saúde na Theia</h2>
      <p className="text-fontDefault text-center my-2">Adicione seu plano e confira quais profissionais estão inclusos.</p>
      <div className="flex flex-col w-full h-full mx-2 justify-end">
        <RegularButton
          variant="primary"
          label="Adicionar plano"
          extraClass="my-2"
          onClick={addHealthPlan}
        />
        <RegularButton
          variant="text"
          label="Não tenho plano de saúde"
          extraClass="my-2"
          onClick={noHealthPlan}
        />
      </div>
    </div>
  )

  return (
    isModal ? (
      <AccessibleModal
        headerText=""
        onClose={onCloseModal}
        visible={isModal}
        extraModalContainerClass="health-plan"
        variant="centerBottom"
      >
        {page === 'LEGACYHEALTHPLAN' && modalLegacyHealthPlan}
        {page === 'GENERIC' && modalGeneric}
      </AccessibleModal>
    ) : null
  )
}
const mapStateToProps = ({ onboarding, authentication }: AppState) => ({
  currentUser: authentication.currentUser,
  isModal: onboarding?.isModal,
  isUserFetched: authentication.isUserFetched,
  shouldEnableModal: authentication.shouldEnableModal
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onUpdateCurrentUser: updateCurrentUserInfosAction,
  onPostHealthInsurance: onPostHealthInsuranceAction,
  openModal: openModalAction,
  closeModal: closeModalAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthPlanModal)
