import {
  FETCH_PROFESSIONAL_SUMMARY,
  FAILURE_FETCH_PROFESSIONAL_SUMMARY,
  SUCCESS_FETCH_PROFESSIONAL_SUMMARY,
  FETCH_ALL_PROFESSIONALS,
  SUCCESS_FETCH_ALL_PROFESSIONALS,
  FAILURE_FETCH_ALL_PROFESSIONALS,
  ProfessionalTypes,
  FETCH_PROFESSIONAL_AVAILABILITY,
  SUCCESS_FETCH_PROFESSIONAL_AVAILABILITY,
  FAILURE_FETCH_PROFESSIONAL_AVAILABILITY,
} from './actions'
import {
  Professional,
  defaultCategory,
  ProfessionalData,
  AvailabilitySlot,
} from '../../domain/Professional';
import { unique } from '../../utils/helpers';
import { SpecialistCategories } from '../../domain/Specialist';

export interface ProfessionalState {
  byId: {
    [id: string]: ProfessionalData;
  };
  categories: Array<SpecialistCategories>;
  isFetchingAll: boolean;
  isFetchingSummary: boolean;
  professionalSummary?: Professional;
  isFetchingAvailability: boolean;
  professionalAvailability?: AvailabilitySlot[];
}

const initialState: ProfessionalState = {
  byId: {},
  isFetchingAll: false,
  categories: [defaultCategory],
  isFetchingSummary: false,
  professionalSummary: undefined,
  isFetchingAvailability: false,
  professionalAvailability: undefined
}

export function professionals(
  state: ProfessionalState = initialState,
  action: ProfessionalTypes
): ProfessionalState {
  switch (action.type) {
    case FETCH_PROFESSIONAL_SUMMARY:
      return {
        ...state,
        isFetchingSummary: true,
        professionalSummary: undefined
      }
    case SUCCESS_FETCH_PROFESSIONAL_SUMMARY:
      return {
        ...state,
        isFetchingSummary: false,
        professionalSummary: action.professionalSummary
      }
    case FAILURE_FETCH_PROFESSIONAL_SUMMARY:
      return {
        ...state,
        isFetchingSummary: false,
        professionalSummary: undefined
      }
    case FETCH_PROFESSIONAL_AVAILABILITY:
      return {
        ...state,
        isFetchingAvailability: true,
        professionalAvailability: undefined
      }
    case SUCCESS_FETCH_PROFESSIONAL_AVAILABILITY:
      return {
        ...state,
        isFetchingAvailability: false,
        professionalAvailability: action.availability
      }
    case FAILURE_FETCH_PROFESSIONAL_AVAILABILITY:
      return {
        ...state,
        isFetchingAvailability: false,
        professionalAvailability: undefined
      }
    case FETCH_ALL_PROFESSIONALS:
      return {
        ...state,
        isFetchingAll: true
      }
    case SUCCESS_FETCH_ALL_PROFESSIONALS: {
      const uniqueCategories = action.professionals
        .map((professional) => professional.category)
        .filter(unique)

      const initialValue: { [id: string]: ProfessionalData } = {}
      const allProfessionals = action.professionals.reduce((byId, professional) => {
        // eslint-disable-next-line no-param-reassign
        byId[professional.id] = {
          ...byId[professional.id],
          professional,
        }
        return byId
      }, initialValue)

      return {
        ...state,
        byId: allProfessionals,
        isFetchingAll: false,
        categories: [defaultCategory].concat(uniqueCategories.sort())
      }
    }
    case FAILURE_FETCH_ALL_PROFESSIONALS:
      return {
        ...state,
        isFetchingAll: false
      }
    default:
      return state
  }
}
