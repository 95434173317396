import { Post, defaultCategory } from '../../domain/Content'
import {
  FETCH_ALL_POSTS,
  SUCCESS_FETCH_ALL_POSTS,
  FAILURE_FETCH_ALL_POSTS,
  FETCH_POST,
  SUCCESS_FETCH_POST,
  FAILURE_FETCH_POST,
  SELECT_CONTENT_CATEGORY,
  SET_NUMBER_OF_POSTS_TO_RENDER,
  ContentTypes
} from './actions'

export interface ContentState {
  allPosts: Array<Post>;
  isFetchingAll: boolean;
  isFetchingPost: boolean;
  singlePost?: Array<Post>;
  contentCategory: string;
  numberOfVisiblePosts: number;
}

export const initialContentState: ContentState = {
  allPosts: [],
  isFetchingAll: false,
  isFetchingPost: false,
  contentCategory: defaultCategory,
  numberOfVisiblePosts: 20
}

export function content(state = initialContentState, action: ContentTypes): ContentState {
  switch (action.type) {
    case FETCH_ALL_POSTS:
      return {
        ...state,
        isFetchingAll: true
      }
    case SUCCESS_FETCH_ALL_POSTS:
      return {
        ...state,
        isFetchingAll: false,
        allPosts: action.allPosts,
        singlePost: []
      }
    case FAILURE_FETCH_ALL_POSTS:
      return {
        ...state,
        isFetchingAll: false
      }
    case FETCH_POST:
      return {
        ...state,
        isFetchingPost: true
      }
    case SUCCESS_FETCH_POST:
      return {
        ...state,
        isFetchingPost: false,
        singlePost: action.singlePost
      }
    case FAILURE_FETCH_POST:
      return {
        ...state,
        isFetchingPost: false
      }
    case SELECT_CONTENT_CATEGORY:
      return {
        ...state,
        contentCategory: action.category,
        numberOfVisiblePosts: initialContentState.numberOfVisiblePosts
      }
    case SET_NUMBER_OF_POSTS_TO_RENDER:
      return {
        ...state,
        numberOfVisiblePosts: action.numberOfPostsToRender
      }
    default: return state
  }
}
