import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  RegularButton,
  ListItemCard,
  FontIcon,
  Button,
  Loader,
} from 'theia-web-ds'
import { useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import {
  getAllWeekOptionsAction,
  selectWeekAction
} from '../../state/weekFlow/actions'
import { WeekInfo } from '../../domain/WeekFlow'
import PediatricJourneyIcon from '../../../assets/pediatric-journey.png'
import WeekCarousel from './WeekCarousel'
import {
  fetchAllBookings, fetchProfileAction
} from '../../state/profile/actions'
import { Booking } from '../../domain/Profile'
import Header from '../common/molecules/Header'
import HealthPlanModal from './HealthPlanModal'
import { AppointedSpecialists, User, UserConfirmationInfos } from '../../domain/User'
import { UsgPrescription } from '../../domain/Prescriptions'
import ProfilePicture from '../common/molecules/ProfilePicture'
import BookingCard from '../myBookingsFlow/BookingCard'
import { fetchAllPostsAction } from '../../state/content/actions'
import WeekContent from './WeekContent'
import JaqueChat from '../../../assets/jaque-chat.png'
import { primary, textSecondary } from '../../color'
import ScheduleSugestionBanner from '../suggestionsFlow/ScheduleSuggestionBanner'
import { getScheduleSuggestionAction } from '../../state/scheduleSuggestion/actions'
import {
  getHighlightsAction,
  getUsgPrescriptionAction,
} from '../../state/prescriptions/actions'
import { eventPage, eventTrack } from '../../../eventGenerate'
import EmptyBookingsDisableImg from '../../../assets/empty-my-bookings-disable.png'
import IconWhatsapp from '../../../assets/icon-whatsapp.png'
import {
  getUserConfirmationInfosAction,
  clearForceUpdateCurrentUserAction,
  updateCurrentUserInfosAction
} from '../../state/authentication/main/actions'
import UsgModal from '../usgFlow/UsgModal'
import JourneyWithNoSuggestions from './JourneyWithNoSuggestions'
import GuidedJourneyComponent from '../guidedJourney/GuidedJourneyComponent'
import { getGuidedJourneyAction, selectJourneyQuarterAction } from '../../state/guidedJourney/actions'
import { GuidedJourney, Quarter } from '../../domain/GuidedJourney'
import { isPostPregnant, isPregnant, isTrying } from '../../utils/helpers'
import ChildrenInfoCard from '../pediatricFlow/ChildrenInfoCard'
import {
  showPediatricWelcomeModalAction,
  suppressPediatricWelcomeModalAction
} from '../../state/pediatricFlow/actions'
import './WeekView.scss'
import { Reminders } from '../../domain/Booking'
import {
  AGENDAR,
  CADASTRO_PEDIATRICO,
  CHAT,
  CONSULTAS
} from '../../routes/RoutesConstants'
import {
  SHOW_EMAIL_VALIDATION_CARD,
  SHOW_PEDIATRIC_JOURNEY,
  SHOW_PEDIATRIC_REGISTRATION,
  SHOW_PHONE_VALIDATION_CARD
} from '../../state/remoteConfig'
import { PEDIATRIC_REGISTER } from '../../utils/EventCategories'
import EmailValidationCard from '../profile/accountValidation/EmailValidationCard'
import { GenericStatusType } from '../../domain/Status'
import { SourceTypes } from '../../domain/ScheduleFlow'
import { setScheduleSourceAction } from '../../state/scheduleFlow/actions'
import PhoneValidationCard from '../profile/accountValidation/PhoneValidationCard'

interface Props {
  getAllWeekOptions: () => void;
  onSelectWeek: (element: string) => void;
  isFetching: boolean;
  weeks: string[];
  selectedWeek?: WeekInfo;
  currentWeek?: WeekInfo;
  currentUser?: User;
  pregnancyJourney: boolean;
  fetchAllBookingsAction: () => void;
  isFetchingAllBookings: boolean;
  bookings: Array<Booking>;
  fetchProfile: () => void;
  forceUpdateCurrentUser: boolean;
  fetchAllPosts: () => void;
  isFetchingAllPosts: boolean;
  allPostsIsEmpty: boolean;
  getScheduleSuggestion: () => void;
  reminders: Array<Reminders>;
  shouldSendReminder: boolean;
  hasSpecialist?: Array<AppointedSpecialists> | [];
  insurancePlanCovered?: boolean | null;
  getUsgPrescription: () => void;
  hasUSGRequest?: boolean;
  showPediatricWelcomeModal: () => void;
  sawUsgModal?: boolean;
  usgPrescription?: UsgPrescription;
  loadingUsgPrescription: boolean;
  guidedJourney: GuidedJourney;
  isGettingGuidedJourney: boolean;
  getGuidedJourney: () => void;
  isFetchingUser?: boolean;
  needToCreateBaby: boolean;
  getHighlights: () => void;
  babyJourney: boolean;
  isGettingPrescriptionsHighlights: boolean;
  updateCurrentUserInfos: () => void;
  clearForceUpdateCurrentUser: () => void;
  selectJourneyQuarter: (quarter: Quarter) => void;
  userConfirmationInfos?: UserConfirmationInfos;
  getUserConfirmationInfos: () => void;
  getUserConfirmationStatus: GenericStatusType;
  setScheduleSource: (source: SourceTypes) => void;
}

function WeekView({
  getAllWeekOptions,
  selectedWeek,
  currentWeek,
  isFetching,
  weeks,
  needToCreateBaby,
  onSelectWeek,
  currentUser,
  showPediatricWelcomeModal,
  pregnancyJourney,
  isFetchingAllBookings,
  fetchAllBookingsAction,
  bookings,
  forceUpdateCurrentUser,
  updateCurrentUserInfos,
  clearForceUpdateCurrentUser,
  fetchProfile,
  fetchAllPosts,
  isFetchingAllPosts,
  allPostsIsEmpty,
  getScheduleSuggestion,
  getHighlights,
  reminders,
  shouldSendReminder,
  hasSpecialist,
  insurancePlanCovered,
  getUsgPrescription,
  hasUSGRequest,
  sawUsgModal,
  usgPrescription,
  loadingUsgPrescription,
  guidedJourney,
  isGettingGuidedJourney,
  getGuidedJourney,
  isFetchingUser,
  babyJourney,
  isGettingPrescriptionsHighlights,
  selectJourneyQuarter,
  userConfirmationInfos,
  getUserConfirmationInfos,
  getUserConfirmationStatus,
  setScheduleSource,
}: Props) {
  const history = useHistory()
  const hasDPP = (!isFetchingUser && !!currentUser?.expectedBabyBirthDate) || false
  const hasDUM = (!isFetchingUser && !!currentUser?.lastPeriodStartDate) || false

  useEffect(() => {
    if (hasUSGRequest && !sawUsgModal && !loadingUsgPrescription) {
      getUsgPrescription()
    }
  }, [hasUSGRequest, sawUsgModal])

  useEffect(() => {
    eventPage('home', 'Home')
  }, [])

  useEffect(() => {
    if (!isGettingGuidedJourney) {
      getGuidedJourney()
    }
    if (weeks.length === 0 && !isFetching) {
      getAllWeekOptions()
    }
    if (!isFetchingAllBookings) {
      fetchAllBookingsAction()
    }
    if (allPostsIsEmpty && !isFetchingAllPosts) {
      fetchAllPosts()
    }
    if (!isGettingPrescriptionsHighlights) {
      getHighlights()
    }
  }, [])

  useEffect(() => {
    if (forceUpdateCurrentUser && !isFetchingUser) {
      fetchProfile()
      updateCurrentUserInfos()
      clearForceUpdateCurrentUser()
      getGuidedJourney()
    }
  }, [forceUpdateCurrentUser])

  useEffect(() => {
    getScheduleSuggestion()
    getUserConfirmationInfos()
  }, [])

  if (isFetching
    || isFetchingAllPosts
    || isFetchingUser
    || isGettingPrescriptionsHighlights
    || getUserConfirmationStatus.isLoading
  ) {
    return (
      <div className="flex justify-center w-full h-screen">
        <div className="flex items-center h-full">
          <Loader />
        </div>
      </div>
    )
  }

  const goToSchedule = () => {
    setScheduleSource(SourceTypes.HOME)
    history.push(AGENDAR)
  }

  const goToAllBookings = () => {
    history.push(CONSULTAS, { initialTab: 'agendamentos' })
  }

  const goToChat = () => {
    eventTrack('Clicou Card EP')
    history.push(CHAT)
  }

  const userName: any = currentUser && currentUser.name
  const userImage: any = currentUser && currentUser.pictureUrl
  const hours = new Date().getHours()
  let title = 'Bom dia!'
  if (hours >= 12 && hours < 18) {
    title = 'Boa tarde!'
  } else if (hours >= 18 && hours < 24) {
    title = 'Boa noite!'
  }

  const bookingsToShow: Booking[] = bookings.length > 2 ? [bookings[0], bookings[1]] : bookings

  function handlePediatricFlow() {
    eventTrack('clicou seu bebe nasceu', { category: PEDIATRIC_REGISTER })
    if (isPregnant(currentUser?.pregnancyMoment) && needToCreateBaby) {
      return showPediatricWelcomeModal()
    }
    if (isPostPregnant(currentUser?.pregnancyMoment, currentUser?.pregnancyMomentText)
    && needToCreateBaby) {
      return history.push(CADASTRO_PEDIATRICO)
    }
    return null
  }

  const bookingsComponent = (
    <>
      <div className="bookings-header">
        <p className="bookings-title">Agendamentos</p>
        {bookingsToShow.length !== 0 && (
          <RegularButton
            onClick={goToAllBookings}
            label="Mostrar tudo"
            variant="text"
            btnSize="small"
          />
        )}
      </div>
      {isFetchingAllBookings && bookingsToShow.length === 0 && (
        <BookingCard />
      )}
      {
        bookingsToShow.map((booking) => (
          <BookingCard
            booking={booking}
            key={booking.id}
            showTagStatus={false}
          />
        ))
      }
      {!isFetchingAllBookings && bookingsToShow.length === 0 && (
        <div className="bg-white rounded-2xl pt-8 pb-8 ml-4 lg:ml-0 mb-10 mr-4 lg:mr-6 text-center">
          <img src={EmptyBookingsDisableImg} className="mx-auto w-auto booking-disable-img" alt="" />
          <p className="text-center mt-5">Você ainda não possui nenhum agendamento</p>
          <div className="mx-5 mt-2">
            <p className="text-textSecondary">
              {isPregnant(currentUser?.pregnancyMoment) && (
                'Primeiro passo é agendar uma consulta presencial com uma de nossas obstetras.'
              )}
              {isTrying(currentUser?.pregnancyMoment) && (
                'Agende uma consulta pré-concepcional presencial com uma de nossas obstetras.'
              )}
            </p>
          </div>
          <RegularButton
            onClick={goToSchedule}
            label="Agendar consulta"
            maxWidth="205px"
            extraClass="m-auto mt-4"
          />
        </div>
      )}
    </>
  )

  const remindersOrdered = reminders && reminders.length > 0 ? reminders?.sort((a: any, b: any) => (
    // eslint-disable-next-line no-nested-ternary
    (a.type < b.type) ? -1 : (a.type > b.type) ? 1 : 0)) : []

  function goToWhatsapp(link: string) {
    eventTrack('Clicou Wpp Home')
    window.open(link, '_blank')
  }

  return (
    <>
      <HealthPlanModal />
      <div className="w-full pb-6">
        <Header
          title={title}
          subTitle={(pregnancyJourney || babyJourney)
            ? 'Tudo que você precisa saber agora.'
            : undefined}
          showLogo
        >
          <a href="/profile" className="flex items-center" aria-label="Ir para perfil">
            <p className="mr-2">{userName ? userName.split(' ')[0] : ''}</p>
            <ProfilePicture
              pictureUrl={userImage}
              pictureSize={40}
            />
          </a>
        </Header>
        {shouldSendReminder && (
          remindersOrdered.map((reminder) => (
            <Fragment key={reminder.type}>
              <ScheduleSugestionBanner
                reminder={reminder}
                extraClass={reminder.firstTime ? 'first-time' : ''}
              />
            </Fragment>
          ))
        )}
        {SHOW_EMAIL_VALIDATION_CARD
        && userConfirmationInfos?.email && (
          <EmailValidationCard />
        )}
        {SHOW_PHONE_VALIDATION_CARD
        && userConfirmationInfos?.phone && (
          <PhoneValidationCard />
        )}
        {isPregnant(currentUser?.pregnancyMoment)
        && (!hasDUM && !hasDPP) && (
          <div className="w-full">
            <JourneyWithNoSuggestions
              title="Para desbloquear conteúdos personalizados pra você,
                precisamos saber a data de nascimento do seu bebê."
              subtitle="Escolha uma das opções abaixo 👇"
              borderBottomStyle="hidden md:block"
            />
          </div>
        )}
        {pregnancyJourney
        && weeks.length !== 0
        && !isTrying(currentUser?.pregnancyMoment)
        && !isPostPregnant(currentUser?.pregnancyMoment) && (
          <WeekCarousel
            weeks={weeks}
            onSelectWeek={onSelectWeek}
            selectedWeek={selectedWeek}
            currentWeek={currentWeek}
          />
        )}
        {isPostPregnant(currentUser?.pregnancyMoment, currentUser?.pregnancyMomentText)
        && babyJourney
        && !needToCreateBaby
        && SHOW_PEDIATRIC_REGISTRATION && (
          <ChildrenInfoCard />
        )}
        {SHOW_PEDIATRIC_REGISTRATION
        && needToCreateBaby && (
          <div className="p-4">
            <ListItemCard
              extraClassNameContainer="pediatric-journey-banner"
              title={isPregnant(currentUser?.pregnancyMoment) ? 'Seu bebê nasceu?' : 'Jornada Pediátrica'}
              picture={(
                <img
                  src={PediatricJourneyIcon}
                  width={40}
                  height={40}
                  className="mr-3 ml-6"
                  alt=""
                />
              )}
              isLastItem
              caption={isPregnant(currentUser?.pregnancyMoment) ? 'Inicie a jornada pediátrica' : 'Cadastre seu bebê para iniciar'}
              ariaLabel="Banner Jornada Pediátrica"
              onClick={handlePediatricFlow}
              titleSize="text-fontDefault"
              arrowColor="rgba(0, 0, 0, 0.6)"
              extraTitleClass="mb-2"
            />
          </div>
        )}
        <div className={`week-view-grid-container ${isTrying(currentUser?.pregnancyMoment) ? 'week-view-grid-container-is-trying' : ''}`}>
          <div className="week-view-item-a">
            {((isPregnant(currentUser?.pregnancyMoment))
            || (SHOW_PEDIATRIC_JOURNEY
            && isPostPregnant(currentUser?.pregnancyMoment, currentUser?.pregnancyMomentText)
            && babyJourney)) && (
              <GuidedJourneyComponent
                isLoading={isGettingGuidedJourney}
                quarters={guidedJourney}
                onSelectQuarter={(quarter) => selectJourneyQuarter(quarter)}
              />
            )}
            {((pregnancyJourney && weeks.length !== 0)
            || (isPostPregnant(currentUser?.pregnancyMoment, currentUser?.pregnancyMomentText)
            && babyJourney
            && SHOW_PEDIATRIC_REGISTRATION)) && (
              <WeekContent
                selectedWeekName={selectedWeek?.weekName}
                showPediatricContent={
                  isPostPregnant(
                    currentUser?.pregnancyMoment,
                    currentUser?.pregnancyMomentText
                  )
                }
              />
            )}
            <div className="bookings-container-item-a">
              {bookingsComponent}
            </div>
            {bookings.length === 0 && insurancePlanCovered && (
              <div className="mx-4 lg:mx-0">
                <Button
                  className="whatsapp-container"
                  onClick={() => goToWhatsapp('https://api.whatsapp.com/send?phone=11940470228&text=Quero%20saber%20mais%20sobre%20a%20Theia')}
                >
                  <div className="flex items-center flex-row w-full">
                    <img
                      src={IconWhatsapp}
                      width={40}
                      height={40}
                      className="mx-4"
                      alt="Whatsapp"
                    />
                    <div className="flex items-center w-full">
                      <div className="justify-between w-full">
                        <p className="text-textPrimary">Ainda com dúvida?</p>
                        <p className="text-textSecondary">Nosso time está pronto para te atender.</p>
                      </div>
                      <div className="mr-5 mt-2">
                        <FontIcon iconType="icon-ArrowRight2Light" color={textSecondary} fontSize="25px" />
                      </div>
                    </div>
                  </div>
                </Button>
              </div>
            )}
            {hasSpecialist !== undefined && hasSpecialist.length > 0
              && bookings.length !== 0 && (
              <ListItemCard
                extraClassNameContainer="week-content-link-to-journey"
                onClick={goToChat}
                picture={(
                  <div
                    className="week-content-link-to-journey-icon-container"
                  >
                    <img
                      src={JaqueChat}
                      width={40}
                      height={40}
                      className="week-content-link-to-journey-icon"
                      alt=""
                    />
                  </div>
               )}
                iconColor={primary}
                caption="Jaqueline Gaspar"
                title="Especialista pessoal"
                ariaLabel="Especialista pessoal"
                isLastItem
                isItemFromList={false}
                key="journey-step-card-2"
              />
            )}
          </div>
          <div className="week-view-item-b">
            <div className="bookings-container-item-b">
              {bookingsComponent}
            </div>
          </div>
        </div>
      </div>
      <UsgModal
        isVisible={hasUSGRequest && !sawUsgModal}
        isLoading={loadingUsgPrescription}
        content={usgPrescription}
      />
    </>
  )
}

const mapStateToProps = ({
  weekFlow,
  profile,
  authentication,
  professionals,
  content,
  scheduleSuggestion,
  prescriptions,
  guidedJourney,
}: AppState) => ({
  isFetching: weekFlow.isFetchingWeekInfos,
  weeks: weekFlow.weeks,
  selectedWeek: weekFlow.selectedWeek,
  currentWeek: weekFlow.currentWeek,
  currentUser: authentication.currentUser,
  forceUpdateCurrentUser: authentication.forceUpdateCurrentUser,
  pregnancyJourney: weekFlow.pregnancyJourney,
  bookings: profile.allBookings,
  isFetchingAllBookings: profile.isFetchingAll,
  isFetchingAllProfessionals: professionals.isFetchingAll,
  isFetchingAllPosts: content.isFetchingAll,
  allPostsIsEmpty: content.allPosts.length === 0,
  reminders: scheduleSuggestion.byCategory,
  shouldSendReminder: scheduleSuggestion.shouldSendReminder,
  hasSpecialist: authentication.currentUser?.appointedSpecialists,
  insurancePlanCovered: authentication.currentUser?.healthInsurancePlanData.insurancePlanCovered,
  hasUSGRequest: prescriptions.hasUSGRequest,
  sawUsgModal: prescriptions.sawUsgModal,
  usgPrescription: prescriptions.usgPrescription,
  loadingUsgPrescription: prescriptions.isGettingUsgPrescription,
  babyJourney: prescriptions.babyJourney,
  needToCreateBaby: prescriptions.needToCreateBaby,
  isGettingPrescriptionsHighlights: prescriptions.isLoading,
  guidedJourney: guidedJourney.guidedJourney,
  isGettingGuidedJourney: guidedJourney.isLoading,
  isFetchingUser: authentication.isFetchingUser,
  userConfirmationInfos: authentication.userConfirmationInfos,
  getUserConfirmationStatus: authentication.getUserConfirmationStatus,
  prescriptions: prescriptions.prescriptions,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  getAllWeekOptions: getAllWeekOptionsAction,
  onSelectWeek: selectWeekAction,
  fetchAllBookingsAction: fetchAllBookings,
  fetchProfile: fetchProfileAction,
  fetchAllPosts: fetchAllPostsAction,
  getScheduleSuggestion: getScheduleSuggestionAction,
  getUsgPrescription: getUsgPrescriptionAction,
  getGuidedJourney: getGuidedJourneyAction,
  showPediatricWelcomeModal: showPediatricWelcomeModalAction,
  suppressPediatricWelcomeModal: suppressPediatricWelcomeModalAction,
  getHighlights: getHighlightsAction,
  updateCurrentUserInfos: updateCurrentUserInfosAction,
  clearForceUpdateCurrentUser: clearForceUpdateCurrentUserAction,
  selectJourneyQuarter: selectJourneyQuarterAction,
  getUserConfirmationInfos: getUserConfirmationInfosAction,
  setScheduleSource: setScheduleSourceAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeekView)
