import React, { } from 'react'
import { Loader } from 'theia-web-ds'
import {
  Elements
} from '@stripe/react-stripe-js'
import '../../payment/PaymentForm.scss'
import '../../sales-onboarding/SalesOnboarding.scss'
import withLoadStripe from '../../payment/hoc/withLoadStripe'
import PaymentForm from './PaymentForm'

interface Props {
  stripe: Promise<any | null>;
  stripeLoaded: boolean;
  stripeError: object | null;
  extraSubmitAction: () => void;
}

function Payment({
  stripe,
  stripeLoaded,
  stripeError,
  extraSubmitAction,
}: Props) {
  if (!stripeLoaded && !stripeError) {
    return (
      <div className="flex justify-center h-screen w-full bg-bgCanvas">
        <div className="flex items-center h-full">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <Elements stripe={stripe}>
      <PaymentForm
        stripeLoadError={!!stripeError}
        extraSubmitAction={extraSubmitAction}
      />
    </Elements>
  )
}

export default withLoadStripe((Payment))
