import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Loader,
  NewChipButton,
  NewestCheckbox,
  RegularButton,
  Snackbar,
  ScheduleDatePicker,
  ResponsiveDrawer
} from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import {
  AttendanceAvailabilityType,
} from '../../domain/ScheduleFlow'
import {
  selectSpecialistAction,
  setLoadingStepAction,
  setPreviousSelectedSpecialistAction
} from '../../state/scheduleFlow/actions'
import {
  abbreviateName,
  isProductionEnv,
  limitCharacters
} from '../../utils/helpers'
import { TheiaError } from '../../domain/errors/TheiaError'
import { eventTrack } from '../../../eventGenerate'
import { NEW_SCHEDULE_FLOW } from '../../utils/EventCategories'
import './ChooseDayAndTime.scss'
import { SpecialistFromList } from '../../domain/Specialist'
import { getAvailableSpecialistsAction, setFilteredSpecialistsAction } from '../../state/specialists/actions'
import { AvailabilitiesRequestParams, AvailabilityByDay, AvailabilityTimeSlot } from '../../domain/Availabilities'
import { getAvailabilitiesAction } from '../../state/availabilities/actions'

interface Props {
  childId?: string;
  selectedAttendanceType?: AttendanceAvailabilityType;
  categoryId?: string;
  included?: boolean;
  chronosId?: string;
  specialistName?: string;
  loadingStep?: boolean;
  isExam?: boolean;
  isChildReschedule?: boolean;
  isReschedule?: boolean;
  availableSpecialists?: SpecialistFromList[];
  isGettingSpecialists: boolean;
  specialistsError?: TheiaError;
  filteredSpecialists?: SpecialistFromList[];
  availabilitiesByDay?: AvailabilityByDay[];
  exactDayMessage?: string;
  isGettingAvailabilities: boolean;
  isLoadingMoreSlots: boolean;
  hasNextAvailabilities: boolean;
  availabilitiesError?: TheiaError;
  getAvailabilities: (
    categoryId: string,
    params: AvailabilitiesRequestParams
  ) => void;
  onChooseTimeSlot: (slot: AvailabilityTimeSlot) => void;
  getAvailableSpecialists: (
    categoryId: string,
    meetingType: AttendanceAvailabilityType
  ) => void;
  setLoadingSteps: (isLoading: boolean) => void;
  clearPreviousSelectedSpecialist: () => void;
  setFilteredSpecialists: (specialists?: SpecialistFromList[]) => void;
}

type BlockedDayReason = {
  visible: boolean;
  title: string;
  message: string;
}

function ChooseDayAndTime({
  childId,
  selectedAttendanceType,
  categoryId,
  included,
  chronosId,
  specialistName,
  loadingStep,
  isExam,
  isChildReschedule,
  isReschedule,
  availableSpecialists,
  isGettingSpecialists,
  specialistsError,
  filteredSpecialists,
  availabilitiesByDay,
  exactDayMessage,
  isGettingAvailabilities,
  isLoadingMoreSlots,
  hasNextAvailabilities,
  availabilitiesError,
  getAvailabilities,
  onChooseTimeSlot,
  getAvailableSpecialists,
  setLoadingSteps,
  clearPreviousSelectedSpecialist,
  setFilteredSpecialists,
}: Props) {
  const [offset, setOffset] = useState(0)
  const [startDate, setStartDate] = useState<Date>()
  const [specialistsModalVisible, setSpecialistsModalVisible] = useState(false)
  const [previousList, setPreviousList] = useState<string[]>([])
  const [blockedDayReason, setBlockedDayReason] = useState<BlockedDayReason>()
  const [previousSpecialistFiltered, setPreviousSpecialistFiltered] = useState(false)
  const hasPreviousSpecialist = !!(chronosId || specialistName)
  const isProd = isProductionEnv()
  const limit = 7
  const specialists = filteredSpecialists && filteredSpecialists?.length > 0
    ? filteredSpecialists.map((item) => item.chronosId) : undefined
  const specialistChipLabel = filteredSpecialists && filteredSpecialists.length > 0
    ? `${limitCharacters(abbreviateName(filteredSpecialists[0].name), 17)} ${filteredSpecialists.length > 1 ? `(+${filteredSpecialists.length - 1})` : ''}`
    : 'Especialista'

  function getAvailableSlots(specialistsIds?: string[]) {
    if (selectedAttendanceType && categoryId && !isGettingAvailabilities) {
      getAvailabilities(
        categoryId,
        {
          childId,
          meetingType: selectedAttendanceType,
          specialists: specialistsIds || specialists,
          limit,
          offset,
          startDate: startDate?.getTime() || undefined
        }
      )
    }
  }

  function selectAvailability(slot: AvailabilityTimeSlot) {
    onChooseTimeSlot(slot)
  }

  function showSpecialistsModal() {
    setSpecialistsModalVisible(true)
  }

  function handleGetMoreSlots() {
    setOffset(offset + limit)
    eventTrack('clicou em ver mais horarios ', {
      categoria: NEW_SCHEDULE_FLOW
    })
  }

  function onSelectSpecialist(specialist: SpecialistFromList, checked: boolean) {
    if (checked) {
      setFilteredSpecialists(
        filteredSpecialists
          ? [...filteredSpecialists, specialist]
          : [specialist]
      )
    } else {
      const newArray = (filteredSpecialists
        && filteredSpecialists?.filter((item) => item.chronosId !== specialist.chronosId)) || []
      setFilteredSpecialists(newArray)
    }
    clearPreviousSelectedSpecialist()
  }

  function handleCloseAndApplySpecialists() {
    const filteredSpecialistsNames = filteredSpecialists?.map(
      (specialist) => specialist.name
    )
    setOffset(0)
    setPreviousList(specialists || [])
    eventTrack('filtrou especialista', {
      categoria: NEW_SCHEDULE_FLOW,
      'especialista': filteredSpecialistsNames?.join(', ')
    })
    setSpecialistsModalVisible(false)
  }

  function getSpecialists() {
    if (selectedAttendanceType && categoryId) {
      getAvailableSpecialists(categoryId, selectedAttendanceType)
    }
  }

  function clearSelectedSpecialists() {
    setFilteredSpecialists(undefined)
    clearPreviousSelectedSpecialist()
  }

  function handleChangeDate(date: Date) {
    setOffset(0)
    setStartDate(date)
    eventTrack('filtrou data', {
      categoria: NEW_SCHEDULE_FLOW
    })
  }

  function onUnmountComponent() {
    clearSelectedSpecialists()
  }

  useEffect(() => {
    getSpecialists()
    eventTrack('visualizou escolha horario', {
      categoria: NEW_SCHEDULE_FLOW
    })
    return () => {
      onUnmountComponent()
    }
  }, [])

  useEffect(() => {
    if (!chronosId
      && !specialistName
      && !isGettingAvailabilities) {
      setLoadingSteps(false)
    }
    if ((chronosId || specialistName)
    && availableSpecialists) {
      const previousSpecialist = availableSpecialists.find(
        (specialist) => (specialist.chronosId === chronosId)
          || (specialist.name === specialistName)
      )
      if (previousSpecialist) {
        setFilteredSpecialists([previousSpecialist])
        setPreviousList([previousSpecialist.chronosId])
        getAvailableSlots([previousSpecialist.chronosId])
      } else {
        clearPreviousSelectedSpecialist()
      }
      setLoadingSteps(false)
      setPreviousSpecialistFiltered(true)
    }
  }, [availableSpecialists])

  useEffect(() => {
    if (hasPreviousSpecialist && !previousSpecialistFiltered) return
    if (!isGettingAvailabilities) {
      getAvailableSlots()
    }
  }, [offset, startDate, previousList, previousSpecialistFiltered])

  if (loadingStep) {
    return (
      <div className="schedule-flow-loader choose-day-and-time-view">
        <Loader />
      </div>
    )
  }

  if ((isGettingAvailabilities && !isLoadingMoreSlots)
  || (hasPreviousSpecialist && !previousSpecialistFiltered)) {
    return (
      <div className="availabilities-loader skeleton-container">
        <div className="flex gap-x-2 items-center">
          <NewChipButton label="" isLoading id="skeleton-1" width="131px" extraClass="skeleton" />
          <NewChipButton label="" isLoading id="skeleton-1" width="131px" extraClass="skeleton" />
        </div>
        <NewChipButton label="" isLoading id="skeleton-2" width="145px" extraClass="skeleton" />
        <div className="flex gap-x-2">
          <NewChipButton label="" isLoading id="skeleton-3" width="62px" extraClass="skeleton" />
          <NewChipButton label="" isLoading id="skeleton-4" width="62px" extraClass="skeleton" />
          <NewChipButton label="" isLoading id="skeleton-5" width="62px" extraClass="skeleton" />
        </div>
        <NewChipButton label="" isLoading id="skeleton-6" width="145px" extraClass="skeleton" />
        <div className="flex gap-x-2">
          <NewChipButton label="" isLoading id="skeleton-7" width="62px" extraClass="skeleton" />
          <NewChipButton label="" isLoading id="skeleton-8" width="62px" extraClass="skeleton" />
          <NewChipButton label="" isLoading id="skeleton-9" width="62px" extraClass="skeleton" />
          <NewChipButton label="" isLoading id="skeleton-10" width="62px" extraClass="skeleton" />
        </div>
        <NewChipButton label="" isLoading id="skeleton-11" width="145px" extraClass="skeleton" />
        <div className="flex gap-x-2">
          <NewChipButton label="" isLoading id="skeleton-12" width="62px" extraClass="skeleton" />
          <NewChipButton label="" isLoading id="skeleton-13" width="62px" extraClass="skeleton" />
          <NewChipButton label="" isLoading id="skeleton-14" width="62px" extraClass="skeleton" />
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className={`day-and-time-slots-container
        ${!included ? 'pb-16 md:pb-6' : 'pb-4'}
        ${!isReschedule ? 'schedule-flow' : ''}`}
      >
        <div className="slots-header-gradient">
          <div className="chip-container">
            <ScheduleDatePicker
              id="startDate"
              maxDate={moment().add(45, 'd').toDate()}
              minDate={moment().subtract(0, 'd').toDate()}
              name="startDate"
              width={!startDate ? '78px' : '110px'}
              value={startDate}
              onChange={(value) => handleChangeDate(value)}
              disabled={isGettingAvailabilities}
              placeholder="Data"
            />
          </div>
          {!isExam && !isChildReschedule ? (
            <div className="chip-container">
              <NewChipButton
                id="specialists-chip-button"
                iconAfter="icon-ArrowDown2Light"
                variant={specialists ? 'primary' : 'default'}
                label={specialistChipLabel}
                onClick={showSpecialistsModal}
                disabled={isGettingSpecialists}
              />
            </div>
          ) : null}
        </div>
        <div className="slots-list-body">
          {availabilitiesError && (
            <Snackbar
              title={availabilitiesError?.friendlyMessageTitle || 'Falha no carregamento'}
              text={availabilitiesError?.friendlyMessage || 'Desculpe, no momento não foi possível carregar os horários disponíveis.'}
              iconLeft="icon-InfoSquareLight"
              alignTop
              buttonOneProps={{
                onClick: getAvailableSlots,
                label: 'Tentar novamente'
              }}
            />
          )}
          {blockedDayReason && blockedDayReason?.visible && (
            <div className="blocked-slots-message" role="alert">
              <Snackbar
                title={blockedDayReason.title}
                text={blockedDayReason.message}
                iconLeft="icon-InfoSquareLight"
                alignTop
                showCloseButton
                extraOnClose={() => setBlockedDayReason(undefined)}
                variant="alert"
              />
            </div>
          )}
          {exactDayMessage && (
            <div
              className="exact-day-message-container"
              dangerouslySetInnerHTML={{ __html: exactDayMessage }}
            />
          )}
          {availabilitiesByDay
          && availabilitiesByDay.length > 0
          && availabilitiesByDay?.map((availability, dayIndex) => (
            <div key={`day-${availability.day.formatDayAndMonthAndYear()}`} className="weekday-container">
              <div className="weekday-container-header">
                <h6>{availability.day.formatToWeekdayAndMonthDay()}</h6>
                {availability?.currentWeek && (
                  <p>{availability.currentWeek}</p>
                )}
              </div>
              <div className="slots-container">
                {availability?.slots.map((slot: AvailabilityTimeSlot, timeIndex: number) => (
                  <RegularButton
                    label={slot.startTime.formatDateToHours()}
                    variant={(slot.specialists.length > 1 && !isProd) ? 'subtle' : 'secondary'}
                    btnSize="small"
                    key={`hour-${slot.startTime.formatDateToHours()}`}
                    id={`time-slot-${dayIndex}-${timeIndex}`}
                    width="100%"
                    onClick={() => selectAvailability(slot)}
                    extraClass={`slot-chip slot-${slot.startTime.toEpochTimestamp()}`}
                    disabled={!!(availability.block)}
                    onClickDisabled={() => setBlockedDayReason({
                      title: availability.blockReason?.title || '',
                      message: availability.blockReason?.message || '',
                      visible: true
                    })}
                  />
                ))}
              </div>
            </div>
          ))}
          {availabilitiesByDay && availabilitiesByDay.length === 0 && (
            <div>
              <Snackbar
                title="Sem horários disponíveis"
                text="Desculpe, no momento todos os horários para essa modalidade de atendimento já foram ocupados."
                iconLeft="icon-InfoSquareLight"
                alignTop
              />
            </div>
          )}
        </div>
        {availabilitiesByDay
        && availabilitiesByDay.length > 0
        && hasNextAvailabilities
        && !availabilitiesError && (
          <div className="show-more-slots-btn">
            <RegularButton
              label="Carregar mais horários"
              onClick={handleGetMoreSlots}
              isSubmitting={isLoadingMoreSlots}
              variant="subtle"
            />
          </div>
        )}
        <div className="slots-bottom-gradient" />
      </div>
      {specialistsModalVisible && (
        <ResponsiveDrawer
          id="check-specialists-drawer"
          isDrawerVisible={specialistsModalVisible}
          onClose={handleCloseAndApplySpecialists}
          headerTitle="Especialista"
          headerExtraComponent={(
            <RegularButton
              variant="text"
              label="Limpar seleção"
              onClick={clearSelectedSpecialists}
              width="fit-content"
              btnSize="small"
              extraClass="gap-0 pl-4"
              disabled={!specialists}
            />
          )}
        >
          {isGettingSpecialists && (
            <div className="schedule-view-loader specialists-modal--loader">
              <Loader />
            </div>
          )}
          {!isGettingSpecialists && (specialistsError || !availableSpecialists) && (
            <div className="specialists-snackbar--error-container">
              <Snackbar
                title={specialistsError?.friendlyMessageTitle || 'Falha no carregamento'}
                text={specialistsError?.friendlyMessage || 'Desculpe, no momento não foi possível carregar as(os) especialistas disponíveis'}
                buttonOneProps={{
                  onClick: getSpecialists,
                  label: 'Tentar novamente'
                }}
                iconLeft="icon-InfoSquareLight"
              />
            </div>
          )}
          {!isGettingSpecialists && availableSpecialists && (
            <div className="specialists-filter-modal">
              <div className="specialists-checkbox-list">
                {availableSpecialists.map((specialist) => (
                  <NewestCheckbox
                    name={specialist.name}
                    value={specialist.chronosId}
                    onChange={(e) => onSelectSpecialist(specialist, e.target.checked)}
                    key={specialist.chronosId}
                    label={specialist.name}
                    componentExtraClass="px-4 py-2"
                    defaultChecked={specialists?.includes(specialist.chronosId)}
                  />
                ))}
              </div>
              <div className="specialist-filter-btn">
                <RegularButton
                  label="Aplicar"
                  disabled={specialists?.length === 0}
                  onClick={handleCloseAndApplySpecialists}
                  variant="primary"
                />
              </div>
            </div>
          )}
        </ResponsiveDrawer>
      )}
    </>
  )
}

const mapStateToProps = ({
  availabilities, specialists
}: AppState) => ({
  availableSpecialists: specialists.availableSpecialists,
  isGettingSpecialists: specialists.isGettingSpecialists,
  specialistsError: specialists.specialistsError,
  filteredSpecialists: specialists.filteredSpecialists,
  availabilitiesByDay: availabilities.availabilitiesByDay,
  exactDayMessage: availabilities.exactDayMessage,
  isGettingAvailabilities: availabilities.isGettingAvailabilities,
  isLoadingMoreSlots: availabilities.isLoadingMoreSlots,
  hasNextAvailabilities: availabilities.hasNextAvailabilities,
  availabilitiesError: availabilities.errorGettingAvailabilities,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  getAvailabilities: getAvailabilitiesAction,
  getAvailableSpecialists: getAvailableSpecialistsAction,
  setFilteredSpecialists: setFilteredSpecialistsAction,
  selectSpecialist: selectSpecialistAction,
  setLoadingSteps: setLoadingStepAction,
  clearPreviousSelectedSpecialist: setPreviousSelectedSpecialistAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseDayAndTime)
