/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { LocalAddressType } from '../utils/LocalAddress'
import { Professional } from './Professional'
import { SpecialistCategories } from './Specialist'
import { LocalDate } from './utils/Date'

export type PromotionalCodeView = {
  originalPrice: number;
  discount: number;
  promotionalPrice: number;
}

export type BookingConfirmationInfo = {
  startDate?: string;
  professionalName: string;
  address: LocalAddressType;
  alreadyConfirmed: boolean;
  alreadyCancelled: boolean;
  confirmationAvailable: boolean;
}

export type BookingProfessionalConfirmationInfo = {
  startDate?: string;
  professionalName: string;
  status?: string;
}

export type ScheduleType = 'InPerson' | 'Virtual';

export interface Prescriptions {
  id: string;
  created: number;
  pdfUrl: string;
}

export enum ScheduleTypeBR {
  PRESENTIAL = 'presencial',
  ONLINE = 'online'
}

export type BookingView = {
  id: string;
  consultantName: string;
  professionalName: string;
  startDate: LocalDate;
  endDate: LocalDate;
  token: string;
  tokenError: string;
  videoRoomName: string;
  chatToken: string;
}

export type Reason = {
  id: number;
  name: string;
  description: string;
  selected: boolean;
}

export type ReminderType = 'SIGNUP' | 'RIGHT_AFTER' | 'AFTER_WEEKS' | 'EXAM_REQUEST';

export type Reminders = {
  type: ReminderType;
  professionalSummary: Professional | null;
  category: SpecialistCategories;
  textHome: string;
  lastBookingIds: Array<string>;
  firstTime: boolean;
  priority: number;
  textPush: string | null;
}
export interface BookingSuggestionResponse {
  shouldSendReminder: boolean;
  reminders: Reminders[];
}

export interface BookingService {
  getFeedbackReasons: () => Promise<Array<Reason>>;
  getBookingsCancellationReasons: () => Promise<Array<Reason>>;
  sendFeedback: (
    bookingId: string,
    reasonsId: number[],
    otherMotive: string,
    meetExpectation: boolean
  ) => Promise<void>;
  cancelBooking: (
    bookingId: string,
    reasonsId: number[],
    otherMotive: string,
  ) => Promise<void>;
  getPromotionalCode: (
    codeCoupon?: string,
    productId?: string
  ) => Promise<PromotionalCodeView>;
  bookingConfirmationInfoSMS: (
    bookingId: string, token: string
  ) => Promise<BookingConfirmationInfo>;
  confirmBookingSMS: (
    bookingId: string, token: string, participantType?: string, professionalStatus?: string
  ) => Promise<void>;
  cancelBookingSMS: (bookingId: string, token: string) => Promise<void>;
  bookingProfessionalConfirmationInfoSMS: (
    bookingId: string, token: string
  ) => Promise<BookingProfessionalConfirmationInfo>;
  getBookingSuggestion(): Promise<BookingSuggestionResponse>;
  updateBookingSuggestion(
    reminderType: string,
    bookingIds?: string[],
    prescriptionId?: string
  ): Promise<void>;
}

export interface BookingViewService {
  getBookingView(uuid: string): Promise<BookingView>;
}
