import React from 'react'
import { ScheduleTypeBR } from '../../domain/Booking'
import './RescheduleDetails.scss'

interface Props {
  title: string;
  isPresential: boolean;
  professionalCategory?: string;
  professionalName?: string;
  startHourString?: string;
  dayString?: string;
  attendanceAddress?: string;
  isExam?: boolean;
}

export default function RescheduleDetails({
  professionalCategory,
  professionalName,
  isPresential,
  title,
  attendanceAddress,
  dayString,
  startHourString,
  isExam,
}: Props) {
  return (
    <div className="reschedule-details__container">
      <h1 className="reschedule-details__title">
        {title}
      </h1>
      <p className="reschedule-details__label-info">
        {isExam ? professionalCategory : 'Consulta'}
      </p>
      <p className="reschedule-details__text-info">
        {isExam ? professionalName : professionalCategory} - {isPresential
          ? ScheduleTypeBR.PRESENTIAL
          : ScheduleTypeBR.ONLINE}
      </p>
      {isPresential && (
        <p className="reschedule-details__address-text-info">
          {attendanceAddress}
        </p>
      )}
      {!isExam && (
        <>
          <p className="reschedule-details__label-info">
            Especialista
          </p>
          <p className="reschedule-details__text-info">
            {professionalName}
          </p>
        </>
      )}
      <p className="reschedule-details__label-info">
        Data e hora
      </p>
      <p className="reschedule-details__text-info">
        {dayString} às {startHourString}
      </p>
    </div>
  )
}
