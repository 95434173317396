/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Alert, RegularButton, Loader } from 'theia-web-ds'
import { success } from '../../../color'
import HealthPlanAccepted from '../../../../assets/Yes-Plancard.svg'
import { AppState } from '../../../apps/main/store'
import {
  HealthInsurancePlan,
  HealthInsuranceData,
  HealthPlanData,
  HealthInsurancePlanData
} from '../../../domain/Healthplan'
import { HealthPlanFaqAndBenefits } from './HealthplanFaqAndBenefits'
import { eventPage, eventTrack } from '../../../../eventGenerate'
import { AppDispatch } from '../../../state/utils'
import { getHealthInsurancePlanDataAction } from '../../../state/healthplanProfile/actions'
import IconWhatsapp from '../../../../assets/icon-whatsapp.png'
import { listOfPlansAcceptedByTheia } from '../../../utils/helpers'
import { Booking } from '../../../domain/Profile'
import BackButton from '../../common/buttons/BackButton'
import { AGENDAR, INICIO } from '../../../routes/RoutesConstants'
import { SourceTypes } from '../../../domain/ScheduleFlow'
import { setScheduleSourceAction } from '../../../state/scheduleFlow/actions'

interface Props {
  currentUserHealthDataBenefits?: Array<HealthInsuranceData>;
  benefitsFromPost?: Array<HealthInsuranceData>;
  currentUserHealthDataInfo?: HealthInsurancePlan | null;
  healthDataInfoFromPost?: HealthInsurancePlan | null;
  isSuccessPost?: boolean;
  getHealthInsurancePlan: () => void;
  healthInsurancePlan: HealthPlanData | null;
  bookings: Array<Booking>;
  setScheduleSource: (source: SourceTypes) => void;
}

interface LocationProps {
  isFromFeedbackChangePlan: boolean;
  hasPreviousInsurancePlan: boolean;
}

function HealthPlanIncluded({
  currentUserHealthDataBenefits,
  benefitsFromPost,
  currentUserHealthDataInfo,
  healthDataInfoFromPost,
  isSuccessPost,
  getHealthInsurancePlan,
  healthInsurancePlan,
  bookings,
  setScheduleSource
}: Props) {
  const [isVisible, setIsVisible] = useState(false)
  const [alertFromFeedbackMessage, setAlertFromFeedbackMessage] = useState('')
  const [containerHeight, setContainerHeight] = useState(764)
  const [planName, setPlanName] = useState<string | null | undefined >('')
  const [companyName, setCompanyName] = useState<string | null | undefined >('')
  const location = useLocation().state as LocationProps
  const isFromFeedbackChangePlan = location?.isFromFeedbackChangePlan
  const hasPreviousInsurancePlan = location?.hasPreviousInsurancePlan
  const [benefits, setBenefits] = useState<[] | unknown>()
  const [includedCategories, setIncludedCategories] = useState<number>(0)
  const [healthInsurancePlanData, setHealthInsurancePlanData] = useState<HealthInsurancePlanData>()
  const bookingsToShow: Booking[] = bookings.length > 2 ? [bookings[0], bookings[1]] : bookings
  let categories: number;

  const history = useHistory()

  useEffect(() => {
    getHealthInsurancePlan()
  }, [])

  useEffect(() => {
    if (healthInsurancePlan) {
      setHealthInsurancePlanData(healthInsurancePlan.healthInsurancePlanData)
    }
  }, [healthInsurancePlan])

  useEffect(() => {
    if (healthInsurancePlanData?.healthInsurancePlan?.company
        && listOfPlansAcceptedByTheia.includes(healthInsurancePlanData?.healthInsurancePlan?.company)
    ) {
      setBenefits(healthInsurancePlanData?.benefits)
      if (currentUserHealthDataInfo) {
        setPlanName(healthInsurancePlanData?.healthInsurancePlan?.planName)
        setCompanyName(healthInsurancePlanData?.healthInsurancePlan?.company)
      }
    } else {
      if (isFromFeedbackChangePlan && isSuccessPost) {
        setBenefits(benefitsFromPost)
        if (benefitsFromPost) {
          categories = benefitsFromPost.map((item) => item.categoryName).filter((cn) => cn !== null).length
          setIncludedCategories(categories)
        }
        if (healthDataInfoFromPost) {
          setPlanName(healthDataInfoFromPost.planName)
          setCompanyName(healthDataInfoFromPost.company)
        }
        return
      }
      setBenefits(currentUserHealthDataBenefits)
      if (currentUserHealthDataBenefits) {
        categories = currentUserHealthDataBenefits.map((item) => item.categoryName).filter((cn) => cn !== null).length
        setIncludedCategories(categories)
      }
      if (currentUserHealthDataInfo) {
        setPlanName(currentUserHealthDataInfo.planName)
        setCompanyName(currentUserHealthDataInfo.company)
      }
    }
  }, [healthInsurancePlanData])

  useEffect(() => {
    if (isFromFeedbackChangePlan && !hasPreviousInsurancePlan) {
      setAlertFromFeedbackMessage('Tudo certo! Seu plano de saúde foi adicionado com sucesso.')
    } else {
      setAlertFromFeedbackMessage('Tudo certo! Seu plano de saúde foi atualizado com sucesso.')
    }
  }, [])

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const listenToScroll = () => {
    if (mounted.current === true) {
      const itemb = document.querySelector('.item-b')
      const heightToHideFrom = 90
      if (itemb) setContainerHeight(itemb.scrollTop)
      const winScroll = itemb ? itemb.scrollTop : document.body.scrollTop
      if (winScroll > heightToHideFrom) {
        setIsVisible(true)
      } else {
        setIsVisible(false);
      }
    }
    return () => {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    eventPage('incluso no plano', 'incluso no plano')
    const itemb = document.querySelector('.item-b')
    if (itemb) itemb.addEventListener('scroll', listenToScroll)
    return () => {
      window.removeEventListener('scroll', listenToScroll)
      setIsVisible(false)
    }
  }, [])

  const goToSchedule = () => {
    eventTrack('Clicou Agendar consulta')
    setScheduleSource(SourceTypes.HEALTHPLAN_INCLUDED)
    history.push(AGENDAR)
  }

  function goToPage(link: string) {
    eventTrack('Clicou Falar com a gente')
    window.open(link, '_blank')
  }

  function goToLocationUsingHistoryPush(link: string, flutterKey?: string) {
    if (flutterKey === 'LIST_ITEM_WITH_CATEGORY') {
      eventTrack('Clicou Agendar Agora')
    } else {
      eventTrack('Clicou Agendar Depois')
    }
    history.push(link)
  }

  if (benefits) {
    return (
      <div className="flex flex-1 relative">
        <div className="mx-auto w-full" style={{ height: `${containerHeight + 764}px` }}>
          {isFromFeedbackChangePlan && (
          <Alert
            iconType="icon-TickRoundLight"
            isBottom
            message={alertFromFeedbackMessage}
            extraClass="alert-profile-picture"
            iconColor={success}
            timer={5000}
            hasCloseButton={false}
          />
          )}
          <div className={`sticky flex items-center bg-bgCanvas top-0 z-10 h-20 ${isVisible ? 'border-divider border-solid border-b sticky-profile-bar' : ''}`}>
            <BackButton text={isVisible ? 'Incluso no plano' : ''} to="/profile" extraClass="back-button-svg" />
          </div>
          <div className="lg:max-w-xl mx-auto w-full">
            <div className="flex p-4 health-plan-welcome-card">
              <div><HealthPlanAccepted /></div>
              <div className="pl-4">
                <p className="text-fontDefault text-textPrimary font-medium">
                  Plano {companyName} {planName}
                </p>
                {(companyName && !listOfPlansAcceptedByTheia.includes(companyName))
                  || (!healthInsurancePlanData?.benefitsSubtitle) ? (
                    <p className="text-fontDefault text-textSecondary">
                      {includedCategories <= 1 && (`Você pode agendar consultas com ${includedCategories} especialidade sem custo adicional.`)}
                      {includedCategories > 1 && (`Você pode agendar consultas com ${includedCategories} especialidades sem custo adicional.`)}
                    </p>
                  ) : (
                    <p className="text-fontDefault text-textSecondary"> {healthInsurancePlanData?.benefitsSubtitle} </p>
                  )}
              </div>
            </div>
            <div className="px-4">
              <RegularButton
                label="Agendar agora"
                extraClass="mt-6 mb-8 mx-auto button-healthplan-feedback"
                onClick={goToSchedule}
              />
            </div>
            <p className="text-fontDefault font-medium mb-4 text-left mx-4">
              Está incluso no seu plano
            </p>
            <HealthPlanFaqAndBenefits
              benefits={benefits}
              iconColor={success}
              iconType="icon-TickRoundLight"
              screen="health-plan-included"
            />
          </div>
          {bookingsToShow.length === 0 && (
            <div className="mx-4">
              <img src={IconWhatsapp} alt="Whatsapp" className="mb-4 mt-6 mx-auto rounded-full" />
              <p className="text-textMedium text-center font-normal justify-center mt-4 mb-2">
                Ainda em dúvida?
              </p>
              <p className="text-fontDefault text-textSecondary text-center">
                Nosso time está pronto para te atender <span role="img" aria-label="carinha mandando um abraço">🤗</span>
              </p>
              <RegularButton
                onClick={() => goToPage('https://atendimento.theia.com.br/whatsapp')}
                variant="subtle"
                label="Falar com a gente"
                extraClass="my-6 button-healthplan-feedback mx-auto"
              />
              <RegularButton
                onClick={() => goToLocationUsingHistoryPush(INICIO, 'GO_TO_HOME')}
                variant="text"
                label="Agendar depois"
                extraClass="mb-6 button-healthplan-feedback mx-auto"
              />
            </div>
          )}
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-1 relative">
      <div className="mx-auto w-full flex items-center justify-center">
        <Loader />
      </div>
    </div>
  )
}

const mapStateToProps = ({
  authentication, healthplan, profile
}: AppState) => ({
  currentUserHealthDataBenefits: authentication.currentUser?.healthInsurancePlanData.benefits,
  benefitsFromPost: healthplan.benefits,
  currentUserHealthDataInfo: authentication.currentUser ? authentication.currentUser.healthInsurancePlanData.healthInsurancePlan : null,
  healthDataInfoFromPost: healthplan.healthInsurancePlanData.healthInsurancePlan,
  isSuccessPost: healthplan.isSuccess,
  healthInsurancePlan: healthplan.healthInsurancePlan,
  bookings: profile.allBookings,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  getHealthInsurancePlan: getHealthInsurancePlanDataAction,
  setScheduleSource: setScheduleSourceAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthPlanIncluded)
