import React, { HTMLAttributes } from 'react'
import { FontIcon } from '../FontIcon/FontIcon'
import { Loader } from '../Loader/Loader'
import { BaseButton } from '../BaseButton/BaseButton'
import { primary } from '../../colors'
import './RegularButton.scss'

export type BtnVariant = 'primary'
| 'secondary'
| 'subtle'
| 'text'
| 'secondary-text'
| 'modal-btn';

export interface Props extends HTMLAttributes<HTMLButtonElement> {
  variant?: BtnVariant;
  isSubmitting?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>
  ) => void;
  onClickDisabled?: (
    e?: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>
  ) => void;
  disabled?: boolean;
  width?: string;
  maxWidth?: string;
  height?: string;
  iconType?: string;
  btnSize?: 'small' | 'large';
  label?: string;
  extraClass?: string;
  iconSize?: string;
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  isMountingScreen?: boolean;
  isLoadingScreen?: boolean;
  labelSize?: string;
  isIconAndLabel?: boolean;
  direction?: 'flex-col' | 'flex-row' | 'flex-row-reverse';
  minWidth?: string;
}

export function RegularButton({
  variant = 'primary',
  isSubmitting,
  onClick,
  onClickDisabled,
  disabled,
  iconType,
  btnSize = 'large',
  width,
  label,
  extraClass,
  maxWidth,
  iconSize,
  id,
  type,
  isMountingScreen = false,
  isLoadingScreen = false,
  labelSize,
  isIconAndLabel,
  direction = 'flex-row',
  minWidth,
  ...props
}: Props) {
  const realDisabled = !!(disabled && !onClickDisabled)

  function handleClick(
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>
  ) {
    if (disabled && onClickDisabled) {
      onClickDisabled(e)
    }
    if (!disabled && onClick) {
      onClick(e)
    }
  }

  if (isMountingScreen) {
    return (
      <div
        data-testid="button-skelleton"
        className={`text-center btn-material loading
          ${btnSize}
          ${variant}
          ${disabled ? 'disabled' : ''}
          ${extraClass}
        `}
      />
    )
  }
  return (
    <BaseButton
      id={id}
      className={`text-center btn-material
        ${btnSize}
        ${variant}
        ${disabled ? 'disabled' : ''}
        ${direction}
        direction--${direction}
        ${extraClass}
      `}
      borderRadius={variant !== 'text' ? '80px' : ''}
      onClick={handleClick}
      width={width}
      disabled={isSubmitting || realDisabled}
      maxWidth={maxWidth}
      minWidth={minWidth}
      type={type}
      {...props}
      IconStart={!isSubmitting && iconType && (
        <FontIcon
          iconType={iconType}
          fontSize={iconSize}
          extraClass={`${isIconAndLabel ? 'icon-relative' : ''}`}
        />
      )}
    >
      {isSubmitting ? (
        <Loader
          color={variant === 'primary' ? '#FFFFFF' : primary}
        />
      ) : (
        <p
          className={`label-btn-material
            ${isLoadingScreen ? 'loading-text' : ''}
            ${variant}
          `}
          style={{ fontSize: labelSize }}
        >
          {label}
        </p>
      )}
    </BaseButton>
  )
}
