import moment, { Moment } from 'moment'
import { LocalDate as LocalDateI } from '../domain/utils/Date'

export class LocalDate implements LocalDateI {
  protected _value: Moment

  constructor(rawValue?: Date | string | Moment) {
    this._value = moment(rawValue)
  }

  get value(): Moment {
    return this._value
  }

  format(): string {
    return this.value.format('YYYY-MM-DDTHH:mm:ssZZ')
    // Saida: 2022-01-17T14:30:00+00:00
  }

  formatDateToHours(): string {
    return this.value.format('HH:mm')
    // Saída: 14:30
  }

  formatDateToWeekday(): string {
    const weekday = this.value.format('dddd').split('-')[0]
    return weekday
    // Saída: segunda
  }

  formatDateToDayOfMonthAndWeekday(): string {
    const weekday = this.value.format('dddd').split('-')[0]
    return `${this.value.format('DD/MM')} - ${weekday}`
    // Saída: 17/01 - segunda
  }

  formatDateToWeekdayAndDayOfMonth(): string {
    const weekday = this.value.format('dddd').split('-')[0]
    return `${weekday} - ${this.value.format('DD/MM')}`
    // Saída: segunda - 17/01
  }

  formatDateToToWeekDay(): string {
    const weekday = this.value.format('ddd').split('-')[0]
    return `${weekday}
    ${this.value.format('DD')}`
    // Saída: Seg 17
  }

  formatToLongDate(): string {
    return this.value.format('DD/MM/YYYY, dddd').toLowerCase()
    // Saída: 17/01/2022, segunda-feira
  }

  formatMonth(): string {
    return this.value.format('MMM').toLowerCase()
    // Saída: jan
  }

  formatYear(): string {
    return this.value.format('YYYY').toLowerCase()
    // Saída: 2022
  }

  formatFullMonth(): string {
    return this.value.format('MMMM').toLowerCase()
    // Saída: janeiro
  }

  formatDayTwoDigits(): string {
    return this.value.format('DD')
    // Saída: 17
  }

  formatDayAndMonthTwoDigits(): string {
    return this.value.format('DD/MM')
    // Saída: 17/01
  }

  formatMonthAndYear(): string {
    const month = this.value.format('MMMM')
    return `${month}, ${this.value.format('YYYY')}`
    // Saída: janeiro, 2022
  }

  formatDayAndMonthAndYearTwoDigits(): string {
    return this.value.format('DD/MM/YY')
    // Saída: 17/01/22
  }

  formatDayAndMonthAndYear(): string {
    return this.value.format('DD/MM/YYYY')
    // Saída: 17/01/2022
  }

  isAfter(date: LocalDateI): boolean {
    return this.value.isAfter(date.value)
  }

  isBefore(date: LocalDateI): boolean {
    return this.value.isBefore(date.value)
  }

  hasPassedOneDay(): boolean {
    const now = new LocalDate()
    const passedHours = now.value.diff(this.value, 'hours')
    return passedHours >= 24
  }

  hasPassedOneHour(): boolean {
    const now = new LocalDate()
    const passedHours = now.value.diff(this.value, 'minutes')
    return passedHours >= 60
  }

  hasPassedOneMinute(): boolean {
    const now = new LocalDate()
    const passedHours = now.value.diff(this.value, 'minutes')
    return passedHours >= 1
  }

  toDate(): Date {
    return this.value.toDate()
    // Saída: Mon Jan 17 2022 14:30:00 GMT-0300 (Brasília Standard Time)
  }

  hasPassedTokenExpirationTime(): boolean {
    const now = new LocalDate()
    const passedHours = now.value.diff(this.value, 'minutes')
    return passedHours >= 360
  }

  toEpochTimestamp(): number {
    return this.value.unix() * 1000
    // Saída: 1642444200000
  }

  formatToWeekdayAndMonthDay(): string {
    const fullDate = this.value.format('dddd, DD [de] MMMM').toLowerCase()
    const [weekday, monthDay] = fullDate.split(', ')
    const reducedWeekday = weekday.split('-')[0]
    const formattedWeekday = reducedWeekday.charAt(0).toUpperCase() + weekday.slice(1)
    return `${formattedWeekday}, ${monthDay}`
    // Saída: Segunda, 17 de janeiro
  }
}
