import * as Yup from 'yup'

export interface PaymentFormValues {
  country: string;
  name: string;
  postalCode: string;
  codeCoupon?: string;
}

export interface PaymentFormValuesProfile {
  name: string;
}

export const paymentFormStyle = {
  base: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '400',
    fontFamily: 'Rubik, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontStyle: 'italic',
    },
  },
  invalid: {
    color: '#E5495F',
    iconColor: '#E5495F',
  },
}

export const countries = [
  { name: '', code: '' },
  { name: 'Brasil', code: 'BR' },
  { name: 'United States', code: 'US' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Canada', code: 'CA' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'Germany', code: 'DE' },
  { name: 'Greece', code: 'GR' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'India', code: 'IN' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Italy', code: 'IT' },
  { name: 'Japan', code: 'JP' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Norway', code: 'NO' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'United Kingdom', code: 'GB' }
]

type errorOptions = {
  [key: string]: string;
}

export function checkCountryCode(country: string) {
  let result = country
  countries.map((index) => {
    if (country === index.name) {
      result = index.code
    }
    return result
  })
  return result
}

export const errorMessages: errorOptions = {
  invalid_number: 'O número de seu cartão é inválido.',
  incomplete_number: 'O número de seu cartão está incompleto.',
  incomplete_expiry: 'A data de expiração de seu cartão está incompleta.',
  invalid_expiry_year: 'O ano de expiração de seu cartão é inválido.',
  invalid_cvc: 'O código de segurança de seu cartão é inválido.',
  incomplete_cvc: 'O código de segurança de seu cartão está incompleto.',
  incomplete_zip: 'Seu código postal está incompleto.',
  invalid_expiry_year_past: 'O ano de expiração de seu cartão já passou.',
  invalid_expiry_month_past: 'A data de expiração do seu cartão já passou.'
}

export const initialValues = {
  country: '',
  name: '',
  postalCode: '',
  codeCoupon: '',
}

export const validationSchema = Yup.object().shape({
  country: Yup.string()
    .required('País obrigatório'),
  name: Yup.string().required('Nome obrigatório'),
  postalCode: Yup.string()
    .required('CEP obrigatório')
    .matches(/^\d{5}-\d{3}$/, 'CEP inválido'),
})
