import * as Sentry from '@sentry/browser'
import { BookingService } from '../../domain/Booking'
import { UserProvider } from '../../domain/User'
import { HttpClient } from '../../services/Http'
import { AsyncAction } from '../utils'
import { success } from '../../color'
import { showNotificationAction } from '../notifications/actions'
import { ToastType } from '../../domain/Notifications'
import { API_URL } from '../../services/api/ApiClient'

export const FETCH_PROMOTIONAL_CODE_REQUESTED = 'FETCH_PROMOTIONAL_CODE_REQUESTED'
export const FETCH_PROMOTIONAL_CODE_SUCCESS = 'FETCH_PROMOTIONAL_CODE_SUCCESS'
export const FETCH_PROMOTIONAL_CODE_FAILURE = 'FETCH_PROMOTIONAL_CODE_FAILURE'
export const CLEAN_PROMOTIONAL_CODE = 'CLEAN_PROMOTIONAL_CODE'
export const CHANGE_COUPON_VALUE = 'CHANGE_COUPON_VALUE'
export const CONFIRM_CARD_PAYMENT_METHOD = 'CONFIRM_CARD_PAYMENT_METHOD'

export const UPDATE_CARD_INFO_REQUESTED = 'UPDATE_CARD_INFO_REQUESTED'
export const SUCCESS_UPDATE_CARD_INFO = 'SUCCESS_UPDATE_CARD_INFO'
export const FAILURE_UPDATE_CARD_INFO = 'FAILURE_UPDATE_CARD_INFO'
export const DELETE_CARD_INFO_REQUESTED = 'DELETE_CARD_INFO_REQUESTED'
export const SUCCESS_DELETE_CARD_INFO = 'SUCCESS_DELETE_CARD_INFO'
export const FAILURE_DELETE_CARD_INFO = 'FAILURE_DELETE_CARD_INFO'
export const BACK_TO_PROFILE_FROM_UPDATE_CARD = 'BACK_TO_PROFILE_FROM_UPDATE_CARD'
export const PROMPT_NEW_CARD_ERROR = 'PROMPT_NEW_CARD_ERROR'
export const CLEAR_NEW_CARD_ERROR = 'CLEAR_NEW_CARD_ERROR'

interface FetchPromotionalCodeRequestedActionType {
  type: typeof FETCH_PROMOTIONAL_CODE_REQUESTED;
}

interface CleanPromotionalCodeActionType {
  type: typeof CLEAN_PROMOTIONAL_CODE;
}

export interface FetchPromotionalCodeSuccessActionType {
  type: typeof FETCH_PROMOTIONAL_CODE_SUCCESS;
  originalPrice: number;
  discount: number;
  promotionalPrice: number;
}

interface FetchPromotionalCodeFailureActionType {
  type: typeof FETCH_PROMOTIONAL_CODE_FAILURE;
  error: {
    message?: string;
    title?: string;
    statusCode?: string;
  };
}

interface ChangeCouponValueActionType {
  type: typeof CHANGE_COUPON_VALUE;
  couponValue?: string;
}

interface ConfirmCardPaymentMethodActionType {
  type: typeof CONFIRM_CARD_PAYMENT_METHOD;
  paymentMethodId: string;
}

interface UpdateCardRequestedActionType {
  type: typeof UPDATE_CARD_INFO_REQUESTED;
}

interface SuccessUpdateCarddActionType {
  type: typeof SUCCESS_UPDATE_CARD_INFO;
}
interface FailureUpdateCarddActionType {
  type: typeof FAILURE_UPDATE_CARD_INFO;
}

interface DeleteCardRequestedActionType {
  type: typeof DELETE_CARD_INFO_REQUESTED;
}

interface SuccessDeleteCarddActionType {
  type: typeof SUCCESS_DELETE_CARD_INFO;
}
interface FailureDeleteCarddActionType {
  type: typeof FAILURE_DELETE_CARD_INFO;
}

interface BackToProfileFromUpdateCardPaymentInfoActionType {
  type: typeof BACK_TO_PROFILE_FROM_UPDATE_CARD;
}

interface PromptNewCardErrorActionType {
  type: typeof PROMPT_NEW_CARD_ERROR;
}

interface ClearNewCardErrorActionType {
  type: typeof CLEAR_NEW_CARD_ERROR;
}

export type PaymentTypes =
  FetchPromotionalCodeSuccessActionType |
  FetchPromotionalCodeFailureActionType |
  FetchPromotionalCodeRequestedActionType |
  CleanPromotionalCodeActionType |
  ChangeCouponValueActionType |
  ConfirmCardPaymentMethodActionType |
  UpdateCardRequestedActionType |
  SuccessUpdateCarddActionType |
  FailureUpdateCarddActionType |
  DeleteCardRequestedActionType |
  SuccessDeleteCarddActionType |
  FailureDeleteCarddActionType |
  BackToProfileFromUpdateCardPaymentInfoActionType |
  PromptNewCardErrorActionType |
  ClearNewCardErrorActionType

export function fetchPromotionalPriceAction(
  codeCoupon?: string, productId?: string
): AsyncAction<{}, {
  bookingService: BookingService
}> {
  return async (dispatch, _getState, { bookingService }) => {
    try {
      dispatch({ type: FETCH_PROMOTIONAL_CODE_REQUESTED })
      const promotionalCodeView = await bookingService.getPromotionalCode(
        codeCoupon,
        productId
      )
      dispatch({
        type: FETCH_PROMOTIONAL_CODE_SUCCESS,
        originalPrice: promotionalCodeView.originalPrice,
        discount: promotionalCodeView.discount,
        promotionalPrice: promotionalCodeView.promotionalPrice
      })
      dispatch(showNotificationAction({
        message: 'Cupom aplicado com sucesso!',
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 5000,
        id: 'FETCH_PROMOTIONAL_CODE_SUCCESS',
        type: ToastType.SUCCESS
      }))
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes('Invalid promotional code')) {
          const errorObj = JSON.parse(error.message)
          Sentry.captureMessage(`Failure fetching promotional code - ${errorObj.friendlyMessage}`, Sentry.Severity.Info);
          dispatch({
            type: FETCH_PROMOTIONAL_CODE_FAILURE,
            error: {
              message: errorObj.friendlyMessage,
              title: errorObj.friendlyMessageTitle
            }
          })
        } else {
          Sentry.captureException(new Error(`Failure fetching promotional code - ${error.message}`))
          dispatch({
            type: FETCH_PROMOTIONAL_CODE_FAILURE,
            error: {
              message: error.message
            }
          })
        }
      }
    }
  }
}

export const onGoBackToProfileAfterUpdateCardPaymentInfoAction = ():
BackToProfileFromUpdateCardPaymentInfoActionType => ({
  type: BACK_TO_PROFILE_FROM_UPDATE_CARD,
})

export const confirmCardPaymentMethodAction = (
  paymentMethodId: string
): PaymentTypes => ({
  type: CONFIRM_CARD_PAYMENT_METHOD,
  paymentMethodId
})

export const cleanPromotionalCodeAction = (): PaymentTypes => ({
  type: CLEAN_PROMOTIONAL_CODE,
})

export const changeCouponValueAction = (couponValue?: string): PaymentTypes => ({
  type: CHANGE_COUPON_VALUE,
  couponValue
})

export function onUpdateCardInfoAction(paymentMethodId: string):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: UPDATE_CARD_INFO_REQUESTED })
    try {
      const url = `${API_URL}/v4/payments/payment-method/${paymentMethodId}`
      const response = await httpClient.put({ url })

      if (response.status !== 200) {
        dispatch({ type: FAILURE_UPDATE_CARD_INFO })
        Sentry.captureException(new Error(`Failure uploading card info - ${response.body}`))
      } else {
        dispatch({
          type: SUCCESS_UPDATE_CARD_INFO
        })
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: FAILURE_UPDATE_CARD_INFO })
        Sentry.captureException(new Error(`Unexpected error uploading card info - ${error.message}`))
      }
    }
  }
}

export function onDeleteCardInfoAction():
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: DELETE_CARD_INFO_REQUESTED })
    try {
      const url = `${API_URL}/v4/payments/payment-method`
      const response = await httpClient.delete({ url })

      if (response.status !== 200) {
        dispatch({ type: FAILURE_DELETE_CARD_INFO })
        Sentry.captureException(new Error(`Failure uploading card info - ${response.body}`))
      } else {
        dispatch({
          type: SUCCESS_DELETE_CARD_INFO
        })
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: FAILURE_DELETE_CARD_INFO })
        Sentry.captureException(new Error(`Unexpected error uploading card info - ${error.message}`))
      }
    }
  }
}

export const promptNewCardErrorAction = ():
PromptNewCardErrorActionType => ({
  type: PROMPT_NEW_CARD_ERROR,
})

export const clearNewCardErrorAction = ():
ClearNewCardErrorActionType => ({
  type: CLEAR_NEW_CARD_ERROR,
})
