import React from 'react'
import { useField } from 'formik'
import SelectOptions from './SelectOptions'
import './Input.scss'

interface Props {
  name: string;
  label?: string;
  options: string[];
  formSelectOnBlur?: () => void;
  placeholder?: string;
  disabled?: boolean;
  formSelectOnChange?: (e: React.ChangeEvent) => void;
}

export default function FormSelect({
  label,
  name,
  options,
  placeholder,
  disabled,
  formSelectOnBlur,
  formSelectOnChange
}: Props) {
  const [field, meta] = useField({ name })
  const { onBlur, onChange, value } = field
  const error = meta.touched && meta.error
  const handleChange = (e: React.ChangeEvent) => {
    if (formSelectOnChange) {
      formSelectOnChange(e)
    } else {
      onChange(e)
    }
  }
  return (
    <div className="input-group">
      {label && <label className="label-normal mb-2">{label}</label>}
      <SelectOptions
        id={name}
        options={options}
        value={value}
        onChange={handleChange}
        onBlur={formSelectOnBlur || onBlur}
        error={error}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  )
}
