import {
  PediatricFlowTypes,
  UPDATE_CHILDREN,
  SUCCESS_UPDATE_CHILDREN,
  FAILURE_UPDATE_CHILDREN,
  SUPPRESS_PEDIATRIC_WELCOME_MODAL,
  GET_CHILDREN_DATA,
  SUCCESS_GET_CHILDREN_DATA,
  FAILURE_GET_CHILDREN_DATA,
  CLEAR_UPDATE_CHILDREN_ALERT,
  GET_CHILDREN_SUMMARY,
  SUCCESS_GET_CHILDREN_SUMMARY,
  FAILURE_GET_CHILDREN_SUMMARY,
  SHOW_PEDIATRIC_WELCOME_MODAL,
  SUCCESS_UPDATE_CHILD_HEALTHPLAN,
  FAILURE_UPDATE_CHILD_HEALTHPLAN,
  UPDATE_CHILD_HEALTHPLAN
} from './actions'
import {
  BabyData,
  ChildrenSummary
} from '../../domain/PediatricFlow'
import { havePlan } from '../../utils/helpers';
import { TheiaError } from '../../domain/errors/TheiaError';
import { NotificationMessage } from '../../domain/Notifications'

export interface PediatricFlowState {
  isGettingPediatricJourney: boolean;
  showPediatricWelcomeModal: boolean;
  isPregnantAndShowModal: boolean;
  isUpdatingBabies: boolean;
  childrenData?: BabyData[];
  isGettingChildrenData: boolean;
  failureGettingChildrenData: boolean;
  failureUpdatingChildren: boolean;
  successUpdatingChildren: boolean;
  childrenSummary?: ChildrenSummary;
  isGettingChildrenSummary: boolean;
  successMessage?: string;
  error?: TheiaError;
}

const initialPediatricFlowState: PediatricFlowState = {
  isGettingPediatricJourney: false,
  showPediatricWelcomeModal: true,
  isUpdatingBabies: false,
  childrenData: undefined,
  isGettingChildrenData: false,
  failureGettingChildrenData: false,
  isPregnantAndShowModal: false,
  failureUpdatingChildren: false,
  successUpdatingChildren: false,
  childrenSummary: undefined,
  isGettingChildrenSummary: false,
  successMessage: undefined,
  error: undefined
}

export function pediatricFlow(
  state = initialPediatricFlowState,
  action: PediatricFlowTypes
): PediatricFlowState {
  switch (action.type) {
    case UPDATE_CHILDREN: {
      return {
        ...state,
        isUpdatingBabies: true,
        failureUpdatingChildren: false,
        successUpdatingChildren: false,
        error: undefined
      }
    }
    case SUCCESS_UPDATE_CHILDREN: {
      return {
        ...state,
        isUpdatingBabies: false,
        failureUpdatingChildren: false,
        successUpdatingChildren: true,
        successMessage: NotificationMessage.DATA_UPDATED_SUCCESSFULLY
      }
    }
    case FAILURE_UPDATE_CHILDREN: {
      return {
        ...state,
        isUpdatingBabies: false,
        failureUpdatingChildren: true,
        successUpdatingChildren: false,
        error: action.error
      }
    }
    case GET_CHILDREN_DATA: {
      return {
        ...state,
        isGettingChildrenData: true,
        failureGettingChildrenData: false,
        error: undefined,
      }
    }
    case SUCCESS_GET_CHILDREN_DATA: {
      return {
        ...state,
        isGettingChildrenData: false,
        childrenData: action.childrenData,
        failureGettingChildrenData: false
      }
    }
    case FAILURE_GET_CHILDREN_DATA: {
      return {
        ...state,
        isGettingChildrenData: false,
        failureGettingChildrenData: true,
        error: action.error
      }
    }
    case GET_CHILDREN_SUMMARY: {
      return {
        ...state,
        isGettingChildrenSummary: true
      }
    }
    case SUCCESS_GET_CHILDREN_SUMMARY: {
      return {
        ...state,
        isGettingChildrenSummary: false,
        childrenSummary: action.childrenSummary
      }
    }
    case FAILURE_GET_CHILDREN_SUMMARY: {
      return {
        ...state,
        isGettingChildrenSummary: false,
      }
    }
    case SUPPRESS_PEDIATRIC_WELCOME_MODAL: {
      return {
        ...state,
        showPediatricWelcomeModal: false,
        isPregnantAndShowModal: false
      }
    }
    case SHOW_PEDIATRIC_WELCOME_MODAL: {
      return {
        ...state,
        showPediatricWelcomeModal: true,
        isPregnantAndShowModal: true
      }
    }
    case CLEAR_UPDATE_CHILDREN_ALERT: {
      return {
        ...state,
        failureUpdatingChildren: false,
        successUpdatingChildren: false,
        successMessage: undefined,
        error: undefined
      }
    }
    case UPDATE_CHILD_HEALTHPLAN: {
      return {
        ...state,
        isUpdatingBabies: true,
        successUpdatingChildren: false,
        failureUpdatingChildren: false,
        error: undefined,
        successMessage: undefined
      }
    }
    case SUCCESS_UPDATE_CHILD_HEALTHPLAN: {
      if (!state.childrenData) return state
      const updatedBabies = state.childrenData?.map(
        (baby: BabyData) => (baby.id === action.childData.id ? {
          ...baby,
          ...action.childData
        } : {
          ...baby
        })
      )
      return {
        ...state,
        isUpdatingBabies: false,
        successUpdatingChildren: true,
        failureUpdatingChildren: false,
        childrenData: updatedBabies,
        successMessage: havePlan(action.healthInsurancePlanStatus)
          ? NotificationMessage.HEALTHPLAN_REGISTERED_SUCCESSFULLY
          : NotificationMessage.DATA_UPDATED_SUCCESSFULLY
      }
    }
    case FAILURE_UPDATE_CHILD_HEALTHPLAN: {
      return {
        ...state,
        isUpdatingBabies: false,
        successUpdatingChildren: false,
        failureUpdatingChildren: true,
        error: action.error
      }
    }
    default:
      return state;
  }
}
