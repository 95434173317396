import React from 'react'

interface Props {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  disabledColor?: string;
  opacity?: string;
}

export default function ChipButton({
  onClick, children, className, disabled, disabledColor, opacity
}: Props) {
  const disabledColorFinal = disabledColor || 'rgba(229, 73, 95, 0.5)'
  return (
    <button
      type="button"
      onClick={() => onClick()}
      className={`confirm-button text-white py-2 px-4 rounded-full bg-primary focus:outline-none ${className}`}
      style={{ fontSize: '14px', backgroundColor: `${disabled ? disabledColorFinal : ''}`, opacity }}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
