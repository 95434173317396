import React from 'react'
import { textPrimary } from '../../colors'

export interface Props {
  children: React.ReactNode;
  className?: string;
  textColor?: string;
}

export function ListItem({
  children,
  className,
  textColor
}: Props) {
  return (
    <li
      className={`font-light text-textSmall ${className}`}
      style={{
        color: textColor || textPrimary,
        marginTop: '2px'
      }}
      data-testid="li-list-item"
    >
      <span
        style={{
          left: '-2px',
          position: 'relative'
        }}
      >
        {children}
      </span>
    </li>
  )
}
