import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import {
  RegularButton,
  Divider,
  FontIcon,
  ToolTip
} from 'theia-web-ds';
import { AppDispatch } from '../../state/utils'
import './BookingCard.scss'
import { Booking, BookingStateOptions } from '../../domain/Profile';
import ProfilePicture from '../common/molecules/ProfilePicture';
import { creatEventOnGoogleCalendar, getBookingTypeBR, getGoogleCalendarEventInfos } from '../../utils/helpers';
import { promptDrawerAction } from '../../state/drawer/actions';
import BookingDetailsDrawer from './BookingDetailsDrawer';
import BookingStatusTag from './BookingStatusTag';
import { secondary, textDisable } from '../../color';
import { onUpdateBookingSuggestionAction } from '../../state/scheduleSuggestion/actions';
import { fetchBookingHistoryAction } from '../../state/myBookingsFlow/actions';
import { eventTrack } from '../../../eventGenerate';
import { PatientType, SourceTypes } from '../../domain/ScheduleFlow'
import { SpecialistCategories } from '../../domain/Specialist';
import {
  setPatientTypeAction,
  setPreviousSelectedCategoryAction,
  setPreviousSelectedChildIdAction,
  setPreviousSelectedSpecialistAction,
  setScheduleSourceAction
} from '../../state/scheduleFlow/actions'
import { AGENDAR } from '../../routes/RoutesConstants'

interface Props {
  booking?: Booking;
  promptDrawer: () => void;
  extraActionRequestCreateCalendarEvent?: () => void;
  extraActionClickShowMoreBooking?: () => void;
  onShowBookingDetails?: () => void;
  showTagStatus?: boolean;
  onUpdateScheduleSuggestion: (type: string, bookingId?: Array<string>) => void;
  fetchBookingHistory: () => void;
  setPreviousSelectedCategory: (category: SpecialistCategories) => void;
  setPatientType: (patientType: PatientType) => void;
  setScheduleSource: (source: SourceTypes) => void;
  setPreviousSelectedSpecialist: (specialistId: string) => void;
  setPreviousSelectedChildId: (childId: string) => void;
}

function BookingCard({
  booking,
  promptDrawer,
  extraActionRequestCreateCalendarEvent,
  extraActionClickShowMoreBooking,
  onShowBookingDetails,
  showTagStatus = true,
  onUpdateScheduleSuggestion,
  fetchBookingHistory,
  setPreviousSelectedCategory,
  setPatientType,
  setScheduleSource,
  setPreviousSelectedSpecialist,
  setPreviousSelectedChildId,
}: Props) {
  const history = useHistory()
  const [isShowBookingDetails, setIsShowBookingDetails] = useState(false)
  const [isChildrenBooking, setIsChildrenBooking] = useState(false)
  if (!booking) {
    return (
      <div
        className="booking-card-container"
      >
        <div className="booking-card-inner">
          <div className="booking-card-left-container-loading">
            <div
              className="booking-card-picture booking-card-picture-loading"
            />
          </div>
          <div className="booking-card-right-container">
            <div className="booking-card-type-loading" />
            <div className="booking-card-name-loading" />
            <div className="booking-card-category-loading" />
            <div className="booking-card-hour-container">
              <div className="booking-card-icon-loading" />
              <div className="booking-card-hour-loading" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  function onShowDrawer(reminderType: string, bookingIds: Array<string>) {
    if (extraActionClickShowMoreBooking) {
      extraActionClickShowMoreBooking()
    }
    promptDrawer()
    setIsShowBookingDetails(true)
    if (!booking?.prescriptionsViewed) {
      onUpdateScheduleSuggestion(reminderType, bookingIds)
    }
  }

  function onHideDrawer() {
    setIsShowBookingDetails(false)
    fetchBookingHistory()
  }

  function onCreateCalendarEvent() {
    eventTrack('Salvou na Agenda')
    if (extraActionRequestCreateCalendarEvent) {
      extraActionRequestCreateCalendarEvent()
    }
    if (!booking) return
    const {
      descriptionEvent,
      titleEvent,
      locationEvent,
      startDateTime,
      endDateTime
    } = getGoogleCalendarEventInfos(booking)
    creatEventOnGoogleCalendar(
      titleEvent,
      locationEvent,
      descriptionEvent,
      startDateTime,
      endDateTime
    )
  }

  function goToSchedule() {
    if (booking) {
      setPreviousSelectedSpecialist(booking.chronosId)
      setPatientType(booking.children?.id ? PatientType.IS_CHILD : PatientType.IS_USER)
      setPreviousSelectedCategory(booking.professionalCategory)
      setPreviousSelectedChildId(booking.children?.id)
      setScheduleSource(SourceTypes.BOOKING)
      history.push(AGENDAR)
    }
  }

  useEffect(() => {
    if (booking?.children) {
      setIsChildrenBooking(true)
    }
  }, [booking])

  const hours = booking.startDate.formatDateToHours()
  const date = booking.startDate.formatDayAndMonthAndYearTwoDigits()

  return (
    <div className="booking-card-outer-container" id="booking-card" data-testid="booking-card">
      <div
        className="booking-card-container"
      >
        <div className="booking-card-inner">
          <div className="booking-card-left-container">
            <p className="booking-card-weekday">
              {booking.startDate.formatDateToWeekday().substring(0, 3).toUpperCase()}
            </p>
            <p className="booking-card-day">
              {booking.startDate.formatDayTwoDigits()}
            </p>
            <ProfilePicture
              pictureUrl={booking && booking.professionalPictureUrl}
              pictureSize={56}
              className="booking-card-picture"
              isSpecialist
            />
          </div>
          <div className="booking-card-right-container">
            <div className="booking-card-right-container-header">
              <p className="booking-card-type">
                {getBookingTypeBR(booking.type)}
              </p>
              {showTagStatus && !booking.prescriptions.length
               && <BookingStatusTag status={booking.state} />}
              {showTagStatus && !!booking.prescriptions.length && (
                <div className="flex">
                  <BookingStatusTag status={booking.state} />
                  <ToolTip
                    iconType="icon-PaperclipLight"
                    backgroundColor={!booking.prescriptionsViewed ? secondary : textDisable}
                  />
                </div>
              )}
            </div>
            <p className="booking-card-name">
              {booking.professionalName}
            </p>
            <p className="booking-card-category mb-2">
              {booking.professionalCategory}
            </p>
            <div className="flex mb-2 text-fontDefault items-center">
              <FontIcon iconType={isChildrenBooking ? 'icon-BabyLight' : 'icon-ProfileLight'} fontSize="20px" />
              <p className="ml-2.5">Para {isChildrenBooking ? booking.children.name : 'você'}</p>
            </div>
            <div className="booking-card-hour-container">
              <FontIcon
                iconType="icon-TimeCircleLight"
                fontSize="20px"
              />
              <p className="booking-card-hour">
                {hours} - {date}
              </p>
            </div>
          </div>
        </div>
        <Divider />
        <div className="footer-container">
          <div className="know-more-button border-r border-divider">
            <RegularButton
              onClick={() => onShowDrawer('EXAM_REQUEST', [booking.id])}
              label="Saiba mais"
              variant="text"
              id="know-more-button"
            />
          </div>
          {booking.state === BookingStateOptions.CONFIRMED ? (
            <RegularButton
              onClick={onCreateCalendarEvent}
              label="Salvar na agenda"
              variant="text"
            />
          ) : booking.isExam === false && (
            <RegularButton
              onClick={goToSchedule}
              label="Nova consulta"
              variant="text"
            />
          )}
        </div>
      </div>
      {isShowBookingDetails && (
        <BookingDetailsDrawer
          booking={booking}
          onHideDrawer={onHideDrawer}
          onShowBookingDetails={onShowBookingDetails}
        />
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  promptDrawer: () => { dispatch(promptDrawerAction()) },
  onUpdateScheduleSuggestion: (type: string, bookingIds?: Array<string>) => {
    dispatch(onUpdateBookingSuggestionAction(type, bookingIds))
  },
  fetchBookingHistory: () => {
    dispatch(fetchBookingHistoryAction())
  },
  setPreviousSelectedCategory: (category: SpecialistCategories) => {
    dispatch(setPreviousSelectedCategoryAction(category))
  },
  setPatientType: (patientType: PatientType) => {
    dispatch(setPatientTypeAction(patientType))
  },
  setScheduleSource: (source: SourceTypes) => {
    dispatch(setScheduleSourceAction(source))
  },
  setPreviousSelectedSpecialist: (specialistId: string) => {
    dispatch(setPreviousSelectedSpecialistAction(specialistId))
  },
  setPreviousSelectedChildId: (childId: string) => {
    dispatch(setPreviousSelectedChildIdAction(childId))
  },
})

export default connect(
  undefined,
  mapDispatchToProps
)(BookingCard)
