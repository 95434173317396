import { isProductionEnv } from '../utils/helpers'

export const SHOW_GINECOLOGIC_ROUTINE: boolean = isProductionEnv()
  ? process.env.SHOW_GINECOLOGIC_ROUTINE?.toLowerCase() === 'true'
  : process.env.SHOW_GINECOLOGIC_ROUTINE_STG?.toLowerCase() === 'true'
export const SHOW_PEDIATRIC_REGISTRATION: boolean = isProductionEnv()
  ? process.env.SHOW_PEDIATRIC_REGISTRATION?.toLowerCase() === 'true'
  : process.env.SHOW_PEDIATRIC_REGISTRATION_STG?.toLowerCase() === 'true'
export const SHOW_PEDIATRIC_JOURNEY: boolean = isProductionEnv()
  ? process.env.SHOW_PEDIATRIC_JOURNEY?.toLowerCase() === 'true'
  : process.env.SHOW_PEDIATRIC_JOURNEY_STG?.toLowerCase() === 'true'
export const SHOW_DESIRE_SURVEY_MODAL: boolean = isProductionEnv()
  ? process.env.SHOW_DESIRE_SURVEY_MODAL?.toLowerCase() === 'true'
  : process.env.SHOW_DESIRE_SURVEY_MODAL_STG?.toLowerCase() === 'true'
export const REDIRECT_SIGNUP: boolean = isProductionEnv()
  ? process.env.REDIRECT_SIGNUP?.toLowerCase() === 'true'
  : process.env.REDIRECT_SIGNUP_STG?.toLowerCase() === 'true'
export const SHOW_RECOMMENDED_SPECIALIST: boolean = isProductionEnv()
  ? process.env.SHOW_RECOMMENDED_SPECIALIST?.toLowerCase() === 'true'
  : process.env.SHOW_RECOMMENDED_SPECIALIST_STG?.toLowerCase() === 'true'
export const SHOW_EMAIL_VALIDATION_CARD: boolean = isProductionEnv()
  ? process.env.SHOW_EMAIL_VALIDATION_CARD?.toLowerCase() === 'true'
  : process.env.SHOW_EMAIL_VALIDATION_CARD_STG?.toLowerCase() === 'true'
export const SHOW_PHONE_VALIDATION_CARD: boolean = isProductionEnv()
  ? process.env.SHOW_PHONE_VALIDATION_CARD?.toLowerCase() === 'true'
  : process.env.SHOW_PHONE_VALIDATION_CARD_STG?.toLowerCase() === 'true'
export const SHOW_EMAIL_VALIDATION_FLOW: boolean = isProductionEnv()
  ? process.env.SHOW_EMAIL_VALIDATION_FLOW?.toLowerCase() === 'true'
  : process.env.SHOW_EMAIL_VALIDATION_FLOW_STG?.toLowerCase() === 'true'
