import React, { useEffect, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { connect } from 'react-redux'

import { RegularButton, Divider } from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import { onUpdateHealthRoutineAndFoodDataInProfileAction } from '../../state/profile/actions'
import { AppDispatch } from '../../state/utils'
import './HealthDataView.scss'
import FormMultiSelect from '../common/molecules/inputs/FormMultiSelect'
import { ConsultantMoment, SubstanceUseOptions, WorkTypeOptions } from '../../domain/Profile'
import FormInputList from '../common/molecules/inputs/FormInputList'
import BooleanInput from '../common/molecules/inputs/BooleanInput'
import { formCheckHasOneElementsEmpty, isTrying } from '../../utils/helpers'

type RoutineAndFoodInfosForm = {
  workType: string[];
  workTypeOthers: string[];
  hasFoodRestriction: string;
  foodRestriction: string[];
  hasSomeHealthCondition: string;
  someHealthCondition: string[];
  substanceUse: string[];
  substanceUseOthers: string[];
}

function renderRoutineAndFoodInfosForm({
  values,
  setFieldValue,
  setFieldTouched,
  handleSubmit,
}: FormikProps<RoutineAndFoodInfosForm>,
onGoBack: () => void,
isSubmitting: boolean,
pregnancyMoment?: ConsultantMoment) {
  const [workTypeOthersError, setWorkTypeOthersError] = useState<{[index: number]: string}>()
  const [foodRestrictionError, setFoodRestrictionError] = useState<{[index: number]: string}>()
  const [
    someHealthConditionError,
    setSomeHealthConditionError
  ] = useState<{[index: number]: string}>()
  const [
    substanceUseOthersError,
    setSubstanceUseOthersError
  ] = useState<{[index: number]: string}>()
  const disabledSubmit = (
    !!workTypeOthersError
    || !!foodRestrictionError
    || !!someHealthConditionError
    || !!substanceUseOthersError
  )

  useEffect(() => {
    setWorkTypeOthersError(formCheckHasOneElementsEmpty(values.workTypeOthers))
  }, [values.workTypeOthers])

  useEffect(() => {
    setFoodRestrictionError(formCheckHasOneElementsEmpty(values.foodRestriction))
  }, [values.foodRestriction])

  useEffect(() => {
    setSomeHealthConditionError(formCheckHasOneElementsEmpty(values.someHealthCondition))
  }, [values.someHealthCondition])

  useEffect(() => {
    setSubstanceUseOthersError(formCheckHasOneElementsEmpty(values.substanceUseOthers))
  }, [values.substanceUseOthers])

  function onChangeWorkType(newList: string[]) {
    setFieldValue('workType', newList);
    setFieldTouched('workType', true);
    if (newList.includes(WorkTypeOptions.OTHER) && values.workTypeOthers.length === 0) {
      setFieldValue('workTypeOthers', ['']);
      setFieldTouched('workTypeOthers', true);
    }
    if (!newList.includes(WorkTypeOptions.OTHER) && values.workTypeOthers.length > 0) {
      setFieldValue('workTypeOthers', []);
      setFieldTouched('workTypeOthers', true);
    }
  }

  function onChangeWorkTypeOthers(newList: string[]) {
    setFieldValue('workTypeOthers', newList);
    setFieldTouched('workTypeOthers', true);
  }

  function onChangeHasFoodRestriction(newValue: boolean) {
    setFieldValue('hasFoodRestriction', `${newValue}`);
    setFieldTouched('hasFoodRestriction', true);
    if (newValue) {
      setFieldValue('foodRestriction', ['']);
      setFieldTouched('foodRestriction', true);
    } else {
      setFieldValue('foodRestriction', []);
      setFieldTouched('foodRestriction', true);
    }
  }

  function onChangeFoodRestriction(newList: string[]) {
    setFieldValue('foodRestriction', newList);
    setFieldTouched('foodRestriction', true);
  }

  function onChangeHasSomeHealthCondition(newValue: boolean) {
    setFieldValue('hasSomeHealthCondition', `${newValue}`);
    setFieldTouched('hasSomeHealthCondition', true);
    if (newValue) {
      setFieldValue('someHealthCondition', ['']);
      setFieldTouched('someHealthCondition', true);
    } else {
      setFieldValue('someHealthCondition', []);
      setFieldTouched('someHealthCondition', true);
    }
  }

  function onChangeSomeHealthCondition(newList: string[]) {
    setFieldValue('someHealthCondition', newList);
    setFieldTouched('someHealthCondition', true);
  }

  function onChangeSubstanceUse(newList: string[]) {
    setFieldValue('substanceUse', newList);
    setFieldTouched('substanceUse', true);
    if (newList.includes(SubstanceUseOptions.OTHER) && values.substanceUseOthers.length === 0) {
      setFieldValue('substanceUseOthers', ['']);
      setFieldTouched('substanceUseOthers', true);
    }
    if (!newList.includes(SubstanceUseOptions.OTHER) && values.substanceUseOthers.length > 0) {
      setFieldValue('substanceUseOthers', []);
      setFieldTouched('substanceUseOthers', true);
    }
  }

  function onChangeSubstanceUseOthers(newList: string[]) {
    setFieldValue('substanceUseOthers', newList);
    setFieldTouched('substanceUseOthers', true);
  }

  return (
    <form onSubmit={handleSubmit} className="health-view-drawer-form" noValidate>
      <div className="health-view-drawer-inner-form">
        <div className="health-view-individual-container">
          <FormMultiSelect
            id="workType"
            label="Como são seus dias?"
            options={Object.values(WorkTypeOptions).filter((e) => e !== WorkTypeOptions.NONE)}
            onChange={onChangeWorkType}
            initialValues={values.workType}
          />
          {values.workType.includes(WorkTypeOptions.OTHER) && (
            <FormInputList
              placeholder="* Digite qual atividade"
              ariaLabel="Digite qual atividade"
              list={values.workTypeOthers}
              errors={workTypeOthersError}
              onChange={onChangeWorkTypeOthers}
              required
            />
          )}
        </div>
        <div className="health-view-individual-container">
          <BooleanInput
            label="Possui alguma restrição alimentar?"
            name="hasFoodRestriction"
            value={values.hasFoodRestriction === '' ? undefined : values.hasFoodRestriction === 'true'}
            onChange={onChangeHasFoodRestriction}
          />
          {values.hasFoodRestriction === 'true' && (
            <FormInputList
              placeholder="* Digite qual restrição"
              ariaLabel="Digite qual restrição"
              list={values.foodRestriction}
              errors={foodRestrictionError}
              onChange={onChangeFoodRestriction}
              required
            />
          )}
        </div>
        <div className="health-view-individual-container">
          <BooleanInput
            label="Tem alguma condição de saúde?"
            name="hasSomeHealthCondition"
            value={values.hasSomeHealthCondition === '' ? undefined : values.hasSomeHealthCondition === 'true'}
            onChange={onChangeHasSomeHealthCondition}
          />
          {values.hasSomeHealthCondition === 'true' && (
            <FormInputList
              placeholder="* Digite qual condição"
              ariaLabel="Digite qual condição"
              list={values.someHealthCondition}
              errors={someHealthConditionError}
              onChange={onChangeSomeHealthCondition}
              required
            />
          )}
        </div>
        {(isTrying(pregnancyMoment)) && (
          <div className="health-view-individual-container">
            <FormMultiSelect
              id="substanceUse"
              label="Faz uso de alguma dessas substâncias?"
              options={Object.values(SubstanceUseOptions)}
              onChange={onChangeSubstanceUse}
              initialValues={values.substanceUse}
            />
            {values.substanceUse.includes(SubstanceUseOptions.OTHER) && (
              <FormInputList
                placeholder="* Digite qual substância"
                ariaLabel="Digite qual substância"
                list={values.substanceUseOthers}
                errors={substanceUseOthersError}
                onChange={onChangeSubstanceUseOthers}
                required
              />
            )}
          </div>
        )}
      </div>
      <div className="health-view-drawer-footer">
        <Divider />
        <div className="health-view-drawer-button">
          <RegularButton
            onClick={onGoBack}
            label="Agora não"
            variant="text"
            extraClass="booking-details-cancel-btn"
          />
          <RegularButton
            onClick={() => handleSubmit()}
            label="Salvar"
            type="submit"
            isSubmitting={isSubmitting}
            disabled={disabledSubmit}
          />
        </div>
      </div>
    </form>
  )
}

const emptyList: string[] = []

const initialValues: RoutineAndFoodInfosForm = {
  workType: emptyList,
  workTypeOthers: emptyList,
  hasFoodRestriction: '',
  foodRestriction: emptyList,
  hasSomeHealthCondition: '',
  someHealthCondition: emptyList,
  substanceUse: emptyList,
  substanceUseOthers: emptyList,
}

interface Props {
  isSubmitting: boolean;
  workType?: string[];
  workTypeOthers?: string[];
  hasFoodRestriction?: string;
  foodRestriction?: string[];
  hasSomeHealthCondition?: string;
  someHealthCondition?: string[];
  onGoBack: () => void;
  onUpdateHealthRoutineAndFoodDataInProfile: (
    name: string,
    email: string,
    phone: string,
    workType: string[],
    workTypeOthers: string[],
    hasFoodRestriction: string,
    foodRestriction: string[],
    hasSomeHealthCondition: string,
    someHealthCondition: string[],
    substanceUse: string[],
    substanceUseOthers: string[],
  ) => void;
  name?: string;
  email?: string;
  phone?: string;
  pregnancyMoment?: ConsultantMoment;
  substanceUse?: string[];
  substanceUseOthers?: string[];
  isUpdatingProfile: boolean;
}

function RoutineAndFoodDataEdit({
  onUpdateHealthRoutineAndFoodDataInProfile,
  isSubmitting,
  workType,
  workTypeOthers,
  hasFoodRestriction,
  foodRestriction,
  hasSomeHealthCondition,
  someHealthCondition,
  onGoBack,
  name,
  email,
  phone,
  pregnancyMoment,
  substanceUse,
  substanceUseOthers,
  isUpdatingProfile
}: Props) {
  const [alreadySendNewInfos, setAlreadySendNewInfos] = useState(false)

  useEffect(() => {
    if (!isUpdatingProfile && alreadySendNewInfos) {
      setAlreadySendNewInfos(false)
      onGoBack()
    }
  }, [isUpdatingProfile])
  initialValues.hasFoodRestriction = hasFoodRestriction
    || initialValues.hasFoodRestriction
  initialValues.foodRestriction = (foodRestriction || initialValues.foodRestriction)
  initialValues.workType = workType || initialValues.workType
  initialValues.workTypeOthers = workTypeOthers || initialValues.workTypeOthers
  initialValues.hasSomeHealthCondition = hasSomeHealthCondition
    || initialValues.hasSomeHealthCondition
  initialValues.someHealthCondition = (someHealthCondition || initialValues.someHealthCondition)
  initialValues.substanceUse = (substanceUse || initialValues.substanceUse)
  initialValues.substanceUseOthers = substanceUseOthers || initialValues.substanceUseOthers
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(_value: RoutineAndFoodInfosForm) => {
        if (name && email && phone) {
          setAlreadySendNewInfos(true)
          onUpdateHealthRoutineAndFoodDataInProfile(
            name,
            email,
            phone,
            _value.workType,
            _value.workTypeOthers,
            _value.hasFoodRestriction,
            _value.foodRestriction,
            _value.hasSomeHealthCondition,
            _value.someHealthCondition,
            _value.substanceUse,
            _value.substanceUseOthers
          )
        }
      }}
    >
      {(props) => renderRoutineAndFoodInfosForm(
        props,
        onGoBack,
        isSubmitting,
        pregnancyMoment
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  profile, authentication
}: AppState) => ({
  workType: profile.profile?.workType,
  workTypeOthers: profile.profile?.workTypeOthers,
  hasFoodRestriction: profile.profile?.hasFoodRestriction,
  foodRestriction: profile.profile?.foodRestriction,
  someHealthCondition: profile.profile?.someHealthCondition,
  hasSomeHealthCondition: profile.profile?.hasSomeHealthCondition,
  name: authentication.currentUser?.name,
  email: authentication.currentUser?.email,
  phone: authentication.currentUser?.phone,
  isSubmitting: profile.isUpdatingProfile,
  pregnancyMoment: profile.profile?.pregnancyMoment,
  substanceUse: profile.profile?.substanceUse,
  substanceUseOthers: profile.profile?.substanceUseOthers,
  isUpdatingProfile: profile.isUpdatingProfile
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onUpdateHealthRoutineAndFoodDataInProfile: (
    name: string,
    email: string,
    phone: string,
    workType: string[],
    workTypeOthers: string[],
    hasFoodRestriction: string,
    foodRestriction: string[],
    hasSomeHealthCondition: string,
    someHealthCondition: string[],
    substanceUse: string[],
    substanceUseOthers: string[],
  ) => {
    dispatch(onUpdateHealthRoutineAndFoodDataInProfileAction(
      name,
      email,
      phone,
      workType,
      workTypeOthers,
      hasFoodRestriction,
      foodRestriction,
      hasSomeHealthCondition,
      someHealthCondition,
      substanceUse,
      substanceUseOthers
    ))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutineAndFoodDataEdit)
