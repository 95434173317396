import {
  CONNECT,
  DISCONNECT,
  REMOTE_CONNECT,
  REMOTE_DISCONNECT,
  REMOTE_VIDEO_CONNECTED,
  REMOTE_VIDEO_DISCONNECTED,
  REMOTE_AUDIO_CONNECTED,
  REMOTE_AUDIO_DISCONNECTED,
  TOGGLE_AUDIO,
  TOGGLE_VIDEO,
  TOGGLE_CHAT,
  UPDATE_DOWNLINK_STATE,
  BookingActionTypes,
  BOOKING_ERROR,
  PROMPT_BROWSER_ERROR,
  SUPPRESS_BROWSER_ERROR,
  BrowserErrorTypes,
  PROMPT_PERMISSION,
  SUPPRESS_PROMPT_PERMISSION,
  PromptPermissionTypes,
  REMOTE_RECONNECTING,
  REMOTE_RECONNECTED,
  LOCAL_RECONNECTED,
  LOCAL_RECONNECTING,
  NETWORK_QUALITY,
  LOCAL_FORCE_DISCONNECT_WITH_DUPLICATE_ROOM,
  LOCAL_CONNECTING,
  LOCAL_DISCONNECT_WITH_DEFAULT_PROBLEM,
  ADD_TRACKS_TO_CLEAR,
} from './actions'
import {
  CallService,
  BookingVideoTrack,
  BookingAudioTrack
} from '../../domain/Call'

export interface BookingState {
  id: string;
  callService?: CallService;
  remoteConnected: boolean;
  remoteVideoTrack?: BookingVideoTrack;
  remoteAudioTrack?: BookingAudioTrack;
  remoteVideoActive: boolean;
  remoteAudioActive: boolean;
  localConnected: boolean;
  localReconnecting: boolean;
  localVideoTrack?: BookingVideoTrack;
  localAudioTrack?: BookingAudioTrack;
  localVideoActive: boolean;
  localAudioActive: boolean;
  localChatActive: boolean;
  isSwitchedOff: boolean;
  remoteUserName?: string;
  errorMessage?: string;
  remoteHasConnected: boolean;
  remoteReconnecting: boolean;
  chatToken: string;
  consultantName: string;
  uuid: string;
  networkQualityLevel: number | null;
  networkQualityStats?: any;
  localForceDisconnectWithDuplicate: boolean;
  localDisconnectWithDefaultProblem: boolean;
  localConnecting: boolean;
  isProblemWithVideo: boolean;
  streamMediaListToClear: Array<MediaStream>;
}

const initialBookingState: BookingState = {
  id: '',
  callService: undefined,
  remoteConnected: false,
  remoteVideoTrack: undefined,
  remoteAudioTrack: undefined,
  remoteVideoActive: false,
  remoteAudioActive: false,
  localConnected: false,
  localReconnecting: false,
  localVideoTrack: undefined,
  localAudioTrack: undefined,
  localVideoActive: false,
  localAudioActive: false,
  localChatActive: false,
  isSwitchedOff: false,
  remoteUserName: undefined,
  errorMessage: undefined,
  remoteHasConnected: false,
  remoteReconnecting: false,
  chatToken: '',
  consultantName: '',
  uuid: '',
  localForceDisconnectWithDuplicate: false,
  localConnecting: false,
  localDisconnectWithDefaultProblem: false,
  networkQualityLevel: null,
  isProblemWithVideo: false,
  streamMediaListToClear: [],
}

export function booking(
  state = initialBookingState,
  action: BookingActionTypes
): BookingState {
  switch (action.type) {
    case CONNECT:
      return {
        ...state,
        id: action.id,
        callService: action.callService,
        remoteUserName: action.remoteUserName,
        localVideoTrack: action.localVideoTrack,
        localAudioTrack: action.localAudioTrack,
        localConnected: true,
        localVideoActive: true,
        localAudioActive: true,
        localChatActive: false,
        errorMessage: undefined,
        chatToken: action.chatToken,
        consultantName: action.consultantName,
        uuid: action.uuid,
        localForceDisconnectWithDuplicate: false,
        localConnecting: false,
        localDisconnectWithDefaultProblem: false,
        localReconnecting: false,
        isProblemWithVideo: false,
      }
    case LOCAL_CONNECTING:
      return {
        ...state,
        localConnecting: true,
      }
    case LOCAL_FORCE_DISCONNECT_WITH_DUPLICATE_ROOM:
      return {
        ...initialBookingState,
        localForceDisconnectWithDuplicate: true,
        localConnecting: false,
      }
    case LOCAL_DISCONNECT_WITH_DEFAULT_PROBLEM:
      return {
        ...state,
        localDisconnectWithDefaultProblem: true,
      }
    case DISCONNECT:
      return { ...initialBookingState }
    case LOCAL_RECONNECTING:
      return {
        ...state,
        localReconnecting: true,
        localConnected: false,
        localVideoActive: false,
        localAudioActive: false,
        isProblemWithVideo: false,
      }
    case LOCAL_RECONNECTED:
      return {
        ...state,
        localReconnecting: false,
        localConnected: true,
        localVideoActive: true,
        localAudioActive: true,
      }
    case REMOTE_CONNECT:
      return {
        ...state,
        remoteConnected: true,
        remoteHasConnected: true,
        remoteReconnecting: false,
      }
    case REMOTE_RECONNECTING:
      return {
        ...state,
        remoteConnected: false,
        remoteHasConnected: false,
        remoteReconnecting: true,
      }
    case REMOTE_RECONNECTED:
      return {
        ...state,
        remoteConnected: true,
        remoteHasConnected: true,
        remoteReconnecting: false,
      }
    case REMOTE_DISCONNECT:
      return {
        ...state,
        remoteConnected: false,
        remoteReconnecting: false,
      }
    case REMOTE_VIDEO_CONNECTED:
      return {
        ...state,
        remoteVideoActive: true,
        remoteVideoTrack: action.remoteVideoTrack,
      }
    case REMOTE_VIDEO_DISCONNECTED:
      return {
        ...state,
        remoteVideoActive: false,
        remoteVideoTrack: undefined,
      }
    case REMOTE_AUDIO_CONNECTED:
      return {
        ...state,
        remoteAudioActive: true,
        remoteAudioTrack: action.remoteAudioTrack,
      }
    case REMOTE_AUDIO_DISCONNECTED:
      return {
        ...state,
        remoteAudioActive: false,
        remoteAudioTrack: undefined,
      }
    case TOGGLE_VIDEO:
      return {
        ...state,
        localVideoActive: action.localVideoActive,
      }
    case TOGGLE_AUDIO:
      return {
        ...state,
        localAudioActive: action.localAudioActive,
      }
    case TOGGLE_CHAT:
      return {
        ...state,
        localChatActive: !state.localChatActive,
      }
    case BOOKING_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        localForceDisconnectWithDuplicate: false,
        localDisconnectWithDefaultProblem: false,
        isProblemWithVideo: action.isProblemWithVideo
      }
    case UPDATE_DOWNLINK_STATE:
      return {
        ...state,
        isSwitchedOff: action.isSwitchedOff,
      }
    case NETWORK_QUALITY:
      return {
        ...state,
        networkQualityLevel: action.networkQualityLevel,
        networkQualityStats: action.networkQualityStats
      }
    case ADD_TRACKS_TO_CLEAR: {
      return {
        ...state,
        streamMediaListToClear: [...state.streamMediaListToClear, action.newMediaStream]
      }
    }
    default:
      return state
  }
}

export interface BrowserErrorState {
  visible: boolean;
}

export const initialState: BrowserErrorState = {
  visible: false
}

export function browserError(state = initialState, action: BrowserErrorTypes): BrowserErrorState {
  switch (action.type) {
    case PROMPT_BROWSER_ERROR:
      return {
        visible: true
      }
    case SUPPRESS_BROWSER_ERROR:
      return {
        visible: false
      }
    default: return state
  }
}

export interface PromptPermissionState {
  visible: boolean;
}

export const initialPermissionState: PromptPermissionState = {
  visible: false
}

export function promptPermission(state = initialPermissionState, action: PromptPermissionTypes):
PromptPermissionState {
  switch (action.type) {
    case PROMPT_PERMISSION:
      return {
        visible: true
      }
    case SUPPRESS_PROMPT_PERMISSION:
      return {
        visible: false
      }
    default: return state
  }
}
