import {
  ProfileForm,
  WorkTypeToServer,
  WorkTypeOptions,
  GenderOptions,
  GenderToServer,
  RelationshipOptions,
  RelationshipToServer,
  GenderPreferenceToServer,
  LiveInSpOptions,
  BloodTypeOptions,
  BloodTypeOptionsToServer
} from '../../domain/Profile';

export function workTypeToRemote(workType: string): string | undefined {
  switch (workType) {
    case WorkTypeOptions.NONE:
      return undefined
    case WorkTypeOptions.COMPUTER:
      return WorkTypeToServer.COMPUTER
    case WorkTypeOptions.MOVIMENT:
      return WorkTypeToServer.MOVIMENT
    case WorkTypeOptions.HEAVY:
      return WorkTypeToServer.HEAVY
    case WorkTypeOptions.EXERCISES:
      return WorkTypeToServer.EXERCISES
    case WorkTypeOptions.OTHER:
      return WorkTypeToServer.OTHER
    default:
      return undefined
  }
}

function workTypeFromRemote(workType?: string): string {
  switch (workType) {
    case WorkTypeToServer.NONE:
      return WorkTypeOptions.NONE
    case WorkTypeToServer.COMPUTER:
      return WorkTypeOptions.COMPUTER
    case WorkTypeToServer.MOVIMENT:
      return WorkTypeOptions.MOVIMENT
    case WorkTypeToServer.HEAVY:
      return WorkTypeOptions.HEAVY
    case WorkTypeToServer.EXERCISES:
      return WorkTypeOptions.EXERCISES
    case WorkTypeToServer.OTHER:
      return WorkTypeOptions.OTHER
    default:
      return WorkTypeOptions.NONE
  }
}

export function genderToRemote(gender: string): string | undefined {
  switch (gender) {
    case GenderOptions.NONE:
      return undefined
    case GenderOptions.FEMALE:
      return GenderToServer.FEMALE
    case GenderOptions.MALE:
      return GenderToServer.MALE
    case GenderOptions.CUSTOM:
      return GenderToServer.CUSTOM
    default:
      return undefined
  }
}

export function relationshipToRemote(relationship: string): string | undefined {
  switch (relationship) {
    case RelationshipOptions.NONE:
      return undefined
    case RelationshipOptions.NO:
      return RelationshipToServer.NO
    case RelationshipOptions.MALE_PARTNER:
      return RelationshipToServer.MALE_PARTNER
    case RelationshipOptions.FEMALE_PARTNER:
      return RelationshipToServer.FEMALE_PARTNER
    case RelationshipToServer.PARENT:
      return RelationshipOptions.PARENT
    case RelationshipToServer.OTHER:
      return RelationshipOptions.OTHER
    default:
      return undefined
  }
}

function relationshipFromRemote(relationship?: string): RelationshipOptions {
  switch (relationship) {
    case RelationshipToServer.NONE:
      return RelationshipOptions.NONE
    case RelationshipToServer.NO:
      return RelationshipOptions.NO
    case RelationshipToServer.MALE_PARTNER:
      return RelationshipOptions.MALE_PARTNER
    case RelationshipToServer.FEMALE_PARTNER:
      return RelationshipOptions.FEMALE_PARTNER
    case RelationshipToServer.PARENT:
      return RelationshipOptions.PARENT
    case RelationshipToServer.OTHER:
      return RelationshipOptions.OTHER
    default:
      return RelationshipOptions.NONE
  }
}

export function bloodTypeFromRemote(bloodType?: string): string {
  switch (bloodType) {
    case BloodTypeOptionsToServer.AB_MINUS:
      return BloodTypeOptions.AB_MINUS
    case BloodTypeOptionsToServer.AB_PLUS:
      return BloodTypeOptions.AB_PLUS
    case BloodTypeOptionsToServer.A_MINUS:
      return BloodTypeOptions.A_MINUS
    case BloodTypeOptionsToServer.A_PLUS:
      return BloodTypeOptions.A_PLUS
    case BloodTypeOptionsToServer.B_PLUS:
      return BloodTypeOptions.B_PLUS
    case BloodTypeOptionsToServer.B_MINUS:
      return BloodTypeOptions.B_MINUS
    case BloodTypeOptionsToServer.O_PLUS:
      return BloodTypeOptions.O_PLUS
    case BloodTypeOptionsToServer.O_MINUS:
      return BloodTypeOptions.O_MINUS
    default:
      return ''
  }
}

export function bloodTypeToRemote(bloodType?: string): string {
  switch (bloodType) {
    case BloodTypeOptions.AB_MINUS:
      return BloodTypeOptionsToServer.AB_MINUS
    case BloodTypeOptions.AB_PLUS:
      return BloodTypeOptionsToServer.AB_PLUS
    case BloodTypeOptions.A_MINUS:
      return BloodTypeOptionsToServer.A_MINUS
    case BloodTypeOptions.A_PLUS:
      return BloodTypeOptionsToServer.A_PLUS
    case BloodTypeOptions.B_PLUS:
      return BloodTypeOptionsToServer.B_PLUS
    case BloodTypeOptions.B_MINUS:
      return BloodTypeOptionsToServer.B_MINUS
    case BloodTypeOptions.O_PLUS:
      return BloodTypeOptionsToServer.O_PLUS
    case BloodTypeOptions.O_MINUS:
      return BloodTypeOptionsToServer.O_MINUS
    default:
      return BloodTypeOptionsToServer.NONE
  }
}

// TODO: Remove after aldeia updates the bd
function genderFromRemoteLegacy(gender: string): string {
  switch (gender) {
    case GenderToServer.FEMALE:
      return 'Feminino'
    case GenderToServer.MALE:
      return 'Masculino'
    case GenderToServer.CUSTOM:
      return 'Personalizado'
    default:
      return gender
  }
}

// TODO: Remove after aldeia updates the bd
function genderPreferenceFromRemoteLegacy(genderPreference: string): string {
  switch (genderPreference) {
    case GenderPreferenceToServer.FEMALE:
      return 'Feminino: "Parabéns para ela"'
    case GenderPreferenceToServer.MALE:
      return 'Masculino: "Parabéns para ele"'
    case GenderPreferenceToServer.NEUTRAL:
      return 'Neutro: "Parabéns para ele"'
    default:
      return genderPreference
  }
}

export function profileFromRemote(profile: any): ProfileForm {
  if (!profile) return profile
  const newProfile = { ...profile }
  if (profile.workType) {
    newProfile.workType = typeof profile.workType === 'string' || profile.workType instanceof String ? (
      [workTypeFromRemote(profile.workType)]
    ) : (
      profile.workType.map((element: string) => workTypeFromRemote(element))
    )
  }
  newProfile.hasKids = profile.hasKids !== undefined && profile.hasKids !== null ? `${profile.hasKids}` : ''
  newProfile.hasUseSomeMedicine = typeof profile.useSomeMedicine === 'string' || profile.useSomeMedicine instanceof String ? (
    `${!!profile.useSomeMedicine}`
  ) : (
    `${profile.hasUseSomeMedicine !== undefined && profile.hasUseSomeMedicine !== null ? `${profile.hasUseSomeMedicine}` : ''}`
  )
  newProfile.useSomeMedicine = typeof profile.useSomeMedicine === 'string' || profile.useSomeMedicine instanceof String ? (
    [profile.useSomeMedicine]
  ) : (
    profile.useSomeMedicine
  )
  newProfile.hasSomeHealthCondition = typeof profile.someHealthCondition === 'string' || profile.someHealthCondition instanceof String ? (
    `${!!profile.someHealthCondition}`
  ) : (
    `${profile.hasSomeHealthCondition !== undefined && profile.hasSomeHealthCondition !== null ? `${profile.hasSomeHealthCondition}` : ''}`
  )
  newProfile.someHealthCondition = typeof profile.someHealthCondition === 'string' || profile.someHealthCondition instanceof String ? (
    [profile.someHealthCondition]
  ) : (
    profile.someHealthCondition
  )
  newProfile.hasFoodRestriction = typeof profile.foodRestriction === 'string' || profile.foodRestriction instanceof String ? (
    `${!!profile.foodRestriction}`
  ) : (
    `${profile.hasFoodRestriction !== undefined && profile.hasFoodRestriction !== null ? `${profile.hasFoodRestriction}` : ''}`
  )
  newProfile.hasAllergy = profile.hasAllergy !== undefined && profile.hasAllergy !== null ? `${profile.hasAllergy}` : ''
  newProfile.foodRestriction = typeof profile.foodRestriction === 'string' || profile.foodRestriction instanceof String ? (
    [profile.foodRestriction]
  ) : (
    profile.foodRestriction
  )
  newProfile.gender = genderFromRemoteLegacy(newProfile.gender)
  newProfile.genderPreference = genderPreferenceFromRemoteLegacy(newProfile.genderPreference)
  newProfile.hasAboutRelationship = typeof profile.aboutRelationship === 'string' || profile.aboutRelationship instanceof String ? (
    `${!!profile.aboutRelationship}`
  ) : (
    `${profile.hasAboutRelationship !== undefined && profile.hasAboutRelationship !== null ? `${profile.hasAboutRelationship}` : ''}`
  )
  if (profile.aboutRelationship) {
    newProfile.aboutRelationship = typeof profile.aboutRelationship === 'string' || profile.aboutRelationship instanceof String ? (
      [relationshipFromRemote(profile.aboutRelationship)]
    ) : (
      profile.aboutRelationship.map((element: string) => relationshipFromRemote(element))
    )
  }
  newProfile.bloodType = bloodTypeFromRemote(profile.bloodType)
  if (!profile.address) {
    newProfile.addressName = profile.addressLegacy
  } else {
    newProfile.postalCode = profile.address?.postalCode
    newProfile.city = profile.address?.cityName
    newProfile.stateAbbreviation = profile.address?.stateAbbreviation
    newProfile.addressName = profile.address?.name
    newProfile.number = profile.address?.number
    newProfile.complement = profile.address?.complement
  }
  newProfile.hasPregnancyLoss = profile.hasPregnancyLoss !== undefined && profile.hasPregnancyLoss !== null ? `${profile.hasPregnancyLoss}` : ''
  return newProfile
}

export function liveInSpFromRemote(liveInSp: boolean): LiveInSpOptions {
  if (liveInSp !== undefined) {
    return liveInSp ? LiveInSpOptions.SP : LiveInSpOptions.OTHER
  }
  return LiveInSpOptions.NONE
}
