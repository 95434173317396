import * as logger from "loglevel";

const log = logger.getLogger("twilio-notificatiions");

function prepareLine(prefix, args) {
  return [`${new Date().toISOString()} Twilio.Notifications ${prefix}:`].concat(
    Array.from(args)
  );
}

class Logger {
  setLevel(level: any): void {
    log.setLevel(level as any);
  }

  trace(...args): void {
    log.trace.apply(null, prepareLine("T", args));
  }
  debug(...args): void {
    log.debug.apply(null, prepareLine("D", args));
  }
  info(...args): void {
    log.info.apply(null, prepareLine("I", args));
  }
  warn(...args): void {
    log.warn.apply(null, prepareLine("W", args));
  }
  error(...args): void {
    log.error.apply(null, prepareLine("E", args));
  }
}

const logInstance = new Logger();

export { Logger, logInstance as log };
