import * as Sentry from '@sentry/browser'
import { AsyncAction } from '../utils'
import { ConsultantService, DesireContent, MaternalDesireType } from '../../domain/Consultant'
import { primary } from '../../color'
import { showNotificationAction } from '../notifications/actions'
import { ToastType } from '../../domain/Notifications'

export const POST_MATERNAL_DESIRE = 'POST_MATERNAL_DESIRE'
export const SUCCESS_POST_MATERNAL_DESIRE = 'SUCCESS_POST_MATERNAL_DESIRE'
export const FAILURE_POST_MATERNAL_DESIRE = 'FAILURE_POST_MATERNAL_DESIRE'
export const GET_DESIRE_CONTENT = 'GET_DESIRE_CONTENT'
export const SUCCESS_GET_DESIRE_CONTENT = 'SUCCESS_GET_DESIRE_CONTENT'
export const FAILURE_GET_DESIRE_CONTENT = 'FAILURE_GET_DESIRE_CONTENT'

export const SUPPRESS_FIRST_MATERNAL_DESIRE_MODAL = 'SUPPRESS_FIRST_MATERNAL_DESIRE_MODAL'
export const SUPPRESS_MATERNAL_DESIRE_ERROR = 'SUPPRESS_MATERNAL_DESIRE_ERROR'

interface PostMaternalDesireActionType {
  type: typeof POST_MATERNAL_DESIRE;
}

export interface SuccessPostMaternalDesireActionType {
  type: typeof SUCCESS_POST_MATERNAL_DESIRE;
}

interface FailurePostMaternalDesireActionType {
  type: typeof FAILURE_POST_MATERNAL_DESIRE;
}

interface GetDesireContentActionType {
  type: typeof GET_DESIRE_CONTENT;
}

interface SuccessGetDesireContentActionType {
  type: typeof SUCCESS_GET_DESIRE_CONTENT;
  desireContent: DesireContent;
}

interface FailureGetDesireContentActionType {
  type: typeof FAILURE_GET_DESIRE_CONTENT;
}

interface SuppressFirstMaternalDesireModalActionType {
  type: typeof SUPPRESS_FIRST_MATERNAL_DESIRE_MODAL;
}

interface SuppressMaternalDesireErrorActionType {
  type: typeof SUPPRESS_MATERNAL_DESIRE_ERROR;
}

export type ConsultantTypes = PostMaternalDesireActionType
| SuccessPostMaternalDesireActionType
| FailurePostMaternalDesireActionType
| SuppressFirstMaternalDesireModalActionType
| SuppressMaternalDesireErrorActionType
| GetDesireContentActionType
| SuccessGetDesireContentActionType
| FailureGetDesireContentActionType

export function postMaternalDesireAction(body: MaternalDesireType):
  AsyncAction<{}, { consultantService: ConsultantService }> {
  return async (dispatch, _getState, { consultantService }) => {
    dispatch({ type: POST_MATERNAL_DESIRE })
    try {
      await consultantService.postMaternalDesire(body)
      dispatch({ type: SUCCESS_POST_MATERNAL_DESIRE })
      dispatch(showNotificationAction({
        message: 'Agradecemos sua resposta!',
        iconColor: primary,
        iconType: 'icon-HeartLight',
        timer: 5000,
        id: 'SUCCESS_POST_MATERNAL_DESIRE',
        type: ToastType.SUCCESS
      }))
    } catch (error) {
      dispatch({ type: FAILURE_POST_MATERNAL_DESIRE })
      Sentry.captureException(new Error(`Failure on post maternal desire - ${error.message}`))
    }
  }
}

export function getDesireContentAction():
  AsyncAction<{}, { consultantService: ConsultantService }> {
  return async (dispatch, _getState, { consultantService }) => {
    dispatch({ type: GET_DESIRE_CONTENT })
    try {
      const desireContent = await consultantService.getDesireContent()
      dispatch({ type: SUCCESS_GET_DESIRE_CONTENT, desireContent })
    } catch (error) {
      dispatch({ type: FAILURE_GET_DESIRE_CONTENT })
      Sentry.captureException(new Error(`Failure to get desire content - ${error.message}`))
    }
  }
}

export const suppressFirstMaternalDesireModalAction = ():
SuppressFirstMaternalDesireModalActionType => ({
  type: SUPPRESS_FIRST_MATERNAL_DESIRE_MODAL,
})

export const suppressMaternalDesireErrorAction = ():
SuppressMaternalDesireErrorActionType => ({
  type: SUPPRESS_MATERNAL_DESIRE_ERROR,
})
