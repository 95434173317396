export const PROMPT_ERROR = 'PROMPT_ERROR'
export const SUPPRESS_ERROR = 'SUPPRESS_ERROR'

export type ErrorWithFriendlyMessage = {
  friendlyMessageTitle?: string;
  friendlyMessage?: string;
  message?: string;
  statusCode?: string;
}

interface PromptErrorActionType {
  type: typeof PROMPT_ERROR;
  error?: ErrorWithFriendlyMessage;
}

interface SuppressErrorActionType {
  type: typeof SUPPRESS_ERROR;
}

export type ErrorTypes =
  PromptErrorActionType |
  SuppressErrorActionType

export const promptErrorAction = (error?: ErrorWithFriendlyMessage): ErrorTypes => ({
  type: PROMPT_ERROR,
  error
})

export const suppressErrorAction = (): ErrorTypes => ({
  type: SUPPRESS_ERROR
})
