import {
  Client,
  Channel,
  Member,
  Message as TwilioMessage
} from 'twilio-chat'
import { LocalDate } from '../../utils/LocalDate'
import {
  ChatService,
  ChatServiceFactory,
  Message,
  ScheduleMessage,
  ProfessionalScheduleMessage,
  CategoryScheduleMessage,
  MessageReceivedHandler,
  OnlineSpecialistHandler,
  OfflineSpecialistHandler,
  SpecialistTypingHandler,
  PlatformUnavailability,
  MediaMessage
} from '../../domain/Chat'
import { User } from '../../domain/User'
import ApiClient from '../api/ApiClient'
import { extractScheduleMetadata } from '../api/utils'

export default class TwilioChatService implements ChatService {
  constructor(
    private client: Client,
    private channel: Channel,
    private currentUser: User,
    private apiClient: ApiClient,
  ) { }

  async getMessages(anchor?: number): Promise<Message[]> {
    const paginator = await this.channel.getMessages(30, anchor)
    return paginator.items.map((message: TwilioMessage) => (
      this.buildMessageFromTwilioMessage(message)
    ))
  }

  setForceReconnect(): void {
    this.client.removeAllListeners()
  }

  needForceReconnect(): boolean {
    return (
      this.client.connectionState !== 'connected'
      && this.client.connectionState !== 'connecting'
    )
  }

  sendMessage(text: string): void {
    this.channel.sendMessage(text)
  }

  sendMediaMessage(file: File): void {
    const formData = new FormData()
    formData.append('file', file, file.name)
    this.channel.sendMessage(formData)
  }

  typing() {
    this.channel.typing()
  }

  setOnMessageReceived(handler: MessageReceivedHandler): void {
    this.registerHandlerOnChannel(handler)
  }

  setOnSpecialistTyping(handler: SpecialistTypingHandler): void {
    this.channel.on('typingStarted', () => {
      handler(true)
    })
    this.channel.on('typingEnded', () => {
      handler(false)
    })
  }

  setOnSpecialistOnline(handler: OnlineSpecialistHandler): void {
    this.channel.on('memberJoined', (specialist: Member) => handler(specialist.identity))
  }

  setOnSpecialistOffline(handler: OfflineSpecialistHandler): void {
    this.channel.on('memberLeft', () => handler())
  }

  async getOnlineSpecialist(): Promise<string | undefined> {
    const members = await this.channel.getMembers()
    const onlineSpecialist = members.find((member) => (
      member.identity !== this.currentUser.twilioIdentity
    ))

    return onlineSpecialist && onlineSpecialist.identity
  }

  async getUnavailability(): Promise<Array<PlatformUnavailability>> {
    return this.apiClient.getUnavailability()
  }

  async getMediaUrl(message: Message): Promise<string> {
    const mediaUrl = await message.media.getContentTemporaryUrl().then((url: string) => url)
    return mediaUrl
  }

  private registerHandlerOnChannel(handler: MessageReceivedHandler): void {
    this.channel.on('messageAdded', async (message) => {
      handler(this.buildMessageFromTwilioMessage(message))
    })
  }

  private buildProfessionalScheduleMessage(message: Message, professionalId: string) {
    const scheduleMessage = message as ProfessionalScheduleMessage
    scheduleMessage.professionalId = professionalId
    return scheduleMessage
  }

  private buildCategoryScheduleMessage(message: Message, professionalCategory: string) {
    const scheduleMessage = message as CategoryScheduleMessage
    scheduleMessage.professionalCategory = professionalCategory
    return scheduleMessage
  }

  private buildMediaMessage(message: Message, url: string) {
    const mediaMessage = message as MediaMessage
    mediaMessage.mediaURL = url
    return mediaMessage
  }

  private buildMessageFromTwilioMessage(twilioMessage: TwilioMessage):
    Message | ScheduleMessage | MediaMessage {
    const {
      isScheduleMessage,
      professionalId,
      professionalCategory
    } = extractScheduleMetadata(twilioMessage.body || '')
    const message: Message = {
      text: twilioMessage.body,
      author: twilioMessage.author,
      media: twilioMessage.media && twilioMessage.media,
      dateCreated: new LocalDate(twilioMessage.dateCreated),
      type: isScheduleMessage ? 'schedule' : twilioMessage.type,
      index: twilioMessage.index,
      isSender: twilioMessage.author === this.currentUser.twilioIdentity,
    }
    if (message.type === 'media') {
      this.getMediaUrl(message).then((url: string) => {
        this.buildMediaMessage(message, url)
      })
    }
    if (isScheduleMessage) {
      if (professionalId) return this.buildProfessionalScheduleMessage(message, professionalId)

      if (professionalCategory) {
        return this.buildCategoryScheduleMessage(message, professionalCategory)
      }
    }

    return message
  }
}

export class TwilioChatServiceFactory implements ChatServiceFactory {
  constructor(private apiClient: ApiClient) { }

  async build(currentUser: User): Promise<TwilioChatService> {
    const client = await Client.create(await this.apiClient.getTwilioToken())
    const channel = await client.getChannelBySid(currentUser.channelSid)
    const twilioChatServiceInstance = new TwilioChatService(
      client,
      channel,
      currentUser,
      this.apiClient,
    )

    client.on('tokenAboutToExpire', async () => {
      client.updateToken(await this.apiClient.getTwilioToken())
    })

    client.on('tokenExpired', async () => {
      twilioChatServiceInstance.setForceReconnect()
    })
    return twilioChatServiceInstance
  }
}
