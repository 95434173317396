import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { NewListItem, Divider, Loader } from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import {
  fetchProfileAction,
  onUpdateProfileAction,
} from '../../state/profile/actions'
import Drawer from '../common/drawer/Drawer'
import { primary } from '../../color'
import BookingBlock from '../../../assets/booking-block.svg'
import PregnancyDueDate from '../common/PregnancyDueDate'
import LastPeriod from '../common/LastPeriod'
import { ProfileForm } from '../../domain/Profile'
import { promptDrawerAction, suppressDrawerAction } from '../../state/drawer/actions'
import './WeekView.scss'

interface Props {
  isUpdatingProfile?: boolean;
  promptDrawer: () => void;
  onUpdateProfile: (profile: ProfileForm) => void;
  profile?: ProfileForm;
  suppressDrawer: () => void;
  fetchProfile: () => void;
  title?: string;
  subtitle?: string;
  borderBottomStyle?: string;
}

function JourneyWithNoSuggestions({
  isUpdatingProfile,
  promptDrawer,
  onUpdateProfile,
  suppressDrawer,
  profile,
  fetchProfile,
  title,
  subtitle,
  borderBottomStyle,
}: Props) {
  const [typeWeekPlan, setTypeWeekPlan] = useState('')

  useEffect(() => {
    fetchProfile()
  }, [])

  function onPromptDrawer(type: string) {
    setTypeWeekPlan(type)
    promptDrawer()
  }

  function extraActionOnSuppressDrawer() {
    setTypeWeekPlan('')
  }

  const onSubmitToBack = (date: string, type: 'expectedBabyBirthDate' | 'lastPeriodStartDate') => {
    const formatedDate = moment(date, 'DD/MM/YYYY').valueOf()
    if (profile) {
      let newProfile = profile
      if (type === 'expectedBabyBirthDate') {
        newProfile = {
          ...profile,
          pregnancyDueDate: date
        }
      }
      if (type === 'lastPeriodStartDate') {
        newProfile = {
          ...profile,
          firstDayLastPeriod: `${formatedDate}`
        }
      }
      onUpdateProfile(newProfile)
    }
    setTimeout(() => {
      suppressDrawer()
    }, 2000)
  }

  const titleDefault = (
    'Para desbloquear conteúdos personalizados pra você, '
    + 'precisamos saber a data de nascimento do seu bebê.'
  )

  return (
    isUpdatingProfile ? (
      <div className="w-full mx-auto flex flex-1 justify-center items-center mb-4">
        <Loader />
      </div>
    ) : (
      <div>
        <div className="text-textPrimary text-center flex flex-col items-center">
          <BookingBlock />
          <p className="font-medium text-base mt-2 mb-4 px-6 md:max-w-xl xl:max-w-5xl">
            {title || titleDefault}
          </p>
          {subtitle && (
            <p className="font-normal text-sm mb-6">
              {subtitle}
            </p>
          )}
        </div>
        <Divider />
        <ul>
          <NewListItem
            onClick={() => onPromptDrawer('due-date')}
            iconType="icon-CalendarLight"
            iconColor={primary}
            title="Informar a data de nascimento do bebê"
            ariaLabel="Informar a data de nascimento do bebê"
            id="pregnance-due-date"
            isLastItem
          />
          <Divider />
          <NewListItem
            onClick={() => onPromptDrawer('last-period')}
            iconType="icon-WaterDropLight"
            iconColor={primary}
            title="Descobrir pela minha última menstruação"
            ariaLabel="Descobrir pela minha última menstruação"
            id="last-period"
            isLastItem
          />
        </ul>
        <Divider className={`${borderBottomStyle}`} />
        {typeWeekPlan && (
          <Drawer variant="rightDrawer" extraAction={extraActionOnSuppressDrawer}>
            {typeWeekPlan === 'due-date' && (
              <PregnancyDueDate
                submitToBack={(lastPeriodDate) => onSubmitToBack(lastPeriodDate, 'expectedBabyBirthDate')}
                isSubmitting={isUpdatingProfile}
              />
            )}
            {typeWeekPlan === 'last-period' && (
              <LastPeriod
                submitToBack={(lastPeriodDate) => onSubmitToBack(lastPeriodDate, 'lastPeriodStartDate')}
                isSubmitting={isUpdatingProfile}
              />
            )}
          </Drawer>
        )}
      </div>
    )
  )
}

const mapStateToProps = ({
  profile
}: AppState) => ({
  isUpdatingProfile: profile.isUpdatingProfile,
  profile: profile.profile,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  promptDrawer: () => { dispatch(promptDrawerAction()) },
  onUpdateProfile: (profile: ProfileForm) => {
    dispatch(onUpdateProfileAction(profile))
  },
  suppressDrawer: () => { dispatch(suppressDrawerAction()) },
  fetchProfile: () => { dispatch(fetchProfileAction()) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JourneyWithNoSuggestions)
