import {
  RouterListenerTypes,
  SET_ACTIVE_ROUTE
} from './actions'

export interface RouterListenerState {
  activeRoute: string;
  previousRoute: string;
}

const initialState: RouterListenerState = {
  activeRoute: '',
  previousRoute: ''
}

export function routerListener(
  state: RouterListenerState = initialState,
  action: RouterListenerTypes
): RouterListenerState {
  switch (action.type) {
    case SET_ACTIVE_ROUTE:
      return {
        ...state,
        previousRoute: state.activeRoute,
        activeRoute: action.route || ''
      }
    default:
      return state
  }
}
