export class LoginError extends Error {
  constructor(
    public message: string,
    public resendLink?: boolean,
    public redirect?: boolean,
    public statusCode?: number
  ) {
    super(message);
    Object.setPrototypeOf(this, LoginError.prototype)
    this.resendLink = resendLink
    this.redirect = redirect
    this.statusCode = statusCode
  }
}
