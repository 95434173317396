import React, { useState, useEffect } from 'react'
import './StepperBar.scss'

import './Accordion.scss';
import { bgButton, primary } from '../../color';

interface Props {
  totalSteps: number;
  currentStep: number;
  isNotAnimated?: boolean;
  showNumbers?: boolean;
  totalStepsColor?: string;
  currentStepColor?: string;
  lineHeight?: string;
  lineRadius?: string;
  showInfosBottom?: boolean;
  explanation?: React.ReactNode;
  isLoading?: boolean;
  titleColor?: string;
  titleSize?: string;
  stepperMargin?: string;
  stepperLabel?: string;
  titleMargin?: string;
}

function StepperBar({
  totalSteps,
  currentStep,
  isNotAnimated,
  lineRadius,
  totalStepsColor,
  currentStepColor,
  lineHeight = '2px',
  showNumbers = true,
  showInfosBottom = false,
  explanation,
  isLoading = false,
  titleColor,
  titleSize,
  stepperMargin,
  stepperLabel,
  titleMargin
}: Props) {
  const [width, setWidthState] = useState('0')
  const [active, setActiveState] = useState('')
  const [percentage, setPercentageState] = useState(0)
  let percent: number
  useEffect(() => {
    percent = (currentStep * 100) / totalSteps
    setActiveState(active === '' ? 'active' : '')
    setPercentageState(percent)
  }, [currentStep])
  useEffect(() => {
    if (percentage !== 0) {
      setWidthState(`${percentage}%`)
    }
  }, [percentage])
  return (
    <div className={`stepper-section ${stepperMargin || 'm-4'}`}>
      {showNumbers && !showInfosBottom && (
        <p className={`stepper-title
          ${titleColor || 'text-primary'}
          ${titleSize || 'text-sm'}
          ${titleMargin || 'mb-3'}`}
        >
          {stepperLabel || 'Etapa'} {currentStep} de {totalSteps}
        </p>
      )}
      <div className="stepper-bar">
        {isLoading ? (
          <span
            className="loading-stepper-line"
            style={{
              height: lineHeight,
              borderRadius: lineRadius,
              background: totalStepsColor || bgButton
            }}
          />
        ) : (
          <span
            className="stepper-line"
            style={{
              height: lineHeight,
              borderRadius: lineRadius,
              background: totalStepsColor || bgButton
            }}
          >
            <span
              style={{
                maxWidth: `${width}`,
                height: lineHeight,
                borderRadius: lineRadius,
                background: currentStepColor || primary
              }}
              className={`stepper-progress ${isNotAnimated ? 'not-animated' : ''} `}
            />
          </span>
        )}
      </div>
      {showInfosBottom && (
        <div className="stepper-infos-container">
          {isLoading ? (
            <div className="loading-explanation-container">
              <div className="loading-explanation" />
            </div>
          ) : (
            explanation
          )}
          {isLoading ? (
            <div className="loading-step" />
          ) : (
            <p className="stepper-progress-info"><span className="step-text">Passo&nbsp;</span>{currentStep} de {totalSteps}</p>
          )}
        </div>
      )}
    </div>
  )
}

export default StepperBar
