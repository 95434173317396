import {
  ScheduleFlowService,
  PaymentMethods,
  ResponseCategories,
  ScheduleInfosType,
  ScheduleResponse,
} from '../../domain/ScheduleFlow'
import ApiClient from './ApiClient'

export default class ApiScheduleFlowService implements ScheduleFlowService {
  constructor(private apiClient: ApiClient) { }

  getCategoriesToSchedule(childId?: string): Promise<ResponseCategories> {
    return this.apiClient.getCategoriesToSchedule(childId)
  }

  getPaymentMethods(startTime: number): Promise<PaymentMethods> {
    return this.apiClient.getPaymentMethods(startTime)
  }

  confirmSchedule(scheduleInfos: ScheduleInfosType): Promise<ScheduleResponse> {
    return this.apiClient.confirmSchedule(scheduleInfos)
  }
}
