import React, { useState, useEffect } from 'react'
import { Divider } from 'theia-web-ds'
import NewCheckbox from '../../NewCheckbox'

import './FormMultiSelect.scss'

interface Props {
  id?: string;
  label?: string;
  options: string[];
  onChange: (response: string[]) => void;
  error?: string;
  required?: boolean;
  isLoading?: boolean;
  initialValues?: string[];
}

export default function FormMultiSelect({
  id,
  label,
  options,
  onChange,
  error,
  isLoading,
  initialValues,
  required = false
}: Props) {
  const [responseList, setResponseList] = useState<string[]>(initialValues || [])

  useEffect(() => {
    if (initialValues) {
      setResponseList(initialValues)
    }
  }, [initialValues])

  function onAddOrRemove(option: string) {
    if (responseList.findIndex((o) => option === o) !== -1) {
      const newList = responseList.filter((o) => o !== option)
      setResponseList(newList)
      onChange(newList)
    } else {
      const newList = [...responseList, option]
      setResponseList(newList)
      onChange(newList)
    }
  }

  return (
    <div
      className="form-multi-select-container"
      role="listbox"
      aria-multiselectable
      aria-required={required}
      aria-labelledby={label}
    >
      {isLoading ? (
        <div className="loading-label" />
      ) : (
        <label className="label-text" id={label}>{label}</label>
      )}
      {options.map((option, index) => (
        <div key={`${id}-${option}`}>
          <NewCheckbox
            id={`${id}-${option}`}
            name={option}
            value={responseList.findIndex((o) => option === o) !== -1}
            onKeyPress={() => onAddOrRemove(option)}
            substituteOnChange={() => onAddOrRemove(option)}
            aria-selected={responseList.findIndex((o) => option === o) !== -1}
            isLoading={isLoading}
          >
            <div className="label-option-container">
              {option}
            </div>
          </NewCheckbox>
          {index < (options.length - 1) && (
            <Divider className="multiselect-divider" />
          )}
        </div>
      ))}
      {error ? (
        <p className="help">{error}</p>
      ) : null}
    </div>
  )
}
