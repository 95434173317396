import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  NewChipButton,
  RegularButton,
  ErrorMessage,
} from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { updateChildHealthPlanAction } from '../../state/pediatricFlow/actions'
import { BabyData } from '../../domain/PediatricFlow'
import HealthPlanRegistrationForm, { HealthInsurancePlanForm } from '../common/HealthPlanRegistrationForm';
import { eventTrack } from '../../../eventGenerate'
import { dontHavePlan, havePlan, isNotInformed } from '../../utils/helpers'
import './RegisterChildHealthPlan.scss'
import { PEDIATRIC_SCHEDULE } from '../../utils/EventCategories'

interface Props {
  selectedChild?: BabyData;
  isUpdatingChildData: boolean;
  isGettingChildrenSummary: boolean;
  isGettingChildrenData: boolean;
  failureUpdatingChildren: boolean;
  updateChildHealthPlan: (babiesData: BabyData) => void;
  actionOnMount?: () => void;
}

function RegisterChildHealthPlan({
  selectedChild,
  isUpdatingChildData,
  isGettingChildrenSummary,
  isGettingChildrenData,
  failureUpdatingChildren,
  updateChildHealthPlan,
  actionOnMount,
}: Props) {
  const [healthInsurancePlanStatus, setHealthInsurancePlanStatus] = useState(
    selectedChild?.healthInsurancePlanStatus
  )
  const [chipsError, setChipsError] = useState<string | undefined>()
  const isLoading = isUpdatingChildData || isGettingChildrenSummary || isGettingChildrenData

  useEffect(() => {
    if (actionOnMount) actionOnMount()
  }, [])

  function handlePostHealthInsurancePlan(values?: HealthInsurancePlanForm) {
    if (isNotInformed(healthInsurancePlanStatus)) {
      return setChipsError('Selecione uma opção para continuar')
    }
    eventTrack('Clicou continuar convenio criança', {
      category: PEDIATRIC_SCHEDULE
    })
    return updateChildHealthPlan({
      id: selectedChild?.id,
      healthInsurancePlanStatus,
      companyId: values?.companyId,
      planId: values?.planId,
      memberId: values?.memberId,
      planName: values?.planName,
      companyName: values?.companyName
    })
  }

  return (
    <div className="child-healthplan-view">
      <div className="healthplan-body">
        <h1 className="title">
          Seu bebê possui plano de saúde?
        </h1>
        <div className="chips-container">
          <NewChipButton
            key="Sim"
            label="Sim"
            isSelected={havePlan(healthInsurancePlanStatus)}
            onClick={() => {
              setHealthInsurancePlanStatus('HAVE_PLAN')
              setChipsError(undefined)
              eventTrack('Clicou criança tem plano de saúde', {
                category: PEDIATRIC_SCHEDULE
              })
            }}
            disabled={isLoading}
            height="48px"
            width="100%"
          />
          <NewChipButton
            key="Não"
            label="Não"
            isSelected={dontHavePlan(healthInsurancePlanStatus)}
            onClick={() => {
              setHealthInsurancePlanStatus('DONT_HAVE_PLAN')
              setChipsError(undefined)
              eventTrack('Clicou criança não tem plano de saúde', {
                category: PEDIATRIC_SCHEDULE
              })
            }}
            disabled={isLoading}
            height="48px"
            width="100%"
          />
        </div>
        {chipsError && (
          <ErrorMessage error={chipsError} />
        )}
        {havePlan(healthInsurancePlanStatus) && (
          <div className="form-container">
            <HealthPlanRegistrationForm
              onPostHealthInsurance={handlePostHealthInsurancePlan}
              showOnlyCovered
              isChildHealthPlan
              failureToSave={failureUpdatingChildren}
            />
          </div>
        )}
      </div>
      {!havePlan(healthInsurancePlanStatus) && (
        <div className="continue-btn">
          <RegularButton
            label="Continuar"
            onClick={() => handlePostHealthInsurancePlan()}
            isSubmitting={isLoading}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({
  pediatricFlow
}: AppState) => ({
  isUpdatingChildData: pediatricFlow.isUpdatingBabies,
  isGettingChildrenSummary: pediatricFlow.isGettingChildrenSummary,
  isGettingChildrenData: pediatricFlow.isGettingChildrenData,
  failureUpdatingChildren: pediatricFlow.failureUpdatingChildren
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateChildHealthPlan: (babiesData: BabyData) => {
    dispatch(updateChildHealthPlanAction(babiesData))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterChildHealthPlan)
