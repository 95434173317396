import * as Sentry from '@sentry/browser'
import { AsyncAction } from '../utils'
import { HttpClient } from '../../services/Http'
import { PlatformProvider, Platform } from '../../domain/Platform'

export const GET_PLATFORM = 'GET_PLATFORM'
export const SUCCESS_GET_PLATFORM = 'SUCCESS_GET_PLATFORM'
export const FAILURE_GET_PLATFORM = 'FAILURE_GET_PLATFORM'

interface GetPlatformActionType {
  type: typeof GET_PLATFORM;
}

interface SuccessGetPlatformActionType {
  type: typeof SUCCESS_GET_PLATFORM;
  platformInfo: Platform;
}

interface FailureGetPlatformActionType {
  type: typeof FAILURE_GET_PLATFORM;
}

export type PlatformTypes = GetPlatformActionType |
SuccessGetPlatformActionType |
FailureGetPlatformActionType

export const successGetPlatformAction = (platformInfo: Platform): SuccessGetPlatformActionType => ({
  type: SUCCESS_GET_PLATFORM,
  platformInfo
})

export const failureGetPlatformAction = (): FailureGetPlatformActionType => ({
  type: FAILURE_GET_PLATFORM
})

export function getPlatformAction():
  AsyncAction<{}, { httpClient: HttpClient; platformProvider: PlatformProvider }> {
  return async (dispatch, _getState, { platformProvider }) => {
    try {
      const platform: Platform = await platformProvider.getPlatform()
      const platformInfo = {
        ...platform
      }
      dispatch(successGetPlatformAction(platformInfo))
    } catch (error) {
      dispatch(failureGetPlatformAction())
      Sentry.captureException(new Error(`Failure to get platform infos - ${error.message}`))
    }
  }
}
