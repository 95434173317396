import React, { ComponentType, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { isProductionEnv } from '../../../utils/helpers';

interface Props {
  stripe: Promise<any | null>;
  stripeLoaded: boolean;
  stripeError: object | null;
}

export default function withLoadStripe<BaseProps>(
  BaseComponent: ComponentType<BaseProps & Props>
) {
  function Hoc({
    ...props
  }: any) {
    const [stripeLoaded, setStripeLoaded] = useState(false)
    const [stripeError, setStripeError] = useState(null)
    const API_KEY = isProductionEnv() ? process.env.STRIPE_PRD || '' : process.env.STRIPE_STG || ''

    const stripePromise = loadStripe(API_KEY, { locale: 'pt-BR' })
    stripePromise.then(() => {
      setStripeLoaded(true)
    })
    stripePromise.catch((error) => {
      setStripeLoaded(false)
      setStripeError(error)
    })

    return (
      <BaseComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        stripe={stripePromise}
        stripeLoaded={stripeLoaded}
        stripeError={stripeError}
      />
    )
  }

  return Hoc
}
