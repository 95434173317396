import React, { useEffect, useState } from 'react'
import { Divider, RegularButton } from 'theia-web-ds'
import { BarWithModal } from '../common/BarWithModal'
import './PaymentSummary.scss'

interface Props {
  description: string;
  isLastStep: boolean;
  price?: number;
  discount?: number;
  coupon?: string;
  promotionalPrice?: number;
  isLoading?: boolean;
  buttonLabel: string;
  onClick: () => void;
}
export default function PaymentSummary({
  description,
  isLastStep,
  price,
  discount,
  coupon,
  promotionalPrice,
  isLoading,
  buttonLabel,
  onClick,
}: Props) {
  const [isSummaryOpen, setIsSummaryOpen] = useState(false)
  const [barHeight, setBarHeight] = useState('')
  const total = discount ? (promotionalPrice || 0) / 100 : (price || 0) / 100

  useEffect(() => {
    if (isLastStep) {
      setBarHeight(discount ? '265px' : '235px')
    } else if (!discount) {
      setBarHeight('180px')
    } else {
      setBarHeight('205px')
    }
  }, [isSummaryOpen])

  const confirmButton = (
    <RegularButton
      onClick={onClick}
      label={buttonLabel}
      maxWidth="100%"
      disabled={isLoading}
      isSubmitting={isLoading}
      extraClass="mb-4 lg:mb-0"
    />
  )

  const summaryComponent = (
    <div className="payment-summary-container">
      <div className="summary-info-container pt-4 pb-2">
        <p className="summary-description">{description}</p>
        <p className="summary-price">R$ {(price || 0) / 100}</p>
      </div>
      {discount && (
        <div className="summary-info-container pb-4 text-success">
          <p className="summary-description">Desconto {coupon}</p>
          <p className="summary-price">- R$ {discount / 100}</p>
        </div>
      )}
      <Divider />
      <div className="summary-info-container pt-4 pb-2 text-fontSmall">
        <p className="summary-description text-textSecondary">Valor total</p>
        <p className="summary-price">R$ {total}</p>
      </div>
      {isLastStep && (
        <div className="hidden md:block">
          {confirmButton}
        </div>
      )}
    </div>
  )

  return (
    <>
      <div className="payment-summary-desktop-container">
        <div className="payment-summary-desktop-body">
          <div className="payment-summary-desktop-content">
            <h1 className="text-fontSmall text-textPrimary font-normal">
              Resumo
            </h1>
            {summaryComponent}
          </div>
        </div>
      </div>
      <BarWithModal
        isOpen={isSummaryOpen}
        onClose={() => setIsSummaryOpen(false)}
        onOpen={() => setIsSummaryOpen(true)}
        modalHeight={barHeight}
        modalComponent={summaryComponent}
        title="Resumo"
        buttonBar={confirmButton}
        hasbutton={isLastStep}
        subtitle={`Total R$ ${total}`}
      />
    </>
  )
}
