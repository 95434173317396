export const SET_SPECIALIST_ONLINE = 'SET_SPECIALIST_ONLINE'
export const SET_SPECIALIST_OFFLINE = 'SET_SPECIALIST_OFFLINE'
export const SET_SPECIALIST_TYPING = 'SET_SPECIALIST_TYPING'

interface OnlineSpecialistActionType {
  type: typeof SET_SPECIALIST_ONLINE;
  onlineSpecialist: string;
}

interface OfflineSpecialistActionType {
  type: typeof SET_SPECIALIST_OFFLINE;
}

interface SpecialistTypingActionType {
  type: typeof SET_SPECIALIST_TYPING;
  typing: boolean;
}

export type SpecialistPresenceTypes =
  OnlineSpecialistActionType |
  OfflineSpecialistActionType |
  SpecialistTypingActionType

export const setSpecialistOnlineAction = (onlineSpecialist: string): SpecialistPresenceTypes => ({
  type: SET_SPECIALIST_ONLINE, onlineSpecialist
})

export const setSpecialistOfflineAction = (): SpecialistPresenceTypes => ({
  type: SET_SPECIALIST_OFFLINE
})

export const setSpecialistTypingAction = (typing: boolean): SpecialistTypingActionType => ({
  type: SET_SPECIALIST_TYPING,
  typing
})
