import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';

function ScrollToTop({ history }: { history: History }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      try {
        window.scrollTo(0, 0);
        document.getElementById('item-b')?.scrollTo(0, 0)
        document.getElementById('grid-container')?.scrollTo(0, 0)
      } catch (error) {
        // Do nothing
      }
    });
    return () => {
      unlisten();
    }
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
