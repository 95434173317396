import React, { useState } from 'react'
import { ContentCard, IconButton } from 'theia-web-ds'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PhoneConfirmIlustra from '../../../../assets/phone-confirm.png'
import { textSecondary } from '../../../color';
import { AppDispatch } from '../../../state/utils'
import { promptValidatePhoneModalAction } from '../../../state/profile/actions';
import { eventTrack } from '../../../../eventGenerate';
import { PHONE_VALIDATION } from '../../../utils/EventCategories';

interface Props {
    promptValidatePhoneModal: (isFromProfileEdit: boolean) => void;
  }

function PhoneValidationCard({
  promptValidatePhoneModal,
}: Props) {
  const [visible, setVisible] = useState(true)

  function handleClickClose() {
    eventTrack('fechou o card', {
      category: PHONE_VALIDATION,
    })
    setVisible(false)
  }

  function handleClickConfirmPhone() {
    eventTrack('clicou card na home', {
      category: PHONE_VALIDATION,
    })
    promptValidatePhoneModal(false)
  }

  return visible ? (
    <div className="p-4">
      <ContentCard
        firstButtonProps={{
          label: 'Validar celular',
          onClick: handleClickConfirmPhone
        }}
        type="outlined"
        headerTitle="Valide seu celular para receber informações de suas consultas"
        ilustraBefore={{ src: PhoneConfirmIlustra }}
        buttonAfter={(
          <IconButton
            variant="text"
            onClick={handleClickClose}
            iconType="icon-CloseLight"
            iconColor={textSecondary}
            iconSize="24px"
          />
        )}
      />
    </div>
  ) : null
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  promptValidatePhoneModal: promptValidatePhoneModalAction
}, dispatch)

export default connect(
  null,
  mapDispatchToProps
)(PhoneValidationCard)
