/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Loader, NewestCheckbox, RegularButton, Button, MaskedFormInput, FormInput
} from 'theia-web-ds';

import { bindActionCreators, Dispatch } from 'redux'
import { Formik, FormikProps, FormikErrors } from 'formik'
import * as Yup from 'yup'
import * as Sentry from '@sentry/browser'
import { useHistory } from 'react-router-dom'

import PrivacyModal from './PrivacyModal'
import HealthDataModal from './HealthDataModal'
import './Authentication.scss'
import { SimpleHttpClient } from '../../services/Http'
import {
  onlyDigits,
  cpfValidation,
  getMobileSignupOrLoginClient,
  getFlutterClient,
  cpfMask,
  phoneMask,
  validationPhoneRegex
} from '../../utils/helpers'
import {
  loginAction,
  promptPrivacyModalAction,
  promptTermsModalAction,
  promptHealthDataModalAction,
} from '../../state/authentication/main/actions'
import { promptDefaultErrorAction } from '../../state/defaultError/actions'
import ErrorModal from '../common/ErrorModal'
import { AppState } from '../../apps/main/store'
import { promptErrorAction, ErrorWithFriendlyMessage } from '../../state/errorWithFriendlyMessage/actions'
import TermsModal from './TermsModal'
import AppMainContainerOff from '../AppMainContainerOff'
import { getCurrentTermsAction } from '../../state/terms/actions'
import { CurrentTerm } from '../../domain/Terms'
import { FetchStatusType } from '../../domain/Status'
import { LOCALIZACAO, LOGIN } from '../../routes/RoutesConstants';
import { API_URL } from '../../services/api/ApiClient';

interface Props {
  login: () => void;
  defaultErrorVisible: boolean;
  promptError: (signupError: ErrorWithFriendlyMessage) => void;
  promptPrivacyModal: () => void;
  promptTermsModal: () => void;
  promptHealthDataModal: () => void;
  getCurrentTerms: () => void;
  termsPolicy: Array<CurrentTerm>;
  privacyPolicy: Array<CurrentTerm>;
  healthDataPolicy: Array<CurrentTerm>;
  termIds: Array<string>;
  termsFetchStatus: FetchStatusType;
}

interface SignUpFormValues {
  name: string;
  email: string;
  document: string;
  password: string;
  phone: string;
  policy: boolean;
  terms: boolean;
  health: boolean;
  benefitByCompany: boolean;
  useCorporativeEmail: boolean;
  marketingTag: string;
}

function renderSignupForm(
  {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    errors
  }: FormikProps<SignUpFormValues>,
  promptPrivacyModal: () => void,
  promptTermsModal: () => void,
  promptHealthDataModal: () => void,
  termIds: Array<string>
) {
  const history = useHistory();
  function goBack() {
    history.push(LOGIN)
  }
  const canSubmit = (
    !!values.name
    && !!values.email
    && !!values.phone
    && !!values.document
    && !!values.password
    && cpfValidation(values.document)
    && values.policy === true
    && values.terms === true
    && values.health === true
  )

  function promptPrivacyModalAux(e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) {
    if (e.type === 'click' || (e.type === 'keydown' && (e as React.KeyboardEvent<HTMLButtonElement>).key === 'Enter')) {
      e.preventDefault()
      promptPrivacyModal()
    }
  }

  function promptTermsModalAux(e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) {
    if (e.type === 'click' || (e.type === 'keydown' && (e as React.KeyboardEvent<HTMLButtonElement>).key === 'Enter')) {
      e.preventDefault()
      promptTermsModal()
    }
  }

  function promptTermsModalHealth(e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) {
    if (e.type === 'click' || (e.type === 'keydown' && (e as React.KeyboardEvent<HTMLButtonElement>).key === 'Enter')) {
      e.preventDefault()
      promptHealthDataModal()
    }
  }

  const setPolicyValue = () => setFieldValue('policy', !values.policy)
  const setTermsValue = () => setFieldValue('terms', !values.terms)
  const setHealthValue = () => setFieldValue('health', !values.health)

  return (
    <AppMainContainerOff screenTitle="Vamos criar a sua conta!">
      <div className="app-signup">
        <div className="flex flex-1 relative">
          <div className="mx-auto w-full signup">
            <div className="flex flex-col rounded">
              <form noValidate className="auth-form signup-form" name="signUpForm">
                <div className="mb-2">
                  <FormInput
                    value={values.name}
                    onChange={handleChange}
                    errorMessage={touched.name ? errors.name : ''}
                    id="name"
                    onBlur={handleBlur}
                    placeholder="Digite aqui seu nome"
                    type="text"
                    name="name"
                    label="Nome"
                  />
                </div>
                <div className="mt-6 mb-2">
                  <MaskedFormInput
                    label="CPF"
                    name="document"
                    type="text"
                    placeholder="000.000.000-00"
                    mask={cpfMask}
                    id="document"
                    onChange={handleChange}
                    errorMessage={touched.document ? errors.document : ''}
                    onBlur={handleBlur}
                    value={values.document}
                  />
                </div>
                <div className="mt-6 mb-2">
                  <MaskedFormInput
                    label="Celular"
                    id="phone"
                    mask={phoneMask}
                    onChange={handleChange}
                    errorMessage={touched.phone ? errors.phone : ''}
                    onBlur={handleBlur}
                    type="tel"
                    value={values.phone}
                    name="phone"
                    placeholder="(00) 00000-0000"
                  />
                </div>
                <div className="mt-6 mb-2">
                  <FormInput
                    value={values.email}
                    onChange={handleChange}
                    errorMessage={touched.email ? errors.email : ''}
                    id="email"
                    onBlur={handleBlur}
                    label="E-mail"
                    type="email"
                    name="email"
                    placeholder="E-mail para login e notificações"
                  />
                </div>
                <div className="mt-6 mb-4">
                  <FormInput
                    value={values.password}
                    onChange={handleChange}
                    errorMessage={touched.password ? errors.password : ''}
                    id="password"
                    onBlur={handleBlur}
                    placeholder="Senha com mais de 8 caracteres"
                    type="password"
                    name="password"
                    label="Senha"
                  />
                </div>

                <div className="flex items-center mb-2">
                  <NewestCheckbox
                    name="policy"
                    value={values.policy.toString()}
                    onClick={setPolicyValue}
                    componentExtraClass="terms-checkbox"
                    disabled={!termIds.length}
                  />
                  <p>
                    Li e estou de acordo com as&nbsp;
                    <span
                      className="font-bold"
                      id="policyPrivacyId"
                      role="button"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => promptPrivacyModalAux(e)}
                      onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => promptPrivacyModalAux(e)}
                      tabIndex={0}
                    >
                      políticas de privacidade
                    </span>.
                  </p>
                  <p className="help">{errors.policy}</p>
                </div>

                <div className="flex items-center mb-2">
                  <NewestCheckbox
                    name="terms"
                    value={values.terms.toString()}
                    onClick={setTermsValue}
                    componentExtraClass="terms-checkbox"
                    disabled={!termIds.length}
                  />
                  <p>
                    Li e estou de acordo com os&nbsp;
                    <span
                      className="font-bold"
                      id="termsId"
                      role="button"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => promptTermsModalAux(e)}
                      onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => promptTermsModalAux(e)}
                      tabIndex={0}
                    >
                      termos de uso
                    </span>.
                  </p>
                  <p className="help">{errors.terms}</p>
                </div>

                <div className="flex items-center mb-2">
                  <NewestCheckbox
                    name="health"
                    value={values.health.toString()}
                    onClick={setHealthValue}
                    componentExtraClass="terms-checkbox"
                    disabled={!termIds.length}
                  />
                  <p>
                    Li, estou de acordo e consinto com o&nbsp;
                    <span
                      className="font-bold"
                      id="healthDataId"
                      role="button"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => promptTermsModalHealth(e)}
                      onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => promptTermsModalHealth(e)}
                      tabIndex={0}
                    >
                      termo de coleta de dados de saúde
                    </span>.
                  </p>
                  <p className="help">{errors.health}</p>
                </div>

                <div className="signup-button-confirm sm:mt-4">
                  <RegularButton
                    type="submit"
                    label="Criar conta"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting || !canSubmit}
                    isSubmitting={isSubmitting}
                    maxWidth="100%"
                  />
                </div>
              </form>
              <Button className="text-textSmaller underline text-greyColor font-light mb-12" height="auto" width="100%" onClick={goBack}>
                Já tenho conta Theia
              </Button>
            </div>
          </div>
        </div>
      </div>
    </AppMainContainerOff>
  );
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Nome obrigatório'),
  email: Yup.string().trim()
    .email('E-mail inválido')
    .required('Email obrigatório'),
  document: Yup.string()
    .required('CPF obrigatório')
    .test(
      'test-document',
      'CPF inválido',
      (value) => cpfValidation(value)
    ),
  phone: Yup.string()
    .required('Celular obrigatório')
    .matches(validationPhoneRegex, 'Celular inválido'),
  password: Yup.string()
    .required('Senha obrigatória')
    .min(8, 'A senha deve ter pelo menos 8 caracteres'),
  policy: Yup.bool().required('Por favor, aceite as políticas de privacidade'),
  terms: Yup.bool().required('Por favor, aceite os termos de uso'),
  health: Yup.bool().required('Por favor, aceite os termos de coleta de dados de saúde'),
})

const initialValues = {
  name: '',
  email: '',
  document: '',
  password: '',
  phone: '',
  policy: false,
  terms: false,
  health: false,
  benefitByCompany: true,
  useCorporativeEmail: true,
  marketingTag: '',
}

function SignUpForm({
  login,
  defaultErrorVisible,
  promptError,
  promptPrivacyModal,
  promptTermsModal,
  getCurrentTerms,
  termsPolicy,
  privacyPolicy,
  healthDataPolicy,
  promptHealthDataModal,
  termIds,
  termsFetchStatus
}: Props) {
  const history = useHistory()

  useEffect(() => {
    getCurrentTerms()
  }, [])

  if (termsFetchStatus.isFetching) {
    return (
      <AppMainContainerOff>
        <div className="flex h-full justify-center items-center form-status-container">
          <Loader />
        </div>
      </AppMainContainerOff>
    )
  }

  if (termsFetchStatus.errorFetch) {
    return (
      <AppMainContainerOff>
        <div className=" flex flex-col h-full justify-center items-center form-status-container">
          <p className="mb-4 text-center">
            Erro ao carregar as informações. Tente novamente.
          </p>
          <RegularButton
            label="Atualizar página"
            onClick={getCurrentTerms}
            btnSize="large"
            maxWidth="80%"
          />
        </div>
      </AppMainContainerOff>
    )
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async ({
          name, email, document, password, phone, benefitByCompany,
          policy, terms, health, marketingTag
        }, { setSubmitting, setErrors }) => {
          // TODO: Move submit logic to service and `signUpAction`.
          const url = `${API_URL}/v2/consultants`
          const httpClient = new SimpleHttpClient()
          const payload = {
            name,
            document: onlyDigits(document),
            email: email.toLowerCase().trim(),
            phone,
            notificationEmail: email.toLowerCase().trim(),
            password,
            benefitByCompany,
            privacyPolicyOptIn: policy && terms && health,
            useCorporativeEmail: true,
            marketingTag
          }
          const errors: FormikErrors<SignUpFormValues> = {}
          const response = await httpClient.post({ url, payload })
          if (response.status !== 200) {
            setSubmitting(false)
            const json = JSON.parse(response.body)
            Sentry.captureException(new Error(`Failure on signup: ${json.message || response.status}`))
            const signupError = {
              friendlyMessageTitle: json.friendlyMessageTitle,
              friendlyMessage: json.friendlyMessage
            }
            if (json?.friendlyMessage?.includes('User already exist with this')) {
              errors.email = 'Este email já está sendo usado'
              setErrors(errors)
            } else {
              promptError(signupError)
            }
          } else {
            const json = JSON.parse(response.body)
            if (json.errors) {
              const fieldProblems: string[] = []
              json.errors.forEach(({ field, message }: { field: string; message: string }) => {
                fieldProblems.push(field)
                if (field === 'password') errors.password = message
                if (field === 'email') errors.email = message
                if (field === 'name') errors.name = message
                if (field === 'phone') errors.phone = message
                if (field === 'document') errors.document = message
              })
              setErrors(errors)
              Sentry.captureException(new Error(`Failure on signup: problem with the fields ${fieldProblems.toString()}`))
            } else {
              const { token } = JSON.parse(response.body)
              const mobileSignupClient = getMobileSignupOrLoginClient()
              if (mobileSignupClient) {
                mobileSignupClient.tellMobileToken(token)
              }
              const flutterClient = getFlutterClient()
              if (flutterClient) {
                flutterClient.callHandler('tellMobileToken', { token, isFinished: false })
              }
              await login()
              history.push(LOCALIZACAO)
            }
          }
        }}
      >
        {(props) => renderSignupForm(
          props,
          promptPrivacyModal,
          promptTermsModal,
          promptHealthDataModal,
          termIds
        )}
      </Formik>
      <ErrorModal
        visible={defaultErrorVisible}
      />
      <PrivacyModal lastIdFocused="policyPrivacySalesOnboardingSignUpId" privacy={privacyPolicy} />
      <TermsModal lastIdFocused="termsSalesOnboardingSignUpId" terms={termsPolicy} />
      <HealthDataModal lastIdFocused="healthDataId" data={healthDataPolicy} />
    </>
  )
}

const mapStateToProps = ({ errorWithFriendlyMessage, terms }: AppState) => ({
  defaultErrorVisible: errorWithFriendlyMessage.visible,
  termsPolicy: terms.termsPolicy,
  privacyPolicy: terms.privacyPolicy,
  healthDataPolicy: terms.healthDataPolicy,
  termIds: terms.termIds,
  termsFetchStatus: terms.termsFetchStatus
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  login: loginAction,
  promptDefaultError: promptDefaultErrorAction,
  promptError: promptErrorAction,
  promptPrivacyModal: promptPrivacyModalAction,
  promptHealthDataModal: promptHealthDataModalAction,
  promptTermsModal: promptTermsModalAction,
  getCurrentTerms: getCurrentTermsAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpForm)
