import React from 'react'
import { ErrorMessage, RegularButton } from 'theia-web-ds'
import { isValidElement } from '../../../../utils/helpers'
import './NewFormInputList.scss'

interface Props {
  label?: string;
  list: any[];
  errors?: {
    [index: number]: string;
  };
  required?: boolean;
  buttonLabel: string;
  extraButtonOnClick?: () => void;
  placeholder?: string;
  disabled?: boolean;
  ariaLabel?: string;
  newListElement: any;
  onChange: (response: any[]) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>, index: number) => void;
}

export default function NewFormInputList({
  label,
  list,
  errors,
  required,
  placeholder,
  buttonLabel,
  extraButtonOnClick,
  disabled,
  ariaLabel,
  newListElement,
  onChange,
  onBlur
}: Props) {
  function onAddNewElement() {
    const newResponseList = [...list, newListElement]
    onChange(newResponseList)
    if (extraButtonOnClick) {
      extraButtonOnClick()
    }
  }

  function onRemoveElement(selectedIndex: number) {
    const newResponseList = list.filter((_, index) => index !== selectedIndex)
    onChange(newResponseList)
  }

  function onUpdateValue(newObj: any, index: number) {
    const newResponseList = list.map((currentObj, currentIndex) => (
      currentIndex === index ? newObj : currentObj
    ))
    onChange(newResponseList)
  }

  return (
    <div
      className="new-form-input-list-container"
      aria-required={required}
      aria-labelledby={label}
      aria-label={ariaLabel}
    >
      {list.length > 0 && list.map((obj, index) => (
        <div
          key={`-${index}`}
          className="input-group"
        >
          <div className="label-container">
            {label && (
              <label className={errors && errors[index] ? 'error' : ''} id={label} htmlFor={`item-${index}`}>
                {label} {list.length > 1 ? `(${index + 1})` : ''}
              </label>
            )}
            {list.length > 1 && index > 0 && !isValidElement(obj.id) && (
              <button
                type="button"
                onClick={() => onRemoveElement(index)}
                data-testid={`remove-button-${index}`}
              >
                Remover
              </button>
            )}
          </div>
          <input
            id={`item-${index}`}
            autoComplete="off"
            className={`input ${errors && errors[index] ? 'error' : ''}`}
            type="text"
            name={`input-item-${index}`}
            placeholder={placeholder}
            onChange={(e) => onUpdateValue({
              ...obj,
              name: e.target.value,
            }, index)}
            value={obj.name}
            disabled={disabled}
            onBlur={(e) => onBlur(e, index)}
            data-testid={`input-item-${index}`}
          />
          {errors && errors[index] && (
            <ErrorMessage error={errors[index]} />
          )}
        </div>
      ))}
      <RegularButton
        variant="subtle"
        label={buttonLabel}
        type="button"
        onClick={onAddNewElement}
      />
    </div>
  )
}
