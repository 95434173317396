/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { AttendanceAvailabilityType } from './ScheduleFlow'

export type Specialist = {
  name: string;
  pictureUrl: string;
  id: string;
}

export type SpecialistFromList = {
  name: string;
  chronosId: string;
}

export enum SpecialistCategories {
  GINECOLOGIST = 'Ginecologista-Obstetra',
  OBSTETRIC_NURSE = 'Enfermeira Obstetra',
  PEDIATRICIAN = 'Pediatra',
  PHYSIOTHERAPIST = 'Fisioterapeuta Pélvica',
  NUTRITIONIST = 'Nutricionista',
  PSYCHOLOGIST = 'Psicólogo (a)',
  DENTIST = 'Dentista e Ortodontista',
  PEDIATRIC_DENTIST = 'Odontopediatria',
  PERSONAL_EXPERT = 'Especialista Pessoal',
  BREASTFEEDING_CONSULTANT = 'Consultora de amamentação',
  EXAM = 'Exame realizado na Theia',
  ALL = 'Todas as categorias'
}

export type SpecialistDetails = {
  id: string;
  name: string;
  category: SpecialistCategories;
  pictureUrl: string;
  summary: string;
}

export interface SpecialistProvider {
  getSpecialist: (identity: string) => Promise<Specialist>;
  getAvailableSpecialists(
    categoryId: string,
    meetingType: AttendanceAvailabilityType
  ): Promise<SpecialistFromList[]>;
  getSpecialistDetails(specialistId: string): Promise<SpecialistDetails>;
}
