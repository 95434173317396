import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  ListItemCard,
  AccessibleModal,
  ButtonOptions,
  Divider,
  RegularButton
} from 'theia-web-ds'
import { error, primary, textSecondary } from '../../../color'
import { User } from '../../../domain/User'
import AddCardImg from '../../../../assets/AddCard.svg'
import { AppState } from '../../../apps/main/store'
import { AppDispatch } from '../../../state/utils'
import { onDeleteCardInfoAction, onGoBackToProfileAfterUpdateCardPaymentInfoAction } from '../../../state/payment/actions'
import { updateCurrentUserInfosAction } from '../../../state/authentication/main/actions'
import './PaymentCardProfile.scss'
import { EDIT_PAYMENT, PROFILE } from '../../../routes/RoutesConstants'

interface Props {
  currentUser?: User;
  isFetchingProfile?: boolean;
  onDeleteCardInfo: () => void;
  isSuccessDeletedCardPayment?: boolean;
  onUpdateCurrentUser: () => void;
  onGoBackToProfileAfterUpdateCardPaymentInfo: () => void;
}

function PaymentCardProfile({
  currentUser,
  isFetchingProfile,
  onDeleteCardInfo,
  isSuccessDeletedCardPayment,
  onUpdateCurrentUser,
  onGoBackToProfileAfterUpdateCardPaymentInfo,
}: Props) {
  const [
    buttonDrawerFromPaymentIsVisible,
    setButtonDrawerFromPaymentIsVisible,
  ] = useState(false)
  const [deleteAlertCard, setDeleteAlertCard] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (isSuccessDeletedCardPayment) {
      onUpdateCurrentUser()
      onGoBackToProfileAfterUpdateCardPaymentInfo()
      history.push(PROFILE, ['isFromDeleteCardPayment'])
    }
  }, [isSuccessDeletedCardPayment])

  const closeButtonDrawerFromPayment = () => {
    setButtonDrawerFromPaymentIsVisible(false)
  }

  const openButtonDrawerFromPayment = () => {
    setButtonDrawerFromPaymentIsVisible(true)
  }

  const suppressModal = () => {
    setDeleteAlertCard(false)
  }

  const onBtnDeleteCard = () => {
    setDeleteAlertCard(true)
    setButtonDrawerFromPaymentIsVisible(false)
  }

  const goToEditPayment = () => {
    history.push(EDIT_PAYMENT)
  }

  const onDeleteCardFromModal = () => {
    onDeleteCardInfo()
    setIsLoading(true)
  }

  const deleteCard = (
    <AccessibleModal
      headerText=""
      visible={deleteAlertCard}
      showCloseButton={false}
      onClose={suppressModal}
      extraModalContainerClass="delete-plan-modal"
    >
      <p className="m-4 text-fontDefault text-textPrimary text-center">
        Tem certeza que deseja excluir seu cartão {currentUser?.plan.cardBrand}?
      </p>
      <Divider />
      <div className="flex">
        <RegularButton
          label="Cancelar"
          variant="text"
          extraClass="btn-from-list-card btn-left"
          onClick={suppressModal}
        />
        <span className="button-bottom" />
        <RegularButton
          label="Excluir"
          variant="text"
          extraClass="btn-from-list-card btn-right btn-delete-plan"
          onClick={onDeleteCardFromModal}
          isSubmitting={isLoading}
        />
      </div>
    </AccessibleModal>
  )
  return (
    <>
      {deleteAlertCard && deleteCard}
      {currentUser?.plan.paymentMethodAdded ? (
        <ButtonOptions
          buttonToAnchor={(
            <ListItemCard
              extraClassNameContainer="profile-card payment list-item-card-no-btn"
              extraClassClickableContainer="list-item-container-no-btn"
              onClick={openButtonDrawerFromPayment}
              picture={(
                <span className="profile-icon">
                  <AddCardImg />
                </span>
            )}
              iconColor={primary}
              caption={
              currentUser?.plan.cardBrand
                ? `Cartão ${currentUser.plan.cardBrand} final ${currentUser.plan.cardLastDigits}`
                : ''
            }
              title="Pagamentos"
              ariaLabel="edit-payment"
              alertMessage=""
              isLastItem
              isLoading={isFetchingProfile}
              isItemFromList={false}
              key="edit-payment"
            />
        )}
          visible={buttonDrawerFromPaymentIsVisible}
          onClose={closeButtonDrawerFromPayment}
          headerText="Opções de ações para edição de cartão de crédito"
          extraClass="new-anchor-group"
        >
          <ListItemCard
            title="Alterar cartão de crédito"
            ariaLabel="Alterar cartão de crédito"
            onClick={goToEditPayment}
            iconType="icon-EditLight"
            iconColor={textSecondary}
            extraClassNameContainer="item-from-btn-group"
            extraClassClickableContainer="first-item-border-radius"
          />
          <ListItemCard
            title="Deletar cartão"
            ariaLabel="Deletar cartão"
            onClick={onBtnDeleteCard}
            iconType="icon-TrashLight"
            iconColor={error}
            arrowColor={textSecondary}
            extraClassNameContainer="item-from-btn-group"
            extraClassClickableContainer="last-item-border-radius"
            isLastItem
          />
        </ButtonOptions>
      ) : (
        <ListItemCard
          extraClassNameContainer="profile-card payment list-item-card-no-btn"
          extraClassClickableContainer="list-item-container-no-btn"
          onClick={goToEditPayment}
          picture={(
            <span className="profile-icon">
              <AddCardImg />
            </span>
          )}
          iconColor={primary}
          caption={
        currentUser?.plan.cardBrand
          ? `Cartão ${currentUser.plan.cardBrand} final ${currentUser.plan.cardLastDigits}`
          : ''
      }
          title="Pagamentos"
          ariaLabel="edit-payment"
          alertMessage=""
          isLastItem
          isLoading={isFetchingProfile}
          isItemFromList={false}
          key="edit-payment"
        />
      )}
    </>
  )
}

const mapStateToProps = ({
  authentication,
  payment
}: AppState) => ({
  currentUser: authentication.currentUser,
  isSuccessDeletedCardPayment: payment.isSuccessDeleted,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onDeleteCardInfo: () => {
    dispatch(onDeleteCardInfoAction())
  },
  onUpdateCurrentUser: () => {
    dispatch(updateCurrentUserInfosAction())
  },
  onGoBackToProfileAfterUpdateCardPaymentInfo: () => {
    dispatch(onGoBackToProfileAfterUpdateCardPaymentInfoAction())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentCardProfile)
