/* eslint-disable no-undef */
exports.infoColor = '#505050'
exports.greyColor = '#818181'
exports.lightGreyColor = '#C7C7C7'
exports.mediumGreyColor = '#585858'
exports.darkGreyColor = '#303030'
exports.lightGreenColor = '#ABF5FF'
exports.darkGreenColor = '#1C8020'
exports.mediumPurpleColor = '#9A4178'
exports.lightPurpleColor = '#B14582'
exports.beigeColor = '#FFFAFA'
exports.lightBeigeColor = '#FFFCFC'
exports.mediumBeigeColor = '#FFF7ED'
exports.darkBeigeColor = '#FFE4C1'
exports.bgBarError = '#F8EBED'
exports.bgBarSuccess = '#E8F2E9'
exports.bgBox = '#FFFFFF'
exports.bgBoxSecondary = '#F0F1FB'
exports.bgCanvas = '#F9FAFF'
exports.bgBar = '#F5F8FC'
exports.bgButton = '#F0F1FB'
exports.bgDisable = '#DEDEDE'
exports.textPrimary = 'rgba(0, 0, 0, 0.87)'
exports.textSecondary = 'rgba(0, 0, 0, 0.6)'
exports.textDisable = 'rgba(0, 0, 0, 0.42)'
exports.primary = '#9A4178'
exports.primaryLight = '#B14582'
exports.primaryDark = '#723965'
exports.primaryGradient = 'linear-gradient(180deg, #B14582 0%, #9A4178 100%)'
exports.success = '#1C8020'
exports.error = '#BB3949'
exports.warning = '#FF7951'
exports.overlayButton = 'rgba(88, 44, 199, 0.1)'
exports.overlay = 'rgba(0, 0, 0, 0.05)'
exports.overlayLight = 'rgba(0, 0, 0, 0.04)'
exports.overlayDark = 'rgba(255, 255, 255, 0.24)'
exports.overlayDarkest = 'rgba(0, 0, 0, 0.8)'
exports.divider = '#EEEEEE'
exports.overlayBrandHover = 'rgba(154, 65, 120, 0.05)'
exports.overlayBrandFocus = 'rgba(154, 65, 120, 0.11)'
exports.overlayBrandPressed = 'rgba(154, 65, 120, 0.08)'
exports.overlayLightHover = 'rgba(0, 0, 0, 0.05)'
exports.overlayLightFocus = 'rgba(0, 0, 0, 0.11)'
exports.overlayLightPressed = 'rgba(0, 0, 0, 0.08)'
exports.overlayPressed = 'rgba(154, 65, 120, 0.08)'
exports.overlayHover = 'rgba(154, 65, 120, 0.05)'
exports.overlayFocus = 'rgba(154, 65, 120, 0.11)'
exports.overlayFocusLight = 'rgba(0, 0, 0, 0.11)'
exports.overlayPressedLight = 'rgba(0, 0, 0, 0.08)'
exports.purpleSecondary = '#8A46B7'
exports.primaryColor = '#9A4178'
exports.secondary = '#B14582'
exports.lightPinkColor = '#FFC4CD'
exports.mediumOrangeColor = '#FAA084'
exports.secondaryColor = '#B14582'
exports.darkOrangeColor = '#FF584A'
exports.mediumPinkColor = '#FF7C90'
exports.darkPinkColor = '#ED1D53'
exports.mediumDarkGreyColor = '#424347'

