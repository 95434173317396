/* eslint-disable react/button-has-type */
import React, { HTMLAttributes } from 'react'
import { Loader } from '../Loader/Loader'

export interface Props extends HTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  id?: string;
  borderRadius?: string;
  boxShadow?: string;
  type?: 'button' | 'submit' | 'reset';
  isSubmitting?: boolean;
}

export function Button({
  onClick,
  children,
  className,
  disabled,
  id,
  width,
  height,
  borderRadius,
  boxShadow,
  type,
  isSubmitting = false,
  ...props
}: Props) {
  return isSubmitting ? (
    <div className="flex justify-center">
      <div className="flex items-center">
        <Loader />
      </div>
    </div>
  ) : (
    <button
      id={id}
      type={type || 'button'}
      onClick={onClick}
      className={`focus:outline-none text-textSmall ${className}`}
      style={{
        opacity: `${disabled ? '0.7' : ''}`,
        width,
        height,
        borderRadius,
        boxShadow
      }}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}
