import React, { useState, useEffect } from 'react'
import { FormikProps } from 'formik'
import {
  RegularButton, FontIcon, MaskedFormInput, FormInput
} from 'theia-web-ds'
import { AddressByCep, LocalAddressType } from '../../../../utils/LocalAddress'
import { primary } from '../../../../color'
import './FormAddressInput.scss'
import '../../Input.scss'

export function renderFormAddressInput(
  {
    setFieldValue,
    setFieldTouched,
    values,
    handleBlur,
    handleChange
  }: FormikProps<LocalAddressType>,
  getAddressInfo: (cep: string) => void,
  isScreenLoading: boolean,
  isFetchindAddressData: boolean,
  addressNameField: string,
  addressFetchedFromCep?: AddressByCep
) {
  const [needChangeAddressInfos, setNeedChangeAddressInfos] = useState(false)
  function handleDontKnowCep() {
    window.open('https://buscacepinter.correios.com.br/')
  }

  function onSearchCEP(e: any) {
    if (`${e.target.value}`.indexOf('_') === -1) {
      getAddressInfo(e.target.value)
      setNeedChangeAddressInfos(true)
      setFieldValue('postalCode', e.target.value)
      setFieldTouched('postalCode')
    }
  }

  useEffect(() => {
    if (needChangeAddressInfos && addressFetchedFromCep) {
      setFieldValue('city', addressFetchedFromCep.localidade)
      setFieldTouched('city')
      setFieldValue('stateAbbreviation', addressFetchedFromCep.uf)
      setFieldTouched('stateAbbreviation')
      setFieldValue('name', addressFetchedFromCep.logradouro)
      setFieldTouched('name')
      setFieldValue('complement', addressFetchedFromCep.complemento)
      setFieldTouched('complement')
      setNeedChangeAddressInfos(false)
    }
  }, [addressFetchedFromCep, needChangeAddressInfos])

  return (
    <div className="form-cep-input-container input-group">
      <div className="cep-container">
        <div className="cep-input-container">
          <MaskedFormInput
            label="CEP"
            id="postalCode"
            name="postalCode"
            type="text"
            onChange={onSearchCEP}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            placeholder="00000-000"
            isLoading={isScreenLoading}
          />
          {isFetchindAddressData && (
            <FontIcon
              animation="spin 2s linear infinite"
              iconType="icon-LoadingLight input-icon"
              fontSize="20px"
              color={primary}
            />
          )}
        </div>
        {!isScreenLoading && (
          <RegularButton
            onClick={handleDontKnowCep}
            label="Não sei o meu CEP"
            variant="text"
            btnSize="small"
            width="135px"
          />
        )}
      </div>
      {!isScreenLoading && (
        <>
          <div className="city-state-container">
            <div className="city-container">
              <FormInput
                type="text"
                label="Cidade"
                value={values?.city}
                id="city"
                onBlur={handleBlur}
                name="city"
                placeholder="Ex.: São Paulo"
              />
            </div>
            <div className="state-container">
              <FormInput
                value={values?.stateAbbreviation}
                onChange={handleChange}
                id="stateAbbreviation"
                onBlur={handleBlur}
                type="text"
                label="Estado"
                name="stateAbbreviation"
                placeholder="Ex.: SP"
              />
            </div>
          </div>
          <div className="name-container">
            <FormInput
              type="text"
              value={values?.name}
              onChange={handleChange}
              id="name"
              onBlur={handleBlur}
              label="Rua/Avenida"
              name="name"
              placeholder="Ex.: Av. Paulista"
            />
          </div>
          <div className="number-complement-container">
            <div className="number-container">
              <FormInput
                value={values?.number}
                onChange={handleChange}
                id="number"
                onBlur={handleBlur}
                type="text"
                label="Número"
                name="number"
              />
            </div>
            <div className="complement-container">
              <FormInput
                value={values?.complement}
                onChange={handleChange}
                id="complement"
                onBlur={handleBlur}
                type="text"
                label="Complemento"
                name="complement"
                placeholder="Andar/Apartamento"
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
