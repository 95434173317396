import {
  ChildrenSummary,
  BabyData,
  PediatricFlowService,
} from '../../domain/PediatricFlow'
import ApiClient from './ApiClient'

export default class ApiPediatricFlowService implements PediatricFlowService {
  constructor(private apiClient: ApiClient) { }

  async getChildrenData(): Promise<BabyData[]> {
    const response = await this.apiClient.getChildrenData()
    return response
  }

  async getChildrenSummary(): Promise<ChildrenSummary> {
    const response = await this.apiClient.getChildrenSummary()
    return response
  }

  updateChildren(babiesData: BabyData[]): Promise<void> {
    return this.apiClient.updateChildren(babiesData)
  }
}
