import React, { useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { ptBR } from 'date-fns/locale/pt-BR'
import { FontIcon } from '../FontIcon/FontIcon'
import { primary, textSecondary } from '../../colors'
import 'react-datepicker/dist/react-datepicker.css'
import './ScheduleDatePicker.scss'

registerLocale('ptbr', ptBR)

export interface Props {
  id: string;
  value?: Date;
  name: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  maxDate?: Date;
  width?: string;
  minDate?: Date;
  onChange: (date: Date) => void;
  onBlur?: () => void;
}

export function ScheduleDatePicker({
  id,
  value,
  name,
  width,
  placeholder,
  required,
  disabled,
  maxDate,
  minDate,
  onChange,
  onBlur,
}: Props) {
  const [isInputFocused, setIsInputFocused] = useState(false)

  const handleInputFocus = () => {
    setIsInputFocused(true)
  }

  const handleInputBlur = () => {
    setIsInputFocused(false)
    if (onBlur) onBlur()
  }

  return (
    <div className="schedule-datepicker-group" style={{ width }}>
      <div className="schedule-datepicker-wrapper">
        <DatePicker
          required={required}
          id={id}
          selected={value}
          data-testid="date"
          dateFormat="dd/MM/yy"
          className="input schedule-datepicker-input"
          name={name}
          onChange={onChange}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          autoComplete="off"
          disabledKeyboardNavigation
          placeholderText={placeholder}
          locale="ptbr"
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
        />
        <FontIcon
          iconType="icon-ArrowDown2Light"
          fontSize="14px"
          extraClass="datepicker-arrow"
          color={value || isInputFocused ? primary : textSecondary}
        />
      </div>
    </div>
  )
}
