import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Lottie from 'react-lottie'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RegularButton } from 'theia-web-ds'
import positiveFeedback from '../../animations/positive_feedback.json'
import { success } from '../../color'
import './PositiveHealthInsurance.scss'
import IconWhatsapp from '../../../assets/icon-whatsapp.png'
import { AppState } from '../../apps/main/store'
import { closeModalAction } from '../../state/onboarding/actions'
import { AppDispatch } from '../../state/utils'
import { HealthPlanFaqAndBenefits } from '../profile/healthplan/HealthplanFaqAndBenefits'
import { eventPage, eventTrack, identifyUser } from '../../../eventGenerate'
import { User } from '../../domain/User'
import { AGENDAR, INICIO } from '../../routes/RoutesConstants'

interface Props {
  benefits: any;
  healthInsurancePlan: any;
  hasHealthInsurancePlan: any;
  currentUser?: User;
  benefitsTitle?: string | null;
  benefitsSubtitle?: string | null;
  closeModal: () => void;
}

function PositiveHealthInsurance({
  benefits,
  healthInsurancePlan,
  hasHealthInsurancePlan,
  currentUser,
  benefitsTitle,
  benefitsSubtitle,
  closeModal,
}: Props) {
  const lottiePositiveFeedback = {
    loop: false,
    autoplay: true,
    animationData: positiveFeedback,
  }

  const history = useHistory()

  useEffect(() => {
    eventPage('Incluso no Plano', 'Incluso no Plano')
  }, [])

  function goToPage(link: string) {
    eventTrack('Clicou Falar com a gente')
    window.open(link, '_blank')
  }

  function goToLocationUsingHistoryPush(
    link: string,
    flutterKey?: string,
  ) {
    if (flutterKey === 'LIST_ITEM_WITH_CATEGORY') {
      eventTrack('Clicou Agendar Agora')
    } else {
      eventTrack('Clicou Agendar Depois')
    }
    history.push(link)
    closeModal()
  }

  const infosToRudder = {
    id: currentUser?.id,
    email: currentUser?.email,
    healthInsurance: healthInsurancePlan?.company,
    hasHealthInsurancePlan,
    moment: currentUser?.pregnancyMoment,
    cityName: currentUser?.address.city
  }
  identifyUser(infosToRudder)

  return (
    <div className="flex flex-col items-center health-plan-check mt-4">
      <div className="animation-up">
        <Lottie
          options={lottiePositiveFeedback}
          isClickToPauseDisabled
          ariaRole=""
          height={210}
          width={210}
        />
      </div>
      <div className="show-content">
        <div className="mx-4">
          <p className="text-textMedium text-center font-normal justify-center">
            {benefitsTitle || 'Legal! A gente aceita seu plano'}
          </p>
          <p className="text-fontDefault text-textSecondary text-center mt-4">
            {benefitsSubtitle || 'Você pode agendar consultas com as nossas ginecologistas-obstetras sem custo adicional.'}
          </p>
          <RegularButton
            label="Agendar agora"
            extraClass="mt-6 mx-auto button-healthplan-feedback"
            onClick={() => goToLocationUsingHistoryPush(
              AGENDAR,
              'LIST_ITEM_WITH_CATEGORY'
            )}
          />
        </div>
        <div className="mt-10 w-full">
          <p className="text-fontDefault font-medium mb-4 text-left mx-4">
            Está incluso no seu plano
          </p>
          <div className="accordion-wrapper">
            <HealthPlanFaqAndBenefits
              benefits={benefits}
              iconColor={success}
              iconType="icon-TickRoundLight"
              closeModal={closeModal}
              screen="convenio-feedback"
            />
            <div className="mx-4">
              <img src={IconWhatsapp} alt="Whatsapp" className="mb-4 mt-6 mx-auto rounded-full" />
              <p className="text-textMedium text-center font-normal justify-center mt-4 mb-2">
                Ainda em dúvida?
              </p>
              <p className="text-fontDefault text-textSecondary text-center">
                Nosso time está pronto para te atender <span role="img" aria-label="carinha mandando um abraço">🤗</span>
              </p>
              <RegularButton
                onClick={() => goToPage('https://atendimento.theia.com.br/whatsapp')}
                variant="subtle"
                label="Falar com a gente"
                extraClass="my-6 button-healthplan-feedback mx-auto"
              />
              <RegularButton
                onClick={() => goToLocationUsingHistoryPush(INICIO, 'GO_TO_HOME')}
                variant="text"
                label="Agendar depois"
                extraClass="mb-6 button-healthplan-feedback mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  onboarding, authentication
}: AppState) => ({
  benefits: authentication.currentUser?.healthInsurancePlanData.benefits,
  healthInsurancePlan:
    authentication.currentUser?.healthInsurancePlanData.healthInsurancePlan
    || onboarding.healthInsurancePlanData.healthInsurancePlan,
  hasHealthInsurancePlan:
    authentication.currentUser?.healthInsurancePlanData.hasHealthInsurancePlan
    || onboarding.healthInsurancePlanData.hasHealthInsurancePlan,
  currentUser: authentication.currentUser,
  benefitsTitle: onboarding.healthInsurancePlanData.benefitsTitle,
  benefitsSubtitle: onboarding.healthInsurancePlanData.benefitsSubtitle,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  closeModal: closeModalAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PositiveHealthInsurance)
