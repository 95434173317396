import React, { useState } from 'react'
import { ContentCard, IconButton } from 'theia-web-ds'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import EmailConfirmIllustra from '../../../../assets/email-confirm.png'
import { textSecondary } from '../../../color';
import { AppDispatch } from '../../../state/utils'
import { promptValidateEmailModalAction } from '../../../state/profile/actions';
import { eventTrack } from '../../../../eventGenerate';
import { EMAIL_VALIDATION } from '../../../utils/EventCategories';

interface Props {
  promptValidateEmailModal: (isFromProfileEdit: boolean) => void;
}

function EmailValidationCard({
  promptValidateEmailModal
}: Props) {
  const [visible, setVisible] = useState(true)

  function handleClickClose() {
    eventTrack('fechou o card', {
      category: EMAIL_VALIDATION,
    })
    setVisible(false)
  }

  function handleClickConfirmEmail() {
    eventTrack('clicou card na home', {
      category: EMAIL_VALIDATION,
    })
    promptValidateEmailModal(false)
  }

  return visible ? (
    <div className="p-4">
      <ContentCard
        firstButtonProps={{
          label: 'Confirmar conta',
          onClick: handleClickConfirmEmail
        }}
        type="outlined"
        contentSubtitle="Para manter seu cadastro atualizado, faça a verificação do seu e-mail e confirme sua conta"
        ilustraBefore={{ src: EmailConfirmIllustra }}
        headerTitle="Verificação de e-mail"
        buttonAfter={(
          <IconButton
            variant="text"
            onClick={handleClickClose}
            iconType="icon-CloseLight"
            iconColor={textSecondary}
            iconSize="24px"
          />
        )}
      />
    </div>
  ) : null
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  promptValidateEmailModal: promptValidateEmailModalAction
}, dispatch)

export default connect(
  null,
  mapDispatchToProps
)(EmailValidationCard)
