import { useState, useEffect } from 'react'

export function useWindowActive() {
  const [isWindowActive, setWindowIsActive] = useState(!document.hidden && document.hasFocus())

  function onBlur() {
    setWindowIsActive(false)
  }

  function onFocus() {
    setWindowIsActive(true)
  }

  function onVisibilityChange() {
    setWindowIsActive(!document.hidden)
  }

  useEffect(() => {
    window.addEventListener('blur', onBlur)
    document.addEventListener('blur', onBlur)
    window.addEventListener('focus', onFocus)
    document.addEventListener('focus', onFocus)
    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      window.removeEventListener('blur', onBlur)
      document.removeEventListener('blur', onBlur)
      window.removeEventListener('focus', onFocus)
      document.removeEventListener('focus', onFocus)
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])

  return isWindowActive
}
