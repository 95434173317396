import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory } from 'react-router-dom'

import { ContentCard } from 'theia-web-ds'
import AppMainContainerOff from '../AppMainContainerOff'
import './SalesOnboarding.scss'
import {
  onAddLiveInSpAction,
  onAddMomentAction,
} from '../../state/sales-onboarding/actions'
import { ConsultantMoment } from '../../domain/Profile'
import { eventPage, eventTrack } from '../../../eventGenerate'
import PregnantImg from '../../../assets/ultrassom.png'
import FertilizationImg from '../../../assets/fertilization.png'
import RoutineImg from '../../../assets/calendar-ok.png'
import OtherServicesImg from '../../../assets/chatballoon.png'
import PediatricImg from '../../../assets/pediatric-journey.png'
import { IDADE_GESTACIONAL, LOGIN_DE_ACESSO, ROTINA_FEEDBACK } from '../../routes/RoutesConstants'
import { SHOW_GINECOLOGIC_ROUTINE } from '../../state/remoteConfig'
import { ONBOARDING } from '../../utils/EventCategories'

interface Props {
  onAddMoment: (moment: ConsultantMoment) => void;
  onAddLiveInSp: (liveInSp: string) => void;
}

function SalesOnboardingMoment({
  onAddMoment,
  onAddLiveInSp
}: Props) {
  const history = useHistory()

  useEffect(() => {
    eventPage('onboarding', 'Momento')
  }, [])

  function handleMomentClickEvent(moment: ConsultantMoment) {
    eventTrack('Escolheu momento', {
      momento: moment,
      categoria: ONBOARDING
    })
  }

  function goFoward(moment: ConsultantMoment) {
    onAddMoment(moment)
    localStorage.setItem('salesOnboardingMoment', moment)
    localStorage.setItem('salesOnboardingLiveInSp', 'true')
    onAddLiveInSp('true')
    handleMomentClickEvent(moment)
    if (moment === ConsultantMoment.PREGNANT) {
      history.push(IDADE_GESTACIONAL)
    } else if (moment === ConsultantMoment.ROUTINE) {
      history.push(ROTINA_FEEDBACK)
    } else {
      history.push(LOGIN_DE_ACESSO)
    }
  }

  return (
    <AppMainContainerOff>
      <div className="sales-onboarding-container">
        <h1 className="text-primary font-medium text-headlineMedium pt-8">
          Para começar...
        </h1>
        <p className="text-textMedium text-darkGreyColor mt-4 mb-6 text-content">
          Pra entendermos qual a melhor forma de te acolher,
          nos conte qual desses momentos combina mais com você
        </p>

        <ContentCard
          type="elevated"
          headerTitle="Acompanhar minha gestação"
          ilustraBefore={{
            src: PregnantImg
          }}
          onClick={() => goFoward(ConsultantMoment.PREGNANT)}
          extraClass="mb-4"
        />

        <ContentCard
          type="elevated"
          headerTitle="Quero engravidar"
          ilustraBefore={{
            src: FertilizationImg
          }}
          onClick={() => goFoward(ConsultantMoment.TRYING)}
          extraClass="mb-4"
        />

        {SHOW_GINECOLOGIC_ROUTINE && (
          <ContentCard
            type="elevated"
            headerTitle="Rotina ginecológica"
            ilustraBefore={{
              src: RoutineImg
            }}
            onClick={() => goFoward(ConsultantMoment.ROUTINE)}
            extraClass="mb-4"
          />
        )}

        <ContentCard
          type="elevated"
          headerTitle={SHOW_GINECOLOGIC_ROUTINE ? 'Pediatria' : 'Outros serviços'}
          ilustraBefore={{
            src: SHOW_GINECOLOGIC_ROUTINE ? PediatricImg : OtherServicesImg
          }}
          onClick={() => goFoward(ConsultantMoment.OTHER_MOMENT)}
          extraClass="mb-4"
        />

      </div>
    </AppMainContainerOff>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  onAddMoment: onAddMomentAction,
  onAddLiveInSp: onAddLiveInSpAction
}, dispatch)

export default React.memo(
  connect(
    null,
    mapDispatchToProps
  )(SalesOnboardingMoment)
)
