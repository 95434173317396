import * as Sentry from '@sentry/browser'
import { AsyncAction } from '../utils'
import { eventTrack } from '../../../eventGenerate'
import { TheiaError } from '../../domain/errors/TheiaError'
import { PromptRescheduleInfosType, RescheduleService } from '../../domain/Reschedule'

export const PROMPT_RESCHEDULE = 'PROMPT_RESCHEDULE'
export const SHOW_RESCHEDULE_MODAL = 'SHOW_RESCHEDULE_MODAL'
export const RESCHEDULE_BOOKING = 'RESCHEDULE_BOOKING'
export const SUCCESS_RESCHEDULE_BOOKING = 'SUCCESS_RESCHEDULE_BOOKING'
export const FAILURE_RESCHEDULE_BOOKING = 'FAILURE_RESCHEDULE_BOOKING'
export const CLEAR_RESCHEDULE_FLOW = 'CLEAR_RESCHEDULE_FLOW'
export const CLOSE_RESCHEDULE_ERROR_MODAL = 'CLOSE_RESCHEDULE_ERROR_MODAL'

interface RescheduleBookingActionType {
  type: typeof RESCHEDULE_BOOKING;
}

export interface SuccessRescheduleBookingActionType {
  type: typeof SUCCESS_RESCHEDULE_BOOKING;
}

interface FailureReScheduleActionType {
  type: typeof FAILURE_RESCHEDULE_BOOKING;
  error: TheiaError;
}

interface PromptRescheduleActionType {
  type: typeof PROMPT_RESCHEDULE;
  rescheduleInfos: PromptRescheduleInfosType;
}

interface ShowRescheduleModalActionType {
  type: typeof SHOW_RESCHEDULE_MODAL;
}

interface ClearRescheduleFlowActionType {
  type: typeof CLEAR_RESCHEDULE_FLOW;
}

interface CloseRescheduleErrorModalActionType {
  type: typeof CLOSE_RESCHEDULE_ERROR_MODAL;
}

export type RescheduleTypes =
  PromptRescheduleActionType |
  ShowRescheduleModalActionType |
  FailureReScheduleActionType |
  SuccessRescheduleBookingActionType |
  RescheduleBookingActionType |
  ClearRescheduleFlowActionType |
  CloseRescheduleErrorModalActionType

export const promptRescheduleAction = (
  rescheduleInfos: PromptRescheduleInfosType
): PromptRescheduleActionType => ({
  type: PROMPT_RESCHEDULE,
  rescheduleInfos
})

export const setShowRescheduleModalAction = ():
ShowRescheduleModalActionType => ({
  type: SHOW_RESCHEDULE_MODAL
})

export const clearRescheduleFlowAction = ():
ClearRescheduleFlowActionType => ({
  type: CLEAR_RESCHEDULE_FLOW
})

export const closeRescheduleErrorModalAction = ():
CloseRescheduleErrorModalActionType => ({
  type: CLOSE_RESCHEDULE_ERROR_MODAL
})

export function reScheduleBookingAction(
  oldBookingId: string,
  availabilityId: string
): AsyncAction<{}, {
  rescheduleService: RescheduleService;
}> {
  return async (dispatch, _getState, { rescheduleService }) => {
    try {
      dispatch({ type: RESCHEDULE_BOOKING })
      await rescheduleService.reScheduleBooking(
        oldBookingId,
        availabilityId,
      )
      dispatch({ type: SUCCESS_RESCHEDULE_BOOKING })
      eventTrack('Remarcou Consulta')
    } catch (error) {
      if (error instanceof TheiaError) {
        Sentry.captureException(new Error(`Failure rescheduling a booking, ${error.statusCode} - ${error.message} - ${error.friendlyMessageTitle} - ${error.friendlyMessage}`))
        dispatch({
          type: FAILURE_RESCHEDULE_BOOKING,
          error
        })
      }
    }
  }
}
