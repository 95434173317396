import * as Sentry from '@sentry/browser'
import { Post } from '../../domain/Content'
import { AsyncAction } from '../utils'

export const FETCH_ALL_POSTS = 'FETCH_ALL_POSTS'
export const SUCCESS_FETCH_ALL_POSTS = 'SUCCESS_FETCH_ALL_POSTS'
export const FAILURE_FETCH_ALL_POSTS = 'FAILURE_FETCH_ALL_POSTS'
export const FETCH_POST = 'FETCH_POST'
export const SUCCESS_FETCH_POST = 'SUCCESS_FETCH_POST'
export const FAILURE_FETCH_POST = 'FAILURE_FETCH_POST'
export const SELECT_CONTENT_CATEGORY = 'SELECT_CONTENT_CATEGORY'
export const SET_NUMBER_OF_POSTS_TO_RENDER = 'SET_NUMBER_OF_POSTS_TO_RENDER'

const ghostKey = process.env.GHOST_KEY

interface FetchAllPostsActionType {
  type: typeof FETCH_ALL_POSTS;
}

export interface SuccessFetchAllPostsActionType {
  type: typeof SUCCESS_FETCH_ALL_POSTS;
  allPosts: Array<Post>;
}

interface FailureFetchAllPostsActionType {
  type: typeof FAILURE_FETCH_ALL_POSTS;
}

interface FetchPostActionType {
  type: typeof FETCH_POST;
}

export interface SuccessFetchPostActionType {
  type: typeof SUCCESS_FETCH_POST;
  singlePost: Array<Post>;
}

interface FailureFetchPostActionType {
  type: typeof FAILURE_FETCH_POST;
}

export interface SelectContentCategoryActionType {
  type: typeof SELECT_CONTENT_CATEGORY;
  category: string;
}

export interface SetNumberOfPostsToRenderActionType {
  type: typeof SET_NUMBER_OF_POSTS_TO_RENDER;
  numberOfPostsToRender: number;
}

export type ContentTypes = FetchAllPostsActionType |
  SuccessFetchAllPostsActionType |
  FailureFetchAllPostsActionType |
  FetchPostActionType |
  SuccessFetchPostActionType |
  FailureFetchPostActionType |
  SelectContentCategoryActionType |
  SetNumberOfPostsToRenderActionType

export function fetchAllPostsAction():
  AsyncAction<{}, {}> {
  return async (dispatch) => {
    dispatch({ type: FETCH_ALL_POSTS })
    await fetch(`https://theia.ghost.io/ghost/api/v3/content/posts/?key=${ghostKey}&include=tags,authors&limit=all`)
      .then((blob) => blob.json())
      .then((posts) => {
        const allPosts = posts.posts
        dispatch({ type: SUCCESS_FETCH_ALL_POSTS, allPosts })
      })
      .catch((error) => {
        dispatch({ type: FAILURE_FETCH_ALL_POSTS })
        Sentry.captureException(new Error(`Failure to fetch all posts - ${error.message}`))
      })
  }
}

export const selectContentCategoryAction = (
  category: string
): SelectContentCategoryActionType => ({
  type: SELECT_CONTENT_CATEGORY,
  category
})

export function fetchPostAction(postId: any):
AsyncAction<{}, {}> {
  return async (dispatch) => {
    dispatch({ type: FETCH_POST })
    await fetch(`https://theia.ghost.io/ghost/api/v3/content/posts/${postId}/?key=${ghostKey}`)
      .then((blob) => blob.json())
      .then((post) => {
        const singlePost = post.posts
        dispatch({ type: SUCCESS_FETCH_POST, singlePost })
      })
      .catch((error) => {
        dispatch({ type: FAILURE_FETCH_POST })
        Sentry.captureException(new Error(`Failure to fetch a post - ${error.message}`))
      })
  }
}

export const setNumberOfPostsToRenderAction = (
  numberOfPostsToRender: number
): SetNumberOfPostsToRenderActionType => ({
  type: SET_NUMBER_OF_POSTS_TO_RENDER,
  numberOfPostsToRender
})
