import React from 'react'
import './BannerUserDataInfo.scss'
import { Card } from 'theia-web-ds'
import BannerInfoIcon from '../../../assets/banner-info-icon.png'

export default function BannerUserDataInfo({
  extraClassName,
  isLoading
}: {
  extraClassName?: string;
  isLoading?: boolean;
}) {
  return (
    <div className={`banner-with-explanation-container ${extraClassName}`}>
      <Card className="card-container">
        {isLoading ? (
          <div className="loading-image" />
        ) : (
          <img src={BannerInfoIcon} alt="" className="banner-info-icon" height={40} width={40} />
        )}
        {isLoading ? (
          <>
            <div className="loading-full-line" />
            <div className="loading-small-line" />
          </>
        ) : (
          <p className="banner-title">
            Porque manter seus dados atualizados?
          </p>
        )}
        {isLoading ? (
          <>
            <div className="loading-full-line" />
            <div className="loading-full-line" />
            <div className="loading-full-line" />
            <div className="loading-middle-line" />
            <div className="loading-small-line" />
          </>
        ) : (
          <p className="banner-text">
            Enviamos informações aos seus especialistas pra garantir
            &nbsp;uma consulta rápida e um atendimento
            &nbsp;integrado para você. Além de aprimorar o
            &nbsp;conteúdo disponibilizado na plataforma.
          </p>
        )}
      </Card>
    </div>
  )
}
