import * as Sentry from '@sentry/browser'
import { AsyncAction } from '../utils'
import { CurrentTerm, TermsProvider } from '../../domain/Terms'

export const GET_CURRENT_TERMS = 'GET_CURRENT_TERMS'
export const SUCCESS_GET_CURRENT_TERMS = 'SUCCESS_GET_CURRENT_TERMS'
export const FAILURE_GET_CURRENT_TERMS = 'FAILURE_GET_CURRENT_TERMS'

interface GetCurrentTermsActionType {
  type: typeof GET_CURRENT_TERMS;
}

interface SuccessGetCurrentTermsActionType {
  type: typeof SUCCESS_GET_CURRENT_TERMS;
  terms: Array<CurrentTerm>;
}

interface FailureGetCurrentTermsActionType {
  type: typeof FAILURE_GET_CURRENT_TERMS;
  error?: string;
}

export type TermsTypes = GetCurrentTermsActionType
| SuccessGetCurrentTermsActionType
| FailureGetCurrentTermsActionType

export function getCurrentTermsAction():
  AsyncAction<{}, { termsProvider: TermsProvider }> {
  return async (dispatch, _getState, { termsProvider }) => {
    dispatch({ type: GET_CURRENT_TERMS })
    try {
      const terms = await termsProvider.getCurrentTerms()
      dispatch({ type: SUCCESS_GET_CURRENT_TERMS, terms })
    } catch (error) {
      dispatch({ type: FAILURE_GET_CURRENT_TERMS, error: error.message })
      Sentry.captureException(new Error(`Failure get current terms - ${error.message}`))
    }
  }
}
