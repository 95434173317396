import React from 'react'
import './MyBookingsView.scss'
import { Booking } from '../../domain/Profile'
import BookingCard from './BookingCard'

interface Props {
  isFetchingBookings: boolean;
  bookingsLength: number;
  bookingsByMonth: Map<string, Array<Booking>>;
  onCreateCalendarEvent?: () => void;
  onClickShowMoreBooking?: () => void;
  onShowBookingDetails?: () => void;
  emptyComponent: React.ReactNode;
  extraButton?: React.ReactNode;
  showTagStatus?: boolean;
}

export default function BookingListView({
  isFetchingBookings,
  bookingsByMonth,
  bookingsLength,
  onCreateCalendarEvent,
  onClickShowMoreBooking,
  onShowBookingDetails,
  emptyComponent,
  extraButton,
  showTagStatus,
}: Props) {
  const iterable = bookingsByMonth.keys()
  const bookingsBody = []
  for (let index = 0; index < bookingsByMonth.size; index += 1) {
    const result = iterable.next()
    if (!result.done) {
      bookingsBody.push(
        <div className="booking-by-month-container" key={result.value}>
          <p className="bookings-month-divider">{result.value}</p>
          {bookingsByMonth.get(result.value)?.map((booking) => (
            <BookingCard
              booking={booking}
              key={booking.id}
              extraActionRequestCreateCalendarEvent={onCreateCalendarEvent}
              extraActionClickShowMoreBooking={onClickShowMoreBooking}
              onShowBookingDetails={onShowBookingDetails}
              showTagStatus={showTagStatus}
            />
          ))}
        </div>
      )
    }
  }

  return (
    <div className="w-full pb-6 booking-list-view">
      <div className="booking-list-view-inner">
        {isFetchingBookings && bookingsLength === 0 && (
          <BookingCard />
        )}
        {bookingsBody}
        {!isFetchingBookings && bookingsLength === 0 && (
          emptyComponent
        )}
        {extraButton}
      </div>
    </div>
  )
}
