/* eslint-disable react/button-has-type */
import React, { HTMLAttributes } from 'react'

export interface Props extends HTMLAttributes<HTMLButtonElement> {
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>
  ) => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id?: string;
  borderRadius?: string;
  type?: 'button' | 'submit' | 'reset';
  IconStart?: React.ReactNode;
  IconEnd?: React.ReactNode;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  height?: string;
}

export function BaseButton({
  onClick,
  children,
  className,
  disabled,
  id,
  borderRadius,
  type,
  IconStart,
  IconEnd,
  width,
  height,
  maxWidth,
  minWidth,
  ...props
}: Props) {
  function handleOnClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    if (onClick) {
      onClick(e)
    }
  }
  function handleKeyDown(e: React.KeyboardEvent<HTMLButtonElement>) {
    e.stopPropagation()
    if (e.key === 'Enter' && onClick) {
      onClick(e)
    }
  }
  return (
    <button
      id={id}
      type={type || 'button'}
      onClick={handleOnClick}
      onKeyDown={handleKeyDown}
      className={`${className} ${disabled ? 'cursor-default' : ''}`}
      style={{
        height,
        borderRadius,
        width,
        maxWidth,
        minWidth
      }}
      disabled={disabled}
      data-testid="base-button"
      {...props}
    >
      <span className="flex justify-center items-center">
        {IconStart}
      </span>
      {children}
      <span className="flex justify-center items-center">
        {IconEnd}
      </span>
    </button>
  )
}
