import React, { HTMLAttributes, useState } from 'react'
import './Snackbar.scss'
import { FontIcon } from '../FontIcon/FontIcon'
import {
  primary,
  textSecondary,
  success,
  error,
  warning,
  bgBox,
} from '../../colors'
import { IconButton } from '../IconButton/IconButton'
import { RegularButton } from '../RegularButton/RegularButton'

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  label: string;
  onClick?: () => void;
}

export interface Props {
  text: string;
  alignTop?: boolean;
  width?: string;
  variant?: 'default' | 'white' | 'success' | 'error' | 'alert';
  showCloseButton?: boolean;
  iconLeft?: string;
  imgLeftUrl?: string;
  extraOnClose?: () => void;
  title?: string;
  customIconColor?: string;
  buttonOneProps?: ButtonProps;
  buttonTwoProps?: ButtonProps;
}

const variantColors = {
  default: primary,
  white: primary,
  success,
  error,
  alert: warning,
}

export function Snackbar({
  text,
  alignTop,
  width,
  variant = 'default',
  showCloseButton,
  iconLeft,
  imgLeftUrl,
  extraOnClose,
  title,
  customIconColor,
  buttonOneProps,
  buttonTwoProps,
}: Props) {
  const [isClosed, setIsClosed] = useState(false)
  const iconColor = variantColors[variant]
  const showButtons = buttonOneProps || buttonTwoProps

  function onClose() {
    setIsClosed(true)
    if (extraOnClose) {
      extraOnClose()
    }
  }

  return !isClosed ? (
    <div
      className={`snackbar-container ${variant}`}
      style={{ width }}
    >
      <div data-testid="snackbar-container-inner-test" className={`flex w-full p-4 ${alignTop || showButtons ? 'items-start' : 'items-center'}`}>
        {iconLeft && (
          <FontIcon
            iconType={iconLeft}
            fontSize="24px"
            extraClass="mr-4"
            color={customIconColor || iconColor}
          />
        )}
        {imgLeftUrl && (
          <img
            src={imgLeftUrl}
            alt=""
            width="40px"
            height="40px"
            className="mr-4 rounded-2xl"
          />
        )}
        <div className="flex flex-col text-left flex-1">
          {title && (
            <h1 className="text-fontDefault font-medium mb-1">
              {title}
            </h1>
          )}
          <h1 className={!showButtons && !title && !alignTop ? 'text-textPrimary' : 'text-textSecondary'}>
            {text}
          </h1>
        </div>
        {showCloseButton && (
          <IconButton
            iconSize="24px"
            height="24px"
            width="24px"
            data-testid="close-button"
            onClick={onClose}
            extraClass="ml-4"
            iconType="icon-CloseLight"
            iconColor={variant === 'alert' ? bgBox : textSecondary}
            variant={variant === 'alert' ? 'dark' : 'text'}
          />
        )}
      </div>
      {showButtons && (
        <div
          className="snackbar-buttons-container"
          data-testid="snackbar-test-buttons-container"
        >
          {buttonTwoProps && (
            <RegularButton
              variant="text"
              type="button"
              btnSize="small"
              label={buttonTwoProps.label}
              onClick={buttonTwoProps.onClick}
            />
          )}
          {buttonOneProps && (
            <RegularButton
              variant="text"
              btnSize="small"
              label={buttonOneProps.label}
              onClick={buttonOneProps.onClick}
            />
          )}
        </div>
      )}
    </div>
  ) : null
}
