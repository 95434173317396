import { LocalDate } from '../../utils/LocalDate'
import ApiClient from './ApiClient'
import {
  ProfileProvider,
  Booking,
  Profile,
  InfosToUpdateProfile,
  PersonalData,
} from '../../domain/Profile'
import {
  profileFromRemote,
  liveInSpFromRemote
} from '../../state/utils/userRemoteAdapters'
import { LocalAddress } from '../../utils/LocalAddress'

export default class ApiProfileService implements ProfileProvider {
  constructor(private apiClient: ApiClient) { }

  async getAllBookings(): Promise<Array<Booking>> {
    const bookings: Array<Booking> = await this.apiClient.getBookings()
    return bookings.map(this.parseBooking)
  }

  async getBookingHistory(): Promise<Array<Booking>> {
    const bookings: Array<Booking> = await this.apiClient.getBookingHistory()
    return bookings.map(this.parseBooking)
  }

  async getProfile(): Promise<Profile> {
    const profileRemote: any = await this.apiClient.getCurrentUser()
    const consultantWithNoAddress = { ...profileRemote.consultant }
    delete consultantWithNoAddress.address
    const profile = {
      ...profileRemote.profile,
      ...consultantWithNoAddress,
      liveInSp: liveInSpFromRemote(profileRemote.consultant.liveInSp),
    }
    const profileForm = profileFromRemote(profile)
    return {
      paymentStatus: profileRemote.consultant?.paymentStatus,
      profile: profileForm,
      baby: profileRemote.baby,
    }
  }

  private parseBooking = (booking: any): Booking => ({
    ...booking,
    startDate: new LocalDate(booking.startDate),
    endDate: new LocalDate(booking.endDate),
    attendanceAddress: booking.attendanceAddress && new LocalAddress(booking.attendanceAddress),
  })

  async updateProfile(payload: InfosToUpdateProfile): Promise<void> {
    const response = await this.apiClient.updateProfile(payload)
    return response
  }

  async updatePersonalData(payload: PersonalData): Promise<void> {
    const response = await this.apiClient.updatePersonalData(payload)
    return response
  }

  async deleteDocuments(consultantId: string): Promise<void> {
    const response = await this.apiClient.deleteDocuments(consultantId)
    return response
  }

  async validateInformation(
    verificationCode: string,
    email?: string,
    phone?: string
  ): Promise<void> {
    const response = await this.apiClient.validateInformation(verificationCode, email, phone)
    return response
  }
}
