import React, { useState } from 'react'
import OnlineIndicator from './OnlineIndicator'
import UserIcon from '../../../assets/user.svg'

interface ProfilePictureProps {
  showOnlineIndicator: boolean;
  pictureUrl?: string;
  pictureSize?: number;
  picture?: File;
  className?: string;
  placeholderType?: 'user' | 'empty';
}

export default function ProfilePicture({
  showOnlineIndicator,
  pictureUrl,
  pictureSize = 24,
  className,
  picture,
  placeholderType = 'user'
}: ProfilePictureProps) {
  const [error, setError] = useState(false)
  const realPictureUrl = picture ? URL.createObjectURL(picture) : pictureUrl

  const placeholder = (
    placeholderType === 'user'
      ? (
        <UserIcon
          data-testid="placeholder-testid"
          width={pictureSize}
          height={pictureSize}
          className="rounded-full specialist-picture--default mr-2"
        />
      ) : (
        <div
          style={{ width: `${pictureSize}px`, height: `${pictureSize}px` }}
          className="rounded-full bg-bgDisable"
        />
      )
  )

  return (
    <div>
      {showOnlineIndicator && (
        <OnlineIndicator style={{ position: 'absolute', right: '6px', top: '16px' }} />
      )}
      {(error || (!pictureUrl && !picture)) ? (
        <>
          {placeholder}
        </>
      ) : (
        <img
          width={pictureSize}
          height={pictureSize}
          alt="profile"
          data-testid="error-state"
          src={realPictureUrl}
          onError={() => setError(true)}
          className={`specialist-picture ${className} rounded-full`}
          style={{
            backgroundImage: `url(${realPictureUrl})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            width: pictureSize,
            height: pictureSize,
          }}
        />
      )}
    </div>
  )
}
