import React from 'react'
import { connect } from 'react-redux'
import { Message } from '../../domain/ChatVideo'
import TextMessage from './TextMessage'
import './MessageList.scss'
import { AppState } from '../../apps/main/store'

interface MessageListProps {
  newMessages?: Array<Message>;
  historicalMessages?: Array<Message>;
}

function MessageList({
  newMessages,
  historicalMessages
}: MessageListProps) {
  function renderMessages(messages: Array<Message>) {
    return messages.map((message, index) => {
      const previousMessage = messages[index - 1]
      const previousIsFromSameAuthor = previousMessage && previousMessage.author !== message.author
      const firstOfSeries = !previousMessage || previousIsFromSameAuthor
      return (
        <TextMessage
          key={index}
          message={message}
          firstOfSeries={firstOfSeries}
        />
      )
    })
  }

  return (
    <div className="flex-1 rounded">
      <div className="pr-2 pb-2">
        {renderMessages(historicalMessages || [])}
        {renderMessages(newMessages || [])}
      </div>
    </div>
  )
}

const mapStateToProps = ({ chatVideo }: AppState) => {
  const historicalMessages = chatVideo.messages.slice(0, chatVideo.historicalMessagesQuantity)
  const newMessages = chatVideo?.messages.slice(chatVideo.historicalMessagesQuantity)
  return {
    newMessages,
    historicalMessages
  }
}

export default connect(
  mapStateToProps,
  null
)(MessageList)
