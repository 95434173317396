import { FetchStatusType } from '../../domain/Status'
import { CurrentTerm, TermTypeEnum } from '../../domain/Terms'
import {
  FAILURE_GET_CURRENT_TERMS,
  GET_CURRENT_TERMS,
  SUCCESS_GET_CURRENT_TERMS,
  TermsTypes
} from './actions'

export interface TermsState {
  termsFetchStatus: FetchStatusType;
  termsPolicy: Array<CurrentTerm>;
  privacyPolicy: Array<CurrentTerm>;
  healthDataPolicy: Array<CurrentTerm>;
  termIds: Array<string>;
}

const initialTermsState: TermsState = {
  termsFetchStatus: {
    isFetch: false,
    isFetching: false,
    errorFetch: false,
    errorMsg: undefined
  },
  termsPolicy: [],
  privacyPolicy: [],
  healthDataPolicy: [],
  termIds: []
}

export function terms(
  state = initialTermsState,
  action: TermsTypes
): TermsState {
  switch (action.type) {
    case GET_CURRENT_TERMS: {
      return {
        ...state,
        termsFetchStatus: {
          isFetching: true,
          isFetch: false,
          errorFetch: false
        },
        termsPolicy: [],
        privacyPolicy: [],
        healthDataPolicy: [],
        termIds: []
      }
    }
    case SUCCESS_GET_CURRENT_TERMS: {
      const termsPolicy = action.terms
        .filter((term: CurrentTerm) => term.type === TermTypeEnum.TERMS)
      const privacyPolicy = action.terms
        .filter((term: CurrentTerm) => term.type === TermTypeEnum.POLICY)
      const healthDataPolicy = action
        .terms.filter((term: CurrentTerm) => term.type === TermTypeEnum.DATA)
      const termIds = action.terms.map((term: CurrentTerm) => term.id)

      return {
        ...state,
        termsFetchStatus: {
          isFetching: false,
          isFetch: true,
          errorFetch: false
        },
        termsPolicy,
        privacyPolicy,
        healthDataPolicy,
        termIds
      }
    }
    case FAILURE_GET_CURRENT_TERMS: {
      return {
        ...state,
        termsFetchStatus: {
          isFetching: false,
          isFetch: false,
          errorFetch: true,
          errorMsg: action.error
        },
        termsPolicy: [],
        privacyPolicy: [],
        healthDataPolicy: [],
        termIds: []
      }
    }
    default: return state
  }
}
