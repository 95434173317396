import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ContentCard, Loader, Snackbar } from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import {
  AttendanceAvailabilityType,
  CategoryToSchedule,
} from '../../domain/ScheduleFlow'
import { selectAttendanceTypeAction, setLoadingStepAction } from '../../state/scheduleFlow/actions'
import {
  isVirtual, isPresential
} from '../../utils/helpers'
import { eventTrack } from '../../../eventGenerate'
import { NEW_SCHEDULE_FLOW } from '../../utils/EventCategories'

interface Props {
  selectedCategory?: CategoryToSchedule;
  loadingStep: boolean;
  selectedAttendanceType?: AttendanceAvailabilityType;
  selectAttendanceType: (attendanceType: AttendanceAvailabilityType) => void;
  onChooseAttendanceType: () => void;
  setLoadingSteps: (isLoading: boolean) => void;
}

function ChooseAttendanceType({
  selectedCategory,
  loadingStep,
  selectedAttendanceType,
  selectAttendanceType,
  onChooseAttendanceType,
  setLoadingSteps,
}: Props) {
  const canChooseOnline = isVirtual(selectedCategory?.attendanceAvailability)
  const canChoosePresential = isPresential(selectedCategory?.attendanceAvailability)
  const haveBlockedBundle = selectedCategory?.blockedBundle
  const haveBlockedMessage = selectedCategory?.blockReason || selectedCategory?.blockReasonTitle
  const [showBlockReason, setShowBlockReason] = useState<boolean>(
    !!(haveBlockedBundle && haveBlockedMessage)
  )

  function handleChooseAttendanceType(attendanceType: AttendanceAvailabilityType) {
    let attendanceTypeEventValue: string
    if (attendanceType === 'inPerson') {
      attendanceTypeEventValue = 'presencial'
    } else if (attendanceType === 'virtual') {
      attendanceTypeEventValue = 'online'
    } else {
      attendanceTypeEventValue = attendanceType
    }
    selectAttendanceType(attendanceType)
    onChooseAttendanceType()
    eventTrack('escolheu modalidade', {
      categoria: NEW_SCHEDULE_FLOW,
      modalidade: attendanceTypeEventValue
    })
  }

  useEffect(() => {
    if (selectedAttendanceType) {
      onChooseAttendanceType()
    } else {
      setLoadingSteps(false)
      eventTrack('visualizou escolha modalidade', {
        categoria: NEW_SCHEDULE_FLOW
      })
    }
  }, [])

  if (loadingStep) {
    return (
      <div className="schedule-flow-loader choose-attendance-type-view">
        <Loader />
      </div>
    )
  }

  return (
    <div>
      <h3 className="text-titleLarge font-normal text-textPrimary mb-6">Selecione o tipo de consulta</h3>
      <ContentCard
        type="elevated"
        headerTitle="Presencial"
        iconBefore={{
          icon: 'icon-2UsersLight'
        }}
        disabled={!canChoosePresential}
        onClick={() => handleChooseAttendanceType(AttendanceAvailabilityType.PRESENTIAL)}
        onClickDisabled={() => setShowBlockReason(true)}
      />
      <ContentCard
        type="elevated"
        headerTitle="Online"
        iconBefore={{
          icon: 'icon-VideoLight'
        }}
        disabled={!canChooseOnline}
        extraClass="mt-4 mb-8"
        onClick={() => handleChooseAttendanceType(AttendanceAvailabilityType.VIRTUAL)}
        onClickDisabled={() => setShowBlockReason(true)}
      />

      {showBlockReason && (
        <Snackbar
          showCloseButton
          title={selectedCategory?.blockReasonTitle}
          text={selectedCategory?.blockReason || ''}
          alignTop={haveBlockedBundle}
          iconLeft={!haveBlockedBundle ? 'icon-DangerTriangleLight' : ''}
          extraOnClose={() => setShowBlockReason(false)}
        />
      )}
    </div>
  )
}

const mapStateToProps = ({
  scheduleFlow,
}: AppState) => ({
  selectedCategory: scheduleFlow.selectedCategory,
  loadingStep: scheduleFlow.loadingStep,
  selectedAttendanceType: scheduleFlow.selectedAttendanceType
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  selectAttendanceType: (attendanceType: AttendanceAvailabilityType) => {
    dispatch(selectAttendanceTypeAction(attendanceType))
  },
  setLoadingSteps: (isLoading: boolean) => {
    dispatch(setLoadingStepAction(isLoading))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseAttendanceType)
