import React from 'react'

interface Props {
  onClick: () => void;
  children: React.ReactNode;
  extraClass?: string;
}

export default function CancelButton({ onClick, children, extraClass }: Props) {
  return (
    <button
      type="button"
      onClick={() => onClick()}
      className={`${extraClass} cancel-button text-primary py-2 px-4 rounded-full focus:outline-none`}
      style={{ fontSize: '14px' }}
    >
      {children}
    </button>
  )
}
