import React from 'react'
import { FontIcon } from '../FontIcon/FontIcon'
import './ToolTip.scss'

export interface Props {
  label?: string;
  backgroundColor?: string;
  borderRadius?: string;
  textColor?: string;
  iconType?: string;
  width?: string;
  fontSize?: string;
}

export function ToolTip({
  label,
  backgroundColor,
  borderRadius,
  textColor,
  iconType,
  width,
  fontSize
}: Props) {
  return (
    <div
      className="tag-done"
      style={{ backgroundColor, borderRadius, width }}
    >
      {iconType ? (
        <FontIcon
          iconType={iconType}
          color={textColor}
          fontSize={fontSize}
        />
      ) : (
        <p
          className="tag-name"
          style={{ color: textColor, fontSize }}
        >
          {label}
        </p>
      )}
    </div>
  )
}
