import { TheiaError } from '../../domain/errors/TheiaError'

export const PROMPT_DEFAULT_ERROR = 'PROMPT_DEFAULT_ERROR'
export const SUPPRESS_DEFAULT_ERROR = 'SUPPRESS_DEFAULT_ERROR'

interface PromptDefaultErrorActionType {
  type: typeof PROMPT_DEFAULT_ERROR;
  error?: TheiaError;
}

interface SuppressDefaultErrorActionType {
  type: typeof SUPPRESS_DEFAULT_ERROR;
}

export type ErrorTypes =
  PromptDefaultErrorActionType |
  SuppressDefaultErrorActionType

export const promptDefaultErrorAction = (error?: TheiaError): PromptDefaultErrorActionType => ({
  type: PROMPT_DEFAULT_ERROR,
  error
})

export const suppressDefaultErrorAction = (): SuppressDefaultErrorActionType => ({
  type: SUPPRESS_DEFAULT_ERROR
})
