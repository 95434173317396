/* eslint-disable no-param-reassign */
import { useRef, useEffect } from 'react'

export const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key]

export const UseCombinedRefs = (...refs: any[]) => {
  const targetRef = useRef(null)

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

const pattern = /[.,!@#$%^&*()~ç_+\-=[\]{};'´`:"\\|<>/?\s+]*$/
export const cleanString = new RegExp(pattern)

export const compareStringsIgnoreSpecialChars = (str1: string, str2: string) => {
  const normalize = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()

  const normalizedStr1 = normalize(str1).replace(cleanString, '')
  const normalizedStr2 = normalize(str2).replace(cleanString, '')

  return normalizedStr1 === normalizedStr2
}

export const isWhitespaceString = (str: string) => !/\S/.test(str)
