/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, FormikProps } from 'formik'
import {
  RegularButton, Divider, Loader, MaskedFormInput, FormInput
} from 'theia-web-ds'
import * as Yup from 'yup'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import { AppState } from '../../apps/main/store'
import {
  cpfMask,
  emailValidationRegex,
  epochToString,
  isNotFutureDate,
  specialCharacterRegex,
  phoneMask,
  validationPhoneRegex,
  cpfValidation,
  validationFullNameRegex,
  onlyDigits,
} from '../../utils/helpers'
import { PersonalData, ProfileForm } from '../../domain/Profile'
import {
  fetchProfileAction,
  onUpdatePasswordAction,
  onConfirmProfileEditAction,
  promptValidateEmailModalAction,
  promptValidatePhoneModalAction,
  onUpdatePersonalDataAction,
} from '../../state/profile/actions'
import Header from '../common/molecules/Header'
import { FaqToggle } from '../common/FaqToggle'
import ValidatePasswordInput from '../common/ValidatePasswordInput'
import './EditAddressForm.scss'
import { PROFILE } from '../../routes/RoutesConstants'
import { SHOW_EMAIL_VALIDATION_CARD, SHOW_PHONE_VALIDATION_CARD } from '../../state/remoteConfig'

interface Props {
  profile?: ProfileForm;
  fetchProfile: () => void;
  isFetchingProfile: boolean;
  isUpdatingProfile: boolean;
  isPasswordUpdated: boolean;
  isProfileUpdated: boolean;
  onUpdatePersonalData: (profile: PersonalData) => void;
  onUpdatePassword: (password: any) => void;
  onConfirmProfileEdit: () => void;
  showEmailValidationModal: (isFromProfileEdit: boolean) => void;
  showPhoneValidationModal: (isFromProfileEdit: boolean) => void;
}

interface FormValues {
  birthDate: string;
  document: string;
  email: string;
  name: string;
  nationalIdentityCard: string;
  phone: string;
  password: string;
}

function renderEditProfileForm(
  {
    handleSubmit,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    validateField
  }: FormikProps<FormValues>,
  onUpdatePassword: (password: any) => void,
  isUpdatingProfile: boolean,
  isPasswordUpdated: boolean,
  isProfileUpdated: boolean,
  onConfirmProfileEdit: () => void,
  showEmailValidationModal: (isFromProfileEdit: boolean) => void,
  showPhoneValidationModal: (isFromProfileEdit: boolean) => void,
) {
  const [hasChanged, setHasChanged] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (isProfileUpdated) {
      if (values.password) {
        onUpdatePassword({ password: values.password })
      }
      history.push(PROFILE)
      onConfirmProfileEdit()
    }
  }, [isProfileUpdated, isPasswordUpdated])

  function handleOnChange(e: any) {
    handleChange(e)
    setHasChanged(true)
  }

  const onSubmit = () => {
    if (hasChanged) handleSubmit()
  }

  const birthDateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

  return (
    <div className="w-full flex flex-col">
      <Header
        title="Editar dados"
        goBack={() => history.push(PROFILE)}
      />
      <Divider />
      <div className="address-form flex flex-1 relative">
        <div className="mx-auto w-full h-full">
          <div className="lg:max-w-xl mx-auto w-full">
            <div className="form-cep-input-container input-group">
              <FaqToggle
                toggleTitle="Porque é importante preencher todos os seus dados?"
                toggleDescription="Além de manter a sua conta segura, usamos os seus dados para preparar a sua nota fiscal."
                iconType="icon-ChatHelpLight"
                extraDescriptionClass="py-4"
              />
              <div className="px-4 pt-3 lg:max-w-lg w-full mx-auto">
                <h1 className="text-fontSmall text-textPrimary mt-4 mb-4" style={{ maxWidth: '80%' }}>
                  Dados pessoais
                </h1>
                <FormInput
                  id="name"
                  errorMessage={touched.name ? errors.name : ''}
                  onChange={handleOnChange}
                  onBlur={handleBlur}
                  value={values.name}
                  label="Nome Completo"
                  type="text"
                  name="name"
                />
              </div>
              <div className="px-4 lg:max-w-lg w-full mx-auto mt-4">
                <MaskedFormInput
                  label="Data de nascimento"
                  name="birthDate"
                  onChange={handleOnChange}
                  onBlur={handleBlur}
                  errorMessage={touched.birthDate ? errors.birthDate : ''}
                  value={values.birthDate}
                  id="birthDate"
                  type="text"
                  placeholder="00/00/0000"
                  mask={birthDateMask}
                />
              </div>
              <div className="px-4 lg:max-w-lg w-full mx-auto mt-4">
                <MaskedFormInput
                  label="CPF"
                  errorMessage={touched.document ? errors.document : ''}
                  onChange={handleOnChange}
                  onBlur={handleBlur}
                  id="document"
                  value={values.document}
                  name="document"
                  type="text"
                  placeholder="000.000.000-00"
                  mask={cpfMask}
                />
              </div>
              <div className="px-4 lg:max-w-lg w-full mx-auto mt-4">
                <FormInput
                  id="nationalIdentityCard"
                  errorMessage={touched.nationalIdentityCard ? errors.nationalIdentityCard : ''}
                  onChange={handleOnChange}
                  onBlur={handleBlur}
                  value={values.nationalIdentityCard}
                  label="RG"
                  type="text"
                  name="nationalIdentityCard"
                />
              </div>
              <div className="px-4 lg:max-w-lg w-full mx-auto mt-4">
                {SHOW_PHONE_VALIDATION_CARD ? (
                  <div className="flex items-end justify-between">
                    <MaskedFormInput
                      label="Celular"
                      onBlur={handleBlur}
                      onChange={handleOnChange}
                      id="phone"
                      value={values.phone}
                      extraContainerClassName="w-full mr-4"
                      type="tel"
                      disabled
                      mask={phoneMask}
                      errorMessage={errors.phone}
                      name="phone"
                      placeholder="(00) 00000-0000"
                    />
                    <RegularButton
                      label="Editar"
                      onClick={() => showPhoneValidationModal(true)}
                      variant="subtle"
                      width="fit-content"
                    />
                  </div>
                ) : (
                  <MaskedFormInput
                    label="Celular"
                    onBlur={handleBlur}
                    onChange={handleOnChange}
                    id="phone"
                    value={values.phone}
                    type="tel"
                    mask={phoneMask}
                    errorMessage={errors.phone}
                    name="phone"
                    placeholder="(00) 00000-0000"
                  />
                )}
              </div>
              <div className="px-4 lg:max-w-lg w-full mx-auto mt-4">
                {SHOW_EMAIL_VALIDATION_CARD ? (
                  <div className="flex items-end justify-between">
                    <FormInput
                      id="email"
                      errorMessage={touched.email ? errors.email : ''}
                      onChange={handleOnChange}
                      onBlur={handleBlur}
                      value={values.email}
                      label="E-mail"
                      type="email"
                      name="email"
                      disabled
                      extraClassContainer="w-full mr-4"
                    />
                    <RegularButton
                      label="Editar"
                      onClick={() => showEmailValidationModal(true)}
                      variant="subtle"
                      width="fit-content"
                    />
                  </div>
                ) : (
                  <FormInput
                    id="email"
                    errorMessage={touched.email ? errors.email : ''}
                    onChange={handleOnChange}
                    onBlur={handleBlur}
                    value={values.email}
                    label="E-mail"
                    type="email"
                    name="email"
                  />
                )}
              </div>
              <div className="px-4 lg:max-w-lg w-full mx-auto mt-4">
                <ValidatePasswordInput
                  onBlur={handleBlur}
                  password={values.password}
                  onChange={handleOnChange}
                  errorMessage={touched.password && !values.password ? errors.password : ''}
                  value={values.password}
                  error={errors.password}
                  passwordLabel="Nova Senha"
                  eventText="'Inseriu Nova Senha Profile'"
                  onValidateField={() => validateField('password')}
                />
              </div>
              <div className="px-4 lg:max-w-lg w-full mx-auto button-confirm">
                <RegularButton
                  isSubmitting={isUpdatingProfile}
                  disabled={isUpdatingProfile}
                  onClick={onSubmit}
                  label="Salvar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Informe o nome e sobrenome para continuar')
    .matches(validationFullNameRegex, 'Informe o nome e sobrenome para continuar')
    .matches(specialCharacterRegex, 'Inválido caracter especial'),
  email: Yup.string()
    .email('E-mail inválido')
    .matches(emailValidationRegex, 'Digite um e-mail válido pra continuar')
    .required('E-mail obrigatório'),
  phone: Yup.string()
    .required('Celular obrigatório')
    .matches(validationPhoneRegex, 'Celular inválido'),
  password: Yup.string()
    .notRequired()
    .min(8)
    .matches(/[A-Z]/)
    .matches(/[a-z]/)
    .matches(/[0-9]/)
    .matches(/[!@#$%^&*(),.?":{}|<>'-/\\;+_=[\]]/),
  document: Yup.string()
    .required('Informe um CPF válido para continuar')
    .transform((value) => value.replace(/[^\d]/g, ''))
    .test(
      'test-document',
      'CPF inválido',
      (value) => cpfValidation(value)
    ),
  birthDate: Yup.string()
    .test(
      'date',
      'Não é possível incluir uma data futura',
      (value) => isNotFutureDate(value)
    )
    .test('date', 'A data não pode ser anterior a 1930', (value) => {
      const date = moment(value, 'DD/MM/YYYY');
      return date.isSameOrAfter(moment('1930-01-01'));
    }),
  nationalIdentityCard: Yup.string()
    .required('RG obrigatório')
})

function EditAccessDataForm({
  profile,
  fetchProfile,
  isFetchingProfile,
  isUpdatingProfile,
  isPasswordUpdated,
  isProfileUpdated,
  onUpdatePersonalData,
  onUpdatePassword,
  onConfirmProfileEdit,
  showEmailValidationModal,
  showPhoneValidationModal
}: Props) {
  useEffect(() => {
    if (profile === undefined) fetchProfile()
  }, [])

  if (isFetchingProfile) {
    return (
      <div className="flex justify-center h-screen w-full bg-bgCanvas">
        <div className="flex items-center h-full">
          <Loader />
        </div>
      </div>
    )
  }

  const initialValues: FormValues = {
    name: profile?.name || '',
    birthDate: epochToString(profile?.birthDate) || '',
    email: profile?.email || '',
    phone: profile?.phone || '',
    document: profile?.document || '',
    nationalIdentityCard: profile?.nationalIdentityCard || '',
    password: ''
  }

  function handleSubmit(values: FormValues) {
    const formatedBirthDate = moment(values.birthDate, 'DD/MM/YYYY').valueOf()
    onUpdatePersonalData({
      name: values.name,
      birthDate: formatedBirthDate,
      document: values.document ? onlyDigits(values.document) : null,
      nationalIdentityCard: values.nationalIdentityCard || null,
      phone: values.phone,
      email: values.email
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<FormValues>) => renderEditProfileForm(
        props,
        onUpdatePassword,
        isUpdatingProfile,
        isPasswordUpdated,
        isProfileUpdated,
        onConfirmProfileEdit,
        showEmailValidationModal,
        showPhoneValidationModal
      )}
    </Formik>
  )
}

const mapStateToProps = ({ profile }: AppState) => ({
  profile: profile.profile,
  isUpdatingProfile: profile.isUpdatingProfile,
  isFetchingProfile: profile.isFetchingProfile,
  isPasswordUpdated: profile.isPasswordUpdated,
  isProfileUpdated: profile.isProfileUpdated
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  fetchProfile: fetchProfileAction,
  onUpdatePersonalData: onUpdatePersonalDataAction,
  onUpdatePassword: onUpdatePasswordAction,
  onConfirmProfileEdit: onConfirmProfileEditAction,
  showEmailValidationModal: promptValidateEmailModalAction,
  showPhoneValidationModal: promptValidatePhoneModalAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAccessDataForm)
