import React, { useEffect } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { Loader } from 'theia-web-ds'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logoutAction } from '../../state/authentication/main/actions'
import { AppState } from '../../apps/main/store'
import { getFlutterClient } from '../../utils/helpers'
import { BOAS_VINDAS } from '../../routes/RoutesConstants'

interface Props {
  isAuthenticated: boolean;
}

function LoginMobile({ isAuthenticated }: Props) {
  const history = useHistory()
  useEffect(() => {
    const flutterClient = getFlutterClient()
    if (isAuthenticated) {
      if (flutterClient) {
        flutterClient.callHandler('tellMobileAuth', {
          isLogged: true
        })
      }
    } else {
      if (flutterClient) {
        flutterClient.callHandler('tellMobileAuth', {
          isLogged: false
        })
      }
      history.push(BOAS_VINDAS)
    }
  }, [])
  return (
    <div className="flex justify-center h-screen w-full bg-bgCanvas">
      <div className="flex items-center h-full">
        <Loader />
      </div>
    </div>
  )
}

const mapStateToProps = ({ authentication }: AppState) => ({
  isAuthenticated: !!authentication.currentUser
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  logout: logoutAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginMobile)
