import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import AppMainContainerOff from '../AppMainContainerOff'
import LastPeriod from '../common/LastPeriod'
import ArrowLeft from '../../../assets/arrow-left.svg'
import { eventPage } from '../../../eventGenerate'
import './SalesOnboarding.scss'
import { LOGIN_DE_ACESSO } from '../../routes/RoutesConstants'

function SalesOnboardingLastPeriod() {
  const history = useHistory()

  useEffect(() => {
    eventPage('onboarding', 'Data Menstruação')
  }, [])

  function goBack() {
    history.goBack()
  }

  return (
    <AppMainContainerOff>
      <div className="sales-onboarding-container">
        <button onClick={goBack} className="flex flex-row pt-4 pb-4 focus:outline-none" type="button">
          <ArrowLeft />
        </button>
        <LastPeriod isToRedirect locationToPush={LOGIN_DE_ACESSO} />
      </div>
    </AppMainContainerOff>
  )
}

export default React.memo(SalesOnboardingLastPeriod)
