/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import {
  Formik
} from 'formik'
import * as Yup from 'yup'
import '../common/Input.scss'
import { RegularButton, Button, FormInput } from 'theia-web-ds'
import './Authentication.scss'
import { handlePasswordRecoveryAction } from '../../state/authentication/main/actions'
import AppMainContainerOff from '../AppMainContainerOff'
import { AppState } from '../../apps/main/store'
import { PostStatusType } from '../../domain/Status'
import ErrorModal from '../common/ErrorModal'
import { LOGIN } from '../../routes/RoutesConstants'

interface Props {
  handlePasswordRecovery: (email: string) => void;
  postPasswordRecoveryStatus: PostStatusType;
  defaultErrorVisible: boolean;
}

type FormValues = {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail obrigatório'),
})

const initialValues: FormValues = {
  email: ''
}

function NewRecoveryForm({
  handlePasswordRecovery,
  postPasswordRecoveryStatus,
  defaultErrorVisible
}: Props) {
  const [responseMessage, setResponseMessage] = useState<string>('')

  const onSubmit = (values: FormValues) => {
    handlePasswordRecovery(values.email)
  }

  const history = useHistory()

  function goToLogin() {
    history.push(LOGIN)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formikProps) => {
        const {
          handleSubmit,
          isSubmitting,
          handleBlur,
          handleChange,
          touched,
          values,
          errors,
          setSubmitting
        } = formikProps

        useEffect(() => {
          if (postPasswordRecoveryStatus.isPost) {
            setResponseMessage('Se você estiver cadastrada na Theia, um e-mail de recuperação será enviado.')
            setSubmitting(false)
          }
          if (postPasswordRecoveryStatus.errorPost) {
            setResponseMessage('')
            setSubmitting(false)
          }
        }, [postPasswordRecoveryStatus])

        return (

          <AppMainContainerOff screenTitle="Recuperação de senha">
            <div className="buttons-content">
              <form onSubmit={handleSubmit} className="mx-auto w-full lg:max-w-x" noValidate>
                <div className="mb-6">
                  <p>
                    {responseMessage}
                  </p>
                </div>
                <div className="mb-6">
                  <FormInput
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={touched.email ? errors.email : ''}
                    label="E-mail"
                    type="email"
                    name="email"
                    placeholder="Digite aqui seu e-mail"
                  />
                </div>
                <div className="mt-8 flex flex-auto justify-center ">
                  <RegularButton
                    type="submit"
                    label="Recuperar minha senha"
                    disabled={isSubmitting}
                    isSubmitting={isSubmitting}
                    maxWidth="100%"
                  />
                </div>
                <div className="mt-5">
                  <Button className="text-textSmaller underline text-greyColor font-light" height="auto" width="100%" onClick={goToLogin}>
                    Acessar minha conta
                  </Button>
                </div>
              </form>
            </div>
            <ErrorModal
              visible={defaultErrorVisible}
            />
          </AppMainContainerOff>
        )
      }}
    </Formik>
  )
}

const mapStateToProps = ({ authentication, errorWithFriendlyMessage }: AppState) => ({
  postPasswordRecoveryStatus: authentication.postPasswordRecoveryStatus,
  defaultErrorVisible: errorWithFriendlyMessage.visible
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  handlePasswordRecovery: handlePasswordRecoveryAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewRecoveryForm)
