import React from 'react'

export interface Props {
  className?: string;
  width?: string;
  id?: string;
  border?: string;
  margin?: string;
}

export function Divider({
  className,
  id,
  width = '100%',
  border,
  margin,
}: Props) {
  return (
    <hr
      id={id}
      className={`${className}`}
      style={{
        width,
        border,
        margin
      }}
    />
  )
}
