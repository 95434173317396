import * as Sentry from '@sentry/browser'
import {
  AvailabilitiesRequestParams,
  AvailabilitiesService,
  ResponseAvailabilitiesSlots
} from '../../domain/Availabilities'
import { TheiaError } from '../../domain/errors/TheiaError'
import { LocalAddress } from '../../utils/LocalAddress'
import { AsyncAction } from '../utils'

export const GET_AVAILABILITIES = 'GET_AVAILABILITIES'
export const SUCCESS_GET_AVAILABILITIES = 'SUCCESS_GET_AVAILABILITIES'
export const FAILURE_GET_AVAILABILITIES = 'FAILURE_GET_AVAILABILITIES'
export const SET_ATTENDANCE_ADDRESS = 'SET_ATTENDANCE_ADDRESS'
export const SET_PRODUCT_ID = 'SET_PRODUCT_ID'
export const RESET_AVAILABILITIES_STATE = 'RESET_AVAILABILITIES_STATE'

interface GetAvailabilitiesActionType {
  type: typeof GET_AVAILABILITIES;
  offset: number;
}

interface SuccessGetAvailabilitiesActionType {
  type: typeof SUCCESS_GET_AVAILABILITIES;
  response: ResponseAvailabilitiesSlots;
  offset: number;
}

interface FailureGetAvailabilitiesActionType {
  type: typeof FAILURE_GET_AVAILABILITIES;
  error: TheiaError;
}

interface SetAttendanceAddressActionType {
  type: typeof SET_ATTENDANCE_ADDRESS;
  address?: LocalAddress;
}

interface SetProductIdActionType {
  type: typeof SET_PRODUCT_ID;
  productId?: string;
}

interface ResetAvailabilitiesStateActionType {
  type: typeof RESET_AVAILABILITIES_STATE;
}

export type AvailabilitiesTypes = GetAvailabilitiesActionType
| SuccessGetAvailabilitiesActionType
| FailureGetAvailabilitiesActionType
| SetAttendanceAddressActionType
| SetProductIdActionType
| ResetAvailabilitiesStateActionType

export function getAvailabilitiesAction(
  categoryId: string,
  params: AvailabilitiesRequestParams
):
  AsyncAction<{}, { availabilitiesService: AvailabilitiesService }> {
  return async (dispatch, _getState, { availabilitiesService }) => {
    dispatch({ type: GET_AVAILABILITIES, offset: params.offset })
    try {
      const response: ResponseAvailabilitiesSlots = await availabilitiesService.getAvailabilities(
        categoryId,
        params
      )
      dispatch({
        type: SUCCESS_GET_AVAILABILITIES,
        response,
        offset: params.offset
      })
    } catch (error) {
      dispatch({ type: FAILURE_GET_AVAILABILITIES, error })
      if (error instanceof TheiaError) {
        Sentry.captureException(new Error(`Failure to get availabilities - ${error.message}`))
      }
    }
  }
}

export const setAttendanceAddressAction = (
  address?: LocalAddress
): SetAttendanceAddressActionType => ({
  type: SET_ATTENDANCE_ADDRESS,
  address
})

export const setProductIdAction = (
  productId?: string
): SetProductIdActionType => ({
  type: SET_PRODUCT_ID,
  productId
})

export const resetAvailabilitiesStateAction = ()
: ResetAvailabilitiesStateActionType => ({
  type: RESET_AVAILABILITIES_STATE
})
