import { isSupported } from 'twilio-video';

declare global {
  interface Window { opera: any }
}

declare global {
  interface Window { MSStream: any }
}

function isIOSAndChromeOrFirefox() {
  return (/CriOS/i.test(window?.navigator?.userAgent) || /FxiOS/i.test(window?.navigator?.userAgent)) && /iphone|ipod|ipad/i.test(window?.navigator?.userAgent)
}

function isSafariOrFirefox() {
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  const isSafariBrowser = navigator.vendor && navigator.vendor.indexOf('Apple') > -1
    && navigator.userAgent
    && navigator.userAgent.indexOf('CriOS') === -1
    && navigator.userAgent.indexOf('FxiOS') === -1;
  return isSafariBrowser || isFirefox
}

function isIOS() {
  return /iphone|ipod|ipad/i.test(window?.navigator?.userAgent)
}

function isAndroid() {
  return navigator.userAgent.match(/android/i)
}

function isSupportedBrowser() {
  return isSupported
}

function getStoreURL() {
  if (isIOS()) {
    return 'https://apps.apple.com/us/app/theia/id1498648488'
  }
  return 'https://play.google.com/store/apps/details?id=br.com.theia'
}

function getMobileOperatingSystem() {
  const userAgent = navigator?.userAgent || navigator?.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export default {
  isIOSAndChromeOrFirefox,
  isSafariOrFirefox,
  isIOS,
  isAndroid,
  isSupportedBrowser,
  getStoreURL,
  getMobileOperatingSystem,
  isMobile
}
