import {
  ChatVideoService,
  Message,
} from '../../domain/ChatVideo'
import {
  SUCCESS_CONNECT_CHAT_VIDEO,
  ADD_NEW_MESSAGE_CHAT_VIDEO,
  ChatVideoTypes,
  FAILURE_CONNECT_CHAT_VIDEO,
  REQUEST_CONNECT_CHAT_VIDEO,
  FORCE_CONNECT_CHAT_VIDEO,
  FETCH_MESSAGES_CHAT_VIDEO,
  SUCCESS_FETCH_MESSAGES_CHAT_VIDEO,
  FAILURE_FETCH_MESSAGES_CHAT_VIDEO,
  RESET_UNREAD_MESSAGES_COUNT_CHAT_VIDEO,
  UNREAD_MESSAGES_COUNT,
  CHAT_VIDEO_SHUTDOWN,
  UPDATE_TOKEN_CHAT_VIDEO,
  RETRY_UPDATE_TOKEN_CHAT_VIDEO,
  SUCCESS_RETRY_UPDATE_TOKEN_CHAT_VIDEO,
  FAILURE_UPDATE_TOKEN_CHAT_VIDEO
} from './actions'

export interface ChatVideoState {
  chatVideoService?: ChatVideoService;
  messages: Array<Message>;
  isFetchingMessages: boolean;
  historicalMessagesQuantity: number;
  connectChatError: boolean;
  connectingChat: boolean;
  forceReconnect: boolean;
  itWasReconnect: boolean;
  unreadMessagesCount: number;
  chatToken: string;
  isUpdatingToken?: boolean;
}

export const initialChatVideoState: ChatVideoState = {
  chatVideoService: undefined,
  messages: [],
  isFetchingMessages: false,
  historicalMessagesQuantity: 0,
  connectChatError: false,
  connectingChat: false,
  forceReconnect: false,
  itWasReconnect: false,
  unreadMessagesCount: 0,
  chatToken: '',
  isUpdatingToken: false
}

export function chatVideo(state = initialChatVideoState, action: ChatVideoTypes): ChatVideoState {
  switch (action.type) {
    case FORCE_CONNECT_CHAT_VIDEO:
      return {
        ...initialChatVideoState,
        historicalMessagesQuantity: 0,
        messages: [],
        forceReconnect: true,
      }
    case SUCCESS_CONNECT_CHAT_VIDEO:
      return {
        ...state,
        chatVideoService: action.chatVideoService,
        connectChatError: false,
        connectingChat: false,
        forceReconnect: false,
        itWasReconnect: state.forceReconnect,
        chatToken: action.chatToken
      }
    case FAILURE_CONNECT_CHAT_VIDEO:
      return {
        ...state,
        connectChatError: true,
        connectingChat: false,
      }
    case REQUEST_CONNECT_CHAT_VIDEO:
      return {
        ...state,
        isFetchingMessages: true,
      }
    case ADD_NEW_MESSAGE_CHAT_VIDEO:
      return {
        ...state,
        messages: [...state.messages, action.message],
        unreadMessagesCount: state.unreadMessagesCount + 1
      }
    case FETCH_MESSAGES_CHAT_VIDEO:
      return {
        ...state,
        isFetchingMessages: true
      }
    case SUCCESS_FETCH_MESSAGES_CHAT_VIDEO:
      return {
        ...state,
        isFetchingMessages: false,
        historicalMessagesQuantity: state.historicalMessagesQuantity + action.messages.length,
        itWasReconnect: false,
        messages: [...action.messages, ...state.messages]
      }
    case FAILURE_FETCH_MESSAGES_CHAT_VIDEO:
      return {
        ...state,
        isFetchingMessages: false
      }
    case UNREAD_MESSAGES_COUNT:
      return {
        ...state,
        unreadMessagesCount: action.unreadMessagesCount
      }
    case RESET_UNREAD_MESSAGES_COUNT_CHAT_VIDEO:
      return {
        ...state,
        unreadMessagesCount: 0
      }
    case CHAT_VIDEO_SHUTDOWN:
      return {
        ...initialChatVideoState,
        historicalMessagesQuantity: 0,
        messages: [],
      }
    case UPDATE_TOKEN_CHAT_VIDEO:
      return {
        ...state,
        chatToken: action.chatToken,
        isFetchingMessages: false
      }
    case RETRY_UPDATE_TOKEN_CHAT_VIDEO:
      return {
        ...state,
        isUpdatingToken: true
      }
    case SUCCESS_RETRY_UPDATE_TOKEN_CHAT_VIDEO:
      return {
        ...state,
        isUpdatingToken: false
      }
    case FAILURE_UPDATE_TOKEN_CHAT_VIDEO:
      return {
        ...state,
        isUpdatingToken: false
      }
    default:
      return state
  }
}
