import React from 'react'
import { useHistory } from 'react-router-dom'
import { RegularButton } from 'theia-web-ds'
import AppMainContainerOff from '../AppMainContainerOff'
import WomanExercise from '../../../assets/woman-exercise.svg'
import './SalesOnboarding.scss'
import { INICIO } from '../../routes/RoutesConstants'

function SalesOnboardingSuccess() {
  const history = useHistory();

  function goToSpecialist() {
    history.push(INICIO)
  }

  return (
    <AppMainContainerOff>
      <div className="sales-onboarding-container items-center">
        <h1 className="sales-onboarding-title text-primary font-medium text-headlineMedium pt-8">
          Ótimo, seu cadastro foi criado!
        </h1>
        <p className="text-textMedium mt-8 text-success-content" style={{ maxWidth: '70%' }}>
          Agora você pode entrar na Theia, agendar suas consultas e acessar nossos conteúdos!
        </p>
        <div className="flex justify-center mt-6">
          <WomanExercise id="image-woman-exercise" />
        </div>
        <RegularButton
          id="sales-onboarding-confirm-button"
          type="button"
          label="Entrar na minha conta"
          onClick={goToSpecialist}
          maxWidth="100%"
          extraClass="font-medium text-white mt-12"
        />
      </div>
    </AppMainContainerOff>
  )
}

export default React.memo(SalesOnboardingSuccess)
