export class TheiaError extends Error {
  constructor(
    message: string,
    public friendlyMessage?: string,
    public friendlyMessageTitle?: string,
    public statusCode?: string,
  ) {
    super(message)
    Object.setPrototypeOf(this, TheiaError.prototype)
    this.friendlyMessage = friendlyMessage
    this.friendlyMessageTitle = friendlyMessageTitle
    this.statusCode = statusCode
  }
}
