import { CurrentTerm, TermsProvider } from '../../domain/Terms'
import ApiClient from './ApiClient'

export default class ApiTermsService implements TermsProvider {
  constructor(private apiClient: ApiClient) { }

  async getCurrentTerms(): Promise<Array<CurrentTerm>> {
    const currentTermsResponse = await this.apiClient.getCurrentTerms()
    return currentTermsResponse
  }
}
