import * as Sentry from '@sentry/browser'
import {
  Booking,
  ProfileProvider,
  ProfileForm,
  Profile,
  HealthInfosForm,
  GestationalInfosForm,
  PersonalInfosForm,
  HistoryInfoForm,
  MomentInfosForm,
  AddressForm,
  InfosToUpdateProfile,
  PersonalData,
} from '../../domain/Profile'
import { AsyncAction } from '../utils'
import { SuccessBookingCancel } from '../scheduleCancellation/actions'
import { UserProvider } from '../../domain/User'
import { HttpClient } from '../../services/Http'
import {
  workTypeToRemote,
  relationshipToRemote,
  bloodTypeToRemote
} from '../utils/userRemoteAdapters'
import { promptDefaultErrorAction } from '../defaultError/actions'
import { isLiveInSp, onlyDigits } from '../../utils/helpers'
import { setForceUpdateCurrentUserAction } from '../authentication/main/actions'
import { setForceUpdateWeeksAction } from '../weekFlow/actions'
import { AppState } from '../../apps/main/store'
import { showNotificationAction } from '../notifications/actions'
import { success, error as errorColor } from '../../color'
import { NotificationMessage, ToastType } from '../../domain/Notifications'
import { TheiaError } from '../../domain/errors/TheiaError'
import { API_URL } from '../../services/api/ApiClient'

export const FETCH_ALL_BOOKINGS = 'FETCH_ALL_BOOKINGS'
export const SUCCESS_FETCH_ALL_BOOKINGS = 'SUCCESS_FETCH_ALL_BOOKINGS'
export const FAILURE_FETCH_ALL_BOOKINGS = 'FAILURE_FETCH_ALL_BOOKINGS'
export const FORCE_FETCH_PROFILE = 'FORCE_FETCH_PROFILE'
// Edit profile infos
export const UPDATE_PROFILE_REQUESTED = 'UPDATE_PROFILE_REQUESTED'
export const SUCCESS_UPDATE_PROFILE = 'SUCCESS_UPDATE_PROFILE'
export const FAILURE_UPDATE_PROFILE = 'FAILURE_UPDATE_PROFILE'
export const FETCH_PROFILE_REQUESTED = 'FETCH_PROFILE_REQUESTED'
export const SUCCESS_FETCH_PROFILE = 'SUCCESS_FETCH_PROFILE'
export const FAILURE_FETCH_PROFILE = 'FAILURE_FETCH_PROFILE'
export const CLOSE_SUCCESS_UPDATE_MODAL = 'CLOSE_SUCCESS_UPDATE_MODAL'

export const UPLOAD_PICTURE_PROFILE_REQUESTED = 'UPLOAD_PICTURE_PROFILE_REQUESTED'
export const FAILURE_UPLOAD_PICTURE_PROFILE = 'FAILURE_UPLOAD_PICTURE_PROFILE'
export const SUCCESS_UPLOAD_PICTURE_PROFILE = 'SUCCESS_UPLOAD_PICTURE_PROFILE'
export const DELETE_PICTURE_PROFILE_REQUESTED = 'DELETE_PICTURE_PROFILE_REQUESTED'
export const FAILURE_DELETE_PICTURE_PROFILE = 'FAILURE_DELETE_PICTURE_PROFILE'
export const SUCCESS_DELETE_PICTURE_PROFILE = 'SUCCESS_DELETE_PICTURE_PROFILE'
export const FINISH_DELETE_PICTURE_PROFILE = 'FINISH_DELETE_PICTURE_PROFILE'
export const FINISH_UPLOAD_PICTURE_PROFILE = 'FINISH_UPLOAD_PICTURE_PROFILE'

export const SUCCESS_UPDATE_HEALTH_INFOS_IN_PROFILE = 'SUCCESS_UPDATE_HEALTH_INFOS_IN_PROFILE'
export const SUCCESS_UPDATE_GESTATIONAL_INFOS_IN_PROFILE = 'SUCCESS_UPDATE_GESTATIONAL_INFOS_IN_PROFILE'
export const SUCCESS_UPDATE_PERSONAL_INFOS_IN_PROFILE = 'SUCCESS_UPDATE_PERSONAL_INFOS_IN_PROFILE'
export const SUCCESS_UPDATE_HEALTH_PERSONAL_DATA_IN_PROFILE = 'SUCCESS_UPDATE_HEALTH_PERSONAL_DATA_IN_PROFILE'
export const SUCCESS_UPDATE_HEALTH_WEIGHT_DATA_IN_PROFILE = 'SUCCESS_UPDATE_HEALTH_WEIGHT_DATA_IN_PROFILE'
export const SUCCESS_UPDATE_HEALTH_ROUTINE_AND_FOOD_DATA_IN_PROFILE = 'SUCCESS_UPDATE_HEALTH_ROUTINE_AND_FOOD_DATA_IN_PROFILE'
export const SUCCESS_UPDATE_HEALTH_MEDICINE_DATA_IN_PROFILE = 'SUCCESS_UPDATE_HEALTH_MEDICINE_DATA_IN_PROFILE'
export const SUCCESS_UPDATE_HEALTH_MOMENT_DATA_IN_PROFILE = 'SUCCESS_UPDATE_HEALTH_MOMENT_DATA_IN_PROFILE'
export const SUCCESS_UPDATE_HEALTH_HISTORY_DATA_IN_PROFILE = 'SUCCESS_UPDATE_HEALTH_HISTORY_DATA_IN_PROFILE'
export const UPDATE_HEALTH_PERSONAL_DATA_IN_PROFILE_REQUESTED = 'UPDATE_HEALTH_PERSONAL_DATA_IN_PROFILE_REQUESTED'
export const UPDATE_HEALTH_WEIGHT_IN_PROFILE_REQUESTED = 'UPDATE_HEALTH_WEIGHT_IN_PROFILE_REQUESTED'
export const UPDATE_HEALTH_ROUTINE_AND_FOOD_IN_PROFILE_REQUESTED = 'UPDATE_HEALTH_ROUTINE_AND_FOOD_IN_PROFILE_REQUESTED'
export const UPDATE_HEALTH_MEDICATION_IN_PROFILE_REQUESTED = 'UPDATE_HEALTH_MEDICATION_IN_PROFILE_REQUESTED'
export const UPDATE_HEALTH_MOMENT_IN_PROFILE_REQUESTED = 'UPDATE_HEALTH_MOMENT_IN_PROFILE_REQUESTED'
export const UPDATE_HEALTH_HISTORY_IN_PROFILE_REQUESTED = 'UPDATE_HEALTH_HISTORY_IN_PROFILE_REQUESTED'

export const UPDATE_ADDRESS_PROFILE_REQUESTED = 'UPDATE_ADDRESS_PROFILE_REQUESTED'
export const SUCCESS_UPDATE_ADDRESS_PROFILE = 'SUCCESS_UPDATE_ADDRESS_PROFILE'
export const FAILURE_UPDATE_ADDRESS_PROFILE = 'FAILURE_UPDATE_ADDRESS_PROFILE'
export const BACK_TO_PROFILE_FROM_EDIT_ADDRESS_PROFILE = 'BACK_TO_PROFILE_FROM_EDIT_ADDRESS_PROFILE'

export const UPDATE_PASSWORD_REQUESTED = 'UPDATE_PASSWORD_REQUESTED'
export const FAILURE_UPDATE_PASSWORD = 'FAILURE_UPDATE_PASSWORD'
export const SUCCESS_UPDATE_PASSWORD = 'SUCCESS_UPDATE_PASSWORD'
export const CONFIRM_EDIT_PROFILE = 'CONFIRM_EDIT_PROFILE'
export const UPDATE_PROFILE_INFOS = 'UPDATE_PROFILE_INFOS'
export const SUCCESS_UPDATE_PROFILE_INFOS = 'SUCCESS_UPDATE_PROFILE_INFOS'
export const FAILURE_UPDATE_PROFILE_INFOS = 'FAILURE_UPDATE_PROFILE_INFOS'
export const DELETE_DOCUMENTS = 'DELETE_DOCUMENTS'
export const SUCCESS_DELETE_DOCUMENTS = 'SUCCESS_DELETE_DOCUMENTS'
export const FAILURE_DELETE_DOCUMENTS = 'FAILURE_DELETE_DOCUMENTS'

export const VALIDATE_INFORMATION = 'VALIDATE_INFORMATION'
export const SUCCESS_VALIDATE_INFORMATION = 'SUCCESS_VALIDATE_INFORMATION'
export const FAILURE_VALIDATE_INFORMATION = 'FAILURE_VALIDATE_INFORMATION'
export const CLEAR_VALIDATE_INFORMATION_ERRORS = 'CLEAR_VALIDATE_INFORMATION_ERRORS'

export const PROMPT_VALIDATE_EMAIL_MODAL = 'PROMPT_VALIDATE_EMAIL_MODAL'
export const SUPPRESS_VALIDATE_EMAIL_MODAL = 'SUPPRESS_VALIDATE_EMAIL_MODAL'

export const CLEAR_VALIDATE_PHONE_ERRORS = 'CLEAR_VALIDATE_PHONE_ERRORS'
export const PROMPT_VALIDATE_PHONE_MODAL = 'PROMPT_VALIDATE_PHONE_MODAL'
export const SUPPRESS_VALIDATE_PHONE_MODAL = 'SUPPRESS_VALIDATE_PHONE_MODAL'

interface UpdateHealthHistoryInProfileRequested {
  type: typeof UPDATE_HEALTH_HISTORY_IN_PROFILE_REQUESTED;
}

interface UpdateHealthMomentInProfileRequested {
  type: typeof UPDATE_HEALTH_MOMENT_IN_PROFILE_REQUESTED;
}

interface UpdateHealthMedicationInProfileRequested {
  type: typeof UPDATE_HEALTH_MEDICATION_IN_PROFILE_REQUESTED;
}

interface UpdateHealthRoutineAndFoodInProfileRequested {
  type: typeof UPDATE_HEALTH_ROUTINE_AND_FOOD_IN_PROFILE_REQUESTED;
}

interface UpdateHealthWeightInProfileRequested {
  type: typeof UPDATE_HEALTH_WEIGHT_IN_PROFILE_REQUESTED;
}

interface UpdateHealthPersonalDataInProfileRequested {
  type: typeof UPDATE_HEALTH_PERSONAL_DATA_IN_PROFILE_REQUESTED;
}

export interface SuccessUpdateHealthHistoryDataInProfileAction {
  type: typeof SUCCESS_UPDATE_HEALTH_HISTORY_DATA_IN_PROFILE;
  historyInfos: HistoryInfoForm;
}

export interface SuccessUpdateHealthInfosInProfileAction {
  type: typeof SUCCESS_UPDATE_HEALTH_INFOS_IN_PROFILE;
  healthInfos: HealthInfosForm;
}

export interface SuccessUpdateHealthPersonalDataInProfileAction {
  type: typeof SUCCESS_UPDATE_HEALTH_PERSONAL_DATA_IN_PROFILE;
  birthDate: string;
  hasAllergy: string;
  allergies: string[];
  height: number;
  bloodType: string;
}

export interface SuccessUpdateHealthRoutineAndFoodDataInProfileAction {
  type: typeof SUCCESS_UPDATE_HEALTH_ROUTINE_AND_FOOD_DATA_IN_PROFILE;
  workType: string[];
  workTypeOthers: string[];
  hasFoodRestriction: string;
  foodRestriction: string[];
  hasSomeHealthCondition: string;
  someHealthCondition: string[];
}

export interface SuccessUpdateHealthMedicineDataInProfileAction {
  type: typeof SUCCESS_UPDATE_HEALTH_MEDICINE_DATA_IN_PROFILE;
  hasUseSomeMedicine: string;
  useSomeMedicine: string[];
}

export interface SuccessUpdateHealthMomentDataInProfileAction {
  type: typeof SUCCESS_UPDATE_HEALTH_MOMENT_DATA_IN_PROFILE;
  pregnancyMomentUpdateInfoText: string;
}

export interface SuccessUpdateHealthWeightDataInProfileAction {
  type: typeof SUCCESS_UPDATE_HEALTH_WEIGHT_DATA_IN_PROFILE;
  weight: number;
  beforePregnancyWeight: number;
}

export interface SuccessUpdateGestationalInfosProfileAction {
  type: typeof SUCCESS_UPDATE_GESTATIONAL_INFOS_IN_PROFILE;
  gestationalInfos: GestationalInfosForm;
}

export interface SuccessUpdatePersonalInfosProfileAction {
  type: typeof SUCCESS_UPDATE_PERSONAL_INFOS_IN_PROFILE;
  personalInfos: PersonalInfosForm;
}

interface FetchAllBookingsAction {
  type: typeof FETCH_ALL_BOOKINGS;
}

export interface SuccessFetchAllBookingsAction {
  type: typeof SUCCESS_FETCH_ALL_BOOKINGS;
  allBookings: Array<Booking>;
}

interface FailureFetchAllBookingsAction {
  type: typeof FAILURE_FETCH_ALL_BOOKINGS;
}

// Edit profile infos
export interface UpdateProfileRequestedActionType {
  type: typeof UPDATE_PROFILE_REQUESTED;
}

export interface SuccessUpdateProfileActionType {
  type: typeof SUCCESS_UPDATE_PROFILE;
  profile: ProfileForm;
}

export interface FailureUpdateProfileActionType {
  type: typeof FAILURE_UPDATE_PROFILE;
}

export interface UpdateAddressProfileRequestedActionType {
  type: typeof UPDATE_ADDRESS_PROFILE_REQUESTED;
}

export interface SuccessUpdateAddressProfileActionType {
  type: typeof SUCCESS_UPDATE_ADDRESS_PROFILE;
  address: AddressForm;
}

export interface FailureUpdateAddressProfileActionType {
  type: typeof FAILURE_UPDATE_ADDRESS_PROFILE;
}

interface UpdatePasswordRequiredActionType {
  type: typeof UPDATE_PASSWORD_REQUESTED;
}

interface SuccessUpdatePasswordActionType {
  type: typeof SUCCESS_UPDATE_PASSWORD;
  password: any;
}

interface ConfirmEditProfileActionType {
  type: typeof CONFIRM_EDIT_PROFILE;
}

interface FailureUpdatePasswordActionType {
  type: typeof FAILURE_UPDATE_PASSWORD;
}
interface FetchProfileAction {
  type: typeof FETCH_PROFILE_REQUESTED;
}

export interface SuccessFetchProfileActionType {
  type: typeof SUCCESS_FETCH_PROFILE;
  profile: Profile;
}

interface FailureFetchProfileAction {
  type: typeof FAILURE_FETCH_PROFILE;
}

interface CloseSuccessUpdateModalActionType {
  type: typeof CLOSE_SUCCESS_UPDATE_MODAL;
}

interface UploadPictureProfileRequestedType {
  type: typeof UPLOAD_PICTURE_PROFILE_REQUESTED;
}

interface FailureUploadPictureProfileType {
  type: typeof FAILURE_UPLOAD_PICTURE_PROFILE;
}

export interface SuccessUploadPictureProfileType {
  type: typeof SUCCESS_UPLOAD_PICTURE_PROFILE;
  pictureUrl: string;
}

interface FinishUploadPictureProfileType {
  type: typeof FINISH_UPLOAD_PICTURE_PROFILE;
}

interface FinishDeletePictureProfileType {
  type: typeof FINISH_DELETE_PICTURE_PROFILE;
}

interface DeletePictureProfileRequestedType {
  type: typeof DELETE_PICTURE_PROFILE_REQUESTED;
}

interface FailureDeletePictureProfileType {
  type: typeof FAILURE_DELETE_PICTURE_PROFILE;
}

export interface SuccessDeletePictureProfileType {
  type: typeof SUCCESS_DELETE_PICTURE_PROFILE;
  pictureUrl: null;
}

export interface ForceFetchProfileActionType {
  type: typeof FORCE_FETCH_PROFILE;
}

interface BackToProfileFromEditAddressProfileActionType {
  type: typeof BACK_TO_PROFILE_FROM_EDIT_ADDRESS_PROFILE;
}

interface UpdateProfileInfosActionType {
  type: typeof UPDATE_PROFILE_INFOS;
}

export interface SuccessUpdateProfileInfosActionType {
  type: typeof SUCCESS_UPDATE_PROFILE_INFOS;
  profileInfos: ProfileForm;
}

interface FailureUpdateProfileInfosActionType {
  type: typeof FAILURE_UPDATE_PROFILE_INFOS;
}

interface DeleteDocumentsActionType {
  type: typeof DELETE_DOCUMENTS;
}

interface SuccessDeleteDocumentsActionType {
  type: typeof SUCCESS_DELETE_DOCUMENTS;
}

interface FailureDeleteDocumentsActionType {
  type: typeof FAILURE_DELETE_DOCUMENTS;
}

interface ValidateInformationActionType {
  type: typeof VALIDATE_INFORMATION;
}

interface SuccessValidateInformationActionType {
  type: typeof SUCCESS_VALIDATE_INFORMATION;
}

interface FailureValidateInformationActionType {
  type: typeof FAILURE_VALIDATE_INFORMATION;
  error?: TheiaError;
}

interface ClearValidateInformationErrorsActionType {
  type: typeof CLEAR_VALIDATE_INFORMATION_ERRORS;
}

interface PromptValidateEmailModalActionType {
  type: typeof PROMPT_VALIDATE_EMAIL_MODAL;
  isFromProfileEdit: boolean;
}

interface SuppressValidateEmailModalActionType {
  type: typeof SUPPRESS_VALIDATE_EMAIL_MODAL;
}

interface ClearValidatePhoneErrorsActionType {
  type: typeof CLEAR_VALIDATE_PHONE_ERRORS;
}

interface PromptValidatePhoneModalActionType {
  type: typeof PROMPT_VALIDATE_PHONE_MODAL;
  isFromProfileEdit: boolean;
}

interface SuppressValidatePhoneModalActionType {
  type: typeof SUPPRESS_VALIDATE_PHONE_MODAL;
}

export type ProfileTypes = FetchAllBookingsAction |
  SuccessFetchAllBookingsAction |
  FailureFetchAllBookingsAction |
  SuccessBookingCancel |
  SuccessUpdateProfileActionType |
  SuccessFetchProfileActionType |
  FailureFetchProfileAction |
  FetchProfileAction |
  UpdateProfileRequestedActionType |
  FailureUpdateProfileActionType |
  CloseSuccessUpdateModalActionType |
  UploadPictureProfileRequestedType |
  FailureUploadPictureProfileType |
  SuccessUploadPictureProfileType |
  FinishUploadPictureProfileType |
  ForceFetchProfileActionType |
  SuccessUpdateHealthInfosInProfileAction |
  SuccessUpdateGestationalInfosProfileAction |
  SuccessUpdatePersonalInfosProfileAction |
  SuccessUpdateHealthPersonalDataInProfileAction |
  SuccessUpdateHealthWeightDataInProfileAction |
  SuccessUpdateHealthRoutineAndFoodDataInProfileAction |
  SuccessUpdateHealthMedicineDataInProfileAction |
  SuccessUpdateHealthMomentDataInProfileAction |
  SuccessUpdateHealthHistoryDataInProfileAction |
  UpdateHealthPersonalDataInProfileRequested |
  UpdateHealthHistoryInProfileRequested |
  UpdateHealthMomentInProfileRequested |
  UpdateHealthMedicationInProfileRequested |
  UpdateHealthRoutineAndFoodInProfileRequested |
  UpdateHealthWeightInProfileRequested |
  DeletePictureProfileRequestedType |
  SuccessDeletePictureProfileType |
  FailureDeletePictureProfileType |
  FinishDeletePictureProfileType |
  SuccessUpdateAddressProfileActionType |
  FailureUpdateAddressProfileActionType |
  UpdateAddressProfileRequestedActionType |
  BackToProfileFromEditAddressProfileActionType |
  UpdatePasswordRequiredActionType |
  SuccessUpdatePasswordActionType |
  FailureUpdatePasswordActionType |
  ConfirmEditProfileActionType |
  UpdateProfileInfosActionType |
  SuccessUpdateProfileInfosActionType |
  FailureUpdateProfileInfosActionType |
  DeleteDocumentsActionType |
  SuccessDeleteDocumentsActionType |
  FailureDeleteDocumentsActionType |
  ValidateInformationActionType |
  SuccessValidateInformationActionType |
  FailureValidateInformationActionType |
  ClearValidateInformationErrorsActionType |
  PromptValidateEmailModalActionType |
  SuppressValidateEmailModalActionType |
  ClearValidatePhoneErrorsActionType |
  PromptValidatePhoneModalActionType |
  SuppressValidatePhoneModalActionType

export function fetchAllBookings():
  AsyncAction<{}, { profileProvider: ProfileProvider }> {
  return async (dispatch, _getState, { profileProvider }) => {
    dispatch({ type: FETCH_ALL_BOOKINGS })
    try {
      const allBookings = await profileProvider.getAllBookings()
      dispatch({ type: SUCCESS_FETCH_ALL_BOOKINGS, allBookings })
    } catch (error) {
      dispatch({ type: FAILURE_FETCH_ALL_BOOKINGS })
      Sentry.captureException(new Error(`Failure to fetch all bookings - ${error.message}`))
    }
  }
}

export function fetchProfileAction():
  AsyncAction<{}, { profileProvider: ProfileProvider }> {
  return async (dispatch, _getState, { profileProvider }) => {
    dispatch({ type: FETCH_PROFILE_REQUESTED })
    try {
      const profile = await profileProvider.getProfile()
      dispatch({ type: SUCCESS_FETCH_PROFILE, profile })
    } catch (error) {
      dispatch({ type: FAILURE_FETCH_PROFILE })
      if (error instanceof Error) {
        Sentry.captureException(new Error(`Failure to fetch profile - ${error.message}`))
      }
    }
  }
}

export function updateProfileAction(
  profileInfos: InfosToUpdateProfile,
  showNotification?: boolean
): AsyncAction<{}, {
  profileProvider: ProfileProvider;
  userProvider: UserProvider
}> {
  return async (dispatch, _getState, { profileProvider }) => {
    dispatch({ type: UPDATE_PROFILE_INFOS })
    try {
      await profileProvider.updateProfile(profileInfos)
      dispatch({ type: SUCCESS_UPDATE_PROFILE_INFOS, profileInfos })
      if (showNotification) {
        dispatch(showNotificationAction({
          message: NotificationMessage.DATA_UPDATED_SUCCESSFULLY,
          iconColor: success,
          iconType: 'icon-TickRoundLight',
          timer: 5000,
          id: 'SUCCESS_UPDATE_PROFILE_INFOS',
          type: ToastType.SUCCESS
        }))
      }
    } catch (error) {
      dispatch({ type: FAILURE_UPDATE_PROFILE_INFOS })
      dispatch(showNotificationAction({
        message: error.friendlyMessage || error.friendlyMessageTitle || 'Erro ao salvar os dados. Por favor, tente novamente.',
        iconColor: errorColor,
        iconType: 'icon-CloseCircleLight',
        timer: 5000,
        id: 'FAILURE_UPDATE_PROFILE_INFOS',
        type: ToastType.ERROR
      }))
      Sentry.captureException(new Error(`Failure updating profile - ${error.message} - ${error.friendlyMessage} - ${error.friendlyMessageTitle}`))
    }
  }
}

export function deleteDocumentsAction(): AsyncAction<{}, {
  profileProvider: ProfileProvider
}> {
  return async (dispatch, _getState, { profileProvider }) => {
    const { authentication } = _getState() as AppState
    const consultantId = authentication.currentUser?.id || ''
    dispatch({ type: DELETE_DOCUMENTS })
    try {
      await profileProvider.deleteDocuments(consultantId)
      dispatch({ type: SUCCESS_DELETE_DOCUMENTS })
    } catch (error) {
      dispatch({ type: FAILURE_DELETE_DOCUMENTS })
      Sentry.captureException(new Error(`Failure delete documents - ${error.message}`))
    }
  }
}

// Edit profile infos
export function onUpdateProfileAction(profile: ProfileForm):
  AsyncAction<{}, {
    userProvider: UserProvider;
    profileProvider: ProfileProvider;
  }> {
  return async (dispatch, _getState, { profileProvider }) => {
    dispatch({ type: UPDATE_PROFILE_REQUESTED })
    const hasAddress = (
      profile.city
      || profile.stateAbbreviation
      || profile.addressName
      || profile.number
      || profile.complement
    )
    const pregnancyDate = {
      pregnancyDueDate: profile.pregnancyDueDate || null,
      firstDayLastPeriod: profile.firstDayLastPeriod || null
    }
    const hasRG = profile.nationalIdentityCard !== ''
    try {
      const liveInSp: boolean = isLiveInSp(profile.liveInSp)
      const payload = {
        name: profile.name,
        liveInSp,
        phone: profile.phone,
        email: profile.email,
        gender: profile.gender === 'Selecionar' ? undefined : profile.gender,
        genderOther: undefined,
        genderPreference: undefined,
        pregnancyMoment: profile.pregnancyMoment === '' ? undefined : profile.pregnancyMoment,
        hasKids: profile.hasKids !== '' ? profile.hasKids === 'true' : undefined,
        numberOfKids: (
          profile.hasKids === 'true'
            ? profile.numberOfKids
            : 0
        ),
        workType: profile.workType.map((element) => workTypeToRemote(element)),
        workTypeOthers: profile.workTypeOthers,
        hasUseSomeMedicine: profile.hasUseSomeMedicine !== '' ? profile.hasUseSomeMedicine === 'true' : undefined,
        useSomeMedicine: profile.useSomeMedicine,
        hasSomeHealthCondition: profile.hasSomeHealthCondition !== '' ? profile.hasSomeHealthCondition === 'true' : undefined,
        someHealthCondition: profile.someHealthCondition,
        hasFoodRestriction: profile.hasFoodRestriction !== '' ? profile.hasFoodRestriction === 'true' : undefined,
        foodRestriction: profile.foodRestriction,
        birthDate: profile.birthDate,
        hasAboutRelationship: profile.hasAboutRelationship !== '' ? profile.hasAboutRelationship === 'true' : undefined,
        aboutRelationship: profile.aboutRelationship.map((e) => relationshipToRemote(e)),
        aboutRelationshipOthers: profile.aboutRelationshipOthers,
        address: hasAddress ? {
          postalCode: profile.postalCode,
          cityName: profile.city,
          stateAbbreviation: profile.stateAbbreviation,
          name: profile.addressName,
          number: profile.number,
          complement: profile.complement
        } : null,
        document: (
          profile.document !== null
            ? onlyDigits(profile.document)
            : null
        ),
        pregnancyDate,
        nationalIdentityCard: hasRG ? profile.nationalIdentityCard : undefined
      } as InfosToUpdateProfile
      await profileProvider.updateProfile(payload)
      dispatch({
        type: SUCCESS_UPDATE_PROFILE,
        profile: {
          ...profile,
          ...pregnancyDate,
          expectedBabyBirthDate: pregnancyDate.pregnancyDueDate,
          lastPeriodStartDate: pregnancyDate.firstDayLastPeriod
        }
      })
      dispatch(showNotificationAction({
        message: NotificationMessage.DATA_UPDATED_SUCCESSFULLY,
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 5000,
        id: 'SUCCESS_UPDATE_PROFILE_INFOS',
        type: ToastType.SUCCESS
      }))
      dispatch(setForceUpdateWeeksAction())
      dispatch(setForceUpdateCurrentUserAction())
    } catch (error) {
      dispatch(promptDefaultErrorAction(error as TheiaError))
      dispatch({ type: FAILURE_UPDATE_PROFILE })
      Sentry.captureException(new Error(`Failure updating the profile - ${error.message} - ${error.friendlyMessage} - ${error.friendlyMessageTitle}`))
    }
  }
}

export function onUpdatePersonalDataAction(profile: PersonalData):
  AsyncAction<{}, {
    userProvider: UserProvider;
    profileProvider: ProfileProvider;
  }> {
  return async (dispatch, _getState, { profileProvider }) => {
    dispatch({ type: UPDATE_PROFILE_REQUESTED })
    try {
      const payload = {
        name: profile.name,
        phone: profile.phone,
        email: profile.email,
        birthDate: profile.birthDate,
        document: profile.document,
        nationalIdentityCard: profile.nationalIdentityCard
      }
      await profileProvider.updatePersonalData(payload)
      dispatch({
        type: SUCCESS_UPDATE_PROFILE,
        profile
      })
      dispatch(showNotificationAction({
        message: NotificationMessage.DATA_UPDATED_SUCCESSFULLY,
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 5000,
        id: 'SUCCESS_UPDATE_PROFILE_INFOS',
        type: ToastType.SUCCESS
      }))
      dispatch(setForceUpdateWeeksAction())
      dispatch(setForceUpdateCurrentUserAction())
    } catch (error) {
      dispatch(promptDefaultErrorAction(error as TheiaError))
      dispatch({ type: FAILURE_UPDATE_PROFILE })
      Sentry.captureException(new Error(`Failure updating the profile - ${error.message} - ${error.friendlyMessage} - ${error.friendlyMessageTitle}`))
    }
  }
}

export function onUpdateAddressAction(
  address: AddressForm
):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: UPDATE_ADDRESS_PROFILE_REQUESTED })
    try {
      const url = `${API_URL}/consultants/address`
      const payload = address
      const response = await httpClient.put({ url, payload })
      if (response.status !== 200) {
        dispatch(promptDefaultErrorAction())
        dispatch({ type: FAILURE_UPDATE_ADDRESS_PROFILE })
        const responseJson = JSON.parse(response?.body)
        Sentry.captureException(new Error(`Failure updating the profile - ${responseJson?.message}`))
      } else {
        dispatch({
          type: SUCCESS_UPDATE_ADDRESS_PROFILE,
          payload
        })
      }
    } catch (error) {
      dispatch(promptDefaultErrorAction())
      dispatch({ type: FAILURE_UPDATE_ADDRESS_PROFILE })
      Sentry.captureException(new Error(`Unexpected error updating the profile - ${error.message}`))
    }
  }
}

export function onUpdatePasswordAction(
  password: any
):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: UPDATE_PASSWORD_REQUESTED })
    try {
      const url = `${API_URL}/v1/password-change`
      const payload = password
      const response = await httpClient.post({ url, payload })
      if (response.status !== 200) {
        dispatch(promptDefaultErrorAction())
        dispatch({ type: FAILURE_UPDATE_PASSWORD })
        dispatch(setForceUpdateCurrentUserAction())
        const responseJson = JSON.parse(response?.body)
        Sentry.captureException(new Error(`Failure updating the profile - ${responseJson?.message}`))
      } else {
        dispatch({
          type: SUCCESS_UPDATE_PASSWORD,
          payload
        })
      }
    } catch (error) {
      dispatch(promptDefaultErrorAction())
      dispatch({ type: FAILURE_UPDATE_PASSWORD })
      dispatch(setForceUpdateCurrentUserAction())
      Sentry.captureException(new Error(`Unexpected error updating the profile - ${error.message}`))
    }
  }
}

export const onGoBackToProfileAfterUpdateAddressAction = ():
BackToProfileFromEditAddressProfileActionType => ({
  type: BACK_TO_PROFILE_FROM_EDIT_ADDRESS_PROFILE,
})

export const onConfirmProfileEditAction = (): ConfirmEditProfileActionType => ({
  type: CONFIRM_EDIT_PROFILE
})

export function onUpdateHealthPersonalDataInProfileAction(
  name: string,
  email: string,
  phone: string,
  birthDate: string,
  hasAllergy: string,
  allergies: string[],
  height: number,
  bloodType: string,
):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: UPDATE_HEALTH_PERSONAL_DATA_IN_PROFILE_REQUESTED })
    try {
      const bloodTypeToRemoteValue = bloodTypeToRemote(bloodType)
      const url = `${API_URL}/consultants/profile`
      const payload: {} = {
        name,
        phone,
        email,
        hasAllergy: hasAllergy !== '' ? hasAllergy === 'true' : undefined,
        allergies,
        birthDate,
        height,
        bloodType: bloodTypeToRemoteValue || null
      }
      const response = await httpClient.put({ url, payload })
      if (response.status !== 200) {
        const responseJson = JSON.parse(response?.body)
        dispatch(promptDefaultErrorAction())
        dispatch({ type: FAILURE_UPDATE_PROFILE })
        Sentry.captureException(new Error(`Failure updating the health personal data in profile - ${responseJson?.message}`))
      } else {
        dispatch({
          type: SUCCESS_UPDATE_HEALTH_PERSONAL_DATA_IN_PROFILE,
          birthDate,
          hasAllergy,
          allergies,
          height,
          bloodType,
        })
        dispatch(showNotificationAction({
          message: NotificationMessage.DATA_SENT_SUCCESSFULLY,
          iconColor: success,
          iconType: 'icon-TickRoundLight',
          timer: 5000,
          id: 'SUCCESS_UPDATE_HEALTH_PERSONAL_DATA_IN_PROFILE',
          type: ToastType.SUCCESS
        }))
        dispatch(setForceUpdateCurrentUserAction())
      }
    } catch (error) {
      dispatch(promptDefaultErrorAction())
      dispatch({ type: FAILURE_UPDATE_PROFILE })
      Sentry.captureException(new Error(`Unexpected error updating the health personal data in profile - ${error.message}`))
    }
  }
}

export function onUpdateHealthWeightDataInProfileAction(
  name: string,
  email: string,
  phone: string,
  weight?: number,
  beforePregnancyWeight?: number
):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: UPDATE_HEALTH_WEIGHT_IN_PROFILE_REQUESTED })
    try {
      const url = `${API_URL}/consultants/profile`
      const payload: {} = {
        name,
        phone,
        email,
        weight,
        beforePregnancyWeight
      }
      const response = await httpClient.put({ url, payload })
      if (response.status !== 200) {
        const responseJson = JSON.parse(response?.body)
        dispatch(promptDefaultErrorAction())
        dispatch({ type: FAILURE_UPDATE_PROFILE })
        Sentry.captureException(new Error(`Failure updating the health weight data in profile - ${responseJson?.message}`))
      } else {
        dispatch({
          type: SUCCESS_UPDATE_HEALTH_WEIGHT_DATA_IN_PROFILE,
          weight,
          beforePregnancyWeight
        })
        dispatch(showNotificationAction({
          message: NotificationMessage.DATA_SENT_SUCCESSFULLY,
          iconColor: success,
          iconType: 'icon-TickRoundLight',
          timer: 5000,
          id: 'SUCCESS_UPDATE_HEALTH_WEIGHT_DATA_IN_PROFILE',
          type: ToastType.SUCCESS
        }))
        dispatch(setForceUpdateCurrentUserAction())
      }
    } catch (error) {
      dispatch(promptDefaultErrorAction())
      dispatch({ type: FAILURE_UPDATE_PROFILE })
      Sentry.captureException(new Error(`Unexpected error updating the health weight data in profile - ${error.message}`))
    }
  }
}

export function onUpdateHealthRoutineAndFoodDataInProfileAction(
  name: string,
  email: string,
  phone: string,
  workType: string[],
  workTypeOthers: string[],
  hasFoodRestriction: string,
  foodRestriction: string[],
  hasSomeHealthCondition: string,
  someHealthCondition: string[],
  substanceUse: string[],
  substanceUseOthers: string[],
):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: UPDATE_HEALTH_ROUTINE_AND_FOOD_IN_PROFILE_REQUESTED })
    try {
      const url = `${API_URL}/consultants/profile`
      const payload: {} = {
        name,
        phone,
        email,
        workType: (
          workType && workType.map((element) => workTypeToRemote(element))
        ),
        workTypeOthers,
        foodRestriction,
        someHealthCondition,
        hasSomeHealthCondition: hasSomeHealthCondition !== '' ? hasSomeHealthCondition === 'true' : undefined,
        hasFoodRestriction: hasFoodRestriction !== '' ? hasFoodRestriction === 'true' : undefined,
        substanceUse,
        substanceUseOthers
      }
      const response = await httpClient.put({ url, payload })
      if (response.status !== 200) {
        const responseJson = JSON.parse(response?.body)
        dispatch(promptDefaultErrorAction())
        dispatch({ type: FAILURE_UPDATE_PROFILE })
        Sentry.captureException(new Error(`Failure updating the health routine and food data in profile - ${responseJson?.message}`))
      } else {
        dispatch({
          type: SUCCESS_UPDATE_HEALTH_ROUTINE_AND_FOOD_DATA_IN_PROFILE,
          workType,
          workTypeOthers,
          hasFoodRestriction,
          foodRestriction,
          hasSomeHealthCondition,
          someHealthCondition
        })
        dispatch(showNotificationAction({
          message: NotificationMessage.DATA_SENT_SUCCESSFULLY,
          iconColor: success,
          iconType: 'icon-TickRoundLight',
          timer: 5000,
          id: 'SUCCESS_UPDATE_HEALTH_ROUTINE_AND_FOOD_DATA_IN_PROFILE',
          type: ToastType.SUCCESS
        }))
        dispatch(setForceUpdateCurrentUserAction())
      }
    } catch (error) {
      dispatch(promptDefaultErrorAction())
      dispatch({ type: FAILURE_UPDATE_PROFILE })
      Sentry.captureException(new Error(`Unexpected error updating the health routine and food data in profile - ${error.message}`))
    }
  }
}

export function onUpdateHealthMedicationDataInProfileAction(
  name: string,
  email: string,
  phone: string,
  hasUseSomeMedicine: string,
  useSomeMedicine: string[]
):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: UPDATE_HEALTH_MEDICATION_IN_PROFILE_REQUESTED })
    try {
      const url = `${API_URL}/consultants/profile`
      const payload: {} = {
        name,
        phone,
        email,
        hasUseSomeMedicine: hasUseSomeMedicine !== '' ? hasUseSomeMedicine === 'true' : undefined,
        useSomeMedicine,
      }
      const response = await httpClient.put({ url, payload })
      if (response.status !== 200) {
        const responseJson = JSON.parse(response?.body)
        dispatch(promptDefaultErrorAction())
        dispatch({ type: FAILURE_UPDATE_PROFILE })
        Sentry.captureException(new Error(`Failure updating the health medicine data in profile - ${responseJson?.message}`))
      } else {
        dispatch({
          type: SUCCESS_UPDATE_HEALTH_MEDICINE_DATA_IN_PROFILE,
          hasUseSomeMedicine,
          useSomeMedicine
        })
        dispatch(showNotificationAction({
          message: NotificationMessage.DATA_SENT_SUCCESSFULLY,
          iconColor: success,
          iconType: 'icon-TickRoundLight',
          timer: 5000,
          id: 'SUCCESS_UPDATE_HEALTH_MEDICINE_DATA_IN_PROFILE',
          type: ToastType.SUCCESS
        }))
        dispatch(setForceUpdateCurrentUserAction())
      }
    } catch (error) {
      dispatch(promptDefaultErrorAction())
      dispatch({ type: FAILURE_UPDATE_PROFILE })
      Sentry.captureException(new Error(`Unexpected error updating the health medicine data in profile - ${error.message}`))
    }
  }
}

export function onUpdateHealthMomentDataInProfileAction(
  name: string,
  email: string,
  phone: string,
  momentInfos: MomentInfosForm
):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: UPDATE_HEALTH_MOMENT_IN_PROFILE_REQUESTED })
    try {
      const url = `${API_URL}/consultants/profile`
      const pregnancyDate = {
        pregnancyDueDate: momentInfos.pregnancyDueDate || null,
        firstDayLastPeriod: momentInfos.firstDayLastPeriod || null
      }
      const payload: {} = {
        name,
        phone,
        email,
        pregnancyMoment: momentInfos.pregnancyMoment === '' ? undefined : momentInfos.pregnancyMoment,
        pregnancyMomentOther: momentInfos.pregnancyMomentOther,
        pregnancyDate,
        pregnancyEffectiveDueDate: momentInfos.pregnancyEffectiveDueDate
      }
      const response = await httpClient.put({ url, payload })
      if (response.status !== 200) {
        const responseJson = JSON.parse(response?.body)
        dispatch(promptDefaultErrorAction())
        dispatch({ type: FAILURE_UPDATE_PROFILE })
        Sentry.captureException(new Error(`Failure updating the health moment data in profile - ${responseJson?.message}`))
      } else {
        fetchProfileAction()
        dispatch({
          type: SUCCESS_UPDATE_HEALTH_MOMENT_DATA_IN_PROFILE,
          pregnancyMomentUpdateInfoText: JSON.parse(response.body).pregnancyMomentUpdateInfo
        })
        dispatch(showNotificationAction({
          message: NotificationMessage.DATA_SENT_SUCCESSFULLY,
          iconColor: success,
          iconType: 'icon-TickRoundLight',
          timer: 5000,
          id: 'SUCCESS_UPDATE_HEALTH_MOMENT_DATA_IN_PROFILE',
          type: ToastType.SUCCESS
        }))
        dispatch(setForceUpdateWeeksAction())
        dispatch(setForceUpdateCurrentUserAction())
      }
    } catch (error) {
      dispatch(promptDefaultErrorAction())
      dispatch({ type: FAILURE_UPDATE_PROFILE })
      Sentry.captureException(new Error(`Unexpected error updating the health moment data in profile - ${error.message}`))
    }
  }
}

export function onUpdateHealthHistoryDataInProfileAction(
  name: string,
  email: string,
  phone: string,
  historyInfos: HistoryInfoForm
):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: UPDATE_HEALTH_HISTORY_IN_PROFILE_REQUESTED })
    try {
      const url = `${API_URL}/consultants/profile`
      const payload: {} = {
        name,
        phone,
        email,
        hasKids: historyInfos.hasKids !== '' ? historyInfos.hasKids === 'true' : undefined,
        numberOfKids: (
          historyInfos.hasKids === 'true'
            ? historyInfos.numberOfKids
            : 0
        ),
        hasAboutRelationship: historyInfos.hasAboutRelationship !== '' ? historyInfos.hasAboutRelationship === 'true' : undefined,
        aboutRelationship: historyInfos.aboutRelationship?.map((e) => relationshipToRemote(e)),
        aboutRelationshipOthers: historyInfos.aboutRelationshipOthers,
        hasPregnancyLoss: historyInfos.hasPregnancyLoss !== '' ? historyInfos.hasPregnancyLoss === 'true' : undefined,
      }
      const response = await httpClient.put({ url, payload })
      if (response.status !== 200) {
        const responseJson = JSON.parse(response?.body)
        dispatch(promptDefaultErrorAction())
        dispatch({ type: FAILURE_UPDATE_PROFILE })
        Sentry.captureException(new Error(`Failure updating the health history data in profile - ${responseJson?.message}`))
      } else {
        fetchProfileAction()
        dispatch({
          type: SUCCESS_UPDATE_HEALTH_HISTORY_DATA_IN_PROFILE,
          historyInfos
        })
        dispatch(showNotificationAction({
          message: NotificationMessage.DATA_SENT_SUCCESSFULLY,
          iconColor: success,
          iconType: 'icon-TickRoundLight',
          timer: 5000,
          id: 'SUCCESS_UPDATE_HEALTH_HISTORY_DATA_IN_PROFILE',
          type: ToastType.SUCCESS
        }))
        dispatch(setForceUpdateCurrentUserAction())
      }
    } catch (error) {
      dispatch(promptDefaultErrorAction())
      dispatch({ type: FAILURE_UPDATE_PROFILE })
      Sentry.captureException(new Error(`Unexpected error updating the health history data in profile - ${error.message}`))
    }
  }
}

export function onUpdateHealthInfosInProfileAction(
  name: string,
  email: string,
  phone: string,
  healthInfos: HealthInfosForm
):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: UPDATE_PROFILE_REQUESTED })
    try {
      const url = `${API_URL}/consultants/profile`
      const payload: {} = {
        ...healthInfos,
        name,
        phone,
        email,
        hasUseSomeMedicine: healthInfos.hasUseSomeMedicine !== '' ? healthInfos.hasUseSomeMedicine === 'true' : undefined,
        hasSomeHealthCondition: healthInfos.hasSomeHealthCondition !== '' ? healthInfos.hasSomeHealthCondition === 'true' : undefined,
        hasFoodRestriction: healthInfos.hasFoodRestriction !== '' ? healthInfos.hasFoodRestriction === 'true' : undefined,
        hasAllergy: healthInfos.hasAllergy !== '' ? healthInfos.hasAllergy === 'true' : undefined,
        workType: (
          healthInfos.workType && healthInfos.workType.map((element) => workTypeToRemote(element))
        ),
        weight: healthInfos.weight
      }
      const response = await httpClient.put({ url, payload })
      if (response.status !== 200) {
        const responseJson = JSON.parse(response?.body)
        dispatch(promptDefaultErrorAction())
        dispatch({ type: FAILURE_UPDATE_PROFILE })
        Sentry.captureException(new Error(`Failure updating the health infos in profile - ${responseJson?.message}`))
      } else {
        dispatch({
          type: SUCCESS_UPDATE_HEALTH_INFOS_IN_PROFILE,
          healthInfos
        })
        dispatch(setForceUpdateCurrentUserAction())
      }
    } catch (error) {
      dispatch(promptDefaultErrorAction())
      dispatch({ type: FAILURE_UPDATE_PROFILE })
      Sentry.captureException(new Error(`Unexpected error updating the health infos in profile - ${error.message}`))
    }
  }
}

export function onUpdateGestationalInfosInProfileAction(
  name: string,
  email: string,
  phone: string,
  gestationalInfos: GestationalInfosForm
):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: UPDATE_PROFILE_REQUESTED })
    try {
      const url = `${API_URL}/consultants/profile`
      const payload: {} = {
        ...gestationalInfos,
        name,
        phone,
        email,
        hasPregnancyLoss: gestationalInfos.hasPregnancyLoss !== '' ? gestationalInfos.hasPregnancyLoss === 'true' : undefined,
        hasAboutRelationship: gestationalInfos.hasAboutRelationship !== '' ? gestationalInfos.hasAboutRelationship === 'true' : undefined,
        aboutRelationship: gestationalInfos.aboutRelationship.map((e) => relationshipToRemote(e)),
        aboutRelationshipOthers: gestationalInfos.aboutRelationshipOthers,
      }

      const response = await httpClient.put({ url, payload })
      if (response.status !== 200) {
        const responseJson = JSON.parse(response?.body)
        dispatch(promptDefaultErrorAction())
        dispatch({ type: FAILURE_UPDATE_PROFILE })
        Sentry.captureException(new Error(`Failure updating the gestational infos in profile - ${responseJson?.message}`))
      } else {
        dispatch({
          type: SUCCESS_UPDATE_GESTATIONAL_INFOS_IN_PROFILE,
          gestationalInfos
        })
        dispatch(setForceUpdateCurrentUserAction())
      }
    } catch (error) {
      dispatch(promptDefaultErrorAction())
      dispatch({ type: FAILURE_UPDATE_PROFILE })
      Sentry.captureException(new Error(`Unexpected error updating the gestational infos in profile - ${error.message}`))
    }
  }
}

export function onUpdatePersonalInfosInProfileAction(
  name: string,
  email: string,
  phone: string,
  personalInfos: PersonalInfosForm
):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: UPDATE_PROFILE_REQUESTED })
    try {
      const url = `${API_URL}/consultants/profile`
      const payload: {} = {
        name,
        phone,
        email,
        address: {
          postalCode: personalInfos.postalCode,
          cityName: personalInfos.city,
          stateAbbreviation: personalInfos.stateAbbreviation,
          name: personalInfos.addressName,
          number: personalInfos.number,
          complement: personalInfos.complement
        },
        birthDate: personalInfos.birthDate?.toString(),
        gender: personalInfos.gender !== '' ? personalInfos.gender : undefined,
        genderOther: personalInfos.genderOther !== '' ? personalInfos.genderOther : undefined,
      }
      const response = await httpClient.put({ url, payload })
      if (response.status !== 200) {
        const responseJson = JSON.parse(response?.body)
        dispatch(promptDefaultErrorAction())
        dispatch({ type: FAILURE_UPDATE_PROFILE })
        Sentry.captureException(new Error(`Failure updating the personal infos in profile - ${responseJson?.message}`))
      } else {
        dispatch({
          type: SUCCESS_UPDATE_PERSONAL_INFOS_IN_PROFILE,
          personalInfos
        })
        dispatch(showNotificationAction({
          message: NotificationMessage.DATA_SENT_SUCCESSFULLY,
          iconColor: success,
          iconType: 'icon-TickRoundLight',
          timer: 5000,
          id: 'SUCCESS_UPDATE_PERSONAL_INFOS_IN_PROFILE',
          type: ToastType.SUCCESS
        }))
        dispatch(setForceUpdateWeeksAction())
        dispatch(setForceUpdateCurrentUserAction())
      }
    } catch (error) {
      dispatch(promptDefaultErrorAction())
      dispatch({ type: FAILURE_UPDATE_PROFILE })
      Sentry.captureException(new Error(`Unexpected error updating the personal infos in profile - ${error.message}`))
    }
  }
}

export const onCloseSuccessUpdateModal = (): CloseSuccessUpdateModalActionType => (
  { type: CLOSE_SUCCESS_UPDATE_MODAL }
)

export function onUploadPictureProfileAction(file?: File):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    if (!file) return
    dispatch({ type: UPLOAD_PICTURE_PROFILE_REQUESTED })
    try {
      const url = `${API_URL}/consultants/avatar`
      const formData = new FormData();
      formData.append('file', file, file.name);
      const response = await httpClient.postFile({ url, body: formData })

      if (response.status !== 200) {
        dispatch(promptDefaultErrorAction())
        dispatch({ type: FAILURE_UPLOAD_PICTURE_PROFILE })
        const responseJson = JSON.parse(response?.body)
        Sentry.captureException(new Error(`Failure uploading picture profile - ${responseJson?.message}`))
      } else {
        const responseJson = JSON.parse(response?.body)
        dispatch({
          type: SUCCESS_UPLOAD_PICTURE_PROFILE,
          pictureUrl: responseJson.pictureUrl,
        })
        setTimeout(() => {
          dispatch({
            type: FINISH_UPLOAD_PICTURE_PROFILE
          })
        }, 6000)
      }
    } catch (error) {
      dispatch(promptDefaultErrorAction())
      dispatch({ type: FAILURE_UPLOAD_PICTURE_PROFILE })
      Sentry.captureException(new Error(`Unexpected error uploading picture profile - ${error.message}`))
    }
  }
}

export function onDeletePictureProfileAction(file: string | null):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    if (!file) return
    dispatch({ type: DELETE_PICTURE_PROFILE_REQUESTED })
    try {
      const url = `${API_URL}/consultants/avatar`
      const response = await httpClient.delete({ url })

      if (response.status !== 200) {
        dispatch(promptDefaultErrorAction())
        dispatch({ type: FAILURE_DELETE_PICTURE_PROFILE })
        const responseJson = JSON.parse(response?.body)
        Sentry.captureException(new Error(`Failure Deleting picture profile - ${responseJson?.message}`))
      } else {
        dispatch({
          type: SUCCESS_DELETE_PICTURE_PROFILE,
          pictureUrl: null,
        })
        setTimeout(() => {
          dispatch({
            type: FINISH_DELETE_PICTURE_PROFILE
          })
        }, 6000)
      }
    } catch (error) {
      dispatch(promptDefaultErrorAction())
      dispatch({ type: FAILURE_UPLOAD_PICTURE_PROFILE })
      Sentry.captureException(new Error(`Unexpected error deleting picture profile - ${error.message}`))
    }
  }
}

export const forceFetchProfileAction = (): ForceFetchProfileActionType => ({
  type: FORCE_FETCH_PROFILE
})

export function validateInformationAction(
  verificationCode: string,
  email?: string,
  phone?: string,
):
  AsyncAction<{}, { profileProvider: ProfileProvider; }> {
  return async (dispatch, _getState, { profileProvider }) => {
    dispatch({ type: VALIDATE_INFORMATION })
    try {
      const { authentication } = _getState() as AppState
      await profileProvider.validateInformation(verificationCode, email, phone)
      dispatch({ type: SUCCESS_VALIDATE_INFORMATION })

      if (authentication.currentUser?.email !== email
        || authentication.currentUser?.phone !== phone) {
        dispatch(setForceUpdateCurrentUserAction())
      }
    } catch (error) {
      if (error instanceof TheiaError) {
        dispatch({ type: FAILURE_VALIDATE_INFORMATION, error })
        Sentry.captureException(new Error(`Failure validating email. ${error.statusCode} - ${error.message} - ${error.friendlyMessageTitle} - ${error.friendlyMessage}`))
      }
    }
  }
}

export const clearValidateInformationErrorsAction = ()
: ClearValidateInformationErrorsActionType => ({
  type: CLEAR_VALIDATE_INFORMATION_ERRORS
})

export const promptValidateEmailModalAction = (
  isFromProfileEdit: boolean
): PromptValidateEmailModalActionType => ({
  type: PROMPT_VALIDATE_EMAIL_MODAL,
  isFromProfileEdit
})

export const suppressValidateEmailModalAction = (): SuppressValidateEmailModalActionType => ({
  type: SUPPRESS_VALIDATE_EMAIL_MODAL,
})

export const clearValidatePhoneErrorsAction = (): ClearValidatePhoneErrorsActionType => ({
  type: CLEAR_VALIDATE_PHONE_ERRORS
})

export const promptValidatePhoneModalAction = (
  isFromProfileEdit: boolean
): PromptValidatePhoneModalActionType => ({
  type: PROMPT_VALIDATE_PHONE_MODAL,
  isFromProfileEdit
})

export const suppressValidatePhoneModalAction = (): SuppressValidatePhoneModalActionType => ({
  type: SUPPRESS_VALIDATE_PHONE_MODAL
})
