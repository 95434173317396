/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

export type ToastInfos = {
  iconType: string;
  iconColor: string;
  message: string;
  timer: number;
  id: string;
  type: ToastType;
}

export enum NotificationMessage {
  DATA_UPDATED_SUCCESSFULLY = 'Dados atualizados com sucesso!',
  HEALTHPLAN_REGISTERED_SUCCESSFULLY = 'Plano cadastrado com sucesso!',
  DATA_SENT_SUCCESSFULLY = 'Dados enviados com sucesso!',
  ERROR_TRY_AGAIN = 'Desculpe, houve um erro ao salvar. Por favor tente novamente.',
  ERROR_RESENDING = 'Erro ao reenviar, por favor tente novamente.'
}
