/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Loader } from 'theia-web-ds'
import { bindActionCreators, Dispatch } from 'redux'
import Lottie from 'react-lottie'
import { useLocation } from 'react-router-dom'
import { AppState } from '../../apps/main/store'
import checkHealthInsurance from '../../animations/checking_card.json'
import AppMainContainerOff from '../AppMainContainerOff'
import { updateCurrentUserInfosAction } from '../../state/authentication/main/actions'
import PositiveHealthInsurance from './PositiveHealthInsurance'
import './HealthInsuranceFeedback.scss'
import NotAcceptedHealthInsurance from './NotAcceptedHealthInsurance'

interface Props {
  insurancePlanCovered: any;
  healthInsurancePlan: any;
  isLoading: boolean;
  onUpdateCurrentUser: () => void;
  isSuccess: boolean;
}

export interface ConvenioFeedbackParams {
  companyName: string;
}

function HealthInsuranceFeedback({
  insurancePlanCovered,
  healthInsurancePlan,
  isLoading = true,
  onUpdateCurrentUser,
  isSuccess
}: Props) {
  const params = useLocation().state as ConvenioFeedbackParams || {}
  const companyName = params.companyName || undefined
  const [healthInsuranceCovered, setHealthInsuranceCovered] = useState(false)
  const [isVisibleFeedback, setIsVisibleFeedback] = useState(false)
  const lottieCheckingHealthInsurance = {
    loop: true,
    autoplay: true,
    animationData: checkHealthInsurance,
  }

  useEffect(() => {
    if (isSuccess) {
      onUpdateCurrentUser()
    }
  }, [isSuccess])

  useEffect(() => {
    const timer: NodeJS.Timeout = setTimeout(() => {
      if (healthInsurancePlan) setIsVisibleFeedback(true)
    }, 6000)
    return () => clearTimeout(timer)
  }, [healthInsurancePlan])

  useEffect(() => {
    if (insurancePlanCovered) {
      setHealthInsuranceCovered(true)
    } else {
      setHealthInsuranceCovered(false)
    }
  }, [insurancePlanCovered])

  return (
    <AppMainContainerOff hasNewLayout={!isVisibleFeedback} extraClassOuter={isVisibleFeedback ? 'container-outer' : ''} extraClassInner="fade-in" extraClassInnerContainer="container-inner">
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Loader />
        </div>
      ) : (
        <div className="px-4">
          {isVisibleFeedback && healthInsuranceCovered && (
            <PositiveHealthInsurance />
          )}
          {isVisibleFeedback && !healthInsuranceCovered && (
            <NotAcceptedHealthInsurance />
          )}
          {!isVisibleFeedback && (
            <div className="flex flex-col items-center justify-center h-full">
              <Lottie
                options={lottieCheckingHealthInsurance}
                isClickToPauseDisabled
                ariaRole=""
                height={192}
                width={192}
              />
              <p className="text-textPrimary text-textMedium w-4/5 text-center">
                Falando com a {companyName}...
              </p>
            </div>
          )}
        </div>
      )}
    </AppMainContainerOff>
  )
}

const mapStateToProps = ({
  onboarding, authentication
}: AppState) => ({
  insurancePlanCovered: authentication.currentUser?.healthInsurancePlanData.insurancePlanCovered,
  healthInsurancePlan: authentication.currentUser?.healthInsurancePlanData.healthInsurancePlan,
  isLoading: onboarding.isLoading,
  isSuccess: onboarding.isSuccess
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  onUpdateCurrentUser: updateCurrentUserInfosAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthInsuranceFeedback)
