/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { InviteInfo } from './Invite'
import { ConsultantMoment } from './Profile'
import { LocalDate } from './utils/Date'
import { HealthInsurancePlan as HealthIsurancePlanType } from './Healthplan'
import { PostStatusType } from './Status'
import { UserLoginError } from './errors/ApiResponseErrors'

export type BookingsInfo = {
  maxBookings: number;
  currentBookings: number;
  remainingBookings: number;
  startDateNextValidPeriod?: string;
}

export type UserPlan = {
  name: string;
  active: boolean;
  paymentMethodAdded: boolean;
  cardLastDigits: string;
  cardBrand: string;
  nextPaymentDate: LocalDate;
  trialFinalDate: string;
  planType: string;
}

export type HealthInsurancePlan = {
  hasHealthInsurancePlan: boolean;
  benefits: any;
  healthInsurancePlan: HealthIsurancePlanType | null;
  insurancePlanCovered: boolean | null;
}

export type AppointedSpecialists = {
  category: string;
  specialistAvatarPictureUrl: string;
  specialistId: string;
  specialistName: string;
  specialistPhone: string;
  specialistPictureUrl: string;
}

export type User = {
  id: string;
  channelSid: string;
  twilioIdentity: string;
  timezone: string;
  locale: 'pt-BR';
  paymentStatus: PaymentStatus;
  name: string;
  email: string;
  phone: string;
  birthDate: string;
  pictureUrl: string | null;
  trialDaysRemaining: number;
  companyName: string;
  bookingsInfo: BookingsInfo;
  inviteInfo: InviteInfo;
  companyBusinessType: string;
  companyBenefitType: string;
  isTest: boolean;
  canGoInPerson: boolean;
  plan: UserPlan;
  document: string;
  created: string;
  gender: string;
  pregnancyMoment: ConsultantMoment;
  healthInsurancePlanData: HealthInsurancePlan;
  pregnancyMomentText?: string;
  expectedBabyBirthDate?: number;
  lastPeriodStartDate?: number;
  city?: string;
  nationalIdentityCard?: string;
  stateAbbreviation?: string;
  address: {
    city: string;
    complement: string;
    district: string;
    localization: string;
    name: string;
    number: string;
    postalCode: string;
    state: string;
    stateAbbreviation: string;
  };
  appointedSpecialists: Array<AppointedSpecialists> | [];
}

export enum PaymentStatus {
  Trial = 'Trial',
  Pending = 'Pending',
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Blocked = 'Blocked',
  Reviewing = 'Reviewing'
}

export interface UserCredentials {
  accessToken: string;
  refreshToken: string;
}

export interface SignUpResponse {
  accessToken: string;
  refreshToken: string;
  consultantId: string;
}

export interface OldUserCredentials {
  token: string;
}

export interface LoginPayload {
  password: string;
  login: string;
}

export interface SignUpPayload {
  name: string;
  email: string;
  phone: string;
  notificationEmail: string;
  password: string;
  privacyPolicyOptIn: boolean;
  benefitByCompany: boolean;
  canGoInPerson: boolean;
  alreadySentToMarketing: boolean;
  marketingTag: string;
  pregnancyDate: {
    pregnancyDueDate: string | null
    firstDayLastPeriod: string | null
  };
  pregnancyMoment: string | null;
  termIds: Array<string>;
}

export interface UserConfirmationInfos {
  email?: {
    value: string;
    confirmed: boolean;
  }
  phone?: {
    value: string;
    confirmed: boolean;
  }
}

export interface VerifiedSignUpPayload {
  name: string;
  email: string;
  phone: string;
  password: string;
  verificationCode: string;
  pregnancyDate: {
    pregnancyDueDate: string | null
    firstDayLastPeriod: string | null
  };
  pregnancyMoment: string | null;
  termIds: Array<string>;
}

export interface UserProvider {
  getCurrentUser(token?: string): Promise<User>;
  postRecaptchaGoogle(token: string): Promise<void>;
  postLoginUnlock(token: string): Promise<void>;
  postEmailUnlock(login: string): Promise<void>;
  postLogin(payload: LoginPayload): Promise<UserCredentials>;
  handleRefreshToken(refreshToken: string): Promise<UserCredentials>;
  handleSignUp(payload: SignUpPayload): Promise<SignUpResponse>;
  handlePasswordRecovery(email: string): Promise<void>;
  logout(refreshToken: string): Promise<void>;
  verifySignUpData(payload: SignUpPayload): Promise<void>;
  verifiedSignUp(signUpData: VerifiedSignUpPayload): Promise<SignUpResponse>;
  getUserConfirmationInfos(): Promise<UserConfirmationInfos>;
  sendVerificationCode(email?: string, phone?: string): Promise<void>;
  sendSignUpVerificationCode(email: string): Promise<void>;
}
export interface UserLoginStatus extends PostStatusType {
  errorData?: UserLoginError;
}

export const TOKEN_REFRESH = 'refreshToken'
export const TOKEN_ACCESS = 'accessToken'
export const TOKEN_OLD = 'token'

export enum UnauthenticatedModalError {
  INACTIVITY,
  GENERAL_ERROR
}
