import React from 'react'
import './Modal.scss'

interface Props {
  children: React.ReactNode;
  extraInnerClass?: string;
  mobileFullModal?: boolean;
}

export default function Modal({
  children,
  extraInnerClass,
  mobileFullModal
}: Props) {
  return (
    <div
      role="dialog"
      className={`modal ${mobileFullModal ? 'full-mobile' : ''}`}
      aria-modal="true"
    >
      <div className={`${mobileFullModal ? 'modal__inner--full-mobile' : 'modal__inner'} ${extraInnerClass}`}>
        {children}
      </div>
    </div>
  )
}
