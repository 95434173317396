import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  RegularButton,
  ErrorMessage,
  Button
} from 'theia-web-ds'
import { changeCouponValueAction, cleanPromotionalCodeAction, fetchPromotionalPriceAction } from '../../state/payment/actions'
import { AppDispatch } from '../../state/utils'
import './CouponForm.scss'
import { AppState } from '../../apps/main/store'
import { eventTrack } from '../../../eventGenerate'

interface Props {
  changeCouponValue: (couponValue?: string) => void;
  couponValue?: string;
  fetchPromotionalPrice: (codeCoupon?: string, id?: string) => void;
  cleanPromotionalCode: () => void;
  productId: string;
  promotionalCodeHasError?: boolean;
  textButton?: string;
  extraClass?: string;
  showButton?: boolean;
  initialCouponValue?: string;
  loadingPromotionalCode: boolean;
}

function CouponForm({
  changeCouponValue,
  couponValue,
  fetchPromotionalPrice,
  cleanPromotionalCode,
  productId,
  promotionalCodeHasError,
  textButton,
  extraClass,
  showButton = true,
  initialCouponValue,
  loadingPromotionalCode,
}: Props) {
  const [couponFieldVisible, setCouponFieldVisible] = useState(false)
  let inputCoupon: any
  function getCouponElement(): HTMLInputElement {
    return document.getElementById('codeCoupon') as HTMLInputElement
  }

  useEffect(() => {
    if (initialCouponValue && initialCouponValue !== '') {
      inputCoupon = getCouponElement()
      inputCoupon.value = initialCouponValue
      changeCouponValue(initialCouponValue)
      fetchPromotionalPrice(initialCouponValue, productId)
    }
  }, [initialCouponValue])

  function onShowCouponCodeField() {
    if (couponFieldVisible) {
      cleanPromotionalCode()
    }
    setCouponFieldVisible(!couponFieldVisible)
  }

  function getEmptyCouponValue() {
    inputCoupon = getCouponElement()
    if (inputCoupon.value === '') {
      cleanPromotionalCode()
    }
  }

  function getPromotionalPrice() {
    eventTrack('Inseriu Código de Desconto')
    inputCoupon = getCouponElement()
    changeCouponValue(inputCoupon.value)
    fetchPromotionalPrice(inputCoupon.value, productId)
  }

  return (
    <>
      {showButton
       && (
         <div className="flex items-center justify-center pt-6">
           <Button
             type="button"
             className={`${extraClass} text-textSmaller underline text-primary float-right font-light`}
             height="auto"
             onClick={onShowCouponCodeField}
           >
             {textButton}
           </Button>
         </div>
       )}
      {(couponFieldVisible || !showButton) && (
        <>
          <div className="flex items-center justify-center pt-4 couponForm">
            <input
              placeholder="*Digite o código aqui"
              type="text"
              name="codeCoupon"
              id="codeCoupon"
              className="coupon-input"
              onChange={getEmptyCouponValue}
            />
            <RegularButton
              type="button"
              variant="subtle"
              maxWidth="25%"
              onClick={getPromotionalPrice}
              isSubmitting={loadingPromotionalCode}
              label="Aplicar"
            />
          </div>
          {promotionalCodeHasError && couponValue && couponValue !== '' && (
            <ErrorMessage error="Código não encontrado" />
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ payment }: AppState) => ({
  couponValue: payment.couponValueFromUser,
  promotionalCodeHasError: (
    !payment.loadingPromotionalCode
    && payment.promotionalCodeError
    && payment.promotionalCodeError?.message !== ''
  ),
  loadingPromotionalCode: payment.loadingPromotionalCode,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeCouponValue: (couponValue?: string) => {
    dispatch(changeCouponValueAction(couponValue))
  },
  fetchPromotionalPrice: (codeCoupon?: string, id?: string) => {
    dispatch(fetchPromotionalPriceAction(codeCoupon, id))
  },
  cleanPromotionalCode: () => {
    dispatch(cleanPromotionalCodeAction())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponForm)
