import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CardPost, SimpleCarousel } from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import { Post } from '../../domain/Content'
import { fetchPostAction } from '../../state/content/actions'
import { AppDispatch } from '../../state/utils'
import { notUndefined } from '../../utils/helpers'
import { getPostsSlugByWeek, hasTagOrSlug, normalizeStringToSlug } from './helpers'
import { ChildrenSummary } from '../../domain/PediatricFlow'
import './WeekContent.scss'
import { CONTENT } from '../../routes/RoutesConstants'

interface Props {
  selectedWeekName?: string;
  posts: Array<Post>;
  showPediatricContent?: boolean;
  childrenSummary?: ChildrenSummary;
  isGettingChildrenSummary: boolean;
  fetchPost: (id: string) => void;
}

function WeekContent({
  selectedWeekName,
  posts,
  showPediatricContent,
  childrenSummary,
  isGettingChildrenSummary,
  fetchPost
}: Props) {
  const history = useHistory()
  const [filteredPosts, setFilteredPosts] = useState<Post[]>([])
  const [slugName, setSlugName] = useState<string>('')

  function getPostById(post: Post) {
    fetchPost(post.id)
    history.push(`${CONTENT}/${post.slug}/${post.id}`)
  }

  useEffect(() => {
    let newSlugName = ''
    if (selectedWeekName && !showPediatricContent) {
      newSlugName = getPostsSlugByWeek(selectedWeekName)
    }
    if (showPediatricContent && !childrenSummary?.currentMonth) {
      newSlugName = 'bebe-1-mes'
    }
    if (showPediatricContent && childrenSummary?.currentMonth) {
      newSlugName = normalizeStringToSlug(childrenSummary?.currentMonth)
    }
    setSlugName(newSlugName)
    const postsFilteredByTag = posts.filter((p) => hasTagOrSlug(p.tags, newSlugName))
    const featuredPosts = postsFilteredByTag.filter((p) => p.featured)
    let newPosts;
    if (featuredPosts.length > 0) {
      newPosts = featuredPosts.concat(postsFilteredByTag.filter((p) => !p.featured))
    } else {
      newPosts = postsFilteredByTag
    }
    setFilteredPosts(newPosts)
  }, [selectedWeekName, childrenSummary])

  if (!selectedWeekName && !showPediatricContent) {
    return null
  }

  function renderPost(post: Post) {
    const slug = (post.tags.filter((tag) => tag.slug === slugName) || [])[0]
    return (
      <button
        onClick={() => getPostById(post)}
        className="carousel-content-card"
        type="button"
      >
        <CardPost
          featureImage={post.feature_image}
          category={slug.name}
          title={post.meta_title}
          description={post.meta_description}
          fixedWidth
          isLoading={isGettingChildrenSummary}
        />
      </button>
    )
  }

  return (
    <div className="content-week-container">
      <p className="content-week-title">Conteúdo pra você</p>
      {filteredPosts.length === 0 ? (
        <p className="content-week-empty">
          Não há conteúdos para essa semana.
        </p>
      ) : (
        <div className="carousel-content-container">
          <SimpleCarousel
            id={`carousel-content-${slugName}`}
            list={filteredPosts}
            carouselId="posts"
            onRenderElement={(post: Post) => renderPost(post)}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({
  content, pediatricFlow
}: AppState) => {
  const allPosts = content.allPosts.map((post: any) => post)
    .filter(notUndefined)
  return {
    posts: allPosts,
    isFetchingAllPosts: content.isFetchingAll,
    childrenSummary: pediatricFlow.childrenSummary,
    isGettingChildrenSummary: pediatricFlow.isGettingChildrenSummary
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchPost: (id: string) => { dispatch(fetchPostAction(id)) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeekContent)
