/* eslint-disable no-new */
import icon from '../../../public/images/favicon.png'

function isNewNotificationSupported() {
  if (!window.Notification || !Notification.requestPermission) {
    return false;
  }
  if (Notification.permission === 'granted') {
    throw new Error('You must only call this *before* calling Notification.requestPermission(), otherwise this feature detect would bug the user with an actual notification!');
  }
  try {
    new Notification('');
  } catch (e) {
    if (e.name === 'TypeError') {
      return false;
    }
  }
  return true;
}
export default class BrowserNotification {
  static sendNotification(body: string) {
    if (!window.Notification || window.Notification.permission !== 'granted') return
    if (window.Notification && Notification.permission === 'granted') {
      new Notification('Nova mensagem do especialista', { body, icon });
    } else if (isNewNotificationSupported()) {
      window.Notification.requestPermission();
    }
  }
}
