import React from 'react'
import { useHistory } from 'react-router-dom'
import Modal from '../common/Modal'
import HeaderModal from '../common/HeaderModal'
import ConfirmButton from '../common/buttons/ConfirmButton'
import { CONTENT } from '../../routes/RoutesConstants'

interface Props {
  visible: boolean;
}

function PermissionWebDeniedModal({ visible }: Props) {
  const history = useHistory()
  if (!visible) return null
  function goToContent() {
    history.push(`${CONTENT}/videochamada/5e2f1d8f8e2b6600389e5657`)
  }

  return (
    <Modal>
      <div className="flex flex-col text-center denied-modal">
        <HeaderModal className="mt-4 mb-4 modal__title">Ei, sua câmera e microfone estão com permissão negada</HeaderModal>
        <p className="text-textSecondary font-bold mb-2">É necessário que você ajuste esta configuração para que a consulta seja iniciada</p>

        <ConfirmButton onClick={() => goToContent()}>Como ajustar?</ConfirmButton>
      </div>
    </Modal>
  )
}

export default PermissionWebDeniedModal
