import { LocalDate } from '../../utils/LocalDate'
import ApiClient from './ApiClient'
import {
  Professional,
  ProfessionalProvider,
  ProfessionalListType,
  AvailabilitySlot,
  TimeSlot
} from '../../domain/Professional'
import { LocalAddress, LocalAddressType } from '../../utils/LocalAddress'
import { isProductionEnv } from '../../utils/helpers'

type TimeSlotResponse = {
  id: string;
  startDate: string;
  endDate: string;
  type: string;
  attendanceAddress: LocalAddressType;
  productId: string;
  paymentSlipAllowed: boolean;
  paymentSlipDueDate: number;
  pixAllowed: boolean;
  pixDueDate: number;
}

export default class ApiProfessionalProvider implements ProfessionalProvider {
  constructor(private apiClient: ApiClient) { }

  async getProfessionalSummary(id: string, token?: string): Promise<Professional> {
    const professionalSummary = await this.apiClient.getProfessionalSummary(id, token)

    return this.parseProfessional(professionalSummary)
  }

  async getProfessionalForRescheduling(id: string): Promise<Professional> {
    const professionalSummary = await this.apiClient.getProfessionalForRescheduling(id)

    return this.parseProfessional(professionalSummary)
  }

  async getProfessionalAvailability(
    chronosId: string,
    token?: string
  ): Promise<Array<AvailabilitySlot>> {
    const availability = await this.apiClient.getProfessionalAvailability(chronosId, token)
    const formattedAvailability = availability.map((slot: any) => ({
      ...slot,
      startDate: new LocalDate(slot.startDate),
      endDate: new LocalDate(slot.endDate),
      attendanceAddress: slot.attendanceAddress && new LocalAddress(
        slot.attendanceAddress
      )
    } as AvailabilitySlot))
    return formattedAvailability
  }

  async getProfessionals(token?: string): Promise<Array<ProfessionalListType>> {
    const response = await this.apiClient.getProfessionals(token)
    const professionals = isProductionEnv()
      ? response.filter((professional) => !professional.category.startsWith('Teste'))
      : response
    return professionals
  }

  private parseProfessional = (json: any): Professional => ({
    ...json,
    availabilityInfo: this.parseAvailabilityInfo(json.availabilityInfo),
    attendanceAddress: json.attendanceAddress && new LocalAddress(
      json.attendanceAddress
    )
  })

  private parseAvailabilityInfo = (json: any) => ({
    ...json,
    availability: this.parseAvailability(json?.availability || [])
  })

  private parseAvailability(availabilities: Array<TimeSlotResponse>): Array<TimeSlot> {
    if (availabilities.length > 0) {
      return availabilities.map((json: TimeSlotResponse) => ({
        ...json,
        startDate: new LocalDate(json.startDate),
        endDate: new LocalDate(json.endDate),
        attendanceAddress: json.attendanceAddress && new LocalAddress(
          json.attendanceAddress
        )
      } as TimeSlot))
    }
    return []
  }
}
