import * as Sentry from '@sentry/browser'
import { HttpClient } from '../../services/Http'
import { AsyncAction } from '../utils'
import {
  HealthInsuranceBenefits,
  HealthInsuranceCompany,
  HealthPlanData,
  HealthPlanFeedbackData,
  HealthplanService,
  PostUserHealthPlanType
} from '../../domain/Healthplan'
import { updateCurrentUserInfosAction } from '../authentication/main/actions'
import { UserProvider } from '../../domain/User'

export const REQUEST_HEALTH_INSURANCE_CIAS_PROFILE = 'REQUEST_HEALTH_INSURANCE_CIAS_PROFILE'
export const SUCCESS_HEALTH_INSURANCE_CIAS_PROFILE = 'SUCCESS_HEALTH_INSURANCE_CIAS_PROFILE'
export const FAILURE_HEALTH_INSURANCE_CIAS_PROFILE = 'FAILURE_HEALTH_INSURANCE_CIAS_PROFILE'
export const SELECT_HEALTH_INSURANCE_PROFILE = 'SELECT_HEALTH_INSURANCE_PROFILE'
export const POST_HEALTH_INSURANCE_PROFILE = 'POST_HEALTH_INSURANCE_PROFILE'
export const SUCCESS_POST_HEALTH_INSURANCE_PROFILE = 'SUCCESS_POST_HEALTH_INSURANCE_PROFILE'
export const FAILURE_POST_HEALTH_INSURANCE_PROFILE = 'FAILURE_POST_HEALTH_INSURANCE_PROFILE'
export const GO_BACK_TO_PROFILE_HEALTH_PLAN_UPDATE = 'GO_BACK_TO_PROFILE_HEALTH_PLAN_UPDATE'
export const GET_HEALTH_INSURANCE_PLAN = 'GET_HEALTH_INSURANCE_PLAN'
export const SUCCESS_GET_HEALTH_INSURANCE_PLAN = 'SUCCESS_GET_HEALTH_INSURANCE_PLAN'
export const FAILURE_GET_HEALTH_INSURANCE_PLAN = 'FAILURE_GET_HEALTH_INSURANCE_PLAN'

interface SelectHealthInsurancePlanActionType {
  type: typeof SELECT_HEALTH_INSURANCE_PROFILE;
  healthPlanFeedbackData: HealthPlanFeedbackData;
}

interface RequestHealthInsuranceCompaniesActionType {
  type: typeof REQUEST_HEALTH_INSURANCE_CIAS_PROFILE;
}

interface SuccessHealthInsuranceCompaniesActionType {
  type: typeof SUCCESS_HEALTH_INSURANCE_CIAS_PROFILE;
  companies: Array<HealthInsuranceCompany>;
}

interface FailureHealthInsuranceCompaniesActionType {
  type: typeof FAILURE_HEALTH_INSURANCE_CIAS_PROFILE;
}

interface PostHealthInsuranceActionType {
  type: typeof POST_HEALTH_INSURANCE_PROFILE;
}

interface SuccessPostHealthInsuranceActionType {
  type: typeof SUCCESS_POST_HEALTH_INSURANCE_PROFILE;
  insurancePlanCovered: boolean;
  benefits: Array<HealthInsuranceBenefits>;
  healthPlanData: PostUserHealthPlanType;
}

interface FailurePostHealthInsuranceActionType {
  type: typeof FAILURE_POST_HEALTH_INSURANCE_PROFILE;
}

interface GoBackToProfileAfterHealthPlanUpdateType {
  type: typeof GO_BACK_TO_PROFILE_HEALTH_PLAN_UPDATE;
}

interface GetHealthInsurancePlanActionType {
  type: typeof GET_HEALTH_INSURANCE_PLAN;
}

interface SuccessGetHealthInsurancePlanActionType {
  type: typeof SUCCESS_GET_HEALTH_INSURANCE_PLAN;
  healthInsurancePlan: HealthPlanData;
}

interface FailureGetHealthInsurancePlanActionType {
  type: typeof FAILURE_GET_HEALTH_INSURANCE_PLAN;
}

export type HealthPlanProfileTypes = SelectHealthInsurancePlanActionType |
RequestHealthInsuranceCompaniesActionType |
SuccessHealthInsuranceCompaniesActionType |
FailureHealthInsuranceCompaniesActionType |
PostHealthInsuranceActionType |
SuccessPostHealthInsuranceActionType |
FailurePostHealthInsuranceActionType |
GoBackToProfileAfterHealthPlanUpdateType |
GetHealthInsurancePlanActionType |
SuccessGetHealthInsurancePlanActionType |
FailureGetHealthInsurancePlanActionType

export const onSelectHealthInsuranceAction = (healthPlanFeedbackData: HealthPlanFeedbackData):
    SelectHealthInsurancePlanActionType => (
  { type: SELECT_HEALTH_INSURANCE_PROFILE, healthPlanFeedbackData }
)

export function getHealthInsuranceCompaniesAction():
  AsyncAction<{}, { healthplanService: HealthplanService }> {
  return async (dispatch, _getState, { healthplanService }) => {
    dispatch({ type: REQUEST_HEALTH_INSURANCE_CIAS_PROFILE })
    try {
      const companies = await healthplanService.getHealthInsuranceCompanies()
      dispatch({ type: SUCCESS_HEALTH_INSURANCE_CIAS_PROFILE, companies })
    } catch (error) {
      dispatch({ type: FAILURE_HEALTH_INSURANCE_CIAS_PROFILE })
      Sentry.captureException(new Error(`Failure to fetch health insurance companies - ${error.message}`))
    }
  }
}

export function postUserHealthInsurance(
  healthPlanData: PostUserHealthPlanType
): AsyncAction<{}, { healthplanService: HealthplanService, userProvider: UserProvider }> {
  return async (dispatch, _getState, { healthplanService }) => {
    dispatch({ type: POST_HEALTH_INSURANCE_PROFILE })
    try {
      const response = await healthplanService.postUserHealthInsurance(healthPlanData)
      dispatch({
        type: SUCCESS_POST_HEALTH_INSURANCE_PROFILE,
        insurancePlanCovered: response.insurancePlanCovered,
        benefits: response.benefits,
        healthPlanData
      })
      dispatch(updateCurrentUserInfosAction())
    } catch (error) {
      dispatch({ type: FAILURE_POST_HEALTH_INSURANCE_PROFILE })
      Sentry.captureException(new Error(`Failure to save user health insurance infos - ${error.message}`))
    }
  }
}

export const goBackToProfileAfterHealthPlanUpdateAction = ():
GoBackToProfileAfterHealthPlanUpdateType => ({
  type: GO_BACK_TO_PROFILE_HEALTH_PLAN_UPDATE
})

export function getHealthInsurancePlanDataAction():
  AsyncAction<{}, { httpClient: HttpClient;
    healthplanService: HealthplanService; }> {
  return async (dispatch, _getState, { healthplanService }) => {
    dispatch({ type: GET_HEALTH_INSURANCE_PLAN })
    try {
      const healthInsurancePlan: HealthPlanData = await
      healthplanService.getHealthInsurancePlanData()
      dispatch({
        type:
        SUCCESS_GET_HEALTH_INSURANCE_PLAN,
        healthInsurancePlan
      })
    } catch (error) {
      dispatch({ type: FAILURE_GET_HEALTH_INSURANCE_PLAN })
      Sentry.captureException(new Error(`Failure to get health plan coverage infos - ${error.message}`))
    }
  }
}
