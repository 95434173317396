export {}

export const infoColor = '#505050'
export const greyColor = '#818181'
export const lightGreyColor = '#C7C7C7'
export const mediumGreyColor = '#585858'
export const darkGreyColor = '#303030'
export const lightGreenColor = '#ABF5FF'
export const darkGreenColor = '#1C8020'
export const mediumPurpleColor = '#9A4178'
export const lightPurpleColor = '#B14582'
export const beigeColor = '#FFFAFA'
export const lightBeigeColor = '#FFFCFC'
export const mediumBeigeColor = '#FFF7ED'
export const darkBeigeColor = '#FFE4C1'
export const bgBarError = '#F8EBED'
export const bgBarSuccess = '#E8F2E9'
export const bgBox = '#FFFFFF'
export const bgBoxSecondary = '#F0F1FB'
export const bgCanvas = '#F9FAFF'
export const bgBar = '#F5F8FC'
export const bgButton = '#F0F1FB'
export const bgDisable = '#DEDEDE'
export const textPrimary = 'rgba(0, 0, 0, 0.87)'
export const textSecondary = 'rgba(0, 0, 0, 0.6)'
export const textDisable = 'rgba(0, 0, 0, 0.42)'
export const primary = '#9A4178'
export const primaryLight = '#B14582'
export const primaryDark = '#723965'
export const primaryGradient = 'linear-gradient(180deg, #B14582 0%, #9A4178 100%)'
export const success = '#1C8020'
export const error = '#BB3949'
export const warning = '#FF7951'
export const overlayButton = 'rgba(88, 44, 199, 0.1)'
export const overlay = 'rgba(0, 0, 0, 0.05)'
export const overlayLight = 'rgba(0, 0, 0, 0.04)'
export const overlayDark = 'rgba(255, 255, 255, 0.24)'
export const overlayDarkest = 'rgba(0, 0, 0, 0.8)'
export const divider = '#EEEEEE'
export const overlayBrandHover = 'rgba(154, 65, 120, 0.05)'
export const overlayBrandFocus = 'rgba(154, 65, 120, 0.11)'
export const overlayBrandPressed = 'rgba(154, 65, 120, 0.08)'
export const overlayLightHover = 'rgba(0, 0, 0, 0.05)'
export const overlayLightFocus = 'rgba(0, 0, 0, 0.11)'
export const overlayLightPressed = 'rgba(0, 0, 0, 0.08)'
export const overlayPressed = 'rgba(154, 65, 120, 0.08)'
export const overlayHover = 'rgba(154, 65, 120, 0.05)'
export const overlayFocus = 'rgba(154, 65, 120, 0.11)'
export const overlayFocusLight = 'rgba(0, 0, 0, 0.11)'
export const overlayPressedLight = 'rgba(0, 0, 0, 0.08)'
export const purpleSecondary = '#8A46B7'
export const primaryColor = '#9A4178'
export const secondary = '#B14582'
export const lightPinkColor = '#FFC4CD'
export const mediumOrangeColor = '#FAA084'
export const secondaryColor = '#B14582'
export const darkOrangeColor = '#FF584A'
export const mediumPinkColor = '#FF7C90'
export const darkPinkColor = '#ED1D53'
export const mediumDarkGreyColor = '#424347'
