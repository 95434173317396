import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../apps/main/store'
import { feedbackStepTypes } from '../../state/feedback/reducers'
import FeedbackModal from './FeedbackModal'
import NegativeFeedbackModal from './NegativeFeedbackModal'

interface Props {
  step: feedbackStepTypes;
}

function FeedbackModalsGroup({
  step
}: Props) {
  if (step === 'closed') return null

  return (
    <>
      {step === 'prompt' && <FeedbackModal />}
      {step === 'feedback' && <NegativeFeedbackModal />}
    </>
  )
}

const mapStateToProps = ({ feedback }: AppState) => ({
  step: feedback.step
})

export default connect(
  mapStateToProps,
)(FeedbackModalsGroup)
