/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, FormikProps } from 'formik'
import { bindActionCreators } from 'redux'
import {
  Loader, NewestCheckbox, RegularButton, MaskedFormInput, FormInput
} from 'theia-web-ds'
import AppMainContainerOff from '../AppMainContainerOff'
import './SalesOnboarding.scss'
import StepperBar from '../common/StepperBar'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import HealthDataModal from '../authentication/HealthDataModal'
import ErrorModal from '../common/ErrorModal'
import PrivacyModal from '../authentication/PrivacyModal'
import TermsModal from '../authentication/TermsModal'
import {
  enableHealthPlanModalAction,
  handleSignUpAction,
  loginAction,
  promptHealthDataModalAction,
  promptPrivacyModalAction,
  promptTermsModalAction,
  verifySignUpDataAction
} from '../../state/authentication/main/actions'
import {
  emailValidationRegex,
  validationPhoneRegex,
  phoneMask
} from '../../utils/helpers'
import { eventPage, eventTrack, identifyUser } from '../../../eventGenerate'
import ValidatePasswordInput from '../common/ValidatePasswordInput'
import { getCurrentTermsAction } from '../../state/terms/actions'
import { CurrentTerm } from '../../domain/Terms'
import { FetchStatusType, GenericStatusType } from '../../domain/Status'
import { SignUpPayload, UserLoginStatus } from '../../domain/User'
import { SHOW_EMAIL_VALIDATION_FLOW } from '../../state/remoteConfig'
import { EMAIL_VALIDATION } from '../../utils/EventCategories'
import { CONFIRMAR_CONTA, LOCALIZACAO } from '../../routes/RoutesConstants'

export interface SignUpFormValues {
  email: string;
  password: string;
  passwordConfirm: string;
  policy: boolean;
  terms: boolean;
  health: boolean;
  name: string;
  phone: string;
  marketingTag: string;
}

function renderOnboardingSignUpForm(
  {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    errors,
    setSubmitting,
    validateField
  }: FormikProps<SignUpFormValues>,
  termIds: Array<string>,
  signUpStatus: UserLoginStatus,
  verifySignUpDataStatus: GenericStatusType,
  consultantId: string,
  login: () => void,
  enableModal: () => void,
  promptPrivacyModal: () => void,
  promptTermsModal: () => void,
  promptHealthDataModal: () => void
) {
  const history = useHistory()

  useEffect(() => {
    if (!SHOW_EMAIL_VALIDATION_FLOW && signUpStatus.isPost) {
      setSubmitting(false)
      const moment = localStorage.getItem('salesOnboardingMoment') || ''
      const infosToRudder = {
        id: consultantId,
        email: values.email,
        moment
      }
      login()
      identifyUser(infosToRudder)
      enableModal()
      history.push(LOCALIZACAO)
      eventTrack('Cadastrou')
    }

    if (!SHOW_EMAIL_VALIDATION_FLOW && signUpStatus.errorPost) {
      setSubmitting(false)
    }
  }, [signUpStatus])

  useEffect(() => {
    if (SHOW_EMAIL_VALIDATION_FLOW && verifySignUpDataStatus.success) {
      eventTrack('email validacao enviado', {
        category: EMAIL_VALIDATION,
        email: values.email
      })
      history.push(CONFIRMAR_CONTA)
    }
    if (SHOW_EMAIL_VALIDATION_FLOW && verifySignUpDataStatus.error) {
      eventTrack('erro ao validar dados cadastrais', {
        category: EMAIL_VALIDATION,
        email: values.email,
        motivo: verifySignUpDataStatus.errorMessage?.friendlyMessage,
        plataforma: 'web'
      })
      setSubmitting(false)
    }
  }, [verifySignUpDataStatus])

  const canSubmit = (
    !!values.email
    && !!values.password
    && !!values.passwordConfirm
    && !errors.email
    && values.policy === true
    && values.terms === true
    && values.health === true
    && !!values.name
    && !!values.phone
    && !errors.email
    && !errors.phone
  )

  const onBlurEventTrack = (param: string) => {
    if (param === 'name') {
      eventTrack('Inseriu Nome Completo')
    }
    if (param === 'email') {
      eventTrack('Inseriu Email')
    }
    if (param === 'phone') {
      eventTrack('Inseriu Telefone')
    }
    if (param === 'password') {
      eventTrack('Inseriu Senha')
    }
    if (param === 'passwordConfirm') {
      eventTrack('Inseriu confirmação de senha')
    }
  }

  const handleFieldBlur = (param: string) => (event: React.FocusEvent<HTMLInputElement>) => {
    handleBlur(event)
    onBlurEventTrack(param)
  }

  function promptPrivacyModalAux(e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) {
    if (e.type === 'click' || (e.type === 'keydown' && (e as React.KeyboardEvent<HTMLButtonElement>).key === 'Enter')) {
      eventTrack('Abriu Políticas de Privacidade')
      e.preventDefault()
      promptPrivacyModal()
    }
  }

  function promptTermsModalAux(e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) {
    if (e.type === 'click' || (e.type === 'keydown' && (e as React.KeyboardEvent<HTMLButtonElement>).key === 'Enter')) {
      eventTrack('Abriu Termos de Uso')
      e.preventDefault()
      promptTermsModal()
    }
  }

  function promptTermsModalHealth(e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) {
    if (e.type === 'click' || (e.type === 'keydown' && (e as React.KeyboardEvent<HTMLButtonElement>).key === 'Enter')) {
      eventTrack('Abriu Termo de Coleta de Dados de Saúde')
      e.preventDefault()
      promptHealthDataModal()
    }
  }

  function handleOnSubmit(e: any) {
    e.preventDefault()
    handleSubmit()
    eventTrack('Clicou Cadastrar')
  }

  return (
    <AppMainContainerOff>
      <div className="sales-onboarding-container">
        <StepperBar totalSteps={2} currentStep={2} showNumbers={false} />
        <h1 className="text-primary font-medium text-headlineMedium pt-8 pb-4">
          Criar seu login de acesso
        </h1>
        <div className="signup">
          <form onSubmit={handleOnSubmit} noValidate className="signup-form" name="signUpForm">
            <div className="mb-4">
              <FormInput
                placeholder="Digite aqui seu nome completo"
                label="Qual é o seu nome completo? *"
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleFieldBlur('name')}
                errorMessage={touched.name ? errors.name : ''}
                id="name"
              />
            </div>
            <div className="mb-4">
              <MaskedFormInput
                placeholder="(00) 00000-0000"
                label="Qual é seu telefone para contato? *"
                name="phone"
                errorMessage={touched.phone ? errors.phone : ''}
                id="phone"
                onBlur={handleFieldBlur('phone')}
                type="tel"
                value={values.phone}
                onChange={handleChange}
                mask={phoneMask}
              />
            </div>
            <div className="mb-4">
              <FormInput
                label="Qual é seu e-mail para login e notificações *"
                placeholder="Digite aqui seu e-mail"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleFieldBlur('email')}
                errorMessage={touched.email ? errors.email : ''}
                id="email"
              />
            </div>
            <div className="mb-4">
              <ValidatePasswordInput
                password={values.password}
                onBlur={handleBlur}
                error={errors.password}
                value={values.password}
                onChange={handleChange}
                errorMessage={touched.password && !values.password ? errors.password : ''}
                passwordLabel="Senha *"
                eventText="Inseriu Senha"
                onValidateField={() => validateField('password')}
              />
            </div>
            <div className={`${errors.password !== undefined ? 'animated' : ''} mb-4 confirm-password-input`}>
              <FormInput
                label="Confirme sua senha *"
                placeholder="Digite a senha novamente"
                name="passwordConfirm"
                maxLength={64}
                value={values.passwordConfirm}
                onChange={handleChange}
                onBlur={handleFieldBlur('passwordConfirm')}
                errorMessage={touched.passwordConfirm ? errors.passwordConfirm : ''}
                id="passwordConfirm"
                iconSize="24px"
                isPassword
              />
            </div>
            <div className="flex items-center mb-2">
              <NewestCheckbox
                name="policy"
                value={values.policy.toString()}
                onClick={() => setFieldValue('policy', !values.policy)}
                onChange={() => eventTrack('Check Politicas de Privacidade')}
                componentExtraClass="terms-checkbox"
                disabled={!termIds.length}
                defaultChecked={values.policy}
              />
              <p>
                Li e estou de acordo com as&nbsp;
                <span
                  className="font-bold"
                  id="policyPrivacySalesOnboardingSignUpId"
                  role="button"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => promptPrivacyModalAux(e)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => promptPrivacyModalAux(e)}
                  tabIndex={0}
                >
                  políticas de privacidade
                </span>.
              </p>
              <p className="help">{errors.policy}</p>
            </div>

            <div className="flex items-center mb-2">
              <NewestCheckbox
                name="terms"
                value={values.terms.toString()}
                onClick={() => setFieldValue('terms', !values.terms)}
                onChange={() => eventTrack('Check Termos de Uso')}
                componentExtraClass="terms-checkbox"
                disabled={!termIds.length}
                defaultChecked={values.terms}
              />
              <p>
                Li e estou de acordo com os&nbsp;
                <span
                  className="font-bold"
                  id="termsSalesOnboardingSignUpId"
                  role="button"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => promptTermsModalAux(e)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => promptTermsModalAux(e)}
                  tabIndex={0}
                >
                  termos de uso
                </span>.
              </p>
              <p className="help">{errors.terms}</p>
            </div>

            <div className="flex items-center mb-2">
              <NewestCheckbox
                name="health"
                value={values.health.toString()}
                onClick={() => setFieldValue('health', !values.health)}
                onChange={() => eventTrack('Check Termo de Coleta de Dados de Saúde')}
                componentExtraClass="terms-checkbox"
                disabled={!termIds.length}
                defaultChecked={values.health}
              />
              <p>
                Li, estou de acordo e consinto com o&nbsp;
                <span
                  className="font-bold"
                  id="healthDataId"
                  role="button"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => promptTermsModalHealth(e)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => promptTermsModalHealth(e)}
                  tabIndex={0}
                >
                  termo de coleta de dados de saúde
                </span>.
              </p>
              <p className="help">{errors.health}</p>
            </div>

            <div className="signup-button-confirm sm:mt-4">
              <RegularButton
                type="submit"
                label={SHOW_EMAIL_VALIDATION_FLOW ? 'Continuar' : 'Criar login'}
                disabled={isSubmitting || !canSubmit}
                isSubmitting={isSubmitting}
                maxWidth="100%"
              />
            </div>
          </form>
        </div>
      </div>
    </AppMainContainerOff>
  )
}

interface Props {
  defaultErrorVisible: boolean;
  promptPrivacyModal: () => void;
  promptTermsModal: () => void;
  promptHealthDataModal: () => void;
  login: () => void;
  enableModal: () => void;
  getCurrentTerms: () => void;
  termsPolicy: Array<CurrentTerm>;
  privacyPolicy: Array<CurrentTerm>;
  healthDataPolicy: Array<CurrentTerm>;
  termIds: Array<string>;
  termsFetchStatus: FetchStatusType;
  handleSignUp: (payload: SignUpPayload) => void;
  signUpStatus: UserLoginStatus;
  verifySignUpDataStatus: GenericStatusType;
  consultantId: string;
  signUpUserData?: SignUpPayload;
  verifySignUpData: (signUpData: SignUpPayload) => void;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().trim()
    .email('E-mail inválido')
    .matches(emailValidationRegex, 'Digite um e-mail válido pra continuar')
    .required('Email obrigatório'),
  password: Yup.string()
    .min(8)
    .matches(/[A-Z]/)
    .matches(/[a-z]/)
    .matches(/[0-9]/)
    .matches(/[!@#$%^&*(),.?":{}|<>'-/\\;+_=[\]]/)
    .required('O campo de senha é obrigatório'),
  name: Yup.string()
    .required('Nome obrigatório'),
  phone: Yup.string()
    .required('Celular obrigatório')
    .matches(validationPhoneRegex, 'Celular inválido'),
  passwordConfirm: Yup.string()
    .required('Confirmação de senha obrigatória')
    .oneOf([Yup.ref('password')], 'As senhas devem ser iguais')
})

function SalesOnboardingSignUp({
  promptPrivacyModal,
  promptTermsModal,
  defaultErrorVisible,
  login,
  enableModal,
  getCurrentTerms,
  termsPolicy,
  privacyPolicy,
  healthDataPolicy,
  termIds,
  promptHealthDataModal,
  termsFetchStatus,
  handleSignUp,
  signUpStatus,
  verifySignUpDataStatus,
  consultantId,
  signUpUserData,
  verifySignUpData
}: Props) {
  const [loadingContent, setLoadingContent] = useState(true)

  const initialValues: SignUpFormValues = {
    email: signUpUserData?.email || '',
    password: signUpUserData?.password || '',
    passwordConfirm: signUpUserData?.password || '',
    policy: signUpUserData?.privacyPolicyOptIn || false,
    terms: signUpUserData?.privacyPolicyOptIn || false,
    health: signUpUserData?.privacyPolicyOptIn || false,
    name: signUpUserData?.name || '',
    phone: signUpUserData?.phone || '',
    marketingTag: signUpUserData?.marketingTag || ''
  }

  const onSubmit = (values: SignUpFormValues) => {
    const pregnancyMoment = localStorage.getItem('salesOnboardingMoment')
    const payload = {
      name: values.name,
      email: values.email.toLowerCase().trim(),
      phone: values.phone,
      notificationEmail: values.email.toLowerCase().trim(),
      password: values.password,
      privacyPolicyOptIn: values.policy && values.terms && values.health,
      benefitByCompany: true,
      canGoInPerson: true,
      alreadySentToMarketing: true,
      marketingTag: values.marketingTag,
      pregnancyDate: {
        pregnancyDueDate: localStorage.getItem('pregnancyDueDate') || null,
        firstDayLastPeriod: localStorage.getItem('lastPeriod') || null
      },
      pregnancyMoment,
      termIds
    }
    if (SHOW_EMAIL_VALIDATION_FLOW) {
      verifySignUpData(payload)
    } else {
      handleSignUp(payload)
    }
  }

  useEffect(() => {
    eventPage('onboarding', 'Login de Acesso')
    getCurrentTerms()
  }, [])

  useEffect(() => {
    if (termsFetchStatus.isFetch || termsFetchStatus.errorFetch) {
      setLoadingContent(false)
    }
  }, [termsFetchStatus])

  if (termsFetchStatus.isFetching || loadingContent) {
    return (
      <AppMainContainerOff>
        <div className="flex h-full justify-center items-center form-status-container">
          <Loader />
        </div>
      </AppMainContainerOff>
    )
  }

  if (termsFetchStatus.errorFetch) {
    return (
      <AppMainContainerOff>
        <div className=" flex flex-col h-full justify-center items-center form-status-container">
          <p className="mb-4 text-center">
            Erro ao carregar as informações. Tente novamente.
          </p>
          <RegularButton
            label="Atualizar página"
            onClick={getCurrentTerms}
            btnSize="large"
            maxWidth="80%"
          />
        </div>
      </AppMainContainerOff>
    )
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => renderOnboardingSignUpForm(
          props,
          termIds,
          signUpStatus,
          verifySignUpDataStatus,
          consultantId,
          login,
          enableModal,
          promptPrivacyModal,
          promptTermsModal,
          promptHealthDataModal
        )}
      </Formik>
      <ErrorModal visible={defaultErrorVisible} />
      <PrivacyModal lastIdFocused="policyPrivacySalesOnboardingSignUpId" privacy={privacyPolicy} />
      <TermsModal lastIdFocused="termsSalesOnboardingSignUpId" terms={termsPolicy} />
      <HealthDataModal lastIdFocused="healthDataId" data={healthDataPolicy} />
    </>
  )
}

const mapStateToProps = ({ errorWithFriendlyMessage, terms, authentication }: AppState) => ({
  defaultErrorVisible: errorWithFriendlyMessage.visible,
  termsPolicy: terms.termsPolicy,
  privacyPolicy: terms.privacyPolicy,
  healthDataPolicy: terms.healthDataPolicy,
  termIds: terms.termIds,
  termsFetchStatus: terms.termsFetchStatus,
  signUpStatus: authentication.signUpStatus,
  consultantId: authentication.consultantId,
  verifySignUpDataStatus: authentication.verifySignUpDataStatus,
  signUpUserData: authentication?.signUpUserData,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  login: loginAction,
  promptPrivacyModal: promptPrivacyModalAction,
  promptTermsModal: promptTermsModalAction,
  promptHealthDataModal: promptHealthDataModalAction,
  enableModal: enableHealthPlanModalAction,
  getCurrentTerms: getCurrentTermsAction,
  handleSignUp: handleSignUpAction,
  verifySignUpData: verifySignUpDataAction
}, dispatch)

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SalesOnboardingSignUp)
)
