import React from 'react'
import { RegularButton, AccessibleModal } from 'theia-web-ds'

import './Terms.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DOMPurify from 'dompurify'
import { AppDispatch } from '../../state/utils'
import { suppressHealthDataModalAction } from '../../state/authentication/main/actions'
import { AppState } from '../../apps/main/store'
import { CurrentTerm } from '../../domain/Terms'

interface Props {
  visible: boolean;
  suppressModal: () => void;
  lastIdFocused?: string;
  data: Array<CurrentTerm>;
}

function HealthDataModal({
  visible, suppressModal, lastIdFocused, data
}: Props) {
  const content = DOMPurify.sanitize(data[0]?.htmlText, { ADD_ATTR: ['target'] })
  return (
    <AccessibleModal
      maxWidthClass="max-w-3xl"
      visible={visible}
      onClose={suppressModal}
      extraModalContainerClass={data.length ? 'accessible-modal-terms' : 'w-11/12 md:w-fit'}
      headerText="Termo de Coleta e Tratamento de Dados Sensíveis"
      showCloseButton={false}
      lastIdFocused={lastIdFocused}
    >
      <div className="terms-modal">
        <div className="privacy-wrapper" dangerouslySetInnerHTML={{ __html: content }} />
        <div className="modal-footer">
          <RegularButton
            label="Fechar"
            onClick={suppressModal}
            extraClass="mt-6"
          />
        </div>
      </div>
    </AccessibleModal>
  )
}

const mapStateToProps = ({
  authentication
}: AppState) => ({
  visible: authentication.showHealthDataModal,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  suppressModal: suppressHealthDataModalAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthDataModal)
