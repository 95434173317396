import React, {
  useState,
  useEffect,
} from 'react'
import { Formik, FormikProps } from 'formik'
import { FormInput, RegularButton } from 'theia-web-ds'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import SelectInputSearch from './SelectInputSearch'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import { HealthInsuranceCompany, HealthInsurancePlans, HealthInsurancePlan } from '../../domain/Healthplan'
import { getHealthInsuranceCompaniesAction } from '../../state/healthplanProfile/actions'
import { eventTrack } from '../../../eventGenerate'
import './HealthPlanRegistrationForm.scss'
import { PEDIATRIC_SCHEDULE } from '../../utils/EventCategories'

export interface HealthInsurancePlanForm {
  hasHealthInsurancePlan: boolean;
  companyName: string;
  planName: string;
  memberId: string;
  companyId: string;
  planId: string;
  otherCompany: string;
  isCovered: boolean;
}

function renderHealthInsuranceForm(
  {
    handleSubmit,
    isSubmitting,
    values,
    setFieldValue,
    setFieldTouched,
    setSubmitting,
    errors,
    touched
  }: FormikProps<HealthInsurancePlanForm>,
  isLoading: boolean,
  isSuccessFetchPlans: boolean,
  companies?: Array<HealthInsuranceCompany>,
  previousHealthInsurancePlan?: HealthInsurancePlan | null,
  showOnlyCovered?: boolean,
  isChildHealthPlan?: boolean,
  failureToSave?: boolean) {
  const [hasOtherCompany, setHasOtherCompany] = useState(false)
  const [companiesOptions, setCompaniesOptions] = useState<string[]>()
  const [plans, setPlans] = useState<HealthInsurancePlans[]>()
  const [companyIncluded, setCompanyIncluded] = useState(false)
  const [planIncluded, setPlanIncluded] = useState(false)
  const [plansNames, setPlansNames] = useState<string[]>()

  useEffect(() => {
    if (values.companyName === 'Outro') {
      setHasOtherCompany(true)
    } else {
      setHasOtherCompany(false)
      setFieldValue('otherCompany', '')
    }
  }, [values.companyName, values.otherCompany])

  const hasError = !!(errors.companyName
    || errors.memberId
    || errors.planName
    || errors.otherCompany)

  useEffect(() => {
    if (companies) {
      let listOfCompanies
      if (showOnlyCovered) {
        listOfCompanies = companies?.filter(
          (company) => company.plans.length > 0
        ).map((company) => company.company)
      } else {
        listOfCompanies = companies?.map((item) => item.company)
      }
      setCompaniesOptions(listOfCompanies)
    }
  }, [companies])

  useEffect(() => {
    if (values.companyName !== previousHealthInsurancePlan?.company) {
      setFieldValue('planName', '')
      setFieldValue('planId', '')
      setFieldValue('memberId', '')
    }
  }, [values.companyName])

  const onSubmit = () => {
    if (hasError) {
      const fieldsWithError = Object.keys(errors)
      fieldsWithError.forEach((field: string) => setFieldTouched(field, true, true))
    } else {
      const isCovered = companyIncluded && planIncluded
      setFieldValue('isCovered', isCovered)
      handleSubmit()
    }
  }

  useEffect(() => {
    if (plans) {
      const plansFiltered = plans.map((item: HealthInsurancePlans) => item.planName)
      if (plansFiltered && plansFiltered.length > 0) {
        setPlansNames(plansFiltered)
        setCompanyIncluded(true)
      } else {
        setCompanyIncluded(false)
        setFieldValue('memberId', '')
        setFieldValue('planName', '')
        setFieldValue('planId', '')
      }
    }
  }, [plans])

  useEffect(() => {
    if (values.companyName && values.companyName !== '' && isSuccessFetchPlans) {
      const selectedCompany = companies?.find(
        (company) => company.company === values.companyName
      )
      if (selectedCompany) {
        setFieldValue('companyId', selectedCompany.companyId)
        if (showOnlyCovered) {
          const coveredPlans = selectedCompany.plans?.filter((plan) => plan.included === true)
          setPlans(coveredPlans)
        } else {
          setPlans(selectedCompany.plans)
        }
      }
    } else {
      setFieldValue('companyId', '')
    }
  }, [values.companyName, isSuccessFetchPlans])

  useEffect(() => {
    if (values.planName && values.planName !== '' && plans) {
      const selectedPlan = plans.find(
        (plan) => plan.planName === values.planName
      )
      if (selectedPlan) {
        setFieldValue('planId', selectedPlan.planId)
        setPlanIncluded(selectedPlan.included)
      }
    } else {
      setFieldValue('planId', '')
    }
  }, [values.planName, plans])

  useEffect(() => {
    if (failureToSave) {
      setSubmitting(false)
    }
  }, [failureToSave])

  return (
    <div className="healthplan-registration-form-container">
      <div className="healthplan-form">
        <div className="mb-4">
          <SelectInputSearch
            name="companyName"
            options={companiesOptions}
            placeholder="Selecione o convênio"
            disabled={isLoading}
            searchInputPlaceholder="Digite o nome do convênio"
            label="Convênio"
            extraOnBlur={() => {
              if (isChildHealthPlan) {
                eventTrack('Selecionou convenio criança', {
                  category: PEDIATRIC_SCHEDULE
                })
              }
            }}
          />
        </div>
        {hasOtherCompany && (
          <div className="mb-4">
            <FormInput
              id="otherCompany"
              label="Nome do convênio"
              type="text"
              name="otherCompany"
              value={values.otherCompany}
              placeholder="Digite o nome do convênio"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('otherCompany', e.target.value)
              }}
              onBlur={() => setFieldTouched('otherCompany', true, true)}
              errorMessage={touched.otherCompany ? errors.otherCompany : ''}
            />
          </div>
        )}
        {companyIncluded && (
          <>
            <div className="mb-4">
              <SelectInputSearch
                name="planName"
                options={plansNames}
                placeholder="Selecione o plano"
                disabled={isLoading}
                label="Nome do plano"
                searchInputPlaceholder="Digite o nome do plano"
                extraOnBlur={() => {
                  if (isChildHealthPlan) {
                    eventTrack('Selecionou plano criança', {
                      category: PEDIATRIC_SCHEDULE
                    })
                  }
                }}
              />
            </div>
            <div className="mb-4">
              <FormInput
                id="memberId"
                label="Número da carteirinha"
                type="text"
                name="memberId"
                value={values.memberId}
                placeholder="Digite o número da carteirinha"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('memberId', e.target.value)
                }}
                onBlur={() => {
                  setFieldTouched('memberId', true, true)
                  if (isChildHealthPlan) {
                    eventTrack('Digitou carteirinha criança', {
                      category: PEDIATRIC_SCHEDULE
                    })
                  }
                }}
                errorMessage={touched.memberId ? errors.memberId : ''}
              />
            </div>
          </>
        )}
      </div>
      <div className="healthplan-form-btn-container">
        <RegularButton
          onClick={onSubmit}
          label="Continuar"
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
        />
      </div>
    </div>
  )
}

interface Props {
  companies?: HealthInsuranceCompany[];
  isLoading: boolean;
  acceptedCompanies?: string[];
  previousHealthInsurancePlan?: HealthInsurancePlan | null;
  isSuccessFetchPlans: boolean;
  showOnlyCovered?: boolean;
  isChildHealthPlan?: boolean;
  failureToSave?: boolean;
  onPostHealthInsurance: (values: HealthInsurancePlanForm) => void;
  getHealthInsuranceCompanies: () => void;
}

function HealthPlanRegistrationForm({
  companies,
  isLoading,
  acceptedCompanies,
  previousHealthInsurancePlan,
  isSuccessFetchPlans,
  showOnlyCovered,
  isChildHealthPlan,
  failureToSave,
  onPostHealthInsurance,
  getHealthInsuranceCompanies,
}: Props) {
  const [healthCompaniesList, setHealthCompaniesList] = useState<string[]>([])
  const previousCompany = companies?.find(
    (company) => company.companyId === previousHealthInsurancePlan?.companyId
  )
  const previousCovered = (previousCompany?.plans && previousCompany?.plans?.length > 0) || false

  const initialValues = {
    hasHealthInsurancePlan: true,
    companyName: previousHealthInsurancePlan?.company || '',
    planName: previousHealthInsurancePlan?.planName || '',
    memberId: previousHealthInsurancePlan?.memberId || '',
    companyId: previousHealthInsurancePlan?.companyId || '',
    planId: previousHealthInsurancePlan?.planId || '',
    otherCompany: '',
    isCovered: previousCovered
  }

  useEffect(() => {
    if (!isLoading) {
      getHealthInsuranceCompanies()
    }
  }, [])

  useEffect(() => {
    if (companies) {
      const companiesNames = companies.map(
        (item: HealthInsuranceCompany) => item.company
      )
      setHealthCompaniesList(companiesNames)
    }
  }, [companies])

  const validationSchema = Yup.object().shape({
    companyName: Yup.mixed()
      .required('Escolha um convênio para continuar')
      .oneOf(healthCompaniesList, 'Escolha uma das opções da lista'),
    planName: Yup.string()
      .when('companyName', {
        is: (val) => acceptedCompanies?.includes(val),
        then: Yup.string().required('Escolha um plano para continuar'),
        otherwise: Yup.string().notRequired()
      }),
    memberId: Yup.string()
      .when('companyName', {
        is: (val) => acceptedCompanies?.includes(val),
        then: Yup.string().required('Informe o número da carteirinha para continuar'),
        otherwise: Yup.string().notRequired()
      }),
    otherCompany: Yup.string()
      .when('companyName', {
        is: (val) => val === 'Outro',
        then: Yup.string().required('Informe o convênio para continuar'),
        otherwise: Yup.string().notRequired()
      })
  })

  function handleSubmit(values: HealthInsurancePlanForm) {
    onPostHealthInsurance(values)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => renderHealthInsuranceForm(
        props,
        isLoading,
        isSuccessFetchPlans,
        companies,
        previousHealthInsurancePlan,
        showOnlyCovered,
        isChildHealthPlan,
        failureToSave,
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  healthplan
}: AppState) => ({
  isLoading: healthplan.isLoading,
  companies: healthplan.companies,
  acceptedCompanies: healthplan.companies?.filter(
    (company: HealthInsuranceCompany) => company.plans.length > 0
  ).map(
    (company: HealthInsuranceCompany) => company.company
  ),
  isSuccessFetchPlans: healthplan.isSuccessFetchCompanies
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getHealthInsuranceCompanies: () => {
    dispatch(getHealthInsuranceCompaniesAction())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthPlanRegistrationForm)
