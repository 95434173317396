import React, { HTMLAttributes } from 'react'
import MaskedInput, { Mask } from 'react-text-mask'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { FormInputPlaceholder } from '../FormInputPlaceholder/FormInputPlaceholder'
import './MaskedFormInput.scss'

export interface Props extends HTMLAttributes<HTMLInputElement> {
  type: 'text' | 'number' | 'date' | 'email' | 'tel';
  name: string;
  id: string;
  label?: string;
  mask: Mask | ((value: string) => Mask)
  guide?: boolean;
  placeholder?: string;
  extraContainerClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  errorMessage?: string;
  value?: string;
  required?: boolean;
}

export function MaskedFormInput({
  type,
  label,
  name,
  mask,
  id,
  guide,
  extraContainerClassName,
  placeholder,
  disabled,
  value,
  errorMessage,
  isLoading,
  required,
  ...props
}: Props) {
  if (isLoading) {
    return <FormInputPlaceholder />
  }

  return (
    <div className={`masked-form-input-container ${extraContainerClassName}`}>
      <div className="masked-form-input-wrapper">
        <label htmlFor={id} className={errorMessage ? 'error' : ''}>{label}</label>
        <MaskedInput
          mask={mask}
          id={id}
          autoComplete="off"
          className={`masked-form-input ${errorMessage ? 'error' : ''}`}
          type={type}
          name={name}
          value={value}
          guide={guide}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
        />
        {errorMessage && (
          <ErrorMessage error={errorMessage} />
        )}
      </div>
    </div>
  )
}
