import React, { useState } from 'react'
import { RegularButton, ListItemCard, IconButton } from 'theia-web-ds'
import { textPrimary } from '../../color'
import ResponsiveSplitModal from './ResponsiveSplitModal'
import StepperBar from './StepperBar'
import './GenericSurveyModal.scss'

export interface OptionType {
  name: string;
  value: any;
  selected?: boolean;
  onClick: (value: any) => void;
}

export interface StepType {
  title: string;
  subtitle?: string;
  options?: OptionType[];
  component?: React.ReactNode;
}

interface Props {
  visible: boolean;
  isSubmitting: boolean;
  numberOfSteps: number;
  steps: StepType[];
  cantProceed: boolean;
  skipToLastStep?: boolean;
  ariaLabelledby: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
  closeButtonText?: string;
  onCloseModal: () => void;
  onSubmit: () => void;
  onDenyToAnswer: () => void;
}

function GenericSurveyModal({
  visible,
  isSubmitting,
  numberOfSteps,
  steps,
  cantProceed,
  skipToLastStep,
  ariaLabelledby,
  desktopImageUrl,
  mobileImageUrl,
  closeButtonText,
  onCloseModal,
  onSubmit,
  onDenyToAnswer
}: Props) {
  const [currentStep, setCurrentStep] = useState(1)

  function handleDontAnswer() {
    onDenyToAnswer()
  }

  function handleSubmit() {
    onSubmit()
  }

  function handleCloseModal() {
    onCloseModal()
  }

  function handleNextStep() {
    if (currentStep === numberOfSteps) {
      return handleSubmit()
    }
    if (skipToLastStep) {
      return setCurrentStep(numberOfSteps)
    }
    return setCurrentStep(currentStep + 1)
  }

  function handlePreviousStep() {
    if (skipToLastStep) {
      return setCurrentStep(1)
    }
    return setCurrentStep(currentStep - 1)
  }

  return (
    <ResponsiveSplitModal
      visible={visible}
      onClose={handleCloseModal}
      ariaLabelledby={ariaLabelledby}
      key={ariaLabelledby}
      desktopImageUrl={desktopImageUrl}
      mobileImageUrl={mobileImageUrl}
      showDesktopCloseButton={currentStep === 1}
      desktopCloseButtonText={closeButtonText}
      desktopCloseButtonAction={handleDontAnswer}
      isLoading={isSubmitting}
    >
      <div className="survey-modal-content" data-testid="survey-modal-content" id="survey-modal-content">
        <div className="stepper-header">
          {currentStep > 1 && (
            <div className="header-icon">
              <IconButton
                aria-label="Voltar"
                variant="text"
                width="32px"
                height="32px"
                iconType="icon-ArrowLeft2Light"
                iconSize="20px"
                iconColor={textPrimary}
                data-dismiss="modal"
                onClick={handlePreviousStep}
              />
            </div>
          )}
          <h6 className="text-textPrimary font-normal text-sm text-center px-4 pt-4">
            Enquete
          </h6>
          <StepperBar
            stepperLabel="Pergunta"
            totalSteps={numberOfSteps}
            currentStep={currentStep}
            titleColor="text-textSecondary"
            titleSize="text-xs"
            stepperMargin="m-0"
            titleMargin="mb-4"
          />
        </div>
        <div className="survey-modal-content-body">
          {steps.length > 0 && steps.map((step: StepType, index: number) => (
            currentStep === (index + 1) && (
              <div key={step.title + index}>
                <h2 className={`text-textPrimary font-normal text-xl text-center mt-9
                  ${step.subtitle ? 'mb-1' : 'mb-10'}`}
                >
                  {step.title}
                </h2>
                {step.subtitle && (
                  <p className="text-center text-textSecondary text-base mt-1 mb-4">
                    {step.subtitle}
                  </p>
                )}
                {step.options?.map((option: OptionType) => (
                  <ListItemCard
                    ariaLabel={option.name}
                    title={option.name}
                    extraClassNameContainer="mb-4"
                    extraClassClickableContainer={`rounded-2xl border
                      ${option.selected ? 'border-primary' : 'border-divider'}`}
                    isLastItem
                    extraTitleClass="text-center"
                    hideIconButton
                    onClick={() => option.onClick(option.value)}
                    key={option.value}
                  />
                ))}
                {step.component}
              </div>
            )
          ))}
          <div className="btn-container">
            <RegularButton
              disabled={cantProceed}
              extraClass="mt-12"
              label="Continuar"
              onClick={handleNextStep}
              variant="primary"
            />
            {currentStep === 1 && (
              <div className="md:hidden mt-2">
                <RegularButton
                  extraClass=""
                  label={closeButtonText}
                  onClick={handleDontAnswer}
                  variant="secondary-text"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </ResponsiveSplitModal>
  )
}

export default GenericSurveyModal
