/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import { RegularButton } from 'theia-web-ds'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import checkHealthInsurance from '../../../animations/checking_card.json'
import { AppState } from '../../../apps/main/store'
import NegativeFeedback from '../../../../assets/Plan-no.svg'
import { postUserHealthInsurance } from '../../../state/healthplanProfile/actions'
import { AppDispatch } from '../../../state/utils'
import { updateCurrentUserInfosAction } from '../../../state/authentication/main/actions'
import { fetchAllProfessionalsAction } from '../../../state/professionals/actions'
import BackButton from '../../common/buttons/BackButton'
import { HealthPlanFeedbackData, PostUserHealthPlanType } from '../../../domain/Healthplan'
import './FeedbackHealthPlanProfile.scss'
import { HEALTH_PLAN_INCLUDED, PROFILE } from '../../../routes/RoutesConstants'

interface Props {
  onPostHealthInsurance: (healthPlanData: PostUserHealthPlanType) => void;
  onUpdateCurrentUser: () => void;
  fetchProfessionals: () => void;
  isSuccess: boolean;
  healthPlanFeedbackData?: HealthPlanFeedbackData;
}

function FeedbackHealthPlanProfile({
  onPostHealthInsurance,
  onUpdateCurrentUser,
  fetchProfessionals,
  healthPlanFeedbackData,
  isSuccess
}: Props) {
  const history = useHistory()
  const companyName = healthPlanFeedbackData?.companyName || ''
  const companyId = healthPlanFeedbackData?.companyId || ''
  const planName = healthPlanFeedbackData?.planName || ''
  const planId = healthPlanFeedbackData?.planId || ''
  const memberId = healthPlanFeedbackData?.memberId || ''
  const hasPreviousInsurancePlan = healthPlanFeedbackData?.hasPreviousInsurancePlan
  const isHealthPlanCovered = healthPlanFeedbackData?.isCovered
  const [animationDelay, setAnimationDelay] = useState(false)
  const [showContent, setShowContent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const lottieCheckingHealthInsurance = {
    loop: true,
    autoplay: true,
    animationData: checkHealthInsurance,
  }

  useEffect(() => {
    const timer: NodeJS.Timeout = setTimeout(() => {
      setAnimationDelay(true)
    }, 6000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isHealthPlanCovered && isSuccess) {
      onUpdateCurrentUser()
      fetchProfessionals()
      timer = setTimeout(() => {
        history.push(HEALTH_PLAN_INCLUDED, {
          isFromFeedbackChangePlan: true,
          hasPreviousInsurancePlan
        })
      }, 5000)
      return
    }
    if (!isHealthPlanCovered && isSuccess && hasPreviousInsurancePlan) {
      onUpdateCurrentUser()
      fetchProfessionals()
      timer = setTimeout(() => {
        history.push(PROFILE, { isFromFeedbackChangePlan: true, hasPreviousInsurancePlan })
      }, 2000)
      return
    }
    if (!isHealthPlanCovered && isSuccess && !hasPreviousInsurancePlan) {
      fetchProfessionals()
      timer = setTimeout(() => {
        setShowContent(true)
      }, 5000)
      return
    }
    if (isSuccess) {
      fetchProfessionals()
      return
    }
    timer = setTimeout(() => {
      setShowContent(true)
    }, 5000)
    return () => {
      clearTimeout(timer)
    }
  }, [isSuccess, hasPreviousInsurancePlan])

  const onSubmit = () => {
    setIsLoading(true)
    onPostHealthInsurance({
      hasHealthInsurancePlan: true,
      healthInsurancePlan: {
        company: companyName,
        planName,
        memberId,
        companyId,
        planId,
      }
    })
  }

  const goToProfileUpdated = () => {
    onUpdateCurrentUser()
    history.push(PROFILE, { isFromOkEntendi: true })
  }

  const goToProfile = () => {
    history.push(PROFILE)
  }

  const negativeFeedback = showContent && (
    <div className="flex flex-col items-center health-plan-negative-profile">
      <div className={animationDelay ? 'go-up' : 'not-accepted-plan-icon'}>
        <NegativeFeedback />
      </div>
      <div className="show-content-not-accepted">
        <div className="mx-4">
          <p className="text-textMedium text-center font-normal justify-center">
            {planName && (`Ahh! Ainda não aceitamos o plano ${planName} da ${companyName}`)}
            {!planName && (`Ahh! Ainda não aceitamos o plano ${companyName}`)}
          </p>
          <p className="text-fontDefault text-textSecondary text-center mt-4">
            Mas você pode agendar no particular e pedir reembolso após a consulta.
          </p>
          {hasPreviousInsurancePlan && (
          <>
            <RegularButton label="Confirmar alteração" onClick={onSubmit} isSubmitting={isLoading} extraClass="my-4" />
            <RegularButton variant="text" label="Manter o atual" onClick={goToProfile} />
          </>
          )}
          {!hasPreviousInsurancePlan && (
          <RegularButton label="Ok, entendi" onClick={goToProfileUpdated} isSubmitting={isLoading} extraClass="my-4" />
          )}
        </div>
      </div>
    </div>
  )

  const checkingHealthInsurance = (
    <div className="flex flex-col items-center justify-center checking-health-insurance">
      <Lottie
        options={lottieCheckingHealthInsurance}
        isClickToPauseDisabled
        ariaRole=""
        height={192}
        width={192}
      />
      <p className="text-textPrimary text-textMedium w-4/5 text-center">
        Falando com a {companyName}...
      </p>
    </div>
  )

  return (
    <div className="flex flex-1 relative">
      <div className="mx-auto w-full">
        <div className="sticky flex items-center bg-bgCanvas top-0 z-10 h-20">
          <BackButton text="" to="/profile" extraClass="back-button-svg" />
        </div>
        <div className="lg:max-w-xl mx-auto w-full feedback-container">
          {!showContent
            ? checkingHealthInsurance
            : negativeFeedback}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  authentication, healthplan
}: AppState) => ({
  currentUser: authentication.currentUser,
  isSuccess: healthplan.isSuccess,
  healthPlanFeedbackData: healthplan.healthPlanFeedbackData
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onPostHealthInsurance: (healthPlanData: PostUserHealthPlanType) => {
    dispatch(postUserHealthInsurance(healthPlanData))
  },
  onUpdateCurrentUser: () => { dispatch(updateCurrentUserInfosAction()) },
  fetchProfessionals: () => { dispatch(fetchAllProfessionalsAction()) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackHealthPlanProfile)
