import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AGENDAR } from '../../routes/RoutesConstants'

export default function RedirectOldSpecialistsLinks() {
  const history = useHistory()
  useEffect(() => {
    history.push(AGENDAR)
  }, [])
  return <div />
}
