/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react'
import { BookingVideoTrack, BookingAudioTrack } from '../../domain/Call'
import './Participant.scss'
import LocalCameraInactive from '../../../assets/local-video-disabled.svg'

interface Props {
  kind: 'local' | 'remote';
  videoTrack?: BookingVideoTrack;
  audioTrack?: BookingAudioTrack;
  showVideoDisabled: boolean;
}

export default function Participant({
  kind,
  videoTrack,
  audioTrack,
  showVideoDisabled
}: Props) {
  const [videoClass, setVideoClass] = useState('')
  const videoRef = useRef<HTMLVideoElement>(null)
  const audioRef = useRef<HTMLAudioElement>(null)
  const [aspectRatio, setAspectRatio] = useState<'horizontal' | 'vertical'>('horizontal')
  function checkLocalOrRemote() {
    if (kind === 'local') {
      if (aspectRatio === 'horizontal') {
        setVideoClass('participant__local-video participant__local-video--horizontal')
      } else {
        setVideoClass('participant__local-video participant__local-video--vertical')
      }
    } else if (aspectRatio === 'horizontal') {
      setVideoClass('participant__remote-video participant__remote-video--horizontal')
    } else {
      setVideoClass('participant__remote-video participant__remote-video--vertical')
    }
  }
  function handleLoadMetaData() {
    if (!videoRef.current) return

    const ratio = videoRef.current.videoWidth / videoRef.current.videoHeight
    setAspectRatio(ratio >= 1 ? 'horizontal' : 'vertical')
  }

  useEffect(() => {
    const videoElement = videoRef.current
    if (!videoElement || !videoTrack) return () => { }

    videoTrack.attach(videoElement)
    videoElement.addEventListener('loadedmetadata', handleLoadMetaData)

    return () => {
      videoTrack.detach()
      videoElement.removeEventListener('loadedmetadata', handleLoadMetaData)
    }
  }, [videoTrack])

  useEffect(() => {
    if (!audioRef.current || !audioTrack) return () => { }
    audioTrack.attach(audioRef.current)
    return () => audioTrack.detach()
  }, [audioTrack])

  useEffect(() => {
    checkLocalOrRemote()
  }, [aspectRatio])

  return (
    <>
      {videoTrack && (
        <video ref={videoRef} className={videoClass} autoPlay />
      )}
      {
        kind === 'local' && !showVideoDisabled && (
          <div className="participant__local-video participant__local-video--disabled">
            <LocalCameraInactive />
          </div>
        )
      }
      {audioTrack && <audio ref={audioRef} autoPlay />}
    </>
  )
}
