import React, { useEffect, useRef, useState } from 'react'
import './ResponsiveDrawer.scss'
import { textPrimary } from '../../colors'
import { Divider } from '../Divider/Divider'
import { IconButton } from '../IconButton/IconButton'

export interface Props {
    isDrawerVisible: boolean;
    id: string;
    children: React.ReactNode;
    onGoBack?: () => void;
    headerTitle?: string;
    onClose?: () => void;
    headerExtraComponent?: React.ReactNode;
 }

export function ResponsiveDrawer({
  isDrawerVisible,
  children,
  onGoBack,
  headerTitle,
  id,
  headerExtraComponent,
  onClose
}: Props) {
  const [visible, setVisible] = useState(true)
  const headerRef = useRef<HTMLDivElement>(null)
  const bodyRef = useRef<HTMLDivElement>(null)
  const [bodyHeight, setBodyHeight] = useState<number>(0)

  function closeDrawer() {
    setVisible(false)
    setTimeout(() => {
      if (onClose) {
        onClose()
      }
    }, 300)
  }

  useEffect(() => {
    if (isDrawerVisible) {
      setVisible(true)
    }
  }, [isDrawerVisible])

  useEffect(() => {
    const updateBodyHeight = () => {
      const headerHeight = headerRef.current?.clientHeight || 0
      const newBodyHeight = window.innerHeight - headerHeight - 24
      setBodyHeight(newBodyHeight)
    }
    updateBodyHeight()
    window.addEventListener('resize', updateBodyHeight)
    return () => {
      window.removeEventListener('resize', updateBodyHeight)
    }
  }, [headerRef.current])

  function onGoBackDrawer() {
    if (onGoBack) {
      onGoBack()
    } else {
      closeDrawer()
    }
  }

  return isDrawerVisible ? (
    <>
      <div data-testid="overlay" aria-hidden="true" className={`right-drawer-overlay ${visible ? 'active' : 'inactive'}`} onClick={closeDrawer} />
      <div
        role="dialog"
        aria-modal="true"
        id={id}
        className={`right-drawer-inner ${visible ? 'active' : 'inactive'}`}
      >
        {headerTitle && (
          <div className="w-full flex flex-col text-left justify-start" ref={headerRef}>
            <div className="right-drawer-inner-header">
              <IconButton
                iconType="icon-ArrowLeft2Light"
                iconSize="18px"
                variant="subtle"
                aria-label="back-button"
                iconColor={textPrimary}
                onClick={onGoBackDrawer}
                type="button"
                height="32px"
                width="32px"
                extraClass="desktop-btn"
              />
              <p className="text-headlineSmall text-textPrimary">
                {headerTitle}
              </p>
              <IconButton
                iconType="icon-CloseLight"
                iconSize="18px"
                variant="subtle"
                iconColor={textPrimary}
                onClick={closeDrawer}
                type="button"
                aria-label="close-button"
                height="32px"
                width="32px"
                extraClass="mobile-btn"
              />
            </div>
            {headerExtraComponent}
            <Divider width="100%" />
          </div>
        )}
        <div ref={bodyRef} style={{ height: bodyHeight }} className="right-drawer-inner-content">
          {children}
        </div>
      </div>
    </>
  ) : null
}
