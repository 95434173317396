import { toast } from 'react-toastify'
import { FontIcon, IconButton } from 'theia-web-ds'
import { ToastInfos } from '../../domain/Notifications'
import { AsyncAction } from '../utils'
import { bgBox } from '../../color'

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'

interface ClearNotificationActionType {
  type: typeof CLEAR_NOTIFICATION;
  id: string;
}

interface ShowNotificationActionType {
  type: typeof SHOW_NOTIFICATION;
  toastInfos: ToastInfos;
}

export type NotificationTypes = ClearNotificationActionType
| ShowNotificationActionType

export const clearNotificationAction = (
  id: string
): ClearNotificationActionType => ({
  type: CLEAR_NOTIFICATION,
  id
})

export function showNotificationAction(toastInfos: ToastInfos):
  AsyncAction<{}, {}> {
  return async (dispatch) => {
    dispatch({ type: SHOW_NOTIFICATION, toastInfos })
    toast(toastInfos.message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'toast-message',
      hideProgressBar: true,
      autoClose: toastInfos.timer || 5000,
      type: toastInfos.type,
      icon: FontIcon({
        iconType: toastInfos.iconType,
        color: toastInfos.iconColor,
        fontSize: '22px',
        extraClass: 'Toastify__toast-icon'
      }),
      toastId: toastInfos.id,
      closeButton: ({ closeToast }) => IconButton({
        variant: 'form-icon',
        iconType: 'icon-CloseLight',
        iconColor: bgBox,
        iconSize: '22px',
        extraClass: 'Toastify__close-button',
        onClick: (e: any) => {
          closeToast(e)
          dispatch(clearNotificationAction(toastInfos.id))
        }
      }),
    })
  }
}
