import {
  BAD_FEEDBACK_DETAILS,
  PROMPT_FEEDBACK,
  GOOD_FEEDBACK,
  SUPPRESS_FEEDBACK,
  FAILURE_FETCH_FEEDBACK_REASONS,
  SUCCESS_FETCH_FEEDBACK_REASONS,
  FETCH_FEEDBACK_REASONS,
  TOGGLE_REASON,
  FeedbackTypes
} from './actions'
import { Reason } from '../../domain/Booking'

export type feedbackStepTypes = 'prompt' | 'feedback' | 'closed';

export interface FeedbackState {
  step: feedbackStepTypes;
  reasons: Array<Reason>;
  isFetchingReasons: boolean;
  bookingId?: string;
}

export const initialScheduleState: FeedbackState = {
  step: 'closed',
  reasons: [],
  isFetchingReasons: false,
}

export function feedback(state = initialScheduleState, action: FeedbackTypes): FeedbackState {
  switch (action.type) {
    case PROMPT_FEEDBACK:
      return {
        ...state,
        step: 'prompt',
        bookingId: action.bookingId
      }
    case GOOD_FEEDBACK:
      return {
        ...state,
        step: 'closed'
      }
    case BAD_FEEDBACK_DETAILS:
      return {
        ...state,
        step: 'feedback'
      }
    case SUPPRESS_FEEDBACK:
      return {
        ...state,
        step: 'closed'
      }
    case FETCH_FEEDBACK_REASONS:
      return {
        ...state,
        isFetchingReasons: true
      }
    case SUCCESS_FETCH_FEEDBACK_REASONS:
      return {
        ...state,
        isFetchingReasons: false,
        reasons: action.reasons
      }
    case FAILURE_FETCH_FEEDBACK_REASONS:
      return {
        ...state,
        isFetchingReasons: false
      }
    case TOGGLE_REASON:
      return {
        ...state,
        reasons: state.reasons.map((reason) => (
          reason.id === action.reason.id
            ? { ...reason, selected: !reason.selected }
            : reason
        ))
      }
    default: return state
  }
}
