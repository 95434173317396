import React, { useEffect } from 'react'
import { Divider, FontIcon } from 'theia-web-ds'
import Header from '../common/molecules/Header'
import PhoneChat from '../../../assets/phone-chat.png'
import Google from '../../../assets/google-play.png'
import Apple from '../../../assets/apple-store.png'
import { success } from '../../color'
import { eventPage, eventTrack } from '../../../eventGenerate'
import './Chat.scss'

export default function ChatView() {
  useEffect(() => {
    eventPage('chat', 'Chat')
  }, [])

  const actionEventTrack = (param: 'Android' | 'Iphone') => {
    eventTrack(`Clicou ${param}`)
  }

  return (
    <div className="health-view chat-view">
      <Header title="Chat" />
      <Divider />
      <div className="chat-content">
        <div className="chat-left-content">
          <div className="top-text">
            <h2 className="title">
              Tire suas dúvidas com o time 24 horas
            </h2>
            <p className="subtitle">
              Para uma melhor experiência e segurança, por enquanto, nosso chat está disponível
              apenas no celular.
            </p>
            <p className="span">
              📱 Disponível para IOS e Android
            </p>
            <div className="chat-stores">
              <a
                href="https://play.google.com/store/apps/details?id=br.com.theia&hl=pt_BR&gl=BR"
                target="_blank"
                rel="noreferrer"
                onClick={() => actionEventTrack('Android')}
              >
                <img src={Google} alt="" />
              </a>
              <a
                href="https://apps.apple.com/br/app/theia/id1498648488?ign-itscg=30200&ign-itsct=apps_box_badge"
                target="_blank"
                rel="noreferrer"
                onClick={() => actionEventTrack('Iphone')}
              >
                <img src={Apple} alt="" />
              </a>
            </div>
          </div>
          <div className="mobile-chat-image block lg:hidden">
            <img src={PhoneChat} alt="" />
          </div>
          <div className="chat-list">
            <p className="text-textPrimary mb-4 ml-3 lg:ml-0 font-medium">
              O que faz o nosso time 24 horas?
            </p>
            <Divider />
            <div className="item-list">
              <FontIcon
                iconType="icon-TickRoundLight"
                color={success}
                fontSize="24px"
                extraClass="p-4"
              />
              <div>
                <p aria-label="Emergências: Respostas mais rápidas para situações urgentes">
                  <b>Emergências: </b>
                  Respostas mais rápidas para situações urgentes
                </p>
                <Divider className="w-full mt-4" />
              </div>
            </div>
            <div className="item-list">
              <FontIcon
                iconType="icon-TickRoundLight"
                color={success}
                fontSize="24px"
                extraClass="p-4"
              />
              <div>
                <p
                  aria-label="Dúvidas de Saúde: Esclareça incertezas sobre sintomas"
                >
                  <b>Dúvidas de Saúde: </b>
                  Esclareça incertezas sobre sintomas
                </p>
                <Divider className="w-full mt-4" />
              </div>
            </div>
            <div className="item-list">
              <FontIcon
                iconType="icon-TickRoundLight"
                color={success}
                fontSize="24px"
                extraClass="p-4"
              />
              <div>
                <p
                  aria-label="Administrativo: Auxílio em agendamentos e questões burocráticas"
                >
                  <b>Administrativo: </b>
                  Auxílio em agendamentos e questões burocráticas
                </p>
                <Divider className="w-full mt-4" />
              </div>
            </div>
            <div className="item-list">
              <FontIcon
                iconType="icon-TickRoundLight"
                color={success}
                fontSize="24px"
                extraClass="p-4"
              />
              <p
                className="pb-4"
                aria-label="Feedback: Sua opinião nos ajuda a melhorar"
              >
                <b>Feedback: </b>
                Sua opinião nos ajuda a melhorar
              </p>
            </div>
            <Divider />
            <p className="text-textSecondary my-4 ml-3 lg:ml-0">
              Queremos garantir que você tenha suporte especializado, quando e onde precisar.
            </p>
          </div>
        </div>
        <div className="mobile-chat-image hidden lg:block">
          <img src={PhoneChat} alt="" />
        </div>
      </div>
    </div>
  )
}
