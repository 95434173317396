/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  forwardRef
} from 'react'
import { Divider } from '../Divider/Divider'
import { Loader } from '../Loader/Loader'
import { UseCombinedRefs } from '../../utils/helpers'
import './NewestCheckbox.scss'

export interface Props {
  label?: string;
  name: string;
  value: string;
  extraClass?: string;
  extraClassLabel?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  hasDivider?: boolean;
  hasVerticalStepper?: boolean;
  status?: 'success' | 'selected';
  isLoading?: boolean;
  componentExtraClass?: string;
  onClick?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const NewestCheckbox: React.FC<Props> = forwardRef(
  ({
    label,
    name,
    value,
    extraClass,
    defaultChecked = false,
    extraClassLabel,
    hasVerticalStepper,
    hasDivider,
    status = 'selected',
    isLoading = false,
    componentExtraClass,
    disabled,
    onClick,
    onChange
  }: Props, forwardedRef) => {
    const [checked, setChecked] = useState<boolean>(defaultChecked || false)
    const [onPressed, setOnPressed] = useState<boolean>(false)
    const innerRef = useRef<HTMLInputElement>(null)
    const combinedRef = UseCombinedRefs(forwardedRef, innerRef)

    const setCheckedInput = (check: boolean) => {
      if (innerRef.current) {
        if (innerRef.current.checked !== check) {
          innerRef.current.click()
        }
      }
    }

    useEffect(() => {
      setCheckedInput(checked)
    }, [checked])

    useEffect(() => {
      setChecked(defaultChecked)
    }, [defaultChecked])

    const onInputClick = () => {
      if (!disabled) {
        setChecked(!checked)
      }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.preventDefault()
      if (e.key === 'Enter') {
        setChecked(!checked)
      }
    }

    return (
      <div
        className={`newest-checkbox-container
          ${disabled ? '' : 'cursor-pointer'}
          ${componentExtraClass}`}
        onClick={onInputClick}
        data-testid="newest-checkbox-container"
      >
        <label
          className={`checkBox
            ${extraClassLabel}
            ${hasVerticalStepper ? 'vertical-stepper' : ''}
            status-${status}
          `}
          htmlFor={name}
          data-testid="checkbox-label"
        >
          <span className="checkbox-container">
            {isLoading ? (
              <Loader size="20px" />
            ) : (
              <>
                <input
                  style={{ opacity: 0 }}
                  ref={combinedRef}
                  type="checkbox"
                  name={name}
                  value={value}
                  defaultChecked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked)
                    if (onChange) {
                      onChange(e)
                    }
                  }}
                  className={extraClass}
                  disabled={disabled}
                  onClick={onClick}
                  id={`checkbox-${name}`}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    handleKeyDown(e)
                  }}
                  data-testid="checkbox-input"
                />
                <span
                  className={`custom-checkbox
                    ${onPressed ? 'checkbox-pressed' : ''}
                  `}
                  onMouseDown={() => setOnPressed(true)}
                  onMouseUp={() => setOnPressed(false)}
                  data-testid="checkbox-span"
                />
              </>
            )}
          </span>
          {label}
        </label>
        {hasDivider && <Divider className="divider-new-checkbox" />}
      </div>
    )
  }
)

NewestCheckbox.displayName = 'NewestCheckbox'
