import {
  SET_SPECIALIST_ONLINE,
  SET_SPECIALIST_OFFLINE,
  SpecialistPresenceTypes,
  SET_SPECIALIST_TYPING,
} from './actions'

export interface OnlineSpecialistState {
  identity: string;
  typing: boolean;
}

const initialState: OnlineSpecialistState = {
  identity: '',
  typing: false
}

export function onlineSpecialist(
  state = initialState, action: SpecialistPresenceTypes
): OnlineSpecialistState {
  switch (action.type) {
    case SET_SPECIALIST_ONLINE:
      return {
        ...state,
        identity: action.onlineSpecialist
      }
    case SET_SPECIALIST_OFFLINE:
      return {
        ...state,
        identity: ''
      }
    case SET_SPECIALIST_TYPING:
      return {
        ...state,
        typing: action.typing
      }
    default: return state
  }
}
