import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  NewListItem,
  ButtonOptions,
  FontIcon,
  IconButton
} from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import Logo from '../../../assets/logo-rounded.svg'
import { primary } from '../../color'
import { Reminders } from '../../domain/Booking'
import { getScheduleSuggestionAction, onUpdateBookingSuggestionAction } from '../../state/scheduleSuggestion/actions'
import { setPreviousSelectedCategoryAction } from '../../state/scheduleFlow/actions'
import { SpecialistCategories } from '../../domain/Specialist'
import './ScheduleSuggestionBanner.scss'
import { AGENDAR, MEDICAL_REQUESTS } from '../../routes/RoutesConstants'

interface Props {
 reminder: Reminders;
 onUpdateScheduleSuggestion: (
   type: string, bookingIds?: Array<string>, callback?: () => void) => void;
 getScheduleSuggestion: () => void;
 extraClass?: string;
 setPreviousSelectedCategory: (
  category?: SpecialistCategories
) => void;
}

function ScheduleSugestionBanner({
  reminder,
  onUpdateScheduleSuggestion,
  getScheduleSuggestion,
  extraClass,
  setPreviousSelectedCategory
}: Props) {
  const history = useHistory()
  const idComponent = 'button'
  const [drawerIsVisible, setDrawerIsVisible] = useState(false)
  const [textHome, setTextHome] = useState('')
  const [type, setType] = useState('')
  const [lastBookingIds, setLastBookingIds] = useState<any>([])

  useEffect(() => {
    if (reminder) {
      setTextHome(reminder.textHome)
      setType(reminder.type)
      setLastBookingIds(reminder.lastBookingIds)
    }
  }, [])

  function openOptions(event?: any) {
    event?.preventDefault()
    event?.stopPropagation()
    if (drawerIsVisible) {
      setDrawerIsVisible(false)
    } else {
      setDrawerIsVisible(true)
    }
  }

  function closeOptions(event?: any) {
    event?.preventDefault()
    event?.stopPropagation()
    setDrawerIsVisible(false)
  }

  function updateAndGetScheduleMessage(_callback: () => void) {
    onUpdateScheduleSuggestion(type, lastBookingIds, _callback)
  }

  function dismissSuggestion(event?: any) {
    event?.preventDefault()
    event?.stopPropagation()
    updateAndGetScheduleMessage(getScheduleSuggestion)
    setDrawerIsVisible(false)
  }

  function doneSuggestionTypeExam(event?: any) {
    event?.preventDefault()
    event?.stopPropagation()
    history.push(MEDICAL_REQUESTS, [reminder, 'isFromReminder'])
  }

  const optionsModalTypeSignUp = (
    <ul>
      <NewListItem
        onClick={dismissSuggestion}
        iconType="icon-TrashLight"
        iconColor={primary}
        iconSize="21px"
        title="Remover lembrete"
        ariaLabel="Remover lembrete"
        id="dismiss-suggestion"
        key="dismiss-suggestion"
        isLastItem
        hideIconButton
      />
    </ul>
  )

  const optionsModalTypeExam = (
    <ul>
      <NewListItem
        onClick={doneSuggestionTypeExam}
        iconType="icon-ExamLight"
        iconColor={primary}
        iconSize="21px"
        title="Pedidos de exames"
        ariaLabel="Pedidos de exames"
        id="exams-requests"
        key="exams-requests"
        isLastItem={false}
        hideIconButton
      />
      <NewListItem
        onClick={dismissSuggestion}
        iconType="icon-TrashLight"
        iconColor={primary}
        iconSize="21px"
        title="Remover lembrete"
        ariaLabel="Remover lembrete"
        id="remove-suggestion"
        key="remove-suggestion"
        isLastItem
        hideIconButton
      />
    </ul>
  )
  const onClickSuggestionBanner = () => {
    switch (reminder.type) {
      case 'SIGNUP': {
        setPreviousSelectedCategory(SpecialistCategories.GINECOLOGIST)
        history.push(AGENDAR)
        break
      }
      case 'EXAM_REQUEST': {
        history.push(MEDICAL_REQUESTS, [reminder, 'isFromReminder'])
        break
      }
      case 'RIGHT_AFTER': {
        setPreviousSelectedCategory(reminder.category)
        history.push(AGENDAR)
        break
      }
      default:
        return ''
    }
    return ''
  }

  return (
    <div
      className={`schedule-suggestion-banner-container ${extraClass}`}
      role="button"
      tabIndex={0}
      onClick={onClickSuggestionBanner}
      onKeyDown={onClickSuggestionBanner}
    >
      <div className="flex w-full p-4 justify-between items-center schedule-suggestion">
        <div className="flex items-center w-full">
          <div className="relative schedule-suggestion-img">
            <Logo />
            {reminder.type === 'SIGNUP' && (
              <span className="block absolute schedule-suggestion-icon">
                <FontIcon iconType="icon-CalendarLight" color="#FFFFFF" />
              </span>
            )}
            {reminder.type === 'EXAM_REQUEST' && (
              <span className="block absolute prescription-icon">
                <FontIcon iconType="icon-PaperclipLight" color="#FFFFFF" />
              </span>
            )}
          </div>
          <p className="ml-6 text-textPrimary text-fontExtraSmall">{textHome}</p>
        </div>
        <ButtonOptions
          buttonToAnchor={(
            <IconButton
              aria-label="Mostrar mais"
              variant="text"
              width="44px"
              height="44px"
              iconType="icon-MoreLight"
              iconSize="25px"
              onClick={openOptions}
            />
            )}
          visible={drawerIsVisible}
          onClose={closeOptions}
          headerText="Opções de ações para a sugestão de consulta"
          lastIdFocused={idComponent}
        >
          <div className="suggestion-bookings-options-modal">
            {reminder.type === 'SIGNUP' ? optionsModalTypeSignUp : optionsModalTypeExam}
          </div>
        </ButtonOptions>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onUpdateScheduleSuggestion: (
    type: string,
    bookingIds?: Array<string>,
    callback?: () => void
  ) => {
    dispatch(
      onUpdateBookingSuggestionAction(type, bookingIds, callback)
    )
  },
  getScheduleSuggestion: () => {
    dispatch(getScheduleSuggestionAction())
  },
  setPreviousSelectedCategory: (category?: SpecialistCategories) => {
    dispatch(setPreviousSelectedCategoryAction(category))
  }
})

export default connect(
  null,
  mapDispatchToProps
)(ScheduleSugestionBanner)
