/* eslint-disable no-nested-ternary */

import {
  primary,
  divider,
  bgBox,
  error,
  bgBoxSecondary,
  textDisable,
  overlayLightHover,
  textSecondary,
  textPrimary,
  bgButton
} from '../../colors'

export const customStyles = (
  isInvisible?: boolean,
  errorMessage?: string,
  size?: 'small' | 'medium' | 'large',
) => {
  function getBorderColor(state: any) {
    if (errorMessage) {
      return error
    }
    if (state.isFocused) {
      return primary
    }
    if (state.hasValue) {
      return textSecondary
    }

    if (state.isDisabled) {
      return textDisable
    }

    return divider
  }

  function getBackgroundColor(state: any) {
    if (state.isSelected) {
      return bgBoxSecondary
    }

    if (state.isDisabled) {
      return divider
    }

    return bgBox
  }

  function getFontSize() {
    if (size === 'small') {
      return '14px'
    }
    if (size === 'large') {
      return '22px'
    }
    return '16px'
  }

  function getLineHeight() {
    if (size === 'small') {
      return '20px'
    }
    if (size === 'large') {
      return '28px'
    }
    return '24px'
  }

  return {
    control: (base: any, state: any) => ({
      ...base,
      display: 'flex',
      boxSizing: 'border-box',
      borderRadius: '4px',
      height: '48px',
      marginTop: '8px',
      fontSize: getFontSize(),
      lineHeight: getLineHeight(),
      position: 'relative',
      outline: 'none',
      width: '100%',
      backgroundColor: getBackgroundColor(state),
      borderColor: getBorderColor(state),
      color: state.isDisabled ? textDisable : textPrimary,
      gridArea: 'none',
      boxShadow: 'none',
      '&:hover': {
        borderColor: primary,
      },
      '&:active': {
        borderColor: primary,
      },
      '&:focus': {
        borderColor: primary,
      },
      '&:disabled': {
        backgroundColor: divider,
        color: textDisable,
        borderColor: textDisable,
        cursor: 'default',
      },
      ...(isInvisible && {
        outline: 'none',
        minHeight: 'fit-content',
        height: 'fit-content',
        fontSize: getFontSize(),
        lineHeight: getLineHeight(),
        margin: 0,
        borderColor: errorMessage ? error : state.isFocused ? primary : 'transparent',
        backgroundColor: state.isFocused
          ? overlayLightHover
          : 'transparent',
        cursor: state.isDisabled ? 'default' : 'pointer',
        '&:focus': {
          borderColor: state.isDisabled ? 'transparent' : primary,
        },
        '&:active': {
          borderColor: state.isDisabled ? 'transparent' : primary,
        },
        '&:hover': {
          backgroundColor: state.isDisabled ? 'transparent' : bgBoxSecondary,
        },
        '&:disabled': {
          color: textDisable,
          borderColor: 'transparent',
          backgroundColor: 'transparent'
        }
      }),
    }),
    menu: (base: any) => ({
      ...base,
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
      border: '1px solid',
      borderColor: divider,
      fontSize: getFontSize(),
      lineHeight: getLineHeight(),
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 1032,
    }),
    loadingMessage: (base: any) => ({
      ...base,
      fontSize: '16px',
    }),
    option: (base: any, state: any) => ({
      ...base,
      borderColor: divider,
      fontSize: getFontSize(),
      lineHeight: getLineHeight(),
      cursor: 'pointer',
      color: textPrimary,
      background: state.isFocused ? bgButton : bgBox,
    }),
    placeholder: (base: any) => ({
      ...base,
      color: isInvisible ? textDisable : textSecondary,
      fontStyle: 'italic',
      fontSize: getFontSize(),
      lineHeight: getLineHeight(),
      whiteSpace: 'nowrap',
      fontWeight: 400
    }),
    input: (base: any) => ({
      ...base,
      margin: 0,
      padding: 0
    }),
    valueContainer: (base: any) => ({
      ...base,
      margin: 0,
      padding: isInvisible ? '0.375rem' : '0px 14px',
      fontWeight: isInvisible && size === 'large' ? 500 : 400,
    })
  }
}
