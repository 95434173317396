import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { AppState } from '../apps/main/store'
import './App.scss'
import './sales-onboarding/SalesOnboarding.scss'
import PrivateRoutes from '../routes/PrivateRoutes'
import PublicRoutes from '../routes/PublicRoutes'
import RouterListener from '../hooks/RouterListener'

interface Props {
  authenticated: boolean;
}
function App({
  authenticated,
}: Props) {
  return (
    <BrowserRouter>
      <RouterListener />
      {authenticated ? <PrivateRoutes /> : <PublicRoutes />}
    </BrowserRouter>
  )
}

const mapStateToProps = ({
  authentication
}: AppState) => ({
  authenticated: authentication.authenticated,
})

export default connect(
  mapStateToProps,
)(App)
