import React, { useEffect } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { Loader } from 'theia-web-ds'
import { connect } from 'react-redux'
import { logoutAction } from '../../state/authentication/main/actions'

interface Props {
  logout: () => void;
}

function LogoutMobile({ logout }: Props) {
  useEffect(() => {
    logout()
  }, [])
  return (
    <div className="flex justify-center h-screen w-full bg-bgCanvas">
      <div className="flex items-center h-full">
        <Loader />
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  logout: logoutAction
}, dispatch)

export default connect(
  null,
  mapDispatchToProps
)(LogoutMobile)
