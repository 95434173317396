import React, {
  HTMLAttributes, forwardRef, useRef
} from 'react'
import './TextArea.scss'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { UseCombinedRefs } from '../../utils/helpers'

export interface Props extends HTMLAttributes<HTMLTextAreaElement> {
  id: string;
  label?: string;
  placeholder?: string;
  rows?: number;
  name?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  hasError?: boolean;
  errorText?: string;
  value?: string;
  extraClass?: string;
}

export const TextArea: React.FC<Props> = forwardRef(({
  id,
  label,
  placeholder,
  rows = 4,
  value,
  name,
  disabled,
  onChange,
  hasError,
  errorText,
  extraClass,
  ...props
}: Props, forwardedRef) => {
  const innerRef = useRef<HTMLTextAreaElement>(null)
  const combinedRef = UseCombinedRefs(forwardedRef, innerRef)

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (onChange) {
      onChange(e)
    }
  }

  return (
    <div
      className={`textarea-container ${extraClass}`}
    >
      {label && (
        <label className="textarea-label" htmlFor={id}>{label}</label>
      )}
      <textarea
        id={id}
        name={name}
        placeholder={placeholder}
        aria-label={label || id}
        className={`textarea-input ${(hasError || errorText) ? 'textarea-input-error' : ''}`}
        rows={rows}
        disabled={disabled}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(e)}
        ref={combinedRef}
        value={value}
        {...props}
      />
      {errorText && (
        <ErrorMessage error={errorText} />
      )}
    </div>
  )
})
