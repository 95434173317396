import {
  SUCCESS_GET_PLATFORM,
  PlatformTypes
} from './actions'
import { Platform } from '../../domain/Platform';

export interface PlatformState {
  platformInfo: Platform;
}

const initialInviteState: PlatformState = {
  platformInfo: {
    maxInvites: 0,
    maxTrialConsultantBookings: 0,
    maxActiveConsultantBookings: 0,
  }
}

export function platform(state = initialInviteState, action: PlatformTypes): PlatformState {
  switch (action.type) {
    case SUCCESS_GET_PLATFORM: {
      return {
        ...state,
        platformInfo: action.platformInfo
      }
    }
    default:
      return state;
  }
}
