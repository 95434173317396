import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Loader } from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import { Post } from '../../domain/Content'
import Arrow from '../../../public/images/arrow.svg'
import { fetchPostAction } from '../../state/content/actions'
import './Content.scss'

interface Props {
  fetchPost: (id: string) => void;
  isFetchingPost: boolean;
  singlePost?: Array<Post>;
  id: string;
}

function SinglePost({
  fetchPost,
  isFetchingPost,
  singlePost,
  id,
}: Props) {
  const history = useHistory()
  const [postUrl, setPostUrl] = useState('')

  useEffect(() => {
    if (!isFetchingPost) {
      fetchPost(id)
    }
  }, [])

  useEffect(() => {
    if (singlePost) {
      const singlePostUrl = singlePost.map((post) => post.url)
      setPostUrl(singlePostUrl[0])
    }
  }, [singlePost])

  function goToContent() {
    history.goBack()
  }

  if (isFetchingPost) {
    return (
      <div className="flex justify-center h-screen w-full bg-bgCanvas">
        <div className="flex items-center h-full">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className="content-text flex flex-1 min-h-full relative">
      <div className="mx-auto w-full lg:max-w-xl">
        <div className="flex flex-col h-full rounded">
          <div className="flex-1 min-h-full m-auto w-full lg:pl-6 lg:pr-6 pl-4 pr-4 pt-8">
            <div className="mb-24 lg:mb-8 relative flex flex-col h-full single-content">
              <button onClick={() => goToContent()} className="flex flex-row pt-4 pb-4 focus:outline-none" type="button">
                <Arrow style={{ transform: 'rotate(90deg)', color: '#333333' }} className="self-center ml-4" />
                <span className="ml-4">Conteúdo</span>
              </button>
              {postUrl === '' && <Loader />}
              <iframe src={postUrl} width="100%" height="100%" title="Single Post" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ content }: AppState, { id }: { id: string }) => ({
  isFetchingPost: content.isFetchingPost,
  singlePost: content.singlePost,
  id
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchPost: (id: string) => { dispatch(fetchPostAction(id)) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SinglePost)
