import {
  HealthInsuranceCompany,
  HealthInsurancePlanData,
  HealthPlanData,
  HealthplanService,
  PostUserHealthPlanType
} from '../../domain/Healthplan'
import ApiClient from './ApiClient'

export default class ApiHealthplanService implements HealthplanService {
  constructor(private apiClient: ApiClient) { }

  async getHealthInsuranceCompanies(): Promise<HealthInsuranceCompany[]> {
    const healthInsuranceCompaniesResponse = await
    this.apiClient.getHealthInsuranceCompanies()
    return healthInsuranceCompaniesResponse
  }

  async getHealthInsurancePlanData(): Promise<HealthPlanData> {
    const healthInsuranceResponse = await this.apiClient.getHealthInsurancePlanData()
    return healthInsuranceResponse
  }

  postUserHealthInsurance(
    healthPlanData: PostUserHealthPlanType
  ): Promise<HealthInsurancePlanData> {
    return this.apiClient.postUserHealthInsurance(healthPlanData)
  }
}
