import {
  PaymentTypes,
  FETCH_PROMOTIONAL_CODE_SUCCESS,
  FETCH_PROMOTIONAL_CODE_FAILURE,
  FETCH_PROMOTIONAL_CODE_REQUESTED,
  CLEAN_PROMOTIONAL_CODE,
  CHANGE_COUPON_VALUE,
  CONFIRM_CARD_PAYMENT_METHOD,
  UPDATE_CARD_INFO_REQUESTED,
  SUCCESS_UPDATE_CARD_INFO,
  FAILURE_UPDATE_CARD_INFO,
  BACK_TO_PROFILE_FROM_UPDATE_CARD,
  SUCCESS_DELETE_CARD_INFO,
  PROMPT_NEW_CARD_ERROR,
  CLEAR_NEW_CARD_ERROR
} from './actions'

export interface PaymentState {
  price?: number;
  loadingPromotionalCode: boolean;
  originalPrice?: number;
  discount?: number;
  promotionalPrice?: number;
  promotionalCodeError?: {
    message?: string;
    title?: string;
  };
  productId?: string;
  couponValueFromUser?: string;
  paymentMethodId?: string;
  isSubmittingPaymentInfo: boolean;
  hasSubmittedPaymentInfo: boolean;
  isSuccessDeleted: boolean;
  showNewCardError: boolean;
}

export const initialPaymentState: PaymentState = {
  price: undefined,
  loadingPromotionalCode: false,
  originalPrice: undefined,
  discount: undefined,
  promotionalPrice: undefined,
  promotionalCodeError: undefined,
  productId: '',
  couponValueFromUser: undefined,
  paymentMethodId: undefined,
  isSubmittingPaymentInfo: false,
  hasSubmittedPaymentInfo: false,
  isSuccessDeleted: false,
  showNewCardError: false
}

export function payment(state = initialPaymentState, action: PaymentTypes): PaymentState {
  switch (action.type) {
    case FETCH_PROMOTIONAL_CODE_REQUESTED: {
      return {
        ...state,
        loadingPromotionalCode: true,
        originalPrice: undefined,
        discount: undefined,
        promotionalPrice: undefined,
        promotionalCodeError: undefined
      }
    }
    case FETCH_PROMOTIONAL_CODE_SUCCESS: {
      return {
        ...state,
        loadingPromotionalCode: false,
        originalPrice: action.originalPrice,
        discount: action.discount,
        promotionalPrice: action.promotionalPrice,
      }
    }
    case FETCH_PROMOTIONAL_CODE_FAILURE: {
      return {
        ...state,
        loadingPromotionalCode: false,
        promotionalCodeError: action.error,
      }
    }
    case CLEAN_PROMOTIONAL_CODE: {
      return {
        ...state,
        promotionalCodeError: undefined,
        loadingPromotionalCode: false,
        originalPrice: undefined,
        discount: undefined,
        promotionalPrice: undefined,
        couponValueFromUser: undefined,
      }
    }
    case CHANGE_COUPON_VALUE:
      return {
        ...state,
        couponValueFromUser: action.couponValue || undefined,
      }
    case CONFIRM_CARD_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethodId: action.paymentMethodId
      }
    case UPDATE_CARD_INFO_REQUESTED: {
      return {
        ...state,
        isSubmittingPaymentInfo: true
      }
    }
    case SUCCESS_UPDATE_CARD_INFO: {
      return {
        ...state,
        isSubmittingPaymentInfo: false,
        hasSubmittedPaymentInfo: true
      }
    }
    case FAILURE_UPDATE_CARD_INFO: {
      return {
        ...state,
        isSubmittingPaymentInfo: false
      }
    }
    case BACK_TO_PROFILE_FROM_UPDATE_CARD: {
      return {
        ...state,
        hasSubmittedPaymentInfo: false,
        isSuccessDeleted: false
      }
    }
    case SUCCESS_DELETE_CARD_INFO: {
      return {
        ...state,
        isSuccessDeleted: true,
      }
    }
    case PROMPT_NEW_CARD_ERROR: {
      return {
        ...state,
        showNewCardError: true,
      }
    }
    case CLEAR_NEW_CARD_ERROR: {
      return {
        ...state,
        showNewCardError: false,
      }
    }
    default: return state
  }
}
