import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RegularButton } from 'theia-web-ds'
import { AppState } from '../../../apps/main/store'
import { AppDispatch } from '../../../state/utils'
import CalendarCancelation from '../../../../assets/calendar_x.svg'
import {
  cancelBookingAction,
  suppressScheduleCancellationAction,
  getStepScheduleCancellationAction
} from '../../../state/scheduleCancellation/actions'
import Modal from '../../common/Modal'
import CancelButton from '../../common/buttons/CancelButton'

interface Props {
  closeModal: (step: any) => void;
  cancelBooking: () => void;
  isSubmitting: boolean;
  step: any;
  getBookingStep: (step: any) => void;
  isExam?: boolean;
}

function ScheduleCancellationReasonModal({
  cancelBooking,
  closeModal,
  isSubmitting,
  step,
  getBookingStep,
  isExam
}: Props) {
  useEffect(() => {
    getBookingStep(step)
  }, [])
  return (
    <Modal>
      <div className="schedule-cancelation-confirmation-modal flex flex-col text-center">
        <div className="self-center mb-4 ml-4">
          <CalendarCancelation />
        </div>
        <h1 className="modal__title">Ok, você tem certeza?</h1>
        <p className="modal__info pt-2">
          {isExam ? 'Este exame será cancelado' : 'Esta consulta será cancelada'}, mas continuaremos disponíveis para conversar com você.
        </p>
        <RegularButton
          label={`Cancelar ${isExam ? 'esse exame' : 'essa consulta'}`}
          onClick={cancelBooking}
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
          maxWidth="100%"
          extraClass="cancelation-confirmation-button mt-4 mb-4"
        />
        <CancelButton onClick={() => closeModal(step)}>Manter {isExam ? 'exame' : 'consulta'}</CancelButton>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ scheduleCancellation }: AppState) => ({
  step: scheduleCancellation.step,
  isExam: scheduleCancellation.isExam
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  closeModal: (step: any) => {
    dispatch(suppressScheduleCancellationAction(step))
  },
  cancelBooking: () => { dispatch(cancelBookingAction()) },
  getBookingStep: (step: any) => {
    dispatch(getStepScheduleCancellationAction(step))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleCancellationReasonModal)
