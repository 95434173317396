import React from 'react'
import { Store } from 'redux'
import { Provider } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import BookingRoom from '../../components/booking/BookingRoom'
import { browserHistory, AppState } from './store'
import { useAdjustableWindowHeight } from '../../hooks/AdjustableWindowHeight'
import '../../index.scss'
import { BOOKINGS } from '../../routes/RoutesConstants'

interface Props {
  store: Store<AppState>;
}

export default function ProfessionalApp({ store }: Props) {
  useAdjustableWindowHeight()

  return (
    <Provider store={store}>
      <ConnectedRouter history={browserHistory}>
        <Switch>
          <Route
            exact
            path={`${BOOKINGS}/:uuid`}
            render={({ match }) => <BookingRoom userType="professional" uuid={match.params.uuid} />}
          />
        </Switch>
      </ConnectedRouter>
    </Provider>
  )
}
