import React from 'react'
import { FontIcon } from 'theia-web-ds';

interface Props {
    iconType?: string;
    iconColor?: string;
    iconSize?: string;
    picture?: React.ReactNode;
    extraBadgeContainerClass?: string;
}

const ImageIconBadge = ({
  iconType,
  iconColor,
  iconSize,
  picture,
  extraBadgeContainerClass
}: Props) => (
  <div className="relative badge-image-container mr-6">
    <div className="image-container">
      {picture}
    </div>
    <div className={`${extraBadgeContainerClass} badge-container absolute`}>
      <FontIcon iconType={iconType} color={iconColor} fontSize={iconSize} />
    </div>
  </div>
)

export default ImageIconBadge
