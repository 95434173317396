/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'
import { textPrimary } from '../../colors'
import { IconButton } from '../IconButton/IconButton'
import './AccessibleModal.scss'
import { getKeyValue } from '../../utils/helpers'

export interface Props {
  children: React.ReactNode;
  extraModalContainerClass?: string;
  extraOuterClassName?: string;
  headerText: string;
  showCloseButton?: boolean;
  visible: boolean;
  lastIdFocused?: string;
  maxWidthClass?: string;
  hasBackButton?: boolean;
  titleText?: string;
  onClose: () => void;
  onBackButton?: () => void;
  variant?: 'centerBottom' | 'centerCenter';
  closeOnOutsideClick?: boolean;
}

export function AccessibleModal({
  headerText,
  children,
  extraModalContainerClass,
  extraOuterClassName,
  maxWidthClass,
  showCloseButton,
  visible,
  lastIdFocused,
  hasBackButton,
  titleText,
  onClose,
  onBackButton,
  variant = 'centerCenter',
  closeOnOutsideClick = true
}: Props) {
  function onCloseAndRefocused() {
    if (lastIdFocused !== undefined && lastIdFocused !== '') {
      if (document.getElementById(lastIdFocused)) {
        (document.getElementById(lastIdFocused) as any).focus()
      }
    }
    onClose()
  }

  function onKeyDown(event: KeyboardEvent) {
    if (event && event.key === 'Escape' && visible && closeOnOutsideClick) {
      onCloseAndRefocused()
    }
  }

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'
      document.addEventListener('keydown', onKeyDown, false)
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [visible])

  const modalVariants = { centerBottom: 'centered sm-bottom', centerCenter: 'centered', undefined: '' }

  const hasButtons = hasBackButton || showCloseButton

  function getHeaderStyle() {
    if (hasButtons && titleText) {
      return 'justify-between'
    }
    if (titleText && !hasButtons) {
      return 'justify-center'
    }
    if (!titleText && showCloseButton) {
      return 'justify-end'
    }
    return ''
  }

  const modal = (
    <FocusLock className={extraOuterClassName}>
      <div
        className="modal-overlay"
        onClick={closeOnOutsideClick ? onCloseAndRefocused : undefined}
        aria-hidden="true"
        role="dialog"
        data-testid="modal-overlay"
      />
      <div
        className={`modal-container ${getKeyValue(modalVariants, variant)} ${maxWidthClass} ${extraModalContainerClass}`}
        role="dialog"
        aria-modal
        aria-labelledby={headerText}
      >
        <div className={`header-modal flex items-center ${getHeaderStyle()}`}>
          {hasBackButton && (
            <IconButton
              aria-label="Voltar"
              variant="subtle"
              width="32px"
              height="32px"
              iconType="icon-ArrowLeftLight"
              iconSize="20px"
              iconColor={textPrimary}
              data-dismiss="modal"
              onClick={onBackButton}
            />
          )}
          {titleText && (
            <p className="text-titleMedium text-textPrimary font-semibold">
              {titleText}
            </p>
          )}
          {showCloseButton && (
            <IconButton
              aria-label="Fechar"
              variant="subtle"
              width="32px"
              height="32px"
              iconType="icon-CloseLight"
              iconSize="20px"
              iconColor={textPrimary}
              data-dismiss="modal"
              onClick={onCloseAndRefocused}
            />
          )}
        </div>
        {children}
      </div>
    </FocusLock>
  )
  return visible ? ReactDOM.createPortal(modal, document.body) : null
}
