import React from 'react'
import { Loader } from 'theia-web-ds'
import ChipButton from './ChipButton'

interface Props {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  isSubmitting?: boolean;
}

export default function ConfirmButton({
  onClick, children, className, disabled, isSubmitting
}: Props) {
  if (isSubmitting) {
    return (
      <div className="flex justify-center">
        <div className="flex items-center">
          <Loader />
        </div>
      </div>
    )
  }
  return (
    <ChipButton
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {children}
    </ChipButton>
  )
}
