export const PROMPT_DRAWER = 'PROMPT_DRAWER'
export const SUPPRESS_DRAWER = 'SUPPRESS_DRAWER'

interface PromptDrawerActionType {
  type: typeof PROMPT_DRAWER;
}

interface SuppressDrawerActionType {
  type: typeof SUPPRESS_DRAWER;
}

export type DrawerTypes = PromptDrawerActionType |
  SuppressDrawerActionType

export const promptDrawerAction = (): DrawerTypes => ({
  type: PROMPT_DRAWER
})

export const suppressDrawerAction = (): DrawerTypes => ({
  type: SUPPRESS_DRAWER
})
