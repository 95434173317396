import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Loader, RegularButton } from 'theia-web-ds'
import {
  Elements
} from '@stripe/react-stripe-js'
import { AppState } from '../../apps/main/store'
import withLoadStripe from './hoc/withLoadStripe'
import SchedulePaymentForm from './SchedulePaymentForm'
import { AppDispatch } from '../../state/utils'
import { changeCouponValueAction, clearNewCardErrorAction, fetchPromotionalPriceAction } from '../../state/payment/actions'
import { CreditCardInfos } from '../../domain/ScheduleFlow'
import './PaymentForm.scss'
import '../sales-onboarding/SalesOnboarding.scss'
import PaymentErrorModal from './PaymentErrorModal'

interface Props {
  children?: React.ReactNode;
  stripe: Promise<any | null>;
  stripeLoaded: boolean;
  stripeError: object | null;
  productId?: string;
  promotionalPrice?: number;
  coupon?: string;
  isSubmittingCardData?: boolean;
  showNewCardError: boolean;
  changeCouponValue: (couponValue?: string) => void;
  fetchPromotionalPrice: (codeCoupon?: string, id?: string) => void;
  extraOnSubmitCardData?: () => void;
  confirmNewCardId: (id: string) => void;
  setNewCardData: (newCardData: CreditCardInfos) => void;
  onSuccessExtraAction: () => void;
  changePaymentMethod: () => void;
  clearNewCardError: () => void;
}

function SchedulePayment({
  children,
  stripe,
  stripeLoaded,
  stripeError,
  productId,
  promotionalPrice,
  coupon,
  isSubmittingCardData,
  showNewCardError,
  changeCouponValue,
  fetchPromotionalPrice,
  extraOnSubmitCardData,
  confirmNewCardId,
  setNewCardData,
  onSuccessExtraAction,
  changePaymentMethod,
  clearNewCardError,
}: Props) {
  useEffect(() => {
    if (!promotionalPrice && coupon !== '' && productId && productId !== '') {
      fetchPromotionalPrice(coupon, productId)
    }
  }, [])

  useEffect(() => {
    changeCouponValue(coupon)
  }, [coupon])

  function handleCloseNewCardError() {
    clearNewCardError()
    changePaymentMethod()
  }

  if (!productId || (!stripeLoaded && !stripeError)) {
    return (
      <div className="flex justify-center h-screen w-full bg-bgCanvas">
        <div className="flex items-center h-full">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="payment-container pb-6">
        {children}
        <Elements stripe={stripe}>
          <SchedulePaymentForm
            stripeLoadError={!!stripeError}
            extraOnSubmitCardData={extraOnSubmitCardData}
            submitButtonText="Continuar"
            confirmNewCardId={(id: string) => confirmNewCardId(id)}
            setNewCardData={(newCardData: CreditCardInfos) => setNewCardData(newCardData)}
            onSuccessExtraAction={onSuccessExtraAction}
            isSubmittingCardData={isSubmittingCardData}
          />
        </Elements>
        <div className="payment-container-button">
          <RegularButton
            onClick={changePaymentMethod}
            label="Alterar forma de pagamento"
            variant="text"
            maxWidth="400px"
            extraClass="payment-change-type"
          />
        </div>
      </div>
      {showNewCardError && (
        <PaymentErrorModal
          visible={showNewCardError}
          onClose={handleCloseNewCardError}
        />
      )}
    </>
  )
}

const mapStateToProps = ({
  payment,
}: AppState) => ({
  promotionalPrice: payment.promotionalPrice,
  coupon: payment.couponValueFromUser,
  showNewCardError: payment.showNewCardError
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeCouponValue: (couponValue?: string) => {
    dispatch(changeCouponValueAction(couponValue))
  },
  fetchPromotionalPrice: (codeCoupon?: string, id?: string) => {
    dispatch(fetchPromotionalPriceAction(codeCoupon, id))
  },
  clearNewCardError: () => {
    dispatch(clearNewCardErrorAction())
  }
})

export default withLoadStripe(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SchedulePayment)
)
