import React, { useState } from 'react'
import { connect } from 'react-redux'
import { ContentCard } from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import ProfessionalInfoModal from '../professionals/ProfessionalInfoModal'
import { TheiaError } from '../../domain/errors/TheiaError'
import './ChooseSpecialist.scss'
import { SpecialistCategories, SpecialistDetails } from '../../domain/Specialist'
import { getSpecialistDetailsAction } from '../../state/specialists/actions'
import { AvailabilityTimeSlot, SlotSpecialist } from '../../domain/Availabilities'

interface Props {
  selectedTimeSlot?: AvailabilityTimeSlot;
  categoryName?: SpecialistCategories;
  isGettingSpecialistDetails: boolean;
  specialistDetails?: SpecialistDetails;
  specialistDetailsError?: TheiaError;
  getSpecialistDetails: (specialistId: string) =>void;
  onChooseSpecialist: (specialist: SlotSpecialist) => void;
}

function ChooseSpecialist({
  selectedTimeSlot,
  categoryName,
  isGettingSpecialistDetails,
  specialistDetails,
  specialistDetailsError,
  getSpecialistDetails,
  onChooseSpecialist,
}: Props) {
  const [showSpecialistDetail, setShowSpecialistDetail] = useState(false)
  const [openedSpecialist, setOpenedSpecialist] = useState<SlotSpecialist>()

  function handleShowSpecialistDetails(specialist?: SlotSpecialist) {
    if (specialist) {
      setOpenedSpecialist(specialist)
      getSpecialistDetails(specialist.chronosId)
      setShowSpecialistDetail(true)
    }
  }

  function closeSpecialistDetail() {
    setShowSpecialistDetail(false)
  }

  function handleChooseSpecialist(specialist?: SlotSpecialist) {
    if (specialist) {
      setShowSpecialistDetail(false)
      onChooseSpecialist(specialist)
    }
  }

  const headerText = selectedTimeSlot && selectedTimeSlot?.specialists.length > 1
    ? `${selectedTimeSlot?.specialists.length} especialistas disponíveis`
    : `${selectedTimeSlot?.specialists.length} especialista disponível`
  const dateText = selectedTimeSlot && selectedTimeSlot.startTime
    ? `${(selectedTimeSlot.startTime).formatToWeekdayAndMonthDay()} às ${(selectedTimeSlot.startTime).formatDateToHours()}`
    : ''

  return (
    <div className="choose-specialist-container">
      {selectedTimeSlot && selectedTimeSlot?.specialists.length > 1 && (
        <h1 className="choose-specialist-main-title">
          Com qual especialista você quer se consultar?
        </h1>
      )}
      <h3 className="choose-specialist-small-title">{headerText}</h3>
      <p className="choose-specialist-subtitle">{dateText}</p>
      {selectedTimeSlot && selectedTimeSlot?.specialists.map((specialist) => (
        <ContentCard
          onClick={() => handleShowSpecialistDetails(specialist)}
          key={specialist.chronosId}
          headerTitle={specialist.name}
          type="elevated"
          headerSubtitle={categoryName}
          avatarBefore={({
            src: specialist.pictureUrl,
          })}
          firstButtonProps={({
            label: 'Agendar',
            onClick: () => handleChooseSpecialist(specialist),
            id: 'choose-specialist-reschedule-btn'
          })}
          secondButtonProps={({
            label: 'Conhecer',
            onClick: () => handleShowSpecialistDetails(specialist),
            id: 'know-specialist-reschedule-btn'
          })}
          extraClass="mb-4"
        />
      ))}
      <ProfessionalInfoModal
        isLoading={isGettingSpecialistDetails}
        isVisible={showSpecialistDetail}
        specialistDetails={specialistDetails}
        onChooseSpecialist={() => handleChooseSpecialist(openedSpecialist)}
        onClose={closeSpecialistDetail}
        error={specialistDetailsError}
        onTryAgain={() => handleShowSpecialistDetails(openedSpecialist)}
      />
    </div>
  )
}

const mapStateToProps = ({
  specialists
}: AppState) => ({
  isGettingSpecialistDetails: specialists.isGettingSpecialistDetails,
  specialistDetails: specialists.specialistDetails,
  specialistDetailsError: specialists.specialistDetailsError,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSpecialistDetails: (specialistId: string) => {
    dispatch(getSpecialistDetailsAction(specialistId))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseSpecialist)
