import React from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import {
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import { User } from '../../domain/User'
import { onlyDigits } from '../../utils/helpers'
import './PaymentForm.scss'
import { promptNewCardErrorAction } from '../../state/payment/actions'
import {
  initialValues,
  PaymentFormValues,
  validationSchema,
  paymentFormStyle as style,
  checkCountryCode
} from './utils/helpers'
import { GenericPaymentForm } from './GenericPaymentForm'
import { promptDefaultErrorAction } from '../../state/defaultError/actions'
import { NewCardData } from '../../state/reschedule/reducers'

interface Props {
  stripeLoadError: boolean;
  submitButtonText: string;
  currentUser?: User;
  isSubmittingCardData?: boolean;
  coupon?: string;
  promotionalCodeHasError?: boolean;
  extraOnSubmitCardData?: () => void;
  promptNewCardError: () => void;
  confirmNewCardId: (id: string) => void;
  promptDefaultError: () => void;
  onSuccessExtraAction: () => void;
  setNewCardData: (newCardData: NewCardData) => void;
}

function SchedulePaymentForm({
  submitButtonText,
  currentUser,
  isSubmittingCardData,
  stripeLoadError,
  coupon,
  promotionalCodeHasError,
  promptNewCardError,
  extraOnSubmitCardData,
  promptDefaultError,
  confirmNewCardId,
  onSuccessExtraAction,
  setNewCardData,
}: Props) {
  const stripe = useStripe()
  const elements = useElements()

  return (
    <div className="flex flex-1 relative lg:mt-0">
      <div className="mx-auto w-full">
        <div className="flex flex-col">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async ({ country, name, postalCode }: PaymentFormValues, {
              setSubmitting
            }) => {
              if (extraOnSubmitCardData) {
                extraOnSubmitCardData()
              }
              if (!stripe || !elements) {
                promptDefaultError()
                setSubmitting(false)
                return;
              }
              const cardElement = elements.getElement(CardNumberElement) || elements.create('cardNumber', { style })
              const result = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                  name,
                  email: currentUser?.email,
                  address: {
                    postal_code: onlyDigits(postalCode),
                    country: checkCountryCode(country)
                  }
                },
              })
              if (result.paymentMethod && !result.error) {
                const { id } = result.paymentMethod
                const brand = result.paymentMethod.card?.brand || ''
                const lastDigits = result.paymentMethod.card?.last4 || ''
                confirmNewCardId(id)
                setNewCardData({ brand, lastDigits })
                onSuccessExtraAction()
              } else {
                promptNewCardError()
                setSubmitting(false)
              }
            }}
          >
            {(props) => GenericPaymentForm(
              props,
              stripeLoadError,
              submitButtonText,
              isSubmittingCardData,
              promotionalCodeHasError,
              coupon
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  authentication,
  payment
}: AppState) => ({
  currentUser: authentication.currentUser,
  coupon: payment.couponValueFromUser,
  promotionalCodeHasError: (
    !payment.loadingPromotionalCode
    && payment.promotionalCodeError
    && payment.promotionalCodeError?.message !== ''
  ),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  promptNewCardError: () => {
    dispatch(promptNewCardErrorAction())
  },
  promptDefaultError: () => {
    dispatch(promptDefaultErrorAction())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchedulePaymentForm)
