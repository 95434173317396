import React, { HTMLAttributes } from 'react'
import { FontIcon } from '../FontIcon/FontIcon'
import {
  error,
  primary,
  textDisable,
  textSecondary
} from '../../colors'
import { Divider } from '../Divider/Divider'
import { RegularButton } from '../RegularButton/RegularButton'
import { NewChipButton, Props as NewChipButtonProps } from '../NewChipButton/NewChipButton'
import './ContentCard.scss'

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}

export interface IconProps {
  icon: string;
  color?: string;
  disabled?: boolean;
  size?: string;
}

export interface ImageProps {
  src: string;
  height?: string;
  width?: string;
  disabled?: boolean;
}

export interface Props {
  type: 'elevated' | 'floating' | 'outlined' | 'filled';
  iconBefore?: IconProps;
  avatarBefore?: ImageProps;
  ilustraBefore?: ImageProps;
  headerTitle?: string;
  headerSubtitle?: string;
  headerSubtitleColor?: string;
  iconAfter?: IconProps;
  buttonAfter?: React.ReactNode;
  image?: string;
  contentTitle?: string;
  contentSubtitle?: string;
  content?: string;
  errorMessage?: string;
  firstButtonProps?: ButtonProps;
  secondButtonProps?: ButtonProps;
  chipProps?: NewChipButtonProps;
  extraClass?: string;
  ariaLabel?: string;
  disabled?: boolean;
  onClick?: () => void;
  onClickDisabled?: () => void;
  children?: React.ReactNode;
}

export function ContentCard({
  type,
  iconBefore,
  avatarBefore,
  ilustraBefore,
  headerTitle,
  headerSubtitle,
  headerSubtitleColor,
  iconAfter,
  buttonAfter,
  image,
  contentTitle,
  contentSubtitle,
  content,
  errorMessage,
  firstButtonProps,
  secondButtonProps,
  chipProps,
  extraClass,
  ariaLabel,
  disabled,
  onClick,
  onClickDisabled,
  children
}: Props) {
  const hasHeader = iconBefore
    || avatarBefore
    || ilustraBefore
    || headerTitle
    || headerSubtitle
    || iconAfter
  const hasContent = contentTitle || contentSubtitle || content
  const showButtons = firstButtonProps || secondButtonProps
  const isClickable = !!(onClick && !chipProps?.onClick)
  const isDisableClickable = !!(onClickDisabled)
  const primaryTextColor = disabled ? 'text-textDisable' : 'text-textPrimary'
  const secondaryTextColor = disabled ? 'text-textDisable' : 'text-textSecondary'

  function handleOnClick() {
    if ((disabled || chipProps?.disabled) && onClickDisabled) {
      onClickDisabled()
    } else if (onClick) {
      onClick()
    }
  }
  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      if ((disabled || chipProps?.disabled) && onClickDisabled) {
        onClickDisabled()
      } else if (onClick) {
        onClick()
      }
    }
  }
  const cardContent = (
    <>
      {hasHeader && (
        <div className="content-card-header">
          <div className="flex justify-start items-center flex-1">
            {iconBefore && (
              <FontIcon
                iconType={iconBefore.icon}
                color={(iconBefore.disabled || disabled)
                  ? textDisable
                  : (iconBefore.color || primary)}
                fontSize={iconBefore.size || '24px'}
                extraClass="mr-4"
              />
            )}
            {avatarBefore && (
              <img
                src={avatarBefore.src}
                alt={avatarBefore.src}
                height={avatarBefore.height || '40px'}
                width={avatarBefore.width || '40px'}
                className="img-before"
                style={{
                  filter: avatarBefore.disabled || disabled ? 'grayscale(100%)' : 'none'
                }}
              />
            )}
            {ilustraBefore && (
              <img
                src={ilustraBefore.src}
                alt={ilustraBefore.src}
                height={ilustraBefore.height || '40px'}
                width={ilustraBefore.width || '40px'}
                className="img-before"
                style={{
                  filter: ilustraBefore.disabled || disabled ? 'grayscale(100%)' : 'none'
                }}
              />
            )}
            <div className="content-card-header-text">
              {headerTitle && (
                <h1 className={`content-card-title ${primaryTextColor}`}>
                  {headerTitle}
                </h1>
              )}
              {headerSubtitle && (
                <h6 className={`subhead ${secondaryTextColor}`} style={{ color: headerSubtitleColor }}>
                  {headerSubtitle}
                </h6>
              )}
            </div>
          </div>
          {buttonAfter}
          {iconAfter && (
            <FontIcon
              iconType={iconAfter.icon}
              color={(iconAfter.disabled || disabled)
                ? textDisable
                : (iconAfter.color || textSecondary)}
              fontSize={iconAfter.size || '24px'}
              extraClass="ml-4"
            />
          )}
          {chipProps && (
            <NewChipButton
              disabled={disabled || chipProps.disabled}
              fontSize={chipProps.fontSize || '11px'}
              {...chipProps}
            />
          )}
        </div>
      )}
      {image && (
        <img
          src={image}
          alt={image}
          className="content-card-image"
          data-testid="content-card-image"
          style={{
            filter: disabled ? 'grayscale(100%)' : 'none'
          }}
        />
      )}
      {hasContent && (
        <div className="content-card-content">
          {contentTitle && (
            <h1 className={`content-card-title ${primaryTextColor}`}>{contentTitle}</h1>
          )}
          {contentSubtitle && (
            <h6 className={`subtitle ${secondaryTextColor}`}>{contentSubtitle}</h6>
          )}
          {content && (
            <p className={`content ${secondaryTextColor}`}>{content}</p>
          )}
        </div>
      )}
      {errorMessage && (
        <div className="content-card-error-message" style={{ color: error }}>
          <FontIcon
            color={error}
            fontSize="14px"
            iconType="icon-DangerBold"
            extraClass="mr-2"
          />
          <p>{errorMessage}</p>
        </div>
      )}
    </>
  )

  const buttonsContainer = (
    showButtons && (
      <>
        <Divider />
        <div className="buttons-container">
          {secondButtonProps && (
            <RegularButton
              variant="secondary-text"
              type="button"
              onClick={secondButtonProps.onClick}
              btnSize="small"
              label={secondButtonProps.label}
              aria-label={secondButtonProps.label}
              disabled={secondButtonProps.disabled}
            />
          )}
          {firstButtonProps && (
            <RegularButton
              variant="text"
              type="button"
              onClick={firstButtonProps.onClick}
              btnSize="small"
              label={firstButtonProps.label}
              aria-label={firstButtonProps.label}
              disabled={firstButtonProps.disabled}
            />
          )}
        </div>
      </>
    )
  )

  return (isClickable || isDisableClickable) ? (
    <div
      className={`content-card-container ${type} ${extraClass}`}
      data-testid="content-card-container"
    >
      <div
        className="content-card-button"
        onClick={handleOnClick}
        data-testid="content-card-button"
        aria-label={ariaLabel}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        {cardContent}
      </div>
      {children}
      {buttonsContainer}
    </div>
  ) : (
    <div
      className={`content-card-container ${type} ${extraClass}`}
      data-testid="content-card-container"
    >
      {cardContent}
      {children}
      {buttonsContainer}
    </div>
  )
}
