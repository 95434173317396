import { ToastInfos } from '../../domain/Notifications'
import {
  CLEAR_NOTIFICATION,
  SHOW_NOTIFICATION,
  NotificationTypes
} from './actions'

export interface NotificationsState {
  showToast: boolean;
  toastInfos?: ToastInfos;
}

const initialNotifications: NotificationsState = {
  showToast: false,
  toastInfos: undefined,
}

export function notifications(
  state = initialNotifications,
  action: NotificationTypes
): NotificationsState {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      return {
        ...state,
        showToast: true,
        toastInfos: {
          ...action.toastInfos
        }
      }
    }
    case CLEAR_NOTIFICATION: {
      return {
        ...state,
        ...initialNotifications
      }
    }
    default: return state
  }
}
