import { HttpClient } from '../Http'
import { BookingView } from '../../domain/Booking'
import { parseBookingView } from './utils'
import { TheiaError } from '../../domain/errors/TheiaError'
import { API_URL } from './ApiClient'

export interface ApiVideoClient {
  getTwilioVideoToken(uuid: string): Promise<BookingView>;
}

export class ProfessionalApiClient implements ApiVideoClient {
  constructor(private httpClient: HttpClient, private professionalToken: string) { }

  async getTwilioVideoToken(uuid: string): Promise<BookingView> {
    const url = `${API_URL}/video/token?roomName=${uuid}&token=${this.professionalToken}`
    const response = await this.httpClient.get({ url })
    const jsonParse = JSON.parse(response.body)
    if (response.status !== 200) {
      const responseJSON = jsonParse.error
      throw new TheiaError(responseJSON.errorMessage, responseJSON.friendlyMessage)
    }
    return parseBookingView(jsonParse)
  }
}
