import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import './MyBookingsView.scss'
import {
  fetchBookingHistoryAction,
  onShowBookingDetailsFromBookingHistoryAction,
} from '../../state/myBookingsFlow/actions'
import { Booking } from '../../domain/Profile'
import BookingListView from './BookingListView'
import EmptyView from '../common/EmptyView'
import Helpers from './helpers'
import EmptyHistory from '../../../assets/empty-history.svg'
import { AGENDAR } from '../../routes/RoutesConstants'
import { SourceTypes } from '../../domain/ScheduleFlow'
import { setScheduleSourceAction } from '../../state/scheduleFlow/actions'

interface Props {
  fetchBookingHistory: () => void;
  isFetchingBookingHistory: boolean;
  bookingHistory: Booking[];
  bookingHistoryByMonth: Map<string, Array<Booking>>;
  goToAllBookings: () => void;
  onShowBookingDetailsFromBookingHistory: () => void;
  setScheduleSource: (source: SourceTypes) => void;
}

function BookingHistoryView({
  isFetchingBookingHistory,
  fetchBookingHistory,
  bookingHistory,
  bookingHistoryByMonth,
  goToAllBookings,
  onShowBookingDetailsFromBookingHistory,
  setScheduleSource
}: Props) {
  const history = useHistory()
  useEffect(() => {
    if (!isFetchingBookingHistory && bookingHistory.length === 0) {
      fetchBookingHistory()
    }
  }, [])
  const goToSchedule = () => {
    setScheduleSource(SourceTypes.BOOKING)
    history.push(AGENDAR)
  }

  return (
    <BookingListView
      isFetchingBookings={isFetchingBookingHistory}
      bookingsLength={bookingHistory.length}
      bookingsByMonth={bookingHistoryByMonth}
      onShowBookingDetails={onShowBookingDetailsFromBookingHistory}
      emptyComponent={(
        <EmptyView
          title="Você ainda não realizou nenhum agendamento"
          subtitle="Ao realizar um agendamento, todas as informações vão estar aqui."
          icon={<EmptyHistory />}
          extraActionButton={goToAllBookings}
          extraActionText="Ver agendamentos"
          goToSpecialist={goToSchedule}
        />
      )}
    />
  )
}

const mapStateToProps = ({
  myBookingsFlow
}: AppState) => {
  const bookingHistoryFiltered = myBookingsFlow.bookingHistory.filter((booking) => (
    !Helpers.isCancelledBooking(booking.state)
    && !Helpers.isConfirmedBooking(booking.state)
  ))
  return {
    bookingHistory: bookingHistoryFiltered,
    isFetchingBookingHistory: myBookingsFlow.isFetchingBookingHistory,
    bookingHistoryByMonth: bookingHistoryFiltered.reduce((resultMap, booking) => {
      const month = booking.startDate.formatFullMonth()
      const year = booking.startDate.formatYear()
      const title = `${month} ${year}`
      if (resultMap.has(title)) {
        resultMap.get(title).push(booking)
      } else {
        resultMap.set(title, [booking])
      }
      return resultMap
    }, new Map())
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  fetchBookingHistory: fetchBookingHistoryAction,
  onShowBookingDetailsFromBookingHistory: onShowBookingDetailsFromBookingHistoryAction,
  setScheduleSource: setScheduleSourceAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingHistoryView)
