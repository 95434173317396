import React, { useEffect, useRef, useState } from 'react'
import Select, { Props as SelectProps, components } from 'react-select'
import { FontIcon } from '../FontIcon/FontIcon'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { customStyles } from './CustomStyles'
import { Alert } from '../Alert/Alert'
import './FormSelect.scss'
import { error, textDisable, textPrimary } from '../../colors'
import { UseCombinedRefs } from '../../utils/helpers'

export interface ValueType {
  value: string;
  label: string;
}
export interface FormSelectProps extends SelectProps {
  label?: string;
  name: string;
  ref?: any;
  errorMessage?: string;
  size?: 'small' | 'medium' | 'large';
  isInvisible?: boolean;
  width?: string;
  extraClass?: string;
  readOnly?: boolean;
  isDisabled?: boolean;
  value: ValueType | null;
  placeholder?: string;
  textInfo?: string;
  hideMenuOptions?: string;
}

function DropdownIndicator(
  props: any,
  isInvisible?: boolean,
  errorMessage?: string
) {
  const { isFocused, isDisabled } = props
  function getColor() {
    if (errorMessage) return error
    if (isDisabled) return textDisable
    return textPrimary
  }
  return (
    <components.DropdownIndicator {...props}>
      <FontIcon
        iconType="icon-Down2Bold"
        color={getColor()}
        extraClass={`absolute self-center right-2 top-1/2 transform -translate-y-1/2 ${
          isInvisible && !isFocused ? 'hidden' : 'flex'
        }`}
        fontSize="20px"
      />
    </components.DropdownIndicator>
  )
}

export function FormSelect({
  label,
  name,
  size,
  isInvisible,
  ref,
  errorMessage,
  width,
  extraClass,
  readOnly,
  isDisabled,
  value,
  placeholder,
  textInfo,
  hideMenuOptions,
  ...rest
}: FormSelectProps) {
  const [showTextInfo, setShowTextInfo] = useState(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const combinedRef = UseCombinedRefs(wrapperRef, ref)

  useEffect(() => {
    if (hideMenuOptions) {
      wrapperRef.current?.blur()
    }
  }, [hideMenuOptions])

  function handleShowTextInfo() {
    if (textInfo && (isDisabled || readOnly)) setShowTextInfo(true)
  }

  function handleHideTextInfo() {
    setShowTextInfo(false)
  }

  return (
    <div
      className={`
        form-select-container
        ${extraClass}
      `}
      style={{ width: width || '100%' }}
      onMouseEnter={handleShowTextInfo}
      onMouseLeave={handleHideTextInfo}
    >
      <label htmlFor={name}>{label}</label>
      {readOnly ? (
        <div
          className={`
            form-select-readonly-text
            form-select-readonly-text-${size || 'medium'}
            ${isDisabled ? 'disabled' : ''}
            ${placeholder ? 'placeholder' : ''}
          `}
          data-testid="form-select-readonly-text"
        >
          {value?.label || placeholder}
          {showTextInfo && (
            <Alert
              extraClass="alert-form-select"
              message={textInfo}
              indicator="left-top"
            />
          )}
        </div>
      ) : (
        <>
          <Select
            components={{
              DropdownIndicator: (props) => DropdownIndicator(
                props,
                isInvisible,
                errorMessage
              ),
              LoadingIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            loadingMessage={() => 'Carregando...'}
            ref={combinedRef}
            isDisabled={isDisabled}
            value={value}
            placeholder={placeholder}
            styles={customStyles(isInvisible, errorMessage, size || 'medium')}
            {...rest}
          />
          {showTextInfo && (
            <Alert
              extraClass="alert-form-select disabled"
              message={textInfo}
              indicator="left-top"
            />
          )}
          {errorMessage && <ErrorMessage error={errorMessage} />}
        </>
      )}
    </div>
  )
}
