import React, { useEffect, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { bindActionCreators } from 'redux'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { RegularButton, FormInput } from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import AppMainContainerOff from '../AppMainContainerOff'
import { FaqToggle } from '../common/FaqToggle'
import { onPostHealthInsuranceAction } from '../../state/onboarding/actions'
import { HealthInsurancePlans } from '../../domain/Healthplan'
import { notUndefined } from '../../utils/helpers'
import CardFaq from '../../../assets/card-faq.png'
import './HealthInsurance.scss'
import SelectInputSearch from '../common/SelectInputSearch'
import { eventPage, eventTrack } from '../../../eventGenerate'
import { ConvenioFeedbackParams } from './HealthInsuranceFeedback'
import { CONVENIO_FEEDBACK } from '../../routes/RoutesConstants'

interface Props {
  onPostHealthInsurance: (
    hasHealthInsurancePlan: boolean,
    company: string,
    planName: string,
    memberId: string,
    companyId: string,
    planId: string,
    benefitsTitle: string | null,
    benefitsSubtitle: string | null,
  ) => void;
  isLoading: boolean;
}

interface HealthInsurancePlanForm {
  hasHealthInsurancePlan: boolean;
  companyName: string;
  planName: string;
  memberId: string;
  companyId: string;
  planId: string;
  otherCompany: string;
}

export interface ConvenioInformacoesParams {
  plans: HealthInsurancePlans[];
  companyName: string;
  companyId: string;
}

function rendeHealthInsurancePlanForm(
  {
    handleSubmit,
    isSubmitting,
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue
  }: FormikProps<HealthInsurancePlanForm>,
  isLoading: boolean
) {
  const history = useHistory()
  const [plansOptions, setPlansOptions] = useState<string[]>()
  const params = useLocation().state as ConvenioInformacoesParams || {}
  const [included, setIncluded] = useState(false)
  const allPlans = params.plans || []
  const companyName = params.companyName || null
  const companyId = params.companyId || null

  useEffect(() => {
    eventPage('onboarding', 'Informar Dados do Plano')
    setFieldValue('companyName', companyName)
    setFieldValue('companyId', companyId)
  }, [])

  useEffect(() => {
    if (allPlans && allPlans.length > 0) {
      const plansNames = allPlans.map((item: HealthInsurancePlans) => item.planName)
      setPlansOptions(plansNames)
    }
  }, [allPlans])

  const isCompanyNameEmpty = !values.companyName || values.companyName === ''
  const isOtherCompanySelected = values.companyName === 'Outro'
  const isOtherCompanyEmpty = isOtherCompanySelected && (!values.otherCompany || values.otherCompany === '')
  const isPlanNameOrMemberIdEmpty = !isCompanyNameEmpty
    && !isOtherCompanySelected && !values.planName

  const disabledButton = isCompanyNameEmpty || isOtherCompanyEmpty || isPlanNameOrMemberIdEmpty

  const onSubmit = () => {
    eventTrack('Clicou Continuar Dados Convenio')
    handleSubmit()
    history.push(CONVENIO_FEEDBACK, {
      companyName: values.companyName
    } as ConvenioFeedbackParams)
  }

  useEffect(() => {
    if (values.planName && values.planName !== '') {
      const selected = allPlans.find((item) => item.planName === values.planName)
      if (selected) {
        setFieldValue('planId', selected.planId)
        setIncluded(selected.included)
      }
    } else {
      setFieldValue('memberId', '')
      setFieldValue('planId', '')
    }
  }, [values.planName, allPlans])

  return (
    <AppMainContainerOff hasNewLayout hasBackButton>
      <div className="onboarding-container">
        <h3 className="text-textPrimary text-textMedium text-center mb-6">
          Complete os dados do seu plano
        </h3>
        <div className="mb-4">
          <SelectInputSearch
            name="planName"
            options={plansOptions}
            placeholder="Selecione seu plano"
            disabled={isLoading}
            label="Nome do plano"
            searchInputPlaceholder="Digite seu plano"
            extraOnBlur={() => eventTrack('Selecionou Nome do Plano')}
          />
        </div>
        {included && (
          <div className="mb-4">
            <FormInput
              label="Número do carteirinha"
              type="text"
              value={values.memberId}
              onChange={handleChange}
              errorMessage={touched.memberId ? errors.memberId : ''}
              id="memberId"
              onBlur={(value) => {
                handleBlur(value)
                eventTrack('Digitou Carteirinha')
              }}
              name="memberId"
              placeholder="Digite o número do carteirinha"
            />
          </div>
        )}
        <div className="mb-6">
          <RegularButton
            onClick={onSubmit}
            label="Continuar"
            isSubmitting={isSubmitting}
            disabled={disabledButton || isSubmitting}
          />
        </div>
      </div>
      <FaqToggle
        toggleTitle="Onde encontro esses dados na carteirinha?"
        iconType="icon-ChatHelpLight"
        extraAction={() => eventTrack('Clicou Onde Encontra Dados Carteirinha')}
      >
        <div className="flex flex-col items-center">
          <img src={CardFaq} alt="" />
          <p className="mt-4 text-textPrimary text-fontDefaul">Você encontra os dados do plano na sua carteirinha virtual da {companyName}.</p>
          <div className="flex items-center mt-6">
            <span className="number-icon text-secondary font-medium">1</span>
            <p className="flex-1 text-textSecondary text-fontDefault ml-4 text-faq-line">
              O seguro ou número da carteirinha fica localizado logo abaixo do seu nome,
              como mostra na figura acima.
            </p>
          </div>
          <div className="flex items-center mt-4">
            <span className="number-icon text-secondary font-medium">2</span>
            <p className="flex-1 text-textSecondary text-fontDefault ml-4">
              E o nome do plano está ao lado direito do número da carteirinha.
            </p>
          </div>
        </div>
      </FaqToggle>
    </AppMainContainerOff>
  )
}
const initialValues = {
  hasHealthInsurancePlan: true,
  companyName: '',
  planName: '',
  memberId: '',
  companyId: '',
  planId: '',
  otherCompany: ''
}

function HealthInsurancePlan({
  onPostHealthInsurance,
  isLoading
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({
        hasHealthInsurancePlan,
        companyName,
        planName,
        memberId,
        companyId,
        planId,
        otherCompany
      }) => {
        const company = otherCompany || companyName
        onPostHealthInsurance(
          hasHealthInsurancePlan,
          company,
          planName,
          memberId,
          companyId,
          planId,
          null,
          null,
        )
      }}
    >
      {(props) => rendeHealthInsurancePlanForm(
        props,
        isLoading
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  onboarding
}: AppState) => {
  const allPlans = Object.values(onboarding.byCompanyName)
    .map((p) => p.plans)
    .filter(notUndefined)
  return {
    isLoading: onboarding.isLoading,
    plans: allPlans,
    insurancePlanCovered: onboarding.insurancePlanCovered,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onPostHealthInsurance: onPostHealthInsuranceAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthInsurancePlan)
