import React from 'react'
import { useHistory } from 'react-router-dom';
import Arrow from '../../../../public/images/arrow.svg'

interface Props {
  text: string;
  to: string;
  extraAction?: () => void;
  extraClass?: string
}

export default function BackButton({
  text,
  to,
  extraAction,
  extraClass
}: Props) {
  const history = useHistory();

  function goBack() {
    if (extraAction) {
      extraAction()
    }
    history.push(to)
  }

  return (
    <button
      onClick={goBack}
      className="flex flex-row pt-4 pb-4 focus:outline-none"
      type="button"
    >
      <Arrow
        style={{ transform: 'rotate(90deg)', color: '#333333' }}
        className={`self-center ml-4 ${extraClass}`}
        data-testid="arrow"
      />
      <span className="ml-4" style={{ color: '#585858' }}>
        {text}
      </span>
    </button>
  )
}
