import React, { useEffect } from 'react'
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useLocation
} from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Loader } from 'theia-web-ds'
import LogoutMobile from '../components/authentication/LogoutMobile'
import { AppState } from '../apps/main/store'
import { PaymentStatus, UnauthenticatedModalError, User } from '../domain/User'
import BookingRoom from '../components/booking/BookingRoom'
import SinglePost from '../components/content/SinglePost'
import ProfessionalInfo from '../components/professionals/ProfessionalInfo'
import HealthInfos from '../components/profile/HealthInfos'
import GestationalInfos from '../components/profile/GestationalInfos'
import PersonalInfos from '../components/profile/PersonalInfos'
import ChooseUsgDate from '../components/usgFlow/ChooseUsgDate'
import ScheduleFlow from '../components/scheduleFlow/ScheduleFlow'
import AppMainContainer from '../components/AppMainContainer'
import Profile from '../components/profile/Profile'
import EditAccessDataForm from '../components/profile/EditAccessDataForm'
import EditAddressForm from '../components/profile/EditAddressForm'
import EditPaymentInfoProfile from '../components/profile/payment/EditPaymentInfoProfile'
import HealthPlanIncluded from '../components/profile/healthplan/HealthPlanIncluded'
import ChangeHealthPlanProfile from '../components/profile/healthplan/ChangeHealthPlanProfile'
import FeedbackHealthPlanProfile from '../components/profile/healthplan/FeedbackHealthPlanProfile'
import PrescriptionsView from '../components/healthData/prescriptions/PrescriptionsView'
import AllPosts from '../components/content/AllPosts'
import ScheduleView from '../components/scheduleFlow/ScheduleView'
import WeekView from '../components/weekFlow/WeekView'
import QuarterDetailsView from '../components/guidedJourney/QuarterDetailsView'
import MyBookingsView from '../components/myBookingsFlow/MyBookingsView'
import HealthDataView from '../components/healthData/HealthDataView'
import ChatView from '../components/weekFlow/Chat'
import PediatricRegistrationForm from '../components/pediatricFlow/PediatricRegistrationForm'
import SalesOnboardingSuccess from '../components/sales-onboarding/SalesOnboardingSuccess'
import Localization from '../components/onboarding/Localization'
import HealthInsurance from '../components/onboarding/HealthInsurance'
import HealthInsuranceType from '../components/onboarding/HealthInsuranceType'
import HealthInsurancePlan from '../components/onboarding/HealthInsurancePlan'
import HealthInsuranceFeedback from '../components/onboarding/HealthInsuranceFeedback'
import { getFlutterClient, isAccessTokenValid, isRefreshTokenValid } from '../utils/helpers'
import { useNewMessageNotifier } from '../hooks/NewMessageNotifier'
import { AppDispatch } from '../state/utils'
import { fetchAllProfessionalsAction } from '../state/professionals/actions'
import {
  clearForceUpdateCurrentUserAction,
  forceFetchedUserAction,
  handleRefreshTokenAction,
  logoutAction,
  promptUnauthenticatedModalAction,
  updateCurrentUserInfosAction
} from '../state/authentication/main/actions'
import { getAllWeekOptionsAction } from '../state/weekFlow/actions'
import { fetchProfileAction } from '../state/profile/actions'
import {
  BOOKINGS,
  DADOS_GESTACIONAIS,
  DADOS_PESSOAIS,
  DADOS_DE_SAUDE,
  LOCALIZACAO,
  LOGOUT_MOBILE,
  OLD_SPECIALISTS,
  AGENDAMENTO_ULTRASSOM,
  NOVO_AGENDAMENTO,
  PLANO_DE_SAUDE,
  CONVENIO,
  CONVENIO_INFORMACOES,
  CONVENIO_FEEDBACK,
  SUCESSO_CADASTRO,
  INICIO,
  JORNADA_EXPLICADA,
  PROFILE,
  EDIT_PROFILE,
  EDIT_ADDRESS,
  EDIT_PAYMENT,
  HEALTH_PLAN_INCLUDED,
  CHANGE_HEALTH_PLAN,
  HEALTHPLAN_FEEDBACK,
  MEDICAL_REQUESTS,
  CONTENT,
  CONSULTAS,
  SAUDE,
  AGENDAR,
  CADASTRO_PEDIATRICO,
  ESPECIALISTA,
  CHAT
} from './RoutesConstants'
import { identifyUser } from '../../eventGenerate'
import RedirectOldSpecialistsLinks from '../components/common/RedirectOldSpecialistsLinks'
import ScheduleCancellationModalsGroup from '../components/schedule/cancellation/ScheduleCancellationModalsGroup'
import BrowserErrorModal from '../components/booking/BrowserErrorModal'
import FeedbackModalsGroup from '../components/feedback/FeedbackModalsGroup'
import RescheduleModalsGroup from '../components/rescheduleFlow/RescheduleModalsGroup'
import DesireModalsGroup from '../components/maternalDesire/DesireModalsGroup'
import PediatricWelcomeModal from '../components/pediatricFlow/PediatricWelcomeModal'
import PhoneValidationModal from '../components/profile/accountValidation/PhoneValidationModal'
import EmailValidationModal from '../components/profile/accountValidation/EmailValidationModal'

interface Props {
  currentUser?: User;
  timesFetchedUser: number;
  paymentStatus?: string;
  forceUpdateWeeks: boolean;
  isFetchingWeeks: boolean;
  forceUpdateCurrentUser: boolean;
  isFetchingUser: boolean;
  isFetchingProfile: boolean;
  updateCurrentUserInfos: () => void;
  fetchProfessionals: () => void;
  forceFetchedUser: () => void;
  getAllWeekOptions: () => void;
  clearForceUpdateCurrentUser: () => void;
  fetchProfile: () => void;
  handleRefreshToken: () => void;
  logout: () => void;
  promptUnauthenticatedModal: (errorType: UnauthenticatedModalError) => void
}

const PrivateRoutes = React.memo(({
  currentUser,
  timesFetchedUser,
  paymentStatus,
  forceUpdateWeeks,
  isFetchingWeeks,
  forceUpdateCurrentUser,
  isFetchingUser,
  isFetchingProfile,
  updateCurrentUserInfos,
  fetchProfessionals,
  forceFetchedUser,
  getAllWeekOptions,
  clearForceUpdateCurrentUser,
  fetchProfile,
  handleRefreshToken,
  logout,
  promptUnauthenticatedModal,
}: Props) => {
  const location = useLocation()
  const isFlutterClient = getFlutterClient()
  useNewMessageNotifier()

  useEffect(() => {
    const interval: NodeJS.Timer = setInterval(() => {
      if (!isAccessTokenValid() && isRefreshTokenValid() && !isFlutterClient) {
        handleRefreshToken()
      }
      if (!isAccessTokenValid() && !isRefreshTokenValid() && !isFlutterClient) {
        logout()
        promptUnauthenticatedModal(UnauthenticatedModalError.INACTIVITY)
      }
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (currentUser) {
      identifyUser(currentUser)
    }
    if (currentUser?.email) {
      localStorage.setItem('userEmail', currentUser.email)
    }
  }, [currentUser])

  useEffect(() => {
    if (forceUpdateWeeks && !isFetchingWeeks) {
      getAllWeekOptions()
    }
  }, [forceUpdateWeeks])

  useEffect(() => {
    if (forceUpdateCurrentUser && !isFetchingUser && !isFetchingProfile) {
      fetchProfile()
      updateCurrentUserInfos()
      clearForceUpdateCurrentUser()
    }
  }, [forceUpdateCurrentUser, isFetchingUser, isFetchingProfile])

  useEffect(() => {
    if (timesFetchedUser < 5 && paymentStatus === PaymentStatus.Pending) {
      setTimeout(() => {
        forceFetchedUser()
        updateCurrentUserInfos()
      }, 10000);
    } else if (timesFetchedUser !== 0 && paymentStatus === PaymentStatus.Approved) {
      fetchProfessionals()
    }
  }, [timesFetchedUser, paymentStatus])

  const renderBooking = ({ match }: RouteComponentProps<any>) => (
    <BookingRoom userType="consultant" uuid={match.params.uuid} />
  )

  const renderSingleContent = ({ match }: RouteComponentProps<any>) => (
    <SinglePost id={match.params.id} />
  )

  const renderProfessionalInfo = ({ match }: RouteComponentProps<any>) => (
    <ProfessionalInfo id={match.params.id} />
  )

  if (!currentUser) {
    return (
      <div id="appLoader" className="container mx-auto lg:max-w-xl app-loader">
        <div className="flex items-center h-full">
          <Loader />
        </div>
      </div>
    )
  }

  const routesWithoutMenu = [
    LOGOUT_MOBILE,
    `${BOOKINGS}/:uuid`,
    DADOS_DE_SAUDE,
    DADOS_GESTACIONAIS,
    DADOS_PESSOAIS,
    AGENDAMENTO_ULTRASSOM,
    NOVO_AGENDAMENTO,
    LOCALIZACAO,
    PLANO_DE_SAUDE,
    CONVENIO,
    CONVENIO_INFORMACOES,
    CONVENIO_FEEDBACK,
    SUCESSO_CADASTRO
  ]

  return (
    <AppMainContainer showMenu={!routesWithoutMenu.includes(location.pathname)}>
      <div id="app" className="app">
        <ScheduleCancellationModalsGroup />
        <BrowserErrorModal />
        <FeedbackModalsGroup />
        <RescheduleModalsGroup />
        <DesireModalsGroup />
        <PediatricWelcomeModal />
        <EmailValidationModal />
        <PhoneValidationModal />
        <Switch>
          <Redirect exact from="/" to={INICIO} />
          <Redirect exact from="/index.html" to={INICIO} />
          {/* Rotas sem menus - tela cheia */}
          <Route exact path={LOGOUT_MOBILE} component={LogoutMobile} />
          <Route exact path={`${BOOKINGS}/:uuid`} render={renderBooking} />
          <Route exact path={DADOS_DE_SAUDE} component={HealthInfos} />
          <Route exact path={DADOS_GESTACIONAIS} component={GestationalInfos} />
          <Route exact path={DADOS_PESSOAIS} component={PersonalInfos} />
          <Route exact path={AGENDAMENTO_ULTRASSOM} component={ChooseUsgDate} />
          <Route exact path={NOVO_AGENDAMENTO} component={ScheduleFlow} />
          <Route exact path={LOCALIZACAO} component={Localization} />
          <Route exact path={PLANO_DE_SAUDE} component={HealthInsurance} />
          <Route exact path={CONVENIO} component={HealthInsuranceType} />
          <Route exact path={CONVENIO_INFORMACOES} component={HealthInsurancePlan} />
          <Route exact path={CONVENIO_FEEDBACK} component={HealthInsuranceFeedback} />
          <Route exact path={SUCESSO_CADASTRO} component={SalesOnboardingSuccess} />
          {/* Rotas com menus */}
          <Route exact path={INICIO} component={WeekView} />
          <Route exact path={AGENDAR} component={ScheduleView} />
          <Route exact path={CHAT} component={ChatView} />
          <Route exact path={CONSULTAS} component={MyBookingsView} />
          <Route exact path={SAUDE} component={HealthDataView} />
          <Route exact path={CONTENT} component={AllPosts} />
          <Route path={PROFILE} component={Profile} />
          <Route path={HEALTH_PLAN_INCLUDED} component={HealthPlanIncluded} />
          <Route path={CHANGE_HEALTH_PLAN} component={ChangeHealthPlanProfile} />
          <Route path={HEALTHPLAN_FEEDBACK} component={FeedbackHealthPlanProfile} />
          <Route path={EDIT_ADDRESS} component={EditAddressForm} />
          <Route path={EDIT_PROFILE} component={EditAccessDataForm} />
          <Route path={EDIT_PAYMENT} component={EditPaymentInfoProfile} />
          <Route path={MEDICAL_REQUESTS} component={PrescriptionsView} />
          <Route exact path={JORNADA_EXPLICADA} component={QuarterDetailsView} />
          <Route exact path={CADASTRO_PEDIATRICO} component={PediatricRegistrationForm} />
          <Route path={`${CONTENT}/:title/:id`} render={renderSingleContent} />
          <Route path={`${ESPECIALISTA}/:id`} render={renderProfessionalInfo} />
          <Route path={OLD_SPECIALISTS} component={RedirectOldSpecialistsLinks} />
          <Redirect to={INICIO} />
        </Switch>
      </div>
    </AppMainContainer>
  )
})

const mapStateToProps = ({
  authentication,
  weekFlow,
  profile
}: AppState) => ({
  currentUser: authentication?.currentUser,
  timesFetchedUser: authentication.timesFetchedUser,
  paymentStatus: authentication.currentUser?.paymentStatus,
  forceUpdateWeeks: weekFlow.forceUpdateWeeks,
  isFetchingWeeks: weekFlow.isFetchingWeekInfos,
  forceUpdateCurrentUser: authentication.forceUpdateCurrentUser,
  isFetchingUser: authentication.isFetchingUser,
  isFetchingProfile: profile.isFetchingProfile,
  postRefreshTokenStatus: authentication.postRefreshTokenStatus,
  newRefreshToken: authentication.currentRefreshToken
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  fetchProfessionals: fetchAllProfessionalsAction,
  forceFetchedUser: forceFetchedUserAction,
  getAllWeekOptions: getAllWeekOptionsAction,
  updateCurrentUserInfos: updateCurrentUserInfosAction,
  clearForceUpdateCurrentUser: clearForceUpdateCurrentUserAction,
  fetchProfile: fetchProfileAction,
  handleRefreshToken: handleRefreshTokenAction,
  logout: logoutAction,
  promptUnauthenticatedModal: promptUnauthenticatedModalAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoutes)
