import { AsyncAction } from '../utils'
import { Reason, BookingService } from '../../domain/Booking'
import { AppState } from '../../apps/main/store'

export const PROMPT_FEEDBACK = 'PROMPT_FEEDBACK'
export const GOOD_FEEDBACK = 'GOOD_FEEDBACK'
export const BAD_FEEDBACK = 'BAD_FEEDBACK'
export const BAD_FEEDBACK_DETAILS = 'BAD_FEEDBACK_DETAILS'
export const BAD_FEEDBACK_SENT = 'BAD_FEEDBACK_SENT'
export const SUPPRESS_FEEDBACK = 'SUPPRESS_FEEDBACK'
export const FETCH_FEEDBACK_REASONS = 'FETCH_FEEDBACK_REASONS'
export const SUCCESS_FETCH_FEEDBACK_REASONS = 'SUCCESS_FETCH_FEEDBACK_REASONS'
export const FAILURE_FETCH_FEEDBACK_REASONS = 'FAILURE_FETCH_FEEDBACK_REASONS'
export const TOGGLE_REASON = 'TOGGLE_REASON'
export const TRACK_FEEDBACK_DESCRIPTION_EVENT = 'TRACK_FEEDBACK_DESCRIPTION_EVENT'

interface PromptFeedbackActionType {
  type: typeof PROMPT_FEEDBACK;
  bookingId: string;
}

interface GoodFeedbackActionType {
  type: typeof GOOD_FEEDBACK;
}

interface BadFeedbackActionType {
  type: typeof BAD_FEEDBACK;
}

interface BadFeedbackDetailsActionType {
  type: typeof BAD_FEEDBACK_DETAILS;
}

interface BadFeedbackSentActionType {
  type: typeof BAD_FEEDBACK_SENT;
  selectedReasons: Array<Reason>;
}

interface SuppressFeedbackActionType {
  type: typeof SUPPRESS_FEEDBACK;
}

interface FetchFeedbackReasonsActionType {
  type: typeof FETCH_FEEDBACK_REASONS;
}

interface SuccessFetchFeedbackReasonsActionType {
  type: typeof SUCCESS_FETCH_FEEDBACK_REASONS;
  reasons: Array<Reason>;
}

interface FailureFetchFeedbackReasonsActionType {
  type: typeof FAILURE_FETCH_FEEDBACK_REASONS;
}

export interface ToggleReasonActionType {
  type: typeof TOGGLE_REASON;
  reason: Reason;
}

interface TrackFeedbackDescriptionEventActionType {
  type: typeof TRACK_FEEDBACK_DESCRIPTION_EVENT;
}

export type FeedbackTypes =
  PromptFeedbackActionType |
  GoodFeedbackActionType |
  BadFeedbackActionType |
  BadFeedbackDetailsActionType |
  BadFeedbackSentActionType |
  SuppressFeedbackActionType |
  FetchFeedbackReasonsActionType |
  SuccessFetchFeedbackReasonsActionType |
  FailureFetchFeedbackReasonsActionType |
  ToggleReasonActionType |
  TrackFeedbackDescriptionEventActionType

export const suppressFeedbackAction = (): FeedbackTypes => ({
  type: SUPPRESS_FEEDBACK
})

export function sendNegativeFeedbackAction(otherMotive = ''):
  AsyncAction<AppState, { bookingService: BookingService }> {
  return async (dispatch, getState, { bookingService }) => {
    dispatch({ type: SUPPRESS_FEEDBACK })

    const { feedback } = getState()
    const { bookingId, reasons } = feedback

    if (!bookingId) return

    const selectedReasonsIds = reasons
      .filter((reason) => reason.selected)
      .map((reason) => reason.id)

    dispatch({ type: BAD_FEEDBACK_SENT })
    await bookingService.sendFeedback(bookingId, selectedReasonsIds, otherMotive, false)
  }
}

export function promptFeedbackAction(bookingId: string): FeedbackTypes {
  return {
    type: PROMPT_FEEDBACK,
    bookingId
  }
}

export function givePositiveFeedbackAction(): AsyncAction<
  AppState, { bookingService: BookingService }> {
  return async (dispatch, getState, { bookingService }) => {
    dispatch({ type: GOOD_FEEDBACK })

    const { feedback } = getState()
    if (feedback.bookingId) {
      bookingService.sendFeedback(feedback.bookingId, [], '', true)
    }
  }
}

export function giveNegativeFeedbackAction():
  AsyncAction<AppState, { bookingService: BookingService }> {
  return async (dispatch, _getState, { bookingService }) => {
    dispatch({ type: BAD_FEEDBACK })
    try {
      dispatch({ type: FETCH_FEEDBACK_REASONS })
      const reasons = await bookingService.getFeedbackReasons()
      dispatch({ type: SUCCESS_FETCH_FEEDBACK_REASONS, reasons })
      dispatch({ type: BAD_FEEDBACK_DETAILS })
    } catch {
      dispatch({ type: SUPPRESS_FEEDBACK })
      dispatch({ type: FAILURE_FETCH_FEEDBACK_REASONS })
      dispatch(sendNegativeFeedbackAction())
    }
  }
}

export const toggleReasonAction = (reason: Reason): ToggleReasonActionType => ({
  type: TOGGLE_REASON,
  reason,
})

export const trackFeedbackDescriptionEventAction = ():
  TrackFeedbackDescriptionEventActionType => ({ type: TRACK_FEEDBACK_DESCRIPTION_EVENT })
