import {
  PROMPT_DRAWER,
  SUPPRESS_DRAWER,
  DrawerTypes
} from './actions'

export interface DrawerState {
  isDrawerOpened?: boolean;
}

const initialDrawer: DrawerState = {
  isDrawerOpened: false
}

export function drawer(state = initialDrawer, action: DrawerTypes): DrawerState {
  switch (action.type) {
    case PROMPT_DRAWER: {
      return {
        ...state,
        isDrawerOpened: true,
      }
    }
    case SUPPRESS_DRAWER: {
      return {
        ...state,
        isDrawerOpened: false,
      }
    }
    default: return state
  }
}
