/* eslint-disable no-unused-vars */
import { LocalAddress } from '../utils/LocalAddress'
import { Prescriptions, ScheduleType } from './Booking'
import { SchedulePaymentType } from './ScheduleFlow'
import { SpecialistCategories } from './Specialist'
import { LocalDate } from './utils/Date'

export enum BookingStateOptions {
  CONFIRMED = 'Confirmed',
  CANCELLED = 'Cancelled',
  COMPLETED = 'Completed',
  CANCELLED_BY_COSTUMER = 'CancelledByCustomer',
  CANCELLING_BY_COSTUMER = 'CancellingByCustomer',
  AUTO_COMPLETED = 'AutoCompletedOneHourAfterBookingEnded'
}

export type Booking = {
  id: string;
  prescriptions: Array<Prescriptions>;
  prescriptionsViewed: boolean;
  startDate: LocalDate;
  endDate: LocalDate;
  consultantId: string;
  consultantName: string;
  professionalName: string;
  roomName: string;
  professionalId: string;
  professionalCategory: SpecialistCategories;
  cancelable: boolean;
  type: ScheduleType;
  attendanceAddress: LocalAddress;
  finalValue: number;
  discountedValue: number;
  fullPriceValue: number;
  professionalPictureUrl: string;
  paymentType: SchedulePaymentType;
  cardLastDigits: string;
  state: string;
  paymentSlipDueDate: number;
  paymentSlipPdfUrl: string;
  paymentSlipDigitableCode: string;
  pixDigitableCode: string;
  pixQrCodeUrl: string;
  pixDueDate: number;
  alreadyPaid: boolean;
  healthInsuranceCompany: string;
  isExam: boolean;
  chronosId: string;
  categoryId: string;
  children: ChildInfo;
}

export enum WorkTypeToServer {
  NONE = '',
  COMPUTER = 'Passo a maior parte do dia sentada na frente do computador',
  MOVIMENT = 'Passo a maior parte do dia em pé / em movimento',
  HEAVY = 'Carrego Peso (filhos pequenos entram nessa conta)',
  EXERCISES = 'Faço atividades físicas com regularidade',
  OTHER = 'Outro'
}

export enum WorkTypeOptions {
  NONE = 'Selecionar',
  COMPUTER = 'Passo a maior parte do tempo no computador.',
  MOVIMENT = 'Passo a maior parte do dia em movimento (em pé).',
  HEAVY = 'Carrego peso (filhos pequenos entram nessa conta).',
  EXERCISES = 'Faço atividades físicas com regularidade.',
  OTHER = 'Outro'
}

export enum SubstanceUseOptions {
  TOBACCO = 'Tabaco',
  ALCOHOL = 'Álcool',
  OTHER = 'Outro'
}

export enum GenderToServer {
  NONE = '',
  FEMALE = 'female',
  MALE = 'male',
  CUSTOM = 'custom'
}

export enum GenderOptions {
  NONE = 'Selecionar',
  FEMALE = 'Feminino',
  MALE = 'Masculino',
  CUSTOM = 'Outro'
}

export enum GenderPreferenceToServer {
  NONE = '',
  FEMALE = 'female',
  MALE = 'male',
  NEUTRAL = 'neutral',
}

export enum GenderPreference {
  NONE = 'Selecionar',
  FEMALE = 'Feminino: "Parabéns para ela"',
  MALE = 'Masculino: "Parabéns para ele"',
  NEUTRAL = 'Neutro: "Parabéns para ele"',
}

export enum ConsultantMoment {
  PREGNANT = 'Grávida',
  OTHER_MOMENT = 'Outro momento',
  PUERPERIUM = 'Puérpera',
  AFTER_BIRTH = 'Pós-parto',
  TRYING = 'Tentante',
  ROUTINE = 'Rotina ginecológica',
  NONE = ''
}

export enum RelationshipToServer {
  NONE = '',
  NO = 'Não tenho parceiro (a)',
  MALE_PARTNER = 'Tenho parceiro',
  FEMALE_PARTNER = 'Tenho parceira',
  PARENT = 'Conto com um familiar nessa jornada',
  OTHER = 'Outro'
}

export enum RelationshipOptions {
  NONE = 'Selecionar',
  NO = 'Não tenho parceiro (a)',
  MALE_PARTNER = 'Tenho parceiro',
  FEMALE_PARTNER = 'Tenho parceira',
  PARENT = 'Conto com um familiar nessa jornada',
  OTHER = 'Outro'
}

export enum LiveInSpOptions {
  SP = 'São Paulo capital',
  OTHER = 'Outra cidade',
  NONE = '',
}

export enum BloodTypeOptionsToServer {
  A_PLUS = 'APlus',
  A_MINUS = 'AMinus',
  B_PLUS = 'BPlus',
  B_MINUS = 'BMinus',
  AB_PLUS = 'ABPlus',
  AB_MINUS = 'ABMinus',
  O_PLUS = 'OPLus',
  O_MINUS = 'OMinus',
  NONE = ''
}

export enum BloodTypeOptions {
  NONE = 'Selecionar',
  A_PLUS = 'A positivo',
  A_MINUS = 'A negativo',
  B_PLUS = 'B positivo',
  B_MINUS = 'B negativo',
  AB_PLUS = 'AB positivo',
  AB_MINUS = 'AB negativo',
  O_PLUS = 'O positivo',
  O_MINUS = 'O negativo',
}

export type PersonalData = {
  name: string;
  phone: string;
  email: string;
  birthDate: number;
  document: string | null;
  nationalIdentityCard: string | null;
}

export type ProfileForm = {
  name: string;
  phone: string;
  email: string;
  gender: string;
  genderOther: string;
  genderPreference: string;
  birthDate: string;
  hasKids: string;
  pregnancyMoment: ConsultantMoment;
  expectedBabyBirthDate: string;
  numberOfKids: number;
  workType: string[];
  workTypeOthers: string[];
  hasUseSomeMedicine: string;
  useSomeMedicine: string[];
  hasSomeHealthCondition: string;
  someHealthCondition: string[];
  pictureUrl: string | null;
  hasFoodRestriction: string;
  foodRestriction: string[];
  hasAboutRelationship: string;
  aboutRelationship: string[];
  aboutRelationshipOthers: string[];
  liveInSp: string;
  lastPeriodStartDate: string;
  postalCode: string;
  city: string;
  stateAbbreviation: string;
  addressName: string;
  number: string;
  complement: string;
  address?: AddressForm;
  document: any;
  nationalIdentityCard: string;
  pregnancyDueDate?: string;
  firstDayLastPeriod?: string;
  weight: number | undefined;
  substanceUse: string[];
  substanceUseOthers: string[];
  hasAllergy: string;
  allergies: string[];
  hasPregnancyLoss: string;
  height: number;
  bloodType: string;
  expectedBabyBirthDateOrigin: string;
  pregnancyMomentText: string;
  beforePregnancyWeight: number;
  pregnancyMomentOther: string;
  pregnancyEffectiveDueDate: string;
}

export type AddressForm = {
  name: string;
  district?: {
      name: string;
      city: {
          name: string;
          state: {
              name: string;
              abbreviation: string;
          };
      };
  };
  districtName?: string;
  stateName?: string;
  stateAbbreviation: string;
  cityName: string;
  number: string;
  postalCode: string;
  complement: string;
  outerCountry?: boolean;
}

export type HealthInfosForm = {
  weight: number | undefined;
  lastPeriodStartDate: string;
  hasUseSomeMedicine: string;
  useSomeMedicine: string[];
  hasSomeHealthCondition: string;
  someHealthCondition: string[];
  hasFoodRestriction: string;
  foodRestriction: string[];
  hasAllergy: string;
  allergies: string[];
  workType: string[];
  workTypeOthers: string[];
  substanceUse: string[];
  substanceUseOthers: string[];
}

export type HistoryInfoForm = {
  hasKids: string;
  numberOfKids: number;
  hasPregnancyLoss: string;
  hasAboutRelationship: string;
  aboutRelationship: string[];
  aboutRelationshipOthers: string[];
}

export type MomentInfosForm = {
  pregnancyMoment: ConsultantMoment;
  pregnancyMomentOther: string;
  pregnancyDueDate?: string;
  firstDayLastPeriod?: string;
  pregnancyEffectiveDueDate?: string;
}

export type GestationalInfosForm = {
  hasPregnancyLoss: string;
  hasAboutRelationship: string;
  aboutRelationship: string[];
  aboutRelationshipOthers: string[];
}

export type PersonalInfosForm = {
  birthDate: string;
  gender: string;
  genderOther: string;
  postalCode: string;
  city: string;
  stateAbbreviation: string;
  addressName: string;
  number: string;
  complement: string;
}

export type ChildInfo = {
  id: string;
  name: string;
  birthDate: string;
  gender: GenderOptions;
  genderOther: string;
  genderPreference: GenderPreference;
  someHealthCondition: string;
  foodRestriction: string;
  pictureUrl: string;
  hasNoName?: boolean;
}

export enum RelationshipTypeOptions {
  NONE = 'Selecionar',
  DATING = 'Namorando',
  FIANCEE = 'Noiva(o)',
  STABLE_UNION = 'União estável',
  MARRIED = 'Casada(o)',
  OTHER = 'Outro',
  DO_NOT_INFORM = 'Prefiro não informar'
}

export type Profile = {
  paymentStatus: string;
  profile: ProfileForm;
  baby: ChildInfo[];
}

export type InfosToUpdateProfile = {
  name: string;
  phone: string;
  email: string;
  liveInSp?: boolean;
  birthDate?: string;
  gender?: string;
  genderOther?: string;
  genderPreference?: string;
  aboutRelationship?: string[];
  aboutKids?: string;
  expectedBabyBirthDate?: string;
  numberOfKids?: number;
  workType?: string[];
  useSomeMedicine?: string[];
  someHealthCondition?: string[];
  foodRestriction?: string[];
  document?: string;
  profileAddress?: string;
  lastPeriodStartDate?: string;
  address?: AddressForm;
  hasUseSomeMedicine?: boolean;
  weight?: number;
  hasSomeHealthCondition?: boolean;
  hasFoodRestriction?: boolean;
  hasAllergy?: boolean;
  hasPregnancyLoss?: boolean;
  hasAboutRelationship?: boolean;
  allergies?: string[];
  workTypeOthers?: string[];
  substanceUse?: string[];
  substanceUseOthers?: string[];
  aboutRelationshipOthers?: string[];
  pregnancyMoment?: ConsultantMoment;
  hasKids?: boolean;
  height?: number;
  bloodType?: string;
  beforePregnancyWeight?: number;
  pregnancyMomentOther?: string;
  pregnancyEffectiveDueDate?: string;
  isCurrentlyWorking?: boolean;
  hasPartner?: boolean;
  occupation?: string;
  nationalIdentityCard?: string;
  naturalness?: string;
  nationality?: string;
}

export interface ProfileProvider {
  getAllBookings: () => Promise<Array<Booking>>;
  getProfile: () => Promise<Profile>;
  getBookingHistory: () => Promise<Array<Booking>>;
  updateProfile: (payload: InfosToUpdateProfile) => Promise<void>;
  updatePersonalData: (payload: PersonalData) => Promise<void>;
  deleteDocuments: (consultantId: string) => Promise<void>;
  validateInformation: (verificationCode: string, email?: string, phone?: string) => Promise<void>;
}
