import * as Sentry from '@sentry/browser'

import { AsyncAction } from '../utils'
import { BookingService, Reminders } from '../../domain/Booking'

export const REQUEST_SCHEDULE_SUGGESTION = 'REQUEST_SCHEDULE_SUGGESTION'
export const SUCCESS_SCHEDULE_SUGGESTION = 'SUCCESS_SCHEDULE_SUGGESTION'
export const FAILURE_SCHEDULE_SUGGESTION = 'FAILURE_SCHEDULE_SUGGESTION'
export const REQUEST_UPDATE_SCHEDULE_SUGGESTION = 'REQUEST_UPDATE_SCHEDULE_SUGGESTION'
export const SUCCESS_UPDATE_SCHEDULE_SUGGESTION = 'SUCCESS_UPDATE_SCHEDULE_SUGGESTION'
export const FAILURE_UPDATE_SCHEDULE_SUGGESTION = 'FAILURE_UPDATE_SCHEDULE_SUGGESTION'

interface RequestScheduleSuggestionActionType {
  type: typeof REQUEST_SCHEDULE_SUGGESTION;
}

interface SuccessScheduleSuggestionActionType {
  type: typeof SUCCESS_SCHEDULE_SUGGESTION;
  shouldSendReminder: boolean;
  reminders: Array<Reminders>;
}

interface FailureScheduleSuggestionActionType {
  type: typeof FAILURE_SCHEDULE_SUGGESTION;
}

interface RequestUpdateScheduleSuggestionActionType {
  type: typeof REQUEST_UPDATE_SCHEDULE_SUGGESTION;
}

interface SuccessUpdateScheduleSuggestionActionType {
  type: typeof SUCCESS_UPDATE_SCHEDULE_SUGGESTION;
}

interface FailureUpdateScheduleSuggestionActionType {
  type: typeof FAILURE_UPDATE_SCHEDULE_SUGGESTION;
}

export type ScheduleSuggestionTypes = RequestScheduleSuggestionActionType |
  SuccessScheduleSuggestionActionType |
  FailureScheduleSuggestionActionType |
  RequestUpdateScheduleSuggestionActionType |
  SuccessUpdateScheduleSuggestionActionType |
  FailureUpdateScheduleSuggestionActionType

export function getScheduleSuggestionAction():
AsyncAction<{}, { bookingService: BookingService }> {
  return async (dispatch, _getState, { bookingService }) => {
    dispatch({ type: REQUEST_SCHEDULE_SUGGESTION })
    try {
      const scheduleSuggestionResponse = await bookingService.getBookingSuggestion()
      const {
        shouldSendReminder,
        reminders
      } = scheduleSuggestionResponse
      dispatch({
        type: SUCCESS_SCHEDULE_SUGGESTION,
        shouldSendReminder,
        reminders
      })
    } catch (error) {
      dispatch({ type: FAILURE_SCHEDULE_SUGGESTION })
      Sentry.captureException(new Error(`Failure to fetch schedule suggestion - ${error.message}`))
    }
  }
}

export function onUpdateBookingSuggestionAction(
  reminderType: string,
  bookingIds?: Array<string>,
  callback?: () => void,
  prescriptionId?: string
):
  AsyncAction<{}, { bookingService: BookingService }> {
  return async (dispatch, _getState, { bookingService }) => {
    dispatch({ type: REQUEST_UPDATE_SCHEDULE_SUGGESTION })
    try {
      await bookingService.updateBookingSuggestion(
        reminderType,
        bookingIds,
        prescriptionId
      )
      localStorage.removeItem('scheduleSuggestion')
      dispatch({ type: SUCCESS_UPDATE_SCHEDULE_SUGGESTION })
      if (typeof callback !== 'undefined') {
        callback()
      }
    } catch (error) {
      dispatch({ type: FAILURE_UPDATE_SCHEDULE_SUGGESTION })
      Sentry.captureException(new Error(`Unexpected error updating the schedule suggestion - ${error.message}`))
    }
  }
}
