/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './Terms.scss'
import { AccessibleModal, RegularButton } from 'theia-web-ds'
import DOMPurify from 'dompurify'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { suppressTermsModalAction } from '../../state/authentication/main/actions'
import { CurrentTerm } from '../../domain/Terms'

interface Props {
  visible: boolean;
  suppressModal: () => void;
  lastIdFocused?: string;
  terms: Array<CurrentTerm>;
}

function TermsModal({
  visible,
  suppressModal,
  lastIdFocused,
  terms
}: Props) {
  const content = DOMPurify.sanitize(terms[0]?.htmlText, { ADD_ATTR: ['target'] })

  return (
    <AccessibleModal
      maxWidthClass="max-w-3xl"
      extraModalContainerClass={terms.length ? 'accessible-modal-terms' : 'w-11/12 md:w-fit'}
      visible={visible}
      onClose={suppressModal}
      headerText="Termos de Uso de Serviço"
      showCloseButton={false}
      lastIdFocused={lastIdFocused}
    >
      <div className="terms-modal">
        <div className="privacy-wrapper" dangerouslySetInnerHTML={{ __html: content }} />
        <div className="modal-footer">
          <RegularButton
            label="Fechar"
            onClick={suppressModal}
            extraClass="mt-6"
          />
        </div>
      </div>
    </AccessibleModal>
  )
}

const mapStateToProps = ({
  authentication
}: AppState) => ({
  visible: authentication.showTermsModal
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  suppressModal: suppressTermsModalAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsModal)
