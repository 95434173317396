import ApiClient from './ApiClient'
import {
  Specialist,
  SpecialistDetails,
  SpecialistFromList,
  SpecialistProvider
} from '../../domain/Specialist'
import { AttendanceAvailabilityType } from '../../domain/ScheduleFlow'

export default class ApiSpecialistProvider implements SpecialistProvider {
  constructor(private apiClient: ApiClient) { }

  async getSpecialist(identity: string): Promise<Specialist> {
    return this.apiClient.getSpecialist(identity)
  }

  getAvailableSpecialists(
    categoryId: string,
    meetingType: AttendanceAvailabilityType
  ): Promise<SpecialistFromList[]> {
    return this.apiClient.getAvailableSpecialists(categoryId, meetingType)
  }

  getSpecialistDetails(specialistId: string): Promise<SpecialistDetails> {
    return this.apiClient.getSpecialistDetails(specialistId)
  }
}
