import { useEffect } from 'react'

// Fix height for mobile. The viewheight does not consider the browsers
// navigation bar, so we calculate it manually.
function updateWindowHeight() {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

export function useAdjustableWindowHeight() {
  useEffect(() => {
    updateWindowHeight()
    window.addEventListener('resize', updateWindowHeight)
  }, [])
}
