/* eslint-disable no-prototype-builtins */
import { Reminders } from '../../domain/Booking';
import {
  REQUEST_SCHEDULE_SUGGESTION,
  SUCCESS_SCHEDULE_SUGGESTION,
  FAILURE_SCHEDULE_SUGGESTION,
  ScheduleSuggestionTypes,
  REQUEST_UPDATE_SCHEDULE_SUGGESTION,
  SUCCESS_UPDATE_SCHEDULE_SUGGESTION,
  FAILURE_UPDATE_SCHEDULE_SUGGESTION
} from './actions'

export type ReminderData = {
  reminder?: Reminders;
}

export interface ScheduleSuggestionState {
  isLoading: boolean;
  byCategory: Array<Reminders>;
  shouldSendReminder: boolean;
  hasDownloaded: boolean;
}

const initialSuggestionFlow: ScheduleSuggestionState = {
  isLoading: false,
  byCategory: [],
  shouldSendReminder: false,
  hasDownloaded: false
}

let remindersFromAction: any

export function scheduleSuggestion(
  state = initialSuggestionFlow,
  action: ScheduleSuggestionTypes
): ScheduleSuggestionState {
  switch (action.type) {
    case REQUEST_SCHEDULE_SUGGESTION: {
      return {
        ...state,
        isLoading: true,
        hasDownloaded: false
      }
    }
    case SUCCESS_SCHEDULE_SUGGESTION: {
      remindersFromAction = action.reminders
      return {
        ...state,
        isLoading: false,
        byCategory: action.reminders,
        shouldSendReminder: action.shouldSendReminder
      }
    }
    case FAILURE_SCHEDULE_SUGGESTION: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case REQUEST_UPDATE_SCHEDULE_SUGGESTION: {
      return {
        ...state,
        isLoading: true
      }
    }
    case SUCCESS_UPDATE_SCHEDULE_SUGGESTION: {
      return {
        ...state,
        isLoading: false,
        shouldSendReminder: remindersFromAction ? remindersFromAction.length !== 0 : false,
        hasDownloaded: true
      }
    }
    case FAILURE_UPDATE_SCHEDULE_SUGGESTION: {
      return {
        ...state,
        isLoading: false
      }
    }
    default: return state
  }
}
