/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'

import { Dispatch, bindActionCreators } from 'redux'
import { Divider, FontIcon } from 'theia-web-ds'
import { connect as connectComponent } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState } from '../../apps/main/store'
import { BookingVideoTrack, BookingAudioTrack } from '../../domain/Call'
import { eventTrack } from '../../../eventGenerate'

import {
  connectAction,
  disconnectAction,
  toggleAudioAction,
  toggleVideoAction,
  promptPermissionAction,
  isNotSupportedBrowserLogEventAction,
  toggleChatAction,
  hasBadNetworkQualityLogEventAction,
  addTracksToClearAction,
  clearTracksAndDisconnectAction,
} from '../../state/booking/actions'
import Participant from './Participant'
import './BookingRoom.scss'
import PhoneIcon from '../../../assets/phone.svg'
import MicrophoneActive from '../../../assets/microphone-active.svg'
import MicrophoneInactive from '../../../assets/microphone-inactive.svg'
import TextActive from '../../../assets/text-active.svg'
import TextInactive from '../../../assets/text-inactive.svg'
import RemoteMicrophoneInactive from '../../../assets/remote-audio-disabled.svg'
import CameraActive from '../../../assets/camera-active.svg'
import CameraInactive from '../../../assets/camera-inactive.svg'
import RemoteCameraInactive from '../../../assets/remote-video-disabled.svg'
import CircleButton from '../common/buttons/CircleButton';
import WhiteLogo from '../../../assets/white-logo.svg'
import { Navigator } from '../../utils/Navigator'
import PermissionWebPromptModal from './PermissionWebPromptModal'
import PermissionWebDeniedModal from './PermissionWebDeniedModal'
import Helpers from '../../utils/Browsers'
import ChatVideo from '../chatVideo/Chat'
import { connectChatVideoAction, resetUnreadMessagesCountChatVideoAction, chatVideoShutdownAction } from '../../state/chatVideo/actions'
import DisconnectedModal from './DisconnectedModal'
import { BOOKINGS, CONTENT, INICIO } from '../../routes/RoutesConstants'

interface Props {
  uuid: string;
  connect: typeof connectAction;
  disconnect: () => void;
  localForceDisconnectWithDuplicate: boolean;
  remoteConnected: boolean;
  remoteReconnecting: boolean;
  remoteVideoActive: boolean;
  remoteVideoTrack?: BookingVideoTrack;
  remoteAudioActive: boolean;
  remoteAudioTrack?: BookingAudioTrack;
  localConnected: boolean;
  localReconnecting: boolean;
  localVideoTrack?: BookingVideoTrack;
  localAudioTrack?: BookingAudioTrack;
  localAudioActive: boolean;
  toggleAudio: () => void;
  localVideoActive: boolean;
  toggleVideo: () => void;
  toggleChat: () => void;
  localChatActive: boolean;
  isSwitchedOff: boolean;
  remoteUserName?: string;
  userType: 'professional' | 'consultant';
  errorMessage?: string;
  isNotSupportedBrowserLogEvent: () => void;
  promptPermission: () => void;
  promptPermissionVisible: boolean;
  count: number;
  connectChatVideo: (chatToken: string, id: string) => void;
  chatToken: string;
  id: string;
  resetUnreadMessagesCountChatVideo: () => void;
  chatVideoShutdown: () => void;
  localConnecting: boolean;
  localDisconnectWithDefaultProblem: boolean;
  networkQualityLevel: number | null;
  hasBadNetworkQualityLogEvent: (networkQualityLevel: number) => void;
  isProblemWithVideo: boolean;
  addTracksToClear: (mediaStream: MediaStream) => void;
  clearTracksAndDisconnect: () => void;
}

function BookingRoom({
  uuid,
  connect,
  localForceDisconnectWithDuplicate,
  localDisconnectWithDefaultProblem,
  localConnecting,
  remoteConnected,
  remoteReconnecting,
  remoteVideoTrack,
  remoteAudioTrack,
  localConnected,
  localReconnecting,
  localVideoTrack,
  localAudioTrack,
  disconnect,
  localAudioActive,
  toggleAudio,
  localVideoActive,
  toggleVideo,
  toggleChat,
  localChatActive,
  isSwitchedOff,
  remoteUserName,
  userType,
  errorMessage,
  isNotSupportedBrowserLogEvent,
  promptPermission,
  promptPermissionVisible,
  remoteVideoActive,
  remoteAudioActive,
  count,
  chatToken,
  id,
  resetUnreadMessagesCountChatVideo,
  connectChatVideo,
  chatVideoShutdown,
  networkQualityLevel,
  hasBadNetworkQualityLogEvent,
  isProblemWithVideo,
  addTracksToClear,
  clearTracksAndDisconnect
}: Props) {
  const history = useHistory()
  const isIOS = Helpers.isIOS()
  const isSupportedBrowser = Helpers.isSupportedBrowser()
  const showError = isSupportedBrowser && !!errorMessage
  const showLocalConnecting = (
    isSupportedBrowser && !showError && !localConnected && !localReconnecting
  )
  const showLocalReconnecting = isSupportedBrowser && !showError && localReconnecting
  const showRemoteConnecting = (
    isSupportedBrowser
    && !showError
    && !showLocalConnecting
    && !showLocalReconnecting
    && !remoteConnected
    && !remoteReconnecting
  )
  const showDownlinkState = isSupportedBrowser && isSwitchedOff && remoteConnected && localConnected
  const showRemoteReconnecting = (
    isSupportedBrowser
    && !showLocalConnecting
    && !showLocalReconnecting
    && !showError
    && !isSwitchedOff
    && remoteReconnecting
  )
  const showRemoteVideoDisabled = (
    isSupportedBrowser
    && !showError
    && !showRemoteConnecting
    && !showLocalReconnecting
    && !showLocalConnecting
    && !showDownlinkState
    && !showRemoteReconnecting
    && !remoteVideoActive
  )
  const [browserDeniedPermission, getBrowserDeniedPermission] = useState(false)
  const [browserPromptPermission, getBrowserPromptPermission] = useState(false)
  const [browserDenied, setBrowserDeniedPermission] = useState(false)
  const [isVisibleNetworkQuality, setVisibleNetworkQualityMsg] = useState(false)
  const [browserPermissionErrorTitle, setBrowserPermissionErrorTitle] = useState('Permissão negada')
  const [browserPermissionErrorDescription, setBrowserPermissionErrorDescription] = useState(
    'É necessária permissão de câmera e microfone para que a consulta seja iniciada corretamente.'
  )

  async function getBrowserPermission() {
    const cameraIsBlocked = await Navigator.cameraIsBlocked()
    const cameraIsPrompt = await Navigator.cameraIsPrompt()
    const cameraIsGranted = await Navigator.cameraIsGranted()
    if (cameraIsBlocked) {
      const err = 'Usuário não deu permissão para câmera'
      getBrowserDeniedPermission(cameraIsBlocked)
      Sentry.captureMessage(`Video error: camera is blocked: ${err}`, Sentry.Severity.Info)
    }
    if (cameraIsPrompt && !browserPromptPermission) {
      promptPermission()
      if (promptPermissionVisible) {
        getBrowserPromptPermission(cameraIsPrompt)
      }
    }
    if (cameraIsGranted) {
      connect(uuid, userType)
    }
  }

  async function getMedia(constraints: any) {
    try {
      const streamsAux = await navigator.mediaDevices.getUserMedia(constraints)
      if (streamsAux) {
        addTracksToClear(streamsAux)
      }
      connect(uuid, userType)
      setBrowserDeniedPermission(false)
    } catch (err) {
      if (err instanceof Error) {
        if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
          setBrowserPermissionErrorTitle('Dispositivos ocupados')
          setBrowserPermissionErrorDescription(
            'Sua câmera e/ou microfone já estão sendo utilizados em outro aplicativo/browser. Por favor, libere esses dispositivos e atualize a tela.'
          )
        } else if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
          setBrowserPermissionErrorTitle('Câmera e/ou microfone não encontrados')
          setBrowserPermissionErrorDescription(
            'Não foi encontrado nenhuma webcam e/ou microfone neste dispositivo.'
          )
        } else if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
          setBrowserPermissionErrorTitle('Permissão negada')
          setBrowserPermissionErrorDescription(
            'É necessário que você permita o acesso à câmera e ao microfone para que a consulta seja iniciada corretamente.'
          )
        } else {
          setBrowserPermissionErrorTitle('Permissão negada')
          setBrowserPermissionErrorDescription('Houve algum erro com a permissão para câmera e/ou microfone.')
        }
        setBrowserDeniedPermission(true)
        connect(uuid, userType)
        Sentry.captureMessage(`Video error when getting the media permission: ${err.name} - ${err.message}`, Sentry.Severity.Info)
      }
    }
  }

  function forceConnect() {
    connect(uuid, userType)
  }

  function decrementCounterActiveTabs() {
    const countActiveBookingTabs = parseInt(window.localStorage.getItem('countActiveBookingTabs') || '1', 10)
    if (countActiveBookingTabs > 0) {
      window.localStorage.setItem('countActiveBookingTabs', `${countActiveBookingTabs - 1}`)
    }
  }

  function incrementCounterActiveTabs() {
    const countActiveBookingTabs = parseInt(window.localStorage.getItem('countActiveBookingTabs') || '0', 10)
    window.localStorage.setItem('countActiveBookingTabs', `${countActiveBookingTabs + 1}`)
  }

  window.addEventListener('beforeunload', () => {
    const alreadyDecrementCountActiveBookingTabs = window.localStorage.getItem('alreadyDecrementCountActiveBookingTabs')
    if (
      window.location.pathname.includes(`${BOOKINGS}/`)
      && alreadyDecrementCountActiveBookingTabs !== 'true'
    ) {
      disconnect()
      chatVideoShutdown()
      decrementCounterActiveTabs()
      window.localStorage.setItem('alreadyDecrementCountActiveBookingTabs', 'true')
    }
  });

  function onLeaveRoom() {
    clearTracksAndDisconnect()
    chatVideoShutdown()
  }

  function onLeaveRoomWithRedirectToChat() {
    onLeaveRoom()
    history.push(INICIO)
  }

  function onToggleChat() {
    if (!localChatActive) {
      resetUnreadMessagesCountChatVideo()
    }
    toggleChat()
  }

  useEffect(() => {
    eventTrack('Entrou na chamada')
    incrementCounterActiveTabs()
    window.localStorage.setItem('alreadyDecrementCountActiveBookingTabs', 'false')
    return function cleanup() {
      onLeaveRoom()
      decrementCounterActiveTabs()
    }
  }, [])

  useEffect(() => {
    if (isSupportedBrowser) {
      if (!Helpers.isSafariOrFirefox()) {
        getBrowserPermission()
      }
      if (browserPromptPermission || Helpers.isSafariOrFirefox()) {
        getMedia({ audio: true, video: true })
      }
    } else {
      isNotSupportedBrowserLogEvent()
    }
  }, [promptPermissionVisible, browserDeniedPermission])

  useEffect(() => {
    if (!chatToken) return

    (async () => {
      await connectChatVideo(chatToken, id)
    })()
  }, [chatToken])

  useEffect(() => {
    if (localChatActive) {
      resetUnreadMessagesCountChatVideo()
    }
  }, [count])

  useEffect(() => {
    if (networkQualityLevel !== null && networkQualityLevel < 3) {
      setVisibleNetworkQualityMsg(true)
      hasBadNetworkQualityLogEvent(networkQualityLevel)
    } else {
      setVisibleNetworkQualityMsg(false)
    }
  }, [networkQualityLevel])

  useEffect(() => {
    if (isProblemWithVideo) {
      setBrowserPermissionErrorTitle('Problema com o acesso à câmera')
      setBrowserPermissionErrorDescription('Por favor, verifique se mais algum aplicativo/browser está usando a câmera.')
    }
  }, [isProblemWithVideo])

  function renderParticipant(
    kind: 'local' | 'remote',
    showVideoDisabled: boolean,
    videoTrack?: BookingVideoTrack,
    audioTrack?: BookingAudioTrack,
  ) {
    return (
      <Participant
        showVideoDisabled={showVideoDisabled}
        kind={kind}
        videoTrack={videoTrack}
        audioTrack={audioTrack}
      />
    )
  }

  function leaveOrRefresh() {
    if (browserDenied) {
      history.push(`${CONTENT}/videochamada/5e2f1d8f8e2b6600389e5657`)
    }
    if (userType === 'consultant' && !browserDenied) {
      history.push(INICIO)
    } else {
      window.location.reload()
    }
  }

  function leaveOrGoToStore() {
    history.push(INICIO)
  }

  function handleClickTalkWithUs(screenErrorTitle: string, screenErrorMessage: string) {
    eventTrack(`${screenErrorTitle}: ${screenErrorMessage}`)
    eventTrack('Clicou fale com a gente')
  }

  return (
    <div className="app-booking">
      {!isSupportedBrowser && (
        <div className={`booking__loading ${localChatActive ? 'to-top' : ''}`}>
          <WhiteLogo className="booking__loading-logo mx-auto" />
          <p className="booking__connecting font-bold mt-3">{isIOS ? 'Navegador não suportado no iPhone' : 'Navegador não suportado'}</p>
          <p className="booking__connecting font-normal mt-3">
            {isIOS ? (
              'Para acessar sua consulta, utilize o navegador Safari a partir da versão 11, ou então acesse pelo desktop.'
            ) : 'Para acessar sua consulta, utilize o Chrome, Firefox ou Safari a partir da versão 11.'}
          </p>
          {userType === 'consultant' && (
            <>
              <button type="button" onClick={leaveOrGoToStore} className="booking__home-button">
                Ir para home
              </button>
            </>
          )}
        </div>
      )}
      {showError && (
        <div className={`booking__loading ${localChatActive ? 'to-top' : ''}`}>
          <WhiteLogo className="booking__loading-logo mx-auto" />
          <p className="booking__connecting font-bold mt-3">{browserDenied || isProblemWithVideo ? browserPermissionErrorTitle : 'Essa ligação falhou.'}</p>
          <p className="booking__connecting font-normal mt-3">{browserDenied || isProblemWithVideo ? browserPermissionErrorDescription : errorMessage}</p>
          <button type="button" onClick={leaveOrRefresh} className="booking__home-button">
            {browserDenied || isProblemWithVideo ? 'Saiba mais' : userType === 'consultant' ? 'Ir para home' : 'Recarregar'}
          </button>
          <Divider className="mt-8 opacity-20" />
          <div className="flex flex-row booking__help-link mt-6">
            Precisa de ajuda?
            <a
              href="https://theiahealth.zendesk.com/hc/pt-br"
              aria-label="Link para site externo do suporte da Theia"
              target="_blank"
              className="flex flex-row justify-center"
              onClick={() => {
                handleClickTalkWithUs(
                  browserDenied || isProblemWithVideo ? browserPermissionErrorTitle : 'Essa ligação falhou.',
                  browserDenied || isProblemWithVideo ? browserPermissionErrorDescription : errorMessage || ''
                )
              }}
            >
              <FontIcon iconType="icon-ChatLight" color="#FFF" fontSize="22px" /> Fale com a gente
            </a>
          </div>
        </div>
      )}

      {showDownlinkState && (
        <div className={`booking__loading ${localChatActive ? 'to-top' : ''}`}>
          <WhiteLogo className="booking__loading-logo mx-auto" />
          <p className="booking__connecting font-bold mt-3">
            Parece que sua conexão <br />
            com a internet está fraca. <br />
            Por favor, aguarde.
          </p>
        </div>
      )}

      {showLocalReconnecting && (
        <div className={`booking__loading ${localChatActive ? 'to-top' : ''}`}>
          <WhiteLogo className="booking__loading-logo mx-auto" />
          <p className="booking__connecting font-bold mt-3">Reconectando...</p>
        </div>
      )}

      {showLocalConnecting && (
        <div className={`booking__loading ${localChatActive ? 'to-top' : ''}`}>
          <WhiteLogo className="booking__loading-logo mx-auto" />
          <p className="booking__connecting font-bold mt-3">Conectando...</p>
        </div>
      )}

      {showRemoteReconnecting && (
        <div className={`booking__loading ${localChatActive ? 'to-top' : ''}`}>
          <WhiteLogo className="booking__loading-logo mx-auto" />
          <p className="booking__connecting font-bold mt-3">O outro participante está reconectando...</p>
        </div>
      )}

      {showRemoteConnecting && (
        <div className={`booking__loading ${localChatActive ? 'to-top' : ''}`}>
          <WhiteLogo className="booking__loading-logo mx-auto" />
          <p className="booking__connecting font-bold mt-3">O outro participante ainda não entrou.</p>
          <Divider className="mt-8 opacity-20" />
          <p className="mt-6 text-bgBox">
            Precisa de ajuda?
          </p>
          <a
            href="https://theiahealth.zendesk.com/hc/pt-br"
            aria-label="Link para site externo do suporte da Theia"
            target="_blank"
            onClick={() => handleClickTalkWithUs('O outro participante ainda não entrou.', '')}
            className="booking__help-button mt-4"
          >
            <FontIcon iconType="icon-ChatLight text-fontSmall" color="#FFF" />Fale com a gente
          </a>
        </div>
      )}

      {showRemoteVideoDisabled && (
        <div className={`booking__loading ${localChatActive ? 'to-top' : ''}`}>
          <p className="booking__connecting font-bold mt-3">{remoteUserName} desabilitou o vídeo.</p>
        </div>
      )}

      <div className="booking">
        {isSupportedBrowser && !showError && !promptPermissionVisible && !browserDeniedPermission
          && (
            <>
              <div className={`booking__thumb ${localChatActive ? 'to-top' : ''}`}>
                {renderParticipant('remote', false, remoteVideoTrack, remoteAudioTrack)}
                {renderParticipant('local', localVideoActive, localVideoTrack, localAudioTrack)}
              </div>
              <div className="booking__header">
                <p className="booking__title">Ligação com</p>
                <div className="booking__remote-infos">
                  <p className="booking__remote-name">{remoteUserName}</p>
                  {remoteAudioActive ? null : (
                    <div className="pr-2">
                      <RemoteMicrophoneInactive width={18} height={18} />
                    </div>
                  )}
                  {remoteVideoActive ? null : <RemoteCameraInactive width={18} height={18} />}
                </div>
                {isVisibleNetworkQuality
                  && (
                    <p>
                      Sua internet está instável, caso seja possível, recomendamos alternar a
                      rede wi-fi ou se conectar via cabo.
                    </p>
                  )}
              </div>

              <div className={`booking__menu ${localChatActive ? 'to-top' : ''}`}>
                {localAudioActive
                  ? <CircleButton id="active-audio-button" disabled={!localConnected} onClick={toggleAudio}><MicrophoneActive /></CircleButton>
                  : <CircleButton onClick={toggleAudio}><MicrophoneInactive /></CircleButton>}

                <CircleButton id="leave-room-buttom" onClick={onLeaveRoomWithRedirectToChat}><PhoneIcon /></CircleButton>

                {localVideoActive
                  ? <CircleButton id="active-video-button" disabled={!localConnected} onClick={toggleVideo}><CameraActive /></CircleButton>
                  : <CircleButton onClick={toggleVideo}><CameraInactive /></CircleButton>}

                {localChatActive
                  ? (
                    <CircleButton id="active-chat-button" disabled={!localConnected} onClick={onToggleChat}>
                      <TextActive />
                    </CircleButton>
                  )
                  : (
                    <>
                      <CircleButton id="active-chat-button" disabled={!localConnected} onClick={onToggleChat}>
                        <div className="relative">
                          {count > 0 && (
                            <span className="chat-video-notification-icon" role="status" aria-label={`${count} mensagens não lidas`}>
                              {count}
                            </span>
                          )}
                        </div>
                        <TextInactive />
                      </CircleButton>
                    </>
                  )}
              </div>
              {localChatActive && <ChatVideo />}
            </>
          )}
      </div>
      <PermissionWebPromptModal visible={promptPermissionVisible} />
      <PermissionWebDeniedModal visible={browserDeniedPermission} />
      <DisconnectedModal
        visible={localForceDisconnectWithDuplicate || localDisconnectWithDefaultProblem}
        forceConnect={forceConnect}
        isLoading={localConnecting}
        isDuplicatedRoom={localForceDisconnectWithDuplicate}
      />
    </div>
  )
}

const mapStateToProps = ({ booking, promptPermission, chatVideo }: AppState, { uuid }: { uuid: string; userType: 'professional' | 'consultant' }) => ({
  ...booking,
  uuid,
  promptPermissionVisible: promptPermission.visible,
  count: chatVideo.unreadMessagesCount,
  chatToken: booking.chatToken,
  id: booking.id,
  networkQualityLevel: booking.networkQualityLevel
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  connect: connectAction,
  disconnect: disconnectAction,
  toggleAudio: toggleAudioAction,
  toggleVideo: toggleVideoAction,
  toggleChat: toggleChatAction,
  isNotSupportedBrowserLogEvent: isNotSupportedBrowserLogEventAction,
  promptPermission: promptPermissionAction,
  connectChatVideo: connectChatVideoAction,
  resetUnreadMessagesCountChatVideo: resetUnreadMessagesCountChatVideoAction,
  chatVideoShutdown: chatVideoShutdownAction,
  hasBadNetworkQualityLogEvent: hasBadNetworkQualityLogEventAction,
  addTracksToClear: addTracksToClearAction,
  clearTracksAndDisconnect: clearTracksAndDisconnectAction
}, dispatch)

export default connectComponent(
  mapStateToProps,
  mapDispatchToProps,
)(BookingRoom)
