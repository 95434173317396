/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Formik, FormikProps } from 'formik'
import { NewListItem, RegularButton, Loader } from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import AppMainContainerOff from '../AppMainContainerOff'
import { textSecondary } from '../../color'
import { FaqToggle } from '../common/FaqToggle'
import {
  getBrazilStatesAction,
  getCitiesOfStateAction,
  onUpdateLocalizationInProfileAction
} from '../../state/onboarding/actions'
import { BrazilState, CityOfState } from '../../domain/Onboarding'
import { User } from '../../domain/User'
import SelectInputSearch from '../common/SelectInputSearch'
import { isNotNullOrUndefined } from '../../utils/helpers'
import { eventPage, eventTrack } from '../../../eventGenerate'
import { PLANO_DE_SAUDE } from '../../routes/RoutesConstants'

interface Props {
  getBrazilStates: () => void;
  brazilStates: Array<BrazilState>;
  getCitiesOfState: (id: string) => void;
  citiesOfState: Array<CityOfState>;
  isLoading: boolean;
  onUpdateLocalizationInProfile: (
    name: string,
    phone: string,
    email: string,
    stateAbbreviation: string,
    cityName: string,
    outerCountry: boolean
  ) => void;
  currentUser?: User;
}

interface LocalizationFormValues {
  stateAbbreviation: string;
  cityName: string;
  outerCountry: boolean;
}

function renderLocalizationForm(
  {
    handleSubmit,
    isSubmitting,
    values,
    setFieldValue
  }: FormikProps<LocalizationFormValues>,
  brazilStates: Array<BrazilState>,
  getCitiesOfState: (id: string) => void,
  citiesOfState: Array<CityOfState>,
  isLoading: boolean,
  currentUser?: User
) {
  const history = useHistory()
  const canSubmit = (
    !!values.stateAbbreviation
    && !!values.cityName
  )
  const states = isNotNullOrUndefined(brazilStates) && brazilStates.length >= 1
    ? brazilStates.map((state) => state.sigla)
    : []
  const textFaq = 'Essa pergunta nos ajuda a saber quais de nossos médicos e especialistas atendem em seu estado. Mas você também pode realizar consultas online pelo app em qualquer lugar do mundo.'
  const [cities, setCities] = useState<any>()
  const [isOuterCountry, setIsOuterCountry] = useState(false)
  let state: Array<BrazilState> = []
  let city: string[] = []
  const getCities = (sigla: string) => {
    if (isNotNullOrUndefined(brazilStates) && brazilStates.length >= 1) {
      state = brazilStates.filter((item) => item.sigla === sigla)
      getCitiesOfState(state[0].sigla)
    }
  }

  const onSubmit = () => {
    eventTrack('Clicou Continuar Localizacao')
    handleSubmit()
    history.push(PLANO_DE_SAUDE)
  }

  const onClickOuterCountry = () => {
    eventTrack('Não Moro no Brasil')
    setIsOuterCountry(true)
  }

  useEffect(() => {
    eventPage('onboarding', 'Localização')
    if (citiesOfState.length >= 1) {
      city = citiesOfState.map((item) => item.nome)
      setFieldValue('cityName', city[0])
      setCities(city)
    }
  }, [citiesOfState])

  useEffect(() => {
    if (isOuterCountry) {
      values.outerCountry = isOuterCountry
      onSubmit()
    }
  }, [isOuterCountry])

  useEffect(() => {
    if (values.stateAbbreviation !== '') {
      const hasState = brazilStates.map((st) => st.sigla)
        .filter((s) => s === values.stateAbbreviation)
      if (hasState.length > 0) {
        getCities(values.stateAbbreviation)
      } else {
        setFieldValue('cityName', '')
        setCities([])
      }
    }
  }, [values.stateAbbreviation])

  if (!currentUser) {
    return (
      <Loader />
    )
  }

  const onBlurEventTrack = (param: string) => {
    if (param === 'Estado') {
      eventTrack('Selecionou Estado')
    } else {
      eventTrack('Selecionou Cidade')
    }
  }
  return (
    <AppMainContainerOff hasNewLayout>
      <div className="onboarding-container">
        <form className="mx-auto w-full lg:max-w-x" noValidate name="localizationForm">
          <h3 className="text-textPrimary text-textMedium text-center mb-6">Onde você mora?</h3>
          <div className="mb-4">
            <SelectInputSearch
              name="stateAbbreviation"
              options={states}
              placeholder="Selecione seu estado"
              disabled={isLoading}
              label="Estado"
              searchInputPlaceholder="Digite seu estado"
              extraOnBlur={() => onBlurEventTrack('Estado')}
            />

          </div>
          <div className="mt-4 mb-4">
            <SelectInputSearch
              name="cityName"
              options={cities}
              placeholder="Selecione a cidade"
              disabled={values.stateAbbreviation === '' || isLoading}
              label="Cidade"
              searchInputPlaceholder="Digite seu cidade"
              extraOnBlur={() => onBlurEventTrack('Cidade')}
            />
          </div>
          <div>
            <NewListItem
              iconType="icon-GlobeLight"
              iconSize="24px"
              iconColor={textSecondary}
              title="Não moro no Brasil"
              ariaLabel="Não moro no Brasil"
              id="otherCountry"
              key="otherCountry"
              onClick={onClickOuterCountry}
              isLastItem
            />
          </div>
          <div className="mb-6 mt-3">
            <RegularButton
              label="Continuar"
              onClick={onSubmit}
              disabled={!canSubmit}
              isSubmitting={isSubmitting}
            />
          </div>
        </form>
      </div>
      <FaqToggle
        toggleTitle="Por que essa pergunta?"
        toggleDescription={textFaq}
        iconType="icon-ChatHelpLight"
      />
    </AppMainContainerOff>
  )
}

const initialValues = {
  stateAbbreviation: '',
  cityName: '',
  outerCountry: false
}

function Localization({
  getBrazilStates,
  brazilStates,
  getCitiesOfState,
  citiesOfState,
  isLoading,
  onUpdateLocalizationInProfile,
  currentUser,
}: Props) {
  useEffect(() => {
    getBrazilStates()
  }, [])
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ stateAbbreviation, cityName, outerCountry }) => {
        if (currentUser) {
          onUpdateLocalizationInProfile(
            currentUser.name,
            currentUser.phone,
            currentUser.email,
            stateAbbreviation,
            cityName,
            outerCountry
          )
        }
      }}
    >
      {(props) => renderLocalizationForm(
        props,
        brazilStates,
        getCitiesOfState,
        citiesOfState,
        isLoading,
        currentUser
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  onboarding, authentication
}: AppState) => ({
  brazilStates: onboarding.brazilStates,
  citiesOfState: onboarding.citiesOfState,
  isLoading: onboarding.isLoading,
  currentUser: authentication.currentUser
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getBrazilStates: getBrazilStatesAction,
  getCitiesOfState: getCitiesOfStateAction,
  onUpdateLocalizationInProfile: onUpdateLocalizationInProfileAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Localization)
