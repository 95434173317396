/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/browser'
import { HttpClient } from '../../services/Http'
import { AsyncAction } from '../utils'
import {
  BrazilState,
  CityOfState,
  HealthInsuranceBenefits,
  OnboardingService
} from '../../domain/Onboarding'
import { UserProvider } from '../../domain/User'
import { HealthInsuranceCompany, HealthInsurancePlanData } from '../../domain/Healthplan'
import { setForceUpdateCurrentUserAction } from '../authentication/main/actions'
import { API_URL } from '../../services/api/ApiClient'

export const REQUEST_BRAZIL_STATES = 'REQUEST_BRAZIL_STATES'
export const SUCCESS_BRAZIL_STATES = 'SUCCESS_BRAZIL_STATES'
export const FAILURE_BRAZIL_STATES = 'FAILURE_BRAZIL_STATES'
export const REQUEST_CITIES_OF_STATE = 'REQUEST_CITIES_OF_STATE'
export const SUCCESS_CITIES_OF_STATE = 'SUCCESS_CITIES_OF_STATE'
export const FAILURE_CITIES_OF_STATE = 'FAILURE_CITIES_OF_STATE'
export const UPDATE_ADDRESS_IN_PROFILE_REQUESTED = 'UPDATE_ADDRESS_IN_PROFILE_REQUESTED'
export const SUCCESS_UPDATE_ADDRESS_IN_PROFILE = 'SUCCESS_UPDATE_ADDRESS_IN_PROFILE'
export const FAILURE_UPDATE_ADDRESS_IN_PROFILE = 'FAILURE_UPDATE_ADDRESS_IN_PROFILE'
export const CONFIRM_HEALTH_INSURANCE_PLAN = 'CONFIRM_HEALTH_INSURANCE_PLAN'
export const DO_NOT_HAVE_HEALTH_INSURANCE_PLAN = 'DO_NOT_HAVE_HEALTH_INSURANCE_PLAN'
export const SELECT_HEALTH_INSURANCE = 'SELECT_HEALTH_INSURANCE'
export const REQUEST_HEALTH_INSURANCE_COMPANIES = 'REQUEST_HEALTH_INSURANCE_COMPANIES'
export const SUCCESS_HEALTH_INSURANCE_COMPANIES = 'SUCCESS_HEALTH_INSURANCE_COMPANIES'
export const FAILURE_HEALTH_INSURANCE_COMPANIES = 'FAILURE_HEALTH_INSURANCE_COMPANIES'
export const POST_HEALTH_INSURANCE = 'POST_HEALTH_INSURANCE'
export const SUCCESS_POST_HEALTH_INSURANCE = 'SUCCESS_POST_HEALTH_INSURANCE'
export const FAILURE_POST_HEALTH_INSURANCE = 'FAILURE_POST_HEALTH_INSURANCE'
export const REQUEST_HEALTH_INSURANCE_DATA = 'REQUEST_HEALTH_INSURANCE_DATA'
export const SUCCESS_HEALTH_INSURANCE_DATA = 'SUCCESS_HEALTH_INSURANCE_DATA'
export const FAILURE_HEALTH_INSURANCE_DATA = 'FAILURE_HEALTH_INSURANCE_DATA'
export const SUCCESS_OPEN_MODAL = 'SUCCESS_OPEN_MODAL'
export const SUCCESS_CLOSE_MODAL = 'SUCCESS_CLOSE_MODAL'
export const GO_BACK_TO_PROFILE_HEALTH_PLAN_UPDATE = 'GO_BACK_TO_PROFILE_HEALTH_PLAN_UPDATE'

interface RequestBrazilStatesActionType {
  type: typeof REQUEST_BRAZIL_STATES;
}

interface SuccessBrazilStatesActionType {
  type: typeof SUCCESS_BRAZIL_STATES;
  brazilStates: Array<BrazilState>;
}

interface FailureBrazilStatesActionType {
  type: typeof FAILURE_BRAZIL_STATES;
}

interface RequestCitiesOfStateActionType {
  type: typeof REQUEST_CITIES_OF_STATE;
}

interface SuccessCitiesOfStateActionType {
  type: typeof SUCCESS_CITIES_OF_STATE;
  citiesOfState: Array<CityOfState>;
}

interface FailureCitiesOfStateActionType {
  type: typeof FAILURE_CITIES_OF_STATE;
}

interface UpdateAddressInProfileRequested {
  type: typeof UPDATE_ADDRESS_IN_PROFILE_REQUESTED;
}

export interface SuccessUpdateAddressInProfileAction {
  type: typeof SUCCESS_UPDATE_ADDRESS_IN_PROFILE;
  address: {
    stateAbbreviation: string;
    cityName: string;
    outerCountry: boolean;
  };
}

export interface FailureUpdateAddressInProfileAction {
  type: typeof FAILURE_UPDATE_ADDRESS_IN_PROFILE;
  hasError: boolean;
}

interface ConfirmHealthInsurancePlanActionType {
  type: typeof CONFIRM_HEALTH_INSURANCE_PLAN;
}

interface DoNotHaveHealthInsurancePlanActionType {
  type: typeof DO_NOT_HAVE_HEALTH_INSURANCE_PLAN;
}

interface SelectHealthInsurancePlanActionType {
  type: typeof SELECT_HEALTH_INSURANCE;
  company: string;
}

interface RequestHealthInsuranceCompaniesActionType {
  type: typeof REQUEST_HEALTH_INSURANCE_COMPANIES;
}

interface SuccessHealthInsuranceCompaniesActionType {
  type: typeof SUCCESS_HEALTH_INSURANCE_COMPANIES;
  companies: Array<HealthInsuranceCompany>;
}

interface FailureHealthInsuranceCompaniesActionType {
  type: typeof FAILURE_HEALTH_INSURANCE_COMPANIES;
}

interface PostHealthInsuranceActionType {
  type: typeof POST_HEALTH_INSURANCE;
}

interface SuccessPostHealthInsuranceActionType {
  payload: HealthInsurancePlanData;
  type: typeof SUCCESS_POST_HEALTH_INSURANCE;
  insurancePlanCovered: boolean;
  benefits: Array<HealthInsuranceBenefits>;
  benefitsTitle: string;
  benefitsSubtitle: string;
}

interface FailurePostHealthInsuranceActionType {
  type: typeof FAILURE_POST_HEALTH_INSURANCE;
}

interface RequestHealthInsuranceDataActionType {
  type: typeof REQUEST_HEALTH_INSURANCE_DATA;
}

interface SuccessHealthInsuranceDataActionType {
  type: typeof SUCCESS_HEALTH_INSURANCE_DATA;
  healthInsurancePlanData: HealthInsurancePlanData;
}

interface FailureHealthInsuranceDataActionType {
  type: typeof FAILURE_HEALTH_INSURANCE_DATA;
}

interface OpenModalType {
  type: typeof SUCCESS_OPEN_MODAL;
}

export interface CloseModalType {
  type: typeof SUCCESS_CLOSE_MODAL;
}

interface GoBackToProfileAfterHealthPlanUpdateType {
  type: typeof GO_BACK_TO_PROFILE_HEALTH_PLAN_UPDATE;
}

export type OnboardingTypes = RequestBrazilStatesActionType |
  SuccessBrazilStatesActionType |
  FailureBrazilStatesActionType |
  RequestCitiesOfStateActionType |
  SuccessCitiesOfStateActionType |
  FailureCitiesOfStateActionType |
  UpdateAddressInProfileRequested |
  SuccessUpdateAddressInProfileAction |
  FailureUpdateAddressInProfileAction |
  ConfirmHealthInsurancePlanActionType |
  DoNotHaveHealthInsurancePlanActionType |
  SelectHealthInsurancePlanActionType |
  RequestHealthInsuranceCompaniesActionType |
  SuccessHealthInsuranceCompaniesActionType |
  FailureHealthInsuranceCompaniesActionType |
  PostHealthInsuranceActionType |
  SuccessPostHealthInsuranceActionType |
  FailurePostHealthInsuranceActionType |
  RequestHealthInsuranceDataActionType |
  SuccessHealthInsuranceDataActionType |
  FailureHealthInsuranceDataActionType |
  OpenModalType |
  CloseModalType |
  GoBackToProfileAfterHealthPlanUpdateType

export function getBrazilStatesAction():
  AsyncAction<{}, { onboardingService: OnboardingService }> {
  return async (dispatch, _getState, { onboardingService }) => {
    dispatch({ type: REQUEST_BRAZIL_STATES })
    try {
      const brazilStates = await onboardingService.getBrazilStates()
      dispatch({ type: SUCCESS_BRAZIL_STATES, brazilStates })
    } catch (error) {
      dispatch({ type: FAILURE_BRAZIL_STATES })
      Sentry.captureException(new Error(`Failure to fetch Brazil states - ${error.message}`))
    }
  }
}

export function getCitiesOfStateAction(id: string):
  AsyncAction<{}, { onboardingService: OnboardingService }> {
  return async (dispatch, _getState, { onboardingService }) => {
    dispatch({ type: REQUEST_CITIES_OF_STATE })
    try {
      const citiesOfState = await onboardingService.getCitiesOfState(id)

      dispatch({ type: SUCCESS_CITIES_OF_STATE, citiesOfState })
    } catch (error) {
      dispatch({ type: FAILURE_CITIES_OF_STATE })
      Sentry.captureException(new Error(`Failure to fetch cities os state - ${error.message}`))
    }
  }
}

export function onUpdateLocalizationInProfileAction(
  name: string,
  phone: string,
  email: string,
  stateAbbreviation: string,
  cityName: string,
  outerCountry: boolean
):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: UPDATE_ADDRESS_IN_PROFILE_REQUESTED })
    try {
      const url = `${API_URL}/consultants/profile`
      const payload: {} = {
        name,
        phone,
        email,
        address: {
          stateAbbreviation,
          cityName,
          outerCountry,
          complement: '',
          name: '',
          number: '',
          postalCode: ''
        }
      }
      const response = await httpClient.put({ url, payload })
      if (response.status !== 200) {
        const responseJson = JSON.parse(response?.body)
        dispatch({ type: FAILURE_UPDATE_ADDRESS_IN_PROFILE, hasError: true })
        Sentry.captureException(new Error(`Failure updating address data in profile - ${responseJson?.message}`))
      } else {
        dispatch({
          type: SUCCESS_UPDATE_ADDRESS_IN_PROFILE,
          address: {
            stateAbbreviation,
            cityName,
            outerCountry,
          }
        })
        dispatch(setForceUpdateCurrentUserAction())
      }
    } catch (error) {
      dispatch({ type: FAILURE_UPDATE_ADDRESS_IN_PROFILE, hasError: true })
      Sentry.captureException(new Error(`Unexpected error updating the address data in profile - ${error.message}`))
    }
  }
}

export const onConfirmHealthInsurancePlanAction = (): ConfirmHealthInsurancePlanActionType => (
  { type: CONFIRM_HEALTH_INSURANCE_PLAN }
)

export const doNotHaveHealthInsurancePlanAction = (): DoNotHaveHealthInsurancePlanActionType => (
  { type: DO_NOT_HAVE_HEALTH_INSURANCE_PLAN }
)

export const onSelectHealthInsuranceAction = (company: string):
  SelectHealthInsurancePlanActionType => (
  { type: SELECT_HEALTH_INSURANCE, company }
)

export function getHealthInsuranceCompaniesAction():
  AsyncAction<{}, { onboardingService: OnboardingService }> {
  return async (dispatch, _getState, { onboardingService }) => {
    dispatch({ type: REQUEST_HEALTH_INSURANCE_COMPANIES })
    try {
      const companies = await onboardingService.getHealthInsuranceCompanies()
      dispatch({ type: SUCCESS_HEALTH_INSURANCE_COMPANIES, companies })
    } catch (error) {
      dispatch({ type: FAILURE_HEALTH_INSURANCE_COMPANIES })
      Sentry.captureException(new Error(`Failure to fetch health insurance companies - ${error.message}`))
    }
  }
}

export function onPostHealthInsuranceAction(
  hasHealthInsurancePlan: boolean,
  company: string | null,
  planName: string | null,
  memberId: string | null,
  companyId: string | null,
  planId: string | null,
  benefitsTitle: string | null,
  benefitsSubtitle: string | null,
): AsyncAction<{}, { httpClient: HttpClient }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({ type: POST_HEALTH_INSURANCE })
    try {
      const url = `${API_URL}/consultants/health-insurance-plan`
      const payload = {
        hasHealthInsurancePlan,
        healthInsurancePlan: {
          company,
          planName,
          memberId,
          companyId,
          planId,
        },
        benefitsTitle,
        benefitsSubtitle,
      }
      const response = await httpClient.post({ url, payload })
      if (response.status !== 200) {
        dispatch({ type: FAILURE_POST_HEALTH_INSURANCE })
        const responseJson = JSON.parse(response?.body)
        Sentry.captureException(new Error(`Failure to send health insurance infos - ${responseJson?.message}`))
        return
      }
      dispatch({
        type: SUCCESS_POST_HEALTH_INSURANCE,
        insurancePlanCovered: JSON.parse(response.body).insurancePlanCovered,
        benefits: JSON.parse(response.body).healthInsurancePlanData.benefits,
        benefitsTitle: JSON.parse(response.body).healthInsurancePlanData.benefitsTitle,
        benefitsSubtitle: JSON.parse(response.body).healthInsurancePlanData.benefitsSubtitle,
        payload
      })
    } catch (error) {
      dispatch({ type: FAILURE_POST_HEALTH_INSURANCE })
      Sentry.captureException(new Error(`Unexpected error to send health insurance infos - ${error.message}`))
    }
  }
}

export function getHealthInsurancePlanDataAction():
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { userProvider }) => {
    dispatch({ type: REQUEST_HEALTH_INSURANCE_DATA })
    try {
      const user: any = await userProvider.getCurrentUser()
      const healthInsurancePlanData = {
        ...user.healthInsurancePlanData
      }
      dispatch({ type: SUCCESS_HEALTH_INSURANCE_DATA, healthInsurancePlanData })
    } catch (error) {
      dispatch({ type: FAILURE_HEALTH_INSURANCE_DATA })
      if (error instanceof Error) {
        Sentry.captureException(new Error(`Failure to get consultant health insurance data - ${error.message}`))
      }
    }
  }
}

export const openModalAction = (): OpenModalType => (
  { type: SUCCESS_OPEN_MODAL }
)

export const closeModalAction = (): CloseModalType => (
  { type: SUCCESS_CLOSE_MODAL }
)
