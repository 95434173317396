import * as Sentry from '@sentry/browser';
import { AsyncAction } from '../utils'
import { HttpClient } from '../../services/Http';
import { AddressByCep } from '../../utils/LocalAddress';

export const ADDRESS_BY_CEP_REQUESTED = 'ADDRESS_BY_CEP_REQUESTED'
export const ADDRESS_BY_CEP_SUCCESS = 'ADDRESS_BY_CEP_SUCCESS'
export const ADDRESS_BY_CEP_FAILURE = 'ADDRESS_BY_CEP_FAILURE'

export interface AddressByCepRequestedActionType {
  type: typeof ADDRESS_BY_CEP_REQUESTED;
}

export interface AddressByCepFailureActionType {
  type: typeof ADDRESS_BY_CEP_FAILURE;
}

export interface AddressByCepSuccessActionType {
  type: typeof ADDRESS_BY_CEP_SUCCESS;
  addressFetchedFromCep: AddressByCep;
  fullState: string;
}

export type AddressActionTypes = AddressByCepRequestedActionType
| AddressByCepSuccessActionType
| AddressByCepFailureActionType

const brazilStates = [
  {
    id: '12',
    sigla: 'AC',
    nome: 'Acre'
  },
  {
    id: '27',
    sigla: 'AL',
    nome: 'Alagoas'
  },
  {
    id: '16',
    sigla: 'AP',
    nome: 'Amapá'
  },
  {
    id: '13',
    sigla: 'AM',
    nome: 'Amazonas'
  },
  {
    id: '29',
    sigla: 'BA',
    nome: 'Bahia'
  },
  {
    id: '23',
    sigla: 'CE',
    nome: 'Ceará'
  },
  {
    id: '53',
    sigla: 'DF',
    nome: 'Distrito Federal'
  },
  {
    id: '32',
    sigla: 'ES',
    nome: 'Espírito Santo'
  },
  {
    id: '52',
    sigla: 'GO',
    nome: 'Goiás'
  },
  {
    id: '21',
    sigla: 'MA',
    nome: 'Maranhão'
  },
  {
    id: '51',
    sigla: 'MT',
    nome: 'Mato Grosso'
  },
  {
    id: '50',
    sigla: 'MS',
    nome: 'Mato Grosso do Sul'
  },
  {
    id: '31',
    sigla: 'MG',
    nome: 'Minas Gerais'
  },
  {
    id: '15',
    sigla: 'PA',
    nome: 'Pará'
  },
  {
    id: '25',
    sigla: 'PB',
    nome: 'Paraíba'
  },
  {
    id: '41',
    sigla: 'PR',
    nome: 'Paraná'
  },
  {
    id: '26',
    sigla: 'PE',
    nome: 'Pernambuco'
  },
  {
    id: '22',
    sigla: 'PI',
    nome: 'Piauí'
  },
  {
    id: '33',
    sigla: 'RJ',
    nome: 'Rio de Janeiro'
  },
  {
    id: '24',
    sigla: 'RN',
    nome: 'Rio Grande do Norte'
  },
  {
    id: '43',
    sigla: 'RS',
    nome: 'Rio Grande do Sul'
  },
  {
    id: '11',
    sigla: 'RO',
    nome: 'Rondônia'
  },
  {
    id: '14',
    sigla: 'RR',
    nome: 'Roraima'
  },
  {
    id: '42',
    sigla: 'SC',
    nome: 'Santa Catarina'
  },
  {
    id: '35',
    sigla: 'SP',
    nome: 'São Paulo'
  },
  {
    id: '28',
    sigla: 'SE',
    nome: 'Sergipe'
  },
  {
    id: '17',
    sigla: 'TO',
    nome: 'Tocantins'
  }
]

export function getAddressInfoAction(cep: string):
  AsyncAction<{}, { httpClient: HttpClient }> {
  return async (dispatch) => {
    dispatch({ type: ADDRESS_BY_CEP_REQUESTED })
    await fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((blob) => blob.json())
      .then((response) => {
        if (response.erro) {
          dispatch({
            type: ADDRESS_BY_CEP_SUCCESS, addressFetchedFromCep: response
          })
          return
        }
        const fullState = brazilStates.map((state) => state)
          .filter((stateSigla) => stateSigla.sigla === response.uf)
        dispatch({
          type: ADDRESS_BY_CEP_SUCCESS, addressFetchedFromCep: response, fullState
        })
      })
      .catch((error) => {
        dispatch({ type: ADDRESS_BY_CEP_FAILURE })
        Sentry.captureException(new Error(`Failure getting the address by CEP - ${error.message}`))
      })
  }
}
