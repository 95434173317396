import React, { useEffect, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import {
  RegularButton, Divider, NewListItem, FormInput, DateInput
} from 'theia-web-ds'
import moment from 'moment'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import {
  fetchProfileAction,
  onUpdateHealthMomentDataInProfileAction
} from '../../state/profile/actions'
import RadioGroup, { RadioItem } from '../common/molecules/inputs/RadioGroup'
import { MomentInfosForm, ConsultantMoment } from '../../domain/Profile'
import CongratsPregnancyModal from './CongratsPregnancyModal'
import {
  isOtherMoment,
  isPostPregnant,
  isPregnant,
  isTrying
} from '../../utils/helpers'
import { getHighlightsAction } from '../../state/prescriptions/actions'
import './HealthDataView.scss'
import DocActive from '../../../assets/doc-active.png'

function renderMomentInfosForm(
  {
    values,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    handleBlur,
    handleChange,
    errors,
    touched
  }: FormikProps<MomentInfosForm>,
  onGoBack: () => void,
  isSubmitting : boolean,
  expectedBabyBirthDateOrigin?: string
) {
  const radioGroupEntries: RadioItem[] = [
    {
      id: 'Tentante',
      value: 'Tentando engravidar',
      checked: isTrying(values.pregnancyMoment)
    },
    {
      id: 'Grávida',
      value: 'Estou grávida',
      checked: isPregnant(values.pregnancyMoment)
    },
    {
      id: 'Puérpera',
      value: 'Acabei de ter um bebê',
      checked: isPostPregnant(values.pregnancyMoment)
    },
    {
      id: 'Outro momento',
      value: 'Outro',
      checked: isOtherMoment(values.pregnancyMoment)
    },
  ]

  function onChangePregnancyDueDate(date: Date) {
    if (!date) {
      setFieldValue('pregnancyDueDate', '')
      setFieldTouched('pregnancyDueDate', true)
    } else {
      setFieldValue('pregnancyDueDate', date.getTime())
      setFieldTouched('pregnancyDueDate', true)
    }
  }

  function onChangeFirstDayLastPeriod(date: Date) {
    if (!date) {
      setFieldValue('firstDayLastPeriod', '')
      setFieldTouched('firstDayLastPeriod', true)
    } else {
      setFieldValue('firstDayLastPeriod', date.getTime())
      setFieldTouched('firstDayLastPeriod', true)
    }
  }

  function onChangePregnancyEffectiveDueDate(date: Date) {
    if (!date) {
      setFieldValue('pregnancyEffectiveDueDate', '')
      setFieldTouched('pregnancyEffectiveDueDate', true)
    } else {
      setFieldValue('pregnancyEffectiveDueDate', date.getTime())
      setFieldTouched('pregnancyEffectiveDueDate', true)
    }
  }
  const extraComponentsByIndex = {
    1: (
      <div className="extra-inputs-container">
        {expectedBabyBirthDateOrigin !== 'APP' && (
        <NewListItem
          isLastItem
          isNoHover
          className="bg-bgBoxSecondary rounded-2xl mb-4"
          title="Essas informações são atualizadas pelo seu time de saúde Theia"
          picture={(
            <img
              src={DocActive}
              width={40}
              height={40}
              className="mr-3 ml-6"
              alt=""
            />
              )}
          ariaLabel="Banner infos atualizadas"
          titleSize="text-fontDefault"
          extraTitleClass="p-1 mr-3"
        />
        )}
        <DateInput
          required
          id="pregnancyDueDate"
          name="pregnancyDueDate"
          onChange={onChangePregnancyDueDate}
          blockPastDates
          placeholder="Selecione uma data"
          label="Data provável do parto"
          disabled={expectedBabyBirthDateOrigin !== 'APP'}
          maxDate={moment().add(9, 'M').toDate()}
          value={values.pregnancyDueDate}
          hasError={(
            isPregnant(values.pregnancyMoment)
            && !values.pregnancyDueDate && !values.firstDayLastPeriod
          )}
          errorText="Para continuar precisamos saber a data provável do parto ou a data da sua última menstruação."
        />
        <div className="health-view-individual-container">
          <DateInput
            required
            id="firstDayLastPeriod"
            name="firstDayLastPeriod"
            disabled={expectedBabyBirthDateOrigin !== 'APP'}
            onChange={onChangeFirstDayLastPeriod}
            blockFutureDates
            placeholder="Selecione uma data"
            label="Data da última menstruação"
            minDate={moment().subtract(9, 'M').toDate()}
            value={values.firstDayLastPeriod}
            hasError={(isPregnant(values.pregnancyMoment)
              && !values.pregnancyDueDate && !values.firstDayLastPeriod
            )}
          />
        </div>
      </div>
    ),
    2: (
      <div className="extra-inputs-container">
        <DateInput
          required
          id="pregnancyEffectiveDueDate"
          name="pregnancyEffectiveDueDate"
          onChange={onChangePregnancyEffectiveDueDate}
          blockFutureDates
          placeholder="Selecione uma data"
          label="Data efetiva do parto"
          value={values.pregnancyEffectiveDueDate}
          hasError={(isPostPregnant(values.pregnancyMoment)
            && !values.pregnancyEffectiveDueDate
          )}
          errorText="Para continuar precisamos saber a data efetiva do parto."
        />
      </div>
    ),
    3: (
      <div className="extra-inputs-container">
        <FormInput
          name="pregnancyMomentOther"
          type="text"
          id="pregnancyMomentOther"
          value={values.pregnancyMomentOther}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={touched.pregnancyMomentOther ? errors.pregnancyMomentOther : ''}
          placeholder="Qual?"
        />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit} className="health-view-drawer-form" noValidate>
      <div className="health-view-drawer-moment-inner-form">
        <div className="health-view-individual-container">
          <RadioGroup
            label="Qual o seu momento atual?"
            name="pregnancyMoment"
            choosenValue={values.pregnancyMoment}
            list={radioGroupEntries}
            extraComponents={extraComponentsByIndex}
            withDivider
            hideLabel
          />
        </div>
      </div>
      <div className="health-view-drawer-footer">
        <Divider />
        <div className="health-view-drawer-button">
          <RegularButton
            onClick={onGoBack}
            label="Agora não"
            variant="text"
            extraClass="booking-details-cancel-btn"
          />
          <RegularButton
            onClick={() => handleSubmit()}
            label="Salvar"
            type="submit"
            isSubmitting={isSubmitting}
            disabled={(
              (isPregnant(values.pregnancyMoment)
              && !values.pregnancyDueDate && !values.firstDayLastPeriod)
              || (isPregnant(values.pregnancyMoment) && expectedBabyBirthDateOrigin !== 'APP')
            ) || (
              isPostPregnant(values.pregnancyMoment)
              && !values.pregnancyEffectiveDueDate
            )}
            id="moment-save-button"
          />
        </div>
      </div>
    </form>
  )
}

interface Props {
  isSubmitting: boolean;
  pregnancyMoment?: ConsultantMoment;
  pregnancyMomentOther?: string;
  onGoBack: () => void;
  onUpdateHealthMomentDataInProfile: (
    name: string,
    email: string,
    phone: string,
    momentInfos: MomentInfosForm
  ) => void;
  name?: string;
  email?: string;
  phone?: string;
  fetchProfile: () => void;
  forceFetchProfile: boolean;
  getHighlights: () => void;
  pregnancyDueDate?: string;
  firstDayLastPeriod?: string;
  pregnancyMomentUpdateInfoText?: string;
  expectedBabyBirthDateOrigin?: string;
  pregnancyEffectiveDueDate?: string;
}

function MomentDataEdit({
  onUpdateHealthMomentDataInProfile,
  isSubmitting,
  pregnancyMoment,
  getHighlights,
  pregnancyMomentOther,
  onGoBack,
  expectedBabyBirthDateOrigin,
  name,
  email,
  phone,
  fetchProfile,
  forceFetchProfile,
  pregnancyDueDate,
  firstDayLastPeriod,
  pregnancyMomentUpdateInfoText,
  pregnancyEffectiveDueDate
}: Props) {
  const [alreadySendNewInfos, setAlreadySendNewInfos] = useState(false)
  const [needsShowCongratsModal, setNeedsShowCongratsModal] = useState(false)
  const [showCongratsModal, setShowCongratsModal] = useState(false)
  const [valuesSaved, setValuesSaved] = useState<MomentInfosForm>()

  const initialValues: MomentInfosForm = {
    pregnancyMoment: pregnancyMoment || ConsultantMoment.NONE,
    pregnancyMomentOther: pregnancyMomentOther || '',
    pregnancyDueDate: pregnancyDueDate || undefined,
    firstDayLastPeriod: firstDayLastPeriod || undefined,
    pregnancyEffectiveDueDate: pregnancyEffectiveDueDate || undefined
  }

  useEffect(() => {
    if (alreadySendNewInfos) {
      fetchProfile()
      setAlreadySendNewInfos(false)
      if (needsShowCongratsModal && pregnancyMomentUpdateInfoText) {
        setShowCongratsModal(true)
        setNeedsShowCongratsModal(false)
      } else {
        onGoBack()
      }
    }
  }, [forceFetchProfile])

  function onSuppressCongratsModal() {
    setShowCongratsModal(false)
    onGoBack()
  }

  function handleSubmit(_value: MomentInfosForm) {
    if (name && email && phone) {
      setAlreadySendNewInfos(true)
      setValuesSaved({ ..._value })
      if (isPregnant(_value.pregnancyMoment)) {
        setNeedsShowCongratsModal(true)
      }
      onUpdateHealthMomentDataInProfile(
        name,
        email,
        phone,
        { ..._value }
      )
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(_value: MomentInfosForm) => handleSubmit(_value)}
      >
        {(props) => renderMomentInfosForm(
          props,
          onGoBack,
          isSubmitting,
          expectedBabyBirthDateOrigin
        )}
      </Formik>
      <CongratsPregnancyModal
        visible={showCongratsModal}
        suppressModal={onSuppressCongratsModal}
        pregnancyMomentUpdateInfoText={pregnancyMomentUpdateInfoText}
        pregnancyMoment={valuesSaved?.pregnancyMoment}
        lastIdFocused="moment-save-button"
      />
    </>
  )
}

const mapStateToProps = ({
  profile, authentication
}: AppState) => ({
  pregnancyMoment: profile.profile?.pregnancyMoment,
  name: authentication.currentUser?.name,
  email: authentication.currentUser?.email,
  phone: authentication.currentUser?.phone,
  isSubmitting: profile.isUpdatingProfile,
  pregnancyMomentOther: profile.profile?.pregnancyMomentOther,
  forceFetchProfile: profile.forceFetchProfile,
  pregnancyDueDate: profile.profile?.pregnancyDueDate || profile.profile?.expectedBabyBirthDate,
  firstDayLastPeriod: profile.profile?.firstDayLastPeriod || profile.profile?.lastPeriodStartDate,
  pregnancyMomentUpdateInfoText: profile.pregnancyMomentUpdateInfoText,
  pregnancyEffectiveDueDate: profile.profile?.pregnancyEffectiveDueDate,
  expectedBabyBirthDateOrigin: profile.profile?.expectedBabyBirthDateOrigin,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onUpdateHealthMomentDataInProfile: (
    name: string,
    email: string,
    phone: string,
    momentInfos: MomentInfosForm
  ) => {
    dispatch(onUpdateHealthMomentDataInProfileAction(
      name,
      email,
      phone,
      momentInfos
    ))
  },
  fetchProfile: () => { dispatch(fetchProfileAction()) },
  getHighlights: () => { dispatch(getHighlightsAction()) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MomentDataEdit)
