import React, { useEffect } from 'react'
import './EditModal.scss'
import FocusLock from 'react-focus-lock'
import ReactDOM from 'react-dom'
import { IconButton } from '../IconButton/IconButton'
import { textPrimary } from '../../colors'
import { RegularButton, Props as RegularButtonProps } from '../RegularButton/RegularButton'
import { Loader } from '../Loader/Loader'
import { Snackbar } from '../Snackbar/Snackbar'

export interface Props {
  children: React.ReactNode;
  visible: boolean;
  lastIdFocused?: string;
  isLoading?: boolean;
  onLoadError?: boolean;
  showBackButton?: boolean;
  extraClass?: string;
  titleText?: string;
  onClose?: () => void;
  onClickBackButton?: () => void;
  buttonSaveProps?: RegularButtonProps;
  onClickRetry?: () => void;
  onSubmitError?: boolean;
  }

export function EditModal({
  children,
  isLoading,
  visible,
  onLoadError,
  extraClass,
  lastIdFocused,
  showBackButton,
  titleText,
  onClose,
  buttonSaveProps,
  onClickBackButton,
  onClickRetry,
  onSubmitError
}: Props) {
  function onCloseAndRefocused() {
    if (lastIdFocused !== undefined && lastIdFocused !== '') {
      if (document.getElementById(lastIdFocused)) {
        (document.getElementById(lastIdFocused) as any).focus()
      }
    }
    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    if (window.innerWidth <= 768) {
      if (visible) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'unset'
      }
    }
  }, [visible])

  function renderContent() {
    if (onLoadError) {
      return (
        <div className="flex items-start h-full w-full px-4 pb-4">
          <Snackbar
            iconLeft="icon-InfoSquareLight"
            alignTop
            title="Falha no carregamento"
            text="Desculpe, no momento não foi possível carregar as informações"
            buttonOneProps={{
              label: 'Tentar novamente',
              onClick: onClickRetry,
            }}
          />
        </div>
      )
    }
    if (isLoading) {
      return (
        <div className="flex items-center h-full justify-center pb-4">
          <Loader />
        </div>
      )
    }
    return (
      <>
        {onSubmitError && (
          <div className="px-4 pb-4">
            <Snackbar
              text="Erro ao salvar a informação"
              variant="error"
              iconLeft="icon-DangerCircleLight"
            />
          </div>
        )}
        { children }
      </>
    )
  }

  const modal = (
    <FocusLock>
      <div
        className="edit-modal-overlay"
        aria-hidden="true"
        role="dialog"
      />
      <div
        className={`edit-modal-container ${extraClass}`}
        role="dialog"
        aria-modal
      >
        <div className="p-4 flex items-center justify-between">
          {showBackButton && (
            <IconButton
              aria-label="Voltar"
              variant="subtle"
              width="32px"
              height="32px"
              iconType="icon-ArrowLeftLight"
              iconSize="20px"
              iconColor={textPrimary}
              data-dismiss="modal"
              onClick={onClickBackButton}
            />
          )}
          <p className="text-titleMedium text-textPrimary font-semibold">
            {titleText}
          </p>
          <IconButton
            aria-label="Fechar"
            variant="subtle"
            width="32px"
            height="32px"
            iconType="icon-CloseLight"
            iconSize="20px"
            iconColor={textPrimary}
            data-dismiss="modal"
            onClick={onCloseAndRefocused}
          />
        </div>
        {renderContent()}
        {isLoading || onLoadError ? null : (
          <div className="edit-modal-footer">
            <div className="footer-buttons-container">
              <RegularButton
                label="Cancelar"
                variant="text"
                onClick={onCloseAndRefocused}
                extraClass="regular-button-edit-modal"
              />
              <RegularButton
                {...buttonSaveProps}
                variant="primary"
                extraClass="regular-button-edit-modal"
              />
            </div>
          </div>
        )}
      </div>
    </FocusLock>
  )
  return visible ? ReactDOM.createPortal(modal, document.body) : null
}
