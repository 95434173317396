import React, { HTMLAttributes } from 'react'

export interface Props extends HTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  maxWidth?: string;
  height?: string;
  id?: string;
  borderRadius?: string;
  boxShadow?: string;
}

export function CardButton({
  onClick,
  children,
  className,
  disabled,
  id,
  maxWidth,
  height,
  borderRadius,
  boxShadow,
  ...props
}: Props) {
  return (
    <button
      id={id}
      data-testid="card-button-test-id"
      type="button"
      onClick={onClick}
      className={`focus:outline-none flex items-center pl-5 pr-5 justify-between text-textSmall ${className}`}
      style={{
        opacity: `${disabled ? '0.7' : ''}`,
        width: maxWidth || '100%',
        height: height || '88px',
        borderRadius: borderRadius || '12px',
        boxShadow: boxShadow || '0px 2px 4px rgba(31, 145, 173, 0.5)'
      }}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}
