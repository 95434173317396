import { Booking } from '../../domain/Profile'
import { SuccessRescheduleBookingActionType, SUCCESS_RESCHEDULE_BOOKING } from '../reschedule/actions'
import { SuccessBookingCancel, SUCCESS_BOOKING_CANCEL } from '../scheduleCancellation/actions'
import {
  FAILURE_FETCH_HISTORIC_BOOKINGS,
  MyBookingsTypes,
  REQUEST_FETCH_HISTORIC_BOOKINGS,
  SHOW_BOOKING_DETAILS_FROM_BOOKING_HISTORY,
  SUCCESS_FETCH_HISTORIC_BOOKINGS,
} from './actions'

export interface MyBookingsState {
  bookingHistory: Array<Booking>;
  isFetchingBookingHistory: boolean;
  forceUpdateHistory: boolean;
  hasPendingNotification: boolean;
}

const initialWeekFlow: MyBookingsState = {
  bookingHistory: [],
  isFetchingBookingHistory: false,
  forceUpdateHistory: false,
  hasPendingNotification: false
}

let bookingHistoryList: any

export function myBookingsFlow(
  state = initialWeekFlow,
  action: MyBookingsTypes | SuccessBookingCancel | SuccessRescheduleBookingActionType
): MyBookingsState {
  switch (action.type) {
    case REQUEST_FETCH_HISTORIC_BOOKINGS: {
      return {
        ...state,
        isFetchingBookingHistory: true,
      }
    }
    case SUCCESS_FETCH_HISTORIC_BOOKINGS: {
      bookingHistoryList = action.bookingsHistory
      const bookingHistoryPendingPrescriptionsViewed = bookingHistoryList.filter(
        (prescription: any) => prescription.prescriptionsViewed === false
      )
      return {
        ...state,
        bookingHistory: action.bookingsHistory,
        forceUpdateHistory: false,
        isFetchingBookingHistory: false,
        hasPendingNotification: bookingHistoryPendingPrescriptionsViewed.length >= 1
      }
    }
    case FAILURE_FETCH_HISTORIC_BOOKINGS: {
      return {
        ...state,
        isFetchingBookingHistory: false,
      }
    }
    case SUCCESS_BOOKING_CANCEL: {
      return {
        ...state,
        forceUpdateHistory: true
      }
    }
    case SUCCESS_RESCHEDULE_BOOKING: {
      return {
        ...state,
        forceUpdateHistory: true
      }
    }
    case SHOW_BOOKING_DETAILS_FROM_BOOKING_HISTORY: {
      const bookingHistoryPendingPrescriptionsViewed = bookingHistoryList.filter(
        (prescription: any) => prescription.prescriptionsViewed === false
      )
      return {
        ...state,
        hasPendingNotification: bookingHistoryPendingPrescriptionsViewed.length >= 1
      }
    }

    default: return state
  }
}
