import * as colors from 'theia-web-ds/src/colors'

export const {
  primaryColor,
  secondaryColor,
  infoColor,
  mediumOrangeColor,
  darkOrangeColor,
  lightPinkColor,
  mediumPinkColor,
  darkPinkColor,
  greyColor,
  lightGreyColor,
  mediumGreyColor,
  darkGreyColor,
  mediumDarkGreyColor,
  lightGreenColor,
  darkGreenColor,
  mediumPurpleColor,
  lightPurpleColor,
  beigeColor,
  lightBeigeColor,
  mediumBeigeColor,
  darkBeigeColor,
  bgBox,
  bgCanvas,
  bgBar,
  bgButton,
  bgDisable,
  textPrimary,
  textSecondary,
  textDisable,
  primary,
  primaryLight,
  primaryDark,
  primaryGradient,
  secondary,
  success,
  error,
  warning,
  overlayButton,
  overlay,
  overlayLight,
  overlayDark,
  overlayDarkest,
  divider,
  overlayBrandHover,
  overlayBrandFocus,
  overlayBrandPressed,
  overlayLightHover,
  overlayLightFocus,
  overlayLightPressed,
  bgBoxSecondary,
} = colors

module.exports = colors
