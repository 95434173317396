import React, { useEffect, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { RegularButton, Divider, FormInput } from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import { onUpdateHealthWeightDataInProfileAction } from '../../state/profile/actions'
import { AppDispatch } from '../../state/utils'
import { isPregnant as checkIsPregnant } from '../../utils/helpers'
import './HealthDataView.scss'

type WeightInfosForm = {
  weight: number;
  beforePregnancyWeight?: number;
}

function renderWeightInfosForm(
  {
    values,
    handleSubmit,
    handleBlur,
    handleChange,
    errors,
    touched
  }: FormikProps<WeightInfosForm>,
  onGoBack: () => void,
  isSubmitting: boolean,
  isPregnant: boolean
) {
  return (
    <form onSubmit={handleSubmit} className="health-view-drawer-form" noValidate>
      <div className="health-view-drawer-inner-form">
        <div className="health-view-individual-container">
          <FormInput
            id="weight"
            value={String(values.weight)}
            errorMessage={touched.weight ? errors.weight : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Peso atual (kg)"
            name="weight"
            type="number"
            placeholder="0.00 (kg)"
          />
        </div>
        {isPregnant && (
          <div className="health-view-individual-container">
            <FormInput
              id="beforePregnancyWeight"
              value={String(values.beforePregnancyWeight)}
              errorMessage={touched.beforePregnancyWeight ? errors.beforePregnancyWeight : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              label="Peso pré-gestacional (kg)"
              name="beforePregnancyWeight"
              type="number"
              placeholder="0.00 (kg)"
            />
          </div>
        )}
      </div>
      <div className="health-view-drawer-footer">
        <Divider />
        <div className="health-view-drawer-button">
          <RegularButton
            onClick={onGoBack}
            label="Agora não"
            variant="text"
            extraClass="booking-details-cancel-btn"
          />
          <RegularButton
            onClick={() => handleSubmit()}
            label="Salvar"
            type="submit"
            isSubmitting={isSubmitting}
            disabled={!values.beforePregnancyWeight && !values.weight}
          />
        </div>
      </div>
    </form>
  )
}

interface Props {
  isSubmitting: boolean;
  weight?: number;
  onGoBack: () => void;
  onUpdateHealthWeightDataInProfile: (
    name: string,
    email: string,
    phone: string,
    weight?: number,
    beforePregnancyWeight?: number
  ) => void;
  name?: string;
  email?: string;
  phone?: string;
  isPregnant: boolean;
  beforePregnancyWeight?: number;
  isUpdatingProfile: boolean;
}

const validationSchema = Yup.object().shape({
  weight: Yup.number()
    .min(0, 'Valor tem que ser positivo')
    .test('len', 'Máximo 4 digitos', (val) => val?.toString().length <= 5)
    .lessThan(200, 'Valor acima do permitido. Por favor, insira no formato 0.00 (kg)'),
  beforePregnancyWeight: Yup.number()
    .min(0, 'Valor tem que ser positivo')
    .test('len', 'Máximo 4 digitos', (val) => val?.toString().length <= 5)
    .lessThan(200, 'Valor acima do permitido. Por favor, insira no formato 0.00 (kg)')
    .notRequired()
})

function WeightDataEdit({
  onUpdateHealthWeightDataInProfile,
  isSubmitting,
  weight,
  onGoBack,
  name,
  email,
  phone,
  isPregnant,
  beforePregnancyWeight,
  isUpdatingProfile
}: Props) {
  const [alreadySendNewInfos, setAlreadySendNewInfos] = useState(false)

  const initialValues: WeightInfosForm = {
    weight: weight || 0,
    beforePregnancyWeight: beforePregnancyWeight || 0
  }

  useEffect(() => {
    if (!isUpdatingProfile && alreadySendNewInfos) {
      setAlreadySendNewInfos(false)
      onGoBack()
    }
  }, [isUpdatingProfile])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(_value: WeightInfosForm) => {
        if (name && email && phone) {
          setAlreadySendNewInfos(true)
          onUpdateHealthWeightDataInProfile(
            name,
            email,
            phone,
            _value.weight,
            _value.beforePregnancyWeight
          )
        }
      }}
    >
      {(props) => renderWeightInfosForm(
        props,
        onGoBack,
        isSubmitting,
        isPregnant
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  profile, authentication
}: AppState) => ({
  weight: profile.profile?.weight,
  name: authentication.currentUser?.name,
  email: authentication.currentUser?.email,
  phone: authentication.currentUser?.phone,
  isSubmitting: profile.isUpdatingProfile,
  beforePregnancyWeight: profile.profile?.beforePregnancyWeight,
  isPregnant: checkIsPregnant(profile.profile?.pregnancyMoment),
  isUpdatingProfile: profile.isUpdatingProfile
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onUpdateHealthWeightDataInProfile: (
    name: string,
    email: string,
    phone: string,
    weight?: number,
    beforePregnancyWeight?: number
  ) => {
    dispatch(onUpdateHealthWeightDataInProfileAction(
      name,
      email,
      phone,
      weight,
      beforePregnancyWeight
    ))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeightDataEdit)
