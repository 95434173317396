import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { AppDispatch } from '../../state/utils'
import BrokenClipboard from '../../../assets/feedback-bad-clipboard.svg'
import {
  sendNegativeFeedbackAction,
  toggleReasonAction,
  trackFeedbackDescriptionEventAction,
} from '../../state/feedback/actions'
import { AppState } from '../../apps/main/store'
import { Reason } from '../../domain/Booking'
import ReasonSelection from '../common/ReasonSelection'
import Modal from '../common/Modal'
import ConfirmButton from '../common/buttons/ConfirmButton'

interface Props {
  reasons: Array<Reason>;
  sendFeedback: (otherMotive: string) => void;
  toggleReason: (reason: Reason) => void;
  trackFeedbackDescriptionEvent: () => void;
}

function NegativeFeedbackModal({
  reasons,
  sendFeedback,
  toggleReason,
  trackFeedbackDescriptionEvent
}: Props) {
  const [otherMotive, setOtherMotive] = useState('')
  const [otherMotiveDirty, setOtherMotiveDirty] = useState(false)

  useEffect(() => {
    if (otherMotiveDirty) trackFeedbackDescriptionEvent()
  }, [otherMotiveDirty])

  function onSend() {
    sendFeedback(otherMotive)
  }

  function updateOtherMotive(value: string) {
    setOtherMotiveDirty(true)
    setOtherMotive(value)
  }

  return (
    <Modal>
      <div className="schedule-confirmed-modal flex flex-col text-center">
        <div className="self-center mb-4 ml-4">
          <BrokenClipboard />
        </div>
        <h1 className="modal__title">Oh, não! Conte para gente o que deu errado</h1>

        <p className="modal__info pt-2">
          Sentimos muito que não tenha sido uma boa experiência.
          Vamos fazer o máximo para mudar isso.
        </p>
        <p className="modal__info pt-2">
          O que não foi bom nessa consulta?
        </p>
        <ReasonSelection
          otherMotive={otherMotive}
          reasons={reasons}
          toggleReason={toggleReason}
          updateOtherMotive={updateOtherMotive}
        />
        <ConfirmButton className="mt-4" onClick={onSend}>Enviar feedback</ConfirmButton>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ feedback }: AppState) => ({
  reasons: feedback.reasons
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendFeedback: (otherMotive: string) => {
    dispatch(sendNegativeFeedbackAction(otherMotive))
  },
  toggleReason: (reason: Reason) => dispatch(toggleReasonAction(reason)),
  trackFeedbackDescriptionEvent: () => dispatch(trackFeedbackDescriptionEventAction()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NegativeFeedbackModal)
