import {
  PregnancyJourney,
  GuidedJourneyService,
  GuidedJourney,
  QuarterConsultation,
  ConsultationInfos,
  UpdateCardResponse
} from '../../domain/GuidedJourney'
import ApiClient from './ApiClient'

export default class ApiGuidedJourneyService implements GuidedJourneyService {
  constructor(private apiClient: ApiClient) { }

  async getPregnancyJourney(): Promise<PregnancyJourney> {
    const journeyResponse = await this.apiClient.getPregnancyJourney()
    return journeyResponse
  }

  async getGuidedJourney(): Promise<GuidedJourney> {
    const response = await this.apiClient.getGuidedJourney()
    return response
  }

  async getQuarterDetails(id: string): Promise<Array<QuarterConsultation>> {
    const response = await this.apiClient.getQuarterDetails(id)
    return response.journeyConsultants
  }

  async getConsultationInfos(id: string): Promise<ConsultationInfos> {
    const response = await this.apiClient.getConsultationInfos(id)
    return response
  }

  setViewedGuidedJourney(): Promise<void> {
    return this.apiClient.setViewedGuidedJourney()
  }

  async checkJourneyStep(id: string): Promise<UpdateCardResponse> {
    const response = await this.apiClient.checkJourneyStep(id)
    return response
  }

  async uncheckJourneyStep(id: string): Promise<UpdateCardResponse> {
    const response = await this.apiClient.uncheckJourneyStep(id)
    return response
  }
}
