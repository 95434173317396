import {
  FAILURE_UPDATE_BOOKING_SUGGESTION_STATUS,
  REQUEST_UPDATE_BOOKING_SUGGESTION_STATUS,
  RESET_NEED_UPDATE_WEEKS,
  SUCCESS_UPDATE_BOOKING_SUGGESTION_STATUS,
  SuggestionFlowTypes
} from './actions'

export interface SuggestionFlowState {
  isUpdatingBookingSuggestionStatus: boolean;
  needUpdateWeeks: boolean;
}

const initialSuggestionFlow: SuggestionFlowState = {
  isUpdatingBookingSuggestionStatus: false,
  needUpdateWeeks: false
}

export function suggestionFlow(
  state = initialSuggestionFlow,
  action: SuggestionFlowTypes
): SuggestionFlowState {
  switch (action.type) {
    case REQUEST_UPDATE_BOOKING_SUGGESTION_STATUS: {
      return {
        ...state,
        isUpdatingBookingSuggestionStatus: true,
      }
    }
    case SUCCESS_UPDATE_BOOKING_SUGGESTION_STATUS: {
      return {
        ...state,
        isUpdatingBookingSuggestionStatus: false,
        needUpdateWeeks: true,
      }
    }
    case FAILURE_UPDATE_BOOKING_SUGGESTION_STATUS: {
      return {
        ...state,
        isUpdatingBookingSuggestionStatus: false,
      }
    }
    case RESET_NEED_UPDATE_WEEKS: {
      return {
        ...state,
        needUpdateWeeks: false,
      }
    }
    default: return state
  }
}
