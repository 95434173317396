import React from 'react'
import { FormikProps } from 'formik'
import {
  FontIcon, RegularButton, MaskedFormInput, FormInput
} from 'theia-web-ds';
import {
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import {
  countries,
  errorMessages,
  paymentFormStyle,
  PaymentFormValues
} from './utils/helpers'
import './PaymentForm.scss'
import { warning } from '../../color';
import VisaImg from '../../../assets/Visa.svg';
import MasterImg from '../../../assets/Master.svg';
import FormSelect from '../common/FormSelect';

export function GenericPaymentForm(
  {
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    handleBlur,
    handleChange
  }: FormikProps<PaymentFormValues>,
  stripeLoadError: boolean,
  submitButtonText: string,
  isSubmittingCardData?: boolean,
  promotionalCodeHasError?: boolean,
  coupon?: string
) {
  const option = countries.map((index) => index.name) || countries.map((index) => index.code)
  const handleChangeCvc = (event: any) => {
    const cardErrors = document.getElementById('card-cvc-errors')
    const elementErrors = document.getElementById('errors-cvc-container')
    if (elementErrors !== null) {
      if (event?.error) {
        elementErrors.className = 'errors-container show-errors-element'
      } else {
        elementErrors.className = 'errors-container hide-errors-element'
      }
    }
    if (cardErrors != null) {
      if (event?.error) {
        const eventCode = event.error.code
        cardErrors.textContent = eventCode ? errorMessages[eventCode] : event.error.message
      } else {
        cardErrors.textContent = ''
      }
    }
  }
  const handleChangeNumber = (event: any) => {
    const cardErrors = document.getElementById('card-number-errors')
    const elementErrors = document.getElementById('errors-number-container')
    if (elementErrors !== null) {
      if (event?.error) {
        elementErrors.className = 'errors-container show-errors-element'
      } else {
        elementErrors.className = 'errors-container hide-errors-element'
      }
    }
    if (cardErrors != null) {
      if (event?.error) {
        const eventCode = event.error.code
        cardErrors.textContent = eventCode ? errorMessages[eventCode] : event.error.message
      } else {
        cardErrors.textContent = ''
      }
    }
  }
  const handleChangeExpiry = (event: any) => {
    const cardErrors = document.getElementById('card-expiry-errors')
    const elementErrors = document.getElementById('errors-expiry-container')
    if (elementErrors !== null) {
      if (event?.error) {
        elementErrors.className = 'errors-container show-errors-element'
      } else {
        elementErrors.className = 'errors-container hide-errors-element'
      }
    }
    if (cardErrors != null) {
      if (event?.error) {
        const eventCode = event.error.code
        cardErrors.textContent = eventCode ? errorMessages[eventCode] : event.error.message
      } else {
        cardErrors.textContent = ''
      }
    }
  }
  const canSubmit = (
    !!values.name
    && !!values.country
    && values.postalCode
    && (!promotionalCodeHasError || coupon === '' || !coupon)
  )

  const isDisabled = isSubmittingCardData
    || isSubmitting
    || !canSubmit
    || Object.keys(errors).length !== 0

  return (
    <form onSubmit={handleSubmit} noValidate id="payment-form" className="generic-payment-form">
      {stripeLoadError
        ? (
          <p className="stripe-error-text">
            Ops! Não foi possível carregar o formulário de pagamento.
            Por favor, tente novamente atualizando a página ou entre
            em contato conosco para resolvermos juntos(as)!
          </p>
        ) : (
          <>
            <div className="mb-4">
              <FormInput
                id="name"
                errorMessage={touched.name ? errors.name : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                placeholder="Digite aqui o seu nome"
                type="text"
                name="name"
                label="Nome no cartão"
              />

            </div>
            <div id="card-element">
              <label htmlFor="cardNumber" className="payment-form-card-label">Número do cartão</label>
              <CardNumberElement
                id="cardNumber"
                options={{
                  style: paymentFormStyle
                }}
                onChange={handleChangeNumber}
              />
              <div id="errors-number-container" className="errors-container">
                <FontIcon iconType="icon-DangerBold" fontSize="11px" color={warning} />
                <div id="card-number-errors" className="element-errors" />
              </div>
              <div className="flex items-center text-textSecondary mt-2 mb-4 text-sm">
                Bandeiras aceitas:
                <VisaImg heigh="30px" width="46px" className="ml-4 mr-2" />
                <MasterImg heigh="30px" width="46px" />
              </div>
              <div className="payment-form-card-infos">
                <div className="payment-form-card-expiry">
                  <label htmlFor="cardNumber" className="payment-form-card-label">Data de vencimento</label>
                  <CardExpiryElement
                    id="cardExpiry"
                    options={{
                      style: paymentFormStyle
                    }}
                    onChange={handleChangeExpiry}
                  />
                  <div id="errors-expiry-container" className="errors-container">
                    <FontIcon iconType="icon-DangerBold" fontSize="11px" color={warning} />
                    <div id="card-expiry-errors" className="element-errors" />
                  </div>
                </div>
                <div className="payment-form-card-cvc">
                  <label htmlFor="cardNumber" className="payment-form-card-label mt-4">Código de segurança</label>
                  <CardCvcElement
                    id="cardCvc"
                    options={{
                      style: paymentFormStyle
                    }}
                    onChange={handleChangeCvc}
                  />
                  <div id="errors-cvc-container" className="errors-container">
                    <FontIcon iconType="icon-DangerBold" fontSize="11px" color={warning} />
                    <div id="card-cvc-errors" className="element-errors" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-4 mb-4 flex-col lg:flex-row">
              <div className="lg:w-1/2 lg:mr-4 w-full mb-4 lg:mb-0">
                <FormSelect
                  name="country"
                  options={option}
                  label="País"
                  placeholder="Selecionar"
                />
              </div>
              <div className="lg:w-1/2 w-full">
                <MaskedFormInput
                  label="CEP"
                  id="postalCode"
                  errorMessage={touched.postalCode ? errors.postalCode : ''}
                  onChange={handleChange}
                  placeholder="00000-000"
                  onBlur={handleBlur}
                  name="postalCode"
                  value={values.postalCode}
                  type="text"
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                />
              </div>
            </div>
          </>
        )}
      <div className="payment-container-button">
        <RegularButton
          type="submit"
          id="btn-submit"
          disabled={isDisabled}
          maxWidth="400px"
          isSubmitting={isSubmittingCardData || isSubmitting}
          extraClass="mt-6"
          label={submitButtonText}
        />
      </div>
    </form>
  )
}
