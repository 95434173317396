import React, { useEffect, useRef, useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import './Authentication.scss'
import {
  RegularButton, ErrorMessage, Button, FormInput
} from 'theia-web-ds'
import {
  loginAction,
  postEmailUnlockAction,
  postLoginAction
} from '../../state/authentication/main/actions'
import AppMainContainerOff from '../AppMainContainerOff'
import { postRecaptchaGoogleAction } from '../../state/authentication/recaptcha/actions'
import { AppState } from '../../apps/main/store'
import ErrorModal from '../common/ErrorModal'
import { promptErrorAction, ErrorWithFriendlyMessage } from '../../state/errorWithFriendlyMessage/actions'
import { LoginPayload, UserLoginStatus } from '../../domain/User'
import { REDIRECT_SIGNUP } from '../../state/remoteConfig'
import { LISTA_DE_INTERESSE, MOMENTO_DE_SAUDE, RECOVERY } from '../../routes/RoutesConstants'
import { isNotNullOrUndefined } from '../../utils/helpers'

interface Props {
  login: () => void;
  recaptchaGoogle: (token: string) => void;
  isSuccess: boolean;
  isFailure: boolean;
  promptError: (signupError: ErrorWithFriendlyMessage) => void;
  defaultErrorVisible: boolean;
  postEmailUnlock: (email: string) => void;
  isLoading: boolean;
  postLogin: (payload: LoginPayload) => void;
  postLoginStatus: UserLoginStatus;
  isAuthenticating: boolean;
}

interface LoginFormValues {
  password: string;
  loginField: string;
}

const initialValues = {
  password: '',
  loginField: ''
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Senha obrigatória'),
  loginField: Yup.string().email('E-mail inválido').required('Email obrigatório')
})

function Login({
  login,
  recaptchaGoogle,
  isSuccess,
  isFailure,
  promptError,
  defaultErrorVisible,
  postEmailUnlock,
  isLoading,
  postLogin,
  postLoginStatus,
  isAuthenticating
}: Props) {
  const [emailResend, setEmailResend] = useState<string>('')
  const [captchaToken, setCaptchaToken] = useState<string>('')
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)
  const [expiredToken, setExpiredToken] = useState<boolean>(false)
  const [displayCaptcha, setDisplayCaptcha] = useState<boolean>(false)
  const [errorValidation, setErrorValidation] = useState<string>('')
  const localDisplayCaptcha = localStorage.getItem('displayCaptcha')
  const history = useHistory()

  function goToSignUp() {
    if (REDIRECT_SIGNUP) {
      history.push(LISTA_DE_INTERESSE)
    } else {
      history.push(MOMENTO_DE_SAUDE)
    }
  }

  useEffect(() => {
    if (postLoginStatus.errorPost) {
      const jsonError = postLoginStatus.errorData
      if (jsonError) {
        const jsonMsg = jsonError.friendlyMessage?.message
        if (jsonMsg === 'Login inválido' && !jsonError.recaptcha) {
          setErrorValidation('E-mail ou senha inválidos')
        } else {
          setErrorValidation(jsonMsg || '')
        }
        setShowErrorMessage(true)
        if (jsonError.blocked) {
          const recoveryError = {
            friendlyMessageTitle: jsonError.friendlyMessage?.title,
            friendlyMessage: jsonError.friendlyMessage?.message,
            message: jsonError.friendlyMessage?.obs
          }
          promptError(recoveryError)
        }
        if (jsonError.recaptcha) {
          setDisplayCaptcha(true)
          localStorage.setItem('displayCaptcha', 'true')
        }
      }
    }
  }, [postLoginStatus])

  useEffect(() => {
    if (postLoginStatus.isPost) {
      login()
      setErrorValidation('')
    }
  }, [postLoginStatus])

  function goToRecoveryPassword() {
    history.push(RECOVERY)
  }

  const captchaRef = useRef(null) as any

  const verify = () => {
    if (isNotNullOrUndefined(captchaRef)) {
      setCaptchaToken(captchaRef.current.getValue())
    }
  }

  function handleLogin(values: LoginFormValues) {
    const { password, loginField } = values
    postLogin({ login: loginField, password })
  }

  const onSubmit = (values: LoginFormValues) => {
    setShowErrorMessage(false)
    const { loginField } = values
    setEmailResend(loginField)
    if (displayCaptcha || localDisplayCaptcha) {
      if (isSuccess) {
        handleLogin(values)
      } else if (captchaToken === '') {
        setShowErrorMessage(true)
      }
    } else {
      handleLogin(values)
    }
  }

  useEffect(() => {
    if (captchaToken !== '') {
      recaptchaGoogle(captchaToken)
    }
  }, [captchaToken])

  useEffect(() => {
    if (isFailure) {
      setTimeout(() => {
        captchaRef.current.reset()
      }, 3000)
      setShowErrorMessage(true)
    } else if (isSuccess) {
      setShowErrorMessage(false)
      setExpiredToken(false)
    }
  }, [isFailure, isSuccess])

  const recaptchaErrorMessage = () => {
    if (captchaToken === '') {
      return 'Confirmação do recaptcha obrigatória'
    }
    if (isFailure) {
      return 'Um erro aconteceu ao validar o token. Tente novamente'
    }
    return ''
  }

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({
          handleSubmit, errors, handleBlur, handleChange, values, touched
        }: FormikProps<LoginFormValues>) => (
          <AppMainContainerOff screenTitle="Vamos fazer o seu login">
            <div className="buttons-content">
              <form onSubmit={handleSubmit} className="mx-auto w-full lg:max-w-x" noValidate>
                <div className="mb-4">
                  {errorValidation !== '' && showErrorMessage && <ErrorMessage error={errorValidation} />}
                </div>
                <div className="mb-4">
                  <FormInput
                    label="E-mail"
                    type="email"
                    name="loginField"
                    placeholder="Digite aqui seu e-mail"
                    id="loginField"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.loginField}
                    errorMessage={touched.loginField ? errors.loginField : ''}
                  />
                </div>
                <div className="mt-4 mb-4">
                  <FormInput
                    label="Senha"
                    name="password"
                    maxLength={64}
                    placeholder="Digite aqui sua senha"
                    iconSize="24px"
                    isPassword
                    id="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    errorMessage={touched.password ? errors.password : ''}
                  />
                </div>
                {(displayCaptcha || localDisplayCaptcha)
          && (
          <div className="mt-4 mb-4 w-full">
            <ReCAPTCHA
              sitekey={process.env.RECAPTCHA_KEY || ''}
              ref={captchaRef}
              onChange={verify}
              size="normal"
              onExpired={() => setExpiredToken(true)}
            />
            {(showErrorMessage && recaptchaErrorMessage()) && (
              <ErrorMessage error={recaptchaErrorMessage()} />
            )}
          </div>
          )}
                <div className="mt-1">
                  <Button type="button" className="text-textSmaller underline text-greyColor float-right font-light" height="auto" onClick={goToRecoveryPassword}>
                    Esqueci minha senha
                  </Button>
                </div>
                <div className="mt-16 flex flex-auto justify-center ">
                  <RegularButton
                    type="submit"
                    label="Acessar minha conta"
                    disabled={postLoginStatus.isPosting || isAuthenticating || expiredToken}
                    isSubmitting={postLoginStatus.isPosting || isAuthenticating}
                    maxWidth="100%"
                  />
                </div>
                <div className="text-center mt-4">
                  <Button type="button" className="text-textSmaller underline text-greyColor font-light" height="auto" width="100%" onClick={goToSignUp}>Criar nova conta</Button>
                </div>
              </form>
            </div>
          </AppMainContainerOff>
        )}
      </Formik>
      <ErrorModal
        visible={defaultErrorVisible}
        hasObs
        buttonText="Tentar mais tarde"
        extraButton
        extraButtonText="Reenviar e-mail"
        extraButtonclick={() => postEmailUnlock(emailResend)}
        isSubmitting={isLoading}
      />
    </>
  )
}

const mapStateToProps = ({ recaptcha, errorWithFriendlyMessage, authentication }: AppState) => ({
  isSuccess: recaptcha.isSuccess,
  isFailure: recaptcha.isFailure,
  defaultErrorVisible: errorWithFriendlyMessage.visible,
  isLoading: authentication.isLoading,
  postLoginStatus: authentication.postLoginStatus,
  isAuthenticating: authentication.isAuthenticating
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  login: loginAction,
  recaptchaGoogle: postRecaptchaGoogleAction,
  promptError: promptErrorAction,
  postEmailUnlock: postEmailUnlockAction,
  postLogin: postLoginAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
