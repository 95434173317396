import React from 'react'
import { FontIcon } from '../FontIcon/FontIcon'
import { error as errorColor } from '../../colors'
import './ErrorMessage.scss'

export interface Props {
  error: string;
  iconSize?: string;
}

export function ErrorMessage({
  error,
  iconSize = '11px'
}: Props) {
  return (
    <div className="error-container" data-testid="error-container">
      <FontIcon
        iconType="icon-DangerBold"
        fontSize={iconSize}
        color={errorColor}
      />
      <p className="error-container-text">
        {error}
      </p>
    </div>
  )
}
