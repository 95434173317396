/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  NewListItem,
  RegularButton,
  ListItemCard,
  BadgeNotification,
  Divider
} from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import Header from '../common/molecules/Header'
import ProfilePicture from '../common/molecules/ProfilePicture'
import { error, primary, textSecondary } from '../../color'
import Item from '../common/molecules/Item'
import {
  getAge,
  getBooleanText,
  isOtherMoment,
  isPregnant
} from '../../utils/helpers'
import { fetchProfileAction } from '../../state/profile/actions'
import HealthStatus from '../../../assets/health-status.png'
import Exams from '../../../assets/exams.png'
import WeightStatus from '../../../assets/weight-status.png'
import RoutineFoodStatus from '../../../assets/routine-food-status.png'
import MedicationStatus from '../../../assets/medication-status.png'
import HistoryStatus from '../../../assets/history-status.png'
import Drawer from '../common/drawer/Drawer'
import PersonalDataEdit from './PersonalDataEdit'
import { promptDrawerAction, suppressDrawerAction } from '../../state/drawer/actions'
import WeightDataEdit from './WeightDataEdit'
import RoutineAndFoodDataEdit from './RoutineAndFoodDataEdit'
import MedicationDataEdit from './MedicationDataEdit'
import MomentDataEdit from './MomentDataEdit'
import HistoryDataEdit from './HistoryDataEdit'
import { getHighlightsAction, getPrescriptionsInformationAction, onSendHighlightAction } from '../../state/prescriptions/actions'
import { eventPage } from '../../../eventGenerate'
import { ConsultantMoment } from '../../domain/Profile'
import './HealthDataView.scss'
import { MEDICAL_REQUESTS } from '../../routes/RoutesConstants'

interface Props {
  pictureUrl?: string | null;
  userName?: string;
  birthDate?: string;
  height?: number;
  fetchProfile: () => void;
  isFetchingProfile: boolean;
  hasProfile: boolean;
  hasAllergy?: string;
  bloodType?: string;
  pregnancyMomentText?: string;
  weight?: number;
  isFilledRoutineAndFood?: boolean;
  hasUseSomeMedicine?: string;
  useSomeMedicine?: string[];
  routinetext?: string;
  promptDrawer: () => void;
  suppressDrawer: () => void;
  isFilledPregnancyHistory: boolean;
  pregnancyMoment?: ConsultantMoment;
  beforePregnancyWeight?: number;
  unseenMedicalRequests?: number;
  getPrescriptionsInformation: () => void;
  isLoadingPrescriptions?: boolean;
  isSuccessPostHighlight?: boolean;
  getHighlights: () => void;
  onSendHightlight: (hightlight: string) => void;
  showNewPrescriptionBadge?: boolean;
}

function HealthDataView({
  pictureUrl,
  userName,
  birthDate,
  height,
  fetchProfile,
  isFetchingProfile,
  hasProfile,
  hasAllergy,
  bloodType,
  pregnancyMomentText,
  weight,
  isFilledRoutineAndFood,
  isFilledPregnancyHistory,
  hasUseSomeMedicine,
  useSomeMedicine,
  routinetext,
  promptDrawer,
  suppressDrawer,
  pregnancyMoment,
  beforePregnancyWeight,
  unseenMedicalRequests,
  getPrescriptionsInformation,
  isLoadingPrescriptions,
  isSuccessPostHighlight,
  getHighlights,
  onSendHightlight,
  showNewPrescriptionBadge
}: Props) {
  const [isOpen, setIsOpen] = useState(true)
  const [userDataType, setUserDataType] = useState('')
  const [drawerTitle, setDrawerTitle] = useState('')
  const [medicalRequestsMsg, setMedicalRequestsMsg] = useState('')

  useEffect(() => {
    if (showNewPrescriptionBadge) {
      onSendHightlight('highlight-health-menu-item')
    }
  }, [showNewPrescriptionBadge])

  useEffect(() => {
    if (isSuccessPostHighlight) {
      getHighlights()
    }
  }, [isSuccessPostHighlight])

  useEffect(() => {
    if (unseenMedicalRequests === 0 || unseenMedicalRequests === undefined) {
      setMedicalRequestsMsg('')
    } else if (unseenMedicalRequests === 1) {
      setMedicalRequestsMsg(`Você tem ${unseenMedicalRequests} novo pedido`)
    } else {
      setMedicalRequestsMsg(`Você tem ${unseenMedicalRequests} novos pedidos`)
    }
  }, [unseenMedicalRequests])

  const history = useHistory()

  useEffect(() => {
    getPrescriptionsInformation()
    eventPage('saude', 'Saúde')
  }, [])

  useEffect(() => {
    if (!hasProfile && !isFetchingProfile) {
      fetchProfile()
    }
  }, [])

  function onOpenOrCloseUserData() {
    setIsOpen(!isOpen)
  }

  function extraActionOnSuppressDrawer() {
    setUserDataType('')
    setDrawerTitle('')
  }
  function onShowPersonalEditModal() {
    promptDrawer()
    setUserDataType('personal')
    setDrawerTitle('Editar dados')
  }
  function onShowWeightEditModal() {
    promptDrawer()
    setUserDataType('weight')
    setDrawerTitle('Peso atual')
  }
  function onShowRoutineAndFoodEditModal() {
    promptDrawer()
    setUserDataType('routineAndFood')
    setDrawerTitle('Rotina e alimentação')
  }

  function onShowMedicineEditModal() {
    promptDrawer()
    setUserDataType('medicine')
    setDrawerTitle('Medicações')
  }

  function onShowMomentEditModal() {
    promptDrawer()
    setUserDataType('moment')
    setDrawerTitle('Status atual')
  }

  function onShowHistoryEditModal() {
    promptDrawer()
    setUserDataType('history')
    setDrawerTitle('Histórico gestacional')
  }

  function onCancel() {
    extraActionOnSuppressDrawer()
    suppressDrawer()
  }

  const goToMedicalRequests = () => {
    history.push(MEDICAL_REQUESTS)
  }

  const age = birthDate ? getAge(birthDate) : undefined
  const hasAllergyText = hasAllergy ? getBooleanText(hasAllergy === 'true') : undefined
  const profileItensComponent = (
    <>
      <div className="profile-items">
        <Item
          iconType="icon-BirthdayLight"
          iconColor={textSecondary}
          iconSize="24px"
          label="Idade"
          value={age}
          ariaLabel="Idade"
          id="Idade"
          isLoading={isFetchingProfile}
        />
        <Item
          iconType="icon-RullerLight"
          iconColor={textSecondary}
          iconSize="24px"
          label="Altura"
          value={height ? `${height.toLocaleString('pt-BR', { minimumFractionDigits: 2, useGrouping: false })}` : undefined}
          ariaLabel="Altura"
          id="Altura"
          isLoading={isFetchingProfile}
        />
      </div>
      <div className="profile-items">
        <Item
          iconType="icon-AllergieLight"
          iconColor={textSecondary}
          iconSize="24px"
          label="Alergias"
          value={hasAllergyText}
          ariaLabel="Alergias"
          id="Alergias"
          isLoading={isFetchingProfile}
        />
        <Item
          iconType="icon-WaterDropLight"
          iconColor={textSecondary}
          iconSize="24px"
          label="Tipo sanguíneo"
          value={bloodType}
          ariaLabel="Tipo sanguíneo"
          id="Tipo sanguíneo"
          isLoading={isFetchingProfile}
        />
      </div>
      <div className="health-profile-button">
        <RegularButton
          onClick={onShowPersonalEditModal}
          label="Editar dados"
          variant="subtle"
          isMountingScreen={isFetchingProfile}
        />
      </div>
    </>
  )

  const isFilledWeight = isPregnant(pregnancyMoment) ? (
    !!beforePregnancyWeight && !!weight
  ) : (
    !!weight
  )

  return (
    <div className="w-full pb-6 health-view">
      <Header
        title="Saúde"
      />
      <Divider />
      <div className="health-view-grid-container">
        <div className="health-view-item-a">
          <div className="profile-card-container-item-a">
            <NewListItem
              onClick={onOpenOrCloseUserData}
              picture={(
                <ProfilePicture
                  pictureUrl={pictureUrl}
                  pictureSize={40}
                  isLoading={isFetchingProfile}
                />
              )}
              iconColor={primary}
              title={userName || ''}
              ariaLabel={userName || 'Dados do usuário'}
              isLastItem
              isOpen={isOpen}
              isOpenAndCloseButton
              isLoading={isFetchingProfile}
              isItemFromList={false}
              key="user-data-health-mobile"
            />
            {isOpen && (
              profileItensComponent
            )}
          </div>
          <Divider className="divider-item-a" />
          <ListItemCard
            extraClassNameContainer="health-card relative"
            onClick={goToMedicalRequests}
            picture={(
              <img
                src={Exams}
                width={40}
                height={40}
                className="health-icon"
                alt=""
              />
            )}
            iconColor={primary}
            caption={medicalRequestsMsg}
            title="Pedidos médicos"
            ariaLabel="Pedidos médicos"
            alertMessage=""
            isLastItem
            isLoading={isLoadingPrescriptions}
            isItemFromList={false}
            key="medical-requests-health"
          >
            {(unseenMedicalRequests !== 0
              && unseenMedicalRequests !== undefined
              && !isLoadingPrescriptions) && (
              <BadgeNotification
                color={error}
                width={20}
                height={20}
                borderRadius="50%"
                extraClass="absolute exam-number-badge"
              >
                <span className="number-inside-badge-container">{unseenMedicalRequests}</span>
              </BadgeNotification>
            )}
          </ListItemCard>
          <ListItemCard
            extraClassNameContainer="health-card"
            onClick={onShowMomentEditModal}
            showCaptionFirst
            picture={(
              <img
                src={HealthStatus}
                width={40}
                height={40}
                className="health-icon"
                alt=""
              />
            )}
            iconColor={primary}
            caption={pregnancyMomentText ? 'Momento atual' : undefined}
            title={pregnancyMomentText || 'Momento atual'}
            ariaLabel="Momento atual"
            alertMessage={!pregnancyMomentText ? 'Completar dados' : undefined}
            isLastItem
            isLoading={isFetchingProfile}
            isItemFromList={false}
            key="status-moment-health"
          />
          <ListItemCard
            extraClassNameContainer="health-card"
            onClick={onShowWeightEditModal}
            picture={(
              <img
                src={WeightStatus}
                width={40}
                height={40}
                className="health-icon"
                alt=""
              />
            )}
            iconColor={primary}
            caption={isFilledWeight ? 'Peso atual' : undefined}
            title={isFilledWeight ? `${weight}kg` : 'Peso atual'}
            ariaLabel="Peso atual"
            alertMessage={!isFilledWeight ? 'Completar dados' : undefined}
            isLastItem
            isLoading={isFetchingProfile}
            isItemFromList={false}
            key="weight-health"
          />
          <ListItemCard
            extraClassNameContainer="health-card"
            onClick={onShowRoutineAndFoodEditModal}
            picture={(
              <img
                src={RoutineFoodStatus}
                width={40}
                height={40}
                className="health-icon"
                alt=""
              />
            )}
            iconColor={primary}
            caption={isFilledRoutineAndFood && routinetext ? 'Rotina e alimentação' : undefined}
            title={isFilledRoutineAndFood && routinetext ? routinetext : 'Rotina e alimentação'}
            ariaLabel="Rotina e alimentação"
            alertMessage={!isFilledRoutineAndFood ? 'Completar dados' : undefined}
            isLastItem
            isLoading={isFetchingProfile}
            isItemFromList={false}
            key="routine-health"
          />
          <ListItemCard
            extraClassNameContainer="health-card"
            onClick={onShowMedicineEditModal}
            picture={(
              <img
                src={MedicationStatus}
                width={40}
                height={40}
                className="health-icon"
                alt=""
              />
            )}
            iconColor={primary}
            title="Medicação atual"
            ariaLabel="Medicação atual"
            alertMessage={!hasUseSomeMedicine ? 'Completar dados' : undefined}
            isLastItem
            isLoading={isFetchingProfile}
            isItemFromList={false}
            hideIconButton
            extraComponent={(
              <p className="use-some-medicine-length-text">Todos ({useSomeMedicine?.length})</p>
            )}
            key="medicine-health"
          >
            {!isFetchingProfile && (useSomeMedicine && useSomeMedicine.length > 0) && <Divider />}
            {useSomeMedicine?.map((medicine, index) => (
              <NewListItem
                onClick={onShowMedicineEditModal}
                iconSize="24px"
                iconType="icon-PillBold"
                iconColor={primary}
                title={medicine}
                ariaLabel={medicine}
                isLastItem={index === (useSomeMedicine.length - 1)}
                isLoading={isFetchingProfile}
                key={`medicine-${index}`}
              />
            ))}
          </ListItemCard>
          {!isOtherMoment(pregnancyMoment) && (
            <ListItemCard
              extraClassNameContainer="health-card"
              onClick={onShowHistoryEditModal}
              picture={(
                <img
                  src={HistoryStatus}
                  width={40}
                  height={40}
                  className="health-icon"
                  alt=""
                />
              )}
              iconColor={primary}
              title="Histórico gestacional"
              ariaLabel="Histórico gestacional"
              alertMessage={!isFilledPregnancyHistory ? 'Completar dados' : undefined}
              isLastItem
              isLoading={isFetchingProfile}
              isItemFromList={false}
              key="history-health"
            />
          )}
        </div>
        <div className="health-view-item-b">
          <div className="profile-container">
            <div className="profile-data">
              <ProfilePicture
                pictureUrl={pictureUrl}
                pictureSize={94}
                isLoading={isFetchingProfile}
              />
              {isFetchingProfile ? (
                <div className="user-name-loading" />
              ) : (
                <p className="user-name-style">{userName || ''}</p>
              )}
            </div>
            {profileItensComponent}
          </div>
        </div>
      </div>
      {userDataType && (
        <Drawer
          variant="rightDrawer"
          extraAction={extraActionOnSuppressDrawer}
          id="health-data-modal"
          text={drawerTitle}
        >
          {userDataType === 'personal'
            && (
              <PersonalDataEdit
                onGoBack={onCancel}
              />
            )}
          {userDataType === 'weight'
            && (
              <WeightDataEdit
                onGoBack={onCancel}
              />
            )}
          {userDataType === 'routineAndFood'
            && (
              <RoutineAndFoodDataEdit
                onGoBack={onCancel}
              />
            )}
          {userDataType === 'medicine'
            && (
              <MedicationDataEdit
                onGoBack={onCancel}
              />
            )}
          {userDataType === 'moment'
            && (
              <MomentDataEdit
                onGoBack={onCancel}
              />
            )}
          {userDataType === 'history'
            && (
              <HistoryDataEdit
                onGoBack={onCancel}
              />
            )}
        </Drawer>
      )}
    </div>
  )
}

const mapStateToProps = ({
  profile, authentication, prescriptions
}: AppState) => {
  const worktypesList = (
    [
      ...(profile.profile?.workType || []),
      ...(profile.profile?.workTypeOthers || []),
    ]
  ).filter((element) => element !== 'Outro')
  return {
    pictureUrl: authentication.currentUser?.pictureUrl,
    userName: authentication.currentUser?.name,
    birthDate: profile.profile?.birthDate,
    height: profile.profile?.height,
    isFetchingProfile: profile.isFetchingProfile,
    hasProfile: !!profile.profile,
    hasAllergy: profile.profile?.hasAllergy,
    bloodType: profile.profile?.bloodType,
    weight: profile.profile?.weight,
    pregnancyMomentText: profile.profile?.pregnancyMomentText,
    isFilledRoutineAndFood: (
      (profile.profile?.workType || []).length > 0
      && profile.profile?.hasFoodRestriction !== undefined
      && profile.profile?.hasFoodRestriction !== ''
      && profile.profile?.hasSomeHealthCondition !== undefined
      && profile.profile?.hasSomeHealthCondition !== ''
    ),
    routinetext: worktypesList.length > 0 ? worktypesList[0] : undefined,
    hasUseSomeMedicine: profile.profile?.hasUseSomeMedicine,
    useSomeMedicine: profile.profile?.useSomeMedicine,
    isFilledPregnancyHistory: (
      profile.profile?.hasKids !== undefined
      && profile.profile?.hasKids !== null
      && (
        (profile.profile?.hasKids === 'true' && profile.profile?.numberOfKids > 0)
        || profile.profile?.hasKids === 'false'
      )
      && profile.profile?.hasPregnancyLoss !== undefined
      && profile.profile?.hasPregnancyLoss !== null
      && profile.profile?.hasAboutRelationship !== undefined
      && profile.profile?.hasAboutRelationship !== null
    ),
    pregnancyMoment: profile.profile?.pregnancyMoment,
    beforePregnancyWeight: profile.profile?.beforePregnancyWeight,
    unseenMedicalRequests: prescriptions.unseenMedicalRequests,
    isLoadingPrescriptions: prescriptions.isLoading,
    isSuccessPostHighlight: prescriptions.isSuccessPostHighlight,
    showNewPrescriptionBadge: prescriptions.highlightHealthMenuItem
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  fetchProfile: fetchProfileAction,
  promptDrawer: promptDrawerAction,
  suppressDrawer: suppressDrawerAction,
  getPrescriptionsInformation: getPrescriptionsInformationAction,
  getHighlights: getHighlightsAction,
  onSendHightlight: onSendHighlightAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthDataView)
