import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { RegularButton } from 'theia-web-ds'
import AppMainContainerOff from '../AppMainContainerOff'
import './SalesOnboarding.scss'
import { eventTrack } from '../../../eventGenerate'
import { BOAS_VINDAS } from '../../routes/RoutesConstants'
import { ONBOARDING } from '../../utils/EventCategories'

function SalesOnboardingBlocked() {
  useEffect(() => {
    eventTrack('Visualizou bloqueio rotina', {
      categoria: ONBOARDING
    })
  }, [])

  const history = useHistory()

  function goToStart() {
    eventTrack('Clicou entendi bloqueio rotina', {
      categoria: ONBOARDING
    })
    history.push(BOAS_VINDAS)
  }

  return (
    <AppMainContainerOff>
      <div className="sales-onboarding-blocked-container flex flex-col">
        <div className="content">
          <p className="text-headlineMedium mb-4 font-medium text-primaryColor">
            Puxa vida, sentimos muito!
          </p>
          <p className="mb-6 text-fontDefault">
            Nesse momento todo nosso time de obstetrícia está focado
            na assistência a tentantes, gestantes e puérperas, por isso você
            não vai conseguir acessar nosso app para agendar sua consulta de rotina ginecológica.
          </p>
          <p className="mb-24 text-fontDefault">
            Agradecemos seu interesse no nosso trabalho, viu?
          </p>
        </div>
        <RegularButton
          onClick={goToStart}
          label="Entendi"
        />
      </div>
    </AppMainContainerOff>
  )
}

export default SalesOnboardingBlocked
