import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  NewListItem,
  Divider,
  ToolTip
} from 'theia-web-ds'
import {
  bgBox,
  primary,
  success,
  textSecondary
} from '../../color'
import { GuidedJourney, Quarter } from '../../domain/GuidedJourney'
import ProfilePicture from '../common/ProfilePicture'
import { eventTrack } from '../../../eventGenerate'
import { isPediatricJourney } from '../../utils/helpers'
import './GuidedJourneyComponent.scss'
import { JORNADA_EXPLICADA } from '../../routes/RoutesConstants'

interface Props {
  quarters?: GuidedJourney;
  isLoading: boolean;
  onSelectQuarter: (quarter: Quarter) => void;
}

function GuidedJourneyComponent({
  quarters,
  isLoading,
  onSelectQuarter,
}: Props) {
  const history = useHistory()
  const pediatricJourney = isPediatricJourney(quarters?.list[0]?.journeyType)

  function handleClickItem(quarter: Quarter) {
    if (!pediatricJourney) {
      eventTrack(`Clicou ${quarter.title} - jornada explicada`)
    }
    onSelectQuarter(quarter)
    history.push(JORNADA_EXPLICADA)
  }

  return (
    <div className="w-full flex flex-col mb-8">
      <div className="relative">
        <p className="text-base font-medium text-textPrimary mb-4 ml-6 lg:ml-0">
          {pediatricJourney
            ? 'Consultas para o primeiro ano do bebê'
            : 'Consultas para realizar no seu Pré-natal'}
        </p>
      </div>
      <div>
        {quarters && quarters.list.map((quarter: Quarter) => (
          <div className="relative" key={quarter.id}>
            <NewListItem
              isLoading={isLoading}
              onClick={() => handleClickItem(quarter)}
              iconColor={primary}
              title={quarter.title}
              titleSize="text-xl"
              caption={quarter.description}
              captionColor={quarter.currentQuarter ? 'text-primary' : ''}
              ariaLabel={quarter.title}
              id={quarter.id}
              isLastItem
              picture={(
                <div className="relative mx-4">
                  <ProfilePicture
                    pictureUrl={quarter.imgUrl}
                    pictureSize={40}
                    showOnlineIndicator={false}
                    className=""
                    placeholderType="empty"
                  />
                </div>
              )}
              tagComponent={(
                <div className="w-full">
                  <ToolTip
                    label={`${quarter.checkedSteps}/${quarter.steps}`}
                    // eslint-disable-next-line no-nested-ternary
                    backgroundColor={quarter.currentQuarter
                      ? primary
                      : (quarter.steps === quarter.checkedSteps)
                        ? success
                        : bgBox}
                    borderRadius="16px"
                    textColor={(quarter.currentQuarter
                      || (quarter.steps === quarter.checkedSteps))
                      ? bgBox
                      : textSecondary}
                    width="66px"
                  />
                </div>
              )}
              responsiveTag={false}
            />
            <Divider />
          </div>
        ))}
      </div>
    </div>
  )
}

export default GuidedJourneyComponent
