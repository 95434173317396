import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  NewListItem,
  RegularButton,
  Divider,
  FontIcon,
} from 'theia-web-ds'
import { primary, textSecondary } from '../../color'
import {
  capitalize,
  getScheduledText,
  isCreditCard as isCreditCardPayment,
  isPix as isPixPayment,
  isPostPregnant,
  isPregnant,
  isSlip as isSlipPayment
} from '../../utils/helpers'
import SuggestionsFinishedIcon from '../../../assets/suggestions-finished-icon.svg'
import ProfilePicture from '../common/molecules/ProfilePicture'
import { SchedulePaymentType } from '../../domain/ScheduleFlow'
import SlipOption from '../../../assets/slip-option.svg'
import PixOption from '../../../assets/pix-option.svg'
import Emoji from '../common/atoms/Emoji'
import CopyInput from '../common/molecules/inputs/CopyInput'
import { eventTrack } from '../../../eventGenerate'
import { LocalDate } from '../../domain/utils/Date'
import { ScheduleTypeBR } from '../../domain/Booking'
import { SpecialistCategories } from '../../domain/Specialist'
import { LocalAddress } from '../../utils/LocalAddress'
import { ConsultantMoment } from '../../domain/Profile'
import './ScheduleSuccess.scss'
import { INICIO, JORNADA_EXPLICADA } from '../../routes/RoutesConstants'
import { NEW_SCHEDULE_FLOW } from '../../utils/EventCategories'

interface Props {
  isPresential: boolean;
  startDate?: LocalDate;
  price?: number;
  paymentType?: SchedulePaymentType;
  isCovered: boolean;
  professionalPicture?: string;
  professionalName?: string;
  patientName?: string;
  selectedCategory?: SpecialistCategories;
  attendanceAddress?: LocalAddress;
  paymentSlipDueDate?: LocalDate;
  pixDueDate?: LocalDate;
  cardBrand?: string;
  cardLastDigits?: string;
  promotionalPrice?: number;
  healthInsuranceCompanyName?: string;
  isFree: boolean;
  paymentSlipBarCode?: string;
  paymentSlipDigitableCode?: string;
  paymentSlipPdfUrl?: string;
  pixDigitableCode?: string;
  pixQrCodeUrl?: string;
  pregnancyMoment?: ConsultantMoment;
  haveBabies: boolean;
  isExam?: boolean
  pregnancyMomentText?: string;
  usgId?: string;
  onCloseSuccessScreen: () => void;
  onScheduleUsg?: (usgId: string) => void;
}

function ScheduleSuccess({
  isPresential,
  startDate,
  price,
  paymentType,
  isCovered,
  professionalPicture,
  professionalName,
  patientName,
  selectedCategory,
  attendanceAddress,
  paymentSlipDueDate,
  pixDueDate,
  cardBrand,
  cardLastDigits,
  promotionalPrice,
  healthInsuranceCompanyName,
  isFree,
  paymentSlipBarCode,
  paymentSlipDigitableCode,
  paymentSlipPdfUrl,
  pixQrCodeUrl,
  pixDigitableCode,
  pregnancyMoment,
  haveBabies,
  isExam,
  pregnancyMomentText,
  usgId,
  onCloseSuccessScreen,
  onScheduleUsg,
}: Props) {
  const history = useHistory()
  const iconType = isPresential ? 'icon-BuildingLight' : 'icon-VideoLight'
  const weekDay = startDate && capitalize(startDate.formatDateToWeekday() || '')
  const dayTwoDigits = startDate
    && startDate.formatDayTwoDigits()
  const bookingDateInfo = startDate ? (
    `${weekDay}, ${dayTwoDigits} de ${startDate.formatFullMonth()} às ${startDate.formatDateToHours()}`
  ) : ''
  const formatedPrice = (promotionalPrice || price || 0) / 100
  const attendanceAddressToShow = attendanceAddress ? (
    `${attendanceAddress?.formatToNameNumberAndComplement()}, ${attendanceAddress?.formatToDistrictAndCity()}`
  ) : 'Clínica Theia'
  const slipDueDateFormated = paymentSlipDueDate && paymentSlipDueDate.formatDayAndMonthAndYear()
  const pixDueDateFormated = pixDueDate && pixDueDate.formatDayAndMonthAndYear()
  const pixDueHourFormated = pixDueDate && pixDueDate.formatDateToHours()
  const haveGuidedJourney = (isPregnant(pregnancyMoment)
    || (isPostPregnant(pregnancyMoment, pregnancyMomentText) && haveBabies))
  const isSlip = isSlipPayment(paymentType)
  const isPix = isPixPayment(paymentType)
  const isCreditCard = isCreditCardPayment(paymentType)

  useEffect(() => {
    eventTrack('visualizou confirmação de agendamento', {
      categoria: NEW_SCHEDULE_FLOW
    })
    return () => {
      onCloseSuccessScreen()
    }
  }, [])

  useEffect(() => {
    if (isExam && usgId) {
      eventTrack('USG - Sucesso agendamento ultrassom')
      if (onScheduleUsg) onScheduleUsg(usgId)
    }
  }, [])

  function onCopyDigitableCode() {
    if (isSlip && paymentSlipDigitableCode) {
      navigator.clipboard.writeText(paymentSlipDigitableCode)
    }
    if (isPix && pixDigitableCode) {
      navigator.clipboard.writeText(pixDigitableCode)
    }
  }

  function onDownloadSlipPdfUrl() {
    window.open(paymentSlipPdfUrl)
  }

  const pixInstructions = (isPix && pixDigitableCode) && (
    <div className="payment-instructions-container">
      <div className="header-image">
        <PixOption
          width={80}
          height={80}
          alt=""
        />
      </div>
      <h1 className="main-title">
        Pague R$ {formatedPrice} até as {pixDueHourFormated} do dia {pixDueDateFormated} para garantir {isExam ? 'o seu exame' : 'a sua consulta'}
      </h1>
      <div className="steps-container">
        <p className="instruction-title">
          Escaneie o código para pagar
        </p>
        <Divider />
        <div className="instruction-step" key="instruction-step-1">
          <span>1</span>
          <p>Acesse o seu aplicativo de pagamentos</p>
        </div>
        <div className="instruction-step" key="instruction-step-1">
          <span>2</span>
          <p>Escolha o pagamento via Pix</p>
        </div>
        <div className="instruction-step" key="instruction-step-1">
          <span>3</span>
          <p>Escaneie o código abaixo:</p>
        </div>
        <img
          src={pixQrCodeUrl}
          height="138px"
          width="139px"
          alt="pix-qr-code"
          className="pb-4 mx-auto"
        />
        <p className="instruction-title hidden md:flex">
          Ou copie o código abaixo:
        </p>
      </div>
      <div className="copy-code-container">
        <p className="instruction-title flex md:hidden">
          Copie o código abaixo:
        </p>
        <Divider />
        <div className="copy-code-box">
          <CopyInput
            value={pixDigitableCode}
            name="paymentPixDigitableCode"
          />
          <div className="flex flex-row w-full pt-4 items-center">
            <FontIcon
              iconType="icon-InfoSquareLight"
              color={textSecondary}
              fontSize="24px"
            />
            <p className="text-textSecondary text-fontExtraSmall pl-4">
              Selecione pagamento via PIX no seu app de pagamentos e cole esse código <Emoji symbol="☝️" />
            </p>
          </div>
        </div>
        <Divider className="md:hidden" />
      </div>
      <div className="buttons-container">
        <RegularButton
          onClick={onCopyDigitableCode}
          label="Copiar código"
        />
      </div>
    </div>
  )
  const slipInstructions = isSlip && (
    <div className="payment-instructions-container">
      <div className="header-image">
        <SlipOption
          width={80}
          height={80}
          alt=""
        />
      </div>
      <h1 className="main-title">
        Pague R$ {formatedPrice} até o dia {slipDueDateFormated} para garantir {isExam ? 'o seu exame' : 'a sua consulta'}
      </h1>
      <div className="steps-container">
        <p className="instruction-title">
          Escaneie o código para pagar
        </p>
        <Divider />
        <div className="instruction-step" key="instruction-step-1">
          <span>1</span>
          <p>Acesse o seu aplicativo de pagamentos</p>
        </div>
        <div className="instruction-step" key="instruction-step-1">
          <span>2</span>
          <p>Escolha o pagamento via Boleto</p>
        </div>
        <div className="instruction-step" key="instruction-step-1">
          <span>3</span>
          <p>Escaneie o código abaixo:</p>
        </div>
        <img
          src={paymentSlipBarCode}
          height={70}
          alt="código-de-barras"
          className="pb-4"
        />
        <p className="instruction-title hidden md:flex">
          Ou copie o código abaixo:
        </p>
      </div>
      <div className="copy-code-container">
        <p className="instruction-title flex md:hidden">
          Copie o código abaixo:
        </p>
        <Divider />
        <div className="copy-code-box">
          {paymentSlipDigitableCode && (
            <CopyInput
              value={paymentSlipDigitableCode}
              name="paymentSlipDigitableCode"
            />
          )}
          <div className="flex flex-row w-full pt-4 items-center">
            <FontIcon
              iconType="icon-InfoSquareLight"
              color={textSecondary}
              fontSize="24px"
            />
            <p className="text-textSecondary text-fontExtraSmall pl-4">
              Selecione pagamento via boleto no seu app de pagamentos e cole esse código <Emoji symbol="☝️" />
            </p>
          </div>
        </div>
        <Divider className="md:hidden" />
      </div>
      <div className="buttons-container">
        <RegularButton
          onClick={onCopyDigitableCode}
          label="Copiar código"
        />
        <RegularButton
          onClick={onDownloadSlipPdfUrl}
          label="Baixar boleto"
          variant="subtle"
        />
      </div>
    </div>
  )

  const finalButton = (
    <div className="final-button-container">
      {haveGuidedJourney && (
        <RegularButton label="Acompanhar a jornada" onClick={() => history.push(JORNADA_EXPLICADA)} id="finalButton" />
      )}
      {!haveGuidedJourney && (
        <RegularButton label="Ir para home" onClick={() => history.push(INICIO)} id="finalButton" />
      )}
    </div>
  )

  return (
    <div className="new-schedule-success-container">
      {(isCreditCard || isFree || isCovered) && (
        <>
          <div className="header-image">
            <SuggestionsFinishedIcon
              width={80}
              height={80}
              alt=""
            />
          </div>
          <h1 className="main-title">
            {getScheduledText(isExam)}
          </h1>
          <div className="mb-4">
            <NewListItem
              picture={(
                <ProfilePicture
                  pictureUrl={professionalPicture}
                  pictureSize={56}
                  className="professional-infos"
                  isSpecialist={!isExam}
                  isUsg={isExam}
                />
              )}
              showCaptionFirst
              caption={isExam ? selectedCategory : 'Especialista'}
              ariaLabel={isExam ? selectedCategory || 'Exame realizado na Theia' : 'Especialista'}
              title={professionalName}
              isNoHover
              isLastItem
              key="professional-infos"
              id="professional-infos"
            />
            {patientName && (
              <NewListItem
                iconType="icon-ProfileLight"
                iconSize="24px"
                iconColor={textSecondary}
                showCaptionFirst
                caption="Paciente"
                ariaLabel="Paciente"
                title={patientName}
                isNoHover
                key="patient-infos"
                id="patient-infos"
              />
            )}
            {!isExam && (
              <NewListItem
                iconType={iconType}
                iconSize="24px"
                iconColor={textSecondary}
                showCaptionFirst
                caption={`Consulta ${isPresential
                  ? ScheduleTypeBR.PRESENTIAL
                  : ScheduleTypeBR.ONLINE}`}
                ariaLabel={`Consulta ${isPresential
                  ? ScheduleTypeBR.PRESENTIAL
                  : ScheduleTypeBR.ONLINE}`}
                title={selectedCategory}
                isNoHover
                key="attendancetype-infos"
                id="attendancetype-infos"
              />
            )}
            {(isPresential) && (
              <NewListItem
                iconType="icon-LocationLight"
                iconSize="24px"
                iconColor={textSecondary}
                showCaptionFirst
                caption="Endereço"
                ariaLabel="Endereço"
                title={attendanceAddressToShow}
                isNoHover
                key="address-infos"
                id="address-infos"
              />
            )}
            <NewListItem
              iconType="icon-CalendarLight"
              iconSize="24px"
              iconColor={textSecondary}
              showCaptionFirst
              caption="Data e hora"
              ariaLabel="Data e hora"
              title={bookingDateInfo}
              isNoHover
              key="date-and-time"
              id="date-and-time"
            />
            {isCovered && (
              <NewListItem
                iconType="icon-WalletLight"
                iconSize="24px"
                iconColor={textSecondary}
                showCaptionFirst
                title={healthInsuranceCompanyName}
                ariaLabel={`Coberto pelo plano de saúde ${healthInsuranceCompanyName || ''}`}
                caption="Coberto pelo plano de saúde"
                isLastItem
                isNoHover
                key="healthplan-payment"
                id="healthplan-payment"
              />
            )}
            {isCreditCard && !isFree && !isCovered && (
              <NewListItem
                iconType="icon-WalletLight"
                iconSize="24px"
                iconColor={textSecondary}
                showCaptionFirst
                title={`${cardBrand} final ${cardLastDigits}`}
                ariaLabel={`${cardBrand} final ${cardLastDigits}`}
                caption="Pagamento"
                isLastItem
                isNoHover
                key="credit-card-payment"
                id="credit-card-payment"
              />
            )}
          </div>
          {isCreditCard && !isFree && !isCovered && (
            <div className="flex items-center w-full bg-bgBoxSecondary rounded-2xl p-4">
              <FontIcon
                iconType="icon-InfoSquareLight"
                color={primary}
                fontSize="24px"
              />
              <p className="text-textPrimary text-bodyMedium ml-2">
                O pagamento só será efetuado após a realização {isExam ? 'do exame' : 'da consulta'}.
              </p>
            </div>
          )}
        </>
      )}
      {pixInstructions}
      {slipInstructions}
      {finalButton}
    </div>
  )
}

export default ScheduleSuccess
