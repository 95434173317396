import * as uuid from 'uuid';

import { SyncError, SyncNetworkError } from '../utils/syncerror';
import log from '../utils/logger';

import { ClientInfo } from '../clientInfo';
import { Retrier } from '@twilio/operation-retrier';
import { Network } from '../interfaces/services';

import { TransportUnavailableError } from 'twilsock';

const MINIMUM_RETRY_DELAY = 4000;
const MAXIMUM_RETRY_DELAY = 60000;
const MAXIMUM_ATTEMPTS_TIME = 90000;
const RETRY_DELAY_RANDOMNESS = 0.2;

function messageFromErrorBody(transportError: any): string {
  if (transportError.body) {
    if (transportError.body.message) {
      return transportError.body.message;
    }
  }
  switch (transportError.status) {
    case 429:
      return 'Throttled by server';
    case 404:
      return 'Not found from server';
    default:
      return 'Error from server';
  }
}

function codeFromErrorBody(trasportError: any): number {
  if (trasportError.body) {
    return trasportError.body.code;
  }
  return 0;
}

function mapTransportError(transportError: any): Error {
  if (transportError.status === 409) {
    return new SyncNetworkError(messageFromErrorBody(transportError),
      transportError.status,
      codeFromErrorBody(transportError),
      transportError.body);
  } else if (transportError.status) {
    return new SyncError(messageFromErrorBody(transportError),
      transportError.status,
      codeFromErrorBody(transportError));
  } else if (transportError instanceof TransportUnavailableError) {
    return transportError;
  } else {
    return new SyncError(transportError.message, 0, 0);
  }
}

/**
 * @classdesc Incapsulates network operations to make it possible to add some optimization/caching strategies
 */
class NetworkService implements Network {
  clientInfo: ClientInfo;
  config: any;
  transport: any;

  constructor(clientInfo: ClientInfo, config: any, transport: any) {
    this.clientInfo = clientInfo;
    this.config = config;
    this.transport = transport;
  }

  private createHeaders() {
    return {
      'Content-Type': 'application/json',
      'Twilio-Sync-Client-Info': JSON.stringify(this.clientInfo),
      'Twilio-Request-Id': 'RQ' + uuid.v4().replace(/-/g, '')
    };
  }

  private backoffConfig() {
    return Object.assign({
      min: MINIMUM_RETRY_DELAY,
      max: MAXIMUM_RETRY_DELAY,
      maxAttemptsTime: MAXIMUM_ATTEMPTS_TIME,
      randomness: RETRY_DELAY_RANDOMNESS
    }, this.config.backoffConfig);
  }

  private executeWithRetry<T>(request: () => Promise<T>, retryWhenThrottled = true): Promise<T> {
    return new Promise((resolve, reject) => {
      let codesToRetryOn = [502, 503, 504];
      if (retryWhenThrottled) {
        codesToRetryOn.push(429);
      }

      let retrier = new Retrier(this.backoffConfig());
      retrier.on('attempt', () => {
        request()
          .then(result => retrier.succeeded(result))
          .catch(err => {
            if (codesToRetryOn.includes(err.status)) {
              let delayOverride = parseInt(err.headers ? err.headers['Retry-After'] : null);
              retrier.failed(mapTransportError(err),
                isNaN(delayOverride) ? null : delayOverride * 1000);
            } else if (err.message === 'Twilsock disconnected') {
              // Ugly hack. We must make a proper exceptions for twilsock
              retrier.failed(mapTransportError(err));
            } else {
              // Fatal error
              retrier.removeAllListeners();
              retrier.cancel();
              reject(mapTransportError(err));
            }
          });
      });

      retrier.on('succeeded', result => {
        resolve(result);
      });
      retrier.on('cancelled', err => reject(mapTransportError(err)));
      retrier.on('failed', err => reject(mapTransportError(err)));

      retrier.start();
    });
  }

  /**
   * Make a GET request by given URI
   * @Returns Promise<Response> Result of successful get request
   */
  get(uri: string): Promise<Response> {
    let headers = this.createHeaders();
    log.debug('GET', uri, 'ID:', headers['Twilio-Request-Id']);

    return this.executeWithRetry(() => this.transport.get(uri, headers, this.config.productId), true);
  }

  post(uri: string, body: Object, revision?: string, retryWhenThrottled: boolean = false): Promise<Response> {
    let headers = this.createHeaders();
    if (typeof revision !== 'undefined' && revision !== null) {
      headers['If-Match'] = revision;
    }

    log.debug('POST', uri, 'ID:', headers['Twilio-Request-Id']);
    return this.executeWithRetry(() => this.transport.post(uri, headers, body, this.config.productId), retryWhenThrottled);
  }

  put(uri: string, body: Object, revision: string): Promise<Response> {
    let headers = this.createHeaders();
    if (typeof revision !== 'undefined' && revision !== null) {
      headers['If-Match'] = revision;
    }

    log.debug('PUT', uri, 'ID:', headers['Twilio-Request-Id']);
    return this.executeWithRetry(() => this.transport.put(uri, headers, body, this.config.productId), false);
  }

  delete(uri: string): Promise<Response> {
    let headers = this.createHeaders();
    log.debug('DELETE', uri, 'ID:', headers['Twilio-Request-Id']);
    return this.executeWithRetry(() => this.transport.delete(uri, headers, this.config.productId), false);
  }
}

export { NetworkService };
