import React from 'react'
import ExclamationError from '../../assets/errorexclamation.svg'
import { AccessibleModal } from '../AccessibleModal/AccessibleModal'
import { BtnVariant, RegularButton } from '../RegularButton/RegularButton'
import './AccessibleErrorModal.scss'

export interface Props {
  visible: boolean;
  closeModal: () => void;
  error?: {
    title: string;
    message: string;
  };
  firstButtonText?: string;
  firstButtonAction?: () => void;
  firstButtonType?: BtnVariant;
  secondButtonText?: string;
  secondButtonAction?: () => void;
  secondButtonType?: BtnVariant;
  errorImg?: React.ReactNode;
}

export function AccessibleErrorModal({
  visible,
  closeModal,
  error,
  firstButtonText,
  firstButtonAction,
  firstButtonType,
  secondButtonText,
  secondButtonAction,
  secondButtonType,
  errorImg,
}: Props) {
  if (!visible) return null
  const screenWidth = window.innerWidth

  return (
    <AccessibleModal
      headerText={error?.message || 'Ops, aconteceu um problema'}
      onClose={closeModal}
      visible={visible}
      showCloseButton={false}
      extraOuterClassName="accessible-error-modal"
    >
      <div className="error-modal" data-testid="error-modal">
        {errorImg || (
          <ExclamationError className="self-center" />
        )}
        <h1 className="error-modal__title">
          {error?.title || 'Ops, aconteceu um problema'}
        </h1>
        <p className="error-modal__subtitle">
          {error?.message || 'Não foi possível concluir sua solicitação.'}
        </p>
        <RegularButton
          label={firstButtonText || 'Fechar'}
          variant={firstButtonType || 'primary'}
          onClick={firstButtonAction || closeModal}
          btnSize={screenWidth < 768 ? 'small' : 'large'}
          id={firstButtonText || 'Fechar'}
        />
        {secondButtonText && (
          <RegularButton
            label={secondButtonText}
            variant={secondButtonType || 'secondary-text'}
            onClick={secondButtonAction}
            extraClass="mt-2"
            btnSize={screenWidth < 768 ? 'small' : 'large'}
            id={secondButtonText}
          />
        )}
      </div>
    </AccessibleModal>
  )
}
