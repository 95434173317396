import { LogLevelDesc } from "loglevel";
import { version } from "../package.json";
import type { ClientOptionsType } from "./client";
import { InitRegistration } from "./protocol/initregistration";

export type RetryPolicyType = {
  min: number;
  max: number;
  initial?: number;
  maxAttemptsCount?: number;
  maxAttemptsTime?: number;
  randomness?: number;
};

/**
 * Settings container for the Twilsock client library
 */
class Configuration {
  private _token: string;
  private _continuationToken: string | null;
  public confirmedCapabilities: Set<string> = new Set<string>();

  public readonly retryPolicy: RetryPolicyType;
  public readonly url: string;
  public readonly activeGrant: string;
  public readonly logLevel: LogLevelDesc;
  public readonly clientMetadata: Record<string, unknown>;
  public readonly initRegistrations: InitRegistration[] | null;
  public readonly tweaks: Record<string, unknown> | null;

  /**
   * @param {String} token - authentication token
   * @param {Object} options - options to override defaults
   */
  constructor(
    token: string,
    activeGrant: string,
    options: Partial<ClientOptionsType>
  ) {
    this.activeGrant = activeGrant;
    this._token = token;

    const region = options.region || "us1";
    const defaultTwilsockUrl = `wss://tsock.${region}.twilio.com/v3/wsconnect`;

    const twilsockOptions = options.twilsock || options.Twilsock || {};
    this.url = twilsockOptions.uri || defaultTwilsockUrl;

    this._continuationToken = options.continuationToken
      ? options.continuationToken
      : null;

    this.logLevel = options.logLevel ? options.logLevel : "error";

    this.retryPolicy = options.retryPolicy
      ? options.retryPolicy
      : {
          min: 1 * 1000,
          max: 2 * 60 * 1000,
          randomness: 0.2,
        };

    this.clientMetadata = options.clientMetadata ? options.clientMetadata : {};
    this.clientMetadata.ver = version;

    this.initRegistrations = options.initRegistrations
      ? options.initRegistrations
      : null;

    this.tweaks = options.tweaks ? options.tweaks : null;
  }

  public get token(): string {
    return this._token;
  }

  public get continuationToken(): string | null {
    return this._continuationToken;
  }

  public updateToken(token: string): void {
    this._token = token;
  }

  public updateContinuationToken(continuationToken: string): void {
    this._continuationToken = continuationToken;
  }
}

export { Configuration };
