import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, FormikProps } from 'formik'
import { bindActionCreators } from 'redux'
import {
  FormInput,
  IconButton,
  RegularButton
} from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import {
  clearSignUpErrorsAction,
  clearVerifyDataStatusAction,
  loginAction,
  sendSignUpVerificationCodeAction,
  verifiedSignUpAction
} from '../../state/authentication/main/actions'
import { SignUpPayload, VerifiedSignUpPayload } from '../../domain/User'
import AppMainContainerOff from '../AppMainContainerOff'
import { GenericStatusType } from '../../domain/Status'
import { LOCALIZACAO, LOGIN_DE_ACESSO } from '../../routes/RoutesConstants'
import { textPrimary } from '../../color'
import Emoji from '../common/atoms/Emoji'
import { eventTrack, identifyUser } from '../../../eventGenerate'
import { EMAIL_VALIDATION } from '../../utils/EventCategories'
import './EmailValidation.scss'

interface SignUpFormValues {
  verificationCode: string;
}

function renderEmailValidationForm({
  values,
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  isSubmitting,
  errors,
  setSubmitting
}: FormikProps<SignUpFormValues>,
verifiedSignUpStatus: GenericStatusType,
sendEmailVerificationCodeStatus: GenericStatusType,
clearVerifyDataStatus: () => void,
sendEmailVerificationCode: (email: string) => void,
login: () => void,
clearSignUpErrors: () => void,
signUpUserData?: SignUpPayload,
consultantId?: string,
) {
  const history = useHistory()
  const [seconds, setSeconds] = useState(45)

  function startTimer() {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1
        }
        clearInterval(interval)
        return prevSeconds
      })
    }, 1000)

    return () => clearInterval(interval)
  }

  useEffect(() => {
    startTimer()
  }, [])

  useEffect(() => {
    if (!signUpUserData?.email) {
      clearVerifyDataStatus()
      history.push(LOGIN_DE_ACESSO)
    }
  }, [])

  const canSubmit = (
    !!values.verificationCode
    && !errors.verificationCode
  )

  function handleOnSubmit(e: React.FormEvent<EventTarget>) {
    e.preventDefault()
    handleSubmit()
  }

  function handleClickResendCode() {
    if (signUpUserData?.email) {
      eventTrack('clicou reenviar email', {
        category: EMAIL_VALIDATION,
        email: signUpUserData.email
      })
      sendEmailVerificationCode(signUpUserData?.email)
    }
  }

  function handleClickChangeEmail() {
    if (signUpUserData?.email) {
      eventTrack('clicou alteracao email', {
        category: EMAIL_VALIDATION,
        email: signUpUserData.email
      })
      clearVerifyDataStatus()
      history.goBack()
    }
  }

  useEffect(() => {
    if (verifiedSignUpStatus.success) {
      eventTrack('email verificado', {
        category: EMAIL_VALIDATION,
        email: signUpUserData?.email,
        source: 'cadastro'
      })
      login()
      const moment = localStorage.getItem('salesOnboardingMoment') || ''
      const infosToRudder = {
        id: consultantId,
        email: signUpUserData?.email,
        moment
      }
      identifyUser(infosToRudder)
      eventTrack('Cadastrou', {
        category: EMAIL_VALIDATION,
        userID: consultantId,
        plataforma: 'web',
        email: signUpUserData?.email
      })
      history.push(LOCALIZACAO)
    }
    if (verifiedSignUpStatus.error) {
      eventTrack('erro ao cadastrar usuario', {
        category: EMAIL_VALIDATION,
        email: signUpUserData?.email,
        plataforma: 'web',
        motivo: verifiedSignUpStatus.errorMessage?.toString()
      })
      setSubmitting(false)
    }
  }, [verifiedSignUpStatus])

  useEffect(() => {
    if (sendEmailVerificationCodeStatus.success) {
      setSeconds(45)
      startTimer()
      eventTrack('email validacao enviado', {
        category: EMAIL_VALIDATION,
        email: signUpUserData?.email
      })
    }
    if (sendEmailVerificationCodeStatus.error) {
      eventTrack('erro reenvio email', {
        category: EMAIL_VALIDATION,
        email: signUpUserData?.email,
        motivo: sendEmailVerificationCodeStatus.errorMessage?.friendlyMessage,
        plataforma: 'web'
      })
    }
  }, [sendEmailVerificationCodeStatus])

  const errorMessage = errors.verificationCode
    || verifiedSignUpStatus?.errorMessage?.friendlyMessage

  return (
    <div className="email-validation--container max-w-md">
      <div className="email-validation--body">
        <div className="email-validation--header">
          <IconButton
            variant="form-icon"
            iconColor={textPrimary}
            iconType="icon-ArrowLeft2Light"
            iconSize="24px"
            onClick={handleClickChangeEmail}
          />
        </div>
        <div className="email-validation--content">
          <h1 className="email-validation--title">
            Confirme a sua conta
          </h1>
          <p className="email-validation--subtitle">
            Informe o código de 6 dígitos que enviamos para
            &nbsp;<span className="user-email">{signUpUserData?.email}</span>&nbsp;
            - pode demorar uns minutos para chegar <Emoji symbol="😊" />
          </p>
          <form
            onSubmit={handleOnSubmit}
            noValidate
            className="email-validation--form"
            name="signUpForm"
          >
            <FormInput
              placeholder="Digite o código"
              label="Código"
              type="text"
              name="verificationCode"
              value={values.verificationCode}
              onChange={(e: any) => {
                handleChange(e)
                clearSignUpErrors()
              }}
              onBlur={handleBlur}
              errorMessage={touched.verificationCode ? errorMessage : ''}
              id="verificationCode"
            />
            <RegularButton
              type="submit"
              label="Criar login"
              disabled={isSubmitting || !canSubmit}
              isSubmitting={isSubmitting}
              maxWidth="100%"
            />
          </form>
          <div className="email-validation--footer">
            <h3 className="email-validation--text-footer">
              Ainda não recebeu o código?
            </h3>
            <RegularButton
              type="button"
              label={seconds > 0 ? `Aguarde ${seconds}s` : 'Reenviar código'}
              disabled={sendEmailVerificationCodeStatus.isLoading || seconds > 0 || isSubmitting}
              isSubmitting={sendEmailVerificationCodeStatus.isLoading}
              onClick={handleClickResendCode}
            />
            <RegularButton
              type="button"
              label="Alterar e-mail"
              disabled={isSubmitting}
              onClick={handleClickChangeEmail}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

interface Props {
  verifiedSignUpStatus: GenericStatusType;
  signUpUserData?: SignUpPayload;
  sendEmailVerificationCodeStatus: GenericStatusType;
  consultantId?: string;
  verifiedSignUp: (signUpData: VerifiedSignUpPayload) => void;
  clearVerifyDataStatus: () => void;
  sendEmailVerificationCode: (email: string) => void;
  login: () => void;
  clearSignUpErrors: () => void;
}

const initialValues: SignUpFormValues = {
  verificationCode: '',
}

const validationSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .required('Código obrigatório')
})

function EmailValidation({
  verifiedSignUpStatus,
  signUpUserData,
  sendEmailVerificationCodeStatus,
  consultantId,
  verifiedSignUp,
  clearVerifyDataStatus,
  sendEmailVerificationCode,
  login,
  clearSignUpErrors,
}: Props) {
  const onSubmit = (values: SignUpFormValues) => {
    if (signUpUserData) {
      verifiedSignUp({
        email: signUpUserData.email,
        name: signUpUserData.name,
        password: signUpUserData.password,
        phone: signUpUserData.phone,
        pregnancyDate: signUpUserData.pregnancyDate,
        pregnancyMoment: signUpUserData.pregnancyMoment,
        termIds: signUpUserData.termIds,
        verificationCode: values.verificationCode
      })
    }
  }

  return (
    <AppMainContainerOff>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => renderEmailValidationForm(
          props,
          verifiedSignUpStatus,
          sendEmailVerificationCodeStatus,
          clearVerifyDataStatus,
          sendEmailVerificationCode,
          login,
          clearSignUpErrors,
          signUpUserData,
          consultantId,
        )}
      </Formik>
    </AppMainContainerOff>
  )
}

const mapStateToProps = ({ authentication }: AppState) => ({
  verifiedSignUpStatus: authentication.verifiedSignUpStatus,
  signUpUserData: authentication?.signUpUserData,
  sendEmailVerificationCodeStatus: authentication.sendVerificationCodeStatus,
  isAuthenticated: authentication.authenticated,
  currentUser: authentication.currentUser,
  consultantId: authentication.consultantId
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  verifiedSignUp: verifiedSignUpAction,
  clearVerifyDataStatus: clearVerifyDataStatusAction,
  sendEmailVerificationCode: sendSignUpVerificationCodeAction,
  login: loginAction,
  clearSignUpErrors: clearSignUpErrorsAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailValidation)
