import React from 'react'
import { RegularButton } from 'theia-web-ds'
import ExclamationError from '../../../assets/exclamation-error.svg'
import Modal from '../common/Modal'
import HeaderModal from '../common/HeaderModal'
import './PaymentErrorModal.scss'

interface Props {
  visible?: boolean;
  onClose: () => void;
}

function PaymentErrorModal({ visible = false, onClose }: Props) {
  if (!visible) return null

  return (
    <Modal>
      <div className="schedule-payment-error">
        <ExclamationError className="self-center" />
        <HeaderModal className="schedule-payment-error-title">
          Não foi possível validar seu cartão
        </HeaderModal>
        <p className="schedule-payment-error-subtitle">
          Por favor, verifique se os dados estão corretos e tente novamente
        </p>
        <RegularButton height="36px" labelSize="14px" onClick={onClose} label="Entendi" />
      </div>
    </Modal>
  )
}

export default PaymentErrorModal
