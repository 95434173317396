import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import { AccessibleModal, RegularButton } from 'theia-web-ds'
import './UnauthenticatedModal.scss'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { suppressUnauthenticatedModalAction } from '../../state/authentication/main/actions'
import { UnauthenticatedModalError } from '../../domain/User'
import Logout from '../../../assets/Logout.png'
import Wifi from '../../../assets/Wifi.png'
import { LOGIN } from '../../routes/RoutesConstants'

interface Props {
  errorType?: UnauthenticatedModalError;
  suppressUnauthenticatedModal: () => void;
}

function UnauthenticatedModal({
  errorType,
  suppressUnauthenticatedModal
}: Props) {
  const history = useHistory()

  function goToLogin() {
    suppressUnauthenticatedModal()
    history.push(LOGIN)
  }

  function renderErrorMessage() {
    if (errorType === UnauthenticatedModalError.INACTIVITY) {
      return 'inatividade'
    }
    if (errorType === UnauthenticatedModalError.GENERAL_ERROR) {
      return 'alguma falha de conexão'
    }
    return null
  }

  return (
    <AccessibleModal
      showCloseButton
      headerText=""
      visible={errorType !== undefined}
      onClose={suppressUnauthenticatedModal}
      extraModalContainerClass="modal-unauthenticated"
      lastIdFocused=""
    >
      <div className="w-full text-center">
        <img
          src={errorType === UnauthenticatedModalError.INACTIVITY ? Logout : Wifi}
          alt="logout"
          width={56}
          className="mx-auto mb-3"
        />
        <p className="font-medium text-titleMedium">
          Sessão finalizada
        </p>
        <p className="text-bodyMedium text-textSecondary mb-3">
          Você foi desconectada(o) por {renderErrorMessage()}. Por favor, faça seu login novamente.
        </p>
        <RegularButton
          onClick={goToLogin}
          label="Fazer login"
          btnSize="small"
        />
      </div>
    </AccessibleModal>
  )
}

const mapStateToProps = ({
  authentication
}: AppState) => ({
  errorType: authentication?.unauthenticatedModalError
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  suppressUnauthenticatedModal: suppressUnauthenticatedModalAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnauthenticatedModal)
