import React from 'react'
import { error } from '../../colors'
import './BadgeNotification.scss'

export interface Props {
  color?: string;
  width?: number;
  height?: number;
  borderRadius?: string;
  extraClass?: string;
  children?: React.ReactNode;
}

export function BadgeNotification({
  color = error,
  width = 8,
  height = 8,
  borderRadius = '50%',
  extraClass,
  children
}: Props) {
  return children ? (
    <div
      className={`badge-notification
        ${extraClass}
      `}
      style={{
        backgroundColor: color,
        width,
        height,
        borderRadius
      }}
      data-testid="badge-notification-with-children"
    >
      {children}
    </div>
  ) : (
    <span
      className={`badge-notification
        ${extraClass}
      `}
      style={{
        backgroundColor: color,
        width,
        height,
        borderRadius
      }}
      data-testid="badge-notification-no-children"
    />
  )
}
