import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  RegularButton,
  ListItemCard,
  AccessibleModal,
  ButtonOptions,
  Divider
} from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import HealthPlanAccepted from '../../../../assets/OvalPlanCard-Yes.svg'
import HealthPlanNotAccepted from '../../../../assets/Plan-no.svg'
import HealthPlanAlert from '../../../../assets/PlanCard-Alert.svg'
import HealthPlanAdd from '../../../../assets/PlanCard-Add.svg'
import { User } from '../../../domain/User'

import './HealthPlanProfile.scss'
import { goBackToProfileAfterHealthPlanUpdateAction, postUserHealthInsurance } from '../../../state/healthplanProfile/actions'
import { AppDispatch } from '../../../state/utils'
import { updateCurrentUserInfosAction } from '../../../state/authentication/main/actions'
import { AppState } from '../../../apps/main/store'
import { fetchAllProfessionalsAction } from '../../../state/professionals/actions'
import { error, textSecondary } from '../../../color'
import { eventTrack } from '../../../../eventGenerate'
import { ConsultantMoment } from '../../../domain/Profile'
import {
  listOfPlansAcceptedByTheia,
  isOtherMoment,
  isPostPregnant,
  isPregnant,
  isTrying
} from '../../../utils/helpers'
import { PostUserHealthPlanType } from '../../../domain/Healthplan'
import { CHANGE_HEALTH_PLAN, HEALTH_PLAN_INCLUDED, PROFILE } from '../../../routes/RoutesConstants'

interface Props {
  isFetchingProfile: boolean;
  currentUser: User;
  onPostHealthInsurance: (healthPlanData: PostUserHealthPlanType) => void;
  onUpdateCurrentUser: () => void;
  onGoBackToProfileHealthPlanUpdate: () => void;
  isSuccessDeleted: boolean;
  fetchProfessionals: () => void;
  isLoading: boolean;
  pregnancyMoment?: ConsultantMoment;
}

function HealthPlanProfileCard({
  isFetchingProfile,
  currentUser,
  onPostHealthInsurance,
  onUpdateCurrentUser,
  onGoBackToProfileHealthPlanUpdate,
  isSuccessDeleted,
  fetchProfessionals,
  isLoading,
  pregnancyMoment
}: Props) {
  const history = useHistory()
  const [healthPlanTitleMessage, setHealthPlanTitleMessage] = useState('')
  const [healthPlanSubtitleMessage, setHealthPlanSubtitleTitleMessage] = useState('')
  const [healthPlanImage, setHealthPlanImage] = useState(<HealthPlanAccepted />)
  const healthPlanData = currentUser.healthInsurancePlanData
  const isInsuranceCovered = currentUser.healthInsurancePlanData.insurancePlanCovered
  const [alreadyHasHealthPlan, setAlreadyHasHealthPlan] = useState(false)
  const [isButtonGroupVisible, setIsButtonGroupVisible] = useState(false)
  const [deleteAlertCard, setDeleteAlertCard] = useState(false)
  const includedCategories = healthPlanData.benefits.map((item: any) => item.categoryName)
    .filter((cn: any) => cn !== null).length
  const healthPlanName = healthPlanData.healthInsurancePlan?.planName !== null ? `${healthPlanData.healthInsurancePlan?.company} ${healthPlanData.healthInsurancePlan?.planName}` : `${healthPlanData.healthInsurancePlan?.company}`
  const healthPlanNameCompany = `${healthPlanData.healthInsurancePlan?.company}`

  useEffect(() => {
    if (isSuccessDeleted) {
      setDeleteAlertCard(false)
      onUpdateCurrentUser()
      fetchProfessionals()
      history.push(PROFILE, { isFromDelete: true })
      onGoBackToProfileHealthPlanUpdate()
    }
  }, [isSuccessDeleted])

  useEffect(() => {
    switch (healthPlanData?.hasHealthInsurancePlan) {
      case false:
        setHealthPlanTitleMessage('Nenhum plano adicionado')
        setHealthPlanImage(<HealthPlanAccepted />)
        setAlreadyHasHealthPlan(false)
        localStorage.setItem('healthPlanAdded', 'false')
        break
      case null:
        setHealthPlanTitleMessage('Nenhum plano adicionado')
        setHealthPlanImage(<HealthPlanAdd />)
        setAlreadyHasHealthPlan(false)
        localStorage.setItem('healthPlanAdded', 'false')
        break
      case true:
        setHealthPlanTitleMessage(healthPlanName)
        setAlreadyHasHealthPlan(true)
        localStorage.removeItem('healthPlanAdded')
        break
      default:
        return setHealthPlanTitleMessage('')
    }
    return undefined
  }, [healthPlanData])

  useEffect(() => {
    if (isInsuranceCovered === false && healthPlanData?.hasHealthInsurancePlan === false) {
      setHealthPlanSubtitleTitleMessage('')
      setHealthPlanImage(<HealthPlanAdd />)
    }
    if (isInsuranceCovered === false && healthPlanData?.hasHealthInsurancePlan) {
      setHealthPlanSubtitleTitleMessage('Ainda não aceitamos esse plano')
      setHealthPlanImage(<HealthPlanNotAccepted />)
    }
    if (healthPlanData.healthInsurancePlan?.company
      && listOfPlansAcceptedByTheia.includes(healthPlanData.healthInsurancePlan?.company)
      && healthPlanData.healthInsurancePlan?.planName === ''
    ) {
      setHealthPlanSubtitleTitleMessage('Atualize os dados do seu convênio')
      setHealthPlanImage(<HealthPlanAlert />)
    }
    if (isInsuranceCovered) {
      if (listOfPlansAcceptedByTheia.includes(healthPlanNameCompany)) {
        if (isPregnant(pregnancyMoment)) {
          setHealthPlanSubtitleTitleMessage('Jornada de Pré-Natal inclusa')
        }
        if (isTrying(pregnancyMoment)) {
          setHealthPlanSubtitleTitleMessage('Consulta pré-concepcional inclusa')
        }
        if (isOtherMoment(pregnancyMoment) || isPostPregnant(pregnancyMoment)) {
          setHealthPlanSubtitleTitleMessage('1 especialidade inclusa')
        }
      } else if (includedCategories === 1) {
        setHealthPlanSubtitleTitleMessage(`${includedCategories} especialidade incluída`)
        setHealthPlanImage(<HealthPlanAccepted />)
      } else if (includedCategories > 1) {
        setHealthPlanSubtitleTitleMessage(`${includedCategories} especialidades disponíveis`)
        setHealthPlanImage(<HealthPlanAccepted />)
      } else if (includedCategories === 0) {
        setHealthPlanSubtitleTitleMessage('')
        setHealthPlanImage(<HealthPlanAccepted />)
      }
    }
  }, [isInsuranceCovered, healthPlanData?.hasHealthInsurancePlan])

  function goToLocationUsingHistoryPush(link: string, state?: unknown) {
    eventTrack('Clicou Incluso no Plano')
    history.push(link, state)
  }

  const openButtonGroup = () => {
    eventTrack('Clicou Editar Plano')
    setIsButtonGroupVisible(true)
  }

  const closeButtonGroup = () => {
    setIsButtonGroupVisible(false)
  }

  const onDeleteHealthPlanBtnGroup = () => {
    setIsButtonGroupVisible(false)
    setDeleteAlertCard(true)
  }

  const suppressModal = () => {
    setDeleteAlertCard(false)
  }

  const deleteHealthPlan = () => {
    onPostHealthInsurance({
      hasHealthInsurancePlan: false,
      healthInsurancePlan: {
        company: null,
        planName: null,
        memberId: null,
        companyId: null,
        planId: null,
      }
    })
  }

  const deleteCard = (
    <AccessibleModal
      headerText=""
      visible={deleteAlertCard}
      showCloseButton={false}
      onClose={suppressModal}
      extraModalContainerClass="delete-plan-modal"
    >
      <p className="m-4 text-fontDefault text-textPrimary text-center">Tem certeza que deseja excluir seu plano {healthPlanName}?</p>
      <Divider />
      <div className="flex">
        <RegularButton label="Agora não" variant="text" extraClass="btn-from-list-card btn-left" onClick={suppressModal} />
        <span className="button-bottom" />
        <RegularButton label="Excluir" variant="text" extraClass="btn-from-list-card btn-right btn-delete-plan" onClick={deleteHealthPlan} isSubmitting={isLoading} />
      </div>
    </AccessibleModal>
  )
  const buttonBarInsurancePlanCovered = (
    <>
      <ButtonOptions
        buttonToAnchor={(
          <RegularButton label="Editar plano" variant="text" extraClass="btn-change-plan btn-from-list-card btn-left" onClick={openButtonGroup} />
        )}
        visible={isButtonGroupVisible}
        onClose={closeButtonGroup}
        headerText="Opções de editar plano"
        extraClass="new-anchor-group"
      >
        <ListItemCard
          title="Alterar plano de saúde"
          ariaLabel="Alterar plano de saúde"
          onClick={() => goToLocationUsingHistoryPush(CHANGE_HEALTH_PLAN, [alreadyHasHealthPlan])}
          iconType="icon-EditLight"
          iconColor={textSecondary}
          extraClassNameContainer="item-from-btn-group"
          extraClassClickableContainer="first-item-border-radius"
        />
        <ListItemCard
          title="Deletar plano atual"
          ariaLabel="Deletar plano atual"
          onClick={onDeleteHealthPlanBtnGroup}
          iconType="icon-TrashLight"
          iconColor={error}
          arrowColor={textSecondary}
          extraClassNameContainer="item-from-btn-group"
          extraClassClickableContainer="last-item-border-radius"
          isLastItem
        />
      </ButtonOptions>
      <span className="button-bottom" />
      <RegularButton label="Incluso no plano" variant="text" onClick={() => goToLocationUsingHistoryPush(HEALTH_PLAN_INCLUDED)} extraClass="btn-from-list-card btn-right" />
    </>
  )

  const buttonBarInsurancePlanNotCovered = (
    <ButtonOptions
      buttonToAnchor={(
        <RegularButton label="Editar plano" variant="text" extraClass="btn-change-plan btn-from-list-card single-btn" onClick={openButtonGroup} />
        )}
      visible={isButtonGroupVisible}
      onClose={closeButtonGroup}
      headerText="Opções de editar plano"
      extraClass="new-anchor-group"
    >
      <ListItemCard
        title="Alterar plano de saúde"
        ariaLabel="Alterar plano de saúde"
        onClick={() => goToLocationUsingHistoryPush(CHANGE_HEALTH_PLAN, [alreadyHasHealthPlan])}
        iconType="icon-EditLight"
        iconColor={textSecondary}
        extraClassNameContainer="item-from-btn-group"
        extraClassClickableContainer="first-item-border-radius"
      />
      <ListItemCard
        title="Deletar plano atual"
        ariaLabel="Deletar plano atual"
        onClick={onDeleteHealthPlanBtnGroup}
        iconType="icon-TrashLight"
        iconColor={error}
        arrowColor={textSecondary}
        extraClassNameContainer="item-from-btn-group"
        extraClassClickableContainer="last-item-border-radius"
        isLastItem
      />
    </ButtonOptions>
  )

  const buttonBarNoHealthPlanAdded = (
    <RegularButton
      label="Adicionar plano de saúde"
      variant="text"
      onClick={() => goToLocationUsingHistoryPush(CHANGE_HEALTH_PLAN, [alreadyHasHealthPlan])}
      extraClass="btn-from-list-card single-btn"
      isLoadingScreen={isFetchingProfile}
    />
  )

  return (
    <>
      {deleteAlertCard && (
        deleteCard
      )}
      <ListItemCard
        extraClassNameContainer="profile-health-card list-item-card-only-btn"
        extraClassClickableContainer="list-item-container-only-btn"
        title={healthPlanTitleMessage}
        caption={healthPlanSubtitleMessage}
        ariaLabel={healthPlanName}
        isLastItem
        isLoading={isFetchingProfile}
        isItemFromList={false}
        picture={<span className="profile-icon">{healthPlanImage}</span>}
      >
        <Divider />
        <div className="flex">
          {isInsuranceCovered && (
            buttonBarInsurancePlanCovered
          )}
          {!isInsuranceCovered && isInsuranceCovered !== null && alreadyHasHealthPlan && (
            buttonBarInsurancePlanNotCovered
          )}
          {isInsuranceCovered === null
          || (!isInsuranceCovered && isInsuranceCovered !== null && alreadyHasHealthPlan === false)
            ? (
              buttonBarNoHealthPlanAdded
            ) : ''}
        </div>
      </ListItemCard>
    </>
  )
}

const mapStateToProps = ({
  healthplan, authentication
}: AppState) => ({
  isSuccess: healthplan.isSuccess,
  isSuccessDeleted: healthplan.isSuccessDeleted,
  isLoading: healthplan.isLoading,
  pregnancyMoment: authentication.currentUser?.pregnancyMoment,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onPostHealthInsurance: postUserHealthInsurance,
  onUpdateCurrentUser: updateCurrentUserInfosAction,
  onGoBackToProfileHealthPlanUpdate: goBackToProfileAfterHealthPlanUpdateAction,
  fetchProfessionals: fetchAllProfessionalsAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthPlanProfileCard)
