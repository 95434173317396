/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  RegularButton,
  ChoiceChipButton,
  Divider,
  FontIcon,
  IconButton,
  NewChipButton,
  Loader,
  ToolTip
} from 'theia-web-ds'
import { useHistory } from 'react-router-dom'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { getUsgAvailabilityAction, getUsgDataAction } from '../../state/prescriptions/actions'
import { AvailabilitySlot, ProfessionalListType } from '../../domain/Professional'
import { UsgAvailabilityParams } from '../../domain/Prescriptions'
import UltrassomSvg from '../../../assets/ultrassom.svg'
import {
  bgBox,
  primary,
  success,
  textPrimary
} from '../../color'
import './ChooseUsgDate.scss'
import {
  selectAttendanceTypeAction,
  selectCategoryToScheduleAction,
  selectChildForScheduleAction,
  selectSpecialistAction,
  selectTimeSlotAction,
  setLoadingStepAction,
  setPatientTypeAction,
} from '../../state/scheduleFlow/actions'
import {
  AttendanceAvailabilityType,
  CategoryToSchedule,
  PatientType,
} from '../../domain/ScheduleFlow'
import { LocalAddress } from '../../utils/LocalAddress'
import { NOVO_AGENDAMENTO } from '../../routes/RoutesConstants'
import { AvailabilityTimeSlot, SlotSpecialist } from '../../domain/Availabilities'
import { setAttendanceAddressAction, setProductIdAction } from '../../state/availabilities/actions'

interface Props {
  usgData?: ProfessionalListType;
  isGettingData: boolean;
  usgAvailability?: Array<AvailabilitySlot>;
  isGettingUsgAvailability: boolean;
  usgId?: string;
  getUsgData: (chronosId: string) => void;
  getUsgAvailability: (chronosId: string, params: UsgAvailabilityParams) => void;
  setPatientType: (patientType?: PatientType) => void;
  selectPatientForSchedule: () => void;
  selectAttendanceType: (attendanceType?: AttendanceAvailabilityType) => void;
  selectCategoryToSchedule: (category?: CategoryToSchedule) => void;
  selectTimeSlot: (selectedTimeSlot?: AvailabilityTimeSlot) =>void;
  selectSpecialist: (selectedSpecialist?: SlotSpecialist) => void;
  setLoadingStep: (loadingStep: boolean) => void;
  setAttendanceAddress: (address?: LocalAddress) => void;
  setProductId: (productId?: string) => void;
}

function ChooseUsgDate({
  usgData,
  isGettingData,
  usgAvailability,
  isGettingUsgAvailability,
  usgId,
  getUsgData,
  getUsgAvailability,
  setPatientType,
  selectPatientForSchedule,
  selectAttendanceType,
  selectCategoryToSchedule,
  selectTimeSlot,
  selectSpecialist,
  setLoadingStep,
  setAttendanceAddress,
  setProductId,
}: Props) {
  const history = useHistory()
  const [activeMonthIndex, setActiveMonthIndex] = useState(0)
  const [activeDayIndex, setActiveDayIndex] = useState(0)
  const [activeTimeIndex, setActiveTimeIndex] = useState(0)
  const [monthsAndYear, setMonthsAndYear] = useState([])
  const [daysByMonth, setDaysByMonth] = useState([]) as any
  const [availabilitiesByDays, setAvailabilitiesByDays] = useState([]) as any
  const USG_CHRONOS_ID = process.env.USG_CHRONOS_ID || ''
  const buttonDisabled = activeMonthIndex < 0 || activeDayIndex < 0 || activeTimeIndex < 0

  useEffect(() => {
    if (USG_CHRONOS_ID) {
      if (!isGettingData) {
        getUsgData(USG_CHRONOS_ID)
      }
      if (!isGettingUsgAvailability && usgId) {
        getUsgAvailability(USG_CHRONOS_ID, { examId: usgId })
      }
    }
  }, [])

  useMemo(() => {
    const monthsAndYears: any = []
    const byMonth: any = []
    const byDays: any = []
    if (!isGettingUsgAvailability && usgAvailability) {
      usgAvailability?.map((av: any) => {
        const monthAndYear: string = av.startDate.formatMonthAndYear()
        const day: string = av.startDate.formatDateToToWeekDay()
        const monthAndYearIndex = monthsAndYears.indexOf(monthAndYear)
        if (monthAndYearIndex === -1) {
          monthsAndYears.push(monthAndYear)
          byMonth.push([day])
          byDays.push([[av]])
        } else {
          const daysByMonthList = byMonth[monthAndYearIndex]
          const daysByMonthIndex = daysByMonthList.indexOf(day)
          if (daysByMonthIndex === -1) {
            byMonth[monthAndYearIndex].push(day)
            byDays[monthAndYearIndex].push([av])
          } else {
            byDays[monthAndYearIndex][daysByMonthIndex].push(av)
          }
        }
        setMonthsAndYear(monthsAndYears)
        setDaysByMonth(byMonth)
        setAvailabilitiesByDays(byDays)
      })
    }
  }, [usgAvailability])

  const changeMonth = (type: string) => {
    if (type === 'prev') {
      setActiveMonthIndex(activeMonthIndex - 1)
    } else {
      setActiveMonthIndex(activeMonthIndex + 1)
    }
    setActiveDayIndex(0)
    setActiveTimeIndex(0)
  }

  function handleConfirmAvailability() {
    const selectedAv: AvailabilitySlot = availabilitiesByDays[activeMonthIndex][activeDayIndex][activeTimeIndex]
    if (selectedAv && usgData) {
      setPatientType(PatientType.IS_USER)
      selectPatientForSchedule()
      selectAttendanceType(AttendanceAvailabilityType.PRESENTIAL)
      selectCategoryToSchedule({
        attendanceAvailability: AttendanceAvailabilityType.PRESENTIAL,
        category: usgData.category,
        categoryId: usgData.id,
        description: '',
        included: usgData.healthInsuranceDiscount,
        price: usgData.price,
      })
      selectTimeSlot({
        startTime: selectedAv.startDate,
        specialists: [{
          chronosId: usgData.chronosId,
          id: usgData.id,
          name: usgData.name,
          pictureUrl: usgData.pictureUrl,
          slotId: selectedAv.id
        }]
      })
      selectSpecialist({
        chronosId: usgData.chronosId,
        id: usgData.id,
        name: usgData.name,
        pictureUrl: usgData.pictureUrl,
        slotId: selectedAv.id
      })
      setAttendanceAddress(selectedAv.attendanceAddress)
      setProductId(selectedAv.productId)
      setLoadingStep(true)
      history.push(NOVO_AGENDAMENTO)
    }
  }

  useEffect(() => {
    const scrollContainer: any = document.querySelector('.usg-days-slider')
    let isDown = false
    let startX: any
    let scrollLeft: any
    scrollContainer?.addEventListener('wheel', (evt: any) => {
      evt.preventDefault()
      scrollContainer.scrollLeft += evt.deltaY
    })
    scrollContainer?.addEventListener('mousedown', (e: any) => {
      isDown = true
      scrollContainer.classList.add('active')
      startX = e.pageX - scrollContainer.offsetLeft
      scrollLeft = scrollContainer.scrollLeft
    })
    scrollContainer?.addEventListener('mouseleave', () => {
      isDown = false
      scrollContainer.classList.remove('active')
    })
    scrollContainer?.addEventListener('mouseenter', () => {
      scrollContainer.classList.remove('active')
    })
    scrollContainer?.addEventListener('mouseup', () => {
      isDown = false
      scrollContainer.classList.remove('active')
    })
    scrollContainer?.addEventListener('mousemove', (e: any) => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - scrollContainer.offsetLeft
      const walk = (x - startX) * 3
      scrollContainer.scrollLeft = scrollLeft - walk
    })
  }, [activeMonthIndex, daysByMonth])

  function scrollDays(evt: any, direction: string) {
    evt.preventDefault()
    const scrollContainer: any = document.querySelector('.usg-days-slider')
    if (direction === 'left') {
      scrollContainer.scrollLeft -= 60
    } else {
      scrollContainer.scrollLeft += 60
    }
  }

  return (
    <div className="choose-usg-date flex flex-col w-full h-screen overflow-auto">
      <div className="text-textPrimary flex items-center justify-start p-4 text-xl font-normal">
        <IconButton
          aria-label="Voltar"
          variant="text"
          width="44px"
          height="44px"
          iconType="icon-ArrowLeft2Light"
          iconColor={textPrimary}
          iconSize="20px"
          onClick={() => history.goBack()}
        />
        <p>Voltar</p>
      </div>
      <Divider />
      <div className="w-full flex-1 pb-20 md:mt-12">
        <div className="bg-white w-full h-full p-4 md:w-1/2 md:mx-auto md:h-auto md: md:rounded-2xl lg:max-w-3xl">
          {isGettingData ? (
            <div className="w-full h-full flex flex-1 flex-grow items-center justify-center md:my-32">
              <Loader size="40px" />
            </div>
          ) : usgData && (
            <div className="w-full h-full flex-grow">
              <div className="flex">
                <UltrassomSvg className="mr-4 h-10 w-10" />
                <div className="flex flex-col md:flex-row md:justify-between md:w-full">
                  <div className="flex flex-col">
                    <h1 className="text-textPrimary text-base font-medium mb-1">
                      {usgData.name}
                    </h1>
                    <p className="mb-3 text-textSecondary text-sm font-normal">
                      {usgData.category}
                    </p>
                  </div>
                  <div className="w-max">
                    <ToolTip
                      label={
                        (!usgData?.shouldBeCharged || usgData?.healthInsuranceDiscount)
                          ? 'Incluso'
                          : `R$ ${(usgData?.price || 0) / 100}`
                      }
                      backgroundColor={
                        (!usgData?.shouldBeCharged || usgData?.healthInsuranceDiscount)
                          ? success
                          : primary
                      }
                      borderRadius="16px"
                      textColor={bgBox}
                    />
                  </div>
                </div>
              </div>
              {isGettingUsgAvailability ? (
                <div className="w-full h-full flex flex-1 flex-grow items-center justify-center md:my-32">
                  <Loader size="40px" />
                </div>
              ) : (
                <>
                  {monthsAndYear.length > 0 && monthsAndYear.map((monthYear, indexMonth) => (
                    indexMonth === activeMonthIndex && (
                      <div className="w-full flex-grow md:px-12" key={indexMonth}>
                        <div key={monthYear} className="w-full h-full flex-grow mt-4 flex flex-col justify-between">
                          <div className="w-full flex justify-between items-center mb-4">
                            <IconButton
                              aria-label="Mover para esquerda"
                              variant="subtle"
                              width="44px"
                              height="44px"
                              iconType="icon-ArrowLeft2Light"
                              onClick={() => {
                                changeMonth('prev')
                              }}
                              isDisabled={activeMonthIndex === 0}
                            />
                            <p className="font-medium text-textPrimary text-default capitalize">
                              {monthYear}
                            </p>
                            <IconButton
                              aria-label="Mover para direita"
                              variant="subtle"
                              width="44px"
                              height="44px"
                              iconType="icon-ArrowRight2Light"
                              onClick={() => {
                                changeMonth('next')
                              }}
                              isDisabled={(
                                monthsAndYear?.length - 1) === activeMonthIndex}
                            />
                          </div>
                          <div className="flex flex-col mb-4 relative w-full">
                            <p className="text-default text-textPrimary font-medium">
                              Dias disponíveis
                            </p>
                            <div className="w-full flex justify-between items-center relative">
                              <div
                                id={`availabilities-slider-${indexMonth}`}
                                className={`usg-days-slider py-4 ${daysByMonth[indexMonth].length > 5 ? 'px-3' : 'px-0'}`}
                              >
                                {daysByMonth[indexMonth].map((day: any, indexDay: number) => (
                                  <div key={day} id={`day-${indexDay}`}>
                                    <ChoiceChipButton
                                      key={`card-${day}`}
                                      firstLine={day}
                                      isSelected={indexDay === activeDayIndex}
                                      onClick={() => {
                                        setActiveDayIndex(indexDay)
                                        setActiveTimeIndex(-1)
                                      }}
                                      isSmaller
                                    />
                                  </div>
                                ))}
                              </div>
                              {daysByMonth[indexMonth].length > 5 && (
                                <>
                                  <button
                                    type="button"
                                    className="usg-days-left-slider-button"
                                    onClick={(e) => scrollDays(e, 'left')}
                                  >
                                    <FontIcon
                                      iconType="icon-ArrowLeft2Light"
                                      fontSize="25px"
                                      color={primary}
                                    />
                                  </button>
                                  <button
                                    type="button"
                                    className="usg-days-right-slider-button"
                                    onClick={(e) => scrollDays(e, 'right')}
                                  >
                                    <FontIcon
                                      iconType="icon-ArrowRight2Light"
                                      fontSize="25px"
                                      color={primary}
                                    />
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col mb-4">
                            <p className="text-default text-textPrimary font-medium">
                              Horários disponíveis
                            </p>
                            <div id={`availabilities-time-slider-${indexMonth}`} className="usg-times-grid">
                              {availabilitiesByDays[activeMonthIndex][activeDayIndex].map(
                                (av: AvailabilitySlot, indexTime: number) => (
                                  <div key={av.id} id={`time-${indexTime}`}>
                                    <NewChipButton
                                      key={av.id}
                                      label={av.startDate.formatDateToHours()}
                                      isSelected={indexTime === activeTimeIndex}
                                      onClick={() => {
                                        setActiveTimeIndex(indexTime)
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <RegularButton
                            id="usg-schedule-btn"
                            label="Confirmar agendamento"
                            disabled={buttonDisabled}
                            extraClass="mb-4"
                            onClick={handleConfirmAvailability}
                          />
                        </div>
                      </div>
                    )
                  ))}
                  {(usgAvailability?.length === 0 || monthsAndYear.length === 0) && (
                    <div className="usg-schedule-message__have-no-timeslot mt-8 md:mx-10 md:mb-5">
                      <div className="p-4 text-center">
                        <p className="text-fontExtraSmall ">
                          Todos os horários já foram preenchidos.
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <Divider className="md:hidden" />
      </div>
    </div>
  )
}

const mapStateToProps = ({
  prescriptions
}: AppState) => ({
  isGettingData: prescriptions.isGettingUsgData,
  usgData: prescriptions.usgData,
  isGettingUsgAvailability: prescriptions.isGettingUsgAvailability,
  usgAvailability: prescriptions.usgAvailability,
  usgId: prescriptions.usgPrescription?.usgId,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  getUsgAvailability: getUsgAvailabilityAction,
  getUsgData: getUsgDataAction,
  setPatientType: setPatientTypeAction,
  selectPatientForSchedule: selectChildForScheduleAction,
  selectAttendanceType: selectAttendanceTypeAction,
  selectCategoryToSchedule: selectCategoryToScheduleAction,
  selectTimeSlot: selectTimeSlotAction,
  selectSpecialist: selectSpecialistAction,
  setLoadingStep: setLoadingStepAction,
  setAttendanceAddress: setAttendanceAddressAction,
  setProductId: setProductIdAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseUsgDate)
