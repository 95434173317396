/* eslint-disable no-param-reassign */
import React, {
  useEffect, useRef, useState
} from 'react'
import ReactCrop, {
  centerCrop, Crop, makeAspectCrop, PixelCrop
} from 'react-image-crop'
import 'react-image-crop/src/ReactCrop.scss'
import imageCompression from 'browser-image-compression'
import { ListItemCard, RegularButton, AccessibleModal } from 'theia-web-ds'
import './EditImageWithCrop.scss'

  interface Props {
    onUpload: (file: File, id?: string, index?: number) => void;
    idToUpload?: string;
    indexToUpload?: number;
    onClick?: () => void;
    id?: string;
    className?: string;
    buttonLabel: string;
    isFromModal?: boolean;
    hideButtonModal?: () => void;
    closeButtonModal?: () => void;
    variant?: 'primary' | 'secondary' | 'subtle' | 'text' | 'secondary-text' | 'modal-btn';
    arrowColor?: string;
    iconColor?: string;
  }

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  deps?: any,
) {
  useEffect(() => {
    const t = setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      fn.apply(undefined, deps)
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  }, deps)
}

const TO_RADIANS = Math.PI / 180

async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0,
) {
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const pixelRatio = window.devicePixelRatio
  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'low'

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  const rotateRads = rotate * TO_RADIANS
  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2

  ctx.save()

  ctx.translate(-cropX, -cropY)
  ctx.translate(centerX, centerY)
  ctx.rotate(rotateRads)
  ctx.scale(scale, scale)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  )

  ctx.restore()
}

export default function EditImageWithCrop({
  onUpload,
  idToUpload,
  indexToUpload,
  onClick,
  id,
  className,
  buttonLabel,
  isFromModal = false,
  hideButtonModal,
  closeButtonModal,
  variant = 'text',
  arrowColor,
  iconColor
}: Props) {
  let inputFile: HTMLInputElement | null;

  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<any>()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const aspect = 1 / 1

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''),)
      reader.readAsDataURL(e.target.files[0])
      setIsModalVisible(true)
      e.target.value = ''
      if (isFromModal && hideButtonModal) {
        hideButtonModal()
      }
    }
  }

  function onImageLoad(e: any) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width
        && completedCrop?.height
        && imgRef.current
        && previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop
        )
      }
    },
    100,
    [completedCrop],
  )

  function _onClick() {
    if (onClick) {
      onClick()
    }
    if (inputFile) {
      inputFile.click()
    }
  }

  let newImgUrl: any;
  let newFile: File

  const closeModal = () => {
    setIsModalVisible(false)
    if (closeButtonModal) {
      closeButtonModal()
    }
  }

  const createAndUploadPicture = () => {
    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeigh: 300,
      fileType: 'image/jpeg',
      initialQuality: 0.5
    }

    if (previewCanvasRef.current !== null) {
      newImgUrl = previewCanvasRef.current?.toDataURL('image/jpeg', 0.5)
      const url = newImgUrl
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          newFile = new File([blob], 'File name', { type: 'image/jpeg' })
          imageCompression(newFile, options)
            .then((compressedImg: any) => {
              const compressedFile = compressedImg
              newFile = new File([compressedFile], 'file name', { type: 'image/jpeg' })
            }).then(() => onUpload(newFile, idToUpload, indexToUpload))
        })
      closeModal()
      if (closeButtonModal) {
        closeButtonModal()
      }
    }
  }

  return (
    <>
      <input
        id="myFile"
        type="file"
        multiple
        style={{ display: 'none' }}
        ref={(inputElement) => { inputFile = inputElement }}
        accept=".png,.jpg,.jpeg"
        onChange={onSelectFile}
      />
      {isFromModal ? (
        <ListItemCard
          title={buttonLabel}
          ariaLabel={buttonLabel}
          onClick={_onClick}
          iconType="icon-FolderLight"
          extraClassNameContainer="item-from-btn-group"
          extraClassClickableContainer="first-item-border-radius"
          iconColor={iconColor}
          arrowColor={arrowColor}
        />
      ) : (
        <RegularButton variant={variant} label={buttonLabel} extraClass={`button-photo mr-2 ${className}`} id={id} onClick={_onClick} />
      )}
      <div>
        <AccessibleModal
          headerText="Ajustar foto"
          onClose={closeModal}
          visible={isModalVisible}
          maxWidthClass="modal-crop-image"
          titleText="Ajustar foto"
          variant="centerBottom"
        >
          {Boolean(imgSrc) && (
            <div className="crop-container mt-4">
              <ReactCrop
                crop={crop}
                onChange={(_: any, percentCrop: any) => setCrop(percentCrop)}
                onComplete={(c: any) => setCompletedCrop(c)}
                aspect={aspect}
                circularCrop
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  onLoad={onImageLoad}
                  style={{
                    maxWidth: '100%',
                    maxHeight: 'auto'
                  }}
                />
              </ReactCrop>
            </div>
          )}
          {Boolean(completedCrop) && (
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          )}
          <div className="flex justify-end mt-6">
            <RegularButton variant="text" label="Cancelar" extraClass="button-photo mr-4" onClick={closeModal} />
            <RegularButton variant="primary" label="Salvar" extraClass="button-photo" onClick={createAndUploadPicture} />
          </div>
        </AccessibleModal>
      </div>
    </>
  )
}
