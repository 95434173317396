export const SET_ACTIVE_ROUTE = 'SET_ACTIVE_ROUTE'

interface SetActiveRouteActionType {
  type: typeof SET_ACTIVE_ROUTE;
  route?: string;
}

export type RouterListenerTypes = SetActiveRouteActionType

export const setActiveRouteAction = (route?: string):
SetActiveRouteActionType => ({
  type: SET_ACTIVE_ROUTE,
  route
})
