import { EventEmitter } from "events";
import {
  validateConstructorTypes,
  nonEmptyString,
  pureObject,
  literal,
} from "@twilio/declarative-type-validator";

import { log } from "./logger";
import { Configuration, RetryPolicyType } from "./configuration";
import { TwilsockChannel } from "./twilsock";
import type { Context, Headers } from "./protocol/protocol";
import { PacketInterface } from "./packetinterface";
import { WebSocketChannel } from "./websocketchannel";
import { Registrations } from "./services/registrations";
import { Upstream } from "./services/upstream";
import { Result, Transport } from "./interfaces/transport";
import { InitReply } from "./protocol/messages/initReply";
import { Deferred } from "./deferred";
import { TwilsockError } from "./error/twilsockerror";
import { OfflineProductStorage } from "./offlinestorage";
import { InitRegistration } from "./protocol/initregistration";
import TokenStorage from "./tokenStorage";
import {
  EventSendingLimitation,
  TelemetryEventDescription,
  TelemetryPoint,
  TelemetryTracker,
} from "./services/telemetrytracker";
import { version } from "../package.json";
import { LogLevelDesc } from "loglevel";

export class TelemetryEvents {
  public static readonly TWILSOCK_CONNECT = "twilsock.sdk.connect"; // establish WebSocket connection (with WebSocket handshake finished)
  public static readonly TWILSOCK_INIT = "twilsock.sdk.init"; // send "init" message and receive reply
}

/**
 * Indicates current state of connection between the client and Sync service.
 * <p>Possible values are as follows:
 * <li>'connecting' - client is offline and connection attempt is in process.
 * <li>'connected' - client is online and ready.
 * <li>'disconnecting' - client is going offline as disconnection is in process.
 * <li>'disconnected' - client is offline and no connection attempt is in process.
 * <li>'denied' - client connection is denied because of invalid JWT access token. User must refresh token in order to proceed.
 * <li>'error' - client connection is in a permanent erroneous state. Client re-initialization is required.
 * @typedef {('unknown'|'connecting'|'connected'|'disconnecting'|'disconnected'|'denied'|'error')} TwilsockClient#ConnectionState
 */
export type ConnectionState =
  | "unknown"
  | "disconnecting"
  | "disconnected"
  | "connecting"
  | "connected"
  | "denied"
  | "error";

export type ClientOptionsType = {
  continuationToken?: string | null;
  channel?: TwilsockChannel;
  transport?: Transport;
  registrations?: Registrations;
  logLevel?: LogLevelDesc;
  region?: string;
  twilsock?: { uri?: string };
  Twilsock?: { uri?: string };
  retryPolicy?: RetryPolicyType;
  initRegistrations?: InitRegistration[] | null;
  tweaks: Record<string, unknown> | null;
  clientMetadata: Record<string, unknown>;
  productId?: string;
  twilsockClient?: TwilsockClient;
  notifications?: {
    region?: string;
    ersUrl?: string;
  };
};

/**
 * @alias Twilsock
 * @classdesc Client library for the Twilsock service
 * It allows to recevie service-generated updates as well as bi-directional transport
 * @fires Twilsock#message
 * @fires Twilsock#connected
 * @fires Twilsock#disconnected
 * @fires Twilsock#tokenAboutToExpire
 * @fires Twilsock#tokenExpired
 * @fires Twilsock#stateChanged
 * @fires Twilsock#connectionError
 */
@validateConstructorTypes(nonEmptyString, nonEmptyString, [
  pureObject,
  "undefined",
  literal(null),
])
class TwilsockClient extends EventEmitter {
  private readonly config: Configuration;
  private readonly channel: TwilsockChannel;

  private readonly registrations: Registrations;
  private readonly upstream: Upstream;
  private readonly telemetryTracker: TelemetryTracker;
  public readonly version = version;

  private offlineStorageDeferred: Deferred<OfflineProductStorage> =
    new Deferred();

  /**
   * @param {string} token Twilio access token
   * @param {string} productId Product identifier. Should be the same as a grant name in token
   * @param {object} options Twilsock configuration options
   */
  constructor(
    token: string,
    productId: string,
    options: Partial<ClientOptionsType>
  ) {
    super();

    options.continuationToken = options.continuationToken
      ? options.continuationToken
      : TokenStorage.getStoredToken(productId);

    const config = (this.config = new Configuration(token, productId, options));

    log.setLevel(config.logLevel);

    const websocket = new WebSocketChannel(config.url);

    const transport = new PacketInterface(websocket, config);
    this.channel = new TwilsockChannel(websocket, transport, config);
    this.registrations = new Registrations(transport);

    this.upstream = new Upstream(transport, this.channel, config);

    // Send telemetry only when connected and initialised
    this.telemetryTracker = new TelemetryTracker(config, transport);
    this.channel.on(
      "initialized",
      () => (this.telemetryTracker.canSendTelemetry = true)
    );
    websocket.on(
      "disconnected",
      () => (this.telemetryTracker.canSendTelemetry = false)
    );

    this.registrations.on("registered", (id) => this.emit("registered", id));

    this.channel.on("message", (type, message) =>
      setTimeout(() => this.emit("message", type, message), 0)
    );

    this.channel.on("stateChanged", (state) =>
      setTimeout(() => this.emit("stateChanged", state), 0)
    );

    this.channel.on("connectionError", (connectionError) =>
      setTimeout(() => this.emit("connectionError", connectionError), 0)
    );

    this.channel.on("tokenAboutToExpire", () =>
      setTimeout(() => this.emit("tokenAboutToExpire"), 0)
    );
    this.channel.on("tokenExpired", () =>
      setTimeout(() => this.emit("tokenExpired"), 0)
    );

    this.channel.on("connected", () =>
      this.registrations.updateRegistrations()
    );
    this.channel.on("connected", () => this.upstream.sendPendingMessages());
    this.channel.on("connected", () =>
      setTimeout(() => this.emit("connected"), 0)
    );

    // Twilsock telemetry events
    this.channel.on("beforeConnect", () =>
      this.telemetryTracker.addPartialEvent(
        new TelemetryEventDescription(
          "Establish WebSocket connection",
          "",
          new Date()
        ),
        TelemetryEvents.TWILSOCK_CONNECT,
        TelemetryPoint.Start
      )
    );

    this.channel.on("connected", () =>
      this.telemetryTracker.addPartialEvent(
        new TelemetryEventDescription(
          "Establish WebSocket connection",
          "",
          new Date(),
          new Date()
        ),
        TelemetryEvents.TWILSOCK_CONNECT,
        TelemetryPoint.End
      )
    );

    this.channel.on("beforeSendInit", () =>
      this.telemetryTracker.addPartialEvent(
        new TelemetryEventDescription("Send Twilsock init", "", new Date()),
        TelemetryEvents.TWILSOCK_INIT,
        TelemetryPoint.Start
      )
    );

    this.channel.on("initialized", () =>
      this.telemetryTracker.addPartialEvent(
        new TelemetryEventDescription(
          "Send Twilsock init",
          "Succeeded",
          new Date(),
          new Date()
        ),
        TelemetryEvents.TWILSOCK_INIT,
        TelemetryPoint.End
      )
    );

    this.channel.on("sendInitFailed", () =>
      this.telemetryTracker.addPartialEvent(
        new TelemetryEventDescription(
          "Send Twilsock init",
          "Failed",
          new Date(),
          new Date()
        ),
        TelemetryEvents.TWILSOCK_INIT,
        TelemetryPoint.End
      )
    );

    this.channel.on("initialized", (initReply: InitReply) => {
      this.handleStorageId(productId, initReply);
      TokenStorage.storeToken(initReply.continuationToken, productId);
      setTimeout(() => this.emit("initialized", initReply), 0);
    });

    this.channel.on("disconnected", () =>
      setTimeout(() => this.emit("disconnected"), 0)
    );
    this.channel.on("disconnected", () =>
      this.upstream.rejectPendingMessages()
    );
    this.channel.on("disconnected", () =>
      this.offlineStorageDeferred.fail(new TwilsockError("Client disconnected"))
    );

    this.offlineStorageDeferred.promise.catch(() => void 0);
  }

  public emit(event: string | symbol, ...args: unknown[]): boolean {
    log.debug(
      `Emitting ${event.toString()}(${args
        .map((a) => JSON.stringify(a))
        .join(", ")})`
    );
    return super.emit(event, ...args);
  }

  private handleStorageId(productId: string, initReply: InitReply) {
    if (!initReply.offlineStorage) {
      this.offlineStorageDeferred.fail(
        new TwilsockError("No offline storage id")
      );
    } else if (initReply.offlineStorage.hasOwnProperty(productId)) {
      try {
        this.offlineStorageDeferred.set(
          OfflineProductStorage.create(initReply.offlineStorage[productId])
        );
        log.debug(
          `Offline storage for '${productId}' product: ${JSON.stringify(
            initReply.offlineStorage[productId]
          )}.`
        );
      } catch (e) {
        this.offlineStorageDeferred.fail(
          new TwilsockError(
            `Failed to parse offline storage for ${productId} ${JSON.stringify(
              initReply.offlineStorage[productId]
            )}. ${e}.`
          )
        );
      }
    } else {
      this.offlineStorageDeferred.fail(
        new TwilsockError(
          `No offline storage id for '${productId}' product: ${JSON.stringify(
            initReply.offlineStorage
          )}`
        )
      );
    }
  }

  /**
   * Get offline storage ID
   * @returns {Promise}
   */
  public storageId(): Promise<OfflineProductStorage> {
    return this.offlineStorageDeferred.promise;
  }

  /**
   * Indicates if twilsock is connected now
   * @returns {Boolean}
   */
  public get isConnected(): boolean {
    return this.channel.isConnected;
  }

  /**
   * Current state
   * @returns {ConnectionState}
   */
  public get state(): ConnectionState {
    return this.channel.state;
  }

  /**
   * Update token
   * @param {String} token
   * @returns {Promise}
   */
  public async updateToken(token: string): Promise<void> {
    log.trace(`updating token '${token}'`);
    if (this.config.token === token) {
      return;
    }

    this.config.updateToken(token);
    return await this.channel.updateToken(token);
  }

  /**
   * Updates notification context.
   * This method shouldn't be used anyone except twilio notifications library
   * @param contextId id of notification context
   * @param context value of notification context
   * @private
   */
  public async setNotificationsContext(
    contextId: string,
    context: Context
  ): Promise<void> {
    await this.registrations.setNotificationsContext(contextId, context);
  }

  /**
   * Remove notification context.
   * This method shouldn't be used anyone except twilio notifications library
   * @param contextId id of notification context
   * @private
   */
  public async removeNotificationsContext(contextId: string): Promise<void> {
    await this.registrations.removeNotificationsContext(contextId);
  }

  /**
   * Connect to the server
   * @fires Twilsock#connected
   * @public
   * @returns {void}
   */
  public connect(): void {
    return this.channel.connect();
  }

  /**
   * Disconnect from the server
   * @fires Twilsock#disconnected
   * @public
   * @returns {Promise}
   */
  public async disconnect(): Promise<void> {
    this.telemetryTracker.sendTelemetry(
      EventSendingLimitation.AnyEventsIncludingUnfinished
    );
    return await this.channel.disconnect();
  }

  /**
   * Get HTTP request to upstream service
   * @param {string} url Upstream service url
   * @param {headers} headers Set of custom headers
   * @param {string} [grant] The product grant
   * @returns {Promise}
   */
  public async get(
    url: string,
    headers: Headers,
    grant?: string
  ): Promise<Result<Context>> {
    this.telemetryTracker.sendTelemetry(EventSendingLimitation.AnyEvents); // send collected telemetry (if any) before upstream message shipment
    return await this.upstream.send("GET", url, headers, undefined, grant);
  }

  /**
   * Post HTTP request to upstream service
   * @param {string} url Upstream service url
   * @param {headers} headers Set of custom headers
   * @param {body} body Body to send
   * @param {string} [grant] The product grant
   * @returns {Promise}
   */
  public async post<
    T extends Record<string, unknown> = Record<string, unknown>
  >(
    url: string,
    headers: Headers,
    body: Context | string,
    grant?: string
  ): Promise<Result<Context<T>>> {
    this.telemetryTracker.sendTelemetry(EventSendingLimitation.AnyEvents); // send collected telemetry (if any) before upstream message shipment
    return (await this.upstream.send(
      "POST",
      url,
      headers,
      body,
      grant
    )) as Result<Context<T>>;
  }

  /**
   * Put HTTP request to upstream service
   * @param {string} url Upstream service url
   * @param {headers} headers Set of custom headers
   * @param {body} body Body to send
   * @param {string} [grant] The product grant
   * @returns {Promise}
   */
  public async put<T extends Record<string, unknown> = Record<string, unknown>>(
    url: string,
    headers: Headers,
    body: Context | string,
    grant?: string
  ): Promise<Result<Context<T>>> {
    this.telemetryTracker.sendTelemetry(EventSendingLimitation.AnyEvents); // send collected telemetry (if any) before upstream message shipment
    return (await this.upstream.send(
      "PUT",
      url,
      headers,
      body,
      grant
    )) as Result<Context<T>>;
  }

  /**
   * Delete HTTP request to upstream service
   * @param {string} url Upstream service url
   * @param {headers} headers Set of custom headers
   * @param {body} body Body to send
   * @param {string} [grant] The product grant
   * @returns {Promise}
   */
  public async delete<
    T extends Record<string, unknown> = Record<string, unknown>
  >(
    url: string,
    headers: Headers,
    body?: Context | string,
    grant?: string
  ): Promise<Result<Context<T>>> {
    this.telemetryTracker.sendTelemetry(EventSendingLimitation.AnyEvents); // send collected telemetry (if any) before upstream message shipment
    return (await this.upstream.send(
      "DELETE",
      url,
      headers,
      body,
      grant
    )) as Result<Context<T>>;
  }

  /**
   * Submits internal telemetry event. Not to be used for any customer and/or sensitive data.
   * @param {TelemetryEventDescription} event Event details.
   * @returns {void}
   */
  public addTelemetryEvent(event: TelemetryEventDescription): void {
    this.telemetryTracker.addTelemetryEvent(event);
    this.telemetryTracker.sendTelemetryIfMinimalPortionCollected(); // send telemetry if need
  }

  /**
   * Submits internal telemetry event. Not to be used for any customer and/or sensitive data.
   * @param {TelemetryEventDescription} event Event details.
   * @param {string} eventKey Unique event key.
   * @param {TelemetryPoint} point Is this partial event for start or end of measurement.
   * @returns {void}
   */
  public addPartialTelemetryEvent(
    event: TelemetryEventDescription,
    eventKey: string,
    point: TelemetryPoint
  ): void {
    this.telemetryTracker.addPartialEvent(event, eventKey, point);
    if (point === TelemetryPoint.End) {
      // this telemetry event is complete, so minimal portion could become ready to send
      this.telemetryTracker.sendTelemetryIfMinimalPortionCollected(); // send telemetry if need
    }
  }
}

export { Context, Result, TwilsockClient, TwilsockClient as Twilsock };

/**
 * Twilsock destination address descriptor
 * @typedef {Object} Twilsock#Address
 * @property {String} method - HTTP method. (POST, PUT, etc)
 * @property {String} host - host name without path. (e.g. my.company.com)
 * @property {String} path - path on the host (e.g. /my/app/to/call.php)
 */

/**
 * Twilsock upstream message
 * @typedef {Object} Twilsock#Message
 * @property {Twilsock#Address} to - destination address
 * @property {Object} headers - HTTP headers
 * @property {Object} body - Body
 */

/**
 * Fired when new message received
 * @param {Twilsock#Message} message
 * @event Twilsock#message
 */

/**
 * Fired when socket connected
 * @param {String} URI of endpoint
 * @event Twilsock#connected
 */

/**
 * Fired when socket disconnected
 * @event Twilsock#disconnected
 */

/**
 * Fired when token is about to expire and should be updated
 * @event Twilsock#tokenAboutToExpire
 */

/**
 * Fired when token has expired and connection is aborted
 * @event Twilsock#tokenExpired
 */

/**
 * Fired when socket connected
 * @param ConnectionState state - general twilsock state
 * @event Twilsock#stateChanged
 */

/**
 * Fired when connection is interrupted by unexpected reason
 * @type {Object}
 * @property {Boolean} terminal - twilsock will stop connection attempts
 * @property {String} message - root cause
 * @property {Number} [httpStatusCode] - http status code if available
 * @property {Number} [errorCode] - Twilio public error code if available
 * @event Twilsock#connectionError
 */
