import React from 'react'
import { IconButton } from 'theia-web-ds'
import Logo from '../../../../assets/horizontal-logo.svg'
import { textPrimary } from '../../../color'
import './Header.scss'

interface Props {
  children?: React.ReactNode;
  title?: string;
  subTitle?: string;
  showLogo?: boolean;
  isLoading?: boolean;
  goBack?: () => void;
}

export default function Header({
  children,
  title,
  subTitle,
  showLogo,
  isLoading,
  goBack
}: Props) {
  return (
    <div
      className={`flex justify-between items-start
        ${showLogo ? 'header-content' : 'header-content-without-logo'}
      `}
    >
      {isLoading ? (
        <div className="header-loading-container">
          <div className="header-loading-button" />
          <div className="header-loading-title" />
        </div>
      ) : (
        <>
          <div className={`header-content-container ${showLogo ? 'header-content-text' : 'header-content-text-without-logo'}`}>
            {goBack ? (
              <IconButton
                aria-label="Voltar"
                variant="text"
                width="44px"
                height="44px"
                iconType="icon-ArrowLeft2Light"
                iconColor={textPrimary}
                iconSize="24px"
                onClick={goBack}
                extraClass="mr-2"
              />
            ) : (
              <div className="icon-button-placeholder h-11 w-2" />
            )}
            <div className="flex flex-col">
              {title && (<h1>{title}</h1>)}
              {subTitle && (<p>{subTitle}</p>)}
            </div>
          </div>
          <div className={`${showLogo ? 'header-content-left' : 'header-content-left-without-logo'} flex justify-between items-center`}>
            {showLogo && <Logo className="header-content-logo" alt="" />}
            {children}
          </div>
        </>
      )}
    </div>
  )
}
