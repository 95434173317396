/** Input Group possui label, input e mensagem de erro. Construído para inputs onde
 * sabemos o comprimento da string (length é obrigatório). Input pode ter máscara ou não
 * e pode ser do tipo texto, password, email, search, tel.
*/
import React, { useEffect, useState } from 'react';
import MaskedInput, { Mask } from 'react-text-mask';
import { ErrorMessage } from 'theia-web-ds';
import { onlyDigits } from '../../../../utils/helpers';
import FormInputPlaceholder from '../placeholders/FormInputPlaceholder';
import '../../Input.scss'

interface InputGroupProps {
  name: string;
  type?: 'text' | 'password' | 'email' | 'search' | 'tel';
  placeholder?: string;
  handleOnChange: (newValue: string) => void;
  error?: string;
  mask?: Mask | ((value: string) => Mask) | undefined;
  length: number;
  label?: string;
  value?: string;
  clearError?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  extraClass?: string;
}

export default function InputGroup({
  name,
  type = 'text',
  placeholder,
  handleOnChange,
  error,
  mask,
  length,
  label,
  value,
  clearError = () => {},
  disabled = false,
  isLoading = false,
  extraClass
}: InputGroupProps) {
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  if (isLoading) {
    return <FormInputPlaceholder />
  }

  return (
    <div className="input-group mt-8" data-testid={name}>
      {label && (
        <label htmlFor={name} className={error ? 'error' : ''}>{label}</label>
      )}
      {mask ? (
        <MaskedInput
          data-testid={`${name}-input`}
          name={name}
          id={name}
          type={type}
          placeholder={placeholder}
          value={inputValue}
          mask={mask}
          onChange={(e) => {
            clearError();
            setInputValue(e.target.value);
            if (onlyDigits(e.target.value).length === length) {
              handleOnChange(e.target.value);
            }
          }}
          onBlur={(e) => handleOnChange(e.target.value)}
          disabled={disabled}
          style={{
            padding: 10,
            width: '100%',
            border: '1px solid #EEEEEE',
            borderRadius: 5,
            marginTop: 8,
            borderColor: error && '#FF7951'
          }}
        />
      ) : (
        <input
          data-testid={`${name}-input`}
          name={name}
          id={name}
          type={type}
          placeholder={placeholder}
          value={inputValue}
          className={`input ${extraClass} ${error ? 'error' : ''}`}
          onChange={(e) => {
            if (onlyDigits(e.target.value).length <= length) {
              clearError();
              setInputValue(e.target.value);
            }
            if (onlyDigits(e.target.value).length === length) {
              handleOnChange(e.target.value);
            }
          }}
          onBlur={(e) => handleOnChange(e.target.value)}
          disabled={disabled}
        />
      )}
      {error && <ErrorMessage error={error} />}
    </div>
  );
}
