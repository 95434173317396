import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  NewListItem,
  RegularButton,
  Divider,
  ResponsiveDrawer,
  FontIcon
} from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import './BookingCard.scss'
import { Booking } from '../../domain/Profile';
import {
  capitalize,
  creatEventOnGoogleCalendar,
  forbiddenCategories,
  formatToDate,
  formatToHours,
  getGoogleCalendarEventInfos,
  isCreditCard,
  isPresentialType,
  isSlip
} from '../../utils/helpers';
import { promptScheduleCancellationAction } from '../../state/scheduleCancellation/actions';
import Helpers from './helpers'
import SpecialistChip from '../common/molecules/SpecialistChip';
import { primaryLight, textSecondary } from '../../color';
import RescheduleView from '../rescheduleFlow/RescheduleView';
import { eventTrack } from '../../../eventGenerate';
import {
  setPatientTypeAction,
  setPreviousSelectedCategoryAction,
  setPreviousSelectedChildIdAction,
  setPreviousSelectedSpecialistAction,
  setScheduleSourceAction
} from '../../state/scheduleFlow/actions'
import { SpecialistCategories } from '../../domain/Specialist'
import { PatientType, SourceTypes } from '../../domain/ScheduleFlow'
import { AGENDAR, BOOKINGS } from '../../routes/RoutesConstants'

interface Props {
  booking: Booking;
  onHideDrawer: () => void;
  cancelBooking: (bookingId: string, isExam: boolean) => void;
  onShowBookingDetails?: () => void;
  setPreviousSelectedCategory: (category: SpecialistCategories) => void;
  setPatientType: (patientType: PatientType) => void;
  setScheduleSource: (source: SourceTypes) => void;
  setPreviousSelectedSpecialist: (specialistId: string) => void;
  setPreviousSelectedChildId: (childId: string) => void;
}

function BookingDetailsDrawer({
  booking,
  onHideDrawer,
  cancelBooking,
  onShowBookingDetails,
  setPreviousSelectedCategory,
  setPatientType,
  setScheduleSource,
  setPreviousSelectedSpecialist,
  setPreviousSelectedChildId,
}: Props) {
  const history = useHistory()
  const [currentAction, setCurrentAction] = useState<'details' | 'reschedule'>('details')
  const [isBookedByHealthInsurance, setIsBookedByHealthInsurance] = useState(false)
  const [isDetails, setIsDetails] = useState(true)
  const isExam = booking.isExam || false
  const isCancelled = Helpers.isCancelledBooking(booking.state)
  const {
    descriptionEvent,
    titleEvent,
    locationEvent,
    startDateTime,
    endDateTime
  } = getGoogleCalendarEventInfos(booking)

  const weekDay = capitalize(booking.startDate.formatDateToWeekday())
  const dayTwoDigits = booking.startDate.formatDayTwoDigits()
  const bookingDateInfo = (
    `${weekDay}, ${dayTwoDigits} de ${booking.startDate.formatFullMonth()}`
  )
  const isPresential = isPresentialType(booking.type)
  const bookingAddress = booking.attendanceAddress
  const typeName = isPresential ? 'presencial ' : 'on-line '

  const iconType = isPresential ? 'icon-LocationLight' : 'icon-VideoLight'
  const bookingAdressToShow = bookingAddress ? (
    `${bookingAddress?.formatToNameNumberAndComplement()}, ${bookingAddress?.formatToDistrictAndCity()}`
  ) : 'Atendimento em Domicílio'
  const captionType = isPresential ? bookingAdressToShow : (
    'Entre no dia e horário agendado'
  )
  const healthInsuranceCaption = booking.healthInsuranceCompany
  const isPayed = booking.fullPriceValue !== 0
  const discountedValue = booking.fullPriceValue - booking.finalValue
  const percentage = (((discountedValue) * 100) / booking.fullPriceValue)
  const valueWithDiscount = `De R$ ${booking.fullPriceValue / 100},00 por R$ ${booking.finalValue / 100},00 (${percentage}% de desconto)`
  const valueCharged = booking.discountedValue !== 0 ? `${valueWithDiscount}` : (
    `Total a vista: R$ ${booking.finalValue / 100},00`
  )
  const cardLastDigitsInfo = booking.cardLastDigits ? `final ••• ${booking.cardLastDigits}` : 'de crédito'
  const cardInfo = `Cartão ${cardLastDigitsInfo}`
  const auxPaymentType = isSlip(booking.paymentType) ? 'Boleto' : 'PIX'
  const paymentType = isCreditCard(booking.paymentType) ? (cardInfo) : auxPaymentType
  const valueTitle = booking.discountedValue !== 0 && booking.finalValue === 0 ? (
    'Pago com cupom de desconto'
  ) : `Pagamento: ${paymentType}`
  const paymentTitle = isPayed && !isBookedByHealthInsurance ? valueTitle : 'Valor incluso no plano'
  const paymentCaption = isPayed && !isBookedByHealthInsurance ? valueCharged : undefined
  const healthInsuranceTitle = 'Coberto pelo plano de saúde'
  const dueDatePayment = isSlip(booking.paymentType)
    ? (booking.paymentSlipDueDate) : booking.pixDueDate
  const dueDatePaymentMoment = formatToDate(dueDatePayment)
  const dueHourPayment = isSlip(booking.paymentType) ? (
    ''
  ) : `às ${formatToHours(booking.pixDueDate)} `
  const pendingPaymentDescription = (
    `Pague R$ ${booking.finalValue / 100} até o dia ${dueDatePaymentMoment} ${dueHourPayment}`
    + `para garantir ${isExam ? 'o seu exame' : 'a sua consulta'}. Assim que o pagamento for aprovado, você irá receber a confirmação ${isExam ? 'do seu exame' : 'da sua consulta'} por e-mail.`
  )
  const drawerTitle = isDetails
    ? `Detalhes ${isExam ? 'do exame' : 'da consulta'}`
    : `Remarcar ${isExam ? 'exame' : 'consulta'}`
  const statusText = isCancelled
    ? `${isExam ? 'Exame cancelado' : 'Consulta cancelada'}`
    : `${isExam ? 'Exame realizado' : 'Consulta realizada'}`
  const cancelledText = isCancelled
    ? `${isExam ? 'Esse exame foi cancelado.' : 'Essa consulta foi cancelada. Você pode agendar um novo especialista.'}`
    : `${isExam ? 'Você pode remarcar seu exame.' : 'Você pode remarcar ou agendar um novo especialista.'}`

  useEffect(() => {
    if (onShowBookingDetails) {
      onShowBookingDetails()
    }
  }, [])

  useEffect(() => {
    setIsDetails(currentAction === 'details')
  }, [currentAction])

  useEffect(() => {
    if (booking.healthInsuranceCompany !== null && booking.healthInsuranceCompany !== undefined) {
      setIsBookedByHealthInsurance(true)
    } else {
      setIsBookedByHealthInsurance(false)
    }
  }, [])

  function goToSchedule(filterSpecialist?: boolean) {
    if (filterSpecialist) setPreviousSelectedSpecialist(booking.chronosId)
    setPatientType(booking.children?.id ? PatientType.IS_CHILD : PatientType.IS_USER)
    setPreviousSelectedCategory(booking.professionalCategory)
    setPreviousSelectedChildId(booking.children?.id)
    setScheduleSource(SourceTypes.BOOKING_DETAILS)
    history.push(AGENDAR)
  }

  function onClickNewSchedule() {
    goToSchedule(true)
  }

  function cancel() {
    cancelBooking(booking.id, isExam)
  }

  function goToBooking() {
    history.push(`${BOOKINGS}/${booking.roomName}`)
  }

  function goToMap() {
    window.open(`https://maps.google.com/maps?q=${booking.attendanceAddress.toString()}`)
  }

  function onCopy() {
    navigator.clipboard.writeText(captionType)
  }

  function onDownloadSlipPdfUrl() {
    window.open(booking.paymentSlipPdfUrl)
  }

  function onCopySlipDigitableCode() {
    navigator.clipboard.writeText(booking.paymentSlipDigitableCode)
  }

  function onDownloadPixQrCode() {
    window.open(booking.pixQrCodeUrl)
  }

  function onCopyPixDigitableCode() {
    navigator.clipboard.writeText(booking.pixDigitableCode)
  }

  function onCreateCalendarEvent() {
    if (!booking) return
    creatEventOnGoogleCalendar(
      titleEvent,
      locationEvent,
      descriptionEvent,
      startDateTime,
      endDateTime
    )
  }

  function onReSchedule() {
    setCurrentAction('reschedule')
  }

  function downloadPrescription(prescription: string) {
    window.open(prescription, '_blank')
  }

  function handleGoBackDrawer() {
    if (isDetails) {
      onHideDrawer()
    } else {
      setCurrentAction('details')
    }
  }

  const extraButtonType = (
    <div className="booking-type-buttons-wrapper">
      {isPresential && bookingAddress ? (
        <>
          <RegularButton
            onClick={onCopy}
            label="Copiar"
            variant="text"
            btnSize="small"
          />
          <span className="booking-type-separator-button"> • </span>
          <RegularButton
            onClick={goToMap}
            label="Ver no mapa"
            variant="text"
            btnSize="small"
          />
        </>
      ) : (
        <RegularButton
          onClick={goToBooking}
          label="Entrar na videochamada"
          variant="text"
        />
      )}
    </div>
  )

  const confirmedCardComponent = (
    <div className="booking-details-card">
      <div className="booking-details-card-info">
        <FontIcon iconType="icon-CalendarLight" color={textSecondary} fontSize="24px" />
        <div className="booking-details-card-info-container">
          <div className={`booking-details-card-title ${isCancelled ? 'risked-text' : ''}`}>
            {bookingDateInfo}
          </div>
          <div className={`booking-details-card-caption ${isCancelled ? 'risked-text' : ''}`}>
            {booking.startDate.formatDateToHours()}
          </div>
        </div>
      </div>
      <div className="footer-container">
        <div className="know-more-button">
          {isExam || forbiddenCategories.includes(booking.categoryId) ? (
            <RegularButton
              onClick={cancel}
              label="Cancelar"
              variant="text"
              id="cancel-booking-button"
            />
          ) : (
            <RegularButton
              onClick={onReSchedule}
              label="Remarcar"
              variant="text"
              id="know-more-button"
            />
          )}
        </div>
        {!isExam && (
          <div className="second-button">
            <RegularButton
              onClick={onCreateCalendarEvent}
              label="Salvar na agenda"
              variant="text"
            />
          </div>
        )}
      </div>
    </div>
  )

  const pendentPaymentComponent = (
    <div className="booking-details-banner-pendent-payment">
      <p className="booking-details-banner-pendent-payment-title">
        Pagamento pendente
      </p>
      <p className="booking-details-banner-pendent-payment-description">
        {pendingPaymentDescription}
      </p>
      <Divider />
      <div className="footer-container">
        <div className="first-button">
          <RegularButton
            onClick={isSlip(booking.paymentType) ? (
              onCopySlipDigitableCode
            ) : onCopyPixDigitableCode}
            label="Copiar código"
            variant="text"
          />
        </div>
        <div className="second-button">
          <RegularButton
            onClick={isSlip(booking.paymentType) ? (
              onDownloadSlipPdfUrl
            ) : onDownloadPixQrCode}
            label={isSlip(booking.paymentType) ? 'Imprimir boleto' : 'Baixar QR code'}
            variant="text"
          />
        </div>
      </div>
    </div>
  )

  return (
    <ResponsiveDrawer
      id="booking-details-modal"
      headerTitle={drawerTitle}
      isDrawerVisible
      onGoBack={handleGoBackDrawer}
      onClose={onHideDrawer}
    >
      {isDetails ? (
        <>
          {!Helpers.isConfirmedBooking(booking.state)
          && !Helpers.isDismissedBooking(booking.state)
          && (
            <div className="booking-details-banner-state">
              <p className="booking-details-banner-state-title">
                {statusText}
              </p>
              <p className="booking-details-banner-state-description">
                {cancelledText}
              </p>
            </div>
          )}
          {!booking.alreadyPaid
          && !isCreditCard(booking.paymentType)
          && booking.finalValue !== 0 && (
            pendentPaymentComponent
          )}
          <div className="booking-details-specialist-info">
            <SpecialistChip
              professionalPictureUrl={booking?.professionalPictureUrl}
              caption={booking?.professionalCategory}
              professionalName={booking?.professionalName}
              showFirstCaption
            />
          </div>
          {booking.prescriptions.length >= 1 && (
            booking.prescriptions.map((prescription) => (
              <div className="booking-prescription-info">
                <NewListItem
                  iconType="icon-PaperclipLight"
                  iconSize="24px"
                  iconColor={primaryLight}
                  ariaLabel={`Enviado dia ${formatToDate(prescription.created)}`}
                  title={`Enviado dia ${formatToDate(prescription.created)}`}
                  id="booking-card-time"
                  key="booking-card-time"
                  extraTitleClass="booking-prescription-title"
                  extraComponent={(
                    <RegularButton
                      variant="text"
                      iconType="icon-DownloadLight"
                      iconSize="24px"
                      onClick={() => downloadPrescription(prescription.pdfUrl)}
                    />
                  )}
                  isNoHover
                />
              </div>
            ))
          )}
          {Helpers.isConfirmedBooking(booking.state) && confirmedCardComponent}
          <ul className="booking-details-list-infos">
            {!Helpers.isConfirmedBooking(booking.state) && (
              <NewListItem
                iconType="icon-TimeCircleLight"
                iconSize="24px"
                title={bookingDateInfo}
                ariaLabel={`${bookingDateInfo} às ${booking.startDate.formatDateToHours()}`}
                caption={booking.startDate.formatDateToHours()}
                id="booking-card-time"
                key="booking-card-time"
                isRisked={isCancelled}
                isNoHover
              />
            )}
            <NewListItem
              iconType={iconType}
              iconSize="24px"
              title={isExam
                ? 'Endereço'
                : `Consulta ${typeName}`}
              ariaLabel={isExam
                ? `Endereço: ${captionType}`
                : `Consulta ${typeName} - ${captionType}`}
              caption={Helpers.isConfirmedBooking(booking.state) ? captionType : ''}
              id="booking-card-type"
              key="booking-card-type"
              extraButton={Helpers.isConfirmedBooking(booking.state) && extraButtonType}
              isRisked={isCancelled}
              isLastItem
              isNoHover
            />
            <Divider className="mb-6" />
            {(booking.alreadyPaid || isCreditCard(booking.paymentType)) && (
              <NewListItem
                iconType="icon-WalletLight"
                iconSize="24px"
                title={isBookedByHealthInsurance ? healthInsuranceTitle : paymentTitle}
                ariaLabel={isBookedByHealthInsurance ? `${healthInsuranceTitle} - ${healthInsuranceCaption}` : `${paymentTitle} - ${paymentCaption}`}
                caption={isBookedByHealthInsurance ? healthInsuranceCaption : paymentCaption}
                isLastItem
                id="booking-card-payment"
                key="booking-card-payment"
                isRisked={isCancelled}
                isNoHover
              />
            )}
          </ul>
          <div className="booking-details-footer">
            <Divider />
            <div className="booking-details-button">
              {Helpers.isConfirmedBooking(booking.state) ? (
                <>
                  {isExam && (
                    <RegularButton
                      onClick={onCreateCalendarEvent}
                      label="Salvar no calendário"
                      extraClass="ml-4"
                      id="cancel-booking-button"
                    />
                  )}
                  {!isExam && (
                    <RegularButton
                      onClick={cancel}
                      disabled={!booking.cancelable}
                      label="Cancelar consulta"
                      variant="text"
                      extraClass="booking-details-cancel-btn"
                      id="cancel-booking-button"
                    />
                  )}
                </>
              ) : (
                <RegularButton
                  onClick={() => goToSchedule(false)}
                  label="Novo especialista"
                  variant="text"
                  extraClass="booking-details-cancel-btn"
                />
              )}
              {!isExam && (
                <RegularButton
                  onClick={onClickNewSchedule}
                  label="Nova consulta"
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <RescheduleView
          booking={booking}
          onAfterReSchedule={() => {
            onHideDrawer()
            if (isExam) {
              eventTrack('USG - Remarcou exame Ultrassom')
            }
          }}
        />
      )}
    </ResponsiveDrawer>
  )
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  cancelBooking: (bookingId: string, isExam: boolean) => {
    dispatch(promptScheduleCancellationAction(bookingId, isExam))
  },
  setPreviousSelectedCategory: (category: SpecialistCategories) => {
    dispatch(setPreviousSelectedCategoryAction(category))
  },
  setPatientType: (patientType: PatientType) => {
    dispatch(setPatientTypeAction(patientType))
  },
  setScheduleSource: (source: SourceTypes) => {
    dispatch(setScheduleSourceAction(source))
  },
  setPreviousSelectedSpecialist: (specialistId: string) => {
    dispatch(setPreviousSelectedSpecialistAction(specialistId))
  },
  setPreviousSelectedChildId: (childId: string) => {
    dispatch(setPreviousSelectedChildIdAction(childId))
  },
})

export default connect(
  undefined,
  mapDispatchToProps
)(BookingDetailsDrawer)
