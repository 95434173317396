import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import MaskedInput from 'react-text-mask'
import moment from 'moment'
import { RegularButton, ErrorMessage } from 'theia-web-ds'
import { eventTrack } from '../../../eventGenerate'

interface Props {
  locationToPush?: string;
  isToRedirect?: boolean;
  submitToBack?: (lastPeriodDate: string) => void;
  isSubmitting?: boolean;
}

function PregnancyDueDate({
  locationToPush,
  isToRedirect,
  submitToBack,
  isSubmitting
}: Props) {
  const [date, setDate] = useState<Date | null>(null)
  const history = useHistory()

  function onChangePregnancyDueDate(pDate: Date) {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(pDate.getTime())) {
      setDate(null)
    } else {
      setDate(pDate)
      eventTrack('Escolheu DPP no Select')
    }
  }

  function onSaveDate() {
    const dateTime = date?.valueOf()
    localStorage.setItem('pregnancyDueDate', (dateTime ? `${dateTime}` : ''))
    localStorage.setItem('lastPeriod', '')
    eventTrack('Clicou Continuar DPP')
    if (isToRedirect) {
      history.push(`${locationToPush}`)
    } else if (submitToBack) {
      submitToBack(`${dateTime}`)
    }
  }

  function passedDate() {
    if (date && date !== undefined && date !== null) {
      const insertedDate = moment(date, 'DD/MM/YYYY').toDate()
      const today = new Date()
      const futureTime = new Date(today.getFullYear(), today.getMonth(), today.getDate())
      if (insertedDate < futureTime) {
        return true
      }
    }
    return false
  }

  function passedScope() {
    if (date && date !== undefined && date !== null) {
      const insertedDate = moment(date, 'DD/MM/YYYY').toDate()
      const today = new Date()
      const validMonth = new Date(today.getFullYear(), today.getMonth() + 10, today.getDate())
      if (insertedDate > validMonth) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <p className="text-fontSmall text-textPrimary justify-center text-center mt-5 max-w-full">
        Qual a data provável de nascimento do bebê?
      </p>
      <MaskedInput
        id="pregnancy-due-date"
        name="pregnancy-due-date"
        type="text"
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        value={date?.toLocaleDateString()}
        onBlur={(e) => onChangePregnancyDueDate(moment(e.target.value, 'DD/MM/YYYY').toDate())}
        placeholder="DD/MM/AAAA"
        style={{
          padding: 10, width: '100%', border: '1px solid #EEEEEE', borderRadius: 5, marginTop: 15
        }}
      />
      {passedDate() && <ErrorMessage error="Não é possivel colocar uma data passada" />}
      {passedScope() && <ErrorMessage error="Não é possivel colocar uma data fora do periodo gestacional" />}
      <div className="flex items-center justify-center bottom-button-date-container">
        <RegularButton
          onClick={onSaveDate}
          disabled={!date || passedDate() || passedScope() || isSubmitting}
          label="Continuar"
          extraClass="mt-6"
          isSubmitting={isSubmitting}
        />
      </div>
    </>
  )
}

export default PregnancyDueDate
