import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { RegularButton } from 'theia-web-ds'
import AppMainContainerOff from '../AppMainContainerOff'
import './SalesOnboardingWelcome.scss'
import Aldeia from '../../../assets/aldeia.svg'
import WelcomeIlust from '../../../assets/welcome-ilust.png'
import { eventPage, eventTrack } from '../../../eventGenerate'
import { TOKEN_ACCESS, TOKEN_OLD, TOKEN_REFRESH } from '../../domain/User'
import { REDIRECT_SIGNUP } from '../../state/remoteConfig'
import UnauthenticatedModal from '../authentication/UnauthenticatedModal'
import { LISTA_DE_INTERESSE, LOGIN, MOMENTO_DE_SAUDE } from '../../routes/RoutesConstants'

function SalesOnboardingWelcome() {
  const history = useHistory()
  const titleText = 'O cuidado que você merece!'

  useEffect(() => {
    localStorage.removeItem(TOKEN_ACCESS)
    localStorage.removeItem(TOKEN_REFRESH)
    localStorage.removeItem(TOKEN_OLD)
    eventPage('onboarding', 'Boas Vindas')
  }, [])

  function goFoward() {
    if (REDIRECT_SIGNUP) {
      history.push(LISTA_DE_INTERESSE)
    } else {
      history.push(MOMENTO_DE_SAUDE)
    }
  }

  function goToLogin() {
    eventTrack('Clicou Fazer Login')
    history.push(LOGIN)
  }

  return (
    <AppMainContainerOff>
      <>
        <UnauthenticatedModal />
        <div className="flex screen-title-container sales-welcome">
          <h1 className="text-primary font-medium text-headlineMedium">
            {titleText}
          </h1>
          <div className="pre-login-icon md:hidden visible lg:hidden">
            <Aldeia />
          </div>
        </div>
        <p className="text-textMedium text-darkGreyColor mt-5 text-explanation">
          Cadastre-se e agende uma consulta na clínica com uma de nossas ginecologistas-obstetras -
          particular ou agora também com cobertura pelo convênio para planejamento,
          pré-natal e parto, com o jeito Theia de cuidar.
        </p>
        <div className="know-theia-container pt-4">
          <div className="icon-welcome">
            <img src={WelcomeIlust} alt="" width="261px" height="240px" />
          </div>
          <div className="button-container w-full">
            <RegularButton
              type="button"
              label="Cadastrar"
              onClick={goFoward}
              maxWidth="100%"
              extraClass="mt-6"
            />
            <RegularButton
              type="button"
              label="Quero acessar minha conta"
              onClick={goToLogin}
              btnSize="large"
              variant="secondary"
              maxWidth="100%"
              extraClass="text-textSmall text-primaryDark border-primaryDark font-medium mt-6 mb-12"
            />
          </div>
        </div>
      </>
    </AppMainContainerOff>
  )
}

export default SalesOnboardingWelcome
