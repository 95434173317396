import React from 'react'
import { connect } from 'react-redux'
import { RegularButton } from 'theia-web-ds'
import moment from 'moment'
import { AppState } from '../../../apps/main/store'
import { AppDispatch } from '../../../state/utils'
import CalendarClock from '../../../../assets/calendar_clock.svg'
import { confirmBookingSMSAction, suppressConfirmBookingSMSModalAction } from '../../../state/scheduleConfirmationSMS/actions'
import Modal from '../../common/Modal'
import CancelButton from '../../common/buttons/CancelButton'
import { formatToHours } from '../../../utils/helpers'

interface Props {
  closeModal: () => void;
  confirmBooking: (
    bookingId: string, token: string, participantType: string, professionalStatus: string
  ) => void;
  bookingId: string;
  token: string;
  startDate?: string;
  isLoading: boolean;
}

function ScheduleConfirmationSmsModal({
  confirmBooking,
  closeModal,
  bookingId,
  token,
  startDate,
  isLoading
}: Props) {
  const startDateString = startDate && moment(startDate).format('DD/MM')
  const startHourString = startDate && formatToHours(startDate)
  const textModal = `Deseja confirmar a consulta do dia ${startDateString} às ${startHourString}?`
  return (
    <Modal>
      <div className="schedule-cancelation-confirmation-modal flex flex-col text-center">
        <div className="self-center ml-4">
          <CalendarClock />
        </div>
        <h1 className="text-textMedium text-primary font-medium w-8/12 mx-auto my-4">Confirmação de consulta</h1>
        <p className="text-textSmall text-darkGreyColor font-medium">
          {textModal}
        </p>
        <RegularButton
          label="Confirmar consulta"
          onClick={() => confirmBooking(bookingId, token, 'Professional', 'Done')}
          disabled={isLoading}
          maxWidth="100%"
          extraClass="cancelation-confirmation-button mt-4 mb-4"
        />
        <CancelButton
          onClick={closeModal}
          extraClass="font-medium text-primaryDark"
        >
          Fechar
        </CancelButton>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ scheduleConfirmationSMS }: AppState) => ({
  startDate: scheduleConfirmationSMS.startDate,
  bookingId: scheduleConfirmationSMS.bookingId,
  token: scheduleConfirmationSMS.token,
  isLoading: scheduleConfirmationSMS.isLoading,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  closeModal: () => {
    dispatch(suppressConfirmBookingSMSModalAction())
  },
  confirmBooking: (
    bookingId: string, token: string, participantType: string, professionalStatus: string
  ) => {
    dispatch(confirmBookingSMSAction(bookingId, token, participantType, professionalStatus))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleConfirmationSmsModal)
