import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { NewListItem } from 'theia-web-ds'
import NavItem from './NavItem'
import { primary, textPrimary, textSecondary } from '../../../color'
import Drawer from '../drawer/Drawer'
import { promptDrawerAction, suppressDrawerAction } from '../../../state/drawer/actions'
import { AppDispatch } from '../../../state/utils'
import { getFlutterClient } from '../../../utils/helpers'
import { AppState } from '../../../apps/main/store'
import { AppointedSpecialists } from '../../../domain/User'
import './Navigation.scss'
import {
  AGENDAR,
  CHAT,
  CONSULTAS,
  CONTENT,
  INICIO,
  PROFILE,
  SAUDE
} from '../../../routes/RoutesConstants'
import { SourceTypes } from '../../../domain/ScheduleFlow'
import { setScheduleSourceAction } from '../../../state/scheduleFlow/actions'

const isActiveMenuItem = (pages: Array<string>) => (
  pages.some((p) => window.location.pathname.includes(p))
)

const initialIsActiveWeekView = isActiveMenuItem([INICIO])
const initialIsActiveMyBookingsView = isActiveMenuItem([CONSULTAS])
const initialIsActiveSchedule = isActiveMenuItem([AGENDAR])
const initialIsActiveMore = isActiveMenuItem([CONTENT, PROFILE, SAUDE])
const initialIsActiveContent = isActiveMenuItem([CONTENT])
const initialIsActiveChat = isActiveMenuItem([CHAT])
const initialIsActiveProfile = isActiveMenuItem([PROFILE])
const initialIsActiveHealth = isActiveMenuItem([SAUDE])

type MenuProps = {
  route: string;
  className?: string;
  promptDrawer: () => void;
  suppressDrawer: () => void;
  showNewPrescriptionBadge: boolean;
  hasSpecialist?: Array<AppointedSpecialists> | [];
  insurancePlanCovered?: boolean | null;
  setScheduleSource: (source: SourceTypes) => void;
}

const Bottombar = React.memo(({
  route,
  className,
  promptDrawer,
  suppressDrawer,
  showNewPrescriptionBadge,
  hasSpecialist,
  insurancePlanCovered,
  setScheduleSource
}: MenuProps) => {
  const history = useHistory()
  const [showMoreModal, setShowMoreModal] = useState(false)
  const [isActiveWeekView, setIsActiveWeekView] = useState(initialIsActiveWeekView)
  const [isActiveSchedule, setIsActiveSchedule] = useState(initialIsActiveSchedule)
  const [isActiveMore, setIsActiveMore] = useState(initialIsActiveMore)
  const [isActiveContent, setIsActiveContent] = useState(initialIsActiveContent)
  const [isActiveChat, setIsActiveChat] = useState(initialIsActiveChat)
  const [isActiveProfile, setIsActiveProfile] = useState(initialIsActiveProfile)
  const [isActiveHealth, setIsActiveHealth] = useState(initialIsActiveHealth)
  const [
    isActiveMyBookingsView,
    setIsActiveMyBookingsView
  ] = useState(initialIsActiveMyBookingsView)

  const updateActiveMenuItem = () => {
    setIsActiveWeekView(isActiveMenuItem([INICIO]))
    setIsActiveMyBookingsView(isActiveMenuItem([CONSULTAS]))
    setIsActiveSchedule(isActiveMenuItem([AGENDAR]))
    setIsActiveMore(isActiveMenuItem([CONTENT, PROFILE, SAUDE]))
    setIsActiveContent(isActiveMenuItem([CONTENT]))
    setIsActiveProfile(isActiveMenuItem([PROFILE]))
    setIsActiveHealth(isActiveMenuItem([SAUDE]))
    setIsActiveChat(isActiveMenuItem([CHAT]))
  }

  useEffect(() => {
    updateActiveMenuItem()
  }, [route])

  const handleClick = (newRoute: string) => {
    localStorage.removeItem('bookingSuggestion')
    localStorage.removeItem('scheduleSuggestion')
    history.push(newRoute)
    updateActiveMenuItem()
    setShowMoreModal(false)
  }

  function extraActionOnSuppressDrawer() {
    setShowMoreModal(false)
  }

  const handleClickHealth = () => {
    handleClick(SAUDE)
  }

  const handleClickChat = () => {
    handleClick(CHAT)
  }

  const handleClickWeekView = () => {
    handleClick(INICIO)
  }

  const handleClickMyBookingsView = () => {
    handleClick(CONSULTAS)
  }

  const handleClickSchedule = () => {
    setScheduleSource(SourceTypes.MENU)
    handleClick(AGENDAR)
  }

  const handleClickContent = () => {
    handleClick(CONTENT)
    suppressDrawer()
  }

  const handleClickProfile = () => {
    handleClick(PROFILE)
    suppressDrawer()
  }

  const handleClickMore = () => {
    setShowMoreModal(true)
    promptDrawer()
  }
  if (getFlutterClient()) return null

  return (
    <div className={`bottombar-content ${className}`}>
      <ul role="menu" aria-label="Menu">
        <NavItem
          handleClick={handleClickWeekView}
          isActive={isActiveWeekView}
          text="Início"
          activeIconType="icon-WeekBold"
          iconType="icon-WeekLight"
          variant="bottombar"
        />
        <NavItem
          handleClick={handleClickSchedule}
          isActive={isActiveSchedule}
          text="Agendar"
          activeIconType="icon-CalendarBold"
          iconType="icon-CalendarLight"
          variant="bottombar"
        />
        {((hasSpecialist !== undefined && hasSpecialist.length > 0)
            || (insurancePlanCovered !== null && insurancePlanCovered))
            && (
            <NavItem
              handleClick={handleClickChat}
              isActive={isActiveChat}
              text="Chat"
              activeIconType="icon-TheiaLight"
              iconType="icon-TheiaLight"
              variant="bottombar"
            />
            )}
        <NavItem
          handleClick={handleClickMyBookingsView}
          isActive={isActiveMyBookingsView}
          text="Consultas"
          activeIconType="icon-PhonendoscopeBold"
          iconType="icon-PhonendoscopeLight"
          variant="bottombar"
        />
        <NavItem
          handleClick={handleClickMore}
          isActive={isActiveMore}
          text="Mais"
          activeIconType="icon-MoreSquareBold"
          iconType="icon-MoreSquareLight"
          variant="bottombar"
        />
        {showMoreModal && (
          <Drawer variant="bottomDrawer" extraAction={extraActionOnSuppressDrawer}>
            <div className="bottom-bar-more-modal">
              <div className="divider-top" />
              <NewListItem
                onClick={handleClickHealth}
                iconType={isActiveHealth ? 'icon-MedicalRecordBold' : 'icon-MedicalRecordLight'}
                iconColor={isActiveHealth ? primary : textPrimary}
                arrowColor={textSecondary}
                title="Saúde"
                ariaLabel="Saúde"
                id="Saúde"
                key="Saúde"
                isLastItem={false}
                hasIconBadge={showNewPrescriptionBadge}
              />
              <NewListItem
                onClick={handleClickContent}
                iconType={isActiveContent ? 'icon-PaperBold' : 'icon-PaperLight'}
                iconColor={isActiveContent ? primary : textPrimary}
                arrowColor={textSecondary}
                title="Conteúdo"
                ariaLabel="Conteúdo"
                id="Conteúdo"
                key="Conteúdo"
                isLastItem={false}
              />
              <NewListItem
                onClick={handleClickProfile}
                iconType={isActiveProfile ? 'icon-ProfileBold' : 'icon-ProfileLight'}
                iconColor={isActiveProfile ? primary : textPrimary}
                arrowColor={textSecondary}
                title="Perfil"
                ariaLabel="Perfil"
                id="Perfil"
                key="Perfil"
                isLastItem
              />
            </div>
          </Drawer>
        )}
      </ul>
    </div>
  )
})

const mapStateToProps = ({
  authentication, prescriptions
}: AppState) => ({
  showNewPrescriptionBadge: prescriptions.highlightHealthMenuItem,
  hasSpecialist: authentication.currentUser?.appointedSpecialists,
  insurancePlanCovered: authentication.currentUser?.healthInsurancePlanData.insurancePlanCovered,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  promptDrawer: promptDrawerAction,
  suppressDrawer: suppressDrawerAction,
  setScheduleSource: setScheduleSourceAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bottombar)
