import {
  FAILURE_FETCH_SCHEDULE_CANCELLATION_REASONS,
  FETCH_SCHEDULE_CANCELLATION_REASONS,
  PROMPT_SCHEDULE_CANCELLATION,
  SUCCESS_FETCH_SCHEDULE_CANCELLATION_REASONS,
  SUPPRESS_SCHEDULE_CANCELLATION,
  TOGGLE_SCHEDULE_CANCELLATION_REASON,
  SUCCESS_BOOKING_CANCEL,
  CONFIRM_BOOKING_CANCELLATION,
  ScheduleCancellationTypes,
  BOOKING_CANCEL_REQUESTED,
  BOOKING_CANCEL_STEP,
  FAILURE_BOOKING_CANCEL
} from './actions'
import { Reason } from '../../domain/Booking'

export type cancellationStepTypes = 'prompt' | 'confirmation' | 'closed' | 'success' | 'loading' | 'failure';

export interface FeedbackState {
  step: cancellationStepTypes;
  reasons: Array<Reason>;
  otherMotive: string;
  isFetchingReasons: boolean;
  bookingId?: string;
  isExam?: boolean;
  error?: {
    message?: string;
    title?: string;
  };
  isRequestDone?: boolean;
}

export const initialState: FeedbackState = {
  step: 'closed',
  reasons: [],
  otherMotive: '',
  isFetchingReasons: false,
  error: {
    message: '',
    title: ''
  },
  isRequestDone: false
}

export function scheduleCancellation(
  state = initialState, action: ScheduleCancellationTypes
): FeedbackState {
  switch (action.type) {
    case PROMPT_SCHEDULE_CANCELLATION:
      return {
        ...state,
        step: 'prompt',
        bookingId: action.bookingId,
        isExam: action.isExam,
        isRequestDone: false
      }
    case SUPPRESS_SCHEDULE_CANCELLATION:
      return initialState
    case FETCH_SCHEDULE_CANCELLATION_REASONS:
      return {
        ...state,
        isFetchingReasons: true,
        isRequestDone: false
      }
    case SUCCESS_FETCH_SCHEDULE_CANCELLATION_REASONS:
      return {
        ...state,
        isFetchingReasons: false,
        reasons: action.reasons,
        isRequestDone: false
      }
    case FAILURE_FETCH_SCHEDULE_CANCELLATION_REASONS:
      return {
        ...state,
        isFetchingReasons: false,
        isRequestDone: false
      }
    case TOGGLE_SCHEDULE_CANCELLATION_REASON:
      return {
        ...state,
        reasons: state.reasons.map((reason) => (
          reason.id === action.reason.id
            ? { ...reason, selected: !reason.selected }
            : { ...reason, selected: false }
        ))
      }
    case CONFIRM_BOOKING_CANCELLATION:
      return {
        ...state,
        step: 'confirmation',
        otherMotive: action.otherMotive,
        isRequestDone: false
      }
    case SUCCESS_BOOKING_CANCEL:
      return {
        ...state,
        step: 'success',
        isRequestDone: true
      }
    case BOOKING_CANCEL_REQUESTED:
      return {
        ...state,
        step: 'loading',
        isRequestDone: false
      }
    case FAILURE_BOOKING_CANCEL:
      return {
        ...state,
        step: 'failure',
        error: action.error,
        reasons: [],
        otherMotive: '',
      }
    case BOOKING_CANCEL_STEP:
      return {
        ...state,
        step: action.step
      }
    default: return state
  }
}
