import React from 'react'
import { useHistory } from 'react-router-dom'
import { RegularButton } from 'theia-web-ds'
import Modal from '../common/Modal'
import HeaderModal from '../common/HeaderModal'
import { INICIO } from '../../routes/RoutesConstants'

interface Props {
  visible: boolean;
  forceConnect: () => void;
  isLoading: boolean;
  isDuplicatedRoom: boolean;
}

function DisconnectedModal({
  visible,
  forceConnect,
  isLoading,
  isDuplicatedRoom
}: Props) {
  const history = useHistory()
  if (!visible) return null
  function goToSpecialists() {
    history.push(INICIO)
  }
  const title = isDuplicatedRoom ? (
    'Ei, você está com a videochamada aberta em outra janela'
  ) : (
    'Ei, você está com algum problema com a videochamada'
  )

  const subtitle = isDuplicatedRoom ? (
    'Clique em "Usar aqui" para fazer sua consulta nesta janela.'
  ) : (
    'Verifique sua internet e tente conectar novamente.'
  )

  const buttonText = isDuplicatedRoom ? (
    'Usar aqui'
  ) : (
    'Conectar novamente'
  )

  return (
    <Modal>
      <div className="flex flex-col text-center denied-modal">
        <HeaderModal className="mt-4 mb-4 modal__title">{title}</HeaderModal>
        <p className="text-textSecondary font-bold mb-2">{subtitle}</p>

        <RegularButton
          type="button"
          label={buttonText}
          onClick={forceConnect}
          disabled={isLoading}
          isSubmitting={isLoading}
          maxWidth="100%"
          extraClass="text-textSmall font-medium mt-6"
        />

        <RegularButton
          type="button"
          label="Fechar"
          onClick={goToSpecialists}
          disabled={isLoading}
          maxWidth="100%"
          extraClass="text-textSmall text-primaryDark border-primaryDark font-medium mt-4 ml-1 mb-6"
        />

      </div>
    </Modal>
  )
}

export default DisconnectedModal
