import * as Sentry from '@sentry/browser';

import { HttpClient } from '../../services/Http';
import { AsyncAction } from '../utils'
import { API_URL } from '../../services/api/ApiClient';

export const SUCCESS_UPDATE_BOOKING_SUGGESTION_STATUS = 'SUCCESS_UPDATE_BOOKING_SUGGESTION_STATUS'
export const REQUEST_UPDATE_BOOKING_SUGGESTION_STATUS = 'REQUEST_UPDATE_BOOKING_SUGGESTION_STATUS'
export const FAILURE_UPDATE_BOOKING_SUGGESTION_STATUS = 'FAILURE_UPDATE_BOOKING_SUGGESTION_STATUS'
export const RESET_NEED_UPDATE_WEEKS = 'RESET_NEED_UPDATE_WEEKS'

interface ResetNeedUpdateWeeksActionType {
  type: typeof RESET_NEED_UPDATE_WEEKS;
}

export interface SuccessUpdateBookingSuggestionStatusActionType {
  type: typeof SUCCESS_UPDATE_BOOKING_SUGGESTION_STATUS;
  id: string;
}

interface RequestUpdateBookingSuggestionStatusActionType {
  type: typeof REQUEST_UPDATE_BOOKING_SUGGESTION_STATUS;
}

interface FailureUpdateBookingSuggestionStatusActionType {
  type: typeof FAILURE_UPDATE_BOOKING_SUGGESTION_STATUS;
}

export type SuggestionFlowTypes = SuccessUpdateBookingSuggestionStatusActionType |
  RequestUpdateBookingSuggestionStatusActionType |
  FailureUpdateBookingSuggestionStatusActionType |
  ResetNeedUpdateWeeksActionType

export const resetNeedUpdateWeeksAction = ():
ResetNeedUpdateWeeksActionType => ({
  type: RESET_NEED_UPDATE_WEEKS,
})

export function onUpdateBookingSuggestionStatusAction(
  id: string,
  status: string,
  bookingId?: string
):
  AsyncAction<{}, { httpClient: HttpClient }> {
  return async (dispatch, _getState, { httpClient }) => {
    dispatch({
      type: REQUEST_UPDATE_BOOKING_SUGGESTION_STATUS
    })
    try {
      const url = `${API_URL}/v4/consultants/suggestion/${id}`
      let payload: {} = {
        status
      }
      if (bookingId) {
        payload = {
          ...payload,
          bookingId
        }
      }
      const response = await httpClient.put({ url, payload })
      if (response.status !== 200) {
        dispatch({
          type: FAILURE_UPDATE_BOOKING_SUGGESTION_STATUS
        })
        const responseJson = JSON.parse(response?.body)
        Sentry.captureException(new Error(`Failure updating the booking suggestion status - ${responseJson?.message}`))
      } else {
        localStorage.removeItem('bookingSuggestion')
        dispatch({
          type: SUCCESS_UPDATE_BOOKING_SUGGESTION_STATUS,
          id
        })
      }
    } catch (error) {
      dispatch({
        type: FAILURE_UPDATE_BOOKING_SUGGESTION_STATUS
      })
      Sentry.captureException(new Error(`Unexpected error updating the booking suggestion status - ${error.message}`))
    }
  }
}
