import { AddressByCep } from '../../utils/LocalAddress'
import {
  AddressActionTypes,
  ADDRESS_BY_CEP_FAILURE,
  ADDRESS_BY_CEP_REQUESTED,
  ADDRESS_BY_CEP_SUCCESS
} from './actions'

export interface AddressInfoState {
  isFetchindAddressData: boolean;
  addressFetchedFromCep?: AddressByCep;
  fullState?: string;
}

const initialAddressInfoState: AddressInfoState = {
  isFetchindAddressData: false,
  addressFetchedFromCep: undefined,
  fullState: ''
}

export function addressInfo(
  state = initialAddressInfoState,
  action: AddressActionTypes
): AddressInfoState {
  switch (action.type) {
    case ADDRESS_BY_CEP_REQUESTED: {
      return {
        ...state,
        isFetchindAddressData: true,
      }
    }
    case ADDRESS_BY_CEP_SUCCESS: {
      return {
        ...state,
        isFetchindAddressData: false,
        addressFetchedFromCep: action.addressFetchedFromCep,
        fullState: action.fullState
      }
    }
    case ADDRESS_BY_CEP_FAILURE: {
      return {
        ...state,
        isFetchindAddressData: false,
      }
    }
    default:
      return state
  }
}
