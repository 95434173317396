import React from 'react'
import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Loader, RegularButton } from 'theia-web-ds'
import CalendarClock from '../../../assets/calendar_clock.svg'
import {
  clearRescheduleFlowAction,
  reScheduleBookingAction,
} from '../../state/reschedule/actions'
import { AppState } from '../../apps/main/store'
import { LocalAddress } from '../../utils/LocalAddress'
import { LocalDate } from '../../domain/utils/Date'
import { ScheduleType } from '../../domain/Booking'
import Modal from '../common/Modal'
import CancelButton from '../common/buttons/CancelButton'
import { isPresentialType } from '../../utils/helpers'
import RescheduleDetails from './RescheduleDetails'
import './RescheduleModal.scss'
import { SpecialistCategories } from '../../domain/Specialist'

interface Props {
  categoryName?: SpecialistCategories;
  professionalName?: string;
  startDate?: LocalDate;
  availabilityId?: string;
  attendanceAddress?: LocalAddress;
  scheduleType: ScheduleType;
  oldBookingId?: string;
  isExam: boolean;
  isLoading: boolean;
  cancelReschedule: () => void;
  reScheduleBooking: (oldBookingId: string, availabilityId: string) => void;
}

function ReScheduleModal({
  categoryName,
  professionalName,
  startDate,
  availabilityId,
  attendanceAddress,
  scheduleType,
  oldBookingId,
  isExam,
  isLoading,
  cancelReschedule,
  reScheduleBooking,
}: Props) {
  function confirmReSchedule() {
    if (oldBookingId && availabilityId) {
      reScheduleBooking(oldBookingId, availabilityId)
    }
  }

  return (
    <Modal>
      <div className="reschedule-modal">
        {!isLoading ? (
          <CalendarClock className="self-center" />
        ) : (
          <div className="flex justify-center w-full">
            <div className="flex items-center">
              <Loader />
            </div>
          </div>
        )}
        <RescheduleDetails
          title="Confirmação do reagendamento"
          isPresential={isPresentialType(scheduleType)}
          professionalCategory={categoryName}
          professionalName={professionalName}
          startHourString={startDate?.formatDateToHours()}
          dayString={startDate?.formatDayAndMonthAndYear()}
          attendanceAddress={attendanceAddress?.formatToNameNumberAndComplement()}
          isExam={isExam}
        />
        <div className="reschedule-modal__buttons-wrapper">
          <RegularButton
            label="Confirmar reagendamento"
            onClick={confirmReSchedule}
            disabled={isLoading}
            maxWidth="100%"
            extraClass="mb-4"
          />
          <CancelButton onClick={cancelReschedule}>
            Cancelar
          </CancelButton>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({
  reschedule
}: AppState) => ({
  categoryName: reschedule.categoryName,
  professionalName: reschedule.professionalName,
  startDate: reschedule.startDate,
  availabilityId: reschedule.availabilityId,
  attendanceAddress: reschedule.attendanceAddress,
  scheduleType: reschedule.scheduleType,
  oldBookingId: reschedule.oldBookingId,
  isExam: reschedule.isExam,
  isLoading: reschedule.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  cancelReschedule: clearRescheduleFlowAction,
  reScheduleBooking: reScheduleBookingAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReScheduleModal)
