import React, {
  HTMLAttributes,
  forwardRef,
  useRef,
  useState
} from 'react'
import './FormInput.scss'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { IconButton } from '../IconButton/IconButton'
import { FormInputPlaceholder } from '../FormInputPlaceholder/FormInputPlaceholder'
import { Loader } from '../Loader/Loader'
import { textDisable } from '../../colors'
import { UseCombinedRefs } from '../../utils/helpers'

export interface Props extends HTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'number' | 'date' | 'checkbox' | 'password' | 'radio' | 'email' | 'tel';
  name: string;
  label?: string;
  id: string;
  autoComplete?: 'off' | 'on';
  placeholder?: string;
  isLoading?: boolean;
  value?: string;
  extraClass?: string;
  required?: boolean;
  disabled?: boolean;
  iconType? : string;
  iconSize? : string;
  maxLength?: number;
  onIconClick?: () => void;
  isPassword?: boolean;
  readOnly?: boolean;
  errorMessage?: string;
  extraClassContainer?: string;
  isSubmitting?: boolean;
  loaderColor?: string;
  append?: string;
  hasError?: boolean;
}

export const FormInput: React.FC<Props> = forwardRef(({
  type = 'text',
  label,
  name,
  maxLength,
  placeholder,
  isLoading,
  isPassword,
  autoComplete,
  id,
  value,
  extraClass,
  required,
  iconType,
  onIconClick,
  disabled,
  iconSize,
  readOnly,
  errorMessage,
  extraClassContainer,
  isSubmitting,
  loaderColor,
  append,
  hasError,
  ...props
}: Props, forwardedRef) => {
  const [showPassword, setShowPassword] = useState(false)
  const [passwordIcon, setPasswordIcon] = useState('icon-EyeLight')
  const innerRef = useRef<HTMLInputElement>(null)
  const combinedRef = UseCombinedRefs(forwardedRef, innerRef)

  const onPasswordIconClick = () => {
    if (showPassword) {
      setShowPassword(false)
      setPasswordIcon('icon-EyeLight')
    } else {
      setShowPassword(true)
      setPasswordIcon('icon-EyeOffLight')
    }
  }

  const getInputType = () => {
    if (isPassword && showPassword) {
      return 'text'
    }
    if (isPassword && !showPassword) {
      return 'password'
    }
    return type
  }

  if (isLoading) {
    return <FormInputPlaceholder />
  }

  return (
    <div className={`form-input-container ${extraClassContainer}`}>
      <div className="form-input-wrapper">
        <label htmlFor={name}>{label}</label>
        <div className="relative">
          <input
            data-testid="form-input"
            required={required}
            id={id}
            maxLength={maxLength}
            autoComplete={autoComplete || 'off'}
            className={`form-input ${extraClass} ${(hasError || errorMessage) ? 'error' : ''}`}
            type={getInputType()}
            name={name}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            ref={combinedRef}
            {...props}
          />
          {(append && !isSubmitting)
            && (
              <div className="form-input-append">
                <span className="form-input-append-text">{append}</span>
              </div>
            )}
          {isSubmitting && (
            <span className="form-input-loader">
              <Loader size={iconSize} color={disabled ? textDisable : loaderColor} />
            </span>
          )}
        </div>
        {errorMessage && (
          <ErrorMessage error={errorMessage} />
        )}
      </div>
      {((iconType || isPassword) && !isSubmitting) && (
        <IconButton
          variant="form-icon"
          iconSize={iconSize}
          iconType={isPassword ? passwordIcon : iconType}
          extraClass="form-input-icon"
          onClick={isPassword ? onPasswordIconClick : onIconClick}
        />
      )}
    </div>
  )
})
