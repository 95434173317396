import React, { useEffect, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import { RegularButton, Divider } from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import { onUpdateHealthMedicationDataInProfileAction } from '../../state/profile/actions'
import { AppDispatch } from '../../state/utils'
import './HealthDataView.scss'
import { formCheckHasOneElementsEmpty } from '../../utils/helpers'
import BooleanInput from '../common/molecules/inputs/BooleanInput'
import FormInputList from '../common/molecules/inputs/FormInputList'

type MedicationInfosForm = {
  hasUseSomeMedicine: string;
  useSomeMedicine: string[];
}

function renderMedicationInfosForm({
  values,
  setFieldValue,
  setFieldTouched,
  handleSubmit,
}: FormikProps<MedicationInfosForm>,
onGoBack: () => void,
isSubmitting: boolean) {
  const [useSomeMedicineError, setUseSomeMedicineError] = useState<{[index: number]: string}>()
  const disabledSubmit = (
    !!useSomeMedicineError
  )

  useEffect(() => {
    setUseSomeMedicineError(formCheckHasOneElementsEmpty(values.useSomeMedicine))
  }, [values.useSomeMedicine])

  function onChangeHasUseSomeMedicine(newValue: boolean) {
    setFieldValue('hasUseSomeMedicine', `${newValue}`);
    setFieldTouched('hasUseSomeMedicine', true);
    if (newValue) {
      setFieldValue('useSomeMedicine', ['']);
      setFieldTouched('useSomeMedicine', true);
    } else {
      setFieldValue('useSomeMedicine', []);
      setFieldTouched('useSomeMedicine', true);
    }
  }

  function onChangeUseSomeMedicine(newList: string[]) {
    setFieldValue('useSomeMedicine', newList);
    setFieldTouched('useSomeMedicine', true);
  }

  return (
    <form onSubmit={handleSubmit} className="health-view-drawer-form" noValidate>
      <div className="health-view-drawer-inner-form">
        <div className="health-view-individual-container">
          <BooleanInput
            label="Faz uso de alguma medicação?"
            name="hasUseSomeMedicine"
            value={values.hasUseSomeMedicine === '' ? undefined : values.hasUseSomeMedicine === 'true'}
            onChange={onChangeHasUseSomeMedicine}
          />
          {values.hasUseSomeMedicine === 'true' && (
            <FormInputList
              placeholder="* Digite qual medicação"
              ariaLabel="Digite qual medicação"
              list={values.useSomeMedicine}
              errors={useSomeMedicineError}
              onChange={onChangeUseSomeMedicine}
              required
            />
          )}
        </div>
      </div>
      <div className="health-view-drawer-footer">
        <Divider />
        <div className="health-view-drawer-button">
          <RegularButton
            onClick={onGoBack}
            label="Agora não"
            variant="text"
            extraClass="booking-details-cancel-btn"
          />
          <RegularButton
            onClick={() => handleSubmit()}
            label="Salvar"
            type="submit"
            isSubmitting={isSubmitting}
            disabled={disabledSubmit}
          />
        </div>
      </div>
    </form>
  )
}

const emptyList: string[] = []

const initialValues: MedicationInfosForm = {
  hasUseSomeMedicine: '',
  useSomeMedicine: emptyList,
}

interface Props {
  isSubmitting: boolean;
  hasUseSomeMedicine?: string;
  useSomeMedicine?: string[];
  onGoBack: () => void;
  onUpdateHealthMedicationDataInProfile: (
    name: string,
    email: string,
    phone: string,
    hasUseSomeMedicine: string,
    useSomeMedicine: string[]
  ) => void;
  name?: string;
  email?: string;
  phone?: string;
  isUpdatingProfile: boolean;
}

function MedicationDataEdit({
  onUpdateHealthMedicationDataInProfile,
  isSubmitting,
  hasUseSomeMedicine,
  useSomeMedicine,
  onGoBack,
  name,
  email,
  phone,
  isUpdatingProfile
}: Props) {
  const [alreadySendNewInfos, setAlreadySendNewInfos] = useState(false)

  useEffect(() => {
    if (!isUpdatingProfile && alreadySendNewInfos) {
      setAlreadySendNewInfos(false)
      onGoBack()
    }
  }, [isUpdatingProfile])

  initialValues.hasUseSomeMedicine = hasUseSomeMedicine
      || initialValues.hasUseSomeMedicine
  initialValues.useSomeMedicine = useSomeMedicine || initialValues.useSomeMedicine
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(_value: MedicationInfosForm) => {
        if (name && email && phone) {
          setAlreadySendNewInfos(true)
          onUpdateHealthMedicationDataInProfile(
            name,
            email,
            phone,
            _value.hasUseSomeMedicine,
            _value.useSomeMedicine
          )
        }
      }}
    >
      {(props) => renderMedicationInfosForm(
        props,
        onGoBack,
        isSubmitting
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  profile, authentication
}: AppState) => ({
  hasUseSomeMedicine: profile.profile?.hasUseSomeMedicine,
  useSomeMedicine: profile.profile?.useSomeMedicine,
  name: authentication.currentUser?.name,
  email: authentication.currentUser?.email,
  phone: authentication.currentUser?.phone,
  isSubmitting: profile.isUpdatingProfile,
  isUpdatingProfile: profile.isUpdatingProfile
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onUpdateHealthMedicationDataInProfile: (
    name: string,
    email: string,
    phone: string,
    hasUseSomeMedicine: string,
    useSomeMedicine: string[]
  ) => {
    dispatch(onUpdateHealthMedicationDataInProfileAction(
      name,
      email,
      phone,
      hasUseSomeMedicine,
      useSomeMedicine
    ))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicationDataEdit)
