import React, { useState } from 'react'
import SpecialistIcon from '../../../../assets/specialist-icon.png'
import UserIcon from '../../../../assets/face-icon.png'
import UsgIcon from '../../../../assets/ultrassom.png'
import './ProfilePicture.scss'

interface ProfilePictureProps {
  pictureUrl?: string | null;
  pictureSize?: number;
  picture?: File;
  className?: string;
  isLoading?: boolean;
  extraClass?: string;
  isSpecialist?: boolean;
  isUsg?: boolean;
}

export default function ProfilePicture({
  pictureUrl,
  pictureSize = 24,
  className,
  picture,
  isLoading,
  extraClass,
  isSpecialist,
  isUsg
}: ProfilePictureProps) {
  const [error, setError] = useState(false)
  const realPictureUrl = !picture ? pictureUrl : URL.createObjectURL(picture)

  if (isLoading) {
    return (
      <div
        className={`profile-picture-loading ${extraClass}`}
        style={{
          width: pictureSize,
          height: pictureSize
        }}
      />
    )
  }

  let iconType = UserIcon
  if (isSpecialist) {
    iconType = SpecialistIcon
  } else if (isUsg) {
    iconType = UsgIcon
  }

  return (
    <div>
      {(error || (!pictureUrl && !picture))
        ? (
          <div className={`profile-picture-container mr-2 ${className}`}>
            <img src={iconType} width={pictureSize} height={pictureSize} alt="" />
          </div>
        ) : (
          <img
            width={pictureSize}
            height={pictureSize}
            alt=""
            src={realPictureUrl || iconType}
            onError={() => setError(true)}
            className={`profile-picture-container ${className}`}
            style={{
              backgroundImage: `url(${realPictureUrl})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: pictureSize,
              height: pictureSize,
            }}
          />
        )}
    </div>
  )
}
