import React from 'react'
import './CardPost.scss'

export interface Props {
  category: string;
  title: string;
  featureImage?: string;
  description: string;
  fixedWidth?: boolean;
  isLoading?: boolean;
}

export function CardPost({
  category,
  title,
  description,
  featureImage,
  fixedWidth = false,
  isLoading
}: Props) {
  const imageContent = fixedWidth ? (
    <div className="imageContainer">
      <div className="imageCenterer">
        <img
          src={featureImage}
          alt=""
          height="110px"
          style={{
            height: '110px'
          }}
        />
      </div>
    </div>
  ) : (
    <div className="post-feature-image">
      <img src={featureImage} alt="" />
    </div>
  )

  return (
    <div
      className={`post-wrapper
        ${fixedWidth ? 'post-wrapper-with-width-fixed' : ''}
        ${isLoading ? 'skeleton' : ''}
      `}
    >
      {!isLoading && (
        <>
          {featureImage && (
            imageContent
          )}
          <div className="post-content">
            <p className="post-category">
              {category}
            </p>
            <h1 className="post-title">
              {title}
            </h1>
            <p className="post-description">
              {description}
            </p>
          </div>
        </>
      )}
    </div>
  )
}
