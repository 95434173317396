import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  NewListItem,
  RegularButton,
  Divider,
  Loader
} from 'theia-web-ds'
import { useHistory } from 'react-router-dom'
import { AppState } from '../../../apps/main/store'
import { Prescriptions } from '../../../domain/Prescriptions'
import { getPrescriptionsInformationAction } from '../../../state/prescriptions/actions'
import { AppDispatch } from '../../../state/utils'
import EmptyView from '../../common/EmptyView'
import NoExams from '../../../../assets/NoExams.svg'
import Header from '../../common/molecules/Header'
import BackButton from '../../common/buttons/BackButton'
import {
  bgCanvas, textSecondary
} from '../../../color'
import DefaultPicture from '../../../../assets/face-icon.png'
import { formatToDate } from '../../../utils/helpers'
import ImageIconBadge from '../../common/molecules/ImageIconBadge'
import { onUpdateBookingSuggestionAction } from '../../../state/scheduleSuggestion/actions'
import { eventPage, eventTrack } from '../../../../eventGenerate'
import './PrescriptionsView.scss'
import { SAUDE } from '../../../routes/RoutesConstants'

interface Props {
  prescriptions: Prescriptions[];
  getPrescriptionsInformation: () => void;
  isLoading?: boolean;
  onUpdateScheduleSuggestion: (
    type: string,
    bookingIds?: Array<string>,
    prescriptionId?: string
  ) => void;
  hasDownloaded: boolean;
}

function PrescriptionsView({
  prescriptions,
  getPrescriptionsInformation,
  isLoading,
  onUpdateScheduleSuggestion,
  hasDownloaded
}: Props) {
  useEffect(() => {
    getPrescriptionsInformation()
    eventPage('saude', 'Pedidos Médicos')
  }, [])

  const history = useHistory()

  const goBackToSaude = () => {
    history.push(SAUDE)
  }

  useEffect(() => {
    if (hasDownloaded) {
      getPrescriptionsInformation()
    }
  }, [hasDownloaded])

  const downloadPrescription = (
    prescription: string,
    bookingId: Array<string>,
    prescriptionId: string
  ) => {
    window.open(prescription, '_blank')
    onUpdateScheduleSuggestion('EXAM_REQUEST', bookingId, prescriptionId)
    eventTrack('Baixou Prescrição')
  }

  const prescriptionsOrderedByDate = prescriptions && prescriptions.length > 0
    ? prescriptions.sort((a, b) => b.created - a.created)
    : []

  const prescriptionsOrderedByDateAndUnseen = prescriptionsOrderedByDate.length > 0
    ? prescriptionsOrderedByDate.sort((a, b) => Number(a.prescriptionDownloaded)
      - Number(b.prescriptionDownloaded))
    : []

  if (isLoading) {
    return (
      <div className="flex justify-center h-screen w-full bg-bgCanvas">
        <div className="flex items-center h-full">
          <Loader />
        </div>
      </div>
    )
  }

  if (prescriptions) {
    if (prescriptions.length === 0) {
      return (
        <div className="w-full pb-6">
          <div className="flex items-center bg-bgCanvas top-0 z-10 h-20">
            <Header
              title="Pedidos médicos"
              goBack={goBackToSaude}
            />
          </div>
          <Divider />
          <div className="booking-list-view mt-10">
            <EmptyView
              title="Você ainda não possui nenhum pedido médico"
              subtitle="Todos os seus pedidos preescritos por nossos especialistas vão estar aqui."
              extraActionButton={goBackToSaude}
              extraActionText="Voltar pra saúde"
              icon={<NoExams />}
              hasGoToSpecialist={false}
            />
          </div>
        </div>
      )
    }
  }

  return (
    <div className="w-full pb-6">
      <div className="flex items-center bg-bgCanvas top-0 z-10 h-20">
        <BackButton to={SAUDE} extraClass="back-button-svg" text="" />
        <Header
          title="Pedidos médicos"
        />
      </div>
      <Divider />
      <div className="prescription-info px-4">
        {prescriptionsOrderedByDateAndUnseen.map((prescription) => (
          <NewListItem
            imageIconBadgeComponent={(
              <ImageIconBadge
                iconColor={prescription.prescriptionDownloaded ? textSecondary : bgCanvas}
                iconType="icon-ExamLight"
                extraBadgeContainerClass={`absolute rounded-full ${prescription.prescriptionDownloaded ? 'bg-divider' : 'bg-secondary'} exam-icon-container text-center bottom-0`}
                iconSize="14px"
                picture={
                  <img src={prescription.professionalPictureUrl || DefaultPicture} alt="foto do profissional" width={40} height={40} />
                  }
              />
              )}
            title="Pedido médico"
            ariaLabel="medical-request"
            caption={`Enviado dia ${formatToDate(prescription.created)}`}
            id={prescription.id}
            key={prescription.id}
            extraComponent={(
              <div className="download-button-container">
                <RegularButton
                  variant="text"
                  iconType="icon-DownloadLight"
                  iconSize="24px"
                  onClick={() => downloadPrescription(
                    prescription.pdfUrl,
                    [prescription.bookingId],
                    prescription.id
                  )}
                />
              </div>
            )}
          />
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = ({ prescriptions, scheduleSuggestion }: AppState) => ({
  prescriptions: prescriptions.prescriptions,
  isLoading: prescriptions.isLoading,
  hasDownloaded: scheduleSuggestion.hasDownloaded
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getPrescriptionsInformation: () => {
    dispatch(getPrescriptionsInformationAction())
  },
  onUpdateScheduleSuggestion:
  (type: string, bookingIds?: Array<string>, prescriptionId?: string) => {
    dispatch(onUpdateBookingSuggestionAction(type, bookingIds, undefined, prescriptionId))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrescriptionsView)
