import React from 'react'
import { NewListItem, Props as NewListItemProps } from '../NewListItem/NewListItem'
import './ListItemCard.scss'

export interface Props extends NewListItemProps {
  extraClassNameContainer?: string;
  children?: React.ReactNode;
}

export function ListItemCard({
  extraClassNameContainer,
  children,
  ...props
}: Props) {
  return (
    <div
      className={`list-item-card ${extraClassNameContainer}`}
      data-testid="list-item-card"
    >
      <NewListItem
        {...props}
      />
      {children}
    </div>
  )
}
