import { Address } from '../domain/utils/Address'

export type LocalAddressType = {
  state: string;
  stateAbbreviation: string;
  city: string;
  district: string;
  name: string;
  number: string;
  complement: string;
  postalCode: string;
}

export type AddressByCep = {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  siafi: string;
  uf: string;
  fullState: string;
  erro?: string;
}

export class LocalAddress implements Address {
  protected _state: string;

  protected _stateAbbreviation: string;

  protected _city: string;

  protected _district: string;

  protected _name: string;

  protected _number: string;

  protected _complement: string;

  protected _postalCode: string;

  constructor(json: LocalAddressType) {
    this._state = json.state
    this._stateAbbreviation = json.stateAbbreviation
    this._city = json.city
    this._district = json.district
    this._name = json.name
    this._number = json.number
    this._complement = json.complement
    this._postalCode = json.postalCode
  }

  get state(): string {
    return this._state
  }

  set state(newState: string) {
    this._state = newState
  }

  get stateAbbreviation(): string {
    return this._stateAbbreviation
  }

  get city(): string {
    return this._city
  }

  get district(): string {
    return this._district
  }

  get name(): string {
    return this._name
  }

  get number(): string {
    return this._number
  }

  get complement(): string {
    return this._complement
  }

  get postalCode(): string {
    return this._postalCode
  }

  set postalCode(newPostalCode: string) {
    this._postalCode = newPostalCode
  }

  toString(): string {
    return (
      `${this._name} ${this._number}, ${this._complement},`
      + ` ${this._district} - ${this._city} - ${this._postalCode}`
    )
  }

  formatToNameNumber(): string {
    return (this._name || this._number) ? (
      `${this._name}, ${this._number}`
    ) : ''
  }

  formatToNameNumberAndComplement(): string {
    const complementString = this._complement && `, ${this._complement}`
    return (this._name || this._number || complementString) ? (
      `${this._name}, ${this._number}${complementString}`
    ) : ''
  }

  formatToDistrictAndCity(): string {
    return (this._district || this._city) ? (
      `${this._district}, ${this._city}`
    ) : ''
  }
}
