import { TheiaError } from '../../domain/errors/TheiaError'
import {
  PROMPT_DEFAULT_ERROR,
  SUPPRESS_DEFAULT_ERROR,
  ErrorTypes,
} from './actions'

export interface DefaultErrorState {
  visible: boolean;
  error?: TheiaError;
}

export const initialState: DefaultErrorState = {
  visible: false,
  error: undefined
}

export function defaultError(state = initialState, action: ErrorTypes): DefaultErrorState {
  switch (action.type) {
    case PROMPT_DEFAULT_ERROR:
      return {
        visible: true,
        error: action.error
      }
    case SUPPRESS_DEFAULT_ERROR:
      return {
        visible: false,
        error: undefined
      }
    default: return state
  }
}
