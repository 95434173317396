import React from 'react'
import { useField } from 'formik'
import { Divider, RadioButton } from 'theia-web-ds'
import './RadioGroup.scss'

export type RadioItem = {
  id: string;
  value: string;
  checked?: boolean;
}

type ExtraInfosByIndex = {
  [index: number]: React.ReactNode;
}

interface Props {
  label?: string;
  name: string;
  choosenValue: string;
  list: RadioItem[];
  disabled?: boolean;
  extraComponents?: ExtraInfosByIndex;
  withDivider?: boolean;
  hideLabel?: boolean;
}

export default function RadioGroup({
  list,
  label,
  name,
  choosenValue,
  extraComponents,
  disabled,
  withDivider,
  hideLabel,
}: Props) {
  const [field] = useField({ name, type: 'radio' })
  const { onBlur, onChange } = field

  return (
    <div role="radiogroup" aria-labelledby="label-radio-group">
      <fieldset aria-label={hideLabel ? label : undefined}>
        {!hideLabel && (
          <legend id="label-radio-group" className="label-medium-radio-group-list">
            {label}
          </legend>
        )}
        <div className="radio-group-list">
          {list.map((item, index) => (
            <div key={item.id}>
              <RadioButton
                id={item.id}
                name={name}
                defaultChecked={item.checked}
                label={item.value}
                disabled={disabled}
                value={item.id}
                onBlur={onBlur}
                onChange={onChange}
                inputRef={undefined}
              />
              {(choosenValue === item.id || item.checked)
                && extraComponents && extraComponents[index] && (
                <div className="radio-group-extra-component">
                  {extraComponents[index]}
                </div>
              )}
              {withDivider && (index < (list.length - 1)) && (
                <Divider className="radio-group-divider" />
              )}
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  )
}
