import thunk from 'redux-thunk'
import { applyMiddleware, createStore, combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { composeWithDevTools } from 'redux-devtools-extension'
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';
import { chatVideo } from '../../state/chatVideo/reducers'
import { ChatVideoServiceFactory } from '../../domain/ChatVideo'
import { CallServiceFactory } from '../../domain/Call'
import { BookingViewService } from '../../domain/Booking'
import { booking, browserError, promptPermission } from '../../state/booking/reducers'
import { isProductionEnv } from '../../utils/helpers'

export const browserHistory = createBrowserHistory()

const rootReducer = combineReducers({
  router: connectRouter(browserHistory),
  booking,
  browserError,
  promptPermission,
  chatVideo
})

export default function initStore(args: {
  callServiceFactory: CallServiceFactory;
  bookingViewService: BookingViewService;
  chatVideoServiceFactory: ChatVideoServiceFactory;
}) {
  const thunkMiddleware = thunk.withExtraArgument(args)
  const stateTransformer = (state: any) => ({
    ...state,
    booking: {
      ...state.booking,
      remoteUserName: '******',
      consultantName: '******',
      chatToken: '******',
    },
    chatVideo: {
      ...state.chatVideo,
      messages: '******',
      chatToken: '******',
    }
  })
  const middlewares = isProductionEnv()
    ? applyMiddleware(
      createSentryMiddleware(Sentry, {
        stateTransformer
      }),
      thunkMiddleware
    )
    : applyMiddleware(thunkMiddleware)

  return createStore(
    rootReducer,
    composeWithDevTools(middlewares)
  )
}

export type AppState = ReturnType<typeof rootReducer>
