import React from 'react'
import { Store } from 'redux'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ToastContainer } from 'react-toastify'
import { useAdjustableWindowHeight } from '../../hooks/AdjustableWindowHeight'
import { browserHistory, AppState } from './store'
import CookiesBanner from '../../components/CookiesBanner'
import DefaultErrorModal from '../../components/common/DefaultErrorModal'
import ScrollToTop from '../../components/common/ScrollToTop'
import App from '../../components/App'
import '../../index.scss'

interface Props {
  store: Store<AppState>;
}

function MainApp({ store }: Props) {
  useAdjustableWindowHeight()

  return (
    <Provider store={store}>
      <CookiesBanner />
      <ConnectedRouter history={browserHistory}>
        <DefaultErrorModal />
        <ScrollToTop />
        <ToastContainer />
        <App />
      </ConnectedRouter>
    </Provider>
  )
}

export default MainApp
