import React, {
  useState, useEffect, useRef
} from 'react'
import { connect } from 'react-redux'
import { Message as MessageInterface } from '../../domain/ChatVideo'
import './TextMessage.scss'
import { AppState } from '../../apps/main/store'

interface TextMessageProps {
  message: MessageInterface;
  firstOfSeries: boolean;
  currentUser?: string;
  consultantName?: string;
}

function TextMessage({
  message, firstOfSeries, currentUser, consultantName
}: TextMessageProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const textSpan = useRef<HTMLSpanElement>(null)
  function getAllText() {
    setIsCollapsed(false)
  }
  useEffect(() => {
    const { current } = textSpan
    if (!current) return

    setIsCollapsed(current.clientHeight >= 315)
  }, [textSpan])
  return (
    <div className={`flex message
      ${message.isSender ? ' message--sender' : ' message--other'}
      ${firstOfSeries ? ' message--first-of-series' : ''}`}
    >
      <div className="message__body">
        {currentUser
          ? <p className="message__specialist-name">{message.author}</p>
          : <p className="message__specialist-name">{message.isSender ? message.author : consultantName}</p>}
        <p className="message__content">
          <span className={isCollapsed ? 'message__text--collapsed' : 'message__text'} ref={textSpan}>
            {message.text}
          </span>
        </p>
        {isCollapsed
          && <button type="button" className="message__link" onClick={getAllText}>Ler tudo</button>}
        <p className="message__timestamp">{message.dateCreated.formatDateToHours()}</p>
      </div>
    </div>
  )
}

const mapStateToProps = ({ booking, authentication }: AppState) => {
  let currentUser
  if (authentication) {
    currentUser = authentication.currentUser?.name
  }
  return {
    currentUser,
    consultantName: booking.consultantName
  }
}

export default connect(
  mapStateToProps,
  null
)(TextMessage)
