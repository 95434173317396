import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../../apps/main/store'
import { AppDispatch } from '../../../state/utils'
import CalendarOk from '../../../../assets/calendar_ok.svg'
import {
  suppressScheduleCancellationAction
} from '../../../state/scheduleCancellation/actions'
import { updateCurrentUserInfosAction } from '../../../state/authentication/main/actions'
import { eventTrack } from '../../../../eventGenerate'
import Modal from '../../common/Modal'
import CancelButton from '../../common/buttons/CancelButton'
import { getCanceledText } from '../../../utils/helpers'

interface Props {
  closeModal: (step: any) => void;
  step: any;
  isRequestDone?: boolean;
  onUpdateCurrentUser: () => void;
  isExam?: boolean;
}

function ScheduleCancellationReasonModal({
  closeModal,
  step,
  isRequestDone,
  onUpdateCurrentUser,
  isExam
}: Props) {
  useEffect(() => {
    if (isRequestDone) {
      onUpdateCurrentUser()
    }
    if (isExam) {
      eventTrack('USG - Cancelou agendamento Ultrassom')
    }
  }, [isRequestDone])

  return (
    <Modal>
      <div className="schedule-cancellation-success-modal flex flex-col text-center">
        <div className="self-center mb-4 ml-4">
          <CalendarOk />
        </div>
        <h1 className="modal__title">{isExam ? 'Exame cancelado!' : 'Consulta cancelada!'}</h1>
        <p className="modal__info pt-2">
          {getCanceledText(isExam)} com sucesso.
        </p>
        <CancelButton onClick={() => closeModal(step)}>Fechar</CancelButton>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ scheduleCancellation }: AppState) => ({
  step: scheduleCancellation.step,
  isRequestDone: scheduleCancellation.isRequestDone,
  isExam: scheduleCancellation.isExam
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  closeModal: (step: any) => {
    dispatch(suppressScheduleCancellationAction(step))
  },
  onUpdateCurrentUser: () => { dispatch(updateCurrentUserInfosAction()) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleCancellationReasonModal)
