import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Alert,
  RegularButton,
  ListItemCard,
  Loader,
  ButtonOptions,
  Divider
} from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import {
  ProfileForm,
} from '../../domain/Profile'
import LocationImg from '../../../assets/Location.svg'
import ProfileImg from '../../../assets/Name-Profile.svg'
import { AppState } from '../../apps/main/store'
import {
  fetchProfileAction,
  onDeletePictureProfileAction,
  onUploadPictureProfileAction
} from '../../state/profile/actions'
import {
  clearForceUpdateCurrentUserAction,
  clearLogoutFailedAction,
  logoutAction,
  updateCurrentUserInfosAction,
} from '../../state/authentication/main/actions'
import { User } from '../../domain/User'
import Header from '../common/molecules/Header'
import ProfilePicture from '../common/molecules/ProfilePicture'
import EditImageWithCrop from './EditImageWithCrop'
import {
  bgBox,
  error,
  primary,
  success,
  textSecondary
} from '../../color'
import HealthPlanProfileCard from './healthplan/HealthPlanProfileCard'
import PaymentCardProfile from './payment/PaymentCardProfile'
import { eventPage, resetUser } from '../../../eventGenerate'
import UserInfo from './UserInfo'
import './Profile.scss'
import { EDIT_ADDRESS, EDIT_PROFILE } from '../../routes/RoutesConstants'

interface Props {
  logout: () => void;
  currentUser?: User;
  fetchProfile: () => void;
  forceFetchProfile: boolean;
  isFetchingProfile: boolean;
  profile?: ProfileForm;
  isLogoutFailed: boolean;
  clearLogoutFailed: () => void;
  onUploadPictureProfile: (file: File) => void;
  onDeletePictureProfile: (file: string | null) => void;
  isUploadingPictureProfile: boolean;
  errorUploadingPictureProfile: boolean;
  isDeletingPictureProfile: boolean;
  errorDeletingPictureProfile: boolean;
  isFetchingUser: boolean;
  isPictureProfileUploaded: boolean;
  isProfilePictureDeleted: boolean;
  updateCurrentUserInfos: () => void;
  clearForceUpdateCurrentUser: () => void;
  forceUpdateCurrentUser: boolean;
}

interface LocationProps {
  isFromFeedbackChangePlan: boolean;
  hasPreviousInsurancePlan: boolean;
  isFromDelete: boolean;
}

function Profile({
  logout,
  currentUser,
  fetchProfile,
  profile,
  isFetchingProfile,
  forceFetchProfile,
  isLogoutFailed,
  clearLogoutFailed,
  onUploadPictureProfile,
  onDeletePictureProfile,
  isUploadingPictureProfile,
  errorUploadingPictureProfile,
  isDeletingPictureProfile,
  errorDeletingPictureProfile,
  isFetchingUser,
  isPictureProfileUploaded,
  updateCurrentUserInfos,
  clearForceUpdateCurrentUser,
  forceUpdateCurrentUser,
  isProfilePictureDeleted
}: Props) {
  const [buttonDrawerIsVisible, setButtonDrawerIsVisible] = useState(false)
  const [isCropOpen, setIsCropOpen] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const location = useLocation().state as LocationProps
  const whereFrom = useLocation().state as any[] || []
  const isFromFeedbackChangePlan = location?.isFromFeedbackChangePlan
  const isFromDelete = location?.isFromDelete
  const hasPreviousInsurancePlan = location?.hasPreviousInsurancePlan
  const [alertFromFeedbackMessage, setAlertFromFeedbackMessage] = useState('')
  const [successAlertMessage, setSuccessAlertMessage] = useState('')
  const [alertFromDelete, setAlertFromDelete] = useState('')
  const [isFromDeleteCardPayment, setIsFromDeleteCardPayment] = useState(false)
  const [isFromUpdateCardPayment, setIsFromUpdateCardPayment] = useState(false)
  const [alertFromUpdateCardPaymentMsg, setAlertFromUpdateCardPaymentMsg] = useState('')

  const history = useHistory()

  useEffect(() => {
    eventPage('perfil', 'Perfil')
    if (isFromFeedbackChangePlan && !hasPreviousInsurancePlan) {
      setAlertFromFeedbackMessage('Tudo certo! Seu plano de saúde foi adicionado com sucesso.')
    } else if (isFromFeedbackChangePlan && hasPreviousInsurancePlan) {
      setAlertFromFeedbackMessage('Tudo certo! Seu plano de saúde foi atualizado com sucesso.')
    }
  }, [])

  useEffect(() => {
    if (whereFrom[0] === 'isFromDeleteCardPayment') {
      setIsFromDeleteCardPayment(true)
      setIsFromUpdateCardPayment(false)
    }
    if (whereFrom[0] === 'isFromUpdateCardPayment') {
      setIsFromUpdateCardPayment(true)
      setIsFromDeleteCardPayment(false)
      if (whereFrom[1] === true) {
        setAlertFromUpdateCardPaymentMsg('Cartão alterado com sucesso!')
      } else setAlertFromUpdateCardPaymentMsg('Cartão adicionado com sucesso!')
    } else if (isFromDelete) {
      setAlertFromDelete('Plano de saúde excluído com sucesso')
    }
  }, [whereFrom])

  useEffect(() => {
    if (isPictureProfileUploaded) {
      setShowSuccessAlert(true)
      setSuccessAlertMessage('Foto atualizada com sucesso!')
    } else {
      setShowSuccessAlert(false)
    }
  }, [isPictureProfileUploaded])

  useEffect(() => {
    if (isProfilePictureDeleted) {
      setShowSuccessAlert(true)
      setSuccessAlertMessage('Foto deletada com sucesso!')
    } else {
      setShowSuccessAlert(false)
    }
  }, [isProfilePictureDeleted])

  const closeButtonDrawer = () => {
    setButtonDrawerIsVisible(false)
  }

  const openButtonDrawer = () => {
    setButtonDrawerIsVisible(true)
  }

  useEffect(() => {
    if ((!isFetchingProfile && !profile)
    || (!isFetchingProfile && forceFetchProfile)
    ) {
      fetchProfile()
    }
  }, [forceFetchProfile])

  useEffect(() => {
    if (forceUpdateCurrentUser && !isFetchingUser) {
      updateCurrentUserInfos()
      clearForceUpdateCurrentUser()
    }
  }, [forceUpdateCurrentUser])

  useEffect(() => {
    if (isLogoutFailed) {
      setTimeout(() => clearLogoutFailed(), 8000)
    }
  }, [isLogoutFailed])

  function onLogout() {
    logout()
    resetUser()
  }

  if (!currentUser) return null

  if (isFetchingProfile || isFetchingUser) {
    return (
      <div className="flex justify-center h-screen w-full">
        <div className="flex items-center h-full">
          <Loader />
        </div>
      </div>
    )
  }

  const onEditProfile = () => {
    history.push(EDIT_PROFILE)
  }

  const onEditAddress = () => {
    history.push(EDIT_ADDRESS)
  }

  const hideButtonModal = () => {
    setIsCropOpen(!isCropOpen)
  }

  const closeButtonModal = () => {
    setIsCropOpen(false)
    setButtonDrawerIsVisible(false)
  }

  const deleteProfilePicture = () => {
    onDeletePictureProfile(currentUser.pictureUrl)
    closeButtonModal()
  }

  const fullAddress = `${profile?.addressName}${profile?.number !== '' ? `, ${profile?.number}` : ''}`

  return (
    <>
      {isUploadingPictureProfile && (
      <Alert
        iconType="icon-LoadingLight"
        isBottom
        message="Atualizando foto..."
        hasCloseButton={false}
        extraClass="alert-profile-picture"
        iconColor={bgBox}
      />
      )}
      {isDeletingPictureProfile && (
      <Alert
        iconType="icon-LoadingLight"
        isBottom
        message="Deletando foto..."
        hasCloseButton={false}
        extraClass="alert-profile-picture"
        iconColor={bgBox}
      />
      )}
      {showSuccessAlert && (
        <Alert
          iconType="icon-TickRoundLight"
          isBottom
          message={successAlertMessage}
          extraClass="alert-profile-picture"
          iconColor={success}
          timer={5000}
          hasCloseButton={false}
        />
      )}
      {(errorUploadingPictureProfile || errorDeletingPictureProfile) && (
        <Alert
          iconType="icon-DangerCircleLight"
          isBottom
          message="Ops! Tivemos um problema para atualizar a sua foto. Tente novamente."
          extraClass="alert-profile-picture"
          iconColor={error}
          timer={5000}
          hasCloseButton={false}
        />
      )}
      {isFromFeedbackChangePlan && (
        <Alert
          iconType="icon-TickRoundLight"
          isBottom
          message={alertFromFeedbackMessage}
          extraClass="alert-profile-picture"
          iconColor={success}
          timer={5000}
          hasCloseButton={false}
        />
      )}
      {isFromDelete && (
        <Alert
          iconType="icon-TickRoundLight"
          isBottom
          message={alertFromDelete}
          extraClass="alert-profile-picture"
          iconColor={success}
          timer={5000}
          hasCloseButton={false}
        />
      )}
      {isFromDeleteCardPayment && (
        <Alert
          iconType="icon-TickRoundLight"
          isBottom
          message="Cartão excluido com sucesso!"
          extraClass="alert-profile-picture"
          iconColor={success}
          timer={5000}
          hasCloseButton={false}
        />
      )}
      {isFromUpdateCardPayment && (
        <Alert
          iconType="icon-TickRoundLight"
          isBottom
          message={alertFromUpdateCardPaymentMsg}
          extraClass="alert-profile-picture"
          iconColor={success}
          timer={5000}
          hasCloseButton={false}
        />
      )}
      <div className="w-full pb-6 profile-view">
        <Header
          title="Perfil"
        />
        <div className="profile-view-grid-container">
          <div className="profile-view-item-a">
            <div className="profile-card-container-item-a mb-4">
              <UserInfo
                profileImage={currentUser.pictureUrl}
                profileEmail={currentUser.email || ''}
                profileName={currentUser.name || ''}
                isLoading={isUploadingPictureProfile || isDeletingPictureProfile}
                isFetchingProfile={isFetchingProfile}
              />
              <div className="flex mt-5">
                {currentUser.pictureUrl === null && (
                <EditImageWithCrop
                  id="photo"
                  onUpload={onUploadPictureProfile}
                  buttonLabel="Adicionar foto"
                  hideButtonModal={hideButtonModal}
                  closeButtonModal={closeButtonModal}
                  variant="subtle"
                  className="mb-4"
                />
                )}
                {currentUser.pictureUrl !== null && (
                <ButtonOptions
                  buttonToAnchor={(
                    <RegularButton variant="subtle" label="Trocar foto" extraClass="button-photo" onClick={openButtonDrawer} />
                )}
                  visible={buttonDrawerIsVisible}
                  onClose={closeButtonDrawer}
                  headerText="Opções de ações para a edição de fotos"
                  isFromCrop={isCropOpen}
                  extraClass="btn-group-photo-edit"
                >
                  <EditImageWithCrop
                    id="photo"
                    onUpload={onUploadPictureProfile}
                    buttonLabel="Carregar foto"
                    isFromModal
                    hideButtonModal={hideButtonModal}
                    closeButtonModal={closeButtonModal}
                    iconColor={textSecondary}
                  />
                  <ListItemCard
                    title="Deletar foto atual"
                    ariaLabel="Deletar foto atual"
                    onClick={deleteProfilePicture}
                    iconType="icon-TrashLight"
                    iconColor={error}
                    arrowColor={textSecondary}
                    extraClassNameContainer="item-from-btn-group"
                    extraClassClickableContainer="last-item-border-radius"
                    isLastItem
                  />
                </ButtonOptions>
                )}
                <RegularButton variant="subtle" label="Editar dados" extraClass="button-photo ml-2" onClick={onEditProfile} />
              </div>
            </div>
            <Divider className="mb-6 mt-4 lg:hidden" />
            <h1 className="text-fontSmall text-mediumDarkGreyColor mx-4 lg:mx-0">Dados do plano de saúde</h1>
            <HealthPlanProfileCard
              isFetchingProfile={isFetchingProfile}
              currentUser={currentUser}
            />
            <h1 className="text-fontSmall text-mediumDarkGreyColor mx-4 lg:mx-0">Minha conta</h1>
            <ListItemCard
              extraClassNameContainer="profile-card personal-data list-item-card-no-btn"
              extraClassClickableContainer="list-item-container-no-btn"
              onClick={onEditProfile}
              picture={<span className="profile-icon"><ProfileImg /></span>}
              iconColor={primary}
              caption={currentUser.document === null ? '' : currentUser.name}
              title="Dados pessoais"
              ariaLabel="edit-personal-data"
              alertMessage={currentUser.document === null ? 'Completar dados' : ''}
              isLastItem
              isLoading={isFetchingProfile}
              isItemFromList={false}
              key="edit-profile"
            />
            <ListItemCard
              extraClassNameContainer="profile-card address list-item-card-no-btn"
              extraClassClickableContainer="list-item-container-no-btn"
              onClick={onEditAddress}
              picture={<span className="profile-icon"><LocationImg /></span>}
              iconColor={primary}
              caption={profile?.addressName ? fullAddress : ''}
              title="Endereço"
              ariaLabel="edit-address"
              alertMessage={profile?.address === null ? 'Completar dados' : ''}
              isLastItem
              isLoading={isFetchingProfile}
              isItemFromList={false}
              key="edit-address"
            />
            <PaymentCardProfile />
          </div>
          <div className="profile-view-item-b">
            <div className="flex-none mt-8 mb-5">
              <div className="profile-container p-4">
                <div className="profile-data">
                  <ProfilePicture
                    pictureUrl={currentUser.pictureUrl}
                    pictureSize={94}
                    isLoading={isUploadingPictureProfile || isDeletingPictureProfile}
                    className="mb-4"
                    extraClass="mb-4"
                  />
                  {isFetchingProfile ? (
                    <div className="user-name-loading" />
                  ) : (
                    <>
                      <p className="text-fontSmall text-textPrimary">{currentUser.name || ''}</p>
                      <p className="text-fontDefault text-textDisable mb-4">{currentUser.email || ''}</p>
                    </>
                  )}
                </div>
                {currentUser.pictureUrl === null && (
                  <EditImageWithCrop
                    id="photo"
                    onUpload={onUploadPictureProfile}
                    buttonLabel="Adicionar foto"
                    hideButtonModal={hideButtonModal}
                    closeButtonModal={closeButtonModal}
                    iconColor={textSecondary}
                    variant="subtle"
                    className="mb-4"
                  />
                )}
                {currentUser.pictureUrl !== null && (
                <ButtonOptions
                  buttonToAnchor={(
                    <RegularButton variant="subtle" label="Trocar foto" extraClass="button-photo" onClick={openButtonDrawer} />
                )}
                  visible={buttonDrawerIsVisible}
                  onClose={closeButtonDrawer}
                  headerText="Opções de ações para a edição de foto"
                  isFromCrop={isCropOpen}
                  extraClass="new-anchor-group"
                >
                  <EditImageWithCrop
                    id="photo"
                    onUpload={onUploadPictureProfile}
                    buttonLabel="Carregar foto"
                    isFromModal
                    hideButtonModal={hideButtonModal}
                    closeButtonModal={closeButtonModal}
                    arrowColor={textSecondary}
                  />
                  <ListItemCard
                    title="Deletar foto atual"
                    ariaLabel="Deletar foto atual"
                    onClick={deleteProfilePicture}
                    iconType="icon-TrashLight"
                    iconColor={error}
                    arrowColor={textSecondary}
                    extraClassNameContainer="item-from-btn-group"
                    extraClassClickableContainer="last-item-border-radius"
                    isLastItem
                  />
                </ButtonOptions>
                )}
                <RegularButton variant="subtle" label="Editar dados" extraClass="button-photo" onClick={onEditProfile} />
              </div>
            </div>
          </div>
        </div>
        <div className="logout mb-10">
          <p className="logout-failed-message">{isLogoutFailed ? 'Desculpe! Algo deu errado. Tente novamente.' : ''}</p>
          <RegularButton
            iconType="icon-LogoutLight"
            onClick={onLogout}
            label="Sair"
            variant="text"
            iconSize="30px"
            width="auto"
            extraClass="logout-btn"
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({
  profile, authentication, payment
}: AppState) => ({
  currentUser: authentication.currentUser,
  profile: profile.profile,
  isFetchingProfile: profile.isFetchingProfile,
  forceFetchProfile: profile.forceFetchProfile,
  isLogoutFailed: authentication.isLogoutFailed,
  planType: authentication.currentUser?.plan?.planType,
  isUploadingPictureProfile: profile.isUploadingPictureProfile,
  errorUploadingPictureProfile: profile.errorUploadingPictureProfile,
  isDeletingPictureProfile: profile.isDeletingPictureProfile,
  errorDeletingPictureProfile: profile.errorDeletingPictureProfile,
  isSuccessDeletedCardPayment: payment.isSuccessDeleted,
  isPictureProfileUploaded: profile.isPictureProfileUploaded,
  isProfilePictureDeleted: profile.isProfilePictureDeleted,
  forceUpdateCurrentUser: authentication.forceUpdateCurrentUser,
  isFetchingUser: authentication.isFetchingUser
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  logout: () => { dispatch(logoutAction()) },
  fetchProfile: () => { dispatch(fetchProfileAction()) },
  clearLogoutFailed: () => { dispatch(clearLogoutFailedAction()) },
  onUploadPictureProfile: (file: File) => { dispatch(onUploadPictureProfileAction(file)) },
  onDeletePictureProfile: (file: string | null) => { dispatch(onDeletePictureProfileAction(file)) },
  updateCurrentUserInfos: () => {
    dispatch(updateCurrentUserInfosAction())
  },
  clearForceUpdateCurrentUser: () => {
    dispatch(clearForceUpdateCurrentUserAction())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
