import React from 'react'
import {
  Loader, RegularButton, Snackbar, ResponsiveDrawer
} from 'theia-web-ds'
import { TheiaError } from '../../domain/errors/TheiaError'
import './ProfessionalInfoModal.scss'
import { SpecialistDetails } from '../../domain/Specialist'

interface Props {
  isVisible: boolean;
  isLoading?: boolean;
  specialistDetails?: SpecialistDetails;
  error?: TheiaError;
  onClose: () => void;
  onChooseSpecialist: () => void;
  onTryAgain?: () => void;
}

function ProfessionalInfoModal({
  isVisible,
  isLoading,
  specialistDetails,
  error,
  onClose,
  onChooseSpecialist,
  onTryAgain,
}: Props) {
  return isVisible ? (
    <ResponsiveDrawer
      id="specialist-details-drawer"
      isDrawerVisible={isVisible}
      onClose={onClose}
      headerTitle="Especialista"
    >
      {(isLoading || (!specialistDetails && !error)) && (
        <div className="specialist-details-loader">
          <Loader />
        </div>
      )}
      {!isLoading && !specialistDetails && (
        <div className="professional-details-error-container">
          <Snackbar
            title={error?.friendlyMessageTitle || 'Falha no carregamento'}
            text={error?.friendlyMessage || 'Desculpe, no momento não foi possível carregar as informações da(o) especialista.'}
            buttonOneProps={{
              onClick: onTryAgain,
              label: 'Tentar novamente'
            }}
            iconLeft="icon-InfoSquareLight"
          />
        </div>
      )}
      {!isLoading && specialistDetails && (
        <div className="specialist-details">
          <div className="specialist-details-header">
            <img
              alt={specialistDetails.pictureUrl}
              src={specialistDetails.pictureUrl}
              className="specialist-details-avatar-img"
            />
            <div className="specialist-details-header-text">
              <h3 className="specialist-name">{specialistDetails.name}</h3>
              <p className="specialist-category">{specialistDetails.category}</p>
            </div>
          </div>
          <div
            className="specialist-summary-html"
            dangerouslySetInnerHTML={{ __html: specialistDetails.summary }}
          />
          <RegularButton
            label="Agendar com especialista"
            onClick={onChooseSpecialist}
          />
        </div>
      )}
    </ResponsiveDrawer>
  ) : null
}

export default ProfessionalInfoModal
