import React, { HTMLAttributes } from 'react'
import './ChoiceChipButton.scss'

export interface Props extends HTMLAttributes<HTMLButtonElement> {
  firstLine: string;
  secondLine?: string;
  isSelected?: boolean;
  onClick: () => void;
  isSmaller?: boolean;
  extraClass?: string;
}

export function ChoiceChipButton({
  firstLine,
  secondLine,
  isSelected = false,
  onClick,
  isSmaller,
  extraClass,
  ...props
}: Props) {
  return (
    <button
      className={`${extraClass}
        choice-chip-container
        ${isSelected ? 'choice-chip-container-selected' : ''}
        ${isSmaller ? 'chip-smaller' : ''}
      `}
      onClick={onClick}
      data-testid="choice-chip-button-test-id"
      type="button"
      {...props}
    >
      <p className={`choice-chip-label ${isSelected ? 'choice-chip-label-selected' : ''}`}>
        {firstLine}
      </p>
      <p className={`choice-chip-label ${isSelected ? 'choice-chip-label-selected' : ''}`}>
        {secondLine}
      </p>
    </button>
  )
}
