export default class TitleNotification {
  private defaultTitle = 'Theia'

  private titleNotification = 'Nova Mensagem!'

  private titleWithCount = '(0) Theia'

  private intervalId = 0

  private titleSwitch = false

  startNotifying(count: number) {
    clearInterval(this.intervalId)
    this.titleWithCount = `(${count}) Theia`
    this.intervalId = window.setInterval(() => this.updateTitle(), 1500)
  }

  stopNotifying() {
    this.setTitle(this.defaultTitle)
    clearInterval(this.intervalId)
  }

  private updateTitle() {
    this.titleSwitch = !this.titleSwitch
    this.setTitle(this.titleSwitch ? this.titleNotification : this.titleWithCount)
  }

  private setTitle(title: string) {
    document.title = title
  }
}
