import React from 'react'

export interface Props {
  children: React.ReactNode;
  className?: string;
  width?: string;
  height?: string;
  id?: string;
  borderRadius?: string;
  boxShadow?: string;
}

export function Card({
  children,
  className,
  id,
  width = '100%',
  height,
  borderRadius,
  boxShadow
}: Props) {
  return (
    <div
      id={id}
      className={`${className}`}
      style={{
        width,
        height,
        borderRadius,
        boxShadow
      }}
      data-testid="card"
    >
      {children}
    </div>
  )
}
