import * as Sentry from '@sentry/browser';
import {
  Specialist,
  SpecialistDetails,
  SpecialistFromList,
  SpecialistProvider
} from '../../domain/Specialist'
import { AsyncAction } from '../utils'
import { AppState } from '../../apps/main/store'
import { TheiaError } from '../../domain/errors/TheiaError';
import { AttendanceAvailabilityType } from '../../domain/ScheduleFlow'

export const FETCH_SPECIALIST = 'FETCH_SPECIALIST'
export const SUCCESS_FETCH_SPECIALIST = 'SUCCESS_FETCH_SPECIALIST'
export const FAILURE_FETCH_SPECIALIST = 'FAILURE_FETCH_SPECIALIST'

export const GET_AVAILABLE_SPECIALISTS = 'GET_AVAILABLE_SPECIALISTS'
export const SUCCESS_GET_AVAILABLE_SPECIALISTS = 'SUCCESS_GET_AVAILABLE_SPECIALISTS'
export const FAILURE_GET_AVAILABLE_SPECIALISTS = 'FAILURE_GET_AVAILABLE_SPECIALISTS'

export const GET_SPECIALIST_DETAILS = 'GET_SPECIALIST_DETAILS'
export const SUCCESS_GET_SPECIALIST_DETAILS = 'SUCCESS_GET_SPECIALIST_DETAILS'
export const FAILURE_GET_SPECIALIST_DETAILS = 'FAILURE_GET_SPECIALIST_DETAILS'

export const SET_FILTERED_SPECIALISTS = 'SET_FILTERED_SPECIALISTS'

interface FetchSpecialistActionType {
  type: typeof FETCH_SPECIALIST;
  identity: string;
}

interface SuccessFetchSpecialistActionType {
  type: typeof SUCCESS_FETCH_SPECIALIST;
  specialist: Specialist;
  identity: string;
}

interface FailureFetchSpecialistActionType {
  type: typeof FAILURE_FETCH_SPECIALIST;
  identity: string;
}

interface GetAvailableSpecialistsActionType {
  type: typeof GET_AVAILABLE_SPECIALISTS;
}

interface SuccessGetAvailableSpecialistsActionType {
  type: typeof SUCCESS_GET_AVAILABLE_SPECIALISTS;
  availableSpecialists: SpecialistFromList[];
}

interface FailureGetAvailableSpecialistsActionType {
  type: typeof FAILURE_GET_AVAILABLE_SPECIALISTS;
  error?: TheiaError;
}

interface GetSpecialistDetailsActionType {
  type: typeof GET_SPECIALIST_DETAILS;
}

interface SuccessGetSpecialistDetailsActionType {
  type: typeof SUCCESS_GET_SPECIALIST_DETAILS;
  specialistDetails: SpecialistDetails;
}

interface FailureGetSpecialistDetailsActionType {
  type: typeof FAILURE_GET_SPECIALIST_DETAILS;
  error?: TheiaError;
}

interface SetFilteredSpecialistsActionType {
  type: typeof SET_FILTERED_SPECIALISTS;
  filteredSpecialists?: SpecialistFromList[]
}

export type SpecialistTypes = FetchSpecialistActionType
| SuccessFetchSpecialistActionType
| FailureFetchSpecialistActionType
| GetAvailableSpecialistsActionType
| SuccessGetAvailableSpecialistsActionType
| FailureGetAvailableSpecialistsActionType
| GetSpecialistDetailsActionType
| SuccessGetSpecialistDetailsActionType
| FailureGetSpecialistDetailsActionType
| SetFilteredSpecialistsActionType

export function fetchSpecialistAction(identity: string):
  AsyncAction<AppState, { specialistProvider: SpecialistProvider }> {
  return async (dispatch, getState, { specialistProvider }) => {
    const { specialists } = getState()
    const specialistData = specialists.byIdentity[identity]

    if (specialistData && specialistData.isFetching) return

    dispatch({ type: FETCH_SPECIALIST, identity })
    try {
      const specialist = await specialistProvider.getSpecialist(identity)
      dispatch({ type: SUCCESS_FETCH_SPECIALIST, specialist, identity })
    } catch (error) {
      dispatch({ type: FAILURE_FETCH_SPECIALIST, identity })
      if (error instanceof Error) {
        Sentry.captureException(new Error(`Failure fetching specialist - ${error.message}`))
      }
    }
  }
}

export function getAvailableSpecialistsAction(
  categoryId: string,
  meetingType: AttendanceAvailabilityType
):
  AsyncAction<{}, { specialistProvider: SpecialistProvider }> {
  return async (dispatch, _getState, { specialistProvider }) => {
    dispatch({ type: GET_AVAILABLE_SPECIALISTS })
    try {
      const availableSpecialists = await specialistProvider.getAvailableSpecialists(
        categoryId,
        meetingType
      )
      dispatch({
        type: SUCCESS_GET_AVAILABLE_SPECIALISTS,
        availableSpecialists
      })
    } catch (error) {
      dispatch({ type: FAILURE_GET_AVAILABLE_SPECIALISTS, error })
      if (error instanceof TheiaError) {
        Sentry.captureException(new Error(`Failure to get available specialists - ${error.message}`))
      }
    }
  }
}

export function getSpecialistDetailsAction(specialistId: string):
  AsyncAction<{}, { specialistProvider: SpecialistProvider }> {
  return async (dispatch, _getState, { specialistProvider }) => {
    dispatch({ type: GET_SPECIALIST_DETAILS })
    try {
      const specialistDetails = await specialistProvider.getSpecialistDetails(specialistId)
      dispatch({
        type: SUCCESS_GET_SPECIALIST_DETAILS,
        specialistDetails
      })
    } catch (error) {
      dispatch({ type: FAILURE_GET_SPECIALIST_DETAILS, error })
      if (error instanceof TheiaError) {
        Sentry.captureException(new Error(`Failure to get specialist details - ${error.message} - ${error.friendlyMessage} - ${error.friendlyMessageTitle}`))
      }
    }
  }
}

export const setFilteredSpecialistsAction = (
  filteredSpecialists?: SpecialistFromList[]
): SetFilteredSpecialistsActionType => ({
  type: SET_FILTERED_SPECIALISTS,
  filteredSpecialists
})
