/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  useRef,
  useMemo
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { AppDispatch } from '../../../state/utils'
import { AppState } from '../../../apps/main/store'
import {
  postUserHealthInsurance,
  onSelectHealthInsuranceAction,
} from '../../../state/healthplanProfile/actions'
import Header from '../../common/molecules/Header'
import BackButton from '../../common/buttons/BackButton'
import CardFaq from '../../../../assets/card-faq.png'
import { HealthInsurancePlan } from '../../../domain/User'
import HealthPlanRegistrationForm, { HealthInsurancePlanForm } from '../../common/HealthPlanRegistrationForm'
import { HealthPlanFeedbackData, PostUserHealthPlanType } from '../../../domain/Healthplan'
import { FaqToggle } from '../../common/FaqToggle'
import { HEALTHPLAN_FEEDBACK } from '../../../routes/RoutesConstants'

interface LocationProps {
  isStillLegacyPlan: boolean;
}

interface Props {
  onPostHealthInsurance: (healthPlanData: PostUserHealthPlanType) => void;
  onSelectHealthInsurancePlan: (healthPlanFeedbackData: HealthPlanFeedbackData) => void;
  hasInsurancePlan: boolean;
  userHealthInfo?: HealthInsurancePlan;
}

function HealthPlanProfile({
  onPostHealthInsurance,
  onSelectHealthInsurancePlan,
  hasInsurancePlan,
  userHealthInfo,
}: Props) {
  const history = useHistory()
  const [isVisible, setIsVisible] = useState(false)
  const location = useLocation().state as LocationProps
  const isStillLegacyPlan = location?.isStillLegacyPlan

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const listenToScroll = () => {
    if (mounted.current === true) {
      const itemb = document.querySelector('.item-b')
      const heightToHideFrom = 90
      const winScroll = itemb ? itemb.scrollTop : document.body.scrollTop
      if (winScroll > heightToHideFrom) {
        setIsVisible(true)
      } else {
        setIsVisible(false);
      }
    }
    return () => {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    const itemb = document.querySelector('.item-b')
    if (itemb) itemb.addEventListener('scroll', listenToScroll)
    return () => {
      window.removeEventListener('scroll', listenToScroll)
      setIsVisible(false)
    }
  }, [])

  const healthInsuranceFormMessage = useMemo(() => {
    let message = 'Trocar plano de saúde'
    if ((hasInsurancePlan === false) || (hasInsurancePlan && isStillLegacyPlan)) {
      message = 'Adicionar plano de saúde'
    }
    return message
  }, [hasInsurancePlan])

  function handleSubmit(values: HealthInsurancePlanForm) {
    onSelectHealthInsurancePlan({
      companyName: values.otherCompany || values.companyName,
      companyId: values.companyId,
      planName: values.planName,
      planId: values.planId,
      memberId: values.memberId,
      hasPreviousInsurancePlan: hasInsurancePlan,
      isCovered: values.isCovered,
    })
    if (values.isCovered) {
      onPostHealthInsurance({
        hasHealthInsurancePlan: values.hasHealthInsurancePlan,
        healthInsurancePlan: {
          company: values.otherCompany || values.companyName,
          planName: values.planName,
          memberId: values.memberId,
          companyId: values.companyId,
          planId: values.planId,
        }
      })
    }
    history.push(HEALTHPLAN_FEEDBACK)
  }

  return (
    <div className="flex flex-1 relative">
      <div className="mx-auto w-full h-full">
        <div className={`sticky flex items-center bg-bgCanvas top-0 z-10 h-20 ${isVisible ? 'border-divider border-solid border-b sticky-profile-bar' : ''}`}>
          <BackButton text={isVisible ? healthInsuranceFormMessage : ''} to="/profile" extraClass="back-button-svg" />
        </div>
        <div className="lg:max-w-xl mx-auto w-full mb-14">
          <Header
            title={healthInsuranceFormMessage}
          />
          <div className="px-4 mb-12 lg:max-w-xl">
            <HealthPlanRegistrationForm
              onPostHealthInsurance={(values: HealthInsurancePlanForm) => handleSubmit(values)}
              previousHealthInsurancePlan={userHealthInfo?.healthInsurancePlan}
            />
          </div>
          <FaqToggle
            toggleTitle="Onde encontro esses dados na carteirinha?"
            iconType="icon-ChatHelpLight"
          >
            <div className="flex flex-col items-center mb-20">
              <img src={CardFaq} alt="" height={40} />
              <p className="mt-4 text-textPrimary text-fontDefault">
                Você encontra os dados do plano na carteirinha virtual.
              </p>
              <div className="flex items-center mt-6">
                <span className="number-icon text-secondary font-medium">1</span>
                <p className="flex-1 text-textSecondary text-fontDefault ml-4 text-faq-line">
                  O seguro ou número da carteirinha fica localizado logo abaixo do nome,
                  como mostra na figura acima.
                </p>
              </div>
              <div className="flex items-center mt-4">
                <span className="number-icon text-secondary font-medium">2</span>
                <p className="flex-1 text-textSecondary text-fontDefault ml-4">
                  E o nome do plano está ao lado direito do número da carteirinha.
                </p>
              </div>
            </div>
          </FaqToggle>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  authentication
}: AppState) => ({
  hasInsurancePlan: !!authentication.currentUser?.healthInsurancePlanData.hasHealthInsurancePlan,
  userHealthInfo: authentication.currentUser?.healthInsurancePlanData,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onPostHealthInsurance: (healthPlanData: PostUserHealthPlanType) => {
    dispatch(postUserHealthInsurance(healthPlanData))
  },
  onSelectHealthInsurancePlan: (healthPlanFeedbackData: HealthPlanFeedbackData) => {
    dispatch(onSelectHealthInsuranceAction(healthPlanFeedbackData))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthPlanProfile)
