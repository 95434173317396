import React from 'react'
import { ToolTip } from 'theia-web-ds';
import './BookingCard.scss'
import Helpers from './helpers';

interface Props {
  status: string;
}

export default function BookingStatusTag({
  status,
}: Props) {
  if (
    Helpers.isCompletedBooking(status)
  ) {
    return (
      <ToolTip label="Realizada" backgroundColor="rgba(32, 230, 183, 0.1)" />
    )
  }
  if (
    Helpers.isConfirmedBooking(status)
  ) {
    return (
      <ToolTip label="Agendada" backgroundColor="rgba(255, 121, 81, 0.1)" />
    )
  }
  if (Helpers.isDismissedBooking(status)) {
    return (
      <ToolTip label="Dispensada" backgroundColor="rgba(218, 219, 225, 0.1)" />
    )
  }
  if (
    Helpers.isCancelledBooking(status)
  ) {
    return (
      <ToolTip label="Cancelada" backgroundColor="rgba(237, 29, 83, 0.1)" />
    )
  }

  return null
}
