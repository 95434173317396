/* eslint-disable no-unused-vars */
import React, { HTMLAttributes } from 'react'
import './RadioButton.scss'

export interface Props extends HTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode;
  name: string;
  id: string;
  disabled?: boolean;
  isLoading?: boolean;
  value: string | string[];
  inputRef: any;
  hasMoreThanOneLine?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function RadioButton({
  label,
  name,
  id,
  disabled,
  isLoading,
  value,
  inputRef,
  hasMoreThanOneLine,
  onBlur,
  onChange,
  ...props
}: Props) {
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
    }
  }
  if (isLoading) {
    return (
      <div className="loading-container-radiobutton">
        <div className="loading-radiobutton" />
        <div className="loading-label-radiobutton" />
      </div>
    )
  }
  return (
    <div className="radiobutton-lib policy-check">
      <label className="radiobutton-lib" htmlFor={id}>
        <input
          id={id}
          name={name}
          type="radio"
          value={value}
          disabled={disabled}
          ref={inputRef}
          onBlur={handleBlur}
          onChange={handleChange}
          className={`${hasMoreThanOneLine ? 'label-bigger' : ''}`}
          {...props}
        />
        <span>{label}</span>
      </label>
    </div>
  )
}
