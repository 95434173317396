import * as Sentry from '@sentry/browser'

import {
  Message,
  ScheduleMessage,
  ChatService,
  isScheduleMessage,
  isProfessionalScheduleMessage,
  isCategoryScheduleMessage,
  PlatformUnavailability
} from '../../domain/Chat'
import { promiseTimeout } from '../../utils/helpers'
import {
  setSpecialistOfflineAction,
  setSpecialistOnlineAction,
  setSpecialistTypingAction
} from '../specialistPresence/actions'
import { AppDispatch, AsyncAppAction } from '../utils'

export const SUCCESS_CONNECT_CHAT = 'SUCCESS_CONNECT_CHAT'
export const FAILURE_CONNECT_CHAT = 'FAILURE_CONNECT_CHAT'
export const REQUEST_CONNECT_CHAT = 'REQUEST_CONNECT_CHAT'
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE'
export const ADD_NEW_MEDIA_MESSAGE = 'ADD_NEW_MEDIA_MESSAGE'
export const INCREMENT_UNREAD_MESSAGES_COUNT = 'INCREMENT_UNREAD_MESSAGES_COUNT'
export const RESET_UNREAD_MESSAGES_COUNT = 'RESET_UNREAD_MESSAGES_COUNT'
export const FETCH_MESSAGES = 'FETCH_MESSAGES'
export const TRACK_SCHEDULE_MESSAGE = 'TRACK_SCHEDULE_MESSAGE'
export const SUCCESS_FETCH_MESSAGES = 'SUCCESS_FETCH_MESSAGES'
export const FAILURE_FETCH_MESSAGES = 'FAILURE_FETCH_MESSAGES'
export const SUCCESS_FETCH_MESSAGES_ON_CONNECT = 'SUCCESS_FETCH_MESSAGES_ON_CONNECT'
export const SUCCESS_GET_PROFESSIONAL_SUMMARY = 'SUCCESS_GET_PROFESSIONAL_SUMMARY'
export const FAILURE_GET_PROFESSIONAL_SUMMARY = 'FAILURE_GET_PROFESSIONAL_SUMMARY'
export const SHOW_WELCOME_MESSAGE = 'SHOW_WELCOME_MESSAGE'
export const RECEIVED_SCHEDULE_MESSAGE = 'RECEIVED_SCHEDULE_MESSAGE'
export const SHOW_CHAT = 'SHOW_CHAT'
export const SUCCESS_GET_UNAVAILABILITY = 'SUCCESS_GET_UNAVAILABILITY'
const FAILURE_GET_UNAVAILABILITY = 'FAILURE_GET_UNAVAILABILITY'
export const RETRY_CONNECT_CHAT_SHOW = 'RETRY_CONNECT_CHAT_SHOW'
export const RETRY_CONNECT_CHAT_CLICKED = 'RETRY_CONNECT_CHAT_CLICKED'
export const FORCE_CONNECT_CHAT = 'FORCE_CONNECT_CHAT'
export const SEND_MEDIA_MESSAGE = 'SEND_MEDIA_MESSAGE'
export const PROMPT_MEDIA_MODAL = 'PROMPT_MEDIA_MODAL'
export const SUPPRESS_MEDIA_MODAL = 'SUPPRESS_MEDIA_MODAL'

interface SuccessfulConnectChatActionType {
  type: typeof SUCCESS_CONNECT_CHAT;
  chatService: ChatService;
}

interface FailureConnectChatActionType {
  type: typeof FAILURE_CONNECT_CHAT;
}

interface RequestConnectChatActionType {
  type: typeof REQUEST_CONNECT_CHAT;
}

interface SendMessageActionType {
  type: typeof SEND_MESSAGE;
}

interface SendMediaMessageActionType {
  type: typeof SEND_MEDIA_MESSAGE;
}

export interface AddNewMessageActionType {
  type: typeof ADD_NEW_MESSAGE;
  message: Message;
}

export interface AddNewMediaMessageActionType {
  type: typeof ADD_NEW_MEDIA_MESSAGE;
  message: Message;
  mediaUrl: string;
}

export interface IncrementUnreadMessagesActionType {
  type: typeof INCREMENT_UNREAD_MESSAGES_COUNT;
}

export interface ResetUnreadMessagesActionType {
  type: typeof RESET_UNREAD_MESSAGES_COUNT;
}

interface FetchMessagesActionType {
  type: typeof FETCH_MESSAGES;
}

export interface TrackScheduleMessageActionType {
  type: typeof TRACK_SCHEDULE_MESSAGE;
  professionalId?: string;
  professionalCategory?: string;
}

interface SuccessFetchMessagesActionType {
  type: typeof SUCCESS_FETCH_MESSAGES;
  messages: Array<Message>;
}

interface FailureFetchMessagesActionType {
  type: typeof FAILURE_FETCH_MESSAGES;
}

interface SuccessFetchMessagesOnConnectActionType {
  type: typeof SUCCESS_FETCH_MESSAGES_ON_CONNECT;
  messages: Array<Message>;
}

interface SuccessGetProfessionalSummaryType {
  type: typeof SUCCESS_GET_PROFESSIONAL_SUMMARY;
  message: Message;
}

interface FailureGetProfessionalSummaryType {
  type: typeof FAILURE_GET_PROFESSIONAL_SUMMARY;
}

interface ShowWelcomeMessageActionType {
  type: typeof SHOW_WELCOME_MESSAGE;
}

interface ReceivedScheduleMessageActionType {
  type: typeof RECEIVED_SCHEDULE_MESSAGE;
  scheduleMessage: ScheduleMessage;
}

interface ShowChatActionType {
  type: typeof SHOW_CHAT;
}

interface SuccessUnavailabilityAction {
  type: typeof SUCCESS_GET_UNAVAILABILITY;
  unavailabilities: Array<PlatformUnavailability>;
}

interface LogClickRetryConnectChatActionType {
  type: typeof RETRY_CONNECT_CHAT_CLICKED;
}

interface ForceConnectChatActionType {
  type: typeof FORCE_CONNECT_CHAT;
}

interface PromptMediaModalActionType {
  type: typeof PROMPT_MEDIA_MODAL;
  mediaURL: string;
}

interface SuppressMediaModalActionType {
  type: typeof SUPPRESS_MEDIA_MODAL;
}

interface TimeoutReconnectActionType {
  type: typeof FAILURE_CONNECT_CHAT;
}

export type ChatTypes =
  SendMessageActionType |
  AddNewMessageActionType |
  FetchMessagesActionType |
  TrackScheduleMessageActionType |
  SuccessFetchMessagesActionType |
  FailureFetchMessagesActionType |
  SuccessGetProfessionalSummaryType |
  FailureGetProfessionalSummaryType |
  SuccessfulConnectChatActionType |
  ShowWelcomeMessageActionType |
  IncrementUnreadMessagesActionType |
  ResetUnreadMessagesActionType |
  ReceivedScheduleMessageActionType |
  FailureConnectChatActionType |
  RequestConnectChatActionType |
  SuccessUnavailabilityAction |
  ForceConnectChatActionType |
  SendMediaMessageActionType |
  SuccessFetchMessagesOnConnectActionType |
  AddNewMediaMessageActionType |
  PromptMediaModalActionType |
  SuppressMediaModalActionType |
  LogClickRetryConnectChatActionType |
  TimeoutReconnectActionType

export function getUnavailabilityAction(): AsyncAppAction {
  return async (dispatch, getState) => {
    const { chat } = getState()
    const { chatService } = chat

    if (!chatService) {
      Sentry.captureException(new Error('Failure getting the unavailabilities - without chatService'))
      return
    }

    try {
      const unavailabilities = await chatService.getUnavailability()
      dispatch({
        type: SUCCESS_GET_UNAVAILABILITY,
        unavailabilities
      })
    } catch (error) {
      dispatch({ type: FAILURE_GET_UNAVAILABILITY })
      Sentry.captureException(new Error(`Failure getting the unavailabilities - ${error.message}`))
    }
  }
}

function getProfessionalSummaryAction(message: ScheduleMessage): AsyncAppAction {
  return async (dispatch, getState, { professionalProvider }) => {
    const { chat } = getState()
    const showMoreOptions = chat.scheduleMessages.some((m) => m.text === message.text)
    try {
      let promise
      if (isProfessionalScheduleMessage(message)) {
        promise = professionalProvider.getProfessionalSummary(message.professionalId)
      }
      dispatch({
        type: SUCCESS_GET_PROFESSIONAL_SUMMARY,
        message: { ...message, professional: await promise, showMoreOptions }
      })
    } catch (error) {
      dispatch({ type: FAILURE_GET_PROFESSIONAL_SUMMARY })
      Sentry.captureException(new Error(`Failure getting the professional summary - ${error.message}`))
    }
  }
}

function loadAdditionalMessageContent(dispatch: AppDispatch, messages: Array<Message>) {
  messages
    .filter((message) => isScheduleMessage(message))
    .forEach((message) => dispatch(getProfessionalSummaryAction(message as ScheduleMessage)))
}

export const forceConnectChatAction = (): ForceConnectChatActionType => ({
  type: FORCE_CONNECT_CHAT
})

export function fetchOldMessagesAction(anchor?: number): AsyncAppAction {
  return async (dispatch, getState) => {
    const { chat } = getState()
    const { chatService } = chat

    if (!chatService) {
      Sentry.captureException(new Error('Failure fetching the old messages - without chatService'))
      return
    }
    if (anchor && anchor < 0) return
    if (chatService.needForceReconnect() && !chat.connectingChat) {
      await dispatch(forceConnectChatAction())
      return
    }

    dispatch({ type: FETCH_MESSAGES })

    try {
      const messages = await chatService.getMessages(anchor)
      dispatch({ type: SUCCESS_FETCH_MESSAGES, messages })
      loadAdditionalMessageContent(dispatch, messages)
    } catch (error) {
      dispatch({ type: FAILURE_FETCH_MESSAGES })
      Sentry.captureException(new Error(`Failure fetching the old messages - ${error.message}`))
    }
  }
}

export function fetchOldMessagesOnConnectAction(anchor?: number): AsyncAppAction {
  return async (dispatch, getState) => {
    const { chat } = getState()
    const { chatService } = chat

    if (!chatService) {
      Sentry.captureException(new Error('Failure fetching the old messages on connect - without chatService'))
      return
    }
    if (anchor && anchor < 0) return
    if (chatService.needForceReconnect() && !chat.connectingChat) {
      await dispatch(forceConnectChatAction())
      return
    }

    dispatch({ type: FETCH_MESSAGES })

    try {
      const messages = await chatService.getMessages(anchor)
      dispatch({ type: SUCCESS_FETCH_MESSAGES_ON_CONNECT, messages })
      loadAdditionalMessageContent(dispatch, messages)
    } catch (error) {
      dispatch({ type: FAILURE_FETCH_MESSAGES })
      Sentry.captureException(new Error(`Failure fetching the old messages on connect - ${error.message}`))
    }
  }
}

export function sendMessageAction(text: string): AsyncAppAction {
  return async (dispatch, getState) => {
    const { chat } = getState()
    const { chatService } = chat

    if (!chatService) {
      Sentry.captureException(new Error('Failure sending message in chat - without chatService'))
      return
    }
    if (chatService.needForceReconnect() && !chat.connectingChat) {
      await dispatch(forceConnectChatAction())
      return
    }

    try {
      chatService.sendMessage(text)
      dispatch({ type: SEND_MESSAGE })
    } catch (error) {
      Sentry.captureException(new Error(`Failure sending message in chat - ${error.message}`))
    }
  }
}

export function sendMediaMessageAction(file: File): AsyncAppAction {
  return async (dispatch, getState) => {
    const { chat } = getState()
    const { chatService } = chat

    if (!chatService) {
      Sentry.captureException(new Error('Failure sending media message in chat - without chatService'))
      return
    }
    if (chatService.needForceReconnect() && !chat.connectingChat) {
      await dispatch(forceConnectChatAction())
      return
    }

    try {
      chatService.sendMediaMessage(file)
      dispatch({ type: SEND_MEDIA_MESSAGE })
    } catch (error) {
      Sentry.captureException(new Error(`Failure sending media message in chat - ${error.message}`))
    }
  }
}

export function receiveMessageAction(message: Message): AsyncAppAction {
  return async (dispatch, getState) => {
    const { chat } = getState()
    const { chatService } = chat
    if (message.type === 'media') {
      try {
        const mediaUrl = await chatService?.getMediaUrl(message)
        dispatch({ type: ADD_NEW_MEDIA_MESSAGE, message, mediaUrl })
      } catch (error) {
        Sentry.captureException(new Error(`Failure receiving media message in chat - ${error.message}`))
      }
    } else {
      dispatch({ type: ADD_NEW_MESSAGE, message })
      if (isScheduleMessage(message) && isProfessionalScheduleMessage(message)) {
        dispatch({
          type: TRACK_SCHEDULE_MESSAGE,
          professionalId: message.professionalId,
        })
      } else if (isScheduleMessage(message) && isCategoryScheduleMessage(message)) {
        dispatch({
          type: TRACK_SCHEDULE_MESSAGE,
          professionalCategory: message.professionalCategory,
        })
      }

      if (isScheduleMessage(message)) {
        dispatch(getProfessionalSummaryAction(message as ScheduleMessage))
        dispatch({ type: RECEIVED_SCHEDULE_MESSAGE, scheduleMessage: message })
      }
    }
  }
}

export const timeoutReconnectAction = (): TimeoutReconnectActionType => ({
  type: FAILURE_CONNECT_CHAT
})

export function connectChatAction(): AsyncAppAction {
  return async (dispatch, _getState, { chatServiceFactory }) => {
    dispatch({ type: REQUEST_CONNECT_CHAT })
    const state = _getState()

    if (!state.authentication.currentUser) {
      dispatch({ type: FAILURE_CONNECT_CHAT })
      return
    }

    try {
      const chatService = await promiseTimeout(
        chatServiceFactory.build(state.authentication.currentUser)
      )
      dispatch({ type: SUCCESS_CONNECT_CHAT, chatService })

      const [onlineSpecialist] = await promiseTimeout(
        Promise.all([
          chatService.getOnlineSpecialist(),
          dispatch(fetchOldMessagesOnConnectAction())
        ])
      )

      if (onlineSpecialist) {
        dispatch(setSpecialistOnlineAction(onlineSpecialist))
      } else {
        dispatch(setSpecialistOfflineAction())
      }

      chatService.setOnSpecialistOnline((identity: string) => {
        dispatch(setSpecialistOnlineAction(identity))
      })
      chatService.setOnSpecialistOffline(() => dispatch(setSpecialistOfflineAction()))
      chatService.setOnMessageReceived(
        (message: Message) => dispatch(receiveMessageAction(message))
      )
      chatService.setOnSpecialistTyping(
        (typing: boolean) => dispatch(setSpecialistTypingAction(typing))
      )
    } catch (error) {
      dispatch({ type: FAILURE_CONNECT_CHAT })
      Sentry.captureException(new Error(`Failure connecting to chat - ${error.message}`))
    }
  }
}

export function messageTypingAction(): AsyncAppAction {
  return async (dispatch, getState) => {
    const { chat } = getState()
    if (chat.chatService) {
      if (chat.chatService.needForceReconnect() && !chat.connectingChat) {
        await dispatch(forceConnectChatAction())
        return
      }
      try {
        chat.chatService.typing()
      } catch (error) {
        Sentry.captureException(new Error(`Failure with typing status in chat - ${error.message}`))
      }
    }
  }
}

export function logConnectChatErrorAction(): AsyncAppAction {
  return async (dispatch) => {
    dispatch({ type: RETRY_CONNECT_CHAT_SHOW })
    Sentry.captureException(new Error('Failure connect chat - Retry button was shown'))
  }
}

export const logClickRetryConnectChatAction = (): LogClickRetryConnectChatActionType => ({
  type: RETRY_CONNECT_CHAT_CLICKED
})

export const showWelcomeMessageAction = (): ShowWelcomeMessageActionType => ({
  type: SHOW_WELCOME_MESSAGE
})

export const resetUnreadMessagesCountAction = (): ResetUnreadMessagesActionType => ({
  type: RESET_UNREAD_MESSAGES_COUNT
})

export const incrementUnreadMessagesCountAction = (): IncrementUnreadMessagesActionType => ({
  type: INCREMENT_UNREAD_MESSAGES_COUNT
})

export const onShowChatAction = (): ShowChatActionType => ({
  type: SHOW_CHAT
})

export const promptMediaModalAction = (mediaURL: string): PromptMediaModalActionType => ({
  type: PROMPT_MEDIA_MODAL,
  mediaURL
})

export const suppressMediaModalAction = (): SuppressMediaModalActionType => ({
  type: SUPPRESS_MEDIA_MODAL
})
