import {
  AvailabilitiesRequestParams,
  AvailabilitiesService,
  AvailabilityByDay,
  AvailabilityTimeSlot,
  ResponseAvailabilitiesSlots
} from '../../domain/Availabilities'
import { LocalDate } from '../../utils/LocalDate'
import ApiClient from './ApiClient'

export default class ApiAvailabilitiesService implements AvailabilitiesService {
  constructor(private apiClient: ApiClient) { }

  async getAvailabilities(
    categoryId: string,
    params: AvailabilitiesRequestParams
  ): Promise<ResponseAvailabilitiesSlots> {
    const response = await this.apiClient.getAvailabilities(categoryId, params)
    const formattedResponse = {
      ...response,
      availabilities: response.availabilities.map((av: any) => ({
        ...av,
        day: new LocalDate(av.day),
        slots: av.slots.map((slot: any) => ({
          ...slot,
          startTime: new LocalDate(slot.startTime)
        })) as AvailabilityTimeSlot[]
      })) as AvailabilityByDay[]
    } as ResponseAvailabilitiesSlots
    return formattedResponse
  }
}
