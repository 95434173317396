import React, { useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import SendIcon from '../../../assets/icon_send.svg'
import { sendMessageAction, messageTypingAction } from '../../state/chatVideo/actions'
import './MessageComposer.scss'

interface MessageComposerProps {
  sendMessage: (text: string) => void;
  messageTyping: () => void;
}

function MessageComposer({ sendMessage, messageTyping }: MessageComposerProps) {
  const [text, setText] = useState('')
  function updateText(event: React.ChangeEvent<HTMLInputElement>) {
    messageTyping()
    setText(event.currentTarget.value)
  }
  const onSendMessage = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault()
    if (!text) return
    sendMessage(text)
    setText('')
  }
  return (
    <form onSubmit={onSendMessage}>
      <div className="message-composer mt-2 mb-2 ml-4 mr-4 flex items-center lg:mb-6">
        <div className="flex-1 pl-4">
          <input className="w-full focus:outline-none" type="text" value={text} onChange={updateText} />
        </div>
        <button className="bg-secondary p-3 m-2 rounded-full focus:outline-none" type="submit" id="sendButton">
          <SendIcon fill="white" style={{ position: 'relative', top: '-1px', left: '-1px' }} />
        </button>
      </div>
    </form>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  sendMessage: sendMessageAction,
  messageTyping: messageTypingAction
}, dispatch)

export default connect(
  null,
  mapDispatchToProps
)(MessageComposer)
