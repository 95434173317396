/* eslint-disable no-return-assign */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RegularButton, Button, FormInput } from 'theia-web-ds'
import { bindActionCreators, Dispatch } from 'redux'
import * as Sentry from '@sentry/browser'
import { Formik, FormikProps, FormikErrors } from 'formik'
import * as Yup from 'yup'
import '../common/Input.scss'
import './Authentication.scss'
import { SimpleHttpClient } from '../../services/Http'
import { loginAction } from '../../state/authentication/main/actions'
import AppMainContainerOff from '../AppMainContainerOff'
import { getFlutterClient, passwordSpecialCharacters } from '../../utils/helpers'
import { ErrorWithFriendlyMessage, promptErrorAction } from '../../state/errorWithFriendlyMessage/actions'
import { AppState } from '../../apps/main/store'
import ErrorModal from '../common/ErrorModal'
import ValidatePasswordInput from '../common/ValidatePasswordInput'
import { INICIO, LOGIN, RECOVERY } from '../../routes/RoutesConstants'
import { API_URL } from '../../services/api/ApiClient'

interface Props {
  login: () => void;
  promptError: (signupError: ErrorWithFriendlyMessage) => void;
  defaultErrorVisible: boolean;
}

interface PasswordRecoveryFormValues {
  password: string;
  passwordConfirm: string;
}

function renderPasswordrecoveryForm(
  {
    handleSubmit,
    isSubmitting,
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    validateField
  }: FormikProps<PasswordRecoveryFormValues>
) {
  const history = useHistory()

  function goToLogin() {
    history.push(LOGIN)
  }

  const canSubmit = (
    !!values.password
    && !!values.passwordConfirm
    && !errors.password
    && !errors.passwordConfirm
  )

  return (
    <form onSubmit={handleSubmit} className="mx-auto w-full lg:max-w-x" noValidate>
      <div className="mb-4">
        <ValidatePasswordInput
          onBlur={handleBlur}
          password={values.password}
          error={errors.password}
          passwordLabel="Nova senha *"
          onChange={handleChange}
          errorMessage={touched.password && !values.password ? errors.password : ''}
          value={values.password}
          eventText="'Inseriu Nova Senha'"
          onValidateField={() => validateField('password')}
        />
      </div>
      <div className="mb-4">
        <FormInput
          label="Confirme sua nova senha *"
          placeholder="Digite a senha novamente"
          type="password"
          name="passwordConfirm"
          id="passwordConfirm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.passwordConfirm}
          errorMessage={touched.passwordConfirm ? errors.passwordConfirm : ''}
          iconSize="24px"
          isPassword
        />
      </div>

      <div className="mt-8 flex flex-auto justify-center ">
        <RegularButton
          type="submit"
          label="Trocar senha"
          disabled={isSubmitting || !canSubmit}
          isSubmitting={isSubmitting}
          maxWidth="100%"
        />
      </div>
      <div className="mt-5">
        <Button className="text-textSmaller underline text-greyColor font-light" height="auto" width="100%" onClick={goToLogin}>
          Entrar na minha conta
        </Button>
      </div>
    </form>
  );
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8)
    .matches(/[A-Z]/)
    .matches(/[a-z]/)
    .matches(/[0-9]/)
    .matches(passwordSpecialCharacters)
    .required('O campo de senha é obrigatório'),
  passwordConfirm: Yup.string()
    .required('Confirmação de senha obrigatória')
    .oneOf([Yup.ref('password')], 'As senhas devem ser iguais')
})

const initialValues = {
  password: '',
  passwordConfirm: '',
}

function PasswordRecoveryForm({
  login, promptError, defaultErrorVisible
}: Props) {
  const history = useHistory()
  const urlToken = new URLSearchParams(window.location.search)
  const token = urlToken.get('token')
  const [hasExtraButton, setHasExtraButton] = useState(false)
  const onClickExtraButton = () => {
    history.push(RECOVERY)
  }
  return (
    <AppMainContainerOff screenTitle="Mudança de senha">
      <div className="buttons-content">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async ({
            password
          }, { setSubmitting, setErrors }) => {
            const url = `${API_URL}/v1/password-recovery`
            const httpClient = new SimpleHttpClient()
            const payload = {
              password, token
            }
            const errors: FormikErrors<PasswordRecoveryFormValues> = {}
            const response = await httpClient.post({ url, payload })
            if (response.status !== 200) {
              setSubmitting(false)
              const json = JSON.parse(response.body)
              Sentry.captureException(new Error(`Failure on recovery password: ${json.message}`))
              if (json.message !== 'Um erro ocorreu durante o envio') {
                setHasExtraButton(true)
              } else {
                setHasExtraButton(false)
              }
              const recoveryError = {
                friendlyMessageTitle: 'Desculpe! Algo deu errado',
                friendlyMessage: json.message
              }
              promptError(recoveryError)
            } else {
              const json = JSON.parse(response.body)
              if (json.errors) {
                json.errors.forEach(({
                  field,
                  message
                }: { field: string; message: string }) => {
                  if (field === 'password') errors.password = message
                  if (field === 'passwordConfirm') errors.passwordConfirm = message
                })
                setErrors(errors)
              } else {
                const flutterClient = getFlutterClient()
                if (flutterClient) {
                  flutterClient.callHandler('tellMobileToken', {
                    token,
                    isFinished: true
                  })
                }
                await login()
                history.push(INICIO)
              }
            }
          }}
        >
          {(props) => renderPasswordrecoveryForm(props)}
        </Formik>
        <ErrorModal
          visible={defaultErrorVisible}
          extraButton={hasExtraButton}
          extraButtonclick={onClickExtraButton}
          extraButtonText="Recuperar senha"
        />
      </div>
    </AppMainContainerOff>
  )
}

const mapStateToProps = ({
  errorWithFriendlyMessage
}: AppState) => ({
  defaultErrorVisible: errorWithFriendlyMessage.visible
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  login: loginAction,
  promptError: promptErrorAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordRecoveryForm)
