/* eslint-disable no-case-declarations */
import {
  ChatService,
  Message,
  ScheduleMessage,
  PlatformUnavailability,
} from '../../domain/Chat'
import {
  SUCCESS_CONNECT_CHAT,
  ADD_NEW_MESSAGE,
  FETCH_MESSAGES,
  FAILURE_FETCH_MESSAGES,
  SUCCESS_FETCH_MESSAGES,
  SUCCESS_GET_PROFESSIONAL_SUMMARY,
  SHOW_WELCOME_MESSAGE,
  ChatTypes,
  INCREMENT_UNREAD_MESSAGES_COUNT,
  RESET_UNREAD_MESSAGES_COUNT,
  RECEIVED_SCHEDULE_MESSAGE,
  FAILURE_CONNECT_CHAT,
  REQUEST_CONNECT_CHAT,
  SUCCESS_GET_UNAVAILABILITY,
  FORCE_CONNECT_CHAT,
  SUCCESS_FETCH_MESSAGES_ON_CONNECT,
  ADD_NEW_MEDIA_MESSAGE,
  PROMPT_MEDIA_MODAL,
  SUPPRESS_MEDIA_MODAL, RETRY_CONNECT_CHAT_CLICKED
} from './actions'

export interface ChatState {
  chatService?: ChatService;
  messages: Array<Message>;
  isFetchingMessages: boolean;
  historicalMessagesQuantity: number;
  shouldShowWelcomeMessage: boolean;
  unreadMessagesCount: number;
  scheduleMessages: Array<ScheduleMessage>;
  connectChatError: boolean;
  connectingChat: boolean;
  unavailabilities: Array<PlatformUnavailability>;
  forceReconnect: boolean;
  itWasReconnect: boolean;
  mediaUrl?: string;
  isVisibleMediaModal: boolean;
  timesTriedToConnect: number;
}

export const initialChatState: ChatState = {
  chatService: undefined,
  messages: [],
  scheduleMessages: [],
  isFetchingMessages: false,
  historicalMessagesQuantity: 0,
  shouldShowWelcomeMessage: false,
  unreadMessagesCount: 0,
  connectChatError: false,
  connectingChat: false,
  unavailabilities: [],
  forceReconnect: false,
  itWasReconnect: false,
  isVisibleMediaModal: false,
  timesTriedToConnect: 0,
}

export function chat(state = initialChatState, action: ChatTypes): ChatState {
  switch (action.type) {
    case FORCE_CONNECT_CHAT:
      return {
        ...initialChatState,
        historicalMessagesQuantity: 0,
        messages: [],
        forceReconnect: true,
        timesTriedToConnect: state.timesTriedToConnect + 1,
      }
    case SUCCESS_CONNECT_CHAT:
      return {
        ...state,
        chatService: action.chatService,
        connectChatError: false,
        connectingChat: false,
        forceReconnect: false,
        itWasReconnect: state.forceReconnect,
      }
    case FAILURE_CONNECT_CHAT:
      return {
        ...state,
        connectChatError: true,
        connectingChat: false,
      }
    case REQUEST_CONNECT_CHAT:
      return {
        ...state,
        connectingChat: true,
        forceReconnect: false,
      }
    case RETRY_CONNECT_CHAT_CLICKED:
      return {
        ...state,
        timesTriedToConnect: 0,
      }
    case ADD_NEW_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.message],
      }
    case ADD_NEW_MEDIA_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.message],
        mediaUrl: action.mediaUrl
      }
    case FETCH_MESSAGES:
      return {
        ...state,
        isFetchingMessages: true
      }
    case SUCCESS_FETCH_MESSAGES:
      return {
        ...state,
        isFetchingMessages: false,
        historicalMessagesQuantity: state.historicalMessagesQuantity + action.messages.length,
        itWasReconnect: false,
        messages: [...action.messages, ...state.messages]
      }
    case SUCCESS_FETCH_MESSAGES_ON_CONNECT:
      if (!state.messages) return state
      let messagesOnConnect
      if (state.messages.length > 0) {
        messagesOnConnect = state.messages
      } else {
        messagesOnConnect = action.messages
      }
      return {
        ...state,
        isFetchingMessages: false,
        historicalMessagesQuantity: state.historicalMessagesQuantity + action.messages.length,
        itWasReconnect: false,
        messages: messagesOnConnect
      }
    case FAILURE_FETCH_MESSAGES:
      return {
        ...state,
        isFetchingMessages: false
      }
    case SUCCESS_GET_PROFESSIONAL_SUMMARY: {
      const index = state.messages.findIndex((message) => message.index === action.message.index)

      return {
        ...state,
        messages: [
          ...state.messages.slice(0, index),
          action.message,
          ...state.messages.slice(index + 1)
        ]
      }
    }
    case SHOW_WELCOME_MESSAGE:
      return {
        ...state,
        shouldShowWelcomeMessage: true
      }
    case INCREMENT_UNREAD_MESSAGES_COUNT:
      return {
        ...state,
        unreadMessagesCount: state.unreadMessagesCount + 1
      }
    case RESET_UNREAD_MESSAGES_COUNT:
      return {
        ...state,
        unreadMessagesCount: 0
      }
    case RECEIVED_SCHEDULE_MESSAGE:
      return {
        ...state,
        scheduleMessages: [...state.scheduleMessages, action.scheduleMessage]
      }
    case SUCCESS_GET_UNAVAILABILITY:
      return {
        ...state,
        unavailabilities: action.unavailabilities
      }
    case PROMPT_MEDIA_MODAL:
      return {
        ...state,
        isVisibleMediaModal: true,
        mediaUrl: action.mediaURL
      }
    case SUPPRESS_MEDIA_MODAL:
      return {
        ...state,
        isVisibleMediaModal: false,
        mediaUrl: ''
      }
    default:
      return state
  }
}
