import ApiClient from './ApiClient'
import { ConsultantService, DesireContent, MaternalDesireType } from '../../domain/Consultant'

export default class ApiConsultantService implements ConsultantService {
  constructor(private apiClient: ApiClient) { }

  postMaternalDesire(body: MaternalDesireType): Promise<void> {
    return this.apiClient.postMaternalDesire(body)
  }

  async getDesireContent(): Promise<DesireContent> {
    const response = await this.apiClient.getDesireContent()
    return response
  }
}
