/* eslint-disable max-len */
import React, {
  Fragment,
  forwardRef,
  useEffect,
  useRef,
  useState
} from 'react'
import ReactDOM from 'react-dom'
import { NewestCheckbox } from '../NewestCheckbox/NewestCheckbox'
import { IconButton } from '../IconButton/IconButton'
import './DropdownCheckbox.scss'
import {
  error,
  primary,
  textDisable,
  textPrimary
} from '../../colors'
import { FontIcon } from '../FontIcon/FontIcon'
import { FormInput } from '../FormInput/FormInput'
import { UseCombinedRefs, compareStringsIgnoreSpecialChars, isWhitespaceString } from '../../utils/helpers'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { Alert } from '../Alert/Alert'

export interface DropdownOption {
  label: string;
  value: string;
  isChecked: boolean;
  isOther: boolean;
  obs?: string;
}

export interface Props {
  options: Array<DropdownOption>;
  onChange?: (value: Array<DropdownOption>) => void;
  selectPlaceholder?: string;
  extraInfoPlaceholder?: string;
  extraItemBtnLabel?: string;
  extraItemPlaceholder?: string;
  isLoading?: boolean;
  errorMessage?: string;
  onClick?: () => void;
  readonly?: boolean;
  dropdownLabel?: string;
  required?: boolean;
  extraClassOuter?: string;
  textInfo?: string;
  hideMenuOptions?: boolean;
}

export const DropdownCheckbox: React.FC<Props> = forwardRef(({
  options,
  onChange,
  selectPlaceholder,
  extraInfoPlaceholder,
  extraItemBtnLabel,
  extraItemPlaceholder,
  isLoading,
  errorMessage,
  onClick,
  readonly,
  dropdownLabel,
  required,
  extraClassOuter,
  textInfo,
  hideMenuOptions
}: Props, ref) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [defaultList, setDefaultList] = useState(options)
  const [chipItems, setChipItems] = useState<Array<DropdownOption>>(() => options.filter((item) => item.isChecked))
  const innerRef = useRef<HTMLDivElement>(null)
  const combinedRef = UseCombinedRefs(ref, innerRef)
  const [offset, setOffset] = useState({ top: 0, left: 0, width: 0 })
  const chipsContainerRef = useRef<HTMLDivElement | null>(null)
  const dropdownListWrapperRef = useRef<HTMLDivElement | null>(null)
  const [isAddNew, setIsAddNew] = useState(false)
  const [newValue, setNewValue] = useState('')
  const [newValueError, setNewValueError] = useState(false)
  const newValueInputRef = useRef<HTMLInputElement | null>(null)
  const [observationValues, setObservationValues] = useState<{ [key: string]: string }>({})
  const itemAlreadyExists = !!chipItems.filter((chip) => compareStringsIgnoreSpecialChars(chip.label, newValue)).length || !!defaultList.filter((chip) => compareStringsIgnoreSpecialChars(chip.label, newValue)).length

  const [showTextInfo, setShowTextInfo] = useState(false)

  function handleShowTextInfo() {
    if (textInfo && readonly) setShowTextInfo(true)
  }

  function handleHideTextInfo() {
    setShowTextInfo(false)
  }

  useEffect(() => {
    if (options) {
      setDefaultList(options)
    }
  }, [options])

  useEffect(() => {
    if (hideMenuOptions) {
      setIsMenuOpen(false)
    }
  }, [hideMenuOptions])

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      const wrapper = dropdownListWrapperRef.current
      if (wrapper && !wrapper.contains(e.target as Node)) {
        setIsMenuOpen(false)
      }
    }

    function handleKeyDownOutside(e: KeyboardEvent) {
      const wrapper = dropdownListWrapperRef.current
      if (e.key === 'Escape') {
        if (wrapper && !wrapper.contains(e.target as Node)) {
          setIsMenuOpen(false)
        }
      }
    }

    window.addEventListener('click', handleClickOutside, false)
    window.addEventListener('keydown', handleKeyDownOutside, false)

    return () => {
      window.removeEventListener('click', handleClickOutside)
      window.removeEventListener('keydown', handleKeyDownOutside)
    }
  }, [])

  useEffect(() => {
    function handleMenuPosition() {
      if (innerRef.current) {
        const offsetTop = (innerRef.current.offsetHeight || 0) + (innerRef.current.getBoundingClientRect().top || 0)
        const offsetLeft = innerRef.current.getBoundingClientRect().left
        const width = innerRef.current.offsetWidth
        setOffset({ top: offsetTop, left: offsetLeft, width })
      }
    }

    handleMenuPosition()
    window.addEventListener('resize', handleMenuPosition)
    return () => {
      window.removeEventListener('resize', handleMenuPosition)
    }
  }, [isMenuOpen, chipItems])

  useEffect(() => {
    if (onChange) onChange(chipItems)
  }, [chipItems])

  useEffect(() => {
    if (newValue && itemAlreadyExists) {
      setNewValueError(true)
    } else {
      setNewValueError(false)
    }
  }, [newValue])

  useEffect(() => {
    if (isAddNew) {
      newValueInputRef?.current?.focus()
    }
  }, [isAddNew])

  function handleOnBlur() {
    if (!isWhitespaceString(newValue) && newValue && !itemAlreadyExists) {
      const valueFormatted = newValue.trim()
      setDefaultList([...defaultList, {
        label: valueFormatted,
        value: valueFormatted,
        isOther: true,
        isChecked: true
      }])
      setChipItems([...chipItems, {
        label: valueFormatted,
        value: valueFormatted,
        isOther: true,
        isChecked: true
      }])
      setIsAddNew(false)
      setNewValue('')
    } else {
      setIsAddNew(false)
      setNewValue('')
    }
  }

  function handleChipItem(chip: DropdownOption) {
    if (chip.isChecked) {
      setChipItems([...chipItems, chip])
    } else {
      const updatedChipItems = chipItems.filter((item) => item.value !== chip.value)
      setChipItems(updatedChipItems)
    }
  }

  function handleMenuOpen(
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ) {
    if (!readonly) {
      e.stopPropagation()
      if (e.target !== chipsContainerRef.current) {
        if (onClick) onClick()
        setIsMenuOpen(!isMenuOpen)
      }
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      handleMenuOpen(e)
    }
  }

  function handleDeleteItem(valueToDelete: string) {
    if (!readonly) {
      setChipItems((previousValue) => previousValue.filter((item) => item.value !== valueToDelete))
    }
  }

  function handleDeleteOther(valueToDelete: string) {
    setChipItems((previousValue) => previousValue.filter((item) => item.value !== valueToDelete))
    setDefaultList((previousValue) => previousValue.filter((item) => item.value !== valueToDelete))
  }

  function isDefaultChecked(value: string) {
    return !!chipItems.filter((item: DropdownOption) => item.value === value).length
  }

  function handleAddNewItem(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      handleOnBlur()
    }
  }

  function onBlurObservation(inputValue: string, observationValue: string) {
    const updatedTempValues = { ...observationValues, [inputValue]: observationValue }
    setObservationValues(updatedTempValues)
    const itemWithObs = chipItems.map((chip: DropdownOption) => {
      if (chip.value === inputValue) {
        return { ...chip, observationValue }
      }
      return chip
    })
    setChipItems(itemWithObs)
  }

  function getCaretColor() {
    if (errorMessage) {
      return error
    }
    if (readonly) {
      return textDisable
    }
    return textPrimary
  }

  return (
    <div className={extraClassOuter}>
      {dropdownLabel && (
        <label
          className="text-fontDefault mb-2 flex"
        >
          {dropdownLabel}
          {required && <span className="text-fontDefault font-medium text-error">*</span>}:
        </label>
      )}
      <div
        className={
          `dropdown-checkbox-container
          ${errorMessage ? 'container-error' : ''}
          ${chipItems.length && !readonly ? 'container-filled' : ''}
          ${readonly ? 'container-readonly' : ''}
          `
        }
        onClick={handleMenuOpen}
        onKeyDown={handleKeyDown}
        onMouseEnter={handleShowTextInfo}
        onMouseLeave={handleHideTextInfo}
        tabIndex={0}
        role="button"
        ref={combinedRef}
        data-testid="dropdown-checkbox-container"
      >
        <div className="dropdown-checkbox-container--chips-wrapper">
          {!chipItems.length ? (
            <span className="text-textDisable italic">{selectPlaceholder}</span>
          ) : (
            <>
              {chipItems.map((chip: DropdownOption) => (
                <div className="chips" key={chip.value} data-testid="chips-item">
                  <span>{chip.label}</span>
                  <span ref={chipsContainerRef}>
                    <IconButton
                      iconType="icon-CloseLight"
                      variant="form-icon"
                      onClick={() => handleDeleteItem(chip.value)}
                      iconColor={textDisable}
                      width="20px"
                      height="20px"
                    />
                  </span>
                </div>
              ))}
            </>
          )}
        </div>
        <FontIcon
          iconType={isMenuOpen ? 'icon-Up2Bold' : 'icon-Down2Bold'}
          fontSize="20px"
          extraClass="dropdown-checkbox-container--arrow"
          color={getCaretColor()}
        />
      </div>
      {showTextInfo && (
        <Alert
          extraClass="alert-dropdown-checkbox"
          message={textInfo}
          indicator="left-top"
        />
      )}
      {errorMessage && <ErrorMessage error={errorMessage} />}
      {isMenuOpen ? (
        ReactDOM.createPortal(
          (
            <div
              className="checkbox-wrapper"
              ref={dropdownListWrapperRef}
              style={{ top: offset.top, left: offset.left, width: offset.width }}
              data-testid="checkbox-wrapper"
            >
              {isLoading ? (
                <div className="checkbox-wrapper--list list-loading">
                  <span className="italic text-textDisable">Carregando...</span>
                </div>
              ) : (
                <>
                  <div className="checkbox-wrapper--list">
                    {defaultList.map((option: DropdownOption) => (
                      <Fragment key={option.value}>
                        <div key={option.value} className="checkbox-wrapper--list-item">
                          <NewestCheckbox
                            label={option.label}
                            value={option.value}
                            name={option.value}
                            defaultChecked={isDefaultChecked(option.value)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              e.stopPropagation()
                              const newChip = {
                                ...option,
                                isChecked: e.currentTarget.checked,
                                isOther: option.isOther || false,
                                obs: observationValues[option.value] || ''
                              }
                              handleChipItem(newChip)
                            }}
                          />
                          {option.isOther && (
                            <IconButton
                              iconType="icon-TrashLight"
                              variant="form-icon"
                              iconColor={textPrimary}
                              onClick={() => handleDeleteOther(option.value)}
                              iconSize="24px"
                              width="24px"
                              height="24px"
                              extraClass="delete-icon"
                            />
                          )}
                        </div>
                        {(extraInfoPlaceholder && isDefaultChecked(option.value)) && (
                          <FormInput
                            name={option.value}
                            id={option.value}
                            placeholder={extraInfoPlaceholder}
                            value={observationValues[option.value] || ''}
                            onChange={(e) => setObservationValues({ ...observationValues, [option.value]: e.currentTarget.value })}
                            onBlur={(e) => onBlurObservation(option.value, e.currentTarget.value)}
                            itemRef={undefined}
                            extraClassContainer="px-4"
                          />
                        )}
                      </Fragment>
                    ))}
                    {isAddNew ? (
                      <div className="extra-item-wrapper">
                        <div className="flex items-center">
                          <NewestCheckbox
                            name={newValue}
                            value={newValue}
                            defaultChecked={!!newValue}
                            componentExtraClass="checkbox--item-new"
                          />
                          <input
                            value={newValue}
                            onChange={(e) => setNewValue(e.currentTarget.value)}
                            onBlur={handleOnBlur}
                            onKeyDown={handleAddNewItem}
                            ref={newValueInputRef}
                            placeholder={extraItemPlaceholder || 'Digite o nome'}
                            data-testid="extra-item-input"
                            className="extra-item-input"
                          />
                          {newValue && (
                            <IconButton
                              iconType="icon-TrashLight"
                              variant="form-icon"
                              iconColor={textPrimary}
                              onClick={undefined}
                              iconSize="24px"
                              width="24px"
                              height="24px"
                            />
                          )}
                        </div>
                        {(extraInfoPlaceholder && newValue) && (
                          <FormInput
                            name={newValue}
                            id={newValue}
                            placeholder={extraInfoPlaceholder}
                            value={observationValues[newValue] || ''}
                            onChange={(e) => setObservationValues({ ...observationValues, [newValue]: e.currentTarget.value })}
                            onBlur={(e) => onBlurObservation(newValue, e.currentTarget.value)}
                            itemRef={undefined}
                          />
                        )}
                        {newValueError && <ErrorMessage error="Item já se encontra na lista" />}
                      </div>
                    ) : null}
                  </div>
                  <button
                    onClick={() => setIsAddNew(true)}
                    type="button"
                    className="extra-item-btn"
                    data-testid="extra-item-btn"
                  >
                    <FontIcon
                      iconType="icon-Plus2Light"
                      fontSize="20px"
                      color={primary}
                    />
                    {extraItemBtnLabel || 'Adicionar item'}
                  </button>
                </>
              )}
            </div>
          ), document.body
        )
      ) : null}
    </div>
  )
})
