/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react'
import { FontIcon } from '../FontIcon/FontIcon'
import { RegularButton } from '../RegularButton/RegularButton'
import { IconButton } from '../IconButton/IconButton'
import {
  bgBox,
  overlay,
  overlayDarkest,
  textSecondary
} from '../../colors'
import './Alert.scss'

export interface Props {
  variant?: 'flowting' | 'alert';
  isLoading?: boolean;
  iconType?: string;
  iconSize?: string;
  iconColor?: string;
  message?: string;
  extraClass?: string;
  id?: string;
  extraInlineButtonText?: string;
  extraInlineButtonAction?: () => void;
  timer?: number;
  isBottom?: boolean;
  onExtraCloseAction?: () => void;
  hasCloseButton?: boolean;
  animation?: string;
  indicator?: 'right-bottom'
  | 'left-bottom'
  | 'center-bottom'
  | 'right'
  | 'left'
  | 'right-top'
  | 'left-top'
  | 'center-top';
}

export function Alert({
  variant = 'flowting',
  isLoading,
  iconType,
  message,
  extraClass = '',
  iconSize = '24px',
  iconColor,
  id,
  extraInlineButtonText,
  extraInlineButtonAction,
  timer,
  isBottom,
  onExtraCloseAction,
  hasCloseButton,
  animation,
  indicator
}: Props) {
  const [isVisible, setIsVisible] = useState(false)
  const [isClosed, setIsClosed] = useState(false)

  useEffect(() => {
    const timeout: NodeJS.Timeout = setTimeout(() => {
      setIsVisible(true)
    }, 200)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  function onClose() {
    setIsVisible(false)
    const timeout: NodeJS.Timeout = setTimeout(() => {
      setIsClosed(true)
    }, 200)
    if (onExtraCloseAction) {
      onExtraCloseAction()
    }
    return () => clearTimeout(timeout)
  }

  useEffect(() => {
    if (timer) {
      const onCloseTimeout = setTimeout(() => {
        onClose()
      }, timer)
      return () => {
        clearTimeout(onCloseTimeout)
      }
    }
  }, [timer])

  useEffect(() => {
    const alertElements = document.querySelectorAll('.alert-bottom')
    if (alertElements && alertElements.length > 1) {
      let marginBottom = 0
      alertElements.forEach((element) => {
        if (element instanceof HTMLDivElement) {
          element.style.marginBottom = `${marginBottom}px`
          marginBottom += element.offsetHeight + 10
        }
      })
    }
  }, [isClosed])

  if (isLoading) {
    return (
      <div className="alert-loading" data-testid="alert-loading" />
    )
  }

  return (
    !isClosed ? (
      <div
        id={id}
        role="alert"
        aria-label={message}
        className={`alert-container
          ${extraClass}
          ${variant}
          ${isVisible ? 'alert-show' : 'alert-hide'}
          ${isBottom ? 'alert-bottom' : ''}
        `}
      >
        {indicator && (
          <div className={`indicator ${indicator}`} data-testid="indicator">
            <FontIcon
              iconType="icon-Down2Bold"
              color={variant === 'alert' ? overlay : overlayDarkest}
              fontSize="22px"
            />
          </div>
        )}
        <div
          className="flex items-center w-full py-4 pl-6 pr-5"
        >
          {iconType && (
            <FontIcon
              iconType={iconType}
              fontSize={iconSize}
              color={iconColor}
              extraClass="mr-4"
              animation={animation}
            />
          )}
          <div className="alert-message-container">
            <p className="alert-message">
              {message}
              {extraInlineButtonText && (
                <RegularButton
                  variant="text"
                  label={extraInlineButtonText}
                  btnSize="small"
                  onClick={extraInlineButtonAction}
                />
              )}
            </p>
          </div>
          {hasCloseButton && (
            <IconButton
              iconType="icon-CloseLight"
              onClick={onClose}
              variant={variant === 'flowting' ? 'dark' : 'text'}
              iconSize="24px"
              width="24px"
              height="24px"
              type="button"
              iconColor={variant === 'flowting' ? bgBox : textSecondary}
              extraClass="ml-4"
            />
          )}
        </div>
      </div>
    ) : null
  )
}
