import React from 'react'

export interface Props {
  color?: string;
  iconType?: string;
  fontSize?: string;
  animation?: string;
  extraClass?: string;
}

export function FontIcon({
  color,
  iconType,
  fontSize,
  animation,
  extraClass
}: Props) {
  return (
    <i
      className={`${iconType} ${extraClass}`}
      style={{
        color,
        fontSize,
        animation
      }}
      aria-label={iconType}
      data-testid={iconType || 'font-icon'}
    />
  )
}
