import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Logo from '../../../../assets/horizontal-logo.svg'
import NavItem from './NavItem'
import './Navigation.scss'
import { AppDispatch } from '../../../state/utils'
import { getFlutterClient } from '../../../utils/helpers'
import { AppState } from '../../../apps/main/store'
import { AppointedSpecialists } from '../../../domain/User'
import { eventTrack } from '../../../../eventGenerate'
import {
  AGENDAR,
  CHAT,
  CONSULTAS,
  CONTENT,
  INICIO,
  PROFILE,
  SAUDE
} from '../../../routes/RoutesConstants'
import { SourceTypes } from '../../../domain/ScheduleFlow'
import { setScheduleSourceAction } from '../../../state/scheduleFlow/actions'

const isActiveMenuItem = (pages: Array<string>) => (
  pages.some((p) => window.location.pathname.includes(p))
)

const initialIsActiveWeekView = isActiveMenuItem([INICIO])
const initialIsActiveMyBookingsView = isActiveMenuItem([CONSULTAS])
const initialIsActiveSchedule = isActiveMenuItem([AGENDAR])
const initialIsActiveContent = isActiveMenuItem([CONTENT])
const initialIsActiveChat = isActiveMenuItem([CHAT])
const initialIsActiveProfile = isActiveMenuItem([PROFILE])
const initialIsActiveHealth = isActiveMenuItem([SAUDE])

type MenuProps = {
  route: string;
  className?: string;
  showNewPrescriptionBadge: boolean;
  hasSpecialist?: Array<AppointedSpecialists> | [];
  insurancePlanCovered?: boolean | null;
  setScheduleSource: (source: SourceTypes) => void;
}

const SideBar = React.memo(({
  route,
  className,
  showNewPrescriptionBadge,
  hasSpecialist,
  insurancePlanCovered,
  setScheduleSource
}: MenuProps) => {
  const history = useHistory()
  const [isActiveWeekView, setIsActiveWeekView] = useState(initialIsActiveWeekView)
  const [isActiveSchedule, setIsActiveSchedule] = useState(initialIsActiveSchedule)
  const [isActiveContent, setIsActiveContent] = useState(initialIsActiveContent)
  const [isActiveChat, setIsActiveChat] = useState(initialIsActiveChat)
  const [isActiveProfile, setIsActiveProfile] = useState(initialIsActiveProfile)
  const [isActiveHealth, setIsActiveHealth] = useState(initialIsActiveHealth)
  const [
    isActiveMyBookingsView,
    setIsActiveMyBookingsView
  ] = useState(initialIsActiveMyBookingsView)

  const updateActiveMenuItem = () => {
    setIsActiveWeekView(isActiveMenuItem([INICIO]))
    setIsActiveMyBookingsView(isActiveMenuItem([CONSULTAS]))
    setIsActiveSchedule(isActiveMenuItem([AGENDAR]))
    setIsActiveContent(isActiveMenuItem([CONTENT]))
    setIsActiveChat(isActiveMenuItem([CHAT]))
    setIsActiveProfile(isActiveMenuItem([PROFILE]))
    setIsActiveHealth(isActiveMenuItem([SAUDE]))
  }

  useEffect(() => {
    updateActiveMenuItem()
  }, [route])

  const handleClick = (newRoute: string) => {
    localStorage.removeItem('bookingSuggestion')
    localStorage.removeItem('scheduleSuggestion')
    history.push(newRoute)
    updateActiveMenuItem()
  }

  const handleClickHealth = () => {
    eventTrack('Clicou Saúde')
    handleClick(SAUDE)
  }

  const handleClickChat = () => {
    eventTrack('Clicou Chat')
    handleClick(CHAT)
  }

  const handleClickWeekView = () => {
    eventTrack('Clicou Início')
    handleClick(INICIO)
  }

  const handleClickMyBookingsView = () => {
    eventTrack('Clicou Consultas')
    handleClick(CONSULTAS)
  }

  const handleClickSchedule = () => {
    eventTrack('Clicou Agendar')
    setScheduleSource(SourceTypes.MENU)
    handleClick(AGENDAR)
  }

  const handleClickContent = () => {
    eventTrack('Clicou Conteúdo')
    handleClick(CONTENT)
  }

  const handleClickProfile = () => {
    eventTrack('Clicou Perfil')
    handleClick(PROFILE)
  }
  if (getFlutterClient()) return null

  return (
    <div className={`sidebar-content ${className}`}>
      <div className="menu__logo">
        <Logo className="logo" alt="" />
      </div>
      <ul role="menu" aria-label="Menu">
        <NavItem
          handleClick={handleClickWeekView}
          isActive={isActiveWeekView}
          text="Início"
          activeIconType="icon-WeekBold"
          iconType="icon-WeekLight"
          variant="sidebar"
        />
        <NavItem
          handleClick={handleClickSchedule}
          isActive={isActiveSchedule}
          text="Agendar"
          activeIconType="icon-CalendarBold"
          iconType="icon-CalendarLight"
          variant="sidebar"
        />
        {((hasSpecialist !== undefined && hasSpecialist.length > 0)
            || (insurancePlanCovered !== null && insurancePlanCovered))
            && (
            <NavItem
              handleClick={handleClickChat}
              isActive={isActiveChat}
              text="Chat"
              activeIconType="icon-TheiaLight"
              iconType="icon-TheiaLight"
              variant="sidebar"
            />
            )}
        <NavItem
          handleClick={handleClickMyBookingsView}
          isActive={isActiveMyBookingsView}
          text="Consultas"
          activeIconType="icon-PhonendoscopeBold"
          iconType="icon-PhonendoscopeLight"
          variant="sidebar"
        />
        <div className="relative">
          <NavItem
            handleClick={handleClickHealth}
            isActive={isActiveHealth}
            text="Saúde"
            activeIconType="icon-MedicalRecordBold"
            iconType="icon-MedicalRecordLight"
            variant="sidebar"
            hasNotificationOnIcon={showNewPrescriptionBadge}
          />
        </div>
        <NavItem
          handleClick={handleClickContent}
          isActive={isActiveContent}
          text="Conteúdo"
          activeIconType="icon-PaperBold"
          iconType="icon-PaperLight"
          variant="sidebar"
        />
        <NavItem
          handleClick={handleClickProfile}
          isActive={isActiveProfile}
          text="Perfil"
          activeIconType="icon-ProfileBold"
          iconType="icon-ProfileLight"
          variant="sidebar"
        />
      </ul>
    </div>
  )
})

const mapStateToProps = ({
  authentication, prescriptions
}: AppState) => ({
  showNewPrescriptionBadge: prescriptions.highlightHealthMenuItem,
  hasSpecialist: authentication.currentUser?.appointedSpecialists,
  insurancePlanCovered: authentication.currentUser?.healthInsurancePlanData.insurancePlanCovered,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  setScheduleSource: setScheduleSourceAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBar)
