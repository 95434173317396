import React from 'react'
import { SimpleCarousel } from 'theia-web-ds'
import { WeekInfo } from '../../domain/WeekFlow'
import { isPreOrPosPregnancy } from '../../utils/helpers'
import { getFruitInfos } from './helpers'
import './WeekCarousel.scss'

interface ItemProps {
  textItem: string;
  isSelected: boolean;
  isCurrentWeek: boolean;
  onSelectWeek: (e: string) => void;
  isFirstOrLast: boolean;
}

function WeekItem({
  textItem,
  isSelected,
  isCurrentWeek,
  onSelectWeek,
  isFirstOrLast
}: ItemProps) {
  const normalWeek = isSelected ? 'Semana' : 'S'
  const caption = isFirstOrLast && isSelected ? 'Semanas' : normalWeek
  const fruitInfos = getFruitInfos(textItem)
  return (
    <button
      type="button"
      aria-label={`Semana ${textItem}. ${isCurrentWeek ? 'Você está aqui.' : ''}`}
      onClick={() => onSelectWeek(textItem)}
      className={`week-item-default
        ${isCurrentWeek ? 'week-item-purple' : 'week-item-grey'}
        ${isSelected ? 'expanded-week-item' : 'normal-week-item'}
      `}
    >
      {isCurrentWeek && isSelected && (
        <div className="current-week-container">
          <p className="current-week-caption">
            {caption}
          </p>
          <p className="current-week-text">Você está aqui</p>
        </div>
      )}
      <div className={`week-name-container ${isCurrentWeek && isSelected ? 'week-name-container-current' : 'week-name-container-not-current'}`}>
        <div className="week-name-text-container">
          {(!isCurrentWeek || !isSelected) && (
            <p className={`week-caption ${isCurrentWeek ? 'text-white' : 'text-textSecondary'}`}>{caption}</p>
          )}
          <p className={`week-name ${isCurrentWeek ? 'text-white' : 'text-textPrimary'}`}>
            {isFirstOrLast && !isSelected ? textItem.split('-')[0] : textItem}
          </p>
          {isSelected && fruitInfos && (
            <p className={`week-name-baby-size-text ${isCurrentWeek ? 'text-white' : 'text-textSecondary'}`}>
              {fruitInfos?.title}
            </p>
          )}
        </div>
        {isSelected && fruitInfos && (
          <div className="week-name-fruit-picture-container">
            <div className="week-name-size-picture">
              {fruitInfos?.picture}
            </div>
          </div>
        )}
      </div>
    </button>
  );
}

interface Props {
  onSelectWeek: (element: string) => void;
  weeks: string[];
  selectedWeek?: WeekInfo;
  currentWeek?: WeekInfo;
  onScrollWeekCarousel?: () => void;
}

function WeekCarousel({
  selectedWeek,
  currentWeek,
  weeks,
  onSelectWeek,
  onScrollWeekCarousel
}: Props) {
  function renderWeek(element: string) {
    const isSelected = selectedWeek?.weekName === element
    return (
      <div className={`week-${element}`}>
        <WeekItem
          key={`item-${element}-${isSelected ? 'selected' : 'not-selected'}`}
          textItem={element}
          isSelected={selectedWeek?.weekName === element}
          isCurrentWeek={currentWeek?.weekName === element}
          onSelectWeek={onSelectWeek}
          isFirstOrLast={isPreOrPosPregnancy(element || '')}
        />
      </div>
    )
  }
  if (!selectedWeek) return null

  const initialSlide = selectedWeek?.weekName ? weeks.indexOf(selectedWeek?.weekName) : 0

  return (
    <div className="carousel-weeks mb-4">
      <SimpleCarousel
        id="carousel-weeks"
        list={weeks}
        carouselId="weeks"
        onRenderElement={renderWeek}
        initialSlide={initialSlide}
        onScrollCarousel={onScrollWeekCarousel}
        cardSize={64}
      />
    </div>
  )
}

export default WeekCarousel
