import { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useWindowActive } from './WindowActive'
import { Message } from '../domain/Chat'
import TitleNotification from '../services/browser/TitleNotification'
import { AppState } from '../apps/main/store'
import { incrementUnreadMessagesCountAction, resetUnreadMessagesCountAction } from '../state/chat/actions'
import BrowserNotification from '../services/browser/BrowserNotification'
import { CHAT } from '../routes/RoutesConstants'

function requestNotificationPermission() {
  if (!window.Notification) return

  window.Notification.requestPermission();
}

export function useNewMessageNotifier() {
  const lastNewMessage = useSelector<AppState, Message | undefined>((state) => (
    state.chat.messages.slice(state.chat.historicalMessagesQuantity).slice(-1)[0]
  ))
  const unreadMessages = useSelector<AppState, number>((state) => state.chat.unreadMessagesCount)

  const dispatch = useDispatch()

  const titleNotification = useRef(new TitleNotification())

  const isWindowActive = useWindowActive()

  const location = useLocation()

  useEffect(() => { requestNotificationPermission() }, [])

  useEffect(() => {
    if (!lastNewMessage || lastNewMessage.isSender) return

    if (location.pathname === CHAT && isWindowActive) return

    dispatch(incrementUnreadMessagesCountAction())
    titleNotification.current.startNotifying(unreadMessages + 1)

    if (lastNewMessage.type === 'schedule') {
      BrowserNotification.sendNotification('Informações de profissional')
    } else {
      BrowserNotification.sendNotification(lastNewMessage.text)
    }
  }, [lastNewMessage])

  useEffect(() => {
    if (location.pathname === CHAT && isWindowActive) {
      dispatch(resetUnreadMessagesCountAction())
      titleNotification.current.stopNotifying()
    }
  }, [location, isWindowActive])
}
