import React, { useEffect, useState } from 'react'
import { IconButton } from '../IconButton/IconButton'
import './SimpleCarousel.scss'

export interface Props {
  list: any[];
  onRenderElement: (element: any) => React.ReactNode;
  carouselId: string;
  id: string;
  initialSlide?: number;
  onScrollCarousel?: () => void;
  cardSize?: number;
}

export function SimpleCarousel({
  list,
  onRenderElement,
  carouselId,
  id,
  initialSlide = 0,
  onScrollCarousel,
  cardSize = 0
}: Props) {
  const isEmpty = list.length === 0
  const [slideLeft, setSlideLeft] = useState(0)
  const [element, setElement] = useState<HTMLElement>()
  const [maxScrollLeft, setMaxScrollLeft] = useState<number>(0)

  useEffect(() => {
    const carousel = document.getElementById(`simple-carousel-${carouselId}`)
    if (carousel) {
      setElement(carousel)
    }
    if (initialSlide && initialSlide !== 0) {
      setSlideLeft(initialSlide * cardSize)
    }
  }, [list])

  useEffect(() => {
    setMaxScrollLeft((element?.scrollWidth || 0) - (element?.clientWidth || 0))
  }, [element])

  useEffect(() => {
    const carousel = document.getElementById(`simple-carousel-${carouselId}`)
    if (carousel) {
      carousel.scrollLeft += (initialSlide * cardSize)
    }
  }, [list])

  function onPrevClick() {
    const slider = document.getElementById(`simple-carousel-${carouselId}`)
    if (onScrollCarousel) {
      onScrollCarousel()
    }
    if (slider) {
      setSlideLeft(slider.scrollLeft -= 60)
    }
  }

  function onNextClick() {
    const slider = document.getElementById(`simple-carousel-${carouselId}`)
    if (onScrollCarousel) {
      onScrollCarousel()
    }
    if (slider) {
      setSlideLeft(slider.scrollLeft += 60)
    }
  }

  return (
    !isEmpty ? (
      <div
        className="simple-carousel"
        key={id}
        data-testid="simple-carousel"
      >
        <div
          id={`simple-carousel-${carouselId}`}
          className="simple-carousel-container"
          data-testid="simple-carousel-container"
        >
          {list.map((item) => (
            <div
              key={typeof item === 'string' || item instanceof String ? item : item.id}
              className="simple-carousel-slider"
            >
              {onRenderElement(item)}
            </div>
          ))}
        </div>
        {maxScrollLeft > 0 && (
          <>
            <div className="prev-arrow-container">
              <div className="arrow-button">
                <IconButton
                  aria-label="Mover para esquerda"
                  variant="subtle"
                  width="44px"
                  height="44px"
                  iconType="icon-ArrowLeft2Light"
                  onClick={onPrevClick}
                  isDisabled={slideLeft <= 0}
                />
              </div>
            </div>
            <div className="next-arrow-container">
              <div className="arrow-button">
                <IconButton
                  aria-label="Mover para direita"
                  variant="subtle"
                  width="44px"
                  height="44px"
                  iconType="icon-ArrowRight2Light"
                  onClick={onNextClick}
                  isDisabled={slideLeft >= maxScrollLeft && slideLeft !== 0}
                />
              </div>
            </div>
          </>
        )}
      </div>
    ) : null
  )
}
