/* eslint-disable indent */
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/pt-br'
import * as Sentry from '@sentry/browser'
import {
  LoginPayload,
  SignUpPayload,
  TOKEN_ACCESS,
  TOKEN_REFRESH,
  UnauthenticatedModalError,
  User,
  UserProvider,
  VerifiedSignUpPayload,
  UserConfirmationInfos,
} from '../../../domain/User'
import { HttpClient } from '../../../services/Http'
import { AsyncAction } from '../../utils'
import { getFlutterClient } from '../../../utils/helpers'
import { LoginFriendlyError } from '../../../domain/errors/LoginFriendlyError'
import { ErrorWithFriendlyMessage, promptErrorAction } from '../../errorWithFriendlyMessage/actions'
import { UserLoginError } from '../../../domain/errors/ApiResponseErrors'
import { TheiaError } from '../../../domain/errors/TheiaError'
import { LoginError } from '../../../domain/errors/LoginError'
import { promptDefaultErrorAction } from '../../defaultError/actions'
import { BOAS_VINDAS } from '../../../routes/RoutesConstants'
import { showNotificationAction } from '../../notifications/actions'
import { NotificationMessage, ToastType } from '../../../domain/Notifications'
import { error as errorColor } from '../../../color'

export const AUTHENTICATED = 'AUTHENTICATED'
export const AUTHENTICATING = 'AUTHENTICATING'
export const UNAUTHENTICATED = 'UNAUTHENTICATED'
export const UPDATE_PAYMENT_TO_PENDING = 'UPDATE_PAYMENT_TO_PENDING'
export const UPDATE_PAYMENT_TO_TRIAL = 'UPDATE_PAYMENT_TO_TRIAL'
export const ACCESS_ONBOARDING = 'ACCESS_ONBOARDING'
export const CLICK_ONBOARDING = 'CLICK_ONBOARDING'
export const ARROW_CLICKED = 'ARROW_CLICKED'
export const CLOSE_COOKIE_INFO = 'CLOSE_COOKIE_INFO'
export const PROMPT_PRIVACY_MODAL = 'PROMPT_PRIVACY_MODAL'
export const SUPPRESS_PRIVACY_MODAL = 'SUPPRESS_PRIVACY_MODAL'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'
export const CLEAR_LOGOUT_FAILED = 'CLEAR_LOGOUT_FAILED'
export const FETCHING_CURRENT_USER = 'FETCHING_CURRENT_USER'
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT'
export const PROMPT_TERMS_MODAL = 'PROMPT_TERMS_MODAL'
export const SUPPRESS_TERMS_MODAL = 'SUPPRESS_TERMS_MODAL'
export const PROMPT_HEALTH_DATA_MODAL = 'PROMPT_HEALTH_DATA_MODAL'
export const SUPPRESS_HEALTH_DATA_MODAL = ' SUPPRESS_HEALTH_DATA_MODAL'
export const PROMPT_COOKIES_MODAL = 'PROMPT_COOKIES_MODAL'
export const SUPPRESS_COOKIES_MODAL = 'SUPPRESS_COOKIES_MODAL'
export const FORCE_FETCHED_USER = 'FORCE_FETCHED_USER'
export const ENABLE_HEALTHPLAN_MODAL = 'ENABLE_HEALTHPLAN_MODAL'
export const SET_FORCE_UPDATE_CURRENT_USER = 'SET_FORCE_UPDATE_CURRENT_USER'
export const CLEAR_FORCE_UPDATE_CURRENT_USER = 'CLEAR_FORCE_UPDATE_CURRENT_USER'

// New Onboarding Flow
export const FREE_MVP_CONTRACT = 'FREE_MVP_CONTRACT'
export const SUPPRESS_RECOVERY_MODAL = 'SUPPRESS_RECOVERY_MODAL'
export const PROMPT_RECOVERY_MODAL = 'PROMPT_RECOVERY_MODAL'

export const PROMPT_UNAUTHENTICATED_MODAL = 'PROMPT_UNAUTHENTICATED_MODAL'
export const SUPPRESS_UNAUTHENTICATED_MODAL = 'SUPPRESS_UNAUTHENTICATED_MODAL'

// Events to Segment
export const SHOW_PRE_LOGIN = 'SHOW_PRE_LOGIN'
export const CLICK_SIGN_UP = 'CLICK_SIGN_UP'
export const CLICK_SIGN_IN = 'CLICK_SIGN_IN'

export const PENDING_PAYMENT_STATUS_MODAL_SHOWED = 'PENDING_PAYMENT_STATUS_MODAL_SHOWED'
export const POST_LOGIN_UNLOCK = 'POST_LOGIN_UNLOCK'
export const SUCCESS_POST_LOGIN_UNLOCK = 'SUCCESS_POST_LOGIN_UNLOCK'
export const FAILURE_POST_LOGIN_UNLOCK = 'FAILURE_POST_LOGIN_UNLOCK'
export const POST_EMAIL_UNLOCK = 'POST_EMAIL_UNLOCK'
export const SUCCESS_POST_EMAIL_UNLOCK = 'SUCCESS_POST_EMAIL_UNLOCK'
export const FAILURE_POST_EMAIL_UNLOCK = 'FAILURE_POST_EMAIL_UNLOCK'
export interface ForceFetchedUserActionType {
  type: typeof FORCE_FETCHED_USER;
}

export const POST_LOGIN = 'POST_LOGIN'
export const SUCCESS_POST_LOGIN = 'SUCCESS_POST_LOGIN'
export const FAILURE_POST_LOGIN = 'FAILURE_POST_LOGIN'

export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const SUCCESS_REFRESH_TOKEN = 'SUCCESS_REFRESH_TOKEN'
export const FAILURE_REFRESH_TOKEN = 'FAILURE_REFRESH_TOKEN'

export const HANDLE_SIGNUP = 'HANDLE_SIGNUP'
export const SUCCESS_HANDLE_SIGNUP = 'SUCCESS_HANDLE_SIGNUP'
export const FAILURE_HANDLE_SIGNUP = 'FAILURE_HANDLE_SIGNUP'

export const HANDLE_PASSWORD_RECOVERY = 'HANDLE_PASSWORD_RECOVERY'
export const SUCCESS_HANDLE_PASSWORD_RECOVERY = 'SUCCESS_HANDLE_PASSWORD_RECOVERY'
export const FAILURE_HANDLE_PASSWORD_RECOVERY = 'FAILURE_HANDLE_PASSWORD_RECOVERY'

export const GET_USER_CONFIRMATION_INFOS = 'GET_USER_CONFIRMATION_INFOS'
export const SUCCESS_GET_USER_CONFIRMATION_INFOS = 'SUCCESS_GET_USER_CONFIRMATION_INFOS'
export const FAILURE_GET_USER_CONFIRMATION_INFOS = 'FAILURE_GET_USER_CONFIRMATION_INFOS'

export const VERIFY_SIGNUP_DATA = 'VERIFY_SIGNUP_DATA'
export const SUCCESS_VERIFY_SIGNUP_DATA = 'SUCCESS_VERIFY_SIGNUP_DATA'
export const FAILURE_VERIFY_SIGNUP_DATA = 'FAILURE_VERIFY_SIGNUP_DATA'
export const CLEAR_VERIFY_DATA_STATUS = 'CLEAR_VERIFY_DATA_STATUS'

export const VERIFIED_SIGNUP = 'VERIFIED_SIGNUP'
export const SUCCESS_VERIFIED_SIGNUP = 'SUCCESS_VERIFIED_SIGNUP'
export const FAILURE_VERIFIED_SIGNUP = 'FAILURE_VERIFIED_SIGNUP'
export const CLEAR_SIGN_UP_ERRORS = 'CLEAR_SIGN_UP_ERRORS'

export const SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE'
export const SUCCESS_SEND_VERIFICATION_CODE = 'SUCCESS_SEND_VERIFICATION_CODE'
export const FAILURE_SEND_VERIFICATION_CODE = 'FAILURE_SEND_VERIFICATION_CODE'

export const CLEAR_VERIFICATION_CODE_STATUS = 'CLEAR_VERIFICATION_CODE_STATUS'

export interface AuthenticatedActionType {
  type: typeof AUTHENTICATED;
  currentUser: User;
}

export interface AuthenticatingActionType {
  type: typeof AUTHENTICATING;
}

export interface UnauthenticatedActionType {
  type: typeof UNAUTHENTICATED;
}

export interface UpdatePaymentToPendingActionType {
  type: typeof UPDATE_PAYMENT_TO_PENDING;
}

export interface UpdatePaymentToTrialActionType {
  type: typeof UPDATE_PAYMENT_TO_TRIAL;
}

export interface AccessOnboardingActionType {
  type: typeof ACCESS_ONBOARDING;
  accessOnboardingItem: number;
}

export interface ClickOnboardingActionType {
  type: typeof CLICK_ONBOARDING;
  clickOnboardingItem: number;
}

export interface ArrowClickedOnboardingActionType {
  type: typeof ARROW_CLICKED;
  arrowClicked: string;
  clickOnboardingItem: number;
}

interface CloseCookieInfoActionType {
  type: typeof CLOSE_COOKIE_INFO;
}

interface PromptPrivacyModalActionType {
  type: typeof PROMPT_PRIVACY_MODAL;
}

interface SuppressPrivacyModalActionType {
  type: typeof SUPPRESS_PRIVACY_MODAL;
}

interface PromptTermsModalActionType {
  type: typeof PROMPT_TERMS_MODAL;
}

interface SuppressTermsModalActionType {
  type: typeof SUPPRESS_TERMS_MODAL;
}

interface PromptHealthDataModalActionType {
  type: typeof PROMPT_HEALTH_DATA_MODAL;
}

interface SuppressHealthDataModalActionType {
  type: typeof SUPPRESS_HEALTH_DATA_MODAL;
}

interface PromptCookiesModalActionType {
  type: typeof PROMPT_COOKIES_MODAL;
}

interface SuppressCookiesModalActionType {
  type: typeof SUPPRESS_COOKIES_MODAL;
}

interface RequestLogoutActionType {
  type: typeof REQUEST_LOGOUT;
}

// New Onboarding Flow

export interface FreeMvpContractActionType {
  type: typeof FREE_MVP_CONTRACT;
}

interface PromptUnauthenticatedModalActionType {
  type: typeof PROMPT_UNAUTHENTICATED_MODAL;
  errorType: UnauthenticatedModalError;
}

interface SuppressUnauthenticatedModalActionType {
  type: typeof SUPPRESS_UNAUTHENTICATED_MODAL;
}

interface FetchingCurrentUserActionType {
  type: typeof FETCHING_CURRENT_USER;
}

// Events to Segment
export interface SuppressRecoveryModalActionType {
  type: typeof SUPPRESS_RECOVERY_MODAL;
}

export interface PromptRecoveryModalActionType {
  type: typeof PROMPT_RECOVERY_MODAL;
}

interface ShowPreLoginActionType {
  type: typeof SHOW_PRE_LOGIN;
}

interface ClickSignUpActionType {
  type: typeof CLICK_SIGN_UP;
}

interface ClickSignInActionType {
  type: typeof CLICK_SIGN_IN;
}

interface LogoutFailedActionType {
  type: typeof LOGOUT_FAILED;
}

interface ClearLogoutFailedActionType {
  type: typeof CLEAR_LOGOUT_FAILED;
}

interface PendingPaymentStatusModalShowedActionType {
  type: typeof PENDING_PAYMENT_STATUS_MODAL_SHOWED;
}

interface EnableHealthPlanModalActionType {
  type: typeof ENABLE_HEALTHPLAN_MODAL;
}

interface PostLoginUnlockActionType {
  type: typeof POST_LOGIN_UNLOCK;
}

interface SuccessPostLoginUnlockActionType {
  type: typeof SUCCESS_POST_LOGIN_UNLOCK;
}

interface FailurePostLoginUnlockActionType {
  type: typeof FAILURE_POST_LOGIN_UNLOCK;
  unlockErrorMessage?: string;
  resendLink?: boolean;
  redirect?: boolean;
}

interface PostEmailUnlockActionType {
  type: typeof POST_EMAIL_UNLOCK;
}

interface SuccessPostEmailUnlockActionType {
  type: typeof SUCCESS_POST_EMAIL_UNLOCK;
}

interface FailurePostEmailUnlockActionType {
  type: typeof FAILURE_POST_EMAIL_UNLOCK;
  unlockErrorMessage?: string;
  isFailureEmail: boolean;
}

interface SetForceUpdateCurrentUserActionType {
  type: typeof SET_FORCE_UPDATE_CURRENT_USER;
}
interface ClearForceUpdateCurrentUserActionType {
  type: typeof CLEAR_FORCE_UPDATE_CURRENT_USER;
}

// login

interface PostLoginActionType {
  type: typeof POST_LOGIN;
}

interface SuccessPostLoginActionType {
  type: typeof SUCCESS_POST_LOGIN;
}

interface FailurePostLoginActionType {
  type: typeof FAILURE_POST_LOGIN;
  error: UserLoginError;
}

// refresh token

interface RefreshTokenActionType {
  type: typeof REFRESH_TOKEN;
}

interface SuccessRefreshTokenActionType {
  type: typeof SUCCESS_REFRESH_TOKEN;
  currentRefreshToken: string;
}

interface FailureRefreshTokenActionType {
  type: typeof FAILURE_REFRESH_TOKEN;
}

interface HandleSignUpActionType {
  type: typeof HANDLE_SIGNUP;
}

interface SuccessHandleSignUpActionType {
  type: typeof SUCCESS_HANDLE_SIGNUP;
  consultantId: string;
}

interface FailureHandleSignUpActionType {
  type: typeof FAILURE_HANDLE_SIGNUP;
}

interface HandlePasswordRecoveryActionType {
  type: typeof HANDLE_PASSWORD_RECOVERY;
}

interface SuccessHandlePasswordRecoveryActionType {
  type: typeof SUCCESS_HANDLE_PASSWORD_RECOVERY;
  consultantId: string;
}

interface FailureHandlePasswordRecoveryActionType {
  type: typeof FAILURE_HANDLE_PASSWORD_RECOVERY;
}

interface VerifySignUpDataActionType {
  type: typeof VERIFY_SIGNUP_DATA;
}

interface SuccessVerifySignUpDataActionType {
  type: typeof SUCCESS_VERIFY_SIGNUP_DATA;
  signUpUserData: SignUpPayload;
}

interface FailureVerifySignUpDataActionType {
  type: typeof FAILURE_VERIFY_SIGNUP_DATA;
  error?: TheiaError;
}

interface ClearVerifyDataStatusActionType {
  type: typeof CLEAR_VERIFY_DATA_STATUS;
}

interface VerifiedSignUpActionType {
  type: typeof VERIFIED_SIGNUP;
}

interface SuccessVerifiedSignUpActionType {
  type: typeof SUCCESS_VERIFIED_SIGNUP;
}

interface FailureVerifiedSignUpActionType {
  type: typeof FAILURE_VERIFIED_SIGNUP;
  error?: TheiaError;
}

interface ClearSignUpErrorsActionType {
  type: typeof CLEAR_SIGN_UP_ERRORS;
}

interface SendVerificationCodeActionType {
  type: typeof SEND_VERIFICATION_CODE;
}

interface SuccessSendVerificationCodeActionType {
  type: typeof SUCCESS_SEND_VERIFICATION_CODE;
}

interface FailureSendVerificationCodeActionType {
  type: typeof FAILURE_SEND_VERIFICATION_CODE;
  error?: TheiaError;
}

interface GetUserConfirmationInfos {
  type: typeof GET_USER_CONFIRMATION_INFOS;
}

interface SuccessGetUserConfirmationInfos {
  type: typeof SUCCESS_GET_USER_CONFIRMATION_INFOS;
  userConfirmationInfos: UserConfirmationInfos;
}

interface FailureGetUserConfirmationInfos {
  type: typeof FAILURE_GET_USER_CONFIRMATION_INFOS;
}

interface ClearVerificationCodeStatusActionType {
  type: typeof CLEAR_VERIFICATION_CODE_STATUS
}

export type AuthenticationActionTypes =
  AuthenticatedActionType |
  AuthenticatingActionType |
  UnauthenticatedActionType |
  UpdatePaymentToPendingActionType |
  UpdatePaymentToTrialActionType |
  AccessOnboardingActionType |
  ClickOnboardingActionType |
  ArrowClickedOnboardingActionType |
  FreeMvpContractActionType |
  SuppressRecoveryModalActionType |
  PromptRecoveryModalActionType |
  CloseCookieInfoActionType |
  PromptPrivacyModalActionType |
  SuppressPrivacyModalActionType |
  LogoutFailedActionType |
  ClearLogoutFailedActionType |
  PendingPaymentStatusModalShowedActionType |
  FetchingCurrentUserActionType |
  RequestLogoutActionType |
  SuppressTermsModalActionType |
  PromptTermsModalActionType |
  PromptHealthDataModalActionType |
  SuppressHealthDataModalActionType |
  PromptCookiesModalActionType |
  SuppressCookiesModalActionType |
  ForceFetchedUserActionType |
  EnableHealthPlanModalActionType |
  PostLoginUnlockActionType |
  SuccessPostLoginUnlockActionType |
  FailurePostLoginUnlockActionType |
  PostEmailUnlockActionType |
  SuccessPostEmailUnlockActionType |
  FailurePostEmailUnlockActionType |
  SetForceUpdateCurrentUserActionType |
  ClearForceUpdateCurrentUserActionType |
  PostLoginActionType |
  SuccessPostLoginActionType |
  FailurePostLoginActionType |
  RefreshTokenActionType |
  SuccessRefreshTokenActionType |
  FailureRefreshTokenActionType |
  PromptUnauthenticatedModalActionType |
  SuppressUnauthenticatedModalActionType |
  HandleSignUpActionType |
  SuccessHandleSignUpActionType |
  FailureHandleSignUpActionType |
  HandlePasswordRecoveryActionType |
  SuccessHandlePasswordRecoveryActionType |
  FailureHandlePasswordRecoveryActionType |
  VerifySignUpDataActionType |
  SuccessVerifySignUpDataActionType |
  FailureVerifySignUpDataActionType |
  VerifiedSignUpActionType |
  SuccessVerifiedSignUpActionType |
  FailureVerifiedSignUpActionType |
  ClearSignUpErrorsActionType |
  ClearVerifyDataStatusActionType |
  GetUserConfirmationInfos |
  SuccessGetUserConfirmationInfos |
  FailureGetUserConfirmationInfos |
  SendVerificationCodeActionType |
  SuccessSendVerificationCodeActionType |
  FailureSendVerificationCodeActionType |
  ClearVerificationCodeStatusActionType

export const authenticateAction = (currentUser: User): AuthenticatedActionType => ({
  type: AUTHENTICATED,
  currentUser,
})

export const isAuthenticatingAction = (): AuthenticatingActionType => ({
  type: AUTHENTICATING
})

export const unauthenticateAction = (): UnauthenticatedActionType => ({
  type: UNAUTHENTICATED
})

export const enableHealthPlanModalAction = (): EnableHealthPlanModalActionType => ({
  type: ENABLE_HEALTHPLAN_MODAL
})

export const freeMvpContractAction = (): FreeMvpContractActionType => ({
  type: FREE_MVP_CONTRACT
})

export const promptUnauthenticatedModalAction = (errorType: UnauthenticatedModalError):
  PromptUnauthenticatedModalActionType => ({
  type: PROMPT_UNAUTHENTICATED_MODAL,
  errorType
})

export const suppressUnauthenticatedModalAction = ():
  SuppressUnauthenticatedModalActionType => ({
  type: SUPPRESS_UNAUTHENTICATED_MODAL
})

export const pendingPaymentStatusModalShowedAction = ():
  PendingPaymentStatusModalShowedActionType => ({
    type: PENDING_PAYMENT_STATUS_MODAL_SHOWED
  })

export function loginAction(token?: string):
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { httpClient, userProvider }) => {
    try {
      dispatch(isAuthenticatingAction())
      const user: any = await userProvider.getCurrentUser(token)
      if (user.freeMvpContract === true) {
        dispatch(freeMvpContractAction())
      }
      const currentUser = {
        ...user.consultant,
        plan: {
          ...user.plan,
          trialFinalDate: user.plan.trialFinalDate,
        },
        companyName: user.companyName,
        companyBusinessType: user.companyBusinessType,
        companyBenefitType: user.companyBenefitType,
        isTest: user.consultant.isTest,
        canGoInPerson: user.consultant.canGoInPerson,
        gender: user?.profile?.gender,
        pregnancyMoment: user?.profile?.pregnancyMoment,
        healthInsurancePlanData: user?.healthInsurancePlanData,
        pregnancyMomentText: user?.profile?.pregnancyMomentText,
        stateAbbreviation: user?.profile?.address?.stateAbbreviation,
        city: user?.profile?.address?.cityName,
        expectedBabyBirthDate: user?.profile?.expectedBabyBirthDate,
        lastPeriodStartDate: user?.profile?.lastPeriodStartDate,
        appointedSpecialists: user?.appointedSpecialists,
        birthDate: user?.profile?.birthDate,
        nationalIdentityCard: user?.profile?.nationalIdentityCard
      }
      moment.updateLocale('pt-BR', currentUser.locale)
      moment.tz.setDefault(currentUser.timezone)

      httpClient.setUnauthorizedCallback(() => {
        dispatch(unauthenticateAction())
      })
      dispatch(authenticateAction(currentUser))
      dispatch(enableHealthPlanModalAction())
    } catch (error) {
      dispatch(unauthenticateAction())
    }
  }
}

export const logoutFailedAction = (): LogoutFailedActionType => ({
  type: LOGOUT_FAILED
})

export const clearLogoutFailedAction = (): ClearLogoutFailedActionType => ({
  type: CLEAR_LOGOUT_FAILED
})

export function logoutAction():
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { userProvider }) => {
    const refreshToken = localStorage.getItem('refreshToken') as string
    dispatch({ type: REQUEST_LOGOUT })
    try {
      await userProvider.logout(refreshToken)
      localStorage.clear()
      window.location.href = BOAS_VINDAS
      dispatch(unauthenticateAction())
    } catch (error) {
      dispatch(logoutFailedAction())
      Sentry.captureException(new Error(`Failure to logout - ${error}`))
    }
  }
}

export function updateCurrentUserInfosAction():
  AsyncAction<{}, { userProvider: UserProvider }> {
  return async (dispatch, _getState, { userProvider }) => {
    dispatch({ type: FETCHING_CURRENT_USER })
    try {
      const user: any = await userProvider.getCurrentUser()

      if (user.freeMvpContract === true) {
        dispatch(freeMvpContractAction())
      }
      const currentUser = {
        ...user.consultant,
        plan: {
          ...user.plan,
          trialFinalDate: user.plan.trialFinalDate,
        },
        companyName: user.companyName,
        companyBusinessType: user.companyBusinessType,
        companyBenefitType: user.companyBenefitType,
        pregnancyMoment: user?.profile?.pregnancyMoment,
        healthInsurancePlanData: user?.healthInsurancePlanData,
        pregnancyMomentText: user?.profile?.pregnancyMomentText,
        stateAbbreviation: user?.profile?.address?.stateAbbreviation,
        city: user?.profile?.address?.cityName,
        expectedBabyBirthDate: user?.profile?.expectedBabyBirthDate,
        lastPeriodStartDate: user?.profile?.lastPeriodStartDate,
        appointedSpecialists: user?.appointedSpecialists,
        birthDate: user?.profile?.birthDate,
        nationalIdentityCard: user?.profile?.nationalIdentityCard
      }
      dispatch(authenticateAction(currentUser))
    } catch {
      dispatch(unauthenticateAction())
    }
  }
}

export const forceFetchedUserAction = (): ForceFetchedUserActionType => ({
  type: FORCE_FETCHED_USER
})

export const updatePaymentToPendingAction = (): UpdatePaymentToPendingActionType => (
  { type: UPDATE_PAYMENT_TO_PENDING }
)

export const updatePaymentToTrialAction = (): UpdatePaymentToTrialActionType => (
  { type: UPDATE_PAYMENT_TO_TRIAL }
)

export const accessOnboardingAction = (accessOnboardingItem: number):
  AccessOnboardingActionType => (
  { type: ACCESS_ONBOARDING, accessOnboardingItem }
)

export const clickOnboardingAction = (clickOnboardingItem: number):
  ClickOnboardingActionType => (
  { type: CLICK_ONBOARDING, clickOnboardingItem }
)

export const arrowClickedOnboardingAction = (arrowClicked: string, clickOnboardingItem: number):
  ArrowClickedOnboardingActionType => (
  { type: ARROW_CLICKED, arrowClicked, clickOnboardingItem }
)

export const promptRecoveryModalAction = (): PromptRecoveryModalActionType => ({
  type: PROMPT_RECOVERY_MODAL
})

export const suppressRecoveryModalAction = (): SuppressRecoveryModalActionType => ({
  type: SUPPRESS_RECOVERY_MODAL
})

export function onCloseCookieInfoAction():
  AsyncAction<{}, {}> {
  return async (dispatch) => {
    localStorage.setItem('CookiesInfoWasShown', 'true')
    dispatch({ type: CLOSE_COOKIE_INFO })
  }
}

export const promptPrivacyModalAction = (): PromptPrivacyModalActionType => ({
  type: PROMPT_PRIVACY_MODAL
})

export const suppressPrivacyModalAction = (): SuppressPrivacyModalActionType => ({
  type: SUPPRESS_PRIVACY_MODAL
})

export const promptTermsModalAction = (): PromptTermsModalActionType => ({
  type: PROMPT_TERMS_MODAL
})

export const suppressTermsModalAction = (): SuppressTermsModalActionType => ({
  type: SUPPRESS_TERMS_MODAL
})

export const promptHealthDataModalAction = (): PromptHealthDataModalActionType => ({
  type: PROMPT_HEALTH_DATA_MODAL
})

export const suppressHealthDataModalAction = (): SuppressHealthDataModalActionType => ({
  type: SUPPRESS_HEALTH_DATA_MODAL
})

export const promptCookiesModalAction = (): PromptCookiesModalActionType => ({
  type: PROMPT_COOKIES_MODAL
})

export const suppressCookiesModalAction = (): SuppressCookiesModalActionType => ({
  type: SUPPRESS_COOKIES_MODAL
})

// Analytics new onboarding
export const onShowPreLoginAction = (): ShowPreLoginActionType => ({
  type: SHOW_PRE_LOGIN
})

export const onClickSignUpAction = (): ClickSignUpActionType => ({
  type: CLICK_SIGN_UP
})

export const onClickSignInAction = (): ClickSignInActionType => ({
  type: CLICK_SIGN_IN
})

export function postLoginUnlockAction(token: string):
  AsyncAction<{}, { userProvider: UserProvider }> {
  return async (dispatch, _getState, { userProvider }) => {
    dispatch({ type: POST_LOGIN_UNLOCK })
    try {
      await userProvider.postLoginUnlock(token)
      dispatch({ type: SUCCESS_POST_LOGIN_UNLOCK })
    } catch (error) {
      if (error instanceof LoginError) {
        const { resendLink, redirect, message } = error
        dispatch({
          type: FAILURE_POST_LOGIN_UNLOCK,
          unlockErrorMessage: message,
          resendLink,
          redirect
        })
        Sentry.captureException(new Error(`Failure to unlock login - ${error.statusCode} - ${error.message}`))
      }
    }
  }
}

export function postEmailUnlockAction(login: string):
  AsyncAction<{}, { userProvider: UserProvider }> {
  return async (dispatch, _getState, { userProvider }) => {
    dispatch({ type: POST_EMAIL_UNLOCK })
    try {
      await userProvider.postEmailUnlock(login)
      dispatch({ type: SUCCESS_POST_EMAIL_UNLOCK })
    } catch (error) {
      if (error instanceof TheiaError) {
        const unlockErrorMessage = undefined
        const isFailureEmail = false
        dispatch(promptErrorAction())
        dispatch({ type: FAILURE_POST_EMAIL_UNLOCK, unlockErrorMessage, isFailureEmail })
        Sentry.captureException(new Error(`Failure to unlock email - ${error}`))
      }
    }
  }
}

export const setForceUpdateCurrentUserAction = ():
  SetForceUpdateCurrentUserActionType => (
    { type: SET_FORCE_UPDATE_CURRENT_USER }
  )

export const clearForceUpdateCurrentUserAction = ():
  ClearForceUpdateCurrentUserActionType => (
  { type: CLEAR_FORCE_UPDATE_CURRENT_USER }
)

export function postLoginAction(payload: LoginPayload):
  AsyncAction<{}, { userProvider: UserProvider }> {
  return async (dispatch, _getState, { userProvider }) => {
    dispatch({ type: POST_LOGIN })
    try {
      const response = await userProvider.postLogin(payload)
      dispatch({ type: SUCCESS_POST_LOGIN })
      const flutterClient = getFlutterClient()
      const { accessToken, refreshToken } = response
      if (flutterClient) {
        flutterClient.callHandler('tellMobileToken', {
          accessToken,
          refreshToken,
          isFinished: true
        })
      }
      localStorage.setItem(TOKEN_ACCESS, accessToken)
      localStorage.setItem(TOKEN_REFRESH, refreshToken)
      localStorage.removeItem('displayCaptcha')
    } catch (error) {
      if (error instanceof LoginFriendlyError) {
        dispatch({
          type: FAILURE_POST_LOGIN,
          error
        })
        if (error.statusCode === 500) {
          dispatch(promptDefaultErrorAction())
        }
        if (error.statusCode !== 401) {
          Sentry.captureException(new Error(`Failure to login - statusCode ${error?.statusCode}, ${error?.friendlyMessage?.message}`))
        }
      }
    }
  }
}

export function handleRefreshTokenAction():
  AsyncAction<{}, { userProvider: UserProvider }> {
    return async (dispatch, _getState, { userProvider }) => {
      dispatch({ type: REFRESH_TOKEN })
      try {
        const refreshToken = localStorage.getItem(TOKEN_REFRESH) || ''
        const response = await userProvider.handleRefreshToken(refreshToken)
        const { accessToken, refreshToken: newRefreshToken } = response
        dispatch({ type: SUCCESS_REFRESH_TOKEN, currentRefreshToken: newRefreshToken })
        localStorage.setItem(TOKEN_ACCESS, accessToken)
        localStorage.setItem(TOKEN_REFRESH, newRefreshToken)
      } catch (error) {
        dispatch({ type: FAILURE_REFRESH_TOKEN })
        if (error instanceof TheiaError) {
          dispatch(promptUnauthenticatedModalAction(UnauthenticatedModalError.GENERAL_ERROR))
          Sentry.captureException(new Error(`Failure to refresh token - statusCode ${error?.statusCode} - ${error.message} - ${error.friendlyMessageTitle} - ${error.friendlyMessage}`))
        }
      }
    }
  }

export function handleSignUpAction(payload: SignUpPayload):
  AsyncAction<{}, { userProvider: UserProvider }> {
    return async (dispatch, _getState, { userProvider }) => {
      dispatch({ type: HANDLE_SIGNUP })
      try {
        const response = await userProvider.handleSignUp(payload)
        const {
          accessToken,
          refreshToken,
          consultantId
        } = response
        dispatch({ type: SUCCESS_HANDLE_SIGNUP, consultantId })
        localStorage.setItem(TOKEN_ACCESS, accessToken)
        localStorage.setItem(TOKEN_REFRESH, refreshToken)
      } catch (error) {
        if (error instanceof TheiaError) {
          dispatch({ type: FAILURE_HANDLE_SIGNUP })
          const signupError: ErrorWithFriendlyMessage = {
            friendlyMessageTitle: error.friendlyMessageTitle,
            friendlyMessage: error.friendlyMessage
          }
          dispatch(promptErrorAction(signupError))
          Sentry.captureException(new Error(`Failure to signup - statusCode ${error?.statusCode}, ${error.friendlyMessage}`))
        }
      }
    }
  }

  export function handlePasswordRecoveryAction(email: string):
  AsyncAction<{}, { userProvider: UserProvider }> {
    return async (dispatch, _getState, { userProvider }) => {
      dispatch({ type: HANDLE_PASSWORD_RECOVERY })
      try {
        await userProvider.handlePasswordRecovery(email)
        dispatch({ type: SUCCESS_HANDLE_PASSWORD_RECOVERY })
      } catch (error) {
        if (error instanceof TheiaError) {
          dispatch({ type: FAILURE_HANDLE_PASSWORD_RECOVERY })
          dispatch(promptErrorAction())
          Sentry.captureException(new Error(`Failure to recover password - statusCode ${error?.statusCode}, ${error.friendlyMessage}`))
        }
      }
    }
  }

export function verifySignUpDataAction(signUpData: SignUpPayload):
  AsyncAction<{}, { userProvider: UserProvider }> {
    return async (dispatch, _getState, { userProvider }) => {
      dispatch({ type: VERIFY_SIGNUP_DATA })
      try {
        await userProvider.verifySignUpData(signUpData)
        dispatch({ type: SUCCESS_VERIFY_SIGNUP_DATA, signUpUserData: signUpData })
      } catch (error) {
        if (error instanceof TheiaError) {
          dispatch({ type: FAILURE_VERIFY_SIGNUP_DATA, error })
          const verifySignupError: ErrorWithFriendlyMessage = {
            friendlyMessageTitle: error.friendlyMessageTitle,
            friendlyMessage: error.friendlyMessage,
            statusCode: error.statusCode,
            message: error.message
          }
          dispatch(promptErrorAction(verifySignupError))
          Sentry.captureException(new Error(`Failure verifying signup data, ${error.statusCode} - ${error.message} - ${error.friendlyMessageTitle} - ${error.friendlyMessage}`))
        }
      }
    }
  }

export const clearVerifyDataStatusAction = ():
  ClearVerifyDataStatusActionType => ({
  type: CLEAR_VERIFY_DATA_STATUS
})

  /** TODO
   * Quando a implementação estiver estável, mudar nome da função
   * verifiedSignUpAction para apenas signUpAction e remover o antigo
   */
export function verifiedSignUpAction(signUpData: VerifiedSignUpPayload):
  AsyncAction<{}, { userProvider: UserProvider }> {
    return async (dispatch, _getState, { userProvider }) => {
      dispatch({ type: VERIFIED_SIGNUP })
      try {
        const response = await userProvider.verifiedSignUp(signUpData)
        const {
          accessToken,
          refreshToken,
          consultantId
        } = response
        dispatch({ type: SUCCESS_VERIFIED_SIGNUP, consultantId })
        localStorage.setItem(TOKEN_ACCESS, accessToken)
        localStorage.setItem(TOKEN_REFRESH, refreshToken)
      } catch (error) {
        if (error instanceof TheiaError) {
          dispatch({ type: FAILURE_VERIFIED_SIGNUP, error })
          Sentry.captureException(new Error(`Failure creating verified signup - ${error?.statusCode} - ${error?.message} - ${error?.friendlyMessageTitle} - ${error?.friendlyMessage}`))
        }
      }
    }
  }

export const clearSignUpErrorsAction = ():
  ClearSignUpErrorsActionType => ({
  type: CLEAR_SIGN_UP_ERRORS
})

  export function sendVerificationCodeAction(email?: string, phone?: string):
  AsyncAction<{}, { userProvider: UserProvider }> {
    return async (dispatch, _getState, { userProvider }) => {
      dispatch({ type: SEND_VERIFICATION_CODE })
      try {
        await userProvider.sendVerificationCode(email, phone)
        dispatch({ type: SUCCESS_SEND_VERIFICATION_CODE })
      } catch (error) {
        if (error instanceof TheiaError) {
          dispatch({ type: FAILURE_SEND_VERIFICATION_CODE, error })
          const resendCodeError: ErrorWithFriendlyMessage = {
            friendlyMessageTitle: error.friendlyMessageTitle,
            friendlyMessage: error.friendlyMessage,
            statusCode: error.statusCode,
            message: error.message
          }
          dispatch(promptErrorAction(resendCodeError))
          Sentry.captureException(new Error(`Failure sending verification code to email, ${error.statusCode} - ${error.message} - ${error.friendlyMessageTitle} - ${error.friendlyMessage}`))
        }
      }
    }
  }

  export const clearVerificationCodeStatusAction = (): ClearVerificationCodeStatusActionType => ({
    type: CLEAR_VERIFICATION_CODE_STATUS
  })

  export function getUserConfirmationInfosAction():
  AsyncAction<{}, { userProvider: UserProvider }> {
    return async (dispatch, _getState, { userProvider }) => {
      dispatch({ type: GET_USER_CONFIRMATION_INFOS })
      try {
        const userConfirmationInfos = await userProvider.getUserConfirmationInfos()
        dispatch({ type: SUCCESS_GET_USER_CONFIRMATION_INFOS, userConfirmationInfos })
      } catch (error) {
        if (error instanceof TheiaError) {
          dispatch({ type: FAILURE_GET_USER_CONFIRMATION_INFOS })
          Sentry.captureException(new Error(`Failure to get user confirmation status - statusCode ${error?.statusCode}, ${error.friendlyMessage}`))
        }
      }
    }
  }

  export function sendSignUpVerificationCodeAction(email: string):
  AsyncAction<{}, { userProvider: UserProvider }> {
    return async (dispatch, _getState, { userProvider }) => {
      dispatch({ type: SEND_VERIFICATION_CODE })
      try {
        await userProvider.sendSignUpVerificationCode(email)
        dispatch({ type: SUCCESS_SEND_VERIFICATION_CODE })
      } catch (error) {
        if (error instanceof TheiaError) {
          dispatch({ type: FAILURE_SEND_VERIFICATION_CODE, error })
          dispatch(showNotificationAction({
            message: error.friendlyMessage
              || error.friendlyMessageTitle
              || NotificationMessage.ERROR_RESENDING,
            iconColor: errorColor,
            iconType: 'icon-DangerTriangleLight',
            timer: 5000,
            id: `FAILURE_SEND_VERIFICATION_CODE ${Date.now()}`,
            type: ToastType.ERROR
          }))
          Sentry.captureException(new Error(`Failure sending verification code to email, ${error.statusCode} - ${error.message} - ${error.friendlyMessageTitle} - ${error.friendlyMessage}`))
        }
      }
    }
  }
