export const SALES_ONBOARDING_MOMENT_ADDED = 'SALES_ONBOARDING_MOMENT_ADDED'
export const SALES_ONBOARDING_LIVE_IN_SP_ADDED = 'SALES_ONBOARDING_LIVE_IN_SP_ADDED'
export const SHOW_SCHEDULE_PROFILE_MODAL = 'SHOW_SCHEDULE_PROFILE_MODAL'

export interface SalesOnboardingLiveInSpAddedActionType {
  type: typeof SALES_ONBOARDING_LIVE_IN_SP_ADDED;
  liveInSp: string;
}

export interface SalesOnboardingMomentAddedActionType {
  type: typeof SALES_ONBOARDING_MOMENT_ADDED;
  moment: string;
}

interface ShowScheduleProfileModalActionType {
  type: typeof SHOW_SCHEDULE_PROFILE_MODAL;
}

export type SalesOnboardingTypes = ShowScheduleProfileModalActionType

function getMomentEvent(moment: string) {
  switch (moment) {
    case 'Quero muito ser mãe':
      return 'quero-ser-mae'
    case 'Estou grávida':
      return 'estou-gravida'
    case 'Sou mãe e estou grávida':
      return 'sou-mae-e-gravida'
    case 'Nem um, nem outro':
      return 'nenhum'
    default:
      return ''
  }
}

export const onAddMomentAction = (moment: string):
SalesOnboardingMomentAddedActionType => ({
  type: SALES_ONBOARDING_MOMENT_ADDED,
  moment: getMomentEvent(moment)
})

function getLiveInSpEvent(liveInSp: string) {
  if (liveInSp === 'true') {
    return 'mora-em-sp'
  }
  return 'nao-mora-em-sp'
}

export const onAddLiveInSpAction = (liveInSp: string):
SalesOnboardingLiveInSpAddedActionType => ({
  type: SALES_ONBOARDING_LIVE_IN_SP_ADDED,
  liveInSp: getLiveInSpEvent(liveInSp)
})

export const onShowScheduleProfileModalAction = ():
ShowScheduleProfileModalActionType => ({
  type: SHOW_SCHEDULE_PROFILE_MODAL
})
