import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { IconButton, NewListItem } from 'theia-web-ds'
import { useHistory } from 'react-router-dom'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { clearUpdateChildrenAlertAction, getChildrenSummaryAction } from '../../state/pediatricFlow/actions'
import { ChildrenSummary } from '../../domain/PediatricFlow'
import { primary } from '../../color'
import { eventTrack } from '../../../eventGenerate'
import { formatToDate } from '../../utils/helpers'
import './ChildrenInfoCard.scss'
import { CADASTRO_PEDIATRICO } from '../../routes/RoutesConstants'
import { PEDIATRIC_REGISTER } from '../../utils/EventCategories'

interface Props {
  isGettingChildrenSummary: boolean;
  childrenSummary?: ChildrenSummary;
  getChildrenSummary: () => void;
  clearUpdateChildrenAlert: () => void;
}

function ChildrenInfoCard({
  isGettingChildrenSummary,
  childrenSummary,
  clearUpdateChildrenAlert,
  getChildrenSummary
}: Props) {
  const history = useHistory()

  useEffect(() => {
    if (!childrenSummary && !isGettingChildrenSummary) {
      getChildrenSummary()
    }
  }, [])

  if (isGettingChildrenSummary) {
    return (
      <div className="children-info-card skeleton" />
    )
  }

  function handleClickEditChildren() {
    clearUpdateChildrenAlert()
    eventTrack('clicou editar criança', { category: PEDIATRIC_REGISTER })
    history.push(CADASTRO_PEDIATRICO)
  }

  if (childrenSummary) {
    return (
      <div className="children-info-card">
        <h1 className="card-title">
          {childrenSummary.title}
          <IconButton
            variant="text"
            iconType="icon-EditLight"
            iconSize="24px"
            iconColor={primary}
            onClick={handleClickEditChildren}
          />
        </h1>
        <NewListItem
          ariaLabel="names"
          title={childrenSummary?.names}
          isLastItem
          iconType="icon-BabyLight"
          iconSize="24px"
          iconColor={primary}
          hideIconButton
          isNoHover
        />
        <NewListItem
          ariaLabel="idade"
          title={childrenSummary?.birthDateText}
          caption={`Nascimento ${formatToDate(childrenSummary?.birthDate)}`}
          isLastItem
          iconType="icon-CalendarLight"
          iconSize="24px"
          iconColor={primary}
          hideIconButton
          isNoHover
        />
      </div>
    )
  }

  return null
}

const mapStateToProps = ({
  pediatricFlow
}: AppState) => ({
  childrenSummary: pediatricFlow.childrenSummary,
  isGettingChildrenSummary: pediatricFlow.isGettingChildrenSummary,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getChildrenSummary: () => { dispatch(getChildrenSummaryAction()) },
  clearUpdateChildrenAlert: () => { dispatch(clearUpdateChildrenAlertAction()) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChildrenInfoCard)
