import React from 'react'
import FocusLock from 'react-focus-lock';
import { Divider, IconButton } from 'theia-web-ds'
import './BarWithModal.scss'

export interface Props {
  onOpen?: () => void;
  onClose?: () => void;
  modalComponent: React.ReactNode;
  barHeight?: string;
  modalHeight?: string;
  isOpen: boolean;
  subtitle: string;
  title: string;
  buttonBar?: React.ReactNode;
  hasbutton?: boolean;
}

export function BarWithModal({
  onOpen,
  onClose,
  title,
  subtitle,
  modalComponent,
  hasbutton,
  barHeight = `${hasbutton ? '120px' : '56px'}`,
  modalHeight = '180px',
  isOpen,
  buttonBar
}: Props) {
  return (
    <FocusLock disabled={!isOpen}>
      {isOpen && (
        <div className="bar-with-modal-overlay" onClick={onClose} aria-hidden="true" data-testid="bar-with-modal-overlay" />
      )}
      <div
        role="dialog"
        aria-modal
        aria-labelledby={title}
      >
        <div className="bar-with-modal-container flex" style={{ height: isOpen ? modalHeight : barHeight }} data-testid="bar-with-modal-container">
          {!isOpen && (
            <Divider />
          )}
          <div className="order-1 bar-with-modal-mobile-header">
            <p className="bar-with-modal-mobile-title">
              {title}
            </p>
            {!isOpen && (
              <p className="bar-with-modal-mobile-subtitle">
                {subtitle}
              </p>
            )}
            {isOpen ? (
              <IconButton
                aria-label="Menos informações"
                variant="text"
                width="44px"
                height="44px"
                iconType="icon-ArrowDown2Light"
                onClick={onClose}
              />
            ) : (
              <IconButton
                aria-label="Mais informações"
                variant="text"
                width="44px"
                height="44px"
                iconType="icon-ArrowUp2Light"
                onClick={onOpen}
              />
            )}
          </div>
          {hasbutton && (
            <div className={`${isOpen ? 'order-3' : 'order-2'} px-4 lg:px-0`}>
              {buttonBar}
            </div>
          )}
          {isOpen && (
            <div className="order-2 mobile-open-container">
              {modalComponent}
            </div>
          )}
        </div>
      </div>
    </FocusLock>
  )
}
