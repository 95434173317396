import React from 'react'
import { FontIcon } from 'theia-web-ds';
import { warning } from '../../color'
import './SelectOptions.scss'

interface Props {
  id: string;
  options?: string[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  placeholder?: string;
  extraClass?: string;
  error?: string | boolean;
  disabled?: boolean;
}

export default function SelectOptions({
  id,
  options,
  onChange,
  onBlur,
  value,
  placeholder,
  extraClass,
  error,
  disabled
}: Props) {
  return (
    <div
      className={extraClass}
    >
      <select
        value={value}
        id={id}
        name={id}
        onChange={onChange}
        onBlur={onBlur}
        className={`select__options ${error ? 'error' : ''}`}
        required
        disabled={disabled}
      >
        {placeholder
          && (
            <option className="select__option__placeholder" value="" disabled hidden>
              {placeholder}
            </option>
          )}
        {options
          && options.map((option) => (
            <option id={`${id}-option`} className="select__option" key={option} value={option}>
              {option}
            </option>
          ))}
      </select>
      {error && (
        <div className="error-container">
          <FontIcon iconType="icon-DangerBold" fontSize="11px" color={warning} />
          <p className="error-text">
            {error}
          </p>
        </div>
      )}
    </div>
  )
}
