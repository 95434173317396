import { Logger } from './logger';
import { Configuration } from './configuration';

import { UserUpdatedEventArgs, User, UserUpdateReason } from './user';
import { PublicChannels } from './data/publicchannels';
import { Network } from './services/network';

import { NotificationTypes } from './interfaces/notificationtypes';

import { Notifications as NotificationClient, ChannelType } from '@twilio/notifications';
import { TwilsockClient, InitRegistration, ConnectionState as TwilsockConnectionState, Transport } from 'twilsock';
import { SyncClient } from 'twilio-sync';
import { McsClient } from '@twilio/mcs-client';

import { Channels as ChannelsEntity, Channel } from './data/channels';

import { Users } from './data/users';
import { TypingIndicator } from './services/typingindicator';
import { UserDescriptor } from './userdescriptor';
import { UserChannels } from './data/userchannels';
import { ChannelDescriptor } from './channeldescriptor';
import { Paginator } from './interfaces/paginator';
import { PushNotification } from './pushnotification';
import { parseToNumber, deepClone } from './util';
import { Member, MemberUpdatedEventArgs, MemberUpdateReason } from './member';
import { Message, MessageUpdatedEventArgs, MessageUpdateReason } from './message';
import {
  validateTypesAsync,
  validateTypes,
  literal,
  nonEmptyString,
  pureObject,
  objectSchema,
  validateConstructorTypes,
  type,
} from '@twilio/declarative-type-validator';
import { CommandExecutor } from './commandexecutor';
import { ConfigurationResponse } from './interfaces/commands/configuration';
import { version } from '../package.json';
import { ChannelUpdatedEventArgs, ChannelUpdateReason } from './channel';
import { ReplayEventEmitter } from '@twilio/replay-event-emitter';

const log = Logger.scope('Client');
const SDK_VERSION = version;

class ClientServices {
  commandExecutor: CommandExecutor;
  twilsockClient: TwilsockClient;
  users: Users;
  notificationClient: NotificationClient;
  publicChannels: PublicChannels;
  userChannels: UserChannels;
  network: Network;
  typingIndicator: TypingIndicator;
  syncClient: SyncClient;
  mcsClient: McsClient;
  transport: Transport;
}

type ClientEvents = {
  channelAdded: (channel: Channel) => void;
  channelInvited: (channel: Channel) => void;
  channelJoined: (channel: Channel) => void;
  channelLeft: (channel: Channel) => void;
  channelRemoved: (channel: Channel) => void;
  channelUpdated: (data: {
    channel: Channel;
    updateReasons: ChannelUpdateReason[];
  }) => void;
  memberJoined: (member: Member) => void;
  memberLeft: (member: Member) => void;
  memberUpdated: (data: {
    member: Member;
    updateReasons: MemberUpdateReason[];
  }) => void;
  messageAdded: (message: Message) => void;
  messageRemoved: (message: Message) => void;
  messageUpdated: (data: {
    message: Message;
    updateReasons: MessageUpdateReason[];
  }) => void;
  tokenAboutToExpire: (ttl: number) => void;
  tokenExpired: () => void;
  typingEnded: (member: Member) => void;
  typingStarted: (member: Member) => void;
  pushNotification: (pushNotification: PushNotification) => void;
  userSubscribed: (user: User) => void;
  userUnsubscribed: (user: User) => void;
  userUpdated: (data: {
    user: User;
    updateReasons: UserUpdateReason[];
  }) => void;
  stateChanged: (state: State) => void;
  connectionStateChanged: (state: TwilsockConnectionState) => void;
  connectionError: (data: {
    terminal: boolean;
    message: string;
    httpStatusCode?: number;
    errorCode?: number;
  }) => void;
};

/**
 * Connection state of the client. Possible values are as follows:
 * * `'connecting'` - client is offline and connection attempt is in process
 * * `'connected'` - client is online and ready
 * * `'disconnecting'` - client is going offline as disconnection is in process
 * * `'disconnected'` - client is offline and no connection attempt is in process
 * * `'denied'` - client connection is denied because of invalid JWT access token. User must
 * refresh token in order to proceed
 */
type ConnectionState = TwilsockConnectionState;

/**
 * State of the client. Possible values are as follows:
 * * `'failed'` - the client failed to initialize
 * * `'initialized'` - the client successfully initialized
 */
type State = 'failed' | 'initialized';

/**
 * Notifications channel type. Possible values are as follows:
 * * `'fcm'`
 * * `'apn'`
 */
type NotificationsChannelType = ChannelType;

type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'silent' | null;

/**
 * Chat client options.
 */
interface ClientOptions {
  region?: string;

  /**
   * The level of logging to enable.
   */
  logLevel?: LogLevel;
  productId?: string;
  twilsockClient?: TwilsockClient;
  transport?: Transport;
  notificationsClient?: NotificationClient;
  syncClient?: SyncClient;
  typingIndicatorTimeoutOverride?: number;
  consumptionReportIntervalOverride?: string;
  httpCacheIntervalOverride?: number;
  userInfosToSubscribeOverride?: number;
  retryWhenThrottledOverride?: boolean;
  backoffConfigOverride?: any;
  Chat?: any;
  IPMessaging?: any;
  Sync?: any;
  Notification?: any;
  Twilsock?: any;
  clientMetadata?: any;
  disableDeepClone?: boolean;
  initRegistrations?: InitRegistration[];
}

/**
 * Options for {@link Client.createChannel}.
 */
interface CreateChannelOptions {
  /**
   * Any custom attributes to attach to the channel.
   */
  attributes?: any;

  /**
   * A non-unique display name of the channel.
   */
  friendlyName?: string;

  /**
   * Signifies whether the channel is private.
   */
  isPrivate?: boolean;

  /**
   * A unique identifier of the channel.
   */
  uniqueName?: string;
}

/**
 * Channel sorting criteria.
 */
type ChannelSortingCriteria = 'lastMessage' | 'friendlyName' | 'uniqueName';

/**
 * Channel sorting order.
 */
type ChannelSortingOrder = 'ascending' | 'descending';

/**
 * Channel sorting options.
 */
interface ChannelSortingOptions {
  /**
   * Channel sorting criteria.
   */
  criteria?: ChannelSortingCriteria;

  /**
   * Channel sorting order.
   */
  order?: ChannelSortingOrder;
}

/**
 * A client is the starting point to the Twilio Programmable Chat functionality.
 */
@validateConstructorTypes(nonEmptyString, [
  pureObject,
  'undefined',
  literal(null),
])
class Client extends ReplayEventEmitter<ClientEvents> {
  /**
   * Client connection state.
   */
  public connectionState: ConnectionState = 'unknown'; // @todo make private?
  private _ensureReady: Promise<any> = null;
  private _resolveEnsureReady: (value?: any) => void = null;
  private _rejectEnsureReady: (err: Error) => void  = null;
  private channelsPromise: Promise<ChannelsEntity> = null;
  private configurationPromise: Promise<ConfigurationResponse> | null = null;
  private configuration: Configuration;
  private channels: ChannelsEntity;
  private readonly services: ClientServices;
  private readonly _myself: User;

  /**
   * Current version of the Chat client.
   */
  public static readonly version = SDK_VERSION;

  /**
   * Current version of the Chat client.
   */
  public readonly version = SDK_VERSION;

  private static readonly supportedPushChannels: NotificationsChannelType[] = ['fcm', 'apn'];
  private static readonly supportedPushDataFields = {
    'channel_sid': 'channelSid',
    'message_sid': 'messageSid',
    'message_index': 'messageIndex'
  };

  /**
   * Returned client instance is not yet fully initialized. Calling any operations will block until it is.
   * Use connection events to monitor when client becomes fully available (connectionStateChanged with state
   * 'connected') or not available (connectionStateChange with state 'denied', event tokenExpired, event connectionError).
   *
   * @param fpaToken Access token
   * @param options Options to customize the Client
   * @returns A not yet fully-initialized client.
   */
  public constructor(
    private fpaToken: string,
    private readonly options: ClientOptions = {}
  ) {
    super();

    this.options = this.options ?? {};

    if (!this.options.disableDeepClone) {
      let options = {
        ...this.options,
        transport: undefined,
        twilsockClient: undefined
      };

      options = deepClone(options);
      options.transport = this.options.transport;
      options.twilsockClient = this.options.twilsockClient;

      this.options = options;
    }

    this.options.logLevel = this.options.logLevel ?? 'silent';
    log.setLevel(this.options.logLevel);

    const productId = this.options.productId = 'ip_messaging';

    // Fill ClientMetadata
    this.options.clientMetadata = this.options.clientMetadata ?? {};

    if (!this.options.clientMetadata.hasOwnProperty('type')) {
      this.options.clientMetadata.type = 'chat';
    }

    if (!this.options.clientMetadata.hasOwnProperty('sdk')) {
      this.options.clientMetadata.sdk = 'JS';
      this.options.clientMetadata.sdkv = SDK_VERSION;
    }

    // Enable session local storage for Sync
    this.options.Sync = this.options.Sync ?? {};

    if (!this.options.Sync?.enableSessionStorage) {
      this.options.Sync.enableSessionStorage = true;
    }

    if (this.options.region) {
      this.options.Sync.region = this.options.region;
    }

    this.services = new ClientServices();

    const startTwilsock = !this.options.twilsockClient;

    // Create default init registrations if none were provided.
    // Otherwise, the outside party have to list all the init registrations they need, including Sync ones.
    if (!this.options.initRegistrations) {
      const initRegistration = new InitRegistration(productId);
      Client.populateInitRegistrations(initRegistration);
      SyncClient.populateInitRegistrations(initRegistration);
      this.options.initRegistrations = [initRegistration];
    }

    this.services.twilsockClient = (this.options.twilsockClient = this.options.twilsockClient
      ?? new TwilsockClient(fpaToken, productId, this.options));

    this.services.twilsockClient.on('tokenAboutToExpire', (ttl) => this.emit('tokenAboutToExpire', ttl));
    this.services.twilsockClient.on('tokenExpired', () => this.emit('tokenExpired'));
    this.services.twilsockClient.on('connectionError', (error) => this.emit('connectionError', error));
    this.services.twilsockClient.on('stateChanged', (state: ConnectionState) => {
      log.debug(`Handling stateChanged for ChatClient: new state ${state}`);
      if (state !== this.connectionState) {
        this.connectionState = state;
        this.emit('connectionStateChanged', this.connectionState);
      }
    });

    this.services.transport = (this.options.transport = (this.options.transport ?? this.options.twilsockClient) as Transport);
    this.services.notificationClient = (this.options.notificationsClient = this.options.notificationsClient
      ?? new NotificationClient(fpaToken, this.options));
    this.services.syncClient = (this.options.syncClient = this.options.syncClient
      ?? new SyncClient(fpaToken, this.options));

    const configurationOptions = this.options?.Chat ?? this.options?.IPMessaging ?? this.options ?? {};
    const region = configurationOptions.region ?? this.options.region;
    const baseUrl = configurationOptions.apiUri
      ?? configurationOptions.typingUri
      ?? `https://aim.${region || 'us1'}.twilio.com`;

    this.services.commandExecutor = new CommandExecutor(baseUrl, { transport: this.options.transport }, productId);

    const emitFailed = (err): void => {
      this._rejectEnsureReady(err);
      this.emit('stateChanged', 'failed');
    };

    this.services.twilsockClient.once('connectionError', emitFailed);
    this.services.twilsockClient.once('disconnected', emitFailed);

    // ChatClient will be able to initialize only after twilsock is connected
    this.services.twilsockClient.once('connected', async () => {
      log.debug(`ChatClient started INITIALIZING`);
      this.services.twilsockClient.off('connectionError', emitFailed);
      this.services.twilsockClient.off('disconnected', emitFailed);
      try {
        await this._initialize();
      } catch (err) {
        // Fail ChatClient if initialization is incomplete
        this._rejectEnsureReady(err);
        this.emit('stateChanged', 'failed');
      }
    });

    this._ensureReady = new Promise((resolve, reject) => {
      this._resolveEnsureReady = resolve;
      this._rejectEnsureReady = reject;
    }).catch((err) => {}); // @todo How to process unhandled rejection here?

    this._myself = new User(
      '',
      '',
      null,
      this.services
    );

    if (startTwilsock) {
      this.services.twilsockClient.connect();
    }
  }

  /**
   * Fired when a channel becomes visible to the client. The event is also triggered when the client creates a new channel.
   * Fired for all channels client has joined.
   *
   * Parameters:
   * 1. {@link Channel} `channel` - the channel in question
   * @event
   */
  static readonly channelAdded = 'channelAdded';

  /**
   * Fired when the client gets invited to a channel.
   *
   * Parameters:
   * 1. {@link Channel} `channel` - the channel in question
   * @event
   */
  static readonly channelInvited = 'channelInvited';

  /**
   * Fired when the client joins a channel.
   *
   * Parameters:
   * 1. {@link Channel} `channel` - the channel in question
   * @event
   */
  static readonly channelJoined = 'channelJoined';

  /**
   * Fired when the client leaves a channel.
   *
   * Parameters:
   * 1. {@link Channel} `channel` - the channel in question
   * @event
   */
  static readonly channelLeft = 'channelLeft';

  /**
   * Fired when a channel is no longer visible to the client.
   *
   * Parameters:
   * 1. {@link Channel} `channel` - the channel in question
   * @event
   */
  static readonly channelRemoved = 'channelRemoved';

  /**
   * Fired when the attributes or the metadata of a channel have been updated.
   * During channel's {@link Client.constructor| creation and initialization}, this event might be fired multiple times
   * for same joined or created channel as new data is arriving from different sources.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Channel} `channel` - the channel in question
   *     * {@link ChannelUpdateReason}[] `updateReasons` - array of reasons for the update
   * @event
   */
  static readonly channelUpdated = 'channelUpdated';

  /**
   * Fired when a member has joined a channel.
   *
   * Parameters:
   * 1. {@link Member} `member` - the member in question
   * @event
   */
  static readonly memberJoined = 'memberJoined';

  /**
   * Fired when a member has left a channel.
   *
   * Parameters:
   * 1. {@link Member} `member` - the member in question
   * @event
   */
  static readonly memberLeft = 'memberLeft';

  /**
   * Fired when a member's fields have been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Member} `member` - the member in question
   *     * {@link MemberUpdateReason}[] `updateReasons` - array of reasons for the update
   * @event
   */
  static readonly memberUpdated = 'memberUpdated';

  /**
   * Fired when a new message has been added to the channel on the server.
   *
   * Parameters:
   * 1. {@link Message} `message` - the message in question
   * @event
   */
  static readonly messageAdded = 'messageAdded';

  /**
   * Fired when a message is removed from the message list of a channel.
   *
   * Parameters:
   * 1. {@link Message} `message` - the message in question
   * @event
   */
  static readonly messageRemoved = 'messageRemoved';

  /**
   * Fired when the fields of an existing message are updated with new values.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Message} `message` - the message in question
   *     * {@link MessageUpdateReason}[] `updateReasons` - array of reasons for the update
   * @event
   */
  static readonly messageUpdated = 'messageUpdated';

  /**
   * Fired when the token is about to expire and needs to be updated.
   *
   * * Parameters:
   * 1. number `ttl` - token's time to live
   * @event
   */
  static readonly tokenAboutToExpire = 'tokenAboutToExpire';

  /**
   * Fired when the token has expired.
   * @event
   */
  static readonly tokenExpired = 'tokenExpired';

  /**
   * Fired when a member has stopped typing.
   *
   * Parameters:
   * 1. {@link Member} `member` - the member in question
   * @event
   */
  static readonly typingEnded = 'typingEnded';

  /**
   * Fired when a member has started typing.
   *
   * Parameters:
   * 1. {@link Member} `member` - the member in question
   * @event
   */
  static readonly typingStarted = 'typingStarted';

  /**
   * Fired when the client has received (and parsed) a push notification via one of the push channels (apn or fcm).
   *
   * Parameters:
   * 1. {@link PushNotification} `pushNotification` - the push notification in question
   * @event
   */
  static readonly pushNotification = 'pushNotification';

  /**
   * Fired when the client has subscribed to a user.
   *
   * Parameters:
   * 1. {@link User} `user` - the user in question
   * @event
   */
  static readonly userSubscribed = 'userSubscribed';

  /**
   * Fired when the client has unsubscribed from a user.
   *
   * Parameters:
   * 1. {@link User} `user` - the user in question
   * @event
   */
  static readonly userUnsubscribed = 'userUnsubscribed';

  /**
   * Fired when the properties or the reachability status of a user have been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link User} `user` - the user in question
   *     * {@link UserUpdateReason}[] `updateReasons` - array of reasons for the update
   * @event
   */
  static readonly userUpdated = 'userUpdated';

  /**
   * Fired when the connection is interrupted for an unexpected reason.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * boolean `terminal` - Twilsock will stop connection attempts if true
   *     * string `message` - the error message of the root cause
   *     * number? `httpStatusCode` - http status code if available
   *     * number? `errorCode` - Twilio public error code if available
   * @event
   */
  static readonly connectionError = 'connectionError';

  /**
   * Fired when the state of the client has been changed.
   *
   * Parameters:
   * 1. {@link State} `state` - the new client state
   * @event
   */
  static readonly stateChanged = 'stateChanged';

  /**
   * Fired when the connection state of the client has been changed.
   *
   * Parameters:
   * 1. {@link ConnectionState} `connectionState` - the updated connection state
   * @event
   */
  static readonly connectionStateChanged = 'connectionStateChanged';

  /**
   * @deprecated Call constructor directly.
   *
   * Factory method to create Chat client instance.
   *
   * The factory method will automatically trigger connection.
   * Do not use it if you need finer-grained control.
   *
   * @param token Access token.
   * @param options Options to customize the client.
   * @returns Returns a fully initialized client.
   */
  public static async create(token: string, options?: ClientOptions): Promise<Client> {
    // The logic is as follows:
    // - If twilsock is not passed in, then the ChatClient constructor will call twilsock.connect() by itself
    //   and we do not need to do it here.
    // - If twilsock was passed in from the outside, but customer called ChatClient.create() then they are
    //   using an obsolete workflow and the startup sequence will never complete.
    if (options?.twilsockClient) {
      throw new Error('Obsolete usage of ChatClient.create() factory method: if you pass twilsock from the outside then you must use ChatClient constructor and be prepared to work with uninitialized client.');
    }

    const client = new Client(token, options);
    await client._ensureReady;

    return client;
  }

  /**
   * Information of the logged-in user. Before client initialization, returns an
   * uninitialized user. Will trigger a {@link Client.userUpdated} event after
   * initialization.
   */
  public get user(): User { return this._myself; }

  /**
   * Client reachability state. Throws if accessed before the client
   * initialization was completed.
   */
  public get reachabilityEnabled(): boolean {
    if (!this.configuration) {
      throw new Error(
        'Reachability information could not yet be accessed as the client ' +
        "has not yet been initialized. Subscribe to the 'stateChanged' event " +
        'to properly react to the client initialization.'
      );
    }

    return this.configuration.reachabilityEnabled;
  }

  public get token(): string { return this.fpaToken; }

  public static populateInitRegistrations(reg: InitRegistration) {
    reg.populateInitRegistrations([NotificationTypes.TYPING_INDICATOR]);
  }

  private _subscribeToPushNotifications(channelType: NotificationsChannelType): void {
    [NotificationTypes.NEW_MESSAGE,
      NotificationTypes.ADDED_TO_CHANNEL,
      NotificationTypes.INVITED_TO_CHANNEL,
      NotificationTypes.REMOVED_FROM_CHANNEL,
      NotificationTypes.CONSUMPTION_UPDATE] // Supported only on iOS
      .forEach(messageType => {
        this.services.notificationClient.subscribe(channelType, messageType);
      });
  }

  private _unsubscribeFromPushNotifications(channelType: NotificationsChannelType): void {
    [NotificationTypes.NEW_MESSAGE,
      NotificationTypes.ADDED_TO_CHANNEL,
      NotificationTypes.INVITED_TO_CHANNEL,
      NotificationTypes.REMOVED_FROM_CHANNEL,
      NotificationTypes.CONSUMPTION_UPDATE] // Supported only on iOS
      .forEach(messageType => {
        this.services.notificationClient.unsubscribe(channelType, messageType);
      });
  }

  private async _initialize() {
    const configurationResponse = await this.services.commandExecutor.fetchResource<void, ConfigurationResponse>(
      'Client/v1/Configuration'
    );

    this.configuration = new Configuration(this.options, configurationResponse, log);

    this._myself._resolveInitialization(
      this.configuration,
      this.configuration.userIdentity,
      this.configuration.userInfo,
      true
    );

    this.services.typingIndicator = new TypingIndicator(this.getChannelBySid.bind(this), this.configuration, this.services);
    this.services.network = new Network(this.configuration, this.services);

    this.services.users = new Users(this._myself, this.configuration, this.services);
    this.services.users.on('userSubscribed', this.emit.bind(this, 'userSubscribed'));
    this.services.users.on('userUpdated', (args: UserUpdatedEventArgs) => this.emit('userUpdated', args));
    this.services.users.on('userUnsubscribed', this.emit.bind(this, 'userUnsubscribed'));

    this.channels = new ChannelsEntity(this.configuration, this.services);
    this.channels.on('channelAdded', this.emit.bind(this, 'channelAdded'));
    this.channels.on('channelInvited', this.emit.bind(this, 'channelInvited'));
    this.channels.on('channelRemoved', this.emit.bind(this, 'channelRemoved'));
    this.channels.on('channelJoined', this.emit.bind(this, 'channelJoined'));
    this.channels.on('channelLeft', this.emit.bind(this, 'channelLeft'));
    this.channels.on('channelUpdated',
      (args: ChannelUpdatedEventArgs) => this.emit('channelUpdated', args));

    this.channels.on('memberJoined', this.emit.bind(this, 'memberJoined'));
    this.channels.on('memberLeft', this.emit.bind(this, 'memberLeft'));
    this.channels.on('memberUpdated',
      (args: MemberUpdatedEventArgs) => this.emit('memberUpdated', args));

    this.channels.on('messageAdded', this.emit.bind(this, 'messageAdded'));
    this.channels.on('messageUpdated',
      (args: MessageUpdatedEventArgs) => this.emit('messageUpdated', args));
    this.channels.on('messageRemoved', this.emit.bind(this, 'messageRemoved'));

    this.channels.on('typingStarted', this.emit.bind(this, 'typingStarted'));
    this.channels.on('typingEnded', this.emit.bind(this, 'typingEnded'));

    this.channelsPromise = this.channels.fetchChannels()
      .then(() => this.channels)
      .catch((err) => { throw err; });

    await this.services.users.myself._ensureFetched();

    Client.supportedPushChannels.forEach(channelType => this._subscribeToPushNotifications(channelType));
    this.services.typingIndicator.initialize();

    this.services.publicChannels = new PublicChannels(this, this.services, this.configuration.links.conversations);
    this.services.userChannels = new UserChannels(this, this.services, this.configuration.links.myConversations);

    this.services.mcsClient = new McsClient(this.fpaToken, this.configuration.links.mediaService, null, {
      ...this.options,
      transport: null,
    });

    this._resolveEnsureReady();
    this.emit('stateChanged', 'initialized');
  }

  /**
   * Gracefully shut down the client.
   */
  public async shutdown(): Promise<void> {
    await this._ensureReady;
    await this.services.syncClient.shutdown(); // also disconnects twilsock
  }

  /**
   * Update the token used by the client and re-register with the Programmable Chat services.
   * @param token New access token.
   */
  @validateTypesAsync(nonEmptyString)
  public async updateToken(token: string): Promise<Client> {
    await this._ensureReady;
    log.info('updateToken');

    if (this.fpaToken === token) {
      return this;
    }

    await this.services.twilsockClient.updateToken(token);
    this.fpaToken = token;
    this.services.mcsClient.updateToken(token);
    await this.configurationPromise;

    return this;
  }

  /**
   * Get a known channel by its SID.
   * @param channelSid Channel sid
   */
  @validateTypesAsync(nonEmptyString)
  public async getChannelBySid(channelSid: string): Promise<Channel> {
    await this._ensureReady;
    await this.channels.myChannelsRead.promise;
    let channel: Channel | ChannelDescriptor = await this.channels.getChannel(channelSid);
    if (!channel) {
      channel = await this.services.publicChannels.getChannelBySid(channelSid);
    }
    return await this.channels.pushChannel(channel);
  }

  /**
   * Get a known channel by its unique identifier name.
   * @param uniqueName The unique identifier name of the channel.
   */
  @validateTypesAsync(nonEmptyString)
  public async getChannelByUniqueName(uniqueName: string): Promise<Channel> {
    await this._ensureReady;
    await this.channels.myChannelsRead.promise;
    const channel = await this.services.publicChannels.getChannelByUniqueName(uniqueName);
    return await this.channels.pushChannel(channel);
  }

  /**
   * Get the current list of all the subscribed channels.
   */
  public async getSubscribedChannels(args?): Promise<Paginator<Channel>> {
    await this._ensureReady;
    const channelsEntity = await this.channelsPromise;
    return await channelsEntity.getChannels(args);
  }

  /**
   * Get an array of channels that are locally known to the client in the provided sorting order.
   * Locally known channels are the ones that are created and/or joined during the client runtime
   * and all the subscribed channel of the currently-logged-in user.
   * To ensure that the full list of the subscribed channels has fetched, call the
   * {@link Client.getSubscribedChannels} method
   * and fetch all the pages with the help of the {@link RestPaginator.nextPage} method.
   * @param sortingOptions Sorting options.
   */
  @validateTypesAsync([
    'undefined',
    objectSchema('sorting options', {
      criteria: [literal('lastMessage', 'friendlyName', 'uniqueName'), 'undefined'],
      order: [literal('ascending', 'descending'), 'undefined']
    })
  ])
  public async getLocalChannels(sortingOptions?: ChannelSortingOptions): Promise<Array<Channel>> {
    await this._ensureReady;
    const channelsEntity = await this.channelsPromise;
    let result: Channel[] = Array.from(channelsEntity.channels.values());
    const sortingOrder = sortingOptions?.order || 'ascending';

    if (sortingOptions?.criteria) {
      if (sortingOptions.criteria === 'lastMessage') {
        result.sort((a: Channel, b: Channel) =>
          Client.compareChannelsByLastMessage(a, b, sortingOrder));
      } else if (sortingOptions.criteria === 'uniqueName') {
        result.sort((a: Channel, b: Channel) =>
          Client.compareChannelsByStringProperty(a.uniqueName, b.uniqueName, sortingOrder));
      } else if (sortingOptions.criteria === 'friendlyName') {
        result.sort((a: Channel, b: Channel) =>
          Client.compareChannelsByStringProperty(a.friendlyName, b.friendlyName, sortingOrder));
      }
    }

    return result;
  }

  private static compareChannelsByLastMessage(a: Channel, b: Channel, order: ChannelSortingOrder) {
    const lessThan = (a: Channel, b: Channel): number => {
      if (a.lastMessage?.dateCreated?.getTime() > b.lastMessage?.dateCreated?.getTime()) {
        // console.log('A time > B time');
        return 1;
      } else if (a.lastMessage?.dateCreated?.getTime() < b.lastMessage?.dateCreated?.getTime()) {
        // console.log('A time < B time');
        return -1;
      }

      if (b.lastMessage?.dateCreated) {
        // console.log('B has time');
        return 1;
      } else if (a.lastMessage?.dateCreated) {
        // console.log('A has time');
        return -1;
      }

      if (b.lastMessage) {
        // console.log('B has lastmessage');
        return 1;
      } else if (a.lastMessage) {
        // console.log('A has lastmessage');
        return -1;
      }

      return 0;
    };
    const result = lessThan(a, b);
    console.log(`Sorted ${a.sid} and ${b.sid}: return ${result}`);
    return (order === 'ascending') ? result : -result;
  }

  private static compareChannelsByStringProperty(str1: string, str2: string, order: ChannelSortingOrder) {
    const lessThan = (a: string, b: string): number => {
      if (a && b) {
        return str1.localeCompare(str2);
      } else if (b) {
        return 1;
      } else if (a) {
        return -1;
      }

      return 0;
    };
    const result = lessThan(str1, str2);
    return (order === 'ascending') ? result : -result;
  }

  /**
   * Get the content of the public channels directory.
   */
  public async getPublicChannelDescriptors(): Promise<Paginator<ChannelDescriptor>> {
    await this._ensureReady;
    return await this.services.publicChannels.getChannels();
  }

  /**
   * Get the content of the channels directory of the user (created by, joined or invited to).
   */
  public async getUserChannelDescriptors(): Promise<Paginator<ChannelDescriptor>> {
    await this._ensureReady;
    return await this.services.userChannels.getChannels();
  }

  /**
   * Create a channel on the server and subscribe to its events.
   * The default is a public channel with an empty friendly name.
   * @param options Options for the channel.
   */
  @validateTypesAsync([
    'undefined',
    objectSchema('channel options', {
      friendlyName: ['string', 'undefined'],
      isPrivate: ['boolean', 'undefined'],
      uniqueName: ['string', 'undefined']
    })
  ])
  public async createChannel(options?: CreateChannelOptions): Promise<Channel> {
    await this._ensureReady;
    options = options || {};
    const channelsEntity = await this.channelsPromise;
    return await channelsEntity.addChannel(options);
  }

  /**
   * Register for push notifications.
   * @param channelType Channel type.
   * @param registrationId Push notification ID provided by the platform.
   */
  @validateTypesAsync(literal('fcm', 'apn'), 'string')
  public async setPushRegistrationId(channelType: NotificationsChannelType, registrationId: string): Promise<void> {
    await this._ensureReady;
    this.services.notificationClient.setPushRegistrationId(channelType, registrationId);
    await this.services.notificationClient.commitChanges(); // Committing before this point is useless because we have no push id
  }

  /**
   * Unregister from push notifications.
   * @param channelType Channel type.
   */
  @validateTypesAsync(literal('fcm', 'apn'))
  public async unsetPushRegistrationId(channelType: NotificationsChannelType): Promise<void> {
    await this._ensureReady;
    // await this.services.notificationClient.removeRegistrations(..) // @todo
    // old version:
    this._unsubscribeFromPushNotifications(channelType);
    await this.services.notificationClient.commitChanges();
  }

  private static parsePushNotificationChatData(data: Object): Object {
    let result: Object = {};
    for (let key in Client.supportedPushDataFields) {
      if (typeof data[key] !== 'undefined' && data[key] !== null) {
        if (key === 'message_index') {
          if (parseToNumber(data[key]) !== null) {
            result[Client.supportedPushDataFields[key]] = Number(data[key]);
          }
        } else {
          result[Client.supportedPushDataFields[key]] = data[key];
        }
      }
    }

    return result;
  }

  /**
   * Static method for push notification payload parsing. Returns parsed push as a {@link PushNotification} object.
   * @param notificationPayload Push notification payload.
   */
  @validateTypes(pureObject)
  public static parsePushNotification(notificationPayload): PushNotification {
    log.debug('parsePushNotification, notificationPayload=', notificationPayload);

    // APNS specifics
    if (typeof notificationPayload.aps !== 'undefined') {
      if (!notificationPayload.twi_message_type) {
        throw new Error('Provided push notification payload does not contain Programmable Chat push notification type');
      }

      let data = Client.parsePushNotificationChatData(notificationPayload);

      let apsPayload = notificationPayload.aps;
      let body: string = null;
      let title: string = null;
      if (typeof apsPayload.alert === 'string') {
        body = apsPayload.alert || null;
      } else {
        body = apsPayload.alert.body || null;
        title = apsPayload.alert.title || null;
      }

      return new PushNotification({
        title: title,
        body: body,
        sound: apsPayload.sound || null,
        badge: apsPayload.badge || null,
        action: apsPayload.category || null,
        type: notificationPayload.twi_message_type,
        data: data
      });
    }

    // FCM specifics
    if (typeof notificationPayload.data !== 'undefined') {
      let dataPayload = notificationPayload.data;
      if (!dataPayload.twi_message_type) {
        throw new Error('Provided push notification payload does not contain Programmable Chat push notification type');
      }

      let data = Client.parsePushNotificationChatData(notificationPayload.data);
      return new PushNotification({
        title: dataPayload.twi_title || null,
        body: dataPayload.twi_body || null,
        sound: dataPayload.twi_sound || null,
        badge: null,
        action: dataPayload.twi_action || null,
        type: dataPayload.twi_message_type,
        data: data
      });
    }

    throw new Error('Provided push notification payload is not Programmable Chat notification');
  }

  public parsePushNotification = Client.parsePushNotification;

  /**
   * Handle push notification payload parsing and emit the {@link Client.pushNotification} event on this {@link Client} instance.
   * @param notificationPayload Push notification payload
   */
  @validateTypesAsync(pureObject)
  public async handlePushNotification(notificationPayload): Promise<void> {
    await this._ensureReady;
    log.debug('handlePushNotification, notificationPayload=', notificationPayload);
    this.emit('pushNotification', Client.parsePushNotification(notificationPayload));
  }

  /**
   * Gets a user with the given identity. If it's in the subscribed list, then return the user object from it;
   * if not, then subscribe and add user to the subscribed list.
   * @param identity Identity of the user.
   * @returns A fully initialized user.
   */
  @validateTypesAsync(nonEmptyString)
  public async getUser(identity: string): Promise<User> {
    await this._ensureReady;
    return await this.services.users.getUser(identity);
  }

  /**
   * Gets the user descriptor for given identity.
   * @param identity Identity of the user.
   * @returns The user descriptor.
   */
  @validateTypesAsync(nonEmptyString)
  public async getUserDescriptor(identity: string): Promise<UserDescriptor> {
    await this._ensureReady;
    return await this.services.users.getUserDescriptor(identity);
  }

  /**
   * Get a list of subscribed user objects.
   */
  public async getSubscribedUsers(): Promise<Array<User>> {
    await this._ensureReady;
    return await this.services.users.getSubscribedUsers();
  }
}

export {
  Client,
  ConnectionState,
  NotificationsChannelType,
  LogLevel,
  ClientOptions,
  CreateChannelOptions,
  ChannelSortingCriteria,
  ChannelSortingOrder,
  ChannelSortingOptions,
  State
};
