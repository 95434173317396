import React from 'react'
import { FontIcon } from '../FontIcon/FontIcon'
import { primary } from '../../colors'
import './Loader.scss'

export interface Props {
  size?: string;
  color?: string;
}

export function Loader({
  size,
  color
}: Props) {
  return (
    <div className="flex" id="loader" data-testid="loader">
      <FontIcon
        animation="spin 2s linear infinite"
        iconType="icon-LoadingLight"
        fontSize={size || '30px'}
        color={color || primary}
      />
    </div>
  )
}
