import { HttpClient } from '../Http'
import { mountUrlParams, parseBookingView, parseUser } from './utils'
import {
  BookingView,
  PromotionalCodeView,
  Reason,
  BookingConfirmationInfo,
  BookingProfessionalConfirmationInfo,
  BookingSuggestionResponse
} from '../../domain/Booking'
import {
  LoginPayload,
  SignUpPayload,
  SignUpResponse,
  User,
  UserConfirmationInfos,
  UserCredentials,
  VerifiedSignUpPayload,
} from '../../domain/User'
import {
  HighlightDesiretype,
  Prescriptions,
  PrescriptionsHighlight,
  UsgAvailabilityParams,
  UsgPrescription,
  UsgStatus,
} from '../../domain/Prescriptions'
import { Specialist, SpecialistDetails, SpecialistFromList } from '../../domain/Specialist'
import {
  Professional,
  AvailabilitySlot,
  ProfessionalListType
} from '../../domain/Professional'
import { Booking, InfosToUpdateProfile, PersonalData } from '../../domain/Profile'
import { Platform } from '../../domain/Platform'
import { PlatformUnavailability } from '../../domain/Chat'
import { TheiaError } from '../../domain/errors/TheiaError'
import { WeekSuggestionsResponse } from '../../domain/WeekFlow'
import { BrazilState, CityOfState } from '../../domain/Onboarding'
import {
  HealthInsuranceCompany,
  HealthInsurancePlanData,
  HealthPlanData,
  PostUserHealthPlanType
} from '../../domain/Healthplan'
import {
  ConsultationInfos,
  GuidedJourney,
  PregnancyJourney,
  ResponseQuarterDetails,
  UpdateCardResponse
} from '../../domain/GuidedJourney'
import { DesireContent, MaternalDesireType } from '../../domain/Consultant'
import { LoginError } from '../../domain/errors/LoginError'
import { BabyData, ChildrenSummary } from '../../domain/PediatricFlow'
import { isProductionEnv } from '../../utils/helpers'
import {
  AttendanceAvailabilityType,
  PaymentMethods,
  ResponseCategories,
  ScheduleInfosType,
  ScheduleResponse,
} from '../../domain/ScheduleFlow'
import {
  AvailabilityResponseError,
  BookingResponseError,
  NimbaResponseError,
  TheiaV6ResponseError,
  UnlockLoginResponseError,
  UserLoginError,
  GetUserResponseError
} from '../../domain/errors/ApiResponseErrors'
import { CurrentTerm } from '../../domain/Terms'
import { LoginFriendlyError } from '../../domain/errors/LoginFriendlyError'
import { ScheduleView } from '../../domain/Reschedule'
import { AvailabilitiesRequestParams, ResponseAvailabilitiesSlots } from '../../domain/Availabilities'

export const API_URL = isProductionEnv()
  ? process.env.API_URL
  : process.env.API_URL_STG
const AVAILABILITIES_URL = isProductionEnv()
  ? process.env.AVAILABILITIES_URL
  : process.env.AVAILABILITIES_URL_STG
const BOOKING_API_URL = isProductionEnv()
  ? process.env.BOOKING_API_URL
  : process.env.BOOKING_API_URL_STG
const GO_PRONTS_API_URL = isProductionEnv()
  ? process.env.GO_PRONTS_API_URL
  : process.env.GO_PRONTS_API_URL_STG
const NIMBA_API_URL = isProductionEnv()
  ? process.env.NIMBA_API_URL
  : process.env.NIMBA_API_URL_STG

export default class ApiClient {
  constructor(private httpClient: HttpClient) { }

  private parseReason(json: any): Reason {
    return {
      id: json.id,
      name: json.name,
      description: json.description,
      selected: false
    }
  }

  private async getAsJson(url: string, token?: string): Promise<any> {
    const response = await this.httpClient.get({ url, token })
    if (response.status !== 200) {
      throw new Error(response.body)
    }
    return JSON.parse(response.body)
  }

  async getCurrentUser(token?: string): Promise<User> {
    const url = `${API_URL}/v4/consultants/info?smartAppointedSpecialists=true`
    const response = await this.httpClient.get({ url, token })
    if (response.status !== 200) {
      const responseError = JSON.parse(response.body) as GetUserResponseError
      throw new Error(responseError.message)
    }
    const responseJSON = JSON.parse(response.body)
    return parseUser(responseJSON)
  }

  async updateProfile(payload: InfosToUpdateProfile): Promise<void> {
    const url = `${API_URL}/consultants/profile`
    const response = await this.httpClient.put({ url, payload })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body) as BookingResponseError
      throw new TheiaError(
        responseJSON.message || 'Failure to update profile infos.',
        responseJSON.friendlyMessage,
        responseJSON.friendlyMessageTitle,
        responseJSON.statusCode
      )
    }
  }

  async updatePersonalData(payload: PersonalData): Promise<void> {
    const url = `${API_URL}/consultants/profile`
    const response = await this.httpClient.put({ url, payload })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body) as BookingResponseError
      throw new TheiaError(
        responseJSON.message || 'Failure to update profile infos.',
        responseJSON.friendlyMessage,
        responseJSON.friendlyMessageTitle,
        responseJSON.statusCode
      )
    }
  }

  async deleteDocuments(consultantId: string): Promise<void> {
    const url = `${NIMBA_API_URL}/children/cpf?consultantId=${consultantId}`
    const response = await this.httpClient.delete({ url })
    if (response.status !== 200) {
      throw new Error(response.body)
    }
    return JSON.parse(response.body)
  }

  async validateInformation(
    verificationCode: string,
    email?: string,
    phone?: string
  ): Promise<void> {
    const url = `${API_URL}/v7/consultants/confirmation`
    const payload = { verificationCode, value: email || phone }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = response.body
        ? JSON.parse(response.body) as TheiaV6ResponseError
        : { statusCode: response.status, error: 'Error validating data.' } as TheiaV6ResponseError
      throw new TheiaError(
        responseJSON.error || 'Error validating data.',
        responseJSON.friendlyMessage?.message || 'Não foi possível concluir sua solicitação.',
        responseJSON.friendlyMessage?.title || 'Ops, aconteceu um problema',
        (responseJSON.statusCode || response.status).toString()
      )
    }
  }

  getWeekSuggestions(): Promise<WeekSuggestionsResponse> {
    const suggestionsUrl = `${API_URL}/v4/consultants/suggestions`
    return this.getAsJson(suggestionsUrl)
  }

  getWeekSuggestionsToJourney(): Promise<WeekSuggestionsResponse> {
    const suggestionsUrl = `${API_URL}/v4/consultants/suggestions?showSuggestionsByWeek=true`
    return this.getAsJson(suggestionsUrl)
  }

  getBookingSuggestion(): Promise<BookingSuggestionResponse> {
    const suggestionsUrl = `${API_URL}/v7/bookings/suggestion-reminders`
    return this.getAsJson(suggestionsUrl)
  }

  async updateBookingSuggestion(
    reminderType: string,
    bookingIds?: string[],
    prescriptionId?: string
  ): Promise<void> {
    const url = `${API_URL}/v7/bookings/suggestion-reminders`
    const payload = bookingIds ? {
      type: reminderType,
      bookingIds,
      prescriptionId
    } : {
      type: reminderType,
      bookingIds: null
    }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) throw new Error('Failed to update booking suggestions.')
  }

  async getTwilioToken(): Promise<string> {
    const twilioTokenUrl = `${API_URL}/chat/token`
    return (await this.getAsJson(twilioTokenUrl)).token
  }

  async getTwilioVideoToken(uuid: string): Promise<BookingView> {
    const url = `${API_URL}/video/token?roomName=${uuid}`
    const response = await this.httpClient.get({ url })
    const jsonParse = JSON.parse(response.body)
    if (response.status !== 200) {
      const responseJSON = jsonParse.error
      throw new TheiaError(responseJSON.errorMessage, responseJSON.friendlyMessage)
    }
    return parseBookingView(jsonParse)
  }

  async getProfessionals(token?: string): Promise<Array<ProfessionalListType>> {
    const url = `${AVAILABILITIES_URL}/availability`
    const response = await this.httpClient.getCrossDomain({ url, token })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body) as AvailabilityResponseError
      throw new TheiaError(
        `${responseJSON.error} - ${responseJSON.title}`,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode
      )
    }
    return JSON.parse(response.body)
  }

  getProfessionalSummary(id: string, token?: string): Promise<Professional> {
    const professionalUrl = `${API_URL}/v3/professionals/${id}/summary?inPersonEnabled=true`
    return this.getAsJson(professionalUrl, token)
  }

  getProfessionalForRescheduling(id: string): Promise<Professional> {
    const professionalUrl = `${API_URL}/v2/professionals/rescheduling/${id}/availability`
    return this.getAsJson(professionalUrl)
  }

  async getProfessionalAvailability(
    chronosId: string,
    token?: string
  ): Promise<Array<AvailabilitySlot>> {
    const url = `${AVAILABILITIES_URL}/availability/${chronosId}`
    const response = await this.httpClient.getCrossDomain({ url, token })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body) as AvailabilityResponseError
      throw new TheiaError(
        responseJSON.error,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode
      )
    }
    return JSON.parse(response.body)
  }

  getPromotionalCode(
    codeCoupon?: string,
    productId?: string
  ): Promise<PromotionalCodeView> {
    const promotionalCodeURL = `${API_URL}/v3/professionals/${productId}/promotional-price/${codeCoupon}?productId=${productId}`
    return this.getAsJson(promotionalCodeURL)
  }

  async reScheduleBooking(
    oldBookingId: string,
    availabilityId: string,
  ): Promise<ScheduleView> {
    const url = `${API_URL}/v5/bookings/reschedule`
    const payload = {
      id: availabilityId,
      cancelledBookingId: oldBookingId,
    }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 201) {
      const responseJSON = JSON.parse(response.body) as BookingResponseError
      throw new TheiaError(
        responseJSON.message || 'default',
        responseJSON.friendlyMessage,
        responseJSON.friendlyMessageTitle,
        responseJSON.statusCode || `${response.status}`
      )
    }

    return JSON.parse(response.body)
  }

  getBookings(): Promise<Array<Booking>> {
    const allBookingsUrl = `${API_URL}/consultants/bookings`
    return this.getAsJson(allBookingsUrl)
  }

  getBookingHistory(): Promise<Array<Booking>> {
    const allBookingsUrl = `${API_URL}/v4/consultants/past-bookings`
    return this.getAsJson(allBookingsUrl)
  }

  async getFeedbackReasons(): Promise<Array<Reason>> {
    const feedbackReasons = `${API_URL}/bookings/feedback-reasons`
    const reasons: Array<any> = await this.getAsJson(feedbackReasons)
    return reasons.map(this.parseReason)
  }

  async getBookingsCancellationReasons(): Promise<Array<Reason>> {
    const cancellationReasons = `${API_URL}/bookings/cancellation-reasons`
    const reasons: Array<any> = await this.getAsJson(cancellationReasons)
    return reasons.map(this.parseReason)
  }

  async postFeedback(
    bookingId: string,
    reasonsId: number[],
    otherMotive: string,
    meetExpectation: boolean
  ): Promise<void> {
    const url = `${API_URL}/bookings/${bookingId}/feedback`
    const payload = {
      reasons: reasonsId,
      feedbackDescription: otherMotive,
      meetExpectation
    }
    const response = await this.httpClient.post({ url, payload })

    if (response.status !== 200) throw Error('Failed to post feedback.')
  }

  async cancelBooking(
    bookingId: string,
    reasonsId: number[],
    otherMotive: string,
  ): Promise<void> {
    const url = `${API_URL}/v5/bookings/${bookingId}/cancel`
    const payload = {
      reasons: reasonsId,
      otherMotive
    }
    const response = await this.httpClient.put({ url, payload })
    if (response.status !== 200) {
      if (response.status === 500) {
        throw new Error('default')
      }
      const responseJSON = JSON.parse(response.body)
      throw new TheiaError(responseJSON.errorMessage, responseJSON.friendlyMessage)
    }
  }

  getPaymentSessionId(): Promise<{ message: string }> {
    const sessionIdUrl = `${API_URL}/payments/session`
    return this.getAsJson(sessionIdUrl);
  }

  getPlatform(): Promise<Platform> {
    const platformUrl = `${API_URL}/platform/info`
    return this.getAsJson(platformUrl)
  }

  getUnavailability(): Promise<Array<PlatformUnavailability>> {
    const unavailability = `${API_URL}/platform/unavailability`
    return this.getAsJson(unavailability)
  }

  async bookingConfirmationInfoSMS(
    bookingId: string,
    token: string
  ): Promise<BookingConfirmationInfo> {
    const payload = { token }
    const url = `${API_URL}/v5/bookings/${bookingId}/confirmation/info`
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body)
      throw new TheiaError(
        responseJSON.message,
        responseJSON.friendlyMessage,
        responseJSON.friendlyMessageTitle,
        responseJSON.statusCode
      )
    }
    return JSON.parse(response.body)
  }

  async confirmBookingSMS(
    bookingId: string,
    token: string,
    participantType?: string,
    professionalStatus?: string
  ): Promise<void> {
    const type = participantType
    const payload = { token, type, professionalStatus }
    const url = `${API_URL}/v5/bookings/${bookingId}/confirmation`
    const response = await this.httpClient.post({ url, payload })

    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body)
      throw new TheiaError(
        responseJSON.message,
        responseJSON.friendlyMessage,
        responseJSON.friendlyMessageTitle,
        responseJSON.statusCode
      )
    }
  }

  async cancelBookingSMS(
    bookingId: string,
    token: string
  ): Promise<void> {
    const payload = { token }
    const url = `${API_URL}/v5/bookings/${bookingId}/confirmation/cancel`
    const response = await this.httpClient.post({ url, payload })

    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body)
      throw new TheiaError(
        responseJSON.message,
        responseJSON.friendlyMessage,
        responseJSON.friendlyMessageTitle,
        responseJSON.statusCode
      )
    }
  }

  async bookingProfessionalConfirmationInfoSMS(
    bookingId: string,
    token: string
  ): Promise<BookingProfessionalConfirmationInfo> {
    const payload = { token }
    const url = `${API_URL}/v5/bookings/${bookingId}/professional-confirmation/info`
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body)
      throw new TheiaError(
        responseJSON.message,
        responseJSON.friendlyMessage,
        responseJSON.friendlyMessageTitle,
        responseJSON.statusCode
      )
    }
    return JSON.parse(response.body)
  }

  /** OnboardingService */
  getBrazilStates(): Promise<BrazilState> {
    const brazilStatesUrl = `${API_URL}/platform/estados`
    return this.getAsJson(brazilStatesUrl)
  }

  getCitiesOfState(id: string): Promise<CityOfState> {
    const citiesOfStateUrl = `${API_URL}/platform/${id}/municipios`
    return this.getAsJson(citiesOfStateUrl)
  }

  getHealthInsuranceCompanies(): Promise<HealthInsuranceCompany[]> {
    const healthInsuranceCompaniesUrl = `${API_URL}/platform/health-insurance-companies`
    return this.getAsJson(healthInsuranceCompaniesUrl)
  }

  /** PrescriptionsService */
  getHighlights(): Promise<PrescriptionsHighlight> {
    const prescriptionsHighlightsUrl = `${API_URL}/v5/consultants/highlights`
    return this.getAsJson(prescriptionsHighlightsUrl)
  }

  getPrescriptionsInformation(): Promise<Array<Prescriptions>> {
    const prescriptionsInformationUrl = `${API_URL}/v5/consultants/prescriptions/`
    return this.getAsJson(prescriptionsInformationUrl)
  }

  getUsgPrescription(): Promise<UsgPrescription> {
    const url = `${API_URL}/usg/content`
    return this.getAsJson(url)
  }

  async getUsgData(
    chronosId: string,
    token?: string
  ): Promise<ProfessionalListType> {
    const url = `${AVAILABILITIES_URL}/exams/${chronosId}`
    const response = await this.httpClient.getCrossDomain({ url, token })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body) as AvailabilityResponseError
      throw new TheiaError(
        responseJSON.error,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode
      )
    }
    return JSON.parse(response.body)
  }

  async getUsgAvailability(
    chronosId: string,
    params: UsgAvailabilityParams,
    token?: string,
  ): Promise<Array<AvailabilitySlot>> {
    const { examId, consultantId } = params
    const urlParams = mountUrlParams({ examId, consultantId })
    const url = `${AVAILABILITIES_URL}/availability/${chronosId}${urlParams}`
    const response = await this.httpClient.getCrossDomain({ url, token })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body) as AvailabilityResponseError
      throw new TheiaError(
        responseJSON.error,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode
      )
    }
    return JSON.parse(response.body)
  }

  async postUsgStatus(
    status: UsgStatus,
    usgId: string,
  ): Promise<void> {
    const url = `${API_URL}/usg`
    const payload = {
      status,
      usgId
    }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) throw Error('Failed to post usg prescription status.')
  }

  async sendHighlight(highlight: string): Promise<void> {
    const url = `${API_URL}/v5/consultants/highlights?name=${highlight}`
    const response = await this.httpClient.put({ url })
    if (response.status !== 200) throw Error('Failure to send highlights infos.')
  }

  async postNotificationConfirmation(
    type: HighlightDesiretype
  ): Promise<void> {
    const url = `${API_URL}/notification/confirmation?type=${type}`
    const response = await this.httpClient.post({ url })
    if (response.status !== 200) throw new Error(`Failure to post notification confirmation to ${type}.`)
  }

  /** GuidedJourneyService */
  getPregnancyJourney(): Promise<PregnancyJourney> {
    const url = `${API_URL}/consultants/health-plan-journey`
    return this.getAsJson(url)
  }

  getGuidedJourney(): Promise<GuidedJourney> {
    const url = `${API_URL}/journey/quarter`
    return this.getAsJson(url)
  }

  getQuarterDetails(id: string): Promise<ResponseQuarterDetails> {
    const url = `${API_URL}/journey/${id}`
    return this.getAsJson(url)
  }

  getConsultationInfos(id: string): Promise<ConsultationInfos> {
    const url = `${API_URL}/journey/${id}/info`
    return this.getAsJson(url)
  }

  async setViewedGuidedJourney(): Promise<void> {
    const url = `${API_URL}/notification-tooltip/journey`
    const response = await this.httpClient.post({ url })
    if (response.status !== 200) throw new Error('Failure to set guided journey viewed.')
  }

  async checkJourneyStep(id: string): Promise<UpdateCardResponse> {
    const url = `${API_URL}/journey/${id}`
    const response = await this.httpClient.put({ url })
    if (response.status !== 200) {
      throw new Error(response.body)
    }
    return JSON.parse(response.body)
  }

  async uncheckJourneyStep(id: string): Promise<UpdateCardResponse> {
    const url = `${API_URL}/journey/${id}`
    const response = await this.httpClient.delete({ url })
    if (response.status !== 200) {
      throw new Error(response.body)
    }
    return JSON.parse(response.body)
  }

  /** PediatricFlowService */
  async getChildrenData(): Promise<BabyData[]> {
    const url = `${NIMBA_API_URL}/children`
    const response = await this.httpClient.get({ url })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body) as NimbaResponseError
      throw new TheiaError(
        responseJSON.error || responseJSON.title,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode
      )
    }
    return JSON.parse(response.body)
  }

  async updateChildren(payload: BabyData[]): Promise<void> {
    const url = `${NIMBA_API_URL}/children`
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 201 && response.status !== 200) {
      const responseJSON = JSON.parse(response.body) as NimbaResponseError
      throw new TheiaError(
        responseJSON.error || responseJSON.title,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode
      )
    }
  }

  getChildrenSummary(): Promise<ChildrenSummary> {
    const url = `${NIMBA_API_URL}/children/info`
    return this.getAsJson(url)
  }

  /** HealthPlanService */
  getHealthInsurancePlanData(): Promise<HealthPlanData> {
    const healthInsurancePlanUrl = `${API_URL}/consultants/health-insurance-plan`
    return this.getAsJson(healthInsurancePlanUrl)
  }

  async postUserHealthInsurance(payload: PostUserHealthPlanType): Promise<HealthInsurancePlanData> {
    const url = `${API_URL}/consultants/health-insurance-plan`
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      throw new Error('Failure to save user health insurance infos')
    }
    return JSON.parse(response?.body)
  }

  /** Consultant */
  async postMaternalDesire(payload: MaternalDesireType): Promise<void> {
    const url = `${GO_PRONTS_API_URL}/desire`
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) throw new Error('Failure to post maternal desire.')
  }

  getDesireContent(): Promise<DesireContent> {
    const url = `${GO_PRONTS_API_URL}/desire/content`
    return this.getAsJson(url)
  }

  /** ScheduleFlowService */
  async getCategoriesToSchedule(childId?: string): Promise<ResponseCategories> {
    const url = `${AVAILABILITIES_URL}/availability/category${childId ? `?childId=${childId}` : ''}`
    const response = await this.httpClient.get({ url })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body) as AvailabilityResponseError
      throw new TheiaError(
        responseJSON.error,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode
      )
    }
    return JSON.parse(response.body)
  }

  async getPaymentMethods(startTime: number): Promise<PaymentMethods> {
    const url = `${BOOKING_API_URL}/paymentMethods?startTime=${startTime}`
    const response = await this.httpClient.get({ url })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body) as AvailabilityResponseError
      throw new TheiaError(
        `${responseJSON.error} - ${responseJSON.title}`,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode
      )
    }
    return JSON.parse(response.body)
  }

  async confirmSchedule(scheduleInfos: ScheduleInfosType): Promise<ScheduleResponse> {
    const url = `${API_URL}/v5/bookings`
    const payload = {
      id: scheduleInfos.availabilityId,
      availabilityId: scheduleInfos.availabilityId,
      paymentMethodId: scheduleInfos.paymentMethodId || null,
      promotionalCode: scheduleInfos.promotionalCode || null,
      childrenId: scheduleInfos.childrenId || null,
      newPaymentMethodAdded: scheduleInfos.newPaymentMethodAdded,
      paymentType: scheduleInfos.paymentType
    }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 201) {
      const responseJSON = JSON.parse(response.body) as BookingResponseError
      throw new TheiaError(
        responseJSON.message,
        responseJSON.friendlyMessage,
        responseJSON.friendlyMessageTitle,
        responseJSON.statusCode || `${response.status}`
      )
    }
    return JSON.parse(response.body)
  }

  /** AvailabilitiesService */
  async getAvailabilities(
    categoryId: string,
    params: AvailabilitiesRequestParams
  ): Promise<ResponseAvailabilitiesSlots> {
    const mountedParams = mountUrlParams(params)
    const url = `${AVAILABILITIES_URL}/availability/category/${categoryId}${mountedParams}`
    const response = await this.httpClient.get({ url })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body) as AvailabilityResponseError
      throw new TheiaError(
        responseJSON.error,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode
      )
    }
    return JSON.parse(response.body)
  }

  /** SpecialistProvider */

  getSpecialist(identity: string): Promise<Specialist> {
    const specialistUrl = `${API_URL}/specialist?identity=${identity}`
    return this.getAsJson(specialistUrl)
  }

  async getAvailableSpecialists(
    categoryId: string,
    meetingType: AttendanceAvailabilityType
  ): Promise<SpecialistFromList[]> {
    const url = `${AVAILABILITIES_URL}/availability/category/${categoryId}/specialists?meetingType=${meetingType}`
    const response = await this.httpClient.get({ url })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body) as AvailabilityResponseError
      throw new TheiaError(
        `${responseJSON.error} - ${responseJSON.title}`,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode
      )
    }
    return JSON.parse(response.body)
  }

  async getSpecialistDetails(specialistId: string): Promise<SpecialistDetails> {
    const url = `${AVAILABILITIES_URL}/specialist/summary/${specialistId}`
    const response = await this.httpClient.get({ url })
    if (response.status !== 200) {
      const responseJSON = JSON.parse(response.body) as AvailabilityResponseError
      throw new TheiaError(
        `${responseJSON.error} - ${responseJSON.title}`,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode
      )
    }
    return JSON.parse(response.body)
  }

  /** TermsService */

  getCurrentTerms(): Promise<Array<CurrentTerm>> {
    const url = `${API_URL}/terms/current`
    return this.getAsJson(url)
  }

  /** UserProvider */

  /**
    * precisa da validação do body porque senão dá erro no parse e o throw nem acontece
  */

  async postRecaptchaGoogle(token: string): Promise<void> {
    const url = `${API_URL}/v1/recaptcha?token=${token}`
    const response = await this.httpClient.post({ url })
    if (response.status !== 200) throw new Error('Failure to recaptcha')
  }

  async postLoginUnlock(token: string): Promise<void> {
    const url = `${API_URL}/v5/login-unlock`
    const payload = { token }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = response.body
        ? JSON.parse(response.body) as UnlockLoginResponseError
        : { statusCode: response.status, message: 'Desculpe, não foi possível desbloquear seu login.' } as UnlockLoginResponseError
      throw new LoginError(
        responseJSON.message,
        responseJSON.resendLink,
        responseJSON.redirect,
        response.status
      )
    }
    return JSON.parse(response.body)
  }

  async postEmailUnlock(login: string): Promise<void> {
    const url = `${API_URL}/v6/email-unlock`
    const payload = { login }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = response.body
        ? JSON.parse(response.body) as TheiaV6ResponseError
        : { statusCode: response.status, error: 'Failure to post email unlock' } as TheiaV6ResponseError
      throw new TheiaError(
        responseJSON.error,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode?.toString()
      )
    }
  }

  async postLogin(payload: LoginPayload): Promise<UserCredentials> {
    const url = `${API_URL}/v6/login`
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = response.body
        ? JSON.parse(response.body) as UserLoginError
        : { statusCode: response.status, error: '' } as UserLoginError

      const friendlyMessage = typeof responseJSON.friendlyMessage === 'string' ? JSON.parse(responseJSON.friendlyMessage) : responseJSON.friendlyMessage
      throw new LoginFriendlyError(
        responseJSON.error,
        responseJSON.statusCode || response.status,
        responseJSON.blocked,
        responseJSON.recaptcha,
        friendlyMessage
      )
    }
    return JSON.parse(response.body)
  }

  async handleRefreshToken(refreshToken: string) {
    const url = `${API_URL}/v6/refresh`
    const payload = { refreshToken }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = response.body
        ? JSON.parse(response.body) as TheiaV6ResponseError
        : { statusCode: response.status, error: 'Failure to refresh token' } as TheiaV6ResponseError
      throw new TheiaError(
        responseJSON?.error,
        responseJSON?.friendlyMessage?.message,
        responseJSON?.friendlyMessage?.title,
        (response.status || responseJSON?.statusCode)?.toString()
      )
    }
    return JSON.parse(response.body)
  }

  async handleSignUp(payload: SignUpPayload): Promise<SignUpResponse> {
    const url = `${API_URL}/v6/consultants/simplified-signup`
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = response.body
        ? JSON.parse(response.body) as TheiaV6ResponseError
        : { statusCode: response.status, error: 'Failure to sign up' } as TheiaV6ResponseError
      throw new TheiaError(
        responseJSON.error,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode?.toString()
      )
    }
    return JSON.parse(response.body)
  }

  async handlePasswordRecovery(email: string) {
    const url = `${API_URL}/v6/recovery`
    const payload = { email }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = response.body
        ? JSON.parse(response.body) as TheiaV6ResponseError
        : { statusCode: response.status, error: 'Failure to recovery password' } as TheiaV6ResponseError
      throw new TheiaError(
        responseJSON.error,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode?.toString()
      )
    }
  }

  async logout(refreshToken: string) {
    const url = `${API_URL}/v6/logout`
    const payload = { refreshToken }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = response.body
        ? JSON.parse(response.body) as TheiaV6ResponseError
        : { statusCode: response.status, error: 'Failure to logout' } as TheiaV6ResponseError
      throw new TheiaError(
        responseJSON.error,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        responseJSON.statusCode?.toString()
      )
    }
  }

  async getUserConfirmationInfos(): Promise<UserConfirmationInfos> {
    const url = `${API_URL}/v7/consultants/confirmation`
    const response = await this.httpClient.get({ url })
    if (response.status !== 200) {
      const responseJSON = response.body
        ? JSON.parse(response.body) as TheiaV6ResponseError
        : { statusCode: response.status, error: 'Failure to get user confirmation infos' } as TheiaV6ResponseError
      throw new TheiaError(
        responseJSON.error,
        responseJSON.friendlyMessage?.message,
        responseJSON.friendlyMessage?.title,
        (responseJSON.statusCode || response.status).toString()
      )
    }
    return JSON.parse(response.body);
  }

  async verifySignUpData(signUpData: SignUpPayload) {
    const url = `${API_URL}/v7/consultants/signup-verification`
    const payload = {
      email: signUpData.email,
      phone: signUpData.phone,
      password: signUpData.password
    }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = response.body
        ? JSON.parse(response.body) as TheiaV6ResponseError
        : { statusCode: response.status, error: 'Error verifying signup data.' } as TheiaV6ResponseError
      throw new TheiaError(
        responseJSON.error || 'Error verifying signup data.',
        responseJSON.friendlyMessage?.message || 'Não foi possível concluir sua solicitação.',
        responseJSON.friendlyMessage?.title || 'Ops, aconteceu um problema',
        (responseJSON.statusCode || response.status).toString()
      )
    }
  }

  async verifiedSignUp(signUpData: VerifiedSignUpPayload): Promise<SignUpResponse> {
    const url = `${API_URL}/v7/consultants/simplified-signup`
    const payload = {
      ...signUpData
    }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = response.body
        ? JSON.parse(response.body) as UserLoginError
        : { statusCode: response.status, error: 'Error on signup.' } as UserLoginError
      throw new TheiaError(
        responseJSON.error || 'Error on signup.',
        responseJSON.friendlyMessage?.message || 'Não foi possível concluir sua solicitação.',
        responseJSON.friendlyMessage?.title || 'Ops, aconteceu um problema',
        (responseJSON.statusCode || response.status).toString()
      )
    }
    return JSON.parse(response.body)
  }

  async sendVerificationCode(email?: string, phone?: string): Promise<void> {
    const url = `${API_URL}/v7/consultants/send-verification`
    const payload = { value: email || phone }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = response.body
        ? JSON.parse(response.body) as TheiaV6ResponseError
        : { statusCode: response.status, error: 'Error requesting new verification code.' } as TheiaV6ResponseError
      throw new TheiaError(
        responseJSON.error || 'Error requesting new verification code.',
        responseJSON.friendlyMessage?.message || 'Não foi possível concluir sua solicitação.',
        responseJSON.friendlyMessage?.title || 'Ops, aconteceu um problema',
        (responseJSON.statusCode || response.status).toString()
      )
    }
  }

  async sendSignUpVerificationCode(email: string): Promise<void> {
    const url = `${API_URL}/v7/consultants/send/verification/onboarding`
    const payload = { value: email }
    const response = await this.httpClient.post({ url, payload })
    if (response.status !== 200) {
      const responseJSON = response.body
        ? JSON.parse(response.body) as TheiaV6ResponseError
        : { statusCode: response.status, error: 'Error requesting verification code.' } as TheiaV6ResponseError
      throw new TheiaError(
        responseJSON.error || 'Error requesting verification code.',
        responseJSON.friendlyMessage?.message || 'Não foi possível concluir sua solicitação.',
        responseJSON.friendlyMessage?.title || 'Ops, aconteceu um problema',
        `${responseJSON.statusCode}` || `${response.status}`
      )
    }
  }
}
