import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { RegularButton, Loader } from 'theia-web-ds';
import { AppState } from '../../../apps/main/store'

import './ScheduleConfirmSMS.scss'
import LogoHorizontal from '../../../../assets/horizontal-logo.svg'
import MainIllustration from '../../../../public/images/confirm-ilustra.png'
import {
  bookingConfirmationInfoSMSAction,
  confirmBookingSMSAction,
  promtpCancelBookingSMSModalAction
} from '../../../state/scheduleConfirmationSMS/actions'
import ScheduleCancellationSmsModal from './ScheduleCancellationSmsModal'
import { LocalAddress } from '../../../utils/LocalAddress'
import { formatToHours } from '../../../utils/helpers'
import { AGENDAR } from '../../../routes/RoutesConstants'

interface Props {
  step: string;
  bookingConfirmationInfoSMS: (
    bookingId: string, token: string
  ) => void;
  confirmBookingSMS: (bookingId: string, token: string) => void;
  promtpCancelBookingSMSModal: () => void;
  id: string;
  token: string;
  startDate?: string;
  professionalName: string;
  address?: LocalAddress;
  alreadyConfirmed: boolean;
  alreadyCancelled: boolean;
  isModalOpened: boolean;
  confirmationAvailable: boolean;
  isLoading: boolean;
}

function ScheduleConfirmSMS({
  step,
  bookingConfirmationInfoSMS,
  confirmBookingSMS,
  promtpCancelBookingSMSModal,
  id,
  token,
  startDate,
  professionalName,
  address,
  alreadyConfirmed,
  alreadyCancelled,
  isModalOpened,
  confirmationAvailable,
  isLoading
}: Props) {
  const history = useHistory();
  const [stepConfirmation, setStepConfirmation] = useState('')
  const startDateString = startDate && moment(startDate).format('DD/MM')
  const startHourString = startDate && formatToHours(startDate)

  useEffect(() => {
    bookingConfirmationInfoSMS(id, token)
  }, [])

  useEffect(() => {
    if (step) {
      setStepConfirmation(step)
    }
  }, [step])

  useEffect(() => {
    if (!confirmationAvailable) {
      setStepConfirmation('confirmation-not-available')
    }
  }, [confirmationAvailable])

  useEffect(() => {
    if (alreadyConfirmed) {
      setStepConfirmation('already-confirmed')
    } else if (alreadyCancelled) {
      setStepConfirmation('already-canceled')
    }
  }, [alreadyConfirmed, alreadyCancelled])

  const onConfirmSchedule = () => {
    confirmBookingSMS(id, token)
  }

  const onCancelledSchedule = () => {
    history.push(AGENDAR)
  }

  const getButtonAction = () => {
    if (stepConfirmation === 'initial') {
      onConfirmSchedule()
    } else if (stepConfirmation === 'just-canceled' || stepConfirmation === 'already-canceled' || stepConfirmation === 'confirmation-not-available') {
      onCancelledSchedule()
    }
  }

  const hasAddress = () => {
    if (address && address.name !== '') {
      return `no endereço ${address.name}, ${address.number}`
    }
    return ''
  }

  function cancelBooking() {
    promtpCancelBookingSMSModal()
  }

  function getConfirmMainText() {
    switch (stepConfirmation) {
      case 'initial':
        return `Você confirma presença na consulta do dia ${startDateString} às ${startHourString} ${hasAddress()} com ${professionalName}?`
      case 'just-confirmed':
        return `Sua presença na consulta do dia ${startDateString} às ${startHourString} foi confirmada!`
      case 'already-confirmed':
        return `Sua presença na consulta do dia ${startDateString} às ${startHourString} já está confirmada!`
      case 'just-canceled':
        return `Sua consulta do dia ${startDateString} às ${startHourString} foi cancelada!`
      case 'already-canceled':
        return `Sua consulta do dia ${startDateString} às ${startHourString} já foi cancelada!`
      case 'confirmation-not-available':
        return `A consulta do dia ${startDateString} às ${startHourString} não está disponível para confirmação!`
      default:
        return `Você confirma presença na consulta do dia ${startDateString} às ${startHourString} no endereço ${address} com ${professionalName}?`
    }
  }

  function getConfirmSubText() {
    switch (stepConfirmation) {
      case 'initial':
        return 'Em caso negativo, iremos cancelar sua consulta e você poderá remarcá-la para outro horário.'
      case 'just-confirmed':
        return 'Em caso de dúvidas, é só entrar em contato com nossa equipe através do chat no aplicativo.'
      case 'already-confirmed':
        return 'Em caso de dúvidas, é só entrar em contato com nossa equipe através do chat no aplicativo.'
      case 'just-canceled':
        return 'Mas esperamos você na plataforma para agendar novamente, viu?!'
      case 'already-canceled':
        return 'Mas não tem problema, podemos agendar novamente, viu?!'
      case 'confirmation-not-available':
        return 'Aproveite para agendar sua próxima consulta com a Theia!'
      default:
        return 'Em caso negativo, iremos cancelar sua consulta e você poderá remarcá-la para outro horário.'
    }
  }

  function getConfirmButtonText() {
    switch (stepConfirmation) {
      case 'initial':
        return 'Confirmo presença'
      case 'just-canceled':
        return 'Agendar novo horário'
      case 'already-canceled':
        return 'Agendar novo horário'
      case 'confirmation-not-available':
        return 'Agendar nova consulta'
      default:
        return 'Confirmo presença'
    }
  }
  return (
    <>
      {isModalOpened && <ScheduleCancellationSmsModal />}
      <div className="ml-4 mt-6 lg:ml-20">
        <LogoHorizontal className="logo-schedule-confirm" />
      </div>
      {startDateString === undefined || isLoading
        ? (
          <div className="flex justify-center w-full">
            <div className="flex items-center">
              <Loader />
            </div>
          </div>
        )
        : (
          <div className="schedule-confirm pt-4">
            <div className="icon-welcome">
              <img src={MainIllustration} alt="ilustração Theia" />
            </div>
            <p className="font-medium text-headlineMedium text-primaryDark mt-12 mx-auto mb-6 text-center text-confirm">
              {getConfirmMainText()}
            </p>
            <p className="font-light text-primaryDark text-textMedium text-center text-confirm">
              {getConfirmSubText()}
            </p>
            <div className={`${stepConfirmation === 'initial' ? 'full-container' : 'half-container'} confirm-button-container flex flex-col lg:flex-row items-center mt-8`}>
              {stepConfirmation !== 'just-confirmed' && stepConfirmation !== 'already-confirmed' && (
                <RegularButton
                  type="button"
                  label={getConfirmButtonText()}
                  onClick={getButtonAction}
                  maxWidth="100%"
                />
              )}
              {stepConfirmation === 'initial' && (
                <RegularButton
                  type="button"
                  label="Não poderei comparecer"
                  onClick={cancelBooking}
                  variant="secondary"
                  maxWidth="100%"
                  extraClass="font-medium md:ml-4 my-6 md:my-0"
                />
              )}
            </div>
          </div>
        )}
    </>
  )
}

const mapStateToProps = ({ scheduleConfirmationSMS }: AppState) => ({
  step: scheduleConfirmationSMS.step,
  startDate: scheduleConfirmationSMS.startDate,
  professionalName: scheduleConfirmationSMS.professionalName,
  address: scheduleConfirmationSMS.address,
  alreadyConfirmed: scheduleConfirmationSMS.alreadyConfirmed,
  alreadyCancelled: scheduleConfirmationSMS.alreadyCancelled,
  isLoading: scheduleConfirmationSMS.isLoading,
  isModalOpened: scheduleConfirmationSMS.isModalOpened,
  confirmationAvailable: scheduleConfirmationSMS.confirmationAvailable
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  bookingConfirmationInfoSMS: bookingConfirmationInfoSMSAction,
  confirmBookingSMS: confirmBookingSMSAction,
  promtpCancelBookingSMSModal: promtpCancelBookingSMSModalAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleConfirmSMS)
