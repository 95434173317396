/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/browser'
import { AvailabilitySlot, ProfessionalListType } from '../../domain/Professional';
import {
  HighlightDesiretype,
  Prescriptions,
  PrescriptionsHighlight,
  PrescriptionsService,
  UsgAvailabilityParams,
  UsgPrescription,
  UsgStatus,
} from '../../domain/Prescriptions';
import { AsyncAction } from '../utils'

export const GET_HIGHLIGHTS = 'GET_HIGHLIGHTS'
export const SUCCESS_GET_HIGHLIGHTS = 'SUCCESS_GET_HIGHLIGHTS'
export const FAILURE_GET_HIGHLIGHTS = 'FAILURE_GET_HIGHLIGHTS'

export const GET_PRESCRIPTIONS_INFOS = 'GET_PRESCRIPTIONS_INFOS'
export const SUCCESS_GET_PRESCRIPTIONS_INFOS = 'SUCCESS_GET_PRESCRIPTIONS_INFOS'
export const FAILURE_GET_PRESCRIPTIONS_INFOS = 'FAILURE_GET_PRESCRIPTIONS_INFOS'

export const GET_USG_PRESCRIPTION = 'GET_USG_PRESCRIPTION'
export const SUCCESS_GET_USG_PRESCRIPTION = 'SUCCESS_GET_USG_PRESCRIPTION'
export const FAILURE_GET_USG_PRESCRIPTION = 'FAILURE_GET_USG_PRESCRIPTION'

export const GET_USG_DATA = 'GET_USG_DATA'
export const SUCCESS_GET_USG_DATA = 'SUCCESS_GET_USG_DATA'
export const FAILURE_GET_USG_DATA = 'FAILURE_GET_USG_DATA'

export const GET_USG_AVAILABILITY = 'GET_USG_AVAILABILITY'
export const SUCCESS_GET_USG_AVAILABILITY = 'SUCCESS_GET_USG_AVAILABILITY'
export const FAILURE_GET_USG_AVAILABILITY = 'FAILURE_GET_USG_AVAILABILITY'

export const POST_USG_STATUS = 'POST_USG_STATUS'
export const SUCCESS_POST_USG_STATUS = 'SUCCESS_POST_USG_STATUS'
export const FAILURE_POST_USG_STATUS = 'FAILURE_POST_USG_STATUS'

export const POST_PRESCRIPTIONS_HIGHLIGHTS = 'POST_PRESCRIPTIONS_HIGHLIGHTS'
export const SUCCESS_POST_PRESCRIPTIONS_HIGHLIGHTS = 'SUCCESS_POST_PRESCRIPTIONS_HIGHLIGHTS'
export const FAILURE_POST_PRESCRIPTIONS_HIGHLIGHTS = 'FAILURE_POST_PRESCRIPTIONS_HIGHLIGHTS'

export const POST_NOTIFICATION_CONFIRMATION = 'POST_NOTIFICATION_CONFIRMATION'
export const SUCCESS_POST_NOTIFICATION_CONFIRMATION = 'SUCCESS_POST_NOTIFICATION_CONFIRMATION'
export const FAILURE_POST_NOTIFICATION_CONFIRMATION = 'FAILURE_POST_NOTIFICATION_CONFIRMATION'

export const SUPPRESS_USG_MODAL = 'SUPPRESS_USG_MODAL'

interface GetHighlightsActionType {
  type: typeof GET_HIGHLIGHTS;
}

interface SuccessGetHighlightsActionType {
  type: typeof SUCCESS_GET_HIGHLIGHTS;
  highlights: PrescriptionsHighlight;
}

interface FailureGetHighlightsActionType {
  type: typeof FAILURE_GET_HIGHLIGHTS;
}

interface GetPrescriptionsInfosActionType {
  type: typeof GET_PRESCRIPTIONS_INFOS;
}

interface SuccessGetPrescriptionsInfosActionType {
  type: typeof SUCCESS_GET_PRESCRIPTIONS_INFOS;
  prescriptions: Array<Prescriptions>;
}

interface FailureGetPrescriptionsInfosActionType {
  type: typeof FAILURE_GET_PRESCRIPTIONS_INFOS;
}

interface GetUsgPrescriptionActionType {
  type: typeof GET_USG_PRESCRIPTION;
}

interface SuccessGetUsgPrescriptionActionType {
  type: typeof SUCCESS_GET_USG_PRESCRIPTION;
  usgPrescription: UsgPrescription;
}

interface FailureGetUsgPrescriptionActionType {
  type: typeof FAILURE_GET_USG_PRESCRIPTION;
}

interface GetUsgDataActionType {
  type: typeof GET_USG_DATA;
}

interface SuccessGetUsgDataActionType {
  type: typeof SUCCESS_GET_USG_DATA;
  usgData: ProfessionalListType;
}

interface FailureGetUsgDataActionType {
  type: typeof FAILURE_GET_USG_DATA;
}

interface GetUsgAvailabilityActionType {
  type: typeof GET_USG_AVAILABILITY;
}

interface SuccessGetUsgAvailabilityActionType {
  type: typeof SUCCESS_GET_USG_AVAILABILITY;
  usgAvailability: Array<AvailabilitySlot>;
}

interface FailureGetUsgAvailabilityActionType {
  type: typeof FAILURE_GET_USG_AVAILABILITY;
}

interface PostUsgStatusActionType {
  type: typeof POST_USG_STATUS;
}

interface SuccessPostUsgStatusActionType {
  type: typeof SUCCESS_POST_USG_STATUS;
}

interface FailurePostUsgStatusActionType {
  type: typeof FAILURE_POST_USG_STATUS;
}

interface PostPrescriptionsHighlightsActionType {
  type: typeof POST_PRESCRIPTIONS_HIGHLIGHTS;
}

interface SuccessPostPrescriptionsHighlightsActionType {
  type: typeof SUCCESS_POST_PRESCRIPTIONS_HIGHLIGHTS;
  highlights: PrescriptionsHighlight;
}

interface FailurePostPrescriptionsHighlightsActionType {
  type: typeof FAILURE_POST_PRESCRIPTIONS_HIGHLIGHTS;
}

interface PostNotificationConfirmationActionType {
  type: typeof POST_NOTIFICATION_CONFIRMATION;
}

interface SuccessPostNotificationConfirmationActionType {
  type: typeof SUCCESS_POST_NOTIFICATION_CONFIRMATION;
  highlights: PrescriptionsHighlight;
}

interface FailurePostNotificationConfirmationActionType {
  type: typeof FAILURE_POST_NOTIFICATION_CONFIRMATION;
}

interface SuppressUsgModalActionType {
  type: typeof SUPPRESS_USG_MODAL;
}

export type PrescriptionsTypes = GetHighlightsActionType
| SuccessGetHighlightsActionType
| FailureGetHighlightsActionType
| GetPrescriptionsInfosActionType
| SuccessGetPrescriptionsInfosActionType
| FailureGetPrescriptionsInfosActionType
| GetUsgPrescriptionActionType
| SuccessGetUsgPrescriptionActionType
| FailureGetUsgPrescriptionActionType
| GetUsgDataActionType
| SuccessGetUsgDataActionType
| FailureGetUsgDataActionType
| GetUsgAvailabilityActionType
| SuccessGetUsgAvailabilityActionType
| FailureGetUsgAvailabilityActionType
| PostUsgStatusActionType
| SuccessPostUsgStatusActionType
| FailurePostUsgStatusActionType
| PostPrescriptionsHighlightsActionType
| SuccessPostPrescriptionsHighlightsActionType
| FailurePostPrescriptionsHighlightsActionType
| PostNotificationConfirmationActionType
| SuccessPostNotificationConfirmationActionType
| FailurePostNotificationConfirmationActionType
| SuppressUsgModalActionType

export function getHighlightsAction():
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: GET_HIGHLIGHTS })
    try {
      const highlights = await prescriptionsService.getHighlights()
      dispatch({ type: SUCCESS_GET_HIGHLIGHTS, highlights })
    } catch (error) {
      dispatch({ type: FAILURE_GET_HIGHLIGHTS })
      Sentry.captureException(new Error(`Failure to fetch user highlights - ${error.message}`))
    }
  }
}

export function getPrescriptionsInformationAction():
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: GET_PRESCRIPTIONS_INFOS })
    try {
      const prescriptions = await prescriptionsService.getPrescriptionsInformation()
      dispatch({ type: SUCCESS_GET_PRESCRIPTIONS_INFOS, prescriptions })
    } catch (error) {
      dispatch({ type: FAILURE_GET_PRESCRIPTIONS_INFOS })
      Sentry.captureException(new Error(`Failure to fetch prescriptions - ${error.message}`))
    }
  }
}

export function getUsgPrescriptionAction():
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: GET_USG_PRESCRIPTION })
    try {
      const usgPrescription = await prescriptionsService.getUsgPrescription()
      dispatch({ type: SUCCESS_GET_USG_PRESCRIPTION, usgPrescription })
    } catch (error) {
      dispatch({ type: FAILURE_GET_USG_PRESCRIPTION })
      Sentry.captureException(new Error(`Failure to fetch usg prescription - ${error.message}`))
    }
  }
}

export function getUsgDataAction(chronosId: string, token?: string):
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: GET_USG_DATA })
    try {
      const usgData = await prescriptionsService.getUsgData(chronosId, token)
      dispatch({ type: SUCCESS_GET_USG_DATA, usgData })
    } catch (error) {
      dispatch({ type: FAILURE_GET_USG_DATA })
      Sentry.captureException(new Error(`Failure to fetch usg data - ${error.message}`))
    }
  }
}

export function getUsgAvailabilityAction(
  chronosId: string,
  params: UsgAvailabilityParams,
  token?: string
):
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: GET_USG_AVAILABILITY })
    try {
      const usgAvailability = await prescriptionsService.getUsgAvailability(
        chronosId, params, token
      )
      dispatch({ type: SUCCESS_GET_USG_AVAILABILITY, usgAvailability })
    } catch (error) {
      dispatch({ type: FAILURE_GET_USG_AVAILABILITY })
      Sentry.captureException(new Error(`Failure to fetch usg availability - ${error.message}`))
    }
  }
}

export function postUsgStatusAction(status: UsgStatus, usgId: string):
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: POST_USG_STATUS })
    try {
      await prescriptionsService.postUsgStatus(status, usgId)
      dispatch({ type: SUCCESS_POST_USG_STATUS })
    } catch (error) {
      dispatch({ type: FAILURE_POST_USG_STATUS })
      Sentry.captureException(new Error(`Failure to post usg status - ${error.message}`))
    }
  }
}

export function onSendHighlightAction(highlight: string):
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: POST_PRESCRIPTIONS_HIGHLIGHTS })
    try {
      await prescriptionsService.sendHighlight(highlight)
      dispatch({ type: SUCCESS_POST_PRESCRIPTIONS_HIGHLIGHTS })
    } catch (error) {
      dispatch({ type: FAILURE_POST_PRESCRIPTIONS_HIGHLIGHTS })
      Sentry.captureException(new Error(`Unexpected error to send highlights infos - ${error.message}`))
    }
  }
}

export function postNotificationConfirmationAction(type: HighlightDesiretype):
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: POST_NOTIFICATION_CONFIRMATION })
    try {
      await prescriptionsService.postNotificationConfirmation(type)
      dispatch({ type: SUCCESS_POST_NOTIFICATION_CONFIRMATION })
    } catch (error) {
      dispatch({ type: FAILURE_POST_NOTIFICATION_CONFIRMATION })
      Sentry.captureException(new Error(`Failure to post notification confirmation to ${type} - ${error.message}`))
    }
  }
}

export const suppressUsgModalAction = (): PrescriptionsTypes => ({
  type: SUPPRESS_USG_MODAL,
})
