import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  RegularButton,
  Divider,
  FontIcon,
  Loader
} from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { UsgPrescription, UsgStatus } from '../../domain/Prescriptions'
import { postUsgStatusAction, suppressUsgModalAction } from '../../state/prescriptions/actions'
import { eventTrack } from '../../../eventGenerate'
import usgRealizadoImg from '../../../assets/usg_realizado.png'
import { setScheduleSourceAction } from '../../state/scheduleFlow/actions'
import { SourceTypes } from '../../domain/ScheduleFlow'
import './UsgModal.scss'
import { AGENDAMENTO_ULTRASSOM } from '../../routes/RoutesConstants'

interface Props {
  isVisible?: boolean;
  isLoading?: boolean;
  content?: UsgPrescription;
  suppressUsgModal: () => void;
  postUsgStatus: (status: UsgStatus, usgId: string) => void;
  setScheduleSource: (source: SourceTypes) => void;
}

function UsgModal({
  isVisible = false,
  isLoading = false,
  content,
  suppressUsgModal,
  postUsgStatus,
  setScheduleSource,
}: Props) {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const [modalUsgRealizado, setModalUsgRealizado] = useState(false)
  const [modalIsVisible, setModalIsVisible] = useState(isVisible)

  useEffect(() => {
    setModalIsVisible(isVisible)
  }, [isVisible])

  function handleCloseModal() {
    eventTrack('USG - Clicou Lembre-me depois')
    suppressUsgModal()
    setModalIsVisible(false)
  }

  function handleScheduleUsg() {
    eventTrack('USG - Clicou Agendar agora na Theia')
    if (content) {
      setModalIsVisible(false)
      setScheduleSource(SourceTypes.USG_MODAL)
      history.push(AGENDAMENTO_ULTRASSOM)
    }
  }

  function handleUsgIsDone() {
    eventTrack('USG - Clicou Já realizei o Ultrassom')
    setModalUsgRealizado(true)
  }

  function handleDismissUsg() {
    eventTrack('USG - Clicou Confirmar e não receber lembrete')
    if (content) {
      suppressUsgModal()
      setModalIsVisible(false)
      postUsgStatus('DONE', content.usgId)
    }
  }

  function handleStillWantToSchedule() {
    eventTrack('USG - Clicou Ainda quero agendar na Theia')
    setModalUsgRealizado(false)
  }

  if (modalIsVisible) {
    return (
      <div
        role="dialog"
        aria-modal="true"
        className="modal__usg__background"
        data-testid="usg-modal"
      >
        <div className="modal__usg__card">
          {isLoading && (
            <div className="flex justify-center items-center h-full w-full">
              <Loader size="80px" />
            </div>
          )}
          {!isLoading && content && (
            <div className="modal__usg__layout bg-white">
              <button
                type="button"
                aria-label="Lembre-me depois"
                onClick={handleCloseModal}
                className="modal__usg__close-button"
              >
                Lembre-me depois
                <FontIcon
                  iconType="icon-CloseLight"
                  fontSize="14px"
                  extraClass="ml-4"
                />
              </button>
              {!modalUsgRealizado && (
                <>
                  <img
                    src={content.pictureUrlWeb}
                    alt="Imagem de especialista realizando ultrassom em paciente"
                    className="hidden md:block"
                  />
                  <img
                    src={content.pictureUrlMobile}
                    alt="Imagem de especialista realizando ultrassom em paciente"
                    className="block md:hidden"
                  />
                  <div className="modal__usg__content modal__usg__scroll">
                    <h1 className="modal__usg__content--title">
                      Olá, você tem um novo pedido médico de {content.title}
                    </h1>
                    <div className="flex flex-row mb-6">
                      <FontIcon
                        iconType="icon-PhonendoscopeLight"
                        fontSize="22px"
                        extraClass="mr-4"
                      />
                      <div className="flex flex-col">
                        <h3 className="modal__usg__content--subtle">Objetivo do exame</h3>
                        <h4 className="modal__usg__content--objective">{content.objective}</h4>
                      </div>
                    </div>
                    <div className="flex flex-row mb-6">
                      <FontIcon
                        iconType="icon-CalendarAttentionLight"
                        fontSize="22px"
                        extraClass="mr-4"
                      />
                      <div className="flex flex-col">
                        <h3 className="modal__usg__content--subtle">Data limite para agendar na Theia</h3>
                        <h2 className="modal__usg__content--date">{content.limitDate}</h2>
                        <p className="modal__usg__content--obs">*{content.obs}</p>
                      </div>
                    </div>
                    {content.infoTitle && (
                      <div
                        role="button"
                        className="modal__usg__content__dropdown"
                        onClick={() => setIsOpen(!isOpen)}
                        onKeyDown={() => setIsOpen(!isOpen)}
                        tabIndex={0}
                      >
                        <div className="w-full flex mb-3 items-center">
                          <FontIcon
                            iconType="icon-HelpLight"
                            extraClass="mr-3 text-textSecondary"
                            fontSize="22px"
                          />
                          <h3 className="w-full">{content.infoTitle}</h3>
                          <FontIcon
                            iconType={isOpen ? 'icon-ArrowUp2Light' : 'icon-ArrowDown2Light'}
                            extraClass="text-textSecondary justify-self-end"
                            fontSize="22px"
                          />
                        </div>
                        {content.infoMessage && isOpen && (
                          <p className="text-textSecondary text-base font-normal mb-3">
                            {content.infoMessage}
                          </p>
                        )}
                        <Divider />
                      </div>
                    )}
                    <RegularButton
                      label="Agendar agora na Theia"
                      type="button"
                      onClick={handleScheduleUsg}
                      btnSize="large"
                      extraClass="mb-3 mt-auto"
                    />
                    <RegularButton
                      label="Já realizei o Ultrassom"
                      type="button"
                      onClick={handleUsgIsDone}
                      btnSize="large"
                      variant="text"
                    />
                  </div>
                </>
              )}
              {modalUsgRealizado && (
                <>
                  <img
                    src={usgRealizadoImg}
                    alt="Imagem de especialista realizando ultrassom em paciente"
                  />
                  <div className="modal__usg__content modal__usg__realizado ">
                    <div className="usg__realizado__icon">
                      <FontIcon
                        iconType="icon-UsgLight"
                        fontSize="45px"
                      />
                    </div>
                    <h1 className="modal__usg__content--title mt-auto">
                      Já realizou o exame de ultrassom em outra clínica médica?
                    </h1>
                    <p className="text-textSecondary text-base font-normal text-center">
                      Se sim, não tem problema!
                    </p>
                    <p className="text-textSecondary text-base font-normal mb-3 text-center">
                      Só não se esqueça de levar o exame de ultrassom no dia da
                      consulta com o Ginecologista-obstetra!
                    </p>
                    <RegularButton
                      label="Confirmar e não receber lembrete"
                      type="button"
                      onClick={handleDismissUsg}
                      btnSize="large"
                      extraClass="mb-3 mt-auto"
                    />
                    <RegularButton
                      label="Ainda quero agendar na Theia"
                      type="button"
                      onClick={handleStillWantToSchedule}
                      btnSize="large"
                      variant="text"
                      extraClass="mb-auto"
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
  return null
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  suppressUsgModal: () => { dispatch(suppressUsgModalAction()) },
  postUsgStatus: (
    status: UsgStatus,
    usgId: string
  ) => { dispatch(postUsgStatusAction(status, usgId)) },
  setScheduleSource: (source: SourceTypes) => {
    dispatch(setScheduleSourceAction(source))
  }
})

export default connect(
  undefined,
  mapDispatchToProps
)(UsgModal)
