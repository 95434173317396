import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ListItemCard } from 'theia-web-ds'
import { primary } from '../../color'
import AppMainContainerOff from '../AppMainContainerOff'
import CardYes from '../../../assets/health-insurance-card-yes.png'
import CardNo from '../../../assets/health-insurance-card-no.png'
import { FaqToggle } from '../common/FaqToggle'
import {
  doNotHaveHealthInsurancePlanAction,
  onConfirmHealthInsurancePlanAction,
  onPostHealthInsuranceAction
} from '../../state/onboarding/actions'
import { updateCurrentUserInfosAction } from '../../state/authentication/main/actions'
import { AppState } from '../../apps/main/store'
import { eventPage, eventTrack } from '../../../eventGenerate'
import { CONVENIO, INICIO } from '../../routes/RoutesConstants'

interface Props {
  onConfirmHealthInsurancePlan: () => void;
  doNotHaveHealthInsurancePlan: () => void;
  onPostHealthInsurance: (
    hasHealthInsurancePlan: boolean,
    company: string | null,
    planName: string | null,
    memberId: string | null,
    companyId: string | null,
    planId: string | null,
    benefitsTitle: string | null,
    benefitsSubtitle: string | null,
  ) => void;
  onUpdateCurrentUser: () => void;
  isSuccess: boolean;
}

function HealthInsurance({
  onConfirmHealthInsurancePlan,
  doNotHaveHealthInsurancePlan,
  onPostHealthInsurance,
  onUpdateCurrentUser,
  isSuccess
}: Props) {
  const history = useHistory()
  const textFaq = 'Essa pergunta nos ajuda a saber quais de nossos especialistas estão cobertos pelo seu plano de saúde.'
  const goToPlanType = () => {
    eventTrack('Clicou Tem Plano')
    onConfirmHealthInsurancePlan()
    history.push(CONVENIO)
  }

  useEffect(() => {
    eventPage('onboarding', 'Plano de Saúde')
    if (isSuccess) {
      doNotHaveHealthInsurancePlan()
      onUpdateCurrentUser()
      history.push(INICIO)
    }
  }, [isSuccess])

  const goToApp = () => {
    eventTrack('Clicou Não Tem Plano')
    onPostHealthInsurance(false, null, null, null, null, null, null, null)
  }

  const cardPlans = (
    <>
      <ListItemCard
        onClick={goToPlanType}
        picture={(
          <div className="px-4">
            <img
              src={CardYes}
              width={40}
              height={40}
              alt=""
            />
          </div>
      )}
        iconColor={primary}
        title="Sim, eu tenho"
        ariaLabel="Sim, eu tenho"
        isLastItem
        isItemFromList={false}
        key="health-insurance-yes"
      />
      <ListItemCard
        extraClassNameContainer="mt-4 mb-6"
        onClick={goToApp}
        picture={(
          <div className="px-4">
            <img
              src={CardNo}
              width={40}
              height={40}
              alt=""
            />
          </div>
        )}
        iconColor={primary}
        title="Não tenho plano"
        ariaLabel="Não tenho plano"
        isLastItem
        isItemFromList={false}
        key="health-insurance-no"
      />
    </>
  )
  return (
    <AppMainContainerOff hasNewLayout hasBackButton>
      <div className="onboarding-container">
        <h3 className="text-textPrimary text-textMedium text-center mb-6">
          Você tem plano de saúde?
        </h3>
        {cardPlans}
      </div>
      <FaqToggle
        iconType="icon-ChatHelpLight"
        toggleTitle="Por que essa pergunta?"
        toggleDescription={textFaq}
      />
    </AppMainContainerOff>
  )
}

const mapStateToProps = ({
  onboarding
}: AppState) => ({
  isSuccess: onboarding.isSuccess
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  onConfirmHealthInsurancePlan: onConfirmHealthInsurancePlanAction,
  doNotHaveHealthInsurancePlan: doNotHaveHealthInsurancePlanAction,
  onPostHealthInsurance: onPostHealthInsuranceAction,
  onUpdateCurrentUser: updateCurrentUserInfosAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthInsurance)
