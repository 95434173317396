/** Unauthenticated routes */
export const LOGIN = '/login'
export const NEWSIGNUP = '/newsignup'
export const RECOVERY = '/recovery'
export const PASSWORDRECOVERY = '/passwordRecovery'
export const SIGNUP = '/signup'
export const BOAS_VINDAS = '/boas-vindas'
export const MOMENTO_DE_SAUDE = '/momento-de-saude'
export const IDADE_GESTACIONAL = '/idade-gestacional'
export const DATA_NASCIMENTO = '/data-nascimento'
export const DATA_MENSTRUACAO = '/data-menstruacao'
export const LOGIN_DE_ACESSO = '/login-de-acesso'
export const LISTA_DE_INTERESSE = '/lista-de-interesse'
export const ROTINA_FEEDBACK = '/rotina-feedback'
export const DESBLOQUEIO_LOGIN = '/desbloqueio-login'
export const AGENDAMENTO = '/agendamento'
export const BOOKING_CONFIRMATION = '/booking-confirmation'
export const PROFESSIONAL_CONFIRMATION = '/professional-confirmation'
export const LOGIN_MOBILE = '/login-mobile'
export const CONFIRMAR_CONTA = '/confirmar-conta'

/** Shared routes */
export const LOCALIZACAO = '/localizacao'

/** Authenticated routes */
export const INICIO = '/inicio'
export const OLD_SPECIALISTS = '/specialists'
export const LOGOUT_MOBILE = '/logout-mobile'
export const BOOKINGS = '/bookings'
export const DADOS_DE_SAUDE = '/dados-de-saude'
export const DADOS_GESTACIONAIS = '/dados-gestacionais'
export const DADOS_PESSOAIS = '/dados-pessoais'
export const AGENDAMENTO_ULTRASSOM = '/agendamento-ultrassom'
export const NOVO_AGENDAMENTO = '/novo-agendamento'
export const PLANO_DE_SAUDE = '/plano-de-saude'
export const CONVENIO = '/convenio'
export const CONVENIO_INFORMACOES = '/convenio-informacoes'
export const CONVENIO_FEEDBACK = '/convenio-feedback'
export const SUCESSO_CADASTRO = '/sucesso-cadastro'
export const JORNADA_EXPLICADA = '/jornada-explicada'
export const PROFILE = '/profile'
export const EDIT_PROFILE = '/edit-profile'
export const EDIT_ADDRESS = '/edit-address'
export const EDIT_PAYMENT = '/edit-payment'
export const HEALTH_PLAN_INCLUDED = '/health-plan-included'
export const CHANGE_HEALTH_PLAN = '/change-health-plan'
export const HEALTHPLAN_FEEDBACK = '/healthplan-feedback'
export const MEDICAL_REQUESTS = '/medical-requests'
export const CONTENT = '/content'
export const CONSULTAS = '/consultas'
export const SAUDE = '/saude'
export const AGENDAR = '/agendar'
export const CADASTRO_PEDIATRICO = '/cadastro-pediatrico'
export const ESPECIALISTA = '/especialista'
export const CHAT = '/chat'
