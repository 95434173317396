import {
  Client,
  Channel,
  Message as TwilioMessage,
} from 'twilio-chat'
import { LocalDate } from '../../utils/LocalDate'
import {
  ChatVideoService,
  ChatVideoServiceFactory,
  Message,
  MessageReceivedHandler,
} from '../../domain/ChatVideo'

export default class TwilioChatVideoService implements ChatVideoService {
  constructor(
    private client: Client,
    private channel: Channel
  ) { }

  async getMessages(anchor?: number): Promise<Message[]> {
    const paginator = await this.channel.getMessages(30, anchor)
    return paginator.items.map((message: TwilioMessage) => (
      this.buildMessageFromTwilioMessage(message)
    ))
  }

  needForceReconnect(): boolean {
    return (
      this.client.connectionState !== 'connected'
      && this.client.connectionState !== 'connecting'
    )
  }

  sendMessage(text: string): void {
    this.channel.sendMessage(text)
  }

  shutdown() {
    this.client.shutdown()
  }

  async getUnreadMessages(): Promise<number> {
    const count = await this.channel.getMessagesCount()
    const unreadMessagesCount = this.channel.lastConsumedMessageIndex === null
      ? count : count - this.channel.lastConsumedMessageIndex - 1
    return unreadMessagesCount
  }

  setOnMessageReceived(handler: MessageReceivedHandler): void {
    this.registerHandlerOnChannel(handler)
  }

  private registerHandlerOnChannel(handler: MessageReceivedHandler): void {
    this.channel.on('messageAdded', async (message) => {
      handler(this.buildMessageFromTwilioMessage(message))
    })
  }

  typing() {
    this.channel.typing()
  }

  private buildMessageFromTwilioMessage(twilioMessage: TwilioMessage):
    Message {
    const message: Message = {
      text: twilioMessage.body,
      author: twilioMessage.author === this.client.user.identity ? 'Você' : twilioMessage.author,
      dateCreated: new LocalDate(twilioMessage.dateCreated),
      type: twilioMessage.type,
      index: twilioMessage.index,
      isSender: twilioMessage.author === this.client.user.identity
    }

    return message
  }
}

export class TwilioChatVideoServiceFactory implements ChatVideoServiceFactory {
  constructor(
    private channel?: Channel,
  ) { }

  async build(
    chatToken: string,
    id: string
  ): Promise<TwilioChatVideoService> {
    const client = await Client.create(chatToken)
    try {
      this.channel = await client.getChannelByUniqueName(`${id}`)
    } catch {
      this.channel = await client.createChannel({
        uniqueName: `${id}`,
        friendlyName: 'Video Chat Channel',
      })
    }
    if (this.channel.status !== 'joined') {
      await this.channel.join()
    }
    const twilioChatServiceInstance = new TwilioChatVideoService(
      client,
      this.channel,
    )

    client.on('tokenAboutToExpire', async () => {
      client.updateToken(chatToken)
    })

    client.on('tokenExpired', async () => {
      twilioChatServiceInstance.shutdown()
    })
    return twilioChatServiceInstance
  }
}
