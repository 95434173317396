import * as loglevelLog from "loglevel";
import { LogLevelDesc } from "loglevel";

type ArgumentsType = unknown[];

const log: loglevelLog.Logger = loglevelLog.getLogger("twilsock"); // twilsock is used by Flex SDK. Please DO NOT change

function prepareLine(prefix, args) {
  return [`${new Date().toISOString()} Twilsock ${prefix}:`].concat(
    Array.from(args)
  );
}

class Logger {
  private prefix = "";

  constructor(prefix: string) {
    this.prefix =
      prefix !== null && prefix !== undefined && prefix.length > 0
        ? " " + prefix + ":"
        : "";
  }

  setLevel(level: LogLevelDesc): void {
    log.setLevel(level);
  }

  static setLevel(level: LogLevelDesc): void {
    log.setLevel(level);
  }

  trace(...args: ArgumentsType): void {
    log.trace.apply(null, prepareLine("T", args));
  }
  debug(...args: ArgumentsType): void {
    log.debug.apply(null, prepareLine("D", args));
  }
  info(...args: ArgumentsType): void {
    log.info.apply(null, prepareLine("I", args));
  }
  warn(...args: ArgumentsType): void {
    log.warn.apply(null, prepareLine("W", args));
  }
  error(...args: ArgumentsType): void {
    log.error.apply(null, prepareLine("E", args));
  }

  static trace(...args: ArgumentsType): void {
    log.trace.apply(null, prepareLine("T", args));
  }
  static debug(...args: ArgumentsType): void {
    log.debug.apply(null, prepareLine("D", args));
  }
  static info(...args: ArgumentsType): void {
    log.info.apply(null, prepareLine("I", args));
  }
  static warn(...args: ArgumentsType): void {
    log.warn.apply(null, prepareLine("W", args));
  }
  static error(...args: ArgumentsType): void {
    log.error.apply(null, prepareLine("E", args));
  }
}

const logInstance = new Logger("");

export { Logger, logInstance as log };
