import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Divider, Loader } from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import { isNotInformed, isPostPregnant } from '../../utils/helpers'
import { ConsultantMoment } from '../../domain/Profile'
import { BabyData } from '../../domain/PediatricFlow'
import Header from '../common/molecules/Header'
import ChooseMedicalSpecialty from './ChooseMedicalSpecialty'
import {
  resetScheduleFlowAction,
  selectChildForScheduleAction,
  setLoadingStepAction,
  setPatientTypeAction,
  setScheduleStepAction
} from '../../state/scheduleFlow/actions'
import { PatientType, ScheduleFlowSteps } from '../../domain/ScheduleFlow'
import RegisterChildHealthPlan from '../pediatricFlow/RegisterChildHealthPlan'
import { cleanPromotionalCodeAction } from '../../state/payment/actions'
import ChoosePatient from './ChoosePatient'
import { SpecialistCategories } from '../../domain/Specialist'
import { getHighlightsAction } from '../../state/prescriptions/actions'
import './ScheduleView.scss'

interface Props {
  pregnancyMoment?: ConsultantMoment;
  selectedChild?: BabyData;
  selectedPatientType?: PatientType;
  activeScheduleStep?: ScheduleFlowSteps;
  loadingSteps: boolean;
  previousSelectedCategoryName?: SpecialistCategories;
  needToCreateBaby: boolean;
  babyJourney: boolean;
  isGettingPrescriptionsHighlights: boolean;
  successGetHighlights: boolean;
  pregnancyMomentText?: string;
  selectChild: (child?: BabyData) => void;
  resetScheduleFlow: () => void;
  cleanPromotionalCode: () => void;
  setScheduleStep: (step: ScheduleFlowSteps) => void;
  setPatientType: (patient?: PatientType) => void;
  setLoadingSteps: (isLoading: boolean) => void;
  getHighlights: () => void;
}

type PreScheduleViewsType = {
  name: ScheduleFlowSteps;
  renderStep: boolean;
  actionsOnGoBack?: Array<() => void>;
}

function ScheduleView({
  pregnancyMoment,
  selectedChild,
  selectedPatientType,
  activeScheduleStep,
  loadingSteps,
  previousSelectedCategoryName,
  needToCreateBaby,
  babyJourney,
  isGettingPrescriptionsHighlights,
  successGetHighlights,
  pregnancyMomentText,
  selectChild,
  resetScheduleFlow,
  cleanPromotionalCode,
  setScheduleStep,
  setPatientType,
  setLoadingSteps,
  getHighlights,
}: Props) {
  const [activeView, setActiveView] = useState(0)
  const canChoosePatient = !!(isPostPregnant(pregnancyMoment, pregnancyMomentText)
    && babyJourney && !needToCreateBaby)
  const canGoBack = canChoosePatient && activeView > 0

  const scheduleViews: PreScheduleViewsType[] = [
    {
      name: ScheduleFlowSteps.ChoosePatient,
      renderStep: canChoosePatient
    },
    {
      name: ScheduleFlowSteps.ChildHealthPlan,
      renderStep: selectedPatientType === PatientType.IS_CHILD
        && !!selectedChild
        && isNotInformed(selectedChild?.healthInsurancePlanStatus),
      actionsOnGoBack: [resetScheduleFlow],
    },
    {
      name: ScheduleFlowSteps.ChooseMedicalSpecialty,
      renderStep: true,
      actionsOnGoBack: [resetScheduleFlow],
    },
  ]

  useEffect(() => {
    if (!isGettingPrescriptionsHighlights) {
      getHighlights()
    }
  }, [])

  useEffect(() => {
    if (successGetHighlights && !isGettingPrescriptionsHighlights) {
      if (activeView > (scheduleViews.length - 1)) return
      if (scheduleViews[activeView]?.renderStep === true) {
        setScheduleStep(scheduleViews[activeView].name)
      } else {
        if (!canChoosePatient && !selectedPatientType) {
          selectChild(undefined)
          setPatientType(PatientType.IS_USER)
        }
        setActiveView(activeView + 1)
      }
    }
  }, [
    activeView,
    canChoosePatient,
    successGetHighlights,
    isGettingPrescriptionsHighlights,
    selectedChild?.healthInsurancePlanStatus
  ])

  function handleNextStep() {
    setLoadingSteps(true)
    setActiveView(activeView + 1)
  }

  function onGoBack() {
    setLoadingSteps(true)
    scheduleViews[activeView].actionsOnGoBack?.map((action) => action())
    if (activeView > 0) {
      for (let i = activeView - 1; i >= 0; i -= 1) {
        if (scheduleViews[i].renderStep === true) {
          setActiveView(i)
          return
        }
      }
    }
    resetScheduleFlow()
    cleanPromotionalCode()
    setActiveView(0)
  }

  if (!successGetHighlights || isGettingPrescriptionsHighlights) {
    return (
      <div className="schedule-view-container loading">
        <div className="schedule-view-loader">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className="schedule-view-container">
      {!loadingSteps && !previousSelectedCategoryName && (
        <div className="schedule-view-header">
          <Header title="Agendar" goBack={canGoBack ? onGoBack : undefined} />
        </div>
      )}
      <Divider className="" />
      <div className="schedule-view-body">
        <div className="schedule-view-content">
          {activeScheduleStep === ScheduleFlowSteps.ChoosePatient && (
            <ChoosePatient onChooseOption={handleNextStep} />
          )}
          {activeScheduleStep === ScheduleFlowSteps.ChildHealthPlan && (
            <RegisterChildHealthPlan
              selectedChild={selectedChild}
              actionOnMount={() => setLoadingSteps(false)}
            />
          )}
          {activeScheduleStep === ScheduleFlowSteps.ChooseMedicalSpecialty && (
            <ChooseMedicalSpecialty />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  authentication,
  prescriptions,
  scheduleFlow,
}: AppState) => ({
  pregnancyMoment: authentication.currentUser?.pregnancyMoment,
  pregnancyMomentText: authentication.currentUser?.pregnancyMomentText,
  selectedPatientType: scheduleFlow.selectedPatientType,
  selectedChild: scheduleFlow.selectedChild,
  activeScheduleStep: scheduleFlow.activeScheduleStep,
  loadingSteps: scheduleFlow.loadingStep,
  previousSelectedCategoryName: scheduleFlow.previousSelectedCategoryName,
  babyJourney: prescriptions.babyJourney,
  needToCreateBaby: prescriptions.needToCreateBaby,
  isGettingPrescriptionsHighlights: prescriptions.isLoading,
  successGetHighlights: prescriptions.successGetHighlights,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  selectChild: (child?: BabyData) => {
    dispatch(selectChildForScheduleAction(child))
  },
  resetScheduleFlow: () => {
    dispatch(resetScheduleFlowAction())
  },
  cleanPromotionalCode: () => {
    dispatch(cleanPromotionalCodeAction())
  },
  setScheduleStep: (step: ScheduleFlowSteps) => {
    dispatch(setScheduleStepAction(step))
  },
  setPatientType: (patient?: PatientType) => {
    dispatch(setPatientTypeAction(patient))
  },
  setLoadingSteps: (isLoading: boolean) => {
    dispatch(setLoadingStepAction(isLoading))
  },
  getHighlights: () => {
    dispatch(getHighlightsAction())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleView)
