import {
  GET_BOOKINGS_INFO_REQUEST,
  SUCCESS_GET_BOOKINGS_INFO,
  FAILURE_GET_BOOKINGS_INFO,
  BookingsInfoActionTypes
} from './actions'
import { BookingsInfo } from '../../domain/User'

export interface BookingsInfoState {
  bookingsInfo: BookingsInfo;
  isFetchingBookingsInfo: boolean;
}

const initialBookingsInfoState: BookingsInfoState = {
  bookingsInfo: {
    maxBookings: 0,
    currentBookings: 0,
    remainingBookings: 0,
    startDateNextValidPeriod: undefined,
  },
  isFetchingBookingsInfo: false,
}

export function bookingsInfo(
  state = initialBookingsInfoState,
  action: BookingsInfoActionTypes
): BookingsInfoState {
  switch (action.type) {
    case GET_BOOKINGS_INFO_REQUEST: {
      return {
        ...state,
        isFetchingBookingsInfo: true,
      }
    }
    case SUCCESS_GET_BOOKINGS_INFO: {
      return {
        ...state,
        isFetchingBookingsInfo: false,
        bookingsInfo: action.bookingsInfo,
      }
    }
    case FAILURE_GET_BOOKINGS_INFO: {
      return {
        ...state,
        isFetchingBookingsInfo: false
      }
    }
    default:
      return state
  }
}
