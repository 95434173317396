const SUBSCRIPTIONS_PATH = '/v4/Subscriptions';
const MAPS_PATH = '/v3/Maps';
const LISTS_PATH = '/v3/Lists';
const DOCUMENTS_PATH = '/v3/Documents';
const STREAMS_PATH = '/v3/Streams';
const INSIGHTS_PATH = '/v3/Insights';

function getWithDefault<T>(container: any, key: string, defaultValue: T): T {
  if (container && typeof container[key] !== 'undefined') {
    return container[key];
  }
  return defaultValue;
}

/**
 * Settings container for Sync library
 */
class Configuration {
  private settings: any;

  /**
   * @param {Object} options
   */
  constructor(options: any = {}) {
    const region = options.region || 'us1';
    const defaultCdsUrl = `https://cds.${region}.twilio.com`;
    const baseUri = options.cdsUri || defaultCdsUrl;
    this.settings = {
      subscriptionsUri: baseUri + SUBSCRIPTIONS_PATH,
      documentsUri: baseUri + DOCUMENTS_PATH,
      listsUri: baseUri + LISTS_PATH,
      mapsUri: baseUri + MAPS_PATH,
      streamsUri: baseUri + STREAMS_PATH,
      insightsUri: baseUri + INSIGHTS_PATH,
      sessionStorageEnabled: getWithDefault(options.Sync, 'enableSessionStorage', true),
      productId: options.productId,
    };
  }

  get subscriptionsUri(): string {
    return this.settings.subscriptionsUri;
  }

  get documentsUri(): string {
    return this.settings.documentsUri;
  }

  get listsUri(): string {
    return this.settings.listsUri;
  }

  get mapsUri(): string {
    return this.settings.mapsUri;
  }

  get streamsUri(): string {
    return this.settings.streamsUri;
  }

  get insightsUri(): string {
    return this.settings.insightsUri;
  }

  get backoffConfig(): any {
    return this.settings.backoffConfig || {};
  }

  get sessionStorageEnabled(): boolean {
    return this.settings.sessionStorageEnabled;
  }

  get productId(): string {
    return this.settings.productId;
  }
}

export { Configuration };
