import {
  AttendanceAvailabilityType,
  CategoryToSchedule,
  CreditCardInfos,
  PatientType,
  PaymentMethods,
  CategoryPaymentType,
  ScheduleFlowSteps,
  SchedulePaymentType,
  ScheduleResponse,
  SourceTypes,
} from '../../domain/ScheduleFlow'
import { BabyData } from '../../domain/PediatricFlow'
import { SpecialistCategories } from '../../domain/Specialist'
import { TheiaError } from '../../domain/errors/TheiaError'

import {
  SuccessUpdateChildHealthPlanActionType,
  SUCCESS_UPDATE_CHILD_HEALTHPLAN
} from '../pediatricFlow/actions'
import {
  ScheduleTypes,
  GET_CATEGORIES_TO_SCHEDULE,
  SUCCESS_GET_CATEGORIES_TO_SCHEDULE,
  FAILURE_GET_CATEGORIES_TO_SCHEDULE,
  SELECT_CHILD_FOR_SCHEDULE,
  RESET_SCHEDULE_FLOW,
  SELECT_CATEGORY_TO_SCHEDULE,
  SELECT_ATTENDANCE_TYPE,
  SET_SCHEDULE_STEP,
  SELECT_TIMESLOT,
  SELECT_SPECIALIST,
  SELECT_PAYMENT_METHOD,
  GET_PAYMENT_METHODS,
  SUCCESS_GET_PAYMENT_METHODS,
  FAILURE_GET_PAYMENT_METHODS,
  SET_PAYMENT_METHOD_ID,
  SET_NEW_CARD_DATA,
  CONFIRM_SCHEDULE,
  SUCCESS_CONFIRM_SCHEDULE,
  FAILURE_CONFIRM_SCHEDULE,
  SET_PREVIOUS_SELECTED_CATEGORY,
  SET_PREVIOUS_SELECTED_CHILD_ID,
  SET_PREVIOUS_SELECTED_SPECIALIST,
  SET_LOADING_STEP,
  SET_PATIENT_TYPE,
  SET_SCHEDULE_SOURCE,
  CLEAR_SCHEDULE_ERROR,
} from './actions'
import { AvailabilityTimeSlot, SlotSpecialist } from '../../domain/Availabilities'

export interface ScheduleFlowState {
  categoriesToSchedule?: CategoryToSchedule[];
  isGettingCategories: boolean;
  failureGettingCategories: boolean;
  selectedPatientType?: PatientType;
  selectedChild?: BabyData;
  selectedCategory?: CategoryToSchedule;
  selectedAttendanceType?: AttendanceAvailabilityType;
  selectedTimeSlot?: AvailabilityTimeSlot;
  selectedSpecialist?: SlotSpecialist;
  selectedPaymentMethod?: SchedulePaymentType;
  previousSelectedChildId?: string;
  previousSelectedCategoryName?: SpecialistCategories;
  previousSelectedSpecialistId?: string;
  previousSelectedSpecialistName?: string;
  activeScheduleStep?: ScheduleFlowSteps;
  error?: TheiaError;
  paymentMethods?: PaymentMethods;
  isGettingPaymentMethods: boolean;
  useOldCard: boolean;
  isFree: boolean;
  newPaymentMethodAdded: boolean;
  paymentMethodId?: string;
  newCardData?: CreditCardInfos;
  isScheduling: boolean;
  scheduleSuccess: boolean;
  scheduleSuccessInfos?: ScheduleResponse;
  scheduleError?: TheiaError;
  loadingStep: boolean;
  source?: SourceTypes;
  paymentMethodsError?: TheiaError;
}

const initialScheduleFlow: ScheduleFlowState = {
  categoriesToSchedule: undefined,
  isGettingCategories: false,
  failureGettingCategories: false,
  selectedPatientType: undefined,
  selectedChild: undefined,
  selectedCategory: undefined,
  selectedAttendanceType: undefined,
  selectedTimeSlot: undefined,
  selectedSpecialist: undefined,
  selectedPaymentMethod: undefined,
  previousSelectedChildId: undefined,
  previousSelectedCategoryName: undefined,
  previousSelectedSpecialistId: undefined,
  previousSelectedSpecialistName: undefined,
  activeScheduleStep: undefined,
  error: undefined,
  paymentMethods: undefined,
  isGettingPaymentMethods: false,
  useOldCard: false,
  isFree: false,
  newPaymentMethodAdded: false,
  paymentMethodId: undefined,
  newCardData: undefined,
  isScheduling: false,
  scheduleSuccess: false,
  scheduleSuccessInfos: undefined,
  scheduleError: undefined,
  loadingStep: false,
  source: undefined,
  paymentMethodsError: undefined
}

export function scheduleFlow(
  state = initialScheduleFlow,
  action: ScheduleTypes | SuccessUpdateChildHealthPlanActionType
): ScheduleFlowState {
  switch (action.type) {
    case RESET_SCHEDULE_FLOW: {
      return {
        ...state,
        ...initialScheduleFlow
      }
    }
    case GET_CATEGORIES_TO_SCHEDULE: {
      return {
        ...state,
        isGettingCategories: true,
        failureGettingCategories: false,
        error: undefined
      }
    }
    case SUCCESS_GET_CATEGORIES_TO_SCHEDULE: {
      const transformPaymentMethod = (payment: CategoryPaymentType) => {
        if (payment === CategoryPaymentType.HEALTH_INSURANCE) {
          return SchedulePaymentType.HEALTH_INSURANCE
        }
        if (payment === CategoryPaymentType.HEALTH_INSURANCE_CHILDREN) {
          return SchedulePaymentType.HEALTH_INSURANCE_CHILDREN
        }
        return SchedulePaymentType.NONE
      }
      return {
        ...state,
        isGettingCategories: false,
        selectedPaymentMethod: transformPaymentMethod(action.categories.paymentType),
        categoriesToSchedule: action.categories.categories
      }
    }
    case FAILURE_GET_CATEGORIES_TO_SCHEDULE: {
      return {
        ...state,
        isGettingCategories: false,
        failureGettingCategories: true,
        error: action.error
      }
    }
    case SELECT_CHILD_FOR_SCHEDULE: {
      return {
        ...state,
        selectedChild: action.selectedChild,
      }
    }
    case SET_PATIENT_TYPE: {
      return {
        ...state,
        selectedPatientType: action.patientType
      }
    }
    case SELECT_CATEGORY_TO_SCHEDULE: {
      return {
        ...state,
        selectedCategory: action.category
      }
    }
    case SELECT_ATTENDANCE_TYPE: {
      return {
        ...state,
        selectedAttendanceType: action.attendanceType
      }
    }
    case SET_SCHEDULE_STEP: {
      return {
        ...state,
        activeScheduleStep: action.step
      }
    }
    case SELECT_TIMESLOT: {
      return {
        ...state,
        selectedTimeSlot: action.selectedTimeSlot
      }
    }
    case SELECT_SPECIALIST: {
      return {
        ...state,
        selectedSpecialist: action.selectedSpecialist
      }
    }
    case SELECT_PAYMENT_METHOD: {
      return {
        ...state,
        selectedPaymentMethod: action.selectedPaymentMethod,
        useOldCard: action.useOldCard,
        isFree: action.isFree,
        newPaymentMethodAdded: action.newPaymentMethodAdded
      }
    }
    case GET_PAYMENT_METHODS: {
      return {
        ...state,
        isGettingPaymentMethods: true,
        paymentMethodsError: undefined
      }
    }
    case SUCCESS_GET_PAYMENT_METHODS: {
      return {
        ...state,
        isGettingPaymentMethods: false,
        paymentMethods: action.paymentMethods
      }
    }
    case FAILURE_GET_PAYMENT_METHODS: {
      return {
        ...state,
        isGettingPaymentMethods: false,
        paymentMethodsError: action.error
      }
    }
    case SET_PAYMENT_METHOD_ID: {
      return {
        ...state,
        paymentMethodId: action.paymentMethodId
      }
    }
    case SET_NEW_CARD_DATA: {
      return {
        ...state,
        newCardData: action.newCardData
      }
    }
    case CONFIRM_SCHEDULE: {
      return {
        ...state,
        isScheduling: true,
        scheduleSuccess: false,
        scheduleSuccessInfos: undefined,
        scheduleError: undefined
      }
    }
    case SUCCESS_CONFIRM_SCHEDULE: {
      return {
        ...state,
        isScheduling: false,
        scheduleSuccess: true,
        scheduleSuccessInfos: action.response
      }
    }
    case FAILURE_CONFIRM_SCHEDULE: {
      return {
        ...state,
        isScheduling: false,
        scheduleError: action.error,
      }
    }
    case SET_PREVIOUS_SELECTED_CATEGORY: {
      return {
        ...state,
        previousSelectedCategoryName: action.category,
      }
    }
    case SET_PREVIOUS_SELECTED_CHILD_ID: {
      return {
        ...state,
        previousSelectedChildId: action.childId,
      }
    }
    case SET_PREVIOUS_SELECTED_SPECIALIST: {
      return {
        ...state,
        previousSelectedSpecialistId: action.specialistId,
        previousSelectedSpecialistName: action.specialistName
      }
    }
    case SET_LOADING_STEP: {
      return {
        ...state,
        loadingStep: action.loadingStep,
      }
    }
    case SET_SCHEDULE_SOURCE: {
      return {
        ...state,
        source: action.source,
      }
    }
    case CLEAR_SCHEDULE_ERROR: {
      return {
        ...state,
        scheduleError: undefined
      }
    }
    // External action - pediatricFlow state
    case SUCCESS_UPDATE_CHILD_HEALTHPLAN: {
      return {
        ...state,
        selectedChild: {
          ...state.selectedChild,
          ...action.childData
        }
      }
    }
    default: return state
  }
}
