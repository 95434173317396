import React from 'react'

function RedirectForm() {
  return (
    <iframe src="https://luiza150273.typeform.com/to/bkTIJEJt" title="Formulário wishlist" className="w-full h-screen" />

  )
}

export default RedirectForm
