import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ReScheduleModal from './ReScheduleModal'
import ScheduleErrorModal from '../scheduleFlow/ScheduleErrorModal'
import { AppState } from '../../apps/main/store'
import {
  closeRescheduleErrorModalAction,
  clearRescheduleFlowAction
} from '../../state/reschedule/actions'
import { TheiaError } from '../../domain/errors/TheiaError'

interface Props {
  error?: TheiaError;
  showErrorReschedule: boolean;
  showRescheduleModal: boolean;
  closeRescheduleErrorModal: () => void;
  closeModal: () => void;
}

function RescheduleModalsGroup({
  error,
  showErrorReschedule,
  showRescheduleModal,
  closeRescheduleErrorModal,
  closeModal,
}: Props) {
  if (!showRescheduleModal && !showErrorReschedule) return (null)

  return (
    <>
      {showRescheduleModal && (
        <ReScheduleModal />
      )}
      {showErrorReschedule && (
        <ScheduleErrorModal
          error={error}
          closeModal={closeModal}
          textCloseButton="Fechar"
          extraButtonAction={closeRescheduleErrorModal}
          extraButtonActionWithCloseModal={false}
        />
      )}
    </>
  )
}

const mapStateToProps = ({
  reschedule,
}: AppState) => ({
  error: reschedule.error,
  showErrorReschedule: reschedule.showErrorReschedule,
  showRescheduleModal: reschedule.showRescheduleModal
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeRescheduleErrorModal: () => {
    dispatch(closeRescheduleErrorModalAction())
  },
  closeModal: () => { dispatch(clearRescheduleFlowAction()) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RescheduleModalsGroup)
