import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { AppDispatch } from '../state/utils'
import { setActiveRouteAction } from '../state/routerListener/actions'
import { AppState } from '../apps/main/store'
import { resetScheduleFlowAction } from '../state/scheduleFlow/actions'
import { cleanPromotionalCodeAction } from '../state/payment/actions'
import { AGENDAR, NOVO_AGENDAMENTO } from '../routes/RoutesConstants'

interface Props {
  previousRoute: string;
  activeRoute: string;
  setActiveRoute: (route?: string) => void;
  resetScheduleFlow: () => void;
  cleanPromotionalCode: () => void;
}

function RouterListener({
  previousRoute,
  activeRoute,
  resetScheduleFlow,
  cleanPromotionalCode,
  setActiveRoute,
}: Props) {
  const location = useLocation()

  useEffect(() => {
    setActiveRoute(location?.pathname)
  }, [location?.pathname])

  useEffect(() => {
    if (activeRoute !== AGENDAR && activeRoute !== NOVO_AGENDAMENTO
    && (previousRoute === AGENDAR || previousRoute === NOVO_AGENDAMENTO)) {
      resetScheduleFlow()
      cleanPromotionalCode()
    }
  }, [previousRoute, activeRoute])

  return null
}

const mapStateToProps = ({
  routerListener,
}: AppState) => ({
  previousRoute: routerListener.previousRoute,
  activeRoute: routerListener.activeRoute
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setActiveRoute: (route?: string) => {
    dispatch(setActiveRouteAction(route))
  },
  resetScheduleFlow: () => {
    dispatch(resetScheduleFlowAction())
  },
  cleanPromotionalCode: () => {
    dispatch(cleanPromotionalCodeAction())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouterListener)
