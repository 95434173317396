/* eslint-disable react/button-has-type */
import React, { HTMLAttributes } from 'react'

export interface Props extends HTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  type?: 'button' | 'submit' | 'reset';
}

export function FullButton({
  onClick,
  children,
  className,
  disabled,
  id,
  width,
  height,
  borderRadius,
  type,
  ...props
}: Props) {
  return (
    <button
      id={id}
      data-testid={id}
      type={type || 'button'}
      onClick={onClick}
      className={`focus:outline-none w-full md:w-3/4 text-textSmall border-none text-beigeColor fixed bottom-0 z-10 m-auto ${className}`}
      style={{
        opacity: `${disabled ? '0.7' : ''}`,
        width,
        height: height || '60px',
        borderRadius
      }}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}
