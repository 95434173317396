import React, { useState, useEffect } from 'react'
import { Formik, FormikProps } from 'formik'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { RegularButton, FormInput } from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import AppMainContainerOff from '../AppMainContainerOff'
import { FaqToggle } from '../common/FaqToggle'
import {
  getHealthInsuranceCompaniesAction,
  onPostHealthInsuranceAction,
  onSelectHealthInsuranceAction
} from '../../state/onboarding/actions'
import { HealthInsuranceCompany } from '../../domain/Healthplan'
import { notUndefined } from '../../utils/helpers'
import SelectInputSearch from '../common/SelectInputSearch'
import { eventPage, eventTrack } from '../../../eventGenerate'
import { ConvenioFeedbackParams } from './HealthInsuranceFeedback'
import { ConvenioInformacoesParams } from './HealthInsurancePlan'
import { CONVENIO_FEEDBACK, CONVENIO_INFORMACOES } from '../../routes/RoutesConstants'

interface Props {
  onPostHealthInsurance: (
    hasHealthInsurancePlan: boolean,
    company: string,
    planName: string,
    memberId: string,
    companyId: string,
    planId: string,
    benefitsTitle: string | null,
    benefitsSubtitle: string | null,
  ) => void;
  getHealthInsuranceCompanies: () => void;
  companies: any;
  onSelectHealthInsurance: (company: string) => void;
  isLoading: boolean;
}

interface HealthInsurancePlanForm {
  hasHealthInsurancePlan: boolean;
  companyName: string;
  planName: string;
  memberId: string;
  companyId: string;
  planId: string;
  otherCompany: string;
}

function renderHealthInsuranceTypeForm(
  {
    handleSubmit,
    isSubmitting,
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    errors,
    touched
  }: FormikProps<HealthInsurancePlanForm>,
  getHealthInsuranceCompanies: () => void,
  companies: Array<HealthInsuranceCompany>,
  onSelectHealthInsurance: (company: string) => void,
  isLoading: boolean
) {
  const history = useHistory()
  const [hasOtherCompany, setHasOtherCompany] = useState(false)
  const [companiesOptions, setCompaniesOptions] = useState<any>()
  const [plans, setPlans] = useState<any>()
  const [companyName, setCompanyName] = useState('')

  useEffect(() => {
    eventPage('onboarding', 'Convenio')
    getHealthInsuranceCompanies()
  }, [])

  useEffect(() => {
    if (values.companyName === 'Outro') {
      setHasOtherCompany(true)
      setCompanyName(values.otherCompany)
    } else {
      setHasOtherCompany(false)
      setFieldValue('otherCompany', '')
    }
  }, [values.companyName, values.otherCompany])

  const disabledButton = !values.companyName || values.companyName === ''
    || (values.companyName === 'Outro' && (!values.otherCompany || values.otherCompany === ''))

  useEffect(() => {
    if (companies) {
      const allCompanies = companies.map((item) => item.company)
      setCompaniesOptions(allCompanies)
    }
  }, [companies])

  const onSubmit = () => {
    eventTrack('Clicou Continuar Convenio')
    if (plans.length === 0) {
      handleSubmit()
      history.push(CONVENIO_FEEDBACK, {
        companyName,
      } as ConvenioFeedbackParams)
    } else {
      history.push(CONVENIO_INFORMACOES, {
        plans,
        companyName,
        companyId: values.companyId
      } as ConvenioInformacoesParams)
    }
  }

  useEffect(() => {
    if (values.companyName !== '') {
      const company = companies.find(
        (item) => item.company === values.companyName
      )
      setCompanyName(values.companyName)
      onSelectHealthInsurance(values.companyName)
      if (company) {
        setFieldValue('companyId', company.companyId)
        setPlans(company.plans)
      }
    } else {
      setFieldValue('companyId', '')
    }
  }, [values.companyName])

  return (
    <AppMainContainerOff hasNewLayout hasBackButton>
      <div className="onboarding-container">
        <h3 className="text-textPrimary text-textMedium text-center mb-6">
          Qual é o seu plano de saúde?
        </h3>
        <div className="mb-4">
          <SelectInputSearch
            name="companyName"
            options={companiesOptions}
            placeholder="Selecione seu convênio"
            disabled={isLoading}
            searchInputPlaceholder="Digite seu convênio"
            extraOnBlur={() => eventTrack('Selecionou Convenio')}
          />
        </div>
        {hasOtherCompany && (
          <div className="mb-4">
            <FormInput
              label="Nome do plano de saúde"
              type="text"
              name="otherCompany"
              placeholder="Digite o nome do seu plano"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.otherCompany}
              id="otherCompany"
              errorMessage={touched.otherCompany ? errors.otherCompany : ''}
            />
          </div>
        )}
        <div className="mb-6">
          <RegularButton
            onClick={onSubmit}
            label="Continuar"
            isSubmitting={isSubmitting}
            disabled={disabledButton || isSubmitting}
          />
        </div>
      </div>
      <FaqToggle
        toggleTitle="Por que essa pergunta?"
        toggleDescription="Essa pergunta nos ajuda a saber quais de nossos especialistas estão cobertos pelo seu plano de saúde."
        iconType="icon-ChatHelpLight"
      />
    </AppMainContainerOff>
  )
}
const initialValues = {
  hasHealthInsurancePlan: true,
  companyName: '',
  planName: '',
  memberId: '',
  companyId: '',
  planId: '',
  otherCompany: ''
}

function HealthInsuranceType({
  onPostHealthInsurance,
  getHealthInsuranceCompanies,
  companies,
  onSelectHealthInsurance,
  isLoading
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({
        hasHealthInsurancePlan,
        companyName,
        planName,
        memberId,
        companyId,
        planId,
        otherCompany
      }) => {
        const company = otherCompany || companyName
        onPostHealthInsurance(
          hasHealthInsurancePlan,
          company,
          planName,
          memberId,
          companyId,
          planId,
          null,
          null
        )
      }}
    >
      {(props) => renderHealthInsuranceTypeForm(
        props,
        getHealthInsuranceCompanies,
        companies,
        onSelectHealthInsurance,
        isLoading
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  onboarding
}: AppState) => {
  const allCompanies = Object.values(onboarding.byCompanyName)
    .map((p) => p.company)
    .filter(notUndefined)
  return {
    isLoading: onboarding.isLoading,
    companies: allCompanies
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onPostHealthInsurance: onPostHealthInsuranceAction,
  getHealthInsuranceCompanies: getHealthInsuranceCompaniesAction,
  onSelectHealthInsurance: onSelectHealthInsuranceAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthInsuranceType)
