import React from 'react'
import { FontIcon } from '../FontIcon/FontIcon'
import { getKeyValue } from '../../utils/helpers'
import './NewChipButton.scss'

export interface Props {
  label: string;
  id?: string;
  variant?: 'default' | 'primary' | 'success' | 'error';
  size?: 'tiny' | 'small' | 'large';
  fontSize?: '11px' | '14px' | '16px';
  height?: string;
  width?: string;
  isSelected?: boolean;
  isFilled?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  iconBefore?: string;
  iconAfter?: string;
  extraClass?: string;
  onClick?: () => void;
}

const mapFontSize = {
  '11px': 'text-labelSmall',
  '14px': 'text-labelMedium',
  '16px': 'text-labelLarge'
}

export function NewChipButton({
  label,
  id,
  variant,
  size,
  fontSize,
  height,
  width,
  isSelected,
  isFilled,
  isLoading,
  disabled,
  iconBefore,
  iconAfter,
  extraClass,
  onClick,
}: Props) {
  const isClickable = !!onClick

  const realVariant = () => {
    let chipVariant
    if (isLoading) {
      chipVariant = 'loader'
    } else if (disabled) {
      chipVariant = 'disabled'
    } else {
      chipVariant = variant || 'default'
    }
    return chipVariant
  }

  const chipContent = (
    <>
      {iconBefore && (
        <FontIcon iconType={iconBefore} fontSize={fontSize || '14px'} />
      )}
      <p className={`new-chip-label ${getKeyValue(mapFontSize, (fontSize || '14px'))}`}>
        {label}
      </p>
      {iconAfter && (
        <FontIcon iconType={iconAfter} fontSize={fontSize || '14px'} extraClass="ml-auto" />
      )}
    </>
  )

  return isClickable ? (
    <button
      type="button"
      className={`new-chip-container cursor-pointer
        ${realVariant()}
        ${size || 'small'}
        ${isSelected ? 'selected' : ''}
        ${isFilled ? 'filled' : ''}
        ${extraClass}
      `}
      onClick={onClick}
      disabled={disabled}
      data-testid="new-chip-button"
      style={{ height, width }}
      id={id}
    >
      {chipContent}
    </button>
  ) : (
    <div
      className={`new-chip-container cursor-default
        ${realVariant()}
        ${size || 'small'}
        ${isSelected ? 'selected' : ''}
        ${isFilled ? 'filled' : ''}
        ${extraClass}
      `}
      data-testid="new-chip-div"
      style={{ height, width }}
      id={id}
    >
      {chipContent}
    </div>
  )
}
