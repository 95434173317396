import React from 'react'
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker'
import { ptBR } from 'date-fns/locale/pt-BR'
import { FontIcon } from '../FontIcon/FontIcon'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import 'react-datepicker/dist/react-datepicker.css'
import './DateInput.scss'

registerLocale('ptbr', ptBR)

export interface Props extends ReactDatePickerProps {
  extraClassName?: string;
  label?: string;
  placeholder?: string;
  onChange: (date: Date) => void;
  value?: string;
  id: string;
  blockPastDates?: boolean;
  blockFutureDates?: boolean;
  name: string;
  required?: boolean;
  onBlur?: () => void;
  hasError?: boolean;
  errorText?: string;
  maxDate?: Date;
  minDate?: Date;
  hideIcon?: boolean;
  disabled?: boolean;
}

export function DateInput({
  id,
  value,
  name,
  label,
  placeholder,
  onChange,
  extraClassName,
  required,
  blockPastDates,
  blockFutureDates,
  onBlur,
  hasError,
  errorText,
  maxDate,
  minDate,
  disabled,
  hideIcon,
  ...props
}: Props) {
  return (
    <div className={`date-input-container ${extraClassName}`}>
      <label htmlFor={id} className="label-medium">{label}</label>
      <div className="date-input-wrapper">
        <ReactDatePicker
          required={required}
          id={id}
          selected={value
            ? new Date(value) : undefined}
          dateFormat="dd/MM/yyyy"
          className={`date-input ${hasError ? 'error' : ''}`}
          name={name}
          onChange={onChange}
          showYearDropdown
          showMonthDropdown
          autoComplete="off"
          disabledKeyboardNavigation
          placeholderText={placeholder}
          locale="ptbr"
          maxDate={maxDate}
          minDate={minDate}
          filterDate={(date: Date) => {
            if (blockPastDates) {
              return new Date() < date
            }
            if (blockFutureDates) {
              return new Date() > date
            }
            return true
          }}
          onBlur={onBlur}
          disabled={disabled}
          portalId="datepicker-outer-container"
          showPopperArrow={false}
          popperPlacement="bottom-start"
          {...props}
        />
        {!hideIcon && (<FontIcon iconType="icon-CalendarLight date-input-icon" fontSize="20px" />)}
      </div>
      {hasError && errorText && (
        <ErrorMessage error={errorText} />
      )}
    </div>
  )
}
