import { LocalAddress } from '../utils/LocalAddress'
import { ScheduleType } from './Booking'
import { SpecialistCategories } from './Specialist'
import { LocalDate } from './utils/Date'

export type AttendaceType = 'ONLY_IN_PERSON' | 'IN_PERSON_AND_VIRTUAL' | 'ONLY_VIRTUAL'
export type BookingHint = 'WAITING_FIRST_OBS' | 'FIRST_OBS_BOOKED' | 'ENABLE_IN_PERSON_ONLY' | 'ENABLE_AFTER_OBS_IN_PERSON_ONLY' | 'NOT_APPLIED' | 'FIRST_OBS_DONE'

export type TimeSlot = {
  id: string;
  type: ScheduleType;
  startDate: LocalDate;
  endDate: LocalDate;
  attendanceAddress?: LocalAddress;
  productId: string;
  paymentSlipAllowed: boolean;
  paymentSlipDueDate: number;
  pixAllowed: boolean;
  pixDueDate: number;
}

export type AvailabilityInfo = {
  availability: Array<TimeSlot>;
  affectedByBookingRules: boolean;
  affectedBlockingDays: number;
  bookingRulesWarningMessage: string;
  priceInCents?: number;
  priceInCentsToInPerson?: number;
  priceInCentsToExtraBooking?: number;
}

export type Professional = {
  id: string;
  name: string;
  registrationCode: string;
  pictureUrl: string;
  category: SpecialistCategories;
  biographyHtml: string;
  availabilityInfo: AvailabilityInfo;
  attendanceAddress: LocalAddress;
  inPersonAttendance: boolean;
  shouldBeCharged: boolean;
  healthInsuranceDiscount: boolean;
  bookingHint?: BookingHint;
}

export type ProfessionalListType = {
  id: string;
  name: string;
  category: SpecialistCategories;
  pictureUrl: string;
  chronosId: string;
  price: number;
  shouldBeCharged: boolean;
  healthInsuranceDiscount: boolean;
  bookingHint?: BookingHint;
  attendanceType: AttendaceType;
}

export type AvailabilitySlot = {
  id: string;
  meetingType: ScheduleType;
  startDate: LocalDate;
  endDate: LocalDate;
  attendanceAddress?: LocalAddress;
  productId: string;
  paymentSlipAllowed: boolean;
  paymentSlipDueDate: number;
  pixAllowed: boolean;
  pixDueDate: number;
  categoryId?: string;
  specialistId?: string;
}

export type ProfessionalData = {
  professional?: ProfessionalListType;
  professionalSummary?: Professional;
  availability?: Array<AvailabilitySlot>;
  isFetchingSummary: boolean;
  isFetchingAvailability: boolean;
}

export type ProfessionalsById = {
  [id: string]: ProfessionalData;
}

export interface ProfessionalProvider {
  getProfessionalSummary: (id: string, token?: string) => Promise<Professional>;
  getProfessionals: (token?: string) => Promise<Array<ProfessionalListType>>;
  getProfessionalAvailability: (id: string, token?: string) => Promise<Array<AvailabilitySlot>>;
  getProfessionalForRescheduling: (id: string) => Promise<Professional>;
}

export const defaultCategory = SpecialistCategories.ALL
