import { ApiVideoClient } from './ApiVideo'
import { BookingViewService, BookingView } from '../../domain/Booking'
import { CallConnectionError } from '../../domain/errors/CallConnectionError'

export default class ApiBookingViewService implements BookingViewService {
  constructor(private apiClient: ApiVideoClient) { }

  async getBookingView(uuid: string): Promise<BookingView> {
    let bookingView: BookingView
    try {
      bookingView = await this.apiClient.getTwilioVideoToken(uuid)
    } catch (error) {
      const friendlyMessage = error.friendlyMessage || 'Desculpe! Estamos com problema. Por favor, tente novamente mais tarde.'
      throw new CallConnectionError(error.message, friendlyMessage)
    }

    if (bookingView.endDate.hasPassedTokenExpirationTime()) {
      throw new CallConnectionError('A consulta já finalizou', 'A consulta já finalizou')
    } else if (bookingView.tokenError) {
      throw new CallConnectionError(bookingView.tokenError, 'Desculpe! Estamos com problema. Por favor, tente novamente mais tarde.')
    }

    return bookingView
  }
}
