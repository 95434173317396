import * as Sentry from '@sentry/browser';
import { Action, AsyncAction } from '../utils'
import {
  CallService,
  CallServiceFactory,
  BookingVideoTrack,
  BookingAudioTrack
} from '../../domain/Call'
import { AppState } from '../../apps/main/store'
import { BookingViewService, BookingView } from '../../domain/Booking'
import { LocalDate } from '../../domain/utils/Date'
import { PROMPT_FEEDBACK } from '../feedback/actions'
import { ChatVideoServiceFactory } from '../../domain/ChatVideo';
import { CallConnectionError } from '../../domain/errors/CallConnectionError';

export const CONNECT = 'CONNECT'
export const DISCONNECT = 'DISCONNECT'
export const LOCAL_CONNECTING = 'LOCAL_CONNECTING'
export const LOCAL_FORCE_DISCONNECT_WITH_DUPLICATE_ROOM = 'LOCAL_FORCE_DISCONNECT_WITH_DUPLICATE_ROOM'
export const LOCAL_DISCONNECT_WITH_DEFAULT_PROBLEM = 'LOCAL_DISCONNECT_WITH_DEFAULT_PROBLEM'
export const LOCAL_RECONNECTING = 'LOCAL_RECONNECTING'
export const LOCAL_RECONNECTED = 'LOCAL_RECONNECTED'
export const REMOTE_CONNECT = 'REMOTE_CONNECT'
export const REMOTE_DISCONNECT = 'REMOTE_DISCONNECT'
export const REMOTE_RECONNECTING = 'REMOTE_RECONNECTING'
export const REMOTE_RECONNECTED = 'REMOTE_RECONNECTED'
export const REMOTE_VIDEO_CONNECTED = 'REMOTE_VIDEO_CONNECTED'
export const REMOTE_VIDEO_DISCONNECTED = 'REMOTE_VIDEO_DISCONNECTED'
export const REMOTE_AUDIO_CONNECTED = 'REMOTE_AUDIO_CONNECTED'
export const REMOTE_AUDIO_DISCONNECTED = 'REMOTE_AUDIO_DISCONNECTED'
export const TOGGLE_AUDIO = 'TOGGLE_AUDIO'
export const TOGGLE_VIDEO = 'TOGGLE_VIDEO'
export const TOGGLE_CHAT = 'TOGGLE_CHAT'
export const UPDATE_DOWNLINK_STATE = 'UPDATE_DOWNLINK_STATE'
export const BOOKING_ERROR = 'BOOKING_ERROR'

export const PROMPT_BROWSER_ERROR = 'PROMPT_BROWSER_ERROR'
export const SUPPRESS_BROWSER_ERROR = 'SUPPRESS_BROWSER_ERROR'

export const PROMPT_PERMISSION = 'PROMPT_PERMISSION'
export const SUPPRESS_PROMPT_PERMISSION = 'SUPPRESS_PROMPT_PERMISSION'

export const NOT_SUPPORTED_BROWSER = 'NOT_SUPPORTED_BROWSER'

export const NETWORK_QUALITY = 'NETWORK_QUALITY'
export const BAD_NETWORK_QUALITY = ' BAD_NETWORK_QUALITY'

export const ADD_TRACKS_TO_CLEAR = 'ADD_TRACKS_TO_CLEAR'

export interface ConnectActionType {
  type: typeof CONNECT;
  id: string;
  callService: CallService;
  endDate: LocalDate;
  remoteUserName: string;
  startDate: LocalDate;
  localVideoTrack: BookingVideoTrack;
  localAudioTrack: BookingAudioTrack;
  chatToken: string;
  consultantName: string;
  uuid: string;
}

interface DisconnectActionType {
  type: typeof DISCONNECT;
}

interface LocalReconnectingActionType {
  type: typeof LOCAL_RECONNECTING;
}

interface LocalReconnectedActionType {
  type: typeof LOCAL_RECONNECTED;
}

interface RemoteConnectActionType {
  type: typeof REMOTE_CONNECT;
}

interface RemoteDisconnectActionType {
  type: typeof REMOTE_DISCONNECT;
}

interface RemoteReconnectingActionType {
  type: typeof REMOTE_RECONNECTING;
}

interface RemoteReconnectedActionType {
  type: typeof REMOTE_RECONNECTED;
}

interface RemoteVideoConnectedActionType {
  type: typeof REMOTE_VIDEO_CONNECTED;
  remoteVideoTrack: BookingVideoTrack;
}

interface RemoteVideoDisconnectedActionType {
  type: typeof REMOTE_VIDEO_DISCONNECTED;
}

interface RemoteAudioConnectedActionType {
  type: typeof REMOTE_AUDIO_CONNECTED;
  remoteAudioTrack: BookingAudioTrack;
}

interface RemoteAudioDisconnectedActionType {
  type: typeof REMOTE_AUDIO_DISCONNECTED;
}

export interface ToggleAudioActionType {
  type: typeof TOGGLE_AUDIO;
  localAudioActive: boolean;
}

export interface ToggleVideoActionType {
  type: typeof TOGGLE_VIDEO;
  localVideoActive: boolean;
}

export interface ToggleChatActionType {
  type: typeof TOGGLE_CHAT;
}

export interface UpdateDownlinkStateActionType {
  type: typeof UPDATE_DOWNLINK_STATE;
  isSwitchedOff: boolean;
}

interface BookingErrorActionType {
  type: typeof BOOKING_ERROR;
  errorMessage: string;
  isProblemWithVideo: boolean;
}

interface PromptBrowserErrorActionType {
  type: typeof PROMPT_BROWSER_ERROR;
}

interface SuppressBrowserErrorActionType {
  type: typeof SUPPRESS_BROWSER_ERROR;
}

interface PromptPermissionActionType {
  type: typeof PROMPT_PERMISSION;
}

interface SuppressPermissionActionType {
  type: typeof SUPPRESS_PROMPT_PERMISSION;
}

interface NotSupportedBrowserLogEventActionType {
  type: typeof NOT_SUPPORTED_BROWSER;
}

interface NetworkQualityActionType {
  type: typeof NETWORK_QUALITY;
  networkQualityLevel: number;
  networkQualityStats: any;
}

interface LocalForceDisconnectWithDuplicateActionType {
  type: typeof LOCAL_FORCE_DISCONNECT_WITH_DUPLICATE_ROOM;
}

interface LocalConectingActionType {
  type: typeof LOCAL_CONNECTING;
}

interface LocalDisconnectWithDefaultProblemActionType {
  type: typeof LOCAL_DISCONNECT_WITH_DEFAULT_PROBLEM;
}

export interface HasBadNetworkQualityLogEventActionType {
  type: typeof BAD_NETWORK_QUALITY;
  networkQualityLevel: number;
}

interface AddTracksToClearActionType {
  type: typeof ADD_TRACKS_TO_CLEAR;
  newMediaStream: MediaStream;
}

export type BrowserErrorTypes =
  PromptBrowserErrorActionType |
  SuppressBrowserErrorActionType

export type PromptPermissionTypes =
PromptPermissionActionType |
SuppressPermissionActionType

export type BookingActionTypes =
  ConnectActionType |
  DisconnectActionType |
  RemoteConnectActionType |
  RemoteDisconnectActionType |
  RemoteVideoConnectedActionType |
  RemoteVideoDisconnectedActionType |
  RemoteAudioConnectedActionType |
  RemoteAudioDisconnectedActionType |
  ToggleAudioActionType |
  ToggleVideoActionType |
  ToggleChatActionType |
  UpdateDownlinkStateActionType |
  BookingErrorActionType |
  RemoteReconnectingActionType |
  RemoteReconnectedActionType |
  LocalReconnectingActionType |
  LocalReconnectedActionType |
  NetworkQualityActionType |
  LocalForceDisconnectWithDuplicateActionType |
  LocalConectingActionType |
  LocalDisconnectWithDefaultProblemActionType |
  HasBadNetworkQualityLogEventActionType |
  AddTracksToClearActionType

export function connectAction(uuid: string, userType: 'professional' | 'consultant'):
  AsyncAction<{}, {
    callServiceFactory: CallServiceFactory;
    bookingViewService: BookingViewService;
    chatVideoServiceFactory: ChatVideoServiceFactory;
  }> {
  return async (dispatch, _getState,
    { callServiceFactory, bookingViewService }) => {
    let bookingView: BookingView
    try {
      dispatch({ type: LOCAL_CONNECTING })
      bookingView = await bookingViewService.getBookingView(uuid)

      const callService = await callServiceFactory.build(bookingView.token)
      const {
        localVideoTrack, localAudioTrack
      } = callService.connect()
      const remoteUserName = userType === 'consultant' ? bookingView.professionalName : bookingView.consultantName
      callService.configureRemote({
        connectRemote: () => dispatch({ type: REMOTE_CONNECT }),
        disconnectRemote: () => dispatch({ type: REMOTE_DISCONNECT }),
        updateDownlinkState: (isSwitchedOff: boolean) => dispatch({
          type: UPDATE_DOWNLINK_STATE, isSwitchedOff
        }),
        videoConnect: (remoteVideoTrack: BookingVideoTrack) => dispatch({
          type: REMOTE_VIDEO_CONNECTED, remoteVideoTrack
        }),
        videoDisconnect: () => dispatch({ type: REMOTE_VIDEO_DISCONNECTED }),
        audioConnect: (remoteAudioTrack: BookingAudioTrack) => dispatch({
          type: REMOTE_AUDIO_CONNECTED, remoteAudioTrack
        }),
        audioDisconnect: () => dispatch({ type: REMOTE_AUDIO_DISCONNECTED }),
        reconnectingRemote: () => dispatch({ type: REMOTE_RECONNECTING }),
        reconnectedRemote: () => dispatch({ type: REMOTE_RECONNECTED }),
        forceDisconnectWithDuplicateRoomLocal: () => dispatch({
          type: LOCAL_FORCE_DISCONNECT_WITH_DUPLICATE_ROOM
        }),
        disconnectWithDefaultProblemLocal: () => dispatch({
          type: LOCAL_DISCONNECT_WITH_DEFAULT_PROBLEM
        })
      })
      callService.configureLocal({
        localReconnecting: () => dispatch({ type: LOCAL_RECONNECTING }),
        localReconnected: () => dispatch({ type: LOCAL_RECONNECTED }),
        localNetworkQualityChange: (
          networkQualityLevel: number, networkQualityStats: any
        ) => dispatch({
          type: NETWORK_QUALITY,
          networkQualityLevel,
          networkQualityStats
        }),
      })
      dispatch({
        type: CONNECT,
        id: bookingView.id,
        startDate: bookingView.startDate,
        endDate: bookingView.endDate,
        callService,
        remoteUserName,
        localVideoTrack,
        localAudioTrack,
        chatToken: bookingView.chatToken,
        consultantName: bookingView.consultantName,
        uuid,
      })
    } catch (error) {
      if (error instanceof CallConnectionError) {
        if (
          error.message.includes('possibly because the user denied permission')
          || error.message.includes('Permission denied')
          || error.message.includes('camera is blocked')) {
          Sentry.captureMessage(`Video error when getting the media permission: ${error.message}`, Sentry.Severity.Info)
          dispatch({
            type: BOOKING_ERROR,
            errorMessage: 'Houve algum erro com a permissão para câmera e/ou microfone',
          })
        } else {
          dispatch({
            type: BOOKING_ERROR,
            errorMessage: error.friendlyMessage || 'Desculpe! Estamos com problema. Por favor, tente novamente mais tarde.',
            isProblemWithVideo: error.message === 'Could not start video source'
          })
          Sentry.withScope((scope) => {
            if (bookingView) {
              scope.setExtra('token', bookingView.token)
              scope.setExtra('tokenError', bookingView.tokenError)
            }
            if (error instanceof Error) Sentry.captureException(new Error(`Failure connecting - VideoRoom: ${error.message}`))
          })
        }
      }
    }
  }
}

export function disconnectAction(): Action<AppState, {}> {
  return (dispatch, getState) => {
    const { booking } = getState()
    const { callService } = booking

    try {
      if (callService) callService.disconnect()

      if (booking.remoteHasConnected) {
        dispatch({ type: PROMPT_FEEDBACK, bookingId: booking.id })
      }
      dispatch({ type: DISCONNECT })
    } catch (error) {
      Sentry.captureException(new Error(`Failure disconnecting - VideoRoom: ${error.message}`))
    }
  }
}

export function toggleAudioAction(): AsyncAction<AppState, {}> {
  return async (dispatch, getState) => {
    const { booking } = getState()
    const { callService, localAudioActive } = booking

    if (!callService) return
    try {
      if (localAudioActive) {
        callService.disableAudio()
      } else {
        await callService.enableAudio()
      }

      dispatch({ type: TOGGLE_AUDIO, localAudioActive: !localAudioActive })
    } catch (error) {
      Sentry.captureException(new Error(`Failure switching the audio - VideoRoom: ${error.message}`))
    }
  }
}

export function toggleVideoAction(): AsyncAction<AppState, {}> {
  return async (dispatch, getState) => {
    const { booking } = getState()
    const { callService, localVideoActive } = booking

    if (!callService) return
    try {
      if (localVideoActive) {
        callService.disableVideo()
      } else {
        await callService.enableVideo()
      }

      dispatch({ type: TOGGLE_VIDEO, localVideoActive: !localVideoActive })
    } catch (error) {
      Sentry.captureException(new Error(`Failure switching the video - VideoRoom: ${error.message}`))
    }
  }
}

export function clearTracksAndDisconnectAction(): AsyncAction<AppState, {}> {
  return async (dispatch, getState) => {
    const { booking } = getState()
    await booking.streamMediaListToClear.forEach((ms) => {
      ms.getTracks().forEach((track) => {
        track.stop();
      });
    })
    dispatch(disconnectAction())
  }
}

export const addTracksToClearAction = (mediaStream: MediaStream) => ({
  type: ADD_TRACKS_TO_CLEAR,
  newMediaStream: mediaStream
})

export const toggleChatAction = (): ToggleChatActionType => ({
  type: TOGGLE_CHAT
})

export function promptBrowserErrorAction(): AsyncAction<AppState, {}> {
  return async (dispatch) => {
    dispatch({ type: PROMPT_BROWSER_ERROR })
  }
}

export const suppressBrowserErrorAction = (): BrowserErrorTypes => ({
  type: SUPPRESS_BROWSER_ERROR
})

export const promptPermissionAction = (): PromptPermissionTypes => ({
  type: PROMPT_PERMISSION
})

export const suppressPermissionAction = (): PromptPermissionTypes => ({
  type: SUPPRESS_PROMPT_PERMISSION
})

export const isNotSupportedBrowserLogEventAction = (): NotSupportedBrowserLogEventActionType => ({
  type: NOT_SUPPORTED_BROWSER
})

export const hasBadNetworkQualityLogEventAction = (
  networkQualityLevel: number
): HasBadNetworkQualityLogEventActionType => ({
  type: BAD_NETWORK_QUALITY,
  networkQualityLevel
})
