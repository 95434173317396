export class Navigator {
  static async cameraIsBlocked(): Promise<boolean> {
    if (!navigator || !navigator.permissions) return false

    const result = await navigator.permissions.query({ name: 'camera' })
    return result.state === 'denied'
  }

  static async cameraIsPrompt(): Promise<boolean> {
    if (!navigator || !navigator.permissions) return false

    const result = await navigator.permissions.query({ name: 'camera' })
    return result.state === 'prompt'
  }

  static async cameraIsGranted(): Promise<boolean> {
    if (!navigator || !navigator.permissions) return false

    const result = await navigator.permissions.query({ name: 'camera' })
    return result.state === 'granted'
  }
}
