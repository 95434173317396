import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RegularButton, ResponsiveSplitModal } from 'theia-web-ds'
import { suppressPediatricWelcomeModalAction } from '../../state/pediatricFlow/actions'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { eventTrack } from '../../../eventGenerate'
import './PediatricWelcomeModal.scss'
import { SHOW_PEDIATRIC_REGISTRATION } from '../../state/remoteConfig'
import { CADASTRO_PEDIATRICO } from '../../routes/RoutesConstants'
import { PEDIATRIC_REGISTER } from '../../utils/EventCategories'

interface Props {
showPediatricWelcomeModal: boolean;
suppressPediatricWelcomeModal: () => void;
babyJourney: boolean;
needToCreateBaby: boolean;
isPregnantAndShowModal: boolean;
}

function PediatricWelcomeModal({
  showPediatricWelcomeModal,
  suppressPediatricWelcomeModal,
  babyJourney,
  needToCreateBaby,
  isPregnantAndShowModal
}: Props) {
  const history = useHistory()

  const shouldShowModal = useMemo(() => {
    if (SHOW_PEDIATRIC_REGISTRATION
    && needToCreateBaby
    && babyJourney
    && showPediatricWelcomeModal
    ) {
      return true
    }
    if (SHOW_PEDIATRIC_REGISTRATION
    && needToCreateBaby
    && isPregnantAndShowModal
    ) {
      return true
    }
    return false
  }, [needToCreateBaby, babyJourney, showPediatricWelcomeModal, isPregnantAndShowModal])

  useEffect(() => {
    if (shouldShowModal) {
      eventTrack('visualizou modal jornada pediátrica', { category: PEDIATRIC_REGISTER })
    }
  }, [shouldShowModal])

  function handleClickStartPediatricJourney() {
    suppressPediatricWelcomeModal()
    eventTrack('clicou começar jornada pediátrica', { category: PEDIATRIC_REGISTER })
    history.push(CADASTRO_PEDIATRICO)
  }

  function handleClickRemindMeLater() {
    suppressPediatricWelcomeModal()
    eventTrack('clicou lembre-me depois', { category: PEDIATRIC_REGISTER })
  }

  return shouldShowModal ? (
    <ResponsiveSplitModal
      visible
      ariaLabelledby="Modal de boas-vindas de jornada pediátrica"
      desktopImageUrl="https://theia-all-assets.s3.amazonaws.com/jornada-pedi/welcome_desktop.png"
      mobileImageUrl="https://theia-all-assets.s3.amazonaws.com/jornada-pedi/welcome_mobile.png"
      showDesktopCloseButton
      desktopCloseButtonText="Lembre-me depois"
      onClose={handleClickRemindMeLater}
    >
      <div className="welcome-pediatric-modal-container">
        <div className="welcome-pediatric-modal-body">
          <h2 className="text-textPrimary mt-6 text-headlineSmall text-center">Nascimentos fazem nosso coração bater mais forte!</h2>
          <p className="text-fontDefault text-textSecondary mt-6 text-center">
            Agora você e seu bebê (ou bebês?) começarão
            uma jornada pediátrica, por isso vamos precisar
            de algumas informações para continuar, ok?!
          </p>
          <div className="btn-modal-pediatric-container">
            <RegularButton
              extraClass="mt-12"
              label="Começar jornada"
              variant="primary"
              onClick={handleClickStartPediatricJourney}
            />
            <RegularButton
              variant="secondary-text"
              extraClass="later-pediatric-modal-reminder"
              label="Lembre-me depois"
              onClick={handleClickRemindMeLater}
            />
          </div>
        </div>
      </div>
    </ResponsiveSplitModal>
  ) : null;
}

const mapStateToProps = ({ pediatricFlow, prescriptions }: AppState) => ({
  showPediatricWelcomeModal: pediatricFlow.showPediatricWelcomeModal,
  babyJourney: prescriptions.babyJourney,
  isPregnantAndShowModal: pediatricFlow.isPregnantAndShowModal,
  needToCreateBaby: prescriptions.needToCreateBaby
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  suppressPediatricWelcomeModal: () => {
    dispatch(suppressPediatricWelcomeModalAction())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PediatricWelcomeModal)
