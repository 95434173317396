/* eslint-disable react/prop-types */
import React from 'react'
import { ErrorMessage, NewChipButton } from 'theia-web-ds'
import './ListChipOptionInput.scss'
import { useField } from 'formik'

interface FieldProps {
  name: string;
  label?: string;
  value?: string;
  onChange: (newValue: string) => void;
  isLoading?: boolean;
  options: string[];
}

export default function ListChipOptionInput({
  label,
  name,
  value,
  onChange,
  isLoading,
  options
}: FieldProps) {
  const meta = useField({ name })[1]
  const error = meta.touched && meta.error

  function onChangeValue(newValue: string) {
    onChange(newValue)
  }

  function renderOptionButton(option: string) {
    const realOnChange = () => onChangeValue(option)
    return isLoading ? (
      <div className="loading-chip-option first-loading-chip" key={option} />
    ) : (
      <NewChipButton
        onClick={realOnChange}
        label={option}
        isSelected={value === option}
        key={option}
        width="100%"
      />
    )
  }

  return (
    <div className="input-group list-chip-option-input">
      {isLoading ? (
        <div className="loading-label" />
      ) : (
        <label htmlFor={name} className={error ? 'error' : ''}>{label}</label>
      )}
      <div className="list-chip-option-buttons" id={name}>
        {options.map((option) => renderOptionButton(option))}
      </div>
      {error && (
        <ErrorMessage error={error} />
      )}
    </div>
  )
}
