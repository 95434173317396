import React from 'react'
import '../../Input.scss'
import './CopyInput.scss'
import { RegularButton } from 'theia-web-ds';
import FormInputPlaceholder from '../placeholders/FormInputPlaceholder'

interface FieldProps {
  name: string;
  value: string;
  isLoading?: boolean;
}

export default function CopyInput({
  name,
  value,
  isLoading
}: FieldProps) {
  const onCopy = () => {
    navigator.clipboard.writeText(value)
  }

  if (isLoading) {
    return <FormInputPlaceholder />
  }

  return (
    <div id={name} className="input-group copy-input-container">
      <input
        autoComplete="off"
        className="input"
        type="text"
        name={name}
        value={value}
        readOnly
      />
      <RegularButton
        onClick={onCopy}
        label="Copiar"
        variant="subtle"
        extraClass="copy-input-button"
        width="73px"
        height="36px"
      />
    </div>
  )
}
