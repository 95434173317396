import ApiClient from './ApiClient'
import {
  PrescriptionsService,
  PrescriptionsHighlight,
  Prescriptions,
  UsgPrescription,
  UsgStatus,
  UsgAvailabilityParams,
  HighlightDesiretype,
} from '../../domain/Prescriptions'
import { AvailabilitySlot, ProfessionalListType } from '../../domain/Professional'
import { LocalDate } from '../../utils/LocalDate'
import { LocalAddress } from '../../utils/LocalAddress'

export default class ApiPrescriptionsService implements PrescriptionsService {
  constructor(private apiClient: ApiClient) { }

  async getHighlights(): Promise<PrescriptionsHighlight> {
    const prescriptionsResponse = await this.apiClient.getHighlights()
    return prescriptionsResponse
  }

  async getPrescriptionsInformation(): Promise<Array<Prescriptions>> {
    const prescriptionsInformationResponse = await this.apiClient.getPrescriptionsInformation()
    return prescriptionsInformationResponse
  }

  async getUsgPrescription(): Promise<UsgPrescription> {
    const response = await this.apiClient.getUsgPrescription()
    return response;
  }

  async getUsgData(
    chronosId: string,
    token?: string
  ): Promise<ProfessionalListType> {
    const response = await this.apiClient.getUsgData(chronosId, token)
    return response;
  }

  async getUsgAvailability(
    chronosId: string,
    params: UsgAvailabilityParams,
    token?: string,
  ): Promise<Array<AvailabilitySlot>> {
    const availability = await this.apiClient.getUsgAvailability(
      chronosId, params, token
    )
    const formattedAvailability = availability.map((slot: any) => ({
      ...slot,
      startDate: new LocalDate(slot.startDate),
      endDate: new LocalDate(slot.endDate),
      attendanceAddress: slot.attendanceAddress && new LocalAddress(
        slot.attendanceAddress
      )
    } as AvailabilitySlot))
    return formattedAvailability
  }

  postUsgStatus(status: UsgStatus, usgId: string): Promise<void> {
    return this.apiClient.postUsgStatus(status, usgId)
  }

  sendHighlight(highlight: string): Promise<void> {
    return this.apiClient.sendHighlight(highlight)
  }

  postNotificationConfirmation(type: HighlightDesiretype): Promise<void> {
    return this.apiClient.postNotificationConfirmation(type)
  }
}
