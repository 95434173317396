import React, { useEffect, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Divider,
  Loader,
  RegularButton,
  DateInput
} from 'theia-web-ds'
import Lottie from 'react-lottie'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import NewFormInputList from '../common/molecules/inputs/NewFormInputList'
import { BabyData } from '../../domain/PediatricFlow'
import { clearUpdateChildrenAlertAction, getChildrenDataAction, updateChildrenAction } from '../../state/pediatricFlow/actions'
import Header from '../common/molecules/Header'
import positiveFeedback from '../../animations/positive_feedback.json'
import { eventTrack } from '../../../eventGenerate'
import { setForceUpdateWeeksAction } from '../../state/weekFlow/actions'
import { setForceUpdateCurrentUserAction } from '../../state/authentication/main/actions'
import './PediatricRegistrationForm.scss'
import { PEDIATRIC_REGISTER } from '../../utils/EventCategories'
import { specialCharacterRegex, validationFullNameRegex } from '../../utils/helpers'
import { INICIO } from '../../routes/RoutesConstants'

type ErrorsType = {
  [index: number]: string
}

type BabiesFormType = {
  birthDate: string;
  babies: BabyData[];
}

const newListElement = {
  id: '',
  name: '',
  birthDate: '',
}

function renderPediatricRegistrationForm(
  {
    values,
    setFieldValue,
    setFieldTouched,
    touched,
    handleSubmit,
    initialValues
  }: FormikProps<BabiesFormType>,
  isUpdatingBabies: boolean
) {
  const [babiesError, setBabiesError] = useState<ErrorsType>()
  const [previousValue, setPreviousValue] = useState<BabiesFormType>(initialValues)

  function searchBabyErrors(list: BabyData[]) {
    let errors = {}
    let hasError = false
    list.forEach((baby: BabyData, index: number) => {
      if (!baby.name || baby.name === '') {
        errors = {
          ...errors,
          [index]: 'Informe o nome do bebê para continuar'
        }
        hasError = true
      }
      if (baby.name && !validationFullNameRegex.test(baby.name)) {
        errors = {
          ...errors,
          [index]: 'Informe o nome e sobrenome para continuar'
        }
        hasError = true
      } else if (baby.name && !specialCharacterRegex.test(baby.name)) {
        errors = {
          ...errors,
          [index]: 'Inválido caracter especial'
        }
        hasError = true
      }
    })
    if (hasError) return errors
    return undefined
  }

  function validateBabiesData(list: any[]) {
    setBabiesError(searchBabyErrors(list))
  }

  function handleChangeBabies(newList: BabyData[]) {
    setFieldValue('babies', newList)
    validateBabiesData(newList)
  }

  function handleChangeBirthDate(birthDate?: Date) {
    const newList = values.babies.map((baby) => ({
      ...baby,
      birthDate: birthDate?.getTime()
    }))
    eventTrack('preencheu data de nascimento', { category: PEDIATRIC_REGISTER })
    setFieldValue('babies', newList)
    setFieldValue('birthDate', birthDate?.getTime())
  }

  function onHandleSubmitForm(e: any) {
    e.preventDefault()
    const errors = searchBabyErrors(values.babies)
    if (!errors) {
      handleSubmit()
    } else {
      setBabiesError(errors)
    }
  }

  function onHandleAddTwins() {
    eventTrack('clicou adicionar gêmeo', { category: PEDIATRIC_REGISTER })
  }

  return (
    <form
      onSubmit={onHandleSubmitForm}
      className="form-container"
    >
      <div className="form-content-container">
        <DateInput
          required
          id="birthDate"
          name="birthDate"
          onChange={handleChangeBirthDate}
          blockFutureDates
          placeholder="Selecione a data de nascimento"
          label="Data de nascimento"
          value={values.birthDate}
          hasError={!values.birthDate && touched.birthDate}
          errorText="Informe a data para continuar"
          onBlur={() => setFieldTouched('birthDate', true)}
          extraClassName="mb-4 mt-6"
          disabled={isUpdatingBabies}
        />
        <NewFormInputList
          required
          buttonLabel="Adicionar gêmeo"
          label="Nome completo da criança"
          placeholder="Escreva o nome do bebê"
          ariaLabel="Escreva o nome do bebê"
          list={values.babies}
          errors={babiesError}
          onChange={handleChangeBabies}
          extraButtonOnClick={onHandleAddTwins}
          onBlur={(e: React.FocusEvent<HTMLInputElement>, index: number) => {
            setFieldTouched('babies', true)
            validateBabiesData(values.babies)
            if (previousValue.babies[index]?.name !== e.target.value) {
              eventTrack('preencheu nome da criança', { category: PEDIATRIC_REGISTER })
              setPreviousValue({ ...previousValue, babies: values.babies })
            }
          }}
          newListElement={{
            ...newListElement,
            birthDate: values.birthDate || ''
          }}
          disabled={isUpdatingBabies}
        />
      </div>
      <RegularButton
        label="Salvar"
        type="submit"
        isSubmitting={isUpdatingBabies}
        disabled={!!babiesError || !values.birthDate}
        extraClass="mt-8"
      />
    </form>
  )
}

export interface Props {
  isUpdatingBabies: boolean;
  childrenData?: BabyData[];
  isGettingChildrenData: boolean;
  failureUpdatingChildren: boolean;
  successUpdatingChildren: boolean;
  needToCreateBaby: boolean;
  getChildrenData: () => void;
  updateChildren: (babiesData: BabyData[]) => void;
  clearUpdateChildrenAlert: () => void;
  setForceUpdateCurrentUser: () => void;
  setForceUpdateWeeks: () => void;
}

function PediatricRegistrationForm({
  isUpdatingBabies,
  childrenData,
  isGettingChildrenData,
  failureUpdatingChildren,
  successUpdatingChildren,
  needToCreateBaby,
  getChildrenData,
  updateChildren,
  clearUpdateChildrenAlert,
  setForceUpdateCurrentUser,
  setForceUpdateWeeks,
}: Props) {
  const history = useHistory()
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  const [newBirthdate, setNewBirthdate] = useState<string>()
  const [hasTwins, setHasTwins] = useState<boolean>(false)
  const birthDate = (childrenData && childrenData[0]?.birthDate)
    ? childrenData[0]?.birthDate
    : ''
  const babies = (childrenData && childrenData?.length > 0)
    ? childrenData
    : [newListElement]
  const initialValues: BabiesFormType = {
    birthDate,
    babies
  }
  const lottiePositiveFeedback = {
    loop: false,
    autoplay: true,
    animationData: positiveFeedback,
  }

  useEffect(() => {
    if (!isGettingChildrenData) {
      getChildrenData()
    }
  }, [])

  function formatDate(timestamp: string | number | Date): string {
    const date = new Date(timestamp)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${day}/${month}/${year}`
  }

  useEffect(() => {
    if (successUpdatingChildren) {
      setForceUpdateCurrentUser()
      setForceUpdateWeeks()
      eventTrack('criança cadastrada', {
        category: PEDIATRIC_REGISTER,
        'data de nascimento registrada': newBirthdate,
        'tem gemeos': hasTwins
      })
      if (!needToCreateBaby) {
        history.push(INICIO)
      } else {
        clearUpdateChildrenAlert()
        setShowSuccessScreen(true)
      }
      return
    }
    if (failureUpdatingChildren) {
      eventTrack('erro ao cadastrar criança', { category: PEDIATRIC_REGISTER })
    }
  }, [successUpdatingChildren, failureUpdatingChildren])

  useEffect(() => {
    if (showSuccessScreen) {
      setTimeout(() => {
        history.push(INICIO)
      }, 10000)
    }
  }, [showSuccessScreen])

  function handleSubmitValues(values: BabiesFormType) {
    /** Aqui recriamos o objeto para mandar somente os dados referentes à esse form,
     * evitando que possíveis erros relacionados a outros dados dos bebês interfiram
     * no response do post.
     */
    const newValues = values.babies.map((baby: BabyData) => (
      baby.id ? {
        id: baby.id,
        name: baby.name,
        birthDate: baby.birthDate,
      } : {
        name: baby.name,
        birthDate: baby.birthDate,
      }))
    updateChildren(newValues)
    setNewBirthdate(formatDate(values.birthDate))
    eventTrack('clicou cadastrar criança', { category: PEDIATRIC_REGISTER })
    if (values.babies.length > 1) {
      setHasTwins(true)
    }
  }

  if (showSuccessScreen) {
    return (
      <div className="pediatric-success-registration">
        <div className="animation-up">
          <Lottie
            options={lottiePositiveFeedback}
            isClickToPauseDisabled
            ariaRole="success-animation"
            height={210}
            width={210}
          />
        </div>
        <div className="show-content">
          <h1 className="title">
            Parabéns!
          </h1>
          <p className="subtitle">
            Já está tudo pronto para iniciarem sua jornada,
            esperamos que ela seja a mais perfeita possível!
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="pediatric-registration-form-container">
      <Header
        title="Jornada pediátrica"
        goBack={() => history.goBack()}
      />
      <Divider />
      {isGettingChildrenData ? (
        <div className="flex justify-center pt-20">
          <Loader />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={(values: BabiesFormType) => handleSubmitValues(values)}
        >
          {(props) => renderPediatricRegistrationForm(
            props,
            isUpdatingBabies
          )}
        </Formik>
      )}
    </div>
  )
}

const mapStateToProps = ({
  pediatricFlow, prescriptions
}: AppState) => ({
  isUpdatingBabies: pediatricFlow.isUpdatingBabies,
  childrenData: pediatricFlow.childrenData,
  isGettingChildrenData: pediatricFlow.isGettingChildrenData,
  failureUpdatingChildren: pediatricFlow.failureUpdatingChildren,
  successUpdatingChildren: pediatricFlow.successUpdatingChildren,
  needToCreateBaby: prescriptions.needToCreateBaby,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateChildren: (babiesData: BabyData[]) => {
    dispatch(updateChildrenAction(babiesData))
  },
  getChildrenData: () => { dispatch(getChildrenDataAction()) },
  clearUpdateChildrenAlert: () => { dispatch(clearUpdateChildrenAlertAction()) },
  setForceUpdateCurrentUser: () => { dispatch(setForceUpdateCurrentUserAction()) },
  setForceUpdateWeeks: () => { dispatch(setForceUpdateWeeksAction()) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PediatricRegistrationForm)
