import * as Sentry from '@sentry/browser';

import { Booking, ProfileProvider } from '../../domain/Profile';
import { AsyncAction } from '../utils'

export const REQUEST_FETCH_HISTORIC_BOOKINGS = 'REQUEST_FETCH_HISTORIC_BOOKINGS'
export const SUCCESS_FETCH_HISTORIC_BOOKINGS = 'SUCCESS_FETCH_HISTORIC_BOOKINGS'
export const FAILURE_FETCH_HISTORIC_BOOKINGS = 'FAILURE_FETCH_HISTORIC_BOOKINGS'
export const SHOW_BOOKING_DETAILS_FROM_BOOKING_HISTORY = 'SHOW_BOOKING_DETAILS_FROM_BOOKING_HISTORY'

interface ShowBookingDetailsFromBookingHistoryActionType {
  type: typeof SHOW_BOOKING_DETAILS_FROM_BOOKING_HISTORY;
}

interface RequestHistoricBookingsActionType {
  type: typeof REQUEST_FETCH_HISTORIC_BOOKINGS;
}

interface SuccessFetchHistoricBookingsActionType {
  type: typeof SUCCESS_FETCH_HISTORIC_BOOKINGS;
  bookingsHistory: Array<Booking>;
}

interface FailureFetchHistoricBookingsActionType {
  type: typeof FAILURE_FETCH_HISTORIC_BOOKINGS;
}

export type MyBookingsTypes = RequestHistoricBookingsActionType
| SuccessFetchHistoricBookingsActionType
| FailureFetchHistoricBookingsActionType
| ShowBookingDetailsFromBookingHistoryActionType

export function fetchBookingHistoryAction():
  AsyncAction<{}, { profileProvider: ProfileProvider }> {
  return async (dispatch, _getState, { profileProvider }) => {
    dispatch({ type: REQUEST_FETCH_HISTORIC_BOOKINGS })
    try {
      const bookingsHistory = await profileProvider.getBookingHistory()
      dispatch({ type: SUCCESS_FETCH_HISTORIC_BOOKINGS, bookingsHistory })
    } catch (error) {
      dispatch({ type: FAILURE_FETCH_HISTORIC_BOOKINGS })
      Sentry.captureException(new Error(`Failure to fetch historic bookings - ${error.message}`))
    }
  }
}

export const onShowBookingDetailsFromBookingHistoryAction = ():
ShowBookingDetailsFromBookingHistoryActionType => ({
  type: SHOW_BOOKING_DETAILS_FROM_BOOKING_HISTORY
})
