/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { AvailabilitySlot, ProfessionalListType } from '../../domain/Professional';
import { HighlightDesiretype, Prescriptions, UsgPrescription } from '../../domain/Prescriptions'
import {
  PrescriptionsTypes,
  GET_HIGHLIGHTS,
  SUCCESS_GET_HIGHLIGHTS,
  FAILURE_GET_HIGHLIGHTS,
  GET_PRESCRIPTIONS_INFOS,
  SUCCESS_GET_PRESCRIPTIONS_INFOS,
  FAILURE_GET_PRESCRIPTIONS_INFOS,
  GET_USG_PRESCRIPTION,
  SUCCESS_GET_USG_PRESCRIPTION,
  FAILURE_GET_USG_PRESCRIPTION,
  GET_USG_DATA,
  SUCCESS_GET_USG_DATA,
  FAILURE_GET_USG_DATA,
  GET_USG_AVAILABILITY,
  SUCCESS_GET_USG_AVAILABILITY,
  FAILURE_GET_USG_AVAILABILITY,
  POST_USG_STATUS,
  SUCCESS_POST_USG_STATUS,
  FAILURE_POST_USG_STATUS,
  POST_PRESCRIPTIONS_HIGHLIGHTS,
  SUCCESS_POST_PRESCRIPTIONS_HIGHLIGHTS,
  FAILURE_POST_PRESCRIPTIONS_HIGHLIGHTS,
  POST_NOTIFICATION_CONFIRMATION,
  SUCCESS_POST_NOTIFICATION_CONFIRMATION,
  FAILURE_POST_NOTIFICATION_CONFIRMATION,
  SUPPRESS_USG_MODAL,
} from './actions'

export interface PrescriptionsState {
  maternalDesire: HighlightDesiretype;
  firstTimePrescriptionsFeature: boolean;
  hasUSGRequest: boolean;
  highlightChatMenuItem: boolean;
  highlightHealthMenuItem: boolean;
  highlightPlusMenu: boolean;
  sawUsgModal: boolean;
  usgPrescription?: UsgPrescription;
  isGettingUsgPrescription: boolean;
  usgData?: ProfessionalListType;
  isGettingUsgData: boolean;
  usgAvailability?: Array<AvailabilitySlot>;
  isGettingUsgAvailability: boolean;
  successPostUsgStatus: boolean;
  unseenMedicalRequests: number;
  prescriptions: Array<Prescriptions>;
  isLoading: boolean;
  successGetHighlights: boolean;
  isSuccessPostHighlight: boolean;
  babyJourney: boolean;
  needToCreateBaby: boolean;
}

const initialPrescriptionsState: PrescriptionsState = {
  maternalDesire: 'DONT_SHOW',
  firstTimePrescriptionsFeature: false,
  hasUSGRequest: false,
  highlightChatMenuItem: false,
  highlightHealthMenuItem: false,
  highlightPlusMenu: false,
  sawUsgModal: false,
  usgPrescription: undefined,
  isGettingUsgPrescription: false,
  usgData: undefined,
  isGettingUsgData: false,
  usgAvailability: undefined,
  isGettingUsgAvailability: false,
  successPostUsgStatus: false,
  unseenMedicalRequests: 0,
  prescriptions: [],
  isLoading: false,
  successGetHighlights: false,
  isSuccessPostHighlight: false,
  babyJourney: false,
  needToCreateBaby: false,
}

export function prescriptions(
  state: PrescriptionsState = initialPrescriptionsState,
  action: PrescriptionsTypes
): PrescriptionsState {
  switch (action.type) {
    case GET_HIGHLIGHTS: {
      return {
        ...state,
        isLoading: true,
        isSuccessPostHighlight: false,
        successGetHighlights: false,
      }
    }
    case SUCCESS_GET_HIGHLIGHTS: {
      return {
        ...state,
        isLoading: false,
        ...action.highlights,
        maternalDesire: action.highlights.maternalDesire,
        isSuccessPostHighlight: false,
        successGetHighlights: true
      }
    }
    case FAILURE_GET_HIGHLIGHTS: {
      return {
        ...state,
        isLoading: false,
        isSuccessPostHighlight: false,
        successGetHighlights: false
      }
    }
    case GET_PRESCRIPTIONS_INFOS: {
      return {
        ...state,
        isLoading: true
      }
    }
    case FAILURE_GET_PRESCRIPTIONS_INFOS: {
      return {
        ...state,
        isLoading: false
      }
    }
    case SUCCESS_GET_PRESCRIPTIONS_INFOS: {
      const pendingMedicalRequests = action.prescriptions.filter(
        (prescription) => prescription.prescriptionDownloaded === false
      )
      return {
        ...state,
        isLoading: false,
        unseenMedicalRequests: pendingMedicalRequests.length || 0,
        prescriptions: action.prescriptions
      }
    }
    case GET_USG_PRESCRIPTION: {
      return {
        ...state,
        isGettingUsgPrescription: true,
      }
    }
    case SUCCESS_GET_USG_PRESCRIPTION: {
      return {
        ...state,
        isGettingUsgPrescription: false,
        usgPrescription: action.usgPrescription,
      }
    }
    case FAILURE_GET_USG_PRESCRIPTION: {
      return {
        ...state,
        isGettingUsgPrescription: false,
        usgPrescription: undefined
      }
    }
    case GET_USG_DATA: {
      return {
        ...state,
        isGettingUsgData: true,
      }
    }
    case SUCCESS_GET_USG_DATA: {
      return {
        ...state,
        isGettingUsgData: false,
        usgData: action.usgData,
      }
    }
    case FAILURE_GET_USG_DATA: {
      return {
        ...state,
        isGettingUsgData: false,
        usgData: undefined
      }
    }
    case GET_USG_AVAILABILITY: {
      return {
        ...state,
        isGettingUsgAvailability: true,
      }
    }
    case SUCCESS_GET_USG_AVAILABILITY: {
      return {
        ...state,
        isGettingUsgAvailability: false,
        usgAvailability: action.usgAvailability,
      }
    }
    case FAILURE_GET_USG_AVAILABILITY: {
      return {
        ...state,
        isGettingUsgAvailability: false,
        usgAvailability: undefined
      }
    }
    case POST_USG_STATUS: {
      return {
        ...state,
        isLoading: true
      }
    }
    case SUCCESS_POST_USG_STATUS: {
      return {
        ...state,
        isLoading: false,
        successPostUsgStatus: true,
      }
    }
    case FAILURE_POST_USG_STATUS: {
      return {
        ...state,
        isLoading: false,
        successPostUsgStatus: false,
      }
    }
    case POST_PRESCRIPTIONS_HIGHLIGHTS: {
      return {
        ...state,
        isSuccessPostHighlight: false
      }
    }
    case SUCCESS_POST_PRESCRIPTIONS_HIGHLIGHTS: {
      return {
        ...state,
        isSuccessPostHighlight: true
      }
    }
    case FAILURE_POST_PRESCRIPTIONS_HIGHLIGHTS: {
      return {
        ...state,
        isSuccessPostHighlight: false
      }
    }
    case POST_NOTIFICATION_CONFIRMATION: {
      return {
        ...state,
      }
    }
    case SUCCESS_POST_NOTIFICATION_CONFIRMATION: {
      return {
        ...state,
      }
    }
    case FAILURE_POST_NOTIFICATION_CONFIRMATION: {
      return {
        ...state,
      }
    }
    case SUPPRESS_USG_MODAL: {
      return {
        ...state,
        sawUsgModal: true
      }
    }
    default: return state
  }
}
