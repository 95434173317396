/*
  eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["el"] }]
*/
import {
  connect as connectOnTwilio,
  ConnectOptions,
  Room,
  LocalParticipant,
  LocalVideoTrack,
  LocalAudioTrack,
  LocalVideoTrackPublication,
  LocalAudioTrackPublication,
} from 'twilio-video'
import {
  CallService,
  CallServiceFactory,
  BookingVideoTrack,
  BookingAudioTrack,
  remoteHandlers,
  localHandlers,
} from '../../domain/Call'
import { first } from '../../utils/helpers'
import { CallConnectionError } from '../../domain/errors/CallConnectionError'
import Helpers from '../../utils/Browsers'

class TwilioBookingVideoTrack implements BookingVideoTrack {
  constructor(private twilioTrack: LocalVideoTrack) { }

  attach(element: HTMLAudioElement): HTMLVideoElement {
    return this.twilioTrack.attach(element)
  }

  detach() {
    const elements = this.twilioTrack.detach()
    elements.forEach((el) => {
      el.remove();
      el.srcObject = null;
    });
  }
}

class TwilioBookingAudioTrack implements BookingAudioTrack {
  constructor(private twilioTrack: LocalAudioTrack) { }

  attach(element: HTMLAudioElement): HTMLAudioElement {
    return this.twilioTrack.attach(element)
  }

  detach() {
    const elements = this.twilioTrack.detach()
    elements.forEach((el) => {
      el.remove();
      el.srcObject = null;
    });
  }
}

export class TwilioCallService implements CallService {
  localVideoTrack: LocalVideoTrackPublication

  localAudioTrack: LocalAudioTrackPublication

  private localParticipant: LocalParticipant

  constructor(private room: Room) {
    this.localParticipant = room.localParticipant
    this.localVideoTrack = first(this.localParticipant.videoTracks)
    this.localAudioTrack = first(this.localParticipant.audioTracks)
  }

  connect() {
    return {
      localVideoTrack: new TwilioBookingVideoTrack(this.localVideoTrack.track),
      localAudioTrack: new TwilioBookingAudioTrack(this.localAudioTrack.track),
    }
  }

  configureRemote(handlers: remoteHandlers) {
    const currentParticipant = first(this.room.participants)

    if (currentParticipant) {
      this.connectParticipant({ ...handlers, participant: currentParticipant })
    }
    this.room.on('disconnected', () => {
      const countActiveBookingTabs = parseInt(window.localStorage.getItem('countActiveBookingTabs') || '0', 10)
      if (countActiveBookingTabs > 1) {
        handlers.forceDisconnectWithDuplicateRoomLocal()
      } else {
        handlers.disconnectWithDefaultProblemLocal()
      }
    })

    this.room.on('participantDisconnected', handlers.disconnectRemote)
    this.room.on('participantConnected', (participant: LocalParticipant) => {
      this.connectParticipant({ ...handlers, participant })
    })
    this.room.on('participantReconnecting', handlers.reconnectingRemote);
    this.room.on('participantReconnected', handlers.reconnectedRemote);
  }

  configureLocal(handlers: localHandlers) {
    this.room.on('reconnecting', handlers.localReconnecting);
    this.room.on('reconnected', handlers.localReconnected);
    this.localParticipant.on('networkQualityLevelChanged', () => {
      if (this.localParticipant.networkQualityLevel !== null) {
        const { networkQualityLevel } = this.localParticipant
        const { networkQualityStats } = this.localParticipant
        handlers.localNetworkQualityChange(networkQualityLevel, networkQualityStats)
      }
    })
  }

  disableVideo() {
    this.localVideoTrack.unpublish()
  }

  async enableVideo() {
    const track = await this.localParticipant.publishTrack(this.localVideoTrack.track)
    this.localVideoTrack = track as LocalVideoTrackPublication
  }

  disableAudio() {
    this.localAudioTrack.unpublish()
  }

  async enableAudio() {
    const track = await this.localParticipant.publishTrack(this.localAudioTrack.track)
    this.localAudioTrack = track as LocalAudioTrackPublication
  }

  disconnect() {
    this.room.disconnect()
  }

  private connectParticipant({
    connectRemote,
    updateDownlinkState,
    videoConnect,
    videoDisconnect,
    audioConnect,
    audioDisconnect,
    participant
  }: remoteHandlers & { participant: LocalParticipant }) {
    connectRemote()
    participant.on('trackSubscribed', (track: LocalVideoTrack | LocalAudioTrack) => {
      if (track.kind === 'video') {
        videoConnect(new TwilioBookingVideoTrack(track))

        track.on('switchedOff', () => {
          updateDownlinkState(true)
        })

        track.on('switchedOn', () => {
          updateDownlinkState(false)
        })
      } else if (track.kind === 'audio') {
        audioConnect(new TwilioBookingAudioTrack(track))
      }
    })

    participant.on('trackUnsubscribed', (track: LocalVideoTrack | LocalAudioTrack) => {
      if (track.kind === 'video') {
        videoDisconnect()
      } else if (track.kind === 'audio') {
        audioDisconnect()
      }
    })
  }
}

export class TwilioCallServiceFactory implements CallServiceFactory {
  private _CONNECT_OPTIONS: ConnectOptions = {
    region: 'br1',
    bandwidthProfile: {
      video: {
        mode: 'grid',
        maxSubscriptionBitrate: Helpers.isMobile ? 2500000 : 0,
        dominantSpeakerPriority: 'standard',
        clientTrackSwitchOffControl: 'auto',
        contentPreferencesMode: 'auto',
        trackSwitchOffMode: 'predicted'
      }
    },
    maxAudioBitrate: 16000,
    networkQuality: {
      local: 1,
      remote: 2
    }
  }

  async build(token: string): Promise<TwilioCallService> {
    let room: Room
    try {
      room = await connectOnTwilio(token, this._CONNECT_OPTIONS)
    } catch (error) {
      throw new CallConnectionError(error.message, 'Desculpe! Estamos com problema. Por favor, tente novamente mais tarde.')
    }
    room.localParticipant.setNetworkQualityConfiguration({
      local: 2,
      remote: 1
    })

    return new TwilioCallService(room)
  }
}
