import React from 'react'
import './HeaderModal.scss'

interface Props {
  className?: string;
  children: React.ReactNode;
}

export default function HeaderModal({ className, children }: Props) {
  return <h1 className={`header-modal ${className}`}>{children}</h1>
}
