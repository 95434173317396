import React from 'react'
import Bottombar from './common/navigation/Bottombar'
import SideBar from './common/navigation/Sidebar'
import './AppMainContainer.scss'

interface Props {
  children?: React.ReactNode;
  showMenu: boolean;
}

function AppMainContainer({
  children,
  showMenu
}: Props) {
  return showMenu ? (
    <div id="grid-container" className="grid-container">
      <div className="item-a">
        <SideBar route={window.location.pathname} />
      </div>
      <Bottombar route={window.location.pathname} />
      <div id="item-b" className="item-b has-menu">
        {children}
      </div>
    </div>
  ) : (
    <div id="grid-container" className="grid-container">
      <div id="item-b" className="item-b no-menu">
        {children}
      </div>
    </div>
  )
}

export default AppMainContainer
