import React from 'react'

import './FormInputPlaceholder.scss'

export default function FormInputPlaceholder() {
  const loadingLabelInput = (
    <div className="loading-label-input" data-testid="loading-label-input" />
  )

  const loadingInput = (
    <div className="loading-input" data-testid="loading-input" />
  )

  return (
    <div className="loading-input-group" data-testid="form-input-placeholder">
      {loadingLabelInput}
      {loadingInput}
    </div>
  )
}
