import { AvailabilityByDay } from '../../domain/Availabilities'
import { TheiaError } from '../../domain/errors/TheiaError'
import { LocalAddress } from '../../utils/LocalAddress'
import { mergeAvailabilitySlots } from '../../utils/helpers'
import {
  AvailabilitiesTypes,
  FAILURE_GET_AVAILABILITIES,
  GET_AVAILABILITIES,
  SET_ATTENDANCE_ADDRESS,
  SET_PRODUCT_ID,
  SUCCESS_GET_AVAILABILITIES,
  RESET_AVAILABILITIES_STATE
} from './actions'

export interface AvailabilitiesState {
  availabilitiesByDay?: AvailabilityByDay[];
  isGettingAvailabilities: boolean;
  hasNextAvailabilities: boolean;
  productId?: string;
  attendanceAddress?: LocalAddress;
  exactDayMessage?: string;
  errorGettingAvailabilities?: TheiaError;
  isLoadingMoreSlots: boolean;
}

const initialAvailabilitiesState: AvailabilitiesState = {
  availabilitiesByDay: undefined,
  isGettingAvailabilities: false,
  hasNextAvailabilities: false,
  productId: undefined,
  attendanceAddress: undefined,
  exactDayMessage: undefined,
  errorGettingAvailabilities: undefined,
  isLoadingMoreSlots: false,
}

export function availabilities(
  state = initialAvailabilitiesState,
  action: AvailabilitiesTypes
): AvailabilitiesState {
  switch (action.type) {
    case GET_AVAILABILITIES: {
      return {
        ...state,
        isGettingAvailabilities: true,
        isLoadingMoreSlots: action.offset > 0,
        errorGettingAvailabilities: undefined,
        exactDayMessage: undefined
      }
    }
    case SUCCESS_GET_AVAILABILITIES: {
      const mergedAvailabilities = mergeAvailabilitySlots(
        state.availabilitiesByDay || [],
        action.response.availabilities
      )
      return {
        ...state,
        isGettingAvailabilities: false,
        attendanceAddress: action.response.attendanceAddress
          ? new LocalAddress(action.response.attendanceAddress) : undefined,
        availabilitiesByDay: action.offset > 0
          ? mergedAvailabilities : action.response.availabilities,
        productId: action.response.productId,
        hasNextAvailabilities: !!action.response.hasNextAvailabilities,
        isLoadingMoreSlots: false,
        exactDayMessage: action.response.exactDayMessage
      }
    }
    case FAILURE_GET_AVAILABILITIES: {
      return {
        ...state,
        isGettingAvailabilities: false,
        attendanceAddress: undefined,
        availabilitiesByDay: undefined,
        productId: undefined,
        hasNextAvailabilities: false,
        isLoadingMoreSlots: false,
        exactDayMessage: undefined,
        errorGettingAvailabilities: action.error
      }
    }
    case SET_PRODUCT_ID: {
      return {
        ...state,
        productId: action.productId
      }
    }
    case SET_ATTENDANCE_ADDRESS: {
      return {
        ...state,
        attendanceAddress: action.address
      }
    }
    case RESET_AVAILABILITIES_STATE: {
      return {
        ...state,
        ...initialAvailabilitiesState
      }
    }
    default: return state
  }
}
