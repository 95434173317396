import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RegularButton, Loader, CardPost } from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import {
  fetchAllPostsAction,
  fetchPostAction,
  setNumberOfPostsToRenderAction,
  selectContentCategoryAction
} from '../../state/content/actions'
import { Post, defaultCategory } from '../../domain/Content'
import { notUndefined } from '../../utils/helpers'
import { eventPage } from '../../../eventGenerate'
import SelectOptions from '../common/SelectOptions'
import { hasTagOrSlug, normalizeStringToSlug } from '../weekFlow/helpers'
import './Content.scss'
import { CONTENT } from '../../routes/RoutesConstants'

interface Props {
  isFetchingAllPosts: boolean;
  posts: Array<Post>;
  contentCategory: string;
  numberOfVisiblePosts: number;
  selectContentCategory: (category: string) => void;
  fetchAllPosts: () => void;
  fetchPost: (id: string) => void;
  setNumberOfPostsToRender: (numberOfPostsToRender: number) => void;
}

function AllPosts({
  isFetchingAllPosts,
  posts,
  contentCategory,
  numberOfVisiblePosts,
  selectContentCategory,
  fetchAllPosts,
  fetchPost,
  setNumberOfPostsToRender
}: Props) {
  const history = useHistory()

  useEffect(() => {
    fetchAllPosts()
    eventPage('conteudo', 'Conteúdo')
  }, [])

  function filterAllCategories() {
    const categories = posts.map((post) => (post.primary_tag.name))
    if (categories.length > 0) {
      const orderedCategories = categories.sort()
      // eslint-disable-next-line prefer-arrow-callback
      const unique = orderedCategories.filter(function filterCategories(elem, index, self) {
        return index === self.indexOf(elem)
      })
      unique.unshift(defaultCategory)
      return unique
    }
    return categories
  }

  const postsToShow = useMemo(() => {
    if (posts.length > 0 && contentCategory !== defaultCategory) {
      const slug = normalizeStringToSlug(contentCategory)
      const postsFilteredBytag = posts.filter(
        (post) => hasTagOrSlug(post.tags, slug)
        || normalizeStringToSlug(post.primary_tag.name) === slug
        || post.primary_tag.slug === slug
      )
      const featuredPosts = postsFilteredBytag.filter((p) => p.featured)
      let newPosts;
      if (featuredPosts.length > 0) {
        newPosts = featuredPosts.concat(postsFilteredBytag.filter((p) => !p.featured))
      } else {
        newPosts = postsFilteredBytag
      }
      return newPosts
    }
    return posts.slice(0, numberOfVisiblePosts)
  }, [contentCategory, posts, numberOfVisiblePosts])

  function onChangeCategory(e: React.ChangeEvent<HTMLSelectElement>) {
    selectContentCategory(e.target.value)
  }

  function getPostById(post: Post) {
    fetchPost(post.id)
    history.push(`${CONTENT}/${post.slug}/${post.id}`)
  }

  function handleClickSeeMorePosts() {
    setNumberOfPostsToRender(numberOfVisiblePosts + 10)
  }

  if (isFetchingAllPosts) {
    return (
      <div className="flex justify-center h-screen w-full bg-bgCanvas">
        <div className="flex items-center h-full">
          <Loader />
        </div>
      </div>
    )
  }
  return (
    <div className="content-list flex flex-1 min-h-0 relative">
      <div className="mx-auto w-full lg:max-w-xl">
        <div className="flex flex-col h-full rounded pt-8">
          <div className="flex-1 min-h-full m-auto w-full lg:pl-6 lg:pr-6 pl-4 pr-4">
            <h1 className="text-headlineSmall text-textPrimary" style={{ maxWidth: '80%' }}>
              Conteúdo
            </h1>
            <div className="mb-24 lg:mb-8 mt-6 relative flex flex-col">
              <SelectOptions
                value={contentCategory}
                id="content-category-filter"
                options={filterAllCategories()}
                onChange={onChangeCategory}
              />
              <ul>
                {postsToShow.map((post) => (
                  <li key={post.id} className="post-list">
                    <button
                      onClick={() => getPostById(post)}
                      type="button"
                    >
                      <CardPost
                        featureImage={post.feature_image}
                        category={post.primary_tag.name}
                        title={post.meta_title}
                        description={post.meta_description}
                      />
                    </button>
                  </li>
                ))}
              </ul>
              {contentCategory === defaultCategory && (
                <RegularButton
                  label="Mostrar mais +"
                  variant="text"
                  onClick={handleClickSeeMorePosts}
                  extraClass="mt-4"
                  aria-label="Mostrar mais"
                  aria-labelledby="Mostrar mais"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = ({ content }: AppState) => {
  const allPosts = content.allPosts.map((post: any) => post)
    .filter(notUndefined)
  return {
    posts: allPosts,
    isFetchingAllPosts: content.isFetchingAll,
    contentCategory: content.contentCategory,
    numberOfVisiblePosts: content.numberOfVisiblePosts
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchAllPosts: () => { dispatch(fetchAllPostsAction()) },
  fetchPost: (id: string) => { dispatch(fetchPostAction(id)) },
  selectContentCategory: (category: string) => {
    dispatch(selectContentCategoryAction(category))
  },
  setNumberOfPostsToRender: (numberOfPostsToRender: number) => {
    dispatch(setNumberOfPostsToRenderAction(numberOfPostsToRender))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllPosts)
