import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'react-toastify/dist/ReactToastify.min.css'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import initMainStore from './apps/main/store'
import initProfessionalStore from './apps/professional/store'
import './index.scss'

import { SimpleHttpClient } from './services/Http'
import ApiClient from './services/api/ApiClient'
import { TwilioChatServiceFactory } from './services/twilio/TwilioChat'

import { TwilioChatVideoServiceFactory } from './services/twilio/TwilioChatVideo'
import { TwilioCallServiceFactory } from './services/twilio/TwilioCall'
import ApiUserProvider from './services/api/ApiUserProvider'
import ApiSpecialistProvider from './services/api/ApiSpecialistProvider'
import ApiProfessionalProvider from './services/api/ApiProfessionalProvider'
import ApiBookingService from './services/api/ApiBookingService'
import ApiBookingViewService from './services/api/ApiBookingViewService'
import ApiProfileService from './services/api/ApiProfileService'
import { ProfessionalApiClient } from './services/api/ApiVideo'
import { loginAction, handleRefreshTokenAction } from './state/authentication/main/actions'
import { authenticateProfessionalAction } from './state/authentication/professional/actions'
import MainApp from './apps/main'
import ProfessionalApp from './apps/professional'
import {
  isProductionEnv,
  parseJwt,
  getFlutterClient,
  isAccessTokenValid,
  isRefreshTokenValid,
  isStagingEnv
} from './utils/helpers'
import ApiPlatformProvider from './services/api/ApiPlatformProvider'
import ApiWeekSuggestionsService from './services/api/ApiWeekSuggestionsService'
import ApiOnboardingService from './services/api/ApiOnboardingService'
import ApiHealthplanService from './services/api/ApiHealthplanService'
import ApiPrescriptionsService from './services/api/ApiPrescriptionsService'
import { rudderInitializeStg, rudderInitializeProd } from '../eventGenerate'
import ApiGuidedJourneyService from './services/api/ApiGuidedJourneyService'
import ApiPediatricFlowService from './services/api/ApiPediatricFlowService'
import ApiConsultantService from './services/api/ApiConsultantService'
import ApiScheduleFlowService from './services/api/ApiScheduleFlowService'
import ApiAvailabilitiesService from './services/api/ApiAvailabilitiesService'
import ApiTermsService from './services/api/ApiTermsService'
import { BOOKINGS } from './routes/RoutesConstants'
import ApiRescheduleService from './services/api/ApiRescheduleService'

if (isProductionEnv()) {
  Sentry.init({
    dsn: 'https://5d695fe2cfe149ee90c5a1e4a4ea5b16@sentry.io/1826395',
    environment: 'production'
  })
  rudderInitializeProd()
}

if (isStagingEnv()) {
  rudderInitializeStg()
}

async function renderMainApp() {
  const rootElement = document.getElementById('root')
  const httpClient = new SimpleHttpClient()
  const apiClient = new ApiClient(httpClient)
  const userProvider = new ApiUserProvider(apiClient)
  const specialistProvider = new ApiSpecialistProvider(apiClient)
  const professionalProvider = new ApiProfessionalProvider(apiClient)
  const chatServiceFactory = new TwilioChatServiceFactory(apiClient)
  const bookingService = new ApiBookingService(apiClient)
  const bookingViewService = new ApiBookingViewService(apiClient)
  const callServiceFactory = new TwilioCallServiceFactory()
  const profileProvider = new ApiProfileService(apiClient)
  const platformProvider = new ApiPlatformProvider(apiClient)
  const chatVideoServiceFactory = new TwilioChatVideoServiceFactory()
  const weekSuggestionsService = new ApiWeekSuggestionsService(apiClient)
  const onboardingService = new ApiOnboardingService(apiClient)
  const healthplanService = new ApiHealthplanService(apiClient)
  const prescriptionsService = new ApiPrescriptionsService(apiClient)
  const consultantService = new ApiConsultantService(apiClient)
  const guidedJourneyService = new ApiGuidedJourneyService(apiClient)
  const pediatricFlowService = new ApiPediatricFlowService(apiClient)
  const scheduleFlowService = new ApiScheduleFlowService(apiClient)
  const availabilitiesService = new ApiAvailabilitiesService(apiClient)
  const termsProvider = new ApiTermsService(apiClient)
  const rescheduleService = new ApiRescheduleService(apiClient)

  const store = initMainStore({
    httpClient,
    userProvider,
    chatServiceFactory,
    callServiceFactory,
    bookingService,
    bookingViewService,
    professionalProvider,
    profileProvider,
    specialistProvider,
    platformProvider,
    chatVideoServiceFactory,
    weekSuggestionsService,
    onboardingService,
    healthplanService,
    prescriptionsService,
    consultantService,
    guidedJourneyService,
    pediatricFlowService,
    scheduleFlowService,
    availabilitiesService,
    termsProvider,
    rescheduleService
  })

  const isFlutterClient = getFlutterClient()
  if (!isAccessTokenValid() && isRefreshTokenValid() && !isFlutterClient) {
    await store.dispatch(handleRefreshTokenAction())
  }
  if (isAccessTokenValid()) await store.dispatch(loginAction())

  ReactDOM.render(<MainApp store={store} />, rootElement)
}

async function renderProfessionalApp(professionalToken: string) {
  const rootElement = document.getElementById('root')
  const httpClient = new SimpleHttpClient()
  const professionalApiClient = new ProfessionalApiClient(httpClient, professionalToken)
  const bookingViewService = new ApiBookingViewService(professionalApiClient)
  const callServiceFactory = new TwilioCallServiceFactory()
  const chatVideoServiceFactory = new TwilioChatVideoServiceFactory()

  const store = initProfessionalStore({
    callServiceFactory,
    bookingViewService,
    chatVideoServiceFactory
  })

  const infoProfessional = parseJwt(professionalToken)
  await store.dispatch(authenticateProfessionalAction(infoProfessional.iss))

  ReactDOM.render(<ProfessionalApp store={store} />, rootElement)
}

const url = new URLSearchParams(window.location.search)
const isBookingRoomPath = window.location.pathname.includes(BOOKINGS)
const professionalToken = url.get('token')
// TODO: Split into multiple entrypoints.
if (isBookingRoomPath && professionalToken) {
  renderProfessionalApp(professionalToken)
} else {
  renderMainApp()
}
