import React, {
  HTMLAttributes,
  forwardRef,
  useRef,
  useState
} from 'react'
import { FontIcon, Props as FontIconProps } from '../FontIcon/FontIcon'
import './InvisibleInput.scss'
import {
  textDisable,
  textPrimary,
  textSecondary,
  error as errorColor
} from '../../colors'
import { UseCombinedRefs } from '../../utils/helpers'
import { Alert } from '../Alert/Alert'

interface Props extends HTMLAttributes<HTMLInputElement> {
  size?: 'small' | 'medium' | 'large';
  variant?: 'outline' | 'no-outline';
  iconBefore?: FontIconProps;
  iconAfter?: FontIconProps;
  supportingText?: string;
  required?: boolean;
  id?: string;
  error?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  width?: number | string;
  extraClass?: string;
  autoComplete?: 'none' | 'off' | 'password' | 'username' | 'email';
  textInfo?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const InvisibleInput: React.FC<Props> = forwardRef(({
  size,
  variant,
  iconBefore,
  iconAfter,
  supportingText,
  required,
  id,
  error,
  name,
  placeholder,
  value,
  disabled,
  readOnly,
  width,
  extraClass,
  autoComplete,
  textInfo,
  onBlur,
  onFocus,
  ...inputProps
}: Props, forwardedRef) => {
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [isInputHover, setIsInputHover] = useState(false)
  const [showTextInfo, setShowTextInfo] = useState(false)
  const innerRef = useRef<HTMLInputElement>(null)
  const combinedRef = UseCombinedRefs(forwardedRef, innerRef)

  function handleInputFocus(e: React.FocusEvent<HTMLInputElement>) {
    setIsInputFocused(true)
    if (onFocus) onFocus(e)
  }

  function handleInputBlur(e: React.FocusEvent<HTMLInputElement>) {
    setIsInputFocused(false)
    if (onBlur) onBlur(e)
  }

  function handleMouseEnter() {
    setIsInputHover(true)
  }

  function handleMouseLeave() {
    setIsInputHover(false)
  }

  function handleShowTextInfo() {
    if (textInfo && (disabled || readOnly)) setShowTextInfo(true)
  }

  function handleHideTextInfo() {
    setShowTextInfo(false)
  }

  const iconColor = (defaultColor?: string) => {
    let color = defaultColor || textSecondary
    if (isInputHover || isInputFocused) color = textPrimary
    if (error) color = errorColor
    if (disabled) color = textDisable
    return color
  }

  if (readOnly && (!value && !placeholder)) return null

  return (
    <div
      className={`
        invisible-input--component
        ${extraClass}
      `}
      style={{ width: width || '100%' }}
      onMouseEnter={handleShowTextInfo}
      onMouseLeave={handleHideTextInfo}
    >
      {readOnly ? (
        <div
          className={`
            invisible-input-readonly-text
            readonly-text-${size || 'medium'}
            ${disabled ? 'disabled' : ''}
            ${placeholder ? 'placeholder' : ''}
          `}
          data-testid="readonly-text"
        >
          {value || placeholder}
          {showTextInfo && (
            <Alert
              extraClass="alert-invisible-input"
              message={textInfo}
              indicator="left-top"
            />
          )}
        </div>
      ) : (
        <>
          <div className="invisible-input--input-wrapper">
            {iconAfter && (
              <FontIcon
                color={iconColor(iconAfter.color)}
                extraClass="absolute left-2"
                {...iconAfter}
              />
            )}
            <input
              data-testid="invisible-input"
              required={required}
              id={id}
              className={`
                invisible-input
                ${error ? 'error' : ''}
                ${variant || 'no-outline'}
                invisible-input-${size || 'medium'}
                ${iconAfter ? 'pl-7' : 'pl-2'}
                ${iconBefore ? 'pr-7' : 'pr-2'}
              `}
              type="text"
              name={name}
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              ref={combinedRef}
              autoComplete={autoComplete || 'off'}
              {...inputProps}
            />
            {iconBefore && (
              <FontIcon
                color={iconColor(iconBefore.color)}
                extraClass="absolute right-2"
                {...iconBefore}
              />
            )}
          </div>
          {showTextInfo && (
            <Alert
              extraClass={`alert-invisible-input disabled-${size || 'medium'}`}
              message={textInfo}
              indicator="left-top"
            />
          )}
          <p className={`invisible-input--supportingText ${disabled ? 'disabled' : ''} ${error ? 'error' : ''}`}>
            {supportingText || error}
          </p>
        </>
      )}
    </div>
  )
})
