import React from 'react'
import { useField } from 'formik'
import './NewCheckbox.scss'

interface Props {
  children: React.ReactNode;
  formInputOnChange?: () => void;
  name: string;
  value: boolean;
  id?: string;
  onKeyPress: (newValue: boolean) => void;
  substituteOnChange?: () => void;
  isLoading?: boolean;
  status?: 'success' | 'selected';
}

export default function NewCheckbox({
  children,
  formInputOnChange,
  name,
  value,
  id,
  onKeyPress,
  isLoading,
  substituteOnChange,
  status = 'selected',
}: Props) {
  const stringValue = value ? 'true' : 'false'
  const [field] = useField({ name, type: 'checkbox', value: stringValue })
  const { onBlur, onChange } = field
  const newOnChange = (e: React.ChangeEvent) => {
    if (formInputOnChange) formInputOnChange()
    if (substituteOnChange) {
      substituteOnChange()
    } else {
      onChange(e)
    }
  }
  const handleKeyDown = (e: any) => {
    e.preventDefault()
    if (e.key === 'Enter') {
      if (formInputOnChange) formInputOnChange()
      onKeyPress(!value)
    }
  }
  if (isLoading) {
    return (
      <div className="new-checkbox-loading-container">
        <div className="loading-checkbox" />
        <div className="loading-label" />
      </div>
    )
  }
  return (
    <div className={`new-checkbox-container new-checkbox-status--${status}`}>
      <input
        type="checkbox"
        id={id || name}
        className="new-checkbox"
        name={name}
        checked={value}
        onBlur={onBlur}
        onChange={newOnChange}
        onKeyPress={handleKeyDown}
        value={stringValue}
      />
      <label htmlFor={id || name}>
        {children}
      </label>
    </div>
  )
}
