import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Divider } from 'theia-web-ds'
import Payment from './Payment'
import Header from '../../common/molecules/Header'
import { AppState } from '../../../apps/main/store'
import { PROFILE } from '../../../routes/RoutesConstants'

interface Props {
  hasCardAdded?: boolean;
}

function EditPaymentInfoProfile({
  hasCardAdded
}: Props) {
  const history = useHistory()

  return (
    <div className="w-full flex flex-col">
      <Header
        title={hasCardAdded ? 'Alterar cartão de crédito' : 'Adicionar cartão de crédito'}
        goBack={() => history.push(PROFILE)}
      />
      <Divider />
      <div className="address-form flex flex-1 relative w-full">
        <div className="mx-auto w-full h-full">
          <div className="lg:max-w-xl mx-auto w-full">
            <Payment />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  authentication
}: AppState) => ({
  hasCardAdded: authentication.currentUser?.plan.paymentMethodAdded,
})

export default connect(
  mapStateToProps,
  null,
)(EditPaymentInfoProfile)
