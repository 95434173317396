/* eslint-disable no-prototype-builtins */
import { HealthInsuranceCompany, HealthInsurancePlanData } from '../../domain/Healthplan';
import {
  BrazilState,
  CityOfState,
} from '../../domain/Onboarding'
import {
  REQUEST_BRAZIL_STATES,
  SUCCESS_BRAZIL_STATES,
  FAILURE_BRAZIL_STATES,
  OnboardingTypes,
  REQUEST_CITIES_OF_STATE,
  SUCCESS_CITIES_OF_STATE,
  FAILURE_CITIES_OF_STATE,
  UPDATE_ADDRESS_IN_PROFILE_REQUESTED,
  SUCCESS_UPDATE_ADDRESS_IN_PROFILE,
  FAILURE_UPDATE_ADDRESS_IN_PROFILE,
  CONFIRM_HEALTH_INSURANCE_PLAN,
  SELECT_HEALTH_INSURANCE,
  REQUEST_HEALTH_INSURANCE_COMPANIES,
  SUCCESS_HEALTH_INSURANCE_COMPANIES,
  FAILURE_HEALTH_INSURANCE_COMPANIES,
  POST_HEALTH_INSURANCE,
  SUCCESS_POST_HEALTH_INSURANCE,
  FAILURE_POST_HEALTH_INSURANCE,
  REQUEST_HEALTH_INSURANCE_DATA,
  SUCCESS_HEALTH_INSURANCE_DATA,
  FAILURE_HEALTH_INSURANCE_DATA,
  SUCCESS_OPEN_MODAL,
  SUCCESS_CLOSE_MODAL,
  DO_NOT_HAVE_HEALTH_INSURANCE_PLAN,
  GO_BACK_TO_PROFILE_HEALTH_PLAN_UPDATE
} from './actions'

export interface OnboardingState {
  isLoading: boolean;
  isSuccess: boolean;
  isSuccessFetchCompanies: boolean;
  brazilStates: Array<BrazilState>;
  citiesOfState: Array<CityOfState>;
  benefits: any;
  insurancePlanCovered: boolean | null;
  healthInsurancePlanData: HealthInsurancePlanData;
  address?: any;
  byCompanyName: {
    [companyName: string]: HealthInsuranceCompany;
  };
  isModal: boolean;
  isSuccessDeleted: boolean;
  isSuccessPostFromModal: boolean;
}

const initialOnboardingFlow: OnboardingState = {
  isLoading: false,
  isSuccess: false,
  isSuccessFetchCompanies: false,
  brazilStates: [],
  citiesOfState: [],
  benefits: [{
  }],
  insurancePlanCovered: null,
  healthInsurancePlanData: {
    hasHealthInsurancePlan: null,
    healthInsurancePlan: null,
    benefitsTitle: null,
    benefitsSubtitle: null,
  },
  address: undefined,
  byCompanyName: {},
  isModal: false,
  isSuccessDeleted: false,
  isSuccessPostFromModal: false
}

export function onboarding(
  state = initialOnboardingFlow,
  action: OnboardingTypes
): OnboardingState {
  switch (action.type) {
    case REQUEST_BRAZIL_STATES: {
      return {
        ...state,
        isLoading: true,
        citiesOfState: []
      }
    }
    case SUCCESS_BRAZIL_STATES: {
      return {
        ...state,
        isLoading: false,
        brazilStates: action.brazilStates
      }
    }
    case FAILURE_BRAZIL_STATES: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case REQUEST_CITIES_OF_STATE: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case SUCCESS_CITIES_OF_STATE: {
      return {
        ...state,
        isLoading: false,
        citiesOfState: action.citiesOfState
      }
    }
    case FAILURE_CITIES_OF_STATE: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPDATE_ADDRESS_IN_PROFILE_REQUESTED: {
      return {
        ...state,
      }
    }
    case SUCCESS_UPDATE_ADDRESS_IN_PROFILE: {
      return {
        ...state,
        address: action.address
      }
    }
    case FAILURE_UPDATE_ADDRESS_IN_PROFILE: {
      return {
        ...state,
      }
    }
    case CONFIRM_HEALTH_INSURANCE_PLAN: {
      return {
        ...state,
        healthInsurancePlanData: {
          hasHealthInsurancePlan: true,
          healthInsurancePlan: {
            company: '',
            memberId: '',
            planName: '',
            journeyType: '',
            companyId: '',
            planId: ''
          }
        },
      }
    }
    case SELECT_HEALTH_INSURANCE: {
      return {
        ...state,
        healthInsurancePlanData: {
          hasHealthInsurancePlan: true,
          healthInsurancePlan: {
            company: action.company,
            memberId: '',
            planName: '',
            journeyType: '',
            companyId: '',
            planId: ''
          }
        },
      }
    }
    case REQUEST_HEALTH_INSURANCE_COMPANIES: {
      return {
        ...state,
        isLoading: true,
        isSuccessFetchCompanies: false,
      }
    }
    case SUCCESS_HEALTH_INSURANCE_COMPANIES: {
      const allCompanies = action.companies.reduce(
        (byCompanyName: any, company) => {
          const newObj: any = byCompanyName
          if (!newObj.hasOwnProperty(company.company)) {
            newObj[company.company] = { company }
          } else {
            newObj[company.company] = {
              ...newObj[company.company],
              company
            }
          }
          return newObj
        }, {}
      )
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isSuccessFetchCompanies: true,
        byCompanyName: allCompanies
      }
    }
    case FAILURE_HEALTH_INSURANCE_COMPANIES: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case POST_HEALTH_INSURANCE: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case SUCCESS_POST_HEALTH_INSURANCE: {
      let isSuccessDeleted: boolean
      if (action.payload.hasHealthInsurancePlan === false) {
        isSuccessDeleted = true
      } else {
        isSuccessDeleted = false
      }
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isSuccessDeleted,
        insurancePlanCovered: action.insurancePlanCovered,
        benefits: action.benefits,
        healthInsurancePlanData: {
          ...action.payload,
          benefitsTitle: action.benefitsTitle,
          benefitsSubtitle: action.benefitsSubtitle,
        },
        isSuccessPostFromModal: state.isModal,
      }
    }
    case FAILURE_POST_HEALTH_INSURANCE: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case DO_NOT_HAVE_HEALTH_INSURANCE_PLAN: {
      const healthInsuranceInfo = {
        insurancePlanCovered: false,
        healthInsurancePlanData: {
          hasHealthInsurancePlan: false,
          healthInsurancePlan: null
        }
      }
      localStorage.setItem('hasHealthInsurancePlan', JSON.stringify(healthInsuranceInfo))
      return {
        ...state,
        insurancePlanCovered: false,
        healthInsurancePlanData: {
          hasHealthInsurancePlan: false,
          healthInsurancePlan: null
        },
        isSuccess: false
      }
    }
    case REQUEST_HEALTH_INSURANCE_DATA: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case SUCCESS_HEALTH_INSURANCE_DATA: {
      return {
        ...state,
        isLoading: false,
        healthInsurancePlanData: action.healthInsurancePlanData
      }
    }
    case FAILURE_HEALTH_INSURANCE_DATA: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case SUCCESS_OPEN_MODAL: {
      return {
        ...state,
        isModal: true,
      }
    }
    case SUCCESS_CLOSE_MODAL: {
      return {
        ...state,
        isModal: false,
        isSuccess: false,
        isSuccessPostFromModal: false
      }
    }
    case GO_BACK_TO_PROFILE_HEALTH_PLAN_UPDATE: {
      return {
        ...state,
        isSuccess: false,
        isSuccessDeleted: false
      }
    }
    default: return state
  }
}
