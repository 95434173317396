import React, { ChangeEvent } from 'react'
import {
  RegularButton,
  Divider,
  FontIcon,
  ToolTip,
  IconButton,
  NewestCheckbox
} from 'theia-web-ds'
import { ConsultationInfos, QuarterConsultation } from '../../domain/GuidedJourney';
import { bgBox, textPrimary, textSecondary } from '../../color'
import './ConsultationDetails.scss'

interface Props {
  isChecking?: boolean;
  consultationInfos: ConsultationInfos;
  activeCard: QuarterConsultation;
  onCloseAction?: () => void;
  onClickCheckbox: (checked: boolean, item: QuarterConsultation) => void;
  onClickScheduleAction: (category: string) => void;
}

function ConsultationDetails({
  isChecking,
  consultationInfos,
  activeCard,
  onCloseAction,
  onClickCheckbox,
  onClickScheduleAction,
}: Props) {
  const {
    attendanceAdress,
    objective,
    obs,
    quarter,
    recommendedWeek,
    title,
    type,
    chipList
  } = consultationInfos;

  function CheckIcon() {
    if (type === 'Consulta presencial') {
      return 'icon-2UsersLight'
    } if (type === 'Consulta online') {
      return 'icon-VideoLight'
    }
    return 'icon-BuildingLight'
  }

  return (
    <div className="consultation-details-container">
      <div className="consultation-details-header">
        <IconButton
          aria-label="Voltar"
          variant="text"
          width="44px"
          height="44px"
          iconType="icon-ArrowLeft2Light"
          iconColor={textPrimary}
          iconSize="20px"
          onClick={onCloseAction}
        />
        <p>Voltar</p>
      </div>
      <div className="consultation-details-body">
        <div
          className="consultation-details-image h-56"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.87)0%, rgba(0, 0, 0, 0)30%), 
            url(${activeCard.imgUrl})`,
            backgroundPosition: 'center 10%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="consultation-image-header">
            <IconButton
              iconType="icon-ArrowLeft2Light"
              width="40px"
              height="40px"
              extraClass="icomoon-consultation"
              variant="subtle"
              iconColor={textPrimary}
              onClick={onCloseAction}
            />
            <div className="checkbox-container">
              <p className="mr-3 text-white">
                Já realizei essa consulta
              </p>
              <NewestCheckbox
                name={activeCard.title}
                value={activeCard.id}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onClickCheckbox(e.target.checked, activeCard)
                }}
                hasDivider={false}
                defaultChecked={activeCard.done}
                isLoading={isChecking}
                status="success"
                componentExtraClass="checkbox-style"
              />
            </div>
          </div>
        </div>
        <div className="consultation-details-info">
          <div className="info-text-container py-6 px-4 md:px-5 xl:px-20">
            <div className="flex justify-between align-top">
              <div className="flex flex-col gap-2 font-normal mb-6">
                <p className="text-sm text-textSecondary">{quarter} </p>
                <h2 className="text-xl text-textPrimary w-64">{title}</h2>
              </div>
              <div>
                {chipList && chipList.length > 0 && chipList.map((chip, index) => (
                  <ToolTip
                    width="fit-content"
                    label={chip.text}
                    backgroundColor={chip.color}
                    textColor={bgBox}
                    borderRadius="73px"
                    fontSize="12px"
                    key={index + chip.text + chip.color}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-col text-fontDefault">
              <FontIcon
                iconType="icon-ChatHelpLight"
                fontSize="22px"
                color={textSecondary}
              />
              <h3 className="text-textPrimary font-medium mt-2 mb-1">Pra quê</h3>
              <p className="text-textSecondary font-normal">{objective}</p>
            </div>

            <Divider className="my-4" />

            <div className="flex flex-col text-fontDefault">
              <FontIcon
                iconType="icon-CalendarLight"
                fontSize="22px"
                color={textSecondary}
              />
              <h3 className="text-textPrimary font-medium mt-2 mb-1">Recomendamos agendar</h3>
              <h3 className="text-primary font-medium">{recommendedWeek}</h3>
              <p className="text-textSecondary font-normal text-textSmaller mt-2">{obs}</p>
            </div>

            <Divider className="my-4" />

            <div className="flex flex-col text-fontDefault">
              <FontIcon
                iconType={CheckIcon()}
                fontSize="22px"
                color={textSecondary}
              />
              <h3 className="text-textPrimary font-medium  mt-2 mb-1">{type}</h3>
              <p className="text-textSecondary font-normal">{attendanceAdress}</p>
            </div>
          </div>
          <div className="info-button-container">
            <RegularButton
              variant="primary"
              label="Agendar Consulta"
              onClick={() => onClickScheduleAction(activeCard.category)}
              extraClass="md:max-w-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultationDetails;
