import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { RegularButton, Divider } from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import Header from '../common/molecules/Header'
import { fetchBookingHistoryAction } from '../../state/myBookingsFlow/actions'
import NavItem from '../common/navigation/NavItem'
import { fetchAllBookings, fetchProfileAction } from '../../state/profile/actions'
import AllBookingsView from './AllBookingsView'
import BookingHistoryView from './BookingHistoryView'
import { eventPage } from '../../../eventGenerate'
import { clearForceUpdateCurrentUserAction, updateCurrentUserInfosAction } from '../../state/authentication/main/actions'
import './MyBookingsView.scss'
import { AGENDAR } from '../../routes/RoutesConstants'
import { SourceTypes } from '../../domain/ScheduleFlow'
import { setScheduleSourceAction } from '../../state/scheduleFlow/actions'

interface Props {
  forceUpdateCurrentUser: boolean;
  isFetchingUser: boolean;
  fetchProfile: () => void;
  fetchAllBookingsAction: () => void;
  isFetchingAllBookings: boolean;
  isFetchingBookingHistory: boolean;
  fetchBookingHistory: () => void;
  forceUpdateHistory: boolean;
  hasPendingPrescription?: boolean;
  updateCurrentUserInfos: () => void;
  clearForceUpdateCurrentUser: () => void;
  setScheduleSource: (source: SourceTypes) => void;
}

type tabType = 'agendamentos' | 'historico'

interface ParamsType {
  initialTab?: tabType;
  isFromReminder?: boolean;
}

function MyBookingsView({
  forceUpdateCurrentUser,
  isFetchingUser,
  fetchProfile,
  fetchAllBookingsAction,
  isFetchingAllBookings,
  fetchBookingHistory,
  isFetchingBookingHistory,
  forceUpdateHistory,
  hasPendingPrescription,
  updateCurrentUserInfos,
  clearForceUpdateCurrentUser,
  setScheduleSource
}: Props) {
  const history = useHistory()
  const params = useLocation().state as ParamsType
  const initialTab = params?.initialTab || undefined
  const [tabSelected, setTabSelected] = useState<tabType>('agendamentos')

  useEffect(() => {
    eventPage('agenda', 'Agendamentos')
    if (params?.isFromReminder === true) {
      setTabSelected('historico')
    }
    if (initialTab) {
      setTabSelected(initialTab)
    }
    if (!isFetchingAllBookings) {
      fetchAllBookingsAction()
    }
  }, [])

  useMemo(() => {
    if (!isFetchingBookingHistory && tabSelected === 'historico') {
      fetchBookingHistory()
    }
  }, [tabSelected])

  useEffect(() => {
    if (forceUpdateCurrentUser && !isFetchingUser) {
      fetchProfile()
      updateCurrentUserInfos()
      clearForceUpdateCurrentUser()
    }
  }, [forceUpdateCurrentUser])

  useEffect(() => {
    if (forceUpdateHistory && !isFetchingBookingHistory && tabSelected === 'historico') {
      fetchBookingHistory()
    }
  }, [forceUpdateHistory])

  const onSelectBookings = () => setTabSelected('agendamentos')
  const onSelectHistoric = () => setTabSelected('historico')

  const goToSchedule = () => {
    setScheduleSource(SourceTypes.BOOKING)
    history.push(AGENDAR)
  }

  const goToAllBookings = () => {
    onSelectBookings()
  }

  const goToBookingsHistory = () => {
    onSelectHistoric()
  }

  return (
    <div className="my-bookings-view-container w-full pb-6">
      <Header
        title="Consultas"
        showLogo
      >
        <RegularButton
          onClick={goToSchedule}
          label="+ Agendar"
          variant="subtle"
        />
      </Header>
      <div className="my-bookings-tab">
        <ul className="flex flex-1 justify-center" role="menu" aria-label="Menu de agendamentos">
          <NavItem
            handleClick={onSelectBookings}
            isActive={tabSelected === 'agendamentos'}
            text="Agendamentos"
            variant="tab"
          />
          <NavItem
            handleClick={onSelectHistoric}
            isActive={tabSelected === 'historico'}
            text="Histórico"
            variant="tab"
            hasNotification={hasPendingPrescription}
          />
        </ul>
      </div>
      <Divider />
      <div className="my-bookings-body">
        {tabSelected === 'agendamentos' && (
          <AllBookingsView goToBookingsHistory={goToBookingsHistory} />
        )}
        {tabSelected === 'historico' && (
          <BookingHistoryView goToAllBookings={goToAllBookings} />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({
  profile, myBookingsFlow, authentication
}: AppState) => ({
  forceUpdateCurrentUser: authentication.forceUpdateCurrentUser,
  isFetchingUser: authentication.isFetchingUser,
  isFetchingAllBookings: profile.isFetchingAll,
  isFetchingBookingHistory: myBookingsFlow.isFetchingBookingHistory,
  hasPendingPrescription: myBookingsFlow.hasPendingNotification,
  forceUpdateHistory: myBookingsFlow.forceUpdateHistory
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  fetchProfile: fetchProfileAction,
  fetchAllBookingsAction: fetchAllBookings,
  fetchBookingHistory: fetchBookingHistoryAction,
  updateCurrentUserInfos: updateCurrentUserInfosAction,
  clearForceUpdateCurrentUser: clearForceUpdateCurrentUserAction,
  setScheduleSource: setScheduleSourceAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyBookingsView)
