import {
  PROMPT_RESCHEDULE,
  RescheduleTypes,
  FAILURE_RESCHEDULE_BOOKING,
  RESCHEDULE_BOOKING,
  SUCCESS_RESCHEDULE_BOOKING,
  CLEAR_RESCHEDULE_FLOW,
  SHOW_RESCHEDULE_MODAL,
  CLOSE_RESCHEDULE_ERROR_MODAL,
} from './actions'
import { ScheduleType } from '../../domain/Booking'
import { ErrorTypes, PROMPT_DEFAULT_ERROR } from '../defaultError/actions'
import { TheiaError } from '../../domain/errors/TheiaError'
import { SpecialistCategories } from '../../domain/Specialist'
import { LocalDate } from '../../domain/utils/Date'
import { LocalAddress } from '../../utils/LocalAddress'

export type NewCardData = {
  brand: string;
  lastDigits: string;
}

export interface RescheduleState {
  error?: TheiaError;
  isLoading: boolean;
  scheduleType: ScheduleType;
  oldBookingId?: string;
  showErrorReschedule: boolean;
  showRescheduleModal: boolean;
  isExam: boolean;
  categoryName?: SpecialistCategories;
  professionalName?: string;
  startDate?: LocalDate;
  availabilityId?: string;
  attendanceAddress?: LocalAddress;
}

export const initialRescheduleState: RescheduleState = {
  error: undefined,
  isLoading: false,
  scheduleType: 'InPerson',
  oldBookingId: undefined,
  showErrorReschedule: false,
  showRescheduleModal: false,
  isExam: false,
  categoryName: undefined,
  professionalName: undefined,
  startDate: undefined,
  availabilityId: undefined,
  attendanceAddress: undefined
}

export function reschedule(
  state = initialRescheduleState,
  action: RescheduleTypes | ErrorTypes
): RescheduleState {
  switch (action.type) {
    case PROMPT_RESCHEDULE:
      return {
        ...state,
        isLoading: false,
        categoryName: action.rescheduleInfos.categoryName,
        professionalName: action.rescheduleInfos.professionalName,
        startDate: action.rescheduleInfos.startDate,
        availabilityId: action.rescheduleInfos.availabilityId,
        attendanceAddress: action.rescheduleInfos.attendanceAddress,
        scheduleType: action.rescheduleInfos.scheduleType,
        oldBookingId: action.rescheduleInfos.oldBookingId,
        isExam: action.rescheduleInfos.isExam
      }
    case CLEAR_RESCHEDULE_FLOW:
      return {
        ...state,
        ...initialRescheduleState,
      }
    case RESCHEDULE_BOOKING: {
      return {
        ...state,
        isLoading: true,
        error: undefined
      }
    }
    case FAILURE_RESCHEDULE_BOOKING: {
      return {
        ...state,
        showErrorReschedule: true,
        isLoading: false,
        error: action.error,
      }
    }
    case SUCCESS_RESCHEDULE_BOOKING: {
      return {
        ...state,
        ...initialRescheduleState,
      }
    }
    case CLOSE_RESCHEDULE_ERROR_MODAL:
      return {
        ...state,
        showErrorReschedule: false,
        error: undefined
      }
    case SHOW_RESCHEDULE_MODAL: {
      return {
        ...state,
        showRescheduleModal: true
      }
    }
    case PROMPT_DEFAULT_ERROR:
      return {
        ...state,
        isLoading: false,
      }
    default: return state
  }
}
