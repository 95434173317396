import * as Sentry from '@sentry/browser';

import { AsyncAppAction } from '../utils'
import { Reason } from '../../domain/Booking'
import { PROMPT_DEFAULT_ERROR } from '../defaultError/actions'
import { promptErrorAction } from '../errorWithFriendlyMessage/actions';
import { setForceUpdateWeeksAction } from '../weekFlow/actions';

export const PROMPT_SCHEDULE_CANCELLATION = 'PROMPT_SCHEDULE_CANCELLATION'
export const SUPPRESS_SCHEDULE_CANCELLATION = 'SUPPRESS_SCHEDULE_CANCELLATION'
export const FETCH_SCHEDULE_CANCELLATION_REASONS = 'FETCH_SCHEDULE_CANCELLATION_REASONS'
export const SUCCESS_FETCH_SCHEDULE_CANCELLATION_REASONS = 'SUCCESS_FETCH_SCHEDULE_CANCELLATION_REASONS'
export const FAILURE_FETCH_SCHEDULE_CANCELLATION_REASONS = 'FAILURE_FETCH_SCHEDULE_CANCELLATION_REASONS'
export const TOGGLE_SCHEDULE_CANCELLATION_REASON = 'TOGGLE_SCHEDULE_CANCELLATION_REASON'
export const SUCCESS_BOOKING_CANCEL = 'SUCCESS_BOOKING_CANCEL'
export const CONFIRM_BOOKING_CANCELLATION = 'CONFIRM_BOOKING_CANCELLATION'
export const BOOKING_CANCEL_REQUESTED = 'BOOKING_CANCEL_REQUESTED'
export const BOOKING_CANCEL_STEP = 'BOOKING_CANCEL_STEP'
export const FAILURE_BOOKING_CANCEL = 'FAILURE_BOOKING_CANCEL'

interface PromptCancellation {
  type: typeof PROMPT_SCHEDULE_CANCELLATION;
  bookingId: string;
  isExam: boolean;
}

export interface SuppressCancellation {
  type: typeof SUPPRESS_SCHEDULE_CANCELLATION;
  step: any;
}

interface FetchReasons {
  type: typeof FETCH_SCHEDULE_CANCELLATION_REASONS;
}

interface SuccessFetchReasons {
  type: typeof SUCCESS_FETCH_SCHEDULE_CANCELLATION_REASONS;
  reasons: Array<Reason>;
}

interface FailureFetchReasons {
  type: typeof FAILURE_FETCH_SCHEDULE_CANCELLATION_REASONS;
}

export interface ToggleReason {
  type: typeof TOGGLE_SCHEDULE_CANCELLATION_REASON;
  reason: Reason;
}

interface CancellationConfirmation {
  type: typeof CONFIRM_BOOKING_CANCELLATION;
  otherMotive: string;
}

export interface SuccessBookingCancel {
  type: typeof SUCCESS_BOOKING_CANCEL;
  bookingId: string;
}

export interface BookingCancelRequested {
  type: typeof BOOKING_CANCEL_REQUESTED;
}

export interface BookingCancelStep {
  type: typeof BOOKING_CANCEL_STEP;
  step: any;
}

export interface FailureBookingCancel {
  type: typeof FAILURE_BOOKING_CANCEL;
  error: {
    message?: string;
    title?: string;
  };
}

export type ScheduleCancellationTypes =
  PromptCancellation |
  SuppressCancellation |
  FetchReasons |
  SuccessFetchReasons |
  FailureFetchReasons |
  SuccessBookingCancel |
  ToggleReason |
  CancellationConfirmation |
  BookingCancelRequested |
  BookingCancelStep |
  FailureBookingCancel

export function promptScheduleCancellationAction(
  bookingId: string,
  isExam?: boolean
): AsyncAppAction {
  return async (dispatch, _getState, { bookingService }) => {
    dispatch({ type: PROMPT_SCHEDULE_CANCELLATION, bookingId, isExam })
    try {
      dispatch({ type: FETCH_SCHEDULE_CANCELLATION_REASONS })
      const reasons = await bookingService.getBookingsCancellationReasons()
      dispatch({ type: SUCCESS_FETCH_SCHEDULE_CANCELLATION_REASONS, reasons })
    } catch (error) {
      Sentry.captureException(new Error(`Failure getting bookings cancellation reasons - ${error.message}`))
      dispatch({ type: SUPPRESS_SCHEDULE_CANCELLATION })
      dispatch({ type: PROMPT_DEFAULT_ERROR })
    }
  }
}

export const suppressScheduleCancellationAction = (step: any):
ScheduleCancellationTypes => ({
  type: SUPPRESS_SCHEDULE_CANCELLATION,
  step
})

export const getStepScheduleCancellationAction = (step: any):
ScheduleCancellationTypes => ({
  type: BOOKING_CANCEL_STEP,
  step
})

export function confirmBookingCancellationAction(otherMotive: string): ScheduleCancellationTypes {
  return {
    type: CONFIRM_BOOKING_CANCELLATION,
    otherMotive
  }
}

export function cancelBookingAction(): AsyncAppAction {
  return async (dispatch, getState, { bookingService }) => {
    const { scheduleCancellation } = getState()
    const { bookingId, reasons, otherMotive } = scheduleCancellation

    if (!bookingId) return

    dispatch({ type: BOOKING_CANCEL_REQUESTED })

    const selectedReasonsIds = reasons
      .filter((reason) => reason.selected)
      .map((reason) => reason.id)
    try {
      await bookingService.cancelBooking(bookingId, selectedReasonsIds, otherMotive)
      dispatch({ type: SUCCESS_BOOKING_CANCEL, bookingId })
      dispatch(setForceUpdateWeeksAction())
    } catch (error) {
      Sentry.captureException(new Error(`Failure cancelling a booking - ${error.message}`))
      if (error.message === 'default') {
        dispatch({ type: PROMPT_DEFAULT_ERROR })
        dispatch({ type: SUPPRESS_SCHEDULE_CANCELLATION })
        return
      }
      const cancelError = {
        friendlyMessageTitle: 'Ops, aconteceu um problema',
        friendlyMessage: error.friendlyMessage
      }
      dispatch({
        type: FAILURE_BOOKING_CANCEL,
        error: {
          message: cancelError.friendlyMessage,
          title: cancelError.friendlyMessageTitle
        }
      })
      dispatch(promptErrorAction(cancelError))
    }
  }
}

export function toggleCancellationReasonAction(reason: Reason): ScheduleCancellationTypes {
  return {
    type: TOGGLE_SCHEDULE_CANCELLATION_REASON,
    reason
  }
}
