import { Formik, FormikProps } from 'formik'
import React, { useEffect, useState } from 'react'
import {
  Divider, RegularButton, FormInput, DateInput
} from 'theia-web-ds'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { bgDisable, success } from '../../color'
import Emoji from '../common/atoms/Emoji'
import Header from '../common/molecules/Header'
import StepperBar from '../common/StepperBar'
import BannerUserDataInfo from './BannerUserDataInfo'
import {
  GenderOptions,
  PersonalInfosForm
} from '../../domain/Profile'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { fetchProfileAction, onUpdatePersonalInfosInProfileAction } from '../../state/profile/actions'
import ButtonPlaceholder from '../common/molecules/placeholders/ButtonPlaceholder'
import FormInputPlaceholder from '../common/molecules/placeholders/FormInputPlaceholder'
import ListChipOptionInput from '../common/molecules/inputs/ListChipOptionInput'
import { renderFormAddressInput } from '../common/molecules/inputs/FormAddressInput'
import { AddressByCep, LocalAddressType } from '../../utils/LocalAddress'
import { getAddressInfoAction } from '../../state/addressInfo/actions'
import { clearForceUpdateCurrentUserAction, updateCurrentUserInfosAction } from '../../state/authentication/main/actions'
import './HealthGestationalAndPersonalInfos.scss'
import './PersonalInfos.scss'
import { CONSULTAS, DADOS_DE_SAUDE, DADOS_GESTACIONAIS } from '../../routes/RoutesConstants'

function renderPersonalInfosForm(
  {
    values,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    handleChange,
    errors,
    touched,
    handleBlur
  }: FormikProps<PersonalInfosForm>,
  isLoading: boolean,
  isSubmitting: boolean,
  getAddressInfo: (cep: string) => void,
  isFetchindAddressData: boolean,
  addressFetchedFromCep?: AddressByCep
) {
  function onChangeBirthDate(date: Date) {
    if (!date) return
    setFieldValue('birthDate', date.getTime());
    setFieldTouched('birthDate', true);
  }

  function onChangeGender(newValue: string) {
    setFieldValue('gender', newValue);
    setFieldTouched('gender', true);
    if (newValue !== GenderOptions.CUSTOM && values.genderOther !== '') {
      setFieldValue('genderOther', '');
      setFieldTouched('genderOther', true);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="user-profile-infos-form" noValidate>
      <div className="individual-container">
        {isLoading ? (
          <FormInputPlaceholder />
        ) : (
          <DateInput
            required
            id="birthDate"
            name="birthDate"
            onChange={onChangeBirthDate}
            blockFutureDates
            placeholder="Selecione uma data"
            label="Data de nascimento"
            value={values.birthDate}
          />
        )}
      </div>
      <div className="individual-container">
        <ListChipOptionInput
          label="Gênero"
          name="gender"
          value={values.gender}
          onChange={onChangeGender}
          isLoading={isLoading}
          options={Object.values(GenderOptions).filter((option) => option !== GenderOptions.NONE)}
        />
        {values.gender === GenderOptions.CUSTOM && (
          <FormInput
            value={values.genderOther}
            onChange={handleChange}
            errorMessage={touched.genderOther ? errors.genderOther : ''}
            id="genderOther"
            onBlur={handleBlur}
            type="text"
            name="genderOther"
            placeholder="Digite seu gênero"
          />
        )}
      </div>
      <div className="individual-container">
        {renderFormAddressInput(
          { setFieldValue, setFieldTouched } as FormikProps<LocalAddressType>,
          getAddressInfo,
          isLoading,
          isFetchindAddressData,
          'addressName',
          addressFetchedFromCep
        )}
      </div>
      <BannerUserDataInfo extraClassName="banner-small-screen" isLoading={isLoading} />
      <div className="button-container">
        {isLoading ? (
          <ButtonPlaceholder />
        ) : (
          <RegularButton
            onClick={() => handleSubmit()}
            label="Finalizar cadastro"
            type="submit"
            isSubmitting={isSubmitting}
          />
        )}
      </div>
    </form>
  )
}

const initialValues = {
  birthDate: '',
  gender: '',
  genderOther: '',
  postalCode: '',
  city: '',
  stateAbbreviation: '',
  addressName: '',
  number: '',
  complement: '',
}

interface Props {
  isFetchingProfile: boolean;
  fetchProfile: () => void;
  getAddressInfo: (cep: string) => void;
  addressFetchedFromCep?: AddressByCep;
  isFetchindAddressData: boolean;
  birthDate?: string;
  gender?: string;
  genderOther?: string;
  postalCode?: string;
  city?: string;
  stateAbbreviation?: string;
  addressName?: string;
  number?: string;
  complement?: string;
  name?: string;
  email?: string;
  phone?: string;
  onUpdatePersonalInfosInProfile: (
    name: string,
    email: string,
    phone: string,
    personalInfos: PersonalInfosForm
  ) => void;
  forceUpdateCurrentUser: boolean;
  isFetchingUser: boolean;
  isUpdatingProfile: boolean;
  hasProfile: boolean;
  updateCurrentUserInfos: () => void;
  clearForceUpdateCurrentUser: () => void;
}

export interface PersonalInfosLocationState {
  has3Steps: boolean;
}

function PersonalInfos({
  isFetchingProfile,
  fetchProfile,
  getAddressInfo,
  addressFetchedFromCep,
  isFetchindAddressData,
  name,
  email,
  phone,
  onUpdatePersonalInfosInProfile,
  forceUpdateCurrentUser,
  isFetchingUser,
  isUpdatingProfile,
  birthDate,
  gender,
  genderOther,
  postalCode,
  city,
  stateAbbreviation,
  addressName,
  number,
  complement,
  updateCurrentUserInfos,
  clearForceUpdateCurrentUser,
  hasProfile
}: Props) {
  const history = useHistory()
  const [updateInfos, setUpdateInfos] = useState(false)
  const isLoading = !hasProfile || isFetchingProfile
  const location = useLocation<undefined | PersonalInfosLocationState>()

  useEffect(() => {
    if (!hasProfile && !isFetchingProfile) {
      fetchProfile()
    }
  }, [])

  useEffect(() => {
    if (forceUpdateCurrentUser && updateInfos && !isFetchingUser) {
      updateCurrentUserInfos()
      clearForceUpdateCurrentUser()
      setUpdateInfos(false)
      history.push(CONSULTAS)
    }
  }, [forceUpdateCurrentUser])

  function goBack() {
    if (location.state?.has3Steps) {
      history.push(DADOS_GESTACIONAIS)
    } else {
      history.push(DADOS_DE_SAUDE)
    }
  }

  initialValues.birthDate = birthDate
    || initialValues.birthDate
  initialValues.gender = gender
    || initialValues.gender
  initialValues.genderOther = genderOther
    || initialValues.genderOther
  initialValues.postalCode = postalCode
    || initialValues.postalCode
  initialValues.city = city
    || initialValues.city
  initialValues.stateAbbreviation = stateAbbreviation
    || initialValues.stateAbbreviation
  initialValues.addressName = addressName
    || initialValues.addressName
  initialValues.number = number
    || initialValues.number
  initialValues.complement = complement
    || initialValues.complement

  return (
    <div className="user-profile-infos-container personal-infos-container">
      <div className="header-container">
        <Header
          title="Dados pessoais"
          isLoading={isLoading}
          goBack={goBack}
        />
        <Divider />
      </div>
      <div className="user-profile-infos-body-outer-container">
        <div className="user-profile-infos-body-inner-container">
          <StepperBar
            totalSteps={location.state?.has3Steps ? 3 : 2}
            currentStep={location.state?.has3Steps ? 3 : 2}
            showInfosBottom
            showNumbers={false}
            currentStepColor={success}
            totalStepsColor={bgDisable}
            isLoading={isLoading}
            lineHeight="8px"
            lineRadius="40px"
            explanation={(
              <div className="stepper-explanation-container">
                <Emoji symbol="💝" />
                <p className="stepper-explanation-text">Pra gente te mandar mimos</p>
              </div>
            )}
          />
          <Divider />
        </div>
        <div className="user-profile-infos-form-and-explanation-container">
          <div className="user-profile-infos-form-container">
            <Formik
              initialValues={initialValues}
              onSubmit={(_value: PersonalInfosForm) => {
                if (name && email && phone) {
                  setUpdateInfos(true)
                  onUpdatePersonalInfosInProfile(name, email, phone, _value)
                }
              }}
            >
              {(props) => renderPersonalInfosForm(
                props,
                isLoading,
                isUpdatingProfile,
                getAddressInfo,
                isFetchindAddressData,
                addressFetchedFromCep
              )}
            </Formik>
          </div>
          <BannerUserDataInfo extraClassName="banner-large-screen" isLoading={isLoading} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  profile, addressInfo, authentication
}: AppState) => ({
  hasProfile: !!profile.profile,
  isFetchingProfile: profile.isFetchingProfile,
  addressFetchedFromCep: addressInfo.addressFetchedFromCep,
  isFetchindAddressData: addressInfo.isFetchindAddressData,
  name: authentication.currentUser?.name,
  email: authentication.currentUser?.email,
  phone: authentication.currentUser?.phone,
  forceUpdateCurrentUser: authentication.forceUpdateCurrentUser,
  isFetchingUser: authentication.isFetchingUser,
  isUpdatingProfile: profile.isUpdatingProfile,
  birthDate: profile.profile?.birthDate,
  gender: profile.profile?.gender,
  genderOther: profile.profile?.genderOther,
  postalCode: profile.profile?.postalCode,
  city: profile.profile?.city,
  stateAbbreviation: profile.profile?.stateAbbreviation,
  addressName: profile.profile?.addressName,
  number: profile.profile?.number,
  complement: profile.profile?.complement
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchProfile: () => { dispatch(fetchProfileAction()) },
  getAddressInfo: (cep: string) => { dispatch(getAddressInfoAction(cep)) },
  onUpdatePersonalInfosInProfile: (
    name: string,
    email: string,
    phone: string,
    personalInfos: PersonalInfosForm
  ) => {
    dispatch(onUpdatePersonalInfosInProfileAction(name, email, phone, personalInfos))
  },
  updateCurrentUserInfos: () => {
    dispatch(updateCurrentUserInfosAction())
  },
  clearForceUpdateCurrentUser: () => {
    dispatch(clearForceUpdateCurrentUserAction())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInfos)
