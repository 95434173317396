import React from 'react'
import Lottie from 'react-lottie'
import positiveFeedback from '../../assets/animations/positive_feedback.json'

export interface Props{
  animationWidth?:number,
  animationHeight?:number,
  isClickToPauseDisabled: boolean;
  ariaRole: string;
}

export function PositiveFeedbackAnimation({
  animationHeight,
  animationWidth,
  isClickToPauseDisabled,
  ariaRole,
}: Props) {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: positiveFeedback
  }
  return (
    <Lottie
      options={defaultOptions}
      height={animationHeight}
      width={animationWidth}
      isClickToPauseDisabled={isClickToPauseDisabled}
      ariaRole={ariaRole}
    />
  )
}
