import React from 'react'

interface Props {
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  id?: string;
}

export default function CircleButton({
  onClick, children, disabled, id
}: Props) {
  return (
    <button
      id={id}
      type="button"
      onClick={() => onClick()}
      disabled={disabled}
      className="rounded-full focus:outline-none flex justify-center flex-shrink-0"
    >
      {children}
    </button>
  )
}
