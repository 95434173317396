import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { NewListItem, Button } from 'theia-web-ds'
import AppMainContainerOff from '../AppMainContainerOff'
import StepperBar from '../common/StepperBar'
import Emoji from '../common/atoms/Emoji'
import { primary } from '../../color'
import { eventPage, eventTrack } from '../../../eventGenerate'
import './SalesOnboarding.scss'
import { DATA_MENSTRUACAO, DATA_NASCIMENTO, LOGIN_DE_ACESSO } from '../../routes/RoutesConstants'

function SalesOnboardingGestationalAge() {
  const history = useHistory()

  useEffect(() => {
    eventPage('onboarding', 'Idade Gestacional')
  }, [])

  function goToPregnancyDueDate() {
    eventTrack('Clicou Informar DPP')
    history.push(DATA_NASCIMENTO)
  }

  function goToLastPeriod() {
    eventTrack('Clicou Informar DUM')
    history.push(DATA_MENSTRUACAO)
  }

  function skipStep() {
    eventTrack('Pulou Etapa Idade Gestacional')
    history.push(LOGIN_DE_ACESSO)
  }

  return (
    <AppMainContainerOff>
      <div className="sales-onboarding-container">
        <StepperBar totalSteps={10} currentStep={1} showNumbers={false} />
        <h1 className="text-primary font-medium text-headlineMedium pt-8">
          Agora vamos calcular sua idade gestacional
        </h1>
        <p className="text-textMedium text-darkGreyColor mt-5 text-content" style={{ maxWidth: '90%' }}>
          Para que você possa acompanhar a sua gestação semana a semana.
        </p>
        <p className="sales-onboarding-subtitle">Escolha uma das opções abaixo <Emoji symbol="👇" /></p>

        <ul>
          <NewListItem
            onClick={goToPregnancyDueDate}
            iconType="icon-CalendarLight"
            iconColor={primary}
            iconSize="21px"
            title="Informar a data de nascimento do bebê"
            ariaLabel="Informar a data de nascimento do bebê"
            id="pregnance-due-date"
          />

          <NewListItem
            onClick={goToLastPeriod}
            iconType="icon-WaterDropLight"
            iconColor={primary}
            iconSize="21px"
            title="Descobrir pela minha última menstruação"
            ariaLabel="Descobrir pela minha última menstruação"
            id="last-period"
            isLastItem
          />
        </ul>

        <div className="flex items-center justify-center mt-10">
          <Button
            type="button"
            className="text-textSmaller underline text-darkGreyColor font-light"
            height="auto"
            onClick={skipStep}
          >
            Pular essa etapa
          </Button>
        </div>
      </div>
    </AppMainContainerOff>
  )
}

export default React.memo(SalesOnboardingGestationalAge)
