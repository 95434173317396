/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum TermTypeEnum {
  POLICY = 'POLICY',
  TERMS = 'TERMS',
  DATA = 'DATA',
}

export type CurrentTerm = {
  id: string;
  name: string;
  type: TermTypeEnum;
  version: number;
  htmlText: string;
}

export interface TermsProvider {
  getCurrentTerms: () => Promise<Array<CurrentTerm>>;
}
