import React, { useState } from 'react'
import { BaseButton } from '../BaseButton/BaseButton'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { Alert } from '../Alert/Alert'
import './InvisibleTextButton.scss'

export interface Props {
  children?: React.ReactNode;
  errorText?: string;
  readOnly?: boolean;
  width?: string;
  placeholderText?: string;
  extraClass?: string;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  textInfo?: string;
  onClick?: () => void;
}

export function InvisibleTextButton({
  children,
  errorText,
  readOnly,
  width,
  placeholderText,
  extraClass,
  disabled,
  size,
  textInfo,
  onClick,
}: Props) {
  const [showTextInfo, setShowTextInfo] = useState(false)

  if (!children && !placeholderText) return null

  function handleShowTextInfo() {
    if (textInfo && (disabled || readOnly)) setShowTextInfo(true)
  }

  function handleHideTextInfo() {
    setShowTextInfo(false)
  }

  return (
    <div
      className={`
        invisible-text-button--component
        ${extraClass}
      `}
      style={{ width: width || '100%' }}
      onMouseEnter={handleShowTextInfo}
      onMouseLeave={handleHideTextInfo}
    >
      {readOnly ? (
        <div
          className={`
            invisible-text-button-readonly-text
            invisible-text-button-readonly-text-${size || 'medium'}
            ${disabled ? 'disabled' : ''}
            ${placeholderText ? 'placeholder' : ''}
          `}
          data-testid="readonly-text"
        >
          {children || placeholderText}
          {showTextInfo && (
            <Alert
              extraClass="alert-invisible-text-button"
              message={textInfo}
              indicator="left-top"
            />
          )}
        </div>
      ) : (
        <>
          <BaseButton
            className={`
              invisible-text-button relative
              ${placeholderText ? 'placeholder' : ''}
              ${errorText ? 'invisible-text-button-error' : ''}
              invisible-text-button-${size || 'medium'}
              ${disabled ? 'disabled' : ''}
            `}
            onClick={onClick}
            data-testid="invisible-text-button"
            disabled={disabled}
          >
            {children || placeholderText}
          </BaseButton>
          {showTextInfo && (
            <Alert
              extraClass={`alert-invisible-text-button disabled-${size || 'medium'}`}
              message={textInfo}
              indicator="left-top"
            />
          )}
          {errorText && (
            <ErrorMessage error={errorText} />
          )}
        </>
      )}
    </div>
  )
}
