import { LocalDate } from './utils/Date'
import { Professional } from './Professional'
import { User } from './User'

export type Message = {
  text: string;
  author: string;
  dateCreated: LocalDate;
  type: 'text' | 'media' | 'schedule';
  index: number;
  isSender: boolean;
  media?: any;
  mediaURL?: string;
}

export type ProfessionalScheduleMessage = {
  professionalId: string;
  professional?: Professional;
  showMoreOptions: boolean;
} & Message

export type CategoryScheduleMessage = {
  professionalCategory: string;
  professional?: Professional;
  showMoreOptions: boolean;
} & Message

export type ScheduleMessage = ProfessionalScheduleMessage | CategoryScheduleMessage

export type MediaMessage = {
  mediaURL: string;
} & Message

export interface MessageReceivedHandler {
  (message: Message): void;
}

export interface OnlineSpecialistHandler {
  (identity: string): void;
}

export interface OfflineSpecialistHandler {
  (): void;
}

export interface SpecialistTypingHandler {
  (typing: boolean): void;
}

export interface PlatformUnavailability {
  id: string;
  start: string;
  end: string;
  description: string;
  hourToBack?: string;
}

export interface ChatServiceFactory {
  build(currentUser: User): Promise<ChatService>;
}

export interface ChatService {
  getMessages(anchor?: number): Promise<Array<Message>>;
  getOnlineSpecialist(): Promise<string | undefined>;
  sendMessage(text: string): void;
  setOnMessageReceived(handler: MessageReceivedHandler): void;
  setOnSpecialistOnline(handler: OnlineSpecialistHandler): void;
  setOnSpecialistOffline(handler: OfflineSpecialistHandler): void;
  setOnSpecialistTyping(handler: SpecialistTypingHandler): void;
  typing(): void;
  getUnavailability(): Promise<Array<PlatformUnavailability>>;
  setForceReconnect(): void;
  needForceReconnect(): boolean;
  sendMediaMessage(file: File): void;
  getMediaUrl(message: Message): Promise<string>;
}

export function isProfessionalScheduleMessage(msg: ScheduleMessage):
  msg is ProfessionalScheduleMessage {
  return !!(msg as ProfessionalScheduleMessage).professionalId
}

export function isCategoryScheduleMessage(msg: ScheduleMessage):
  msg is CategoryScheduleMessage {
  return !!(msg as CategoryScheduleMessage).professionalCategory
}

export function isMediaMessage(msg: Message):
  msg is MediaMessage {
  return msg.type === 'media'
}

export function isScheduleMessage(msg: Message):
  msg is ScheduleMessage {
  return msg.type === 'schedule'
}
