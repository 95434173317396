import React, { useEffect, useRef, useState } from 'react'
import {
  AccessibleModal,
  ErrorMessage,
  FormInput,
  IconButton,
  MaskedFormInput,
  RegularButton
} from 'theia-web-ds'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AppDispatch } from '../../../state/utils'
import { clearVerificationCodeStatusAction, getUserConfirmationInfosAction, sendVerificationCodeAction } from '../../../state/authentication/main/actions'
import { AppState } from '../../../apps/main/store'
import { User } from '../../../domain/User'
import { textPrimary } from '../../../color'
import Emoji from '../../common/atoms/Emoji'
import { GenericStatusType } from '../../../domain/Status'
import PhoneCheck from '../../../../assets/ValidationPhone.svg'
import { clearValidatePhoneErrorsAction, suppressValidatePhoneModalAction, validateInformationAction } from '../../../state/profile/actions'
import { eventTrack } from '../../../../eventGenerate'
import { PHONE_VALIDATION } from '../../../utils/EventCategories'
import { phoneMask, validationPhoneRegex } from '../../../utils/helpers'
import './ValidationModal.scss'

interface PhoneValidationValues {
  phone: string;
  verificationCode: string;
}

function renderSteps(
  {
    values,
    touched,
    isSubmitting,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setSubmitting,
    setFieldValue,
    setFieldTouched
  }: FormikProps<PhoneValidationValues>,
  sendPhoneVerificationCodeStatus: GenericStatusType,
  validatePhoneStatus: GenericStatusType,
  isFromProfileEdit: boolean,
  sendPhoneVerificationCode: (phone: string) => void,
  suppressValidatePhoneModal: () => void,
  clearValidatePhoneErrors: () => void,
  getUserConfirmationInfos: () => void,
  clearSendPhoneVerificationCodeStatus: () => void
) {
  const [validationStep, setValidationStep] = useState(1)
  const [seconds, setSeconds] = useState(45)
  const [showSuccessContent, setShowSuccessContent] = useState(false)
  const intervalRef: any = useRef()

  function startTimer() {
    intervalRef.current = (setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1
        }
        clearInterval(intervalRef.current)
        return prevSeconds
      })
    }, 1000))

    return () => clearInterval(intervalRef.current)
  }

  const resetTimer = () => {
    clearInterval(intervalRef?.current)
    setSeconds(45)
  }

  function handleOnSubmit(e?: React.FormEvent<EventTarget>) {
    e?.preventDefault()
    handleSubmit()
  }

  function handleClickResendCode() {
    sendPhoneVerificationCode(values.phone)
    resetTimer()
    eventTrack('clicou reenviar sms', {
      category: PHONE_VALIDATION,
      phone: values.phone
    })
  }

  function clearValidatePhoneForm() {
    clearValidatePhoneErrors()
    setFieldValue('verificationCode', '')
    setFieldTouched('verificationCode', false)
  }

  function handleNextStep() {
    setValidationStep(validationStep + 1)
    resetTimer()
  }

  function closeModal() {
    suppressValidatePhoneModal()
    clearSendPhoneVerificationCodeStatus()
  }

  function onGoBack() {
    if (validationStep > 1) {
      setValidationStep(validationStep - 1)
      resetTimer()
    } else closeModal()
  }

  function handleClickChangePhone() {
    eventTrack('clicou alteracao celular', {
      category: PHONE_VALIDATION,
      phone: values.phone
    })
    onGoBack()
  }

  function handleSendVerificationCode() {
    sendPhoneVerificationCode(values.phone)
  }

  useEffect(() => {
    if (sendPhoneVerificationCodeStatus.success) {
      clearValidatePhoneForm()
      if (validationStep === 1) {
        handleNextStep()
      }
      startTimer()
      eventTrack('sms validação enviado', {
        category: PHONE_VALIDATION,
        phone: values.phone
      })
    }
    if (sendPhoneVerificationCodeStatus.error) {
      eventTrack(`${validationStep === 1 ? 'erro envio sms' : 'erro reenvio sms'}`, {
        category: PHONE_VALIDATION,
        phone: values.phone,
        motivo: sendPhoneVerificationCodeStatus.errorMessage?.friendlyMessage,
        plataforma: 'web'
      })
    }
  }, [sendPhoneVerificationCodeStatus])

  useEffect(() => {
    if (values.verificationCode.length === 6) handleOnSubmit()
  }, [values.verificationCode])

  useEffect(() => {
    if (validatePhoneStatus.error) {
      setSubmitting(false)
      eventTrack('erro validação celular', {
        category: PHONE_VALIDATION,
        phone: values.phone,
        motivo: validatePhoneStatus.errorMessage?.friendlyMessage,
        plataforma: 'web'
      })
    }
    if (validatePhoneStatus.success) {
      setSubmitting(false)
      setShowSuccessContent(true)
      getUserConfirmationInfos()
      eventTrack('celular verificado', {
        category: PHONE_VALIDATION,
        phone: values.phone,
        source: isFromProfileEdit ? 'perfil' : 'card da home'
      })
    }
  }, [validatePhoneStatus])

  useEffect(() => {
    if (validationStep) {
      clearValidatePhoneErrors()
    }
  }, [validationStep])

  const hasRequestError = validatePhoneStatus.errorMessage?.friendlyMessage
  || sendPhoneVerificationCodeStatus?.errorMessage?.friendlyMessage

  /**
   * erro 409 indica que o valor enviado já está em uso por outra conta,
   * portanto o user precisa inserir outro valor e validar novamente
   */

  const step1ButtonLabel = sendPhoneVerificationCodeStatus.errorMessage?.statusCode === '409' || !hasRequestError ? 'Continuar' : 'Tentar novamente'

  const step1 = (
    <div className="account-validation--content">
      <h1 className="account-validation--title">
        {isFromProfileEdit ? 'Alterar celular' : 'Confirme seu celular'}
      </h1>
      <p className="account-validation--text">
        {isFromProfileEdit
          ? 'O novo celular substituirá o que está cadastrado atualmente para receber informações e contato da Theia'
          : 'Verifique se o seu celular está correto para fazer a validação'}
      </p>
      <MaskedFormInput
        type="tel"
        placeholder=""
        label={isFromProfileEdit ? 'Novo celular' : 'Celular'}
        name="phone"
        value={values.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        errorMessage={touched.phone ? errors.phone : ''}
        id="phone"
        mask={phoneMask}
        onClick={clearSendPhoneVerificationCodeStatus}
      />
      {sendPhoneVerificationCodeStatus?.error && (
      <ErrorMessage error={sendPhoneVerificationCodeStatus?.errorMessage?.friendlyMessage || 'Não foi possível concluir sua solicitação'} />
      )}
      <RegularButton
        label={step1ButtonLabel}
        type="button"
        onClick={handleSendVerificationCode}
        extraClass="mt-8"
        isSubmitting={sendPhoneVerificationCodeStatus.isLoading}
        disabled={!!errors.phone}
      />
    </div>
  )

  const step2 = (
    <div className="account-validation--content">
      <h1 className="account-validation--title">
        {isFromProfileEdit ? 'Valide seu novo celular' : 'Validar celular'}
      </h1>
      <p className="account-validation--text">
        Informe o código de 6 digitos que enviamos para
        &nbsp;<span className="user-data">{values.phone}</span>&nbsp;
        – pode demorar uns minutos para chegar <Emoji symbol="😊" />
      </p>
      <FormInput
        placeholder="Digite o código"
        label="Código"
        type="text"
        name="verificationCode"
        value={values.verificationCode}
        onChange={handleChange}
        onBlur={handleBlur}
        errorMessage={touched.verificationCode ? errors.verificationCode : ''}
        id="verificationCode"
        disabled={isSubmitting || validatePhoneStatus.isLoading}
        isSubmitting={validatePhoneStatus.isLoading || isSubmitting}
        onClick={clearValidatePhoneErrors}
      />
      {validatePhoneStatus?.error && (
      <ErrorMessage error={validatePhoneStatus?.errorMessage?.friendlyMessage || 'Não foi possível concluir sua solicitação'} />
      )}
      <div className="account-validation--footer">
        <h3 className="account-validation--text-footer">
          Ainda não recebeu o código?
        </h3>
        <RegularButton
          type="button"
          label={seconds > 0 ? `Aguarde ${seconds}s` : 'Reenviar código'}
          disabled={seconds > 0 || isSubmitting}
          isSubmitting={sendPhoneVerificationCodeStatus.isLoading}
          onClick={handleClickResendCode}
        />
        <RegularButton
          type="button"
          label="Alterar celular"
          disabled={isSubmitting}
          onClick={handleClickChangePhone}
          variant="subtle"
        />
      </div>
    </div>
  )

  return showSuccessContent ? (
    <div className="legacy-account-validation--success-content">
      <PhoneCheck className="header-image" width="104" height="104" />
      <h1 className="success-title">
        Celular validado!
      </h1>
      <p className="success-content">
        Deu tudo certo, agora que seu celular está {isFromProfileEdit ? 'atualizado' : 'validado'},
        &nbsp;você já pode utilizar seu app da Theia à vontade!
      </p>
      <RegularButton
        label="Fechar"
        type="button"
        onClick={closeModal}
        variant="primary"
      />
    </div>
  ) : (
    <form
      onSubmit={handleOnSubmit}
      noValidate
      className="legacy-account-validation--form"
      name="phoneValidationForm"
    >
      <div className="account-validation--header ">
        <IconButton
          variant="form-icon"
          iconColor={textPrimary}
          iconType="icon-ArrowLeft2Light"
          iconSize="24px"
          onClick={onGoBack}
          isDisabled={isSubmitting}
        />
      </div>
      {validationStep === 1 ? step1 : null}
      {validationStep === 2 ? step2 : null}
    </form>
  )
}

interface Props {
  currentUser?: User;
  sendPhoneVerificationCodeStatus: GenericStatusType;
  validatePhoneStatus: GenericStatusType;
  isFromProfileEdit: boolean;
  showValidatePhoneModal: boolean;
  sendPhoneVerificationCode: (phone: string) => void;
  validatePhone: (verificationCode: string, phone: string) => void;
  suppressValidatePhoneModal: () => void;
  clearValidatePhoneErrors: () => void;
  getUserConfirmationInfos: () => void;
  clearSendPhoneVerificationCodeStatus: () => void;
}

const validationSchema = Yup.object().shape({
  verificationCode: Yup.string().required('Código obrigatório')
    .length(6, 'O código deve conter 6 dígitos'),
  phone: Yup.string()
    .required('Celular obrigatório')
    .matches(validationPhoneRegex, 'Celular inválido'),
})

function PhoneValidationModal({
  currentUser,
  sendPhoneVerificationCodeStatus,
  validatePhoneStatus,
  isFromProfileEdit,
  showValidatePhoneModal,
  sendPhoneVerificationCode,
  validatePhone,
  suppressValidatePhoneModal,
  clearValidatePhoneErrors,
  getUserConfirmationInfos,
  clearSendPhoneVerificationCodeStatus
}: Props) {
  const initialValues: PhoneValidationValues = {
    phone: currentUser?.phone || '',
    verificationCode: '',
  }

  function onSubmit(values: PhoneValidationValues) {
    validatePhone(values.verificationCode, values.phone)
  }

  function suppressModal() {
    suppressValidatePhoneModal()
    clearSendPhoneVerificationCodeStatus()
  }

  return (
    <AccessibleModal
      headerText=""
      onClose={suppressModal}
      visible={showValidatePhoneModal}
      variant="centerBottom"
      extraOuterClassName={`legacy-account-validation--modal
        ${validatePhoneStatus.success ? 'success' : ''}
      `}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => renderSteps(
          props,
          sendPhoneVerificationCodeStatus,
          validatePhoneStatus,
          isFromProfileEdit,
          sendPhoneVerificationCode,
          suppressValidatePhoneModal,
          clearValidatePhoneErrors,
          getUserConfirmationInfos,
          clearSendPhoneVerificationCodeStatus
        )}
      </Formik>
    </AccessibleModal>
  )
}

const mapStateToProps = ({ authentication, profile }: AppState) => ({
  currentUser: authentication.currentUser,
  sendPhoneVerificationCodeStatus: authentication.sendVerificationCodeStatus,
  validatePhoneStatus: profile.validateInformationStatus,
  isFromProfileEdit: profile.isFromProfileEdit,
  showValidatePhoneModal: profile.showValidatePhoneModal
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  sendPhoneVerificationCode: sendVerificationCodeAction,
  validatePhone: validateInformationAction,
  suppressValidatePhoneModal: suppressValidatePhoneModalAction,
  clearValidatePhoneErrors: clearValidatePhoneErrorsAction,
  getUserConfirmationInfos: getUserConfirmationInfosAction,
  clearSendPhoneVerificationCodeStatus: clearVerificationCodeStatusAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneValidationModal)
