/* eslint-disable class-methods-use-this */
import { TOKEN_ACCESS } from '../domain/User'

export type HttpHeaderSet = {
  [s: string]: string;
}

export interface HttpResponse {
  status: number;
  body: string;
}

export type RequestParameters = {
  url: string;
  headers?: HttpHeaderSet;
  token?: string;
}

export type PostRequestParameters = {
  payload?: { [key: string]: any };
} & RequestParameters

export type PostFileRequestParameters = {
  body: FormData;
} & RequestParameters

export type PutRequestParameters = {
  payload?: { [key: string]: any };
} & RequestParameters

export interface UnauthorizedCallback {
  (): void;
}

export interface HttpClient {
  setUnauthorizedCallback(callback: UnauthorizedCallback): void;
  get(parameters: RequestParameters): Promise<HttpResponse>;
  post(parameters: PostRequestParameters): Promise<HttpResponse>;
  postFile(parameters: PostFileRequestParameters): Promise<HttpResponse>;
  put(parameters: PutRequestParameters): Promise<HttpResponse>;
  delete(parameters: RequestParameters): Promise<HttpResponse>;
  getCrossDomain(parameters: RequestParameters): Promise<HttpResponse>;
}

export class SimpleHttpClient implements HttpClient {
  private unauthorizedCallback?: UnauthorizedCallback

  setUnauthorizedCallback(callback: UnauthorizedCallback) {
    this.unauthorizedCallback = callback
  }

  setHeader(parameters: RequestParameters) {
    let headers = {}
    const localToken = localStorage.getItem(TOKEN_ACCESS) || ''
    if (parameters.token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${parameters.token}`
      }
    } else if (localToken !== '') {
      headers = {
        ...headers,
        Authorization: `Bearer ${localToken}`
      }
    }
    return headers
  }

  async get(parameters: RequestParameters): Promise<HttpResponse> {
    const headers = this.setHeader(parameters)
    const response = await fetch(parameters.url, {
      credentials: 'include',
      headers
    })
    if (this.unauthorizedCallback && response.status === 401) {
      this.unauthorizedCallback()
    }
    return {
      status: response.status,
      body: await response.text(),
    }
  }

  async getCrossDomain(parameters: RequestParameters): Promise<HttpResponse> {
    let headers = {}
    const localToken = localStorage.getItem(TOKEN_ACCESS) || ''
    if (localToken !== '') {
      headers = {
        ...headers,
        Authorization: `Bearer ${localToken}`
      }
    }
    const response = await fetch(parameters.url, {
      credentials: 'include',
      headers
    })
    if (this.unauthorizedCallback && response.status === 401) {
      this.unauthorizedCallback()
    }
    return {
      status: response.status,
      body: await response.text(),
    }
  }

  async post(parameters: PostRequestParameters): Promise<HttpResponse> {
    const headers = this.setHeader(parameters)
    const response = await fetch(parameters.url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json; charset=UTF-8',
        ...headers,
      },
      body: JSON.stringify(parameters.payload),
    })

    if (this.unauthorizedCallback && response.status === 401) {
      this.unauthorizedCallback()
    }

    return {
      status: response.status,
      body: await response.text(),
    }
  }

  async postFile(parameters: PostFileRequestParameters): Promise<HttpResponse> {
    const headers = this.setHeader(parameters)
    const response = await fetch(parameters.url, {
      method: 'POST',
      credentials: 'include',
      body: parameters.body,
      headers
    })

    if (this.unauthorizedCallback && response.status === 401) {
      this.unauthorizedCallback()
    }

    return {
      status: response.status,
      body: await response.text(),
    }
  }

  async put(parameters: PutRequestParameters): Promise<HttpResponse> {
    const headers = this.setHeader(parameters)
    const response = await fetch(parameters.url, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json; charset=UTF-8',
        ...headers,
      },
      body: JSON.stringify(parameters.payload)
    })

    if (this.unauthorizedCallback && response.status === 401) {
      this.unauthorizedCallback()
    }

    return {
      status: response.status,
      body: await response.text(),
    }
  }

  async delete(parameters: RequestParameters): Promise<HttpResponse> {
    const headers = this.setHeader(parameters)
    const response = await fetch(parameters.url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json; charset=UTF-8',
        ...headers,
      }
    })

    if (this.unauthorizedCallback && response.status === 401) {
      this.unauthorizedCallback()
    }

    return {
      status: response.status,
      body: await response.text(),
    }
  }
}
