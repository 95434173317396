import {
  GET_PREGNANCY_JOURNEY,
  SUCCESS_GET_PREGNANCY_JOURNEY,
  FAILURE_GET_PREGNANCY_JOURNEY,
  GET_GUIDED_JOURNEY,
  SUCCESS_GET_GUIDED_JOURNEY,
  FAILURE_GET_GUIDED_JOURNEY,
  GET_QUARTER_DETAILS,
  SUCCESS_GET_QUARTER_DETAILS,
  FAILURE_GET_QUARTER_DETAILS,
  GET_CONSULTATION_INFOS,
  SUCCESS_GET_CONSULTATION_INFOS,
  FAILURE_GET_CONSULTATION_INFOS,
  SET_VIEWED_GUIDED_JOURNEY,
  SUCCESS_VIEWED_GUIDED_JOURNEY,
  FAILURE_VIEWED_GUIDED_JOURNEY,
  CHECK_JOURNEY_STEP,
  SUCCESS_CHECK_JOURNEY_STEP,
  FAILURE_CHECK_JOURNEY_STEP,
  UNCHECK_JOURNEY_STEP,
  SUCCESS_UNCHECK_JOURNEY_STEP,
  FAILURE_UNCHECK_JOURNEY_STEP,
  PROMPT_CHECK_JOURNEY_STEP,
  PROMPT_UNCHECK_JOURNEY_STEP,
  SELECT_JOURNEY_QUARTER,
  GuidedJourneyTypes,
} from './actions';
import {
  ConsultationInfos,
  GuidedJourney,
  PregnancyJourney,
  Quarter,
  QuarterConsultation
} from '../../domain/GuidedJourney';

export interface GuidedJourneyState {
  pregnancyJourney: PregnancyJourney;
  guidedJourney: GuidedJourney;
  quarterDetails?: Array<QuarterConsultation>;
  activeCard?: QuarterConsultation;
  activeConsultation?: ConsultationInfos;
  isLoading: boolean;
  isGettingConsultationInfos: boolean;
  isCheckingStep: boolean;
  activeCheckboxId: string;
  selectedQuarter?: Quarter;
  hasCurrentQuarter: boolean;
}

const initialGuidedJourneyState: GuidedJourneyState = {
  pregnancyJourney: {
    firstJourneyAppointmentBooked: false,
    journeySteps: {
      imageUrl: '',
      title: '',
      subtitle: '',
      appointments: [],
    },
  },
  guidedJourney: {
    firstTimeJourneyMessage: '',
    firstTimeMessage: '',
    isFirstTime: false,
    list: [],
  },
  quarterDetails: undefined,
  activeCard: undefined,
  activeConsultation: undefined,
  isLoading: false,
  isGettingConsultationInfos: false,
  isCheckingStep: false,
  activeCheckboxId: '',
  selectedQuarter: undefined,
  hasCurrentQuarter: false
}

export function guidedJourney(
  state = initialGuidedJourneyState,
  action: GuidedJourneyTypes
): GuidedJourneyState {
  switch (action.type) {
    case GET_PREGNANCY_JOURNEY: {
      return {
        ...state,
        isLoading: true
      }
    }
    case SUCCESS_GET_PREGNANCY_JOURNEY: {
      return {
        ...state,
        pregnancyJourney: action.pregnancyJourney,
        isLoading: false,
      }
    }
    case FAILURE_GET_PREGNANCY_JOURNEY: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case GET_GUIDED_JOURNEY: {
      return {
        ...state,
        isLoading: true
      }
    }
    case SUCCESS_GET_GUIDED_JOURNEY: {
      const currentQuarter = action.guidedJourney.list.find(
        (quarter) => quarter.currentQuarter === true
      )
      return {
        ...state,
        guidedJourney: action.guidedJourney,
        hasCurrentQuarter: !!currentQuarter,
        isLoading: false,
      }
    }
    case FAILURE_GET_GUIDED_JOURNEY: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case GET_QUARTER_DETAILS: {
      return {
        ...state,
        isLoading: true,
        quarterDetails: undefined,
        activeConsultation: undefined
      }
    }
    case SUCCESS_GET_QUARTER_DETAILS: {
      return {
        ...state,
        isLoading: false,
        quarterDetails: action.quarterDetails
      }
    }
    case FAILURE_GET_QUARTER_DETAILS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case GET_CONSULTATION_INFOS: {
      return {
        ...state,
        isGettingConsultationInfos: true,
        activeCard: action.cardInfo,
        activeConsultation: undefined
      }
    }
    case SUCCESS_GET_CONSULTATION_INFOS: {
      return {
        ...state,
        isGettingConsultationInfos: false,
        activeConsultation: {
          ...action.consultationInfos,
          id: action.id
        }
      }
    }
    case FAILURE_GET_CONSULTATION_INFOS: {
      return {
        ...state,
        isGettingConsultationInfos: false,
      }
    }
    case SET_VIEWED_GUIDED_JOURNEY: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case SUCCESS_VIEWED_GUIDED_JOURNEY: {
      return {
        ...state,
        isLoading: false
      }
    }
    case FAILURE_VIEWED_GUIDED_JOURNEY: {
      return {
        ...state,
        isLoading: false
      }
    }
    case PROMPT_CHECK_JOURNEY_STEP: {
      const newQuarterState = state.quarterDetails?.map((step) => {
        if (step.id === action.id) {
          return {
            ...step,
            done: true
          }
        }
        return step
      })
      return {
        ...state,
        isCheckingStep: false,
        quarterDetails: newQuarterState,
        activeCheckboxId: action.id,
      }
    }
    case CHECK_JOURNEY_STEP: {
      return {
        ...state,
        isCheckingStep: true,
        activeCheckboxId: action.id,
      }
    }
    case SUCCESS_CHECK_JOURNEY_STEP: {
      const newQuarterState = state.quarterDetails?.map((card) => {
        if (card.id === action.cardProps.id) {
          return {
            ...card,
            done: action.cardProps.done,
            chipList: action.cardProps.chipList
          }
        }
        return card
      })
      return {
        ...state,
        isCheckingStep: false,
        quarterDetails: newQuarterState
      }
    }
    case FAILURE_CHECK_JOURNEY_STEP: {
      const newQuarterState = state.quarterDetails?.map((card) => {
        if (card.id === action.id) {
          return {
            ...card,
            done: false
          }
        }
        return card
      })
      return {
        ...state,
        isCheckingStep: false,
        quarterDetails: newQuarterState
      }
    }
    case PROMPT_UNCHECK_JOURNEY_STEP: {
      const newQuarterState = state.quarterDetails?.map((step) => {
        if (step.id === action.id) {
          return {
            ...step,
            done: false
          }
        }
        return step
      })
      return {
        ...state,
        isCheckingStep: false,
        quarterDetails: newQuarterState,
        activeCheckboxId: action.id,
      }
    }
    case UNCHECK_JOURNEY_STEP: {
      return {
        ...state,
        isCheckingStep: true,
        activeCheckboxId: action.id
      }
    }
    case SUCCESS_UNCHECK_JOURNEY_STEP: {
      const newQuarterState = state.quarterDetails?.map((card) => {
        if (card.id === action.cardProps.id) {
          return {
            ...card,
            done: action.cardProps.done,
            chipList: action.cardProps.chipList
          }
        }
        return card
      })
      return {
        ...state,
        isCheckingStep: false,
        quarterDetails: newQuarterState
      }
    }
    case FAILURE_UNCHECK_JOURNEY_STEP: {
      const newQuarterState = state.quarterDetails?.map((card) => {
        if (card.id === action.id) {
          return {
            ...card,
            done: true
          }
        }
        return card
      })
      return {
        ...state,
        isCheckingStep: false,
        quarterDetails: newQuarterState
      }
    }
    case SELECT_JOURNEY_QUARTER: {
      return {
        ...state,
        selectedQuarter: action.selectedQuarter
      }
    }
    default:
      return state;
  }
}
