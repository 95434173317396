/* eslint-disable react/prop-types */
import React from 'react'
import { ErrorMessage, NewChipButton } from 'theia-web-ds'
import './BooleanInput.scss'

export interface FieldProps {
  label?: string;
  name: string;
  value?: boolean;
  isLoading?: boolean;
  error?: string;
  onChange: (newValue: boolean) => void;
}

export default function BooleanInput({
  label,
  name,
  value,
  isLoading,
  error,
  onChange,
}: FieldProps) {
  function onChangeToTrue() {
    onChange(true)
  }
  function onChangeToFalse() {
    onChange(false)
  }

  if (isLoading) {
    return (
      <div className="input-group boolean-input" data-testid="loading-boolean-input">
        <div className="loading-label" data-testid="loading-label" />
        <div className="boolean-buttons" id={name}>
          <div className="loading-chip-option first-loading-chip" data-testid="sim-loading-chip" />
          <div className="loading-chip-option" data-testid="não-loading-chip" />
        </div>
      </div>
    )
  }

  return (
    <div className="input-group boolean-input">
      <label htmlFor={name} className={error ? 'error' : ''}>{label}</label>
      <div className="boolean-buttons" id={name}>
        <NewChipButton
          onClick={onChangeToTrue}
          label="Sim"
          isSelected={value}
          width="100%"
        />
        <NewChipButton
          onClick={onChangeToFalse}
          label="Não"
          isSelected={!value && value !== undefined}
          width="100%"
        />
      </div>
      {error && (
        <ErrorMessage error={error} />
      )}
    </div>
  )
}
