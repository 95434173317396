import React from 'react'
import { FontIcon } from 'theia-web-ds';
import './Item.scss'

interface Props {
  iconType?: string;
  iconColor?: string;
  iconSize?: string;
  label: string;
  value?: string;
  ariaLabel: string;
  className?: string;
  id?: string;
  isLoading?: boolean;
}

export default function Item({
  iconType,
  iconColor,
  iconSize,
  label,
  ariaLabel,
  className,
  id,
  value,
  isLoading = false
}: Props) {
  if (isLoading) {
    return (
      <div className="item-loading">
        <div className="item-icon-loading" />
        <div className="item-content-container-loading">
          <div className="item-label-loading" />
          <div className="item-value-loading" />
        </div>
      </div>
    )
  }

  return (
    <div
      className={`item-container ${className}`}
      key={id}
      id={id}
      aria-label={ariaLabel}
    >
      <div className="item-icon-container">
        <FontIcon
          color={iconColor}
          iconType={iconType}
          fontSize={iconSize}
        />
      </div>
      <div className="item-content-info">
        <p className="item-label">{label}</p>
        <p className="item-value">{value || '--'}</p>
      </div>
    </div>
  )
}
