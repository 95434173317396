export type Tag = {
  slug: string;
  name: string;
}

export type Post = {
  id: string;
  primary_tag: Tag;
  title: string;
  feature_image: string;
  postDate: string;
  primary_author: {
    profile_image: string;
    name: string;
  };
  published_at: string;
  slug: string;
  url: string;
  meta_description: string;
  meta_title: string;
  tags: Tag[];
  featured: boolean;
}

export const defaultCategory = 'Todas as categorias'
