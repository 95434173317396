import { McsClient, McsMedia } from '@twilio/mcs-client';

export interface MediaState {
  sid: string;
  filename?: string;
  contentType: string;
  size: number;
}

export interface MediaServices {
  mcsClient: McsClient;
}

/**
 * Represents media information for a message in a channel.
 */
class Media {

  private state: MediaState;
  private services: MediaServices;
  private mcsMedia: McsMedia = null;

  /**
   * @internal
   */
  constructor(data: MediaState, services: MediaServices) {

    this.services = services;

    this.state = {
      sid: data.sid,
      filename: data.filename,
      contentType: data.contentType,
      size: data.size
    };
  }

  /**
   * Server-assigned unique identifier for the media.
   */
  public get sid(): string { return this.state.sid; }

  /**
   * File name. Null if absent.
   */
  public get filename(): string {return this.state.filename; }

  /**
   * Content type of the media.
   */
  public get contentType(): string { return this.state.contentType; }

  /**
   * Size of the media in bytes.
   */
  public get size(): number {return this.state.size; }

  /**
   * Returns the direct content URL for the media.
   *
   * This URL is impermanent, it will expire in several minutes and cannot be cached.
   * If the URL becomes expired, you need to request a new one.
   * Each call to this function produces a new temporary URL.
   */
  public async getContentTemporaryUrl() {
    if (!this.mcsMedia) {
      if (this.services.mcsClient) {
        this.mcsMedia = await this.services.mcsClient.get(this.state.sid);
      } else {
        throw new Error('Media Content Service is unavailable');
      }
    }
    return this.mcsMedia.getContentUrl();
  }
}

export { Media };
