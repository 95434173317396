import {
  ErrorTypes,
  PROMPT_ERROR,
  SUPPRESS_ERROR,
  ErrorWithFriendlyMessage
} from './actions'

export interface DefaultErrorState {
  visible: boolean;
  error?: ErrorWithFriendlyMessage;
}

export const initialState: DefaultErrorState = {
  visible: false,
  error: undefined
}

export function errorWithFriendlyMessage(
  state = initialState,
  action: ErrorTypes
): DefaultErrorState {
  switch (action.type) {
    case PROMPT_ERROR:
      return {
        ...state,
        visible: true,
        error: action.error
      }
    case SUPPRESS_ERROR:
      return {
        ...state,
        visible: false
      }
    default: return state
  }
}
