import * as Sentry from '@sentry/browser';
import { AsyncAction } from '../utils'
import { UserProvider, BookingsInfo } from '../../domain/User';
import { HttpClient } from '../../services/Http';

export const GET_BOOKINGS_INFO_REQUEST = 'GET_BOOKINGS_INFO_REQUEST'
export const SUCCESS_GET_BOOKINGS_INFO = 'SUCCESS_GET_BOOKINGS_INFO'
export const FAILURE_GET_BOOKINGS_INFO = 'FAILURE_GET_BOOKINGS_INFO'

interface GetBookingsInfoRequestActionType {
  type: typeof GET_BOOKINGS_INFO_REQUEST;
}
interface SuccessGetBookingsInfoActionType {
  type: typeof SUCCESS_GET_BOOKINGS_INFO;
  bookingsInfo: BookingsInfo;
}
interface FailureGetBookingsInfoActionType {
  type: typeof FAILURE_GET_BOOKINGS_INFO;
}

export type BookingsInfoActionTypes =
  GetBookingsInfoRequestActionType |
  SuccessGetBookingsInfoActionType |
  FailureGetBookingsInfoActionType

export const getBookingsInfoRequestAction = (): GetBookingsInfoRequestActionType => ({
  type: GET_BOOKINGS_INFO_REQUEST
})

export const successGetBookingsInfoAction = (bookingsInfo: BookingsInfo):
SuccessGetBookingsInfoActionType => ({
  type: SUCCESS_GET_BOOKINGS_INFO,
  bookingsInfo,
})

export const failureGetBookingsInfoAction = (): FailureGetBookingsInfoActionType => ({
  type: FAILURE_GET_BOOKINGS_INFO
})

export function getBookingsInfoAction():
  AsyncAction<{}, { httpClient: HttpClient; userProvider: UserProvider }> {
  return async (dispatch, _getState, { userProvider }) => {
    dispatch(getBookingsInfoRequestAction())
    try {
      const user: any = await userProvider.getCurrentUser()
      const bookingsInfo = {
        ...user.bookingsInfo
      }
      dispatch(successGetBookingsInfoAction(bookingsInfo))
    } catch (error) {
      dispatch(failureGetBookingsInfoAction())
      if (error instanceof Error) {
        Sentry.captureException(new Error(`Failure getting the bookings info: ${error.message}`))
      }
    }
  }
}
