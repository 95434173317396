/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import {
  RegularButton,
  ToolTip,
  FontIcon,
  IconButton
} from 'theia-web-ds'
import { QuarterConsultation } from '../../domain/GuidedJourney'
import {
  bgBox,
} from '../../color'
import './InfographicCard.scss'

interface Props {
  cardInfo?: QuarterConsultation;
  isLoading?: boolean;
  onClickArrowDesktopAction: () => void;
  onClickScheduleAction: () => void;
  onClickInfosAction: () => void;
}

function InfographicCard({
  cardInfo,
  isLoading,
  onClickArrowDesktopAction,
  onClickScheduleAction,
  onClickInfosAction,
}: Props) {
  const [isFlipped, setIsFlipped] = useState('')

  function handleClickArrowButton(event?: any) {
    event?.stopPropagation()
    if (window.innerWidth <= 1023 && isFlipped === '') {
      if (isFlipped === '') {
        setIsFlipped('is-flipped')
      }
      return
    }
    if (isFlipped === 'is-flipped') {
      setIsFlipped('')
    }
    if (window.innerWidth > 1023) {
      onClickArrowDesktopAction()
    }
  }

  function handleScheduleAction() {
    onClickScheduleAction()
  }

  function handleInfosAction() {
    onClickInfosAction()
  }

  if (isLoading || !cardInfo) {
    return (
      <div className="scene scene--card" data-testid="skeleton-card">
        <div className={`card ${isFlipped}`}>
          <div className="card__face card__face--front background card__face--loading">
            <div className="card__face__content--front">
              <div className="header">
                <div className="tooltip--placeholder" />
                <div className="iconButton--placeholder ml-auto" />
              </div>
              <div className="footer">
                <div className="title--placeholder mb-2" />
                <div className="flex items-center text-xs">
                  <div className="icon--loading mr-1" />
                  <div className="text--loading mr-4" />
                  <div className="icon--loading mr-1" />
                  <div className="text--loading" />
                </div>
              </div>
            </div>
          </div>
          <div className="card__face card__face--back background card__face--loading">
            <div className="card__face__content--back">
              <div className="header">
                <div className="iconButton--placeholder" />
              </div>
              <div className="body">
                <div className="flex flex-col text-center items-center w-full">
                  <div className="fontIcon--placeholder mb-3" />
                  <div className="button--placeholder" />
                </div>
                <div className="flex flex-col text-center items-center w-full">
                  <div className="fontIcon--placeholder mb-3" />
                  <div className="button--placeholder" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="infographic__card scene scene--card">
      <div className={`card ${isFlipped}`} data-testid="card-container">
        <div
          className="card__face card__face--front background"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0)30%, rgba(0, 0, 0, 0.8)100%), 
              url(${cardInfo.imgUrl})`,
          }}
          onClick={(e) => handleClickArrowButton(e)}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => handleClickArrowButton(e)}
        >
          <div className="card__face__content--front">
            <div className="header">
              <div className="flex flex-col gap-1">
                {cardInfo.chipList
                && cardInfo.chipList.length > 0
                && cardInfo.chipList.map((chip, index) => (
                  <ToolTip
                    width="fit-content"
                    label={chip.text}
                    backgroundColor={chip.color}
                    textColor={bgBox}
                    borderRadius="73px"
                    fontSize="12px"
                    key={index}
                  />
                ))}
              </div>
              <IconButton
                variant="subtle"
                extraClass="ml-auto"
                iconColor="primary"
                width="28px"
                height="28px"
                iconSize="14px"
                iconType="icon-ArrowRightLight"
                onClick={(e) => handleClickArrowButton(e)}
              />
            </div>
            <div className="footer">
              <p className="mb-2 text-base">
                {cardInfo.title}
              </p>
              <div className="flex items-center text-xs">
                <FontIcon
                  iconType={cardInfo.type === 'Presencial' && cardInfo.typeInfo === null
                    ? 'icon-2UsersLight'
                    : cardInfo.typeInfo !== null
                      ? 'icon-BuildingLight'
                      : 'icon-VideoLight'}
                  extraClass="mr-1"
                />
                <p className="mr-4">
                  {cardInfo.type}
                </p>
                <FontIcon
                  iconType="icon-CalendarLight"
                  extraClass="mr-1"
                />
                <p>
                  {cardInfo.description}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card__face card__face--back background"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0)30%, rgba(0, 0, 0, 0.8)100%), 
              url(${cardInfo.imgUrl})`,
          }}
          onClick={(e) => handleClickArrowButton(e)}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => handleClickArrowButton(e)}
        >
          <div className="card__face__content--back">
            <div className="header">
              <IconButton
                variant="icon"
                extraClass="ml-auto"
                iconColor="textPrimary"
                width="28px"
                height="28px"
                iconSize="18px"
                iconType="icon-CloseLight"
                onClick={(e) => handleClickArrowButton(e)}
              />
            </div>
            <div className="body">
              <div className="flex flex-col text-center items-center w-full">
                <RegularButton
                  label="Agendar consulta"
                  labelSize="14px"
                  variant="secondary-text"
                  type="button"
                  onClick={handleScheduleAction}
                  direction="flex-col"
                  iconType="icon-CalendarLight"
                  iconSize="20px"
                  height="fit-content"
                />
              </div>
              <div className="flex flex-col text-center items-center w-full">
                <RegularButton
                  label="Pra quê serve essa consulta?"
                  labelSize="14px"
                  variant="secondary-text"
                  type="button"
                  onClick={handleInfosAction}
                  direction="flex-col"
                  iconType="icon-ChatHelpLight"
                  iconSize="20px"
                  height="fit-content"
                  isIconAndLabel
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfographicCard
