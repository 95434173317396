import React, { useEffect } from 'react'
import {
  ContentCard,
  ListItemCard,
  Loader
} from 'theia-web-ds'
import { connect } from 'react-redux'
import { primary } from '../../color'
import { BabyData } from '../../domain/PediatricFlow'
import { eventPage, eventTrack } from '../../../eventGenerate'
import { PatientType } from '../../domain/ScheduleFlow'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import {
  selectChildForScheduleAction,
  setLoadingStepAction,
  setPatientTypeAction,
} from '../../state/scheduleFlow/actions'
import { TheiaError } from '../../domain/errors/TheiaError'
import { getChildrenDataAction } from '../../state/pediatricFlow/actions'
import './ChoosePatient.scss'
import { NEW_SCHEDULE_FLOW, PEDIATRIC_SCHEDULE } from '../../utils/EventCategories'

interface Props {
  selectedPatientType?: PatientType;
  babiesData?: BabyData[];
  isGettingChildrenData: boolean;
  failureGettingChildrenData: boolean;
  error?: TheiaError;
  loadingStep: boolean;
  previousSelectedChildId?: string;
  selectedChild?: BabyData;
  getChildrenData: () => void;
  selectChild: (child?: BabyData) => void;
  setPatientType: (patient?: PatientType) => void;
  setLoadingSteps: (loading: boolean) => void;
  onChooseOption: () => void;
}

function ChoosePatient({
  selectedPatientType,
  babiesData,
  isGettingChildrenData,
  failureGettingChildrenData,
  error,
  loadingStep,
  previousSelectedChildId,
  selectedChild,
  getChildrenData,
  selectChild,
  setPatientType,
  onChooseOption,
  setLoadingSteps,
} : Props) {
  const isChildSchedule = selectedPatientType === PatientType.IS_CHILD
  const errorMessage = error?.friendlyMessage || error?.friendlyMessageTitle
    ? `${error?.friendlyMessageTitle} ${error.friendlyMessage}`
    : 'Falha ao carregar os dados do(s) bebê(s).'

  function handleChoosePatient(patient?: BabyData) {
    selectChild(patient)
    onChooseOption()
  }

  useEffect(() => {
    if (!isGettingChildrenData) {
      getChildrenData()
    }
    eventTrack('visualizou escolha paciente', {
      categoria: NEW_SCHEDULE_FLOW
    })
  }, [])

  useEffect(() => {
    if (selectedPatientType && !isChildSchedule) {
      handleChoosePatient(undefined)
      return
    }
    if (selectedPatientType && isChildSchedule && babiesData && babiesData?.length === 1) {
      handleChoosePatient(babiesData[0])
      return
    }
    if (previousSelectedChildId && babiesData) {
      const childToSchedule = babiesData?.find(
        (child) => child.id === previousSelectedChildId
      )
      if (childToSchedule) {
        handleChoosePatient(childToSchedule)
        return
      }
    }
    if (selectedChild) {
      handleChoosePatient(selectedChild)
      return
    }
    setLoadingSteps(false)
    eventPage(PEDIATRIC_SCHEDULE, 'Seleção Paciente', {
      category: PEDIATRIC_SCHEDULE
    })
  }, [babiesData])

  if (loadingStep || isGettingChildrenData) {
    return (
      <div className="schedule-view-loader choose-patient-view">
        <Loader />
      </div>
    )
  }

  return (
    <div className="choose-patient-container">
      <h3 className="choose-patient-title">
        Para quem é o atendimento?
      </h3>
      <ListItemCard
        title="Para você"
        ariaLabel="Para você"
        iconType="icon-ProfileLight"
        iconColor={primary}
        iconSize="20px"
        extraClassNameContainer="w-full mb-4"
        hideIconButton
        onClick={() => {
          handleChoosePatient(undefined)
          setPatientType(PatientType.IS_USER)
          eventTrack('escolheu paciente', {
            categoria: NEW_SCHEDULE_FLOW,
            paciente: 'mãe'
          })
        }}
        isLastItem
      />
      {failureGettingChildrenData && error && (
        <ContentCard
          headerTitle="Bebê"
          disabled
          ariaLabel="Bebê"
          iconBefore={{
            icon: 'icon-BabyLight',
            size: '20px'
          }}
          type="elevated"
          firstButtonProps={{
            label: 'Tentar novamente',
            onClick: getChildrenData
          }}
          errorMessage={errorMessage}
        />
      )}
      {!failureGettingChildrenData
      && babiesData
      && babiesData?.length > 0
      && babiesData?.map((child: BabyData) => (
        <ListItemCard
          title={`Para ${child.name}`}
          ariaLabel={`Para ${child.name}`}
          iconType="icon-BabyLight"
          iconColor={primary}
          iconSize="20px"
          extraClassNameContainer="w-full mb-4"
          hideIconButton
          onClick={() => {
            handleChoosePatient(child)
            setPatientType(PatientType.IS_CHILD)
            eventTrack('escolheu paciente', {
              categoria: NEW_SCHEDULE_FLOW,
              paciente: 'criança'
            })
          }}
          isLastItem
          key={child.id}
        />
      ))}
    </div>
  )
}

const mapStateToProps = ({
  pediatricFlow,
  scheduleFlow,
}: AppState) => ({
  selectedPatientType: scheduleFlow.selectedPatientType,
  babiesData: pediatricFlow.childrenData,
  isGettingChildrenData: pediatricFlow.isGettingChildrenData,
  failureGettingChildrenData: pediatricFlow.failureGettingChildrenData,
  error: pediatricFlow.error,
  loadingStep: scheduleFlow.loadingStep,
  previousSelectedChildId: scheduleFlow.previousSelectedChildId,
  selectedChild: scheduleFlow.selectedChild,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getChildrenData: () => {
    dispatch(getChildrenDataAction())
  },
  selectChild: (child?: BabyData) => {
    dispatch(selectChildForScheduleAction(child))
  },
  setPatientType: (patient?: PatientType) => {
    dispatch(setPatientTypeAction(patient))
  },
  setLoadingSteps: (isLoading: boolean) => {
    dispatch(setLoadingStepAction(isLoading))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChoosePatient)
