/* eslint-disable max-len */
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/browser'
import { applyMiddleware, legacy_createStore as createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import createSentryMiddleware from 'redux-sentry-middleware'
import { UserProvider } from '../../domain/User'
import { PrescriptionsService } from '../../domain/Prescriptions'
import { ChatServiceFactory } from '../../domain/Chat'
import { ChatVideoServiceFactory } from '../../domain/ChatVideo'
import { CallServiceFactory } from '../../domain/Call'
import { BookingService, BookingViewService } from '../../domain/Booking'
import { ProfessionalProvider } from '../../domain/Professional'
import { ProfileProvider } from '../../domain/Profile'
import { ConsultantService } from '../../domain/Consultant'
import { chat } from '../../state/chat/reducers'
import { chatVideo } from '../../state/chatVideo/reducers'
import { onlineSpecialist } from '../../state/specialistPresence/reducers'
import { reschedule } from '../../state/reschedule/reducers'
import { RescheduleService } from '../../domain/Reschedule'
import { professionals } from '../../state/professionals/reducers'
import { booking, browserError, promptPermission } from '../../state/booking/reducers'
import { defaultError } from '../../state/defaultError/reducers'
import { errorWithFriendlyMessage } from '../../state/errorWithFriendlyMessage/reducers'
import { profile } from '../../state/profile/reducers'
import { consultant } from '../../state/consultant/reducers'
import { SpecialistProvider } from '../../domain/Specialist'
import { specialists } from '../../state/specialists/reducers'
import { feedback } from '../../state/feedback/reducers'
import { scheduleCancellation } from '../../state/scheduleCancellation/reducers'
import { authentication } from '../../state/authentication/main/reducers'
import { HttpClient } from '../../services/Http'
import { content } from '../../state/content/reducers'
import { weekFlow } from '../../state/weekFlow/reducers'
import { suggestionFlow } from '../../state/suggestionsFlow/reducers'
import { myBookingsFlow } from '../../state/myBookingsFlow/reducers'
import { platform } from '../../state/platform/reducers'
import { PlatformProvider } from '../../domain/Platform'
import { bookingsInfo } from '../../state/bookingsInfo/reducers'
import { payment } from '../../state/payment/reducers'
import { scheduleConfirmationSMS } from '../../state/scheduleConfirmationSMS/reducers'
import { WeekSuggestionsService } from '../../domain/WeekFlow'
import { drawer } from '../../state/drawer/reducers'
import { addressInfo } from '../../state/addressInfo/reducers'
import { notifications } from '../../state/notifications/reducers'
import { scheduleSuggestion } from '../../state/scheduleSuggestion/reducers'
import { OnboardingService } from '../../domain/Onboarding'
import { onboarding } from '../../state/onboarding/reducers'
import { healthplan } from '../../state/healthplanProfile/reducers'
import { prescriptions } from '../../state/prescriptions/reducers'
import { HealthplanService } from '../../domain/Healthplan'
import { GuidedJourneyService } from '../../domain/GuidedJourney'
import { guidedJourney } from '../../state/guidedJourney/reducers'
import { recaptcha } from '../../state/authentication/recaptcha/reducers'
import { PediatricFlowService } from '../../domain/PediatricFlow'
import { pediatricFlow } from '../../state/pediatricFlow/reducers'
import { scheduleFlow } from '../../state/scheduleFlow/reducers'
import { ScheduleFlowService } from '../../domain/ScheduleFlow'
import { availabilities } from '../../state/availabilities/reducers'
import { AvailabilitiesService } from '../../domain/Availabilities'
import { isProductionEnv } from '../../utils/helpers'
import { routerListener } from '../../state/routerListener/reducers'
import { terms } from '../../state/terms/reducers'
import { TermsProvider } from '../../domain/Terms'

export const browserHistory = createBrowserHistory()

const rootReducer = combineReducers({
  router: connectRouter(browserHistory),
  authentication,
  addressInfo,
  booking,
  bookingsInfo,
  browserError,
  chat,
  chatVideo,
  consultant,
  content,
  defaultError,
  drawer,
  errorWithFriendlyMessage,
  feedback,
  guidedJourney,
  healthplan,
  myBookingsFlow,
  notifications,
  onboarding,
  onlineSpecialist,
  payment,
  pediatricFlow,
  platform,
  prescriptions,
  professionals,
  profile,
  promptPermission,
  reschedule,
  recaptcha,
  routerListener,
  specialists,
  scheduleCancellation,
  scheduleConfirmationSMS,
  scheduleFlow,
  availabilities,
  scheduleSuggestion,
  suggestionFlow,
  terms,
  weekFlow,
})

export interface AppDependencies {
  httpClient: HttpClient;
  userProvider: UserProvider;
  chatServiceFactory: ChatServiceFactory;
  callServiceFactory: CallServiceFactory;
  bookingService: BookingService;
  bookingViewService: BookingViewService;
  professionalProvider: ProfessionalProvider;
  profileProvider: ProfileProvider;
  consultantService: ConsultantService;
  specialistProvider: SpecialistProvider;
  platformProvider: PlatformProvider;
  chatVideoServiceFactory: ChatVideoServiceFactory;
  weekSuggestionsService: WeekSuggestionsService;
  onboardingService: OnboardingService;
  healthplanService: HealthplanService;
  prescriptionsService: PrescriptionsService;
  guidedJourneyService: GuidedJourneyService;
  pediatricFlowService: PediatricFlowService;
  scheduleFlowService: ScheduleFlowService;
  availabilitiesService: AvailabilitiesService;
  termsProvider: TermsProvider;
  rescheduleService: RescheduleService;
}

export default function initStore(args: AppDependencies) {
  const thunkMiddleware = thunk.withExtraArgument(args)
  let middlewares = applyMiddleware(thunkMiddleware)
  /** geralmente usada para ocultar informações confidenciais */
  const stateTransformer = (state: any) => ({
    ...state,
    addressInfo: '******',
    authentication: {
      ...state.authentication,
      currentUser: {
        ...state.authentication.currentUser,
        document: '******',
        birthDate: '******',
        email: '******',
        name: '******',
        phone: '******',
        pictureUrl: '******',
        address: '******',
        plan: null,
      }
    },
    myBookingsFlow: {
      ...state.myBookingsFlow,
      bookingHistory: '******',
    },
    profile: {
      ...state.profile,
      allBookings: '******',
      bookingsByMonth: '******',
    },
    booking: {
      ...state.booking,
      remoteUserName: '******',
      consultantName: '******',
      chatToken: '******',
    },
    chatVideo: {
      ...state.chatVideo,
      messages: '******',
      chatToken: '******',
    },
    scheduleConfirmationSMS: {
      ...state.scheduleConfirmationSMS,
      token: '******',
    }
  })
  if (isProductionEnv()) {
    middlewares = applyMiddleware(
      createSentryMiddleware(Sentry, {
        stateTransformer
      }),
      thunkMiddleware
    )
  }
  return createStore(
    rootReducer,
    composeWithDevTools(middlewares),
  )
}

export type AppState = ReturnType<typeof rootReducer>
