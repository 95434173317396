import React, { useEffect } from 'react'
import FocusLock from 'react-focus-lock'

import './ButtonOptions.scss'

interface OptionsModalProps {
  children: React.ReactNode;
  headerText: string;
  onClose: (event: any) => void;
  visible: boolean;
  lastIdFocused?: string;
  isFromCrop?: boolean;
  extraClass?: string;
  openOverflowStyle?: string;
}

function OptionsModal({
  headerText,
  children,
  onClose,
  visible,
  lastIdFocused,
  isFromCrop,
  extraClass,
  openOverflowStyle
}: OptionsModalProps) {
  function onCloseAndRefocused(event: any) {
    if (lastIdFocused !== undefined && lastIdFocused !== '') {
      (document.getElementById(lastIdFocused) as any)?.focus()
    }
    onClose(event)
  }

  function onKeyDown(event: KeyboardEvent) {
    if (event?.key === 'Escape' && visible) {
      event.stopPropagation()
      event.preventDefault()
      onCloseAndRefocused(event)
    }
  }

  useEffect(() => {
    if (visible) {
      if (openOverflowStyle) {
        document.body.style.overflow = openOverflowStyle
      }
      document.addEventListener('keydown', onKeyDown, false)
    } else {
      document.body.style.overflow = 'unset'
    }
    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [visible, openOverflowStyle])

  return visible ? (
    <FocusLock>
      <div
        className={`${!isFromCrop ? 'background-opacity w-full flex items-center justify-center p-4' : 'background-opacity-crop-open'}`}
        onClick={onCloseAndRefocused}
        aria-hidden="true"
      />
      <div
        role="dialog"
        className={`${!isFromCrop ? 'options-modal' : 'options-modal-crop-open'} ${visible && 'active'} ${extraClass}`}
        aria-modal
        aria-labelledby={headerText}
      >
        <div className="options-modal-inner">
          <div className="divider-top" />
          {children}
        </div>
      </div>
    </FocusLock>
  ) : null
}

export interface Props {
  buttonToAnchor: React.ReactNode;
  children: React.ReactNode;
  visible: boolean;
  onClose: (event: any) => void;
  headerText: string;
  lastIdFocused?: string;
  isFromCrop?: boolean;
  extraClass?: string;
  openOverflowStyle?: string;
}

export function ButtonOptions({
  children,
  buttonToAnchor,
  visible,
  onClose,
  headerText,
  lastIdFocused,
  isFromCrop = false,
  extraClass,
  openOverflowStyle
}: Props) {
  return (
    <div className="anchor-container">
      {buttonToAnchor}
      <OptionsModal
        visible={visible}
        onClose={onClose}
        headerText={headerText}
        lastIdFocused={lastIdFocused}
        isFromCrop={isFromCrop}
        extraClass={extraClass}
        openOverflowStyle={openOverflowStyle}
      >
        {children}
      </OptionsModal>
    </div>
  )
}
