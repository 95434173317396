/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import './NavigationCard.scss'
import { textPrimary } from '../../colors'
import { BadgeNotification } from '../BadgeNotification/BadgeNotification'
import { FontIcon } from '../FontIcon/FontIcon'

export interface Props {
  cardLabel: string;
  onClick: () => void;
  cardIcon?: string;
  iconSize?: string;
  iconColor?: string;
  extraClass?: string;
  hasBadge?: boolean;
  badgeData?: string | number;
}

export function NavigationCard({
  cardLabel,
  onClick,
  cardIcon = 'icon-BookmarkLight',
  iconSize = '24px',
  iconColor = textPrimary,
  extraClass,
  hasBadge,
  badgeData
}: Props) {
  return (
    <div className={`navigation-card__outer ${extraClass}`} onClick={onClick} role="button" tabIndex={0}>
      <div className="navigation-card__inner">
        <div className={`navigation-card__icon ${hasBadge ? 'relative' : ''}`}>
          {hasBadge ? (
            <BadgeNotification
              extraClass="absolute navigation-card__icon-badge"
              width={16}
              height={16}
            >
              {badgeData}
            </BadgeNotification>
          ) : null}
          <FontIcon
            iconType={cardIcon}
            fontSize={iconSize}
            color={iconColor}
          />
        </div>
        <p className="navigation-card__label">{cardLabel}</p>
      </div>
    </div>
  )
}
