import React from 'react'
import { BadgeNotification, FontIcon } from 'theia-web-ds'
import { error } from '../../../color'
import { getKeyValue } from '../../../utils/helpers'
import './NavItem.scss'

type MenuItemProps = {
  text: string;
  isActive: boolean;
  handleClick: () => void;
  variant?: any;
  iconType?: string;
  className?: string;
  activeIconType?: string;
  hasNotification?: boolean;
  hasNotificationOnIcon?: boolean;
  disabled?: boolean;
}

const mapVariant = {
  sidebar: 'sidebar',
  bottombar: 'bottombar',
  tab: 'tab'
}

const NavItem = React.memo(({
  text,
  isActive,
  handleClick,
  variant = 'sidebar',
  iconType,
  className,
  activeIconType,
  hasNotification,
  hasNotificationOnIcon = false,
  disabled = false
}: MenuItemProps) => {
  const variantValue = getKeyValue(mapVariant, variant)
  return (
    <li key={text} className={className}>
      <button
        disabled={disabled}
        role="menuitem"
        id={text}
        onClick={handleClick}
        type="button"
        aria-label={text}
        className={`menu-item ${variantValue} ${isActive ? `text-primary ${variantValue ? 'nav-item-active' : ''}` : 'text-textPrimary'} focus:outline-none items-center`}
      >
        {(!hasNotification && !hasNotificationOnIcon) && <FontIcon iconType={isActive ? activeIconType : iconType} fontSize="24px" />}
        {(!hasNotification && hasNotificationOnIcon) && (
          <div className="relative">
            <FontIcon iconType={isActive ? activeIconType : iconType} fontSize="24px" />
            <BadgeNotification
              color={error}
              width={8}
              height={8}
              borderRadius="50%"
              extraClass="badge-outline absolute right-0"
            />
          </div>
        )}
        <p className="nav-item-text">{text}
          {(hasNotification && !hasNotificationOnIcon) && (
          <BadgeNotification
            color={error}
            width={8}
            height={8}
            borderRadius="50%"
            extraClass="badge-outline"
          />
          )}
        </p>
      </button>
    </li>
  )
})

export default NavItem
