import * as Sentry from '@sentry/browser'

import {
  Message,
  ChatVideoService
} from '../../domain/ChatVideo'
import { AsyncAppAction } from '../utils'

export const SUCCESS_CONNECT_CHAT_VIDEO = 'SUCCESS_CONNECT_CHAT_VIDEO'
export const FAILURE_CONNECT_CHAT_VIDEO = 'FAILURE_CONNECT_CHAT_VIDEO'
export const REQUEST_CONNECT_CHAT_VIDEO = 'REQUEST_CONNECT_CHAT_VIDEO'
export const SEND_MESSAGE_CHAT_VIDEO = 'SEND_MESSAGE_CHAT_VIDEO'
export const ADD_NEW_MESSAGE_CHAT_VIDEO = 'ADD_NEW_MESSAGE_CHAT_VIDEO'
export const FETCH_MESSAGES_CHAT_VIDEO = 'FETCH_MESSAGES_CHAT_VIDEO'
export const SUCCESS_FETCH_MESSAGES_CHAT_VIDEO = 'SUCCESS_FETCH_MESSAGES_CHAT_VIDEO'
export const FAILURE_FETCH_MESSAGES_CHAT_VIDEO = 'FAILURE_FETCH_MESSAGES_CHAT_VIDEO'
export const SHOW_CHAT_VIDEO = 'SHOW_CHAT_VIDEO'
export const RETRY_CONNECT_CHAT_VIDEO_SHOW = 'RETRY_CONNECT_CHAT_VIDEO_SHOW'
export const RETRY_CONNECT_CHAT_VIDEO_CLICKED = 'RETRY_CONNECT_CHAT_VIDEO_CLICKED'
export const FORCE_CONNECT_CHAT_VIDEO = 'FORCE_CONNECT_CHAT_VIDEO'
export const RESET_UNREAD_MESSAGES_COUNT_CHAT_VIDEO = 'RESET_UNREAD_MESSAGES_COUNT_CHAT_VIDEO'
export const UNREAD_MESSAGES_COUNT = 'UNREAD_MESSAGES_COUNT'
export const CHAT_VIDEO_SHUTDOWN = 'CHAT_VIDEO_SHUTDOWN'
export const UPDATE_TOKEN_CHAT_VIDEO = 'UPDATE_TOKEN_CHAT_VIDEO'
export const FAILURE_UPDATE_TOKEN_CHAT_VIDEO = 'FAILURE_UPDATE_TOKEN_CHAT_VIDEO'
export const SUCCESS_RETRY_UPDATE_TOKEN_CHAT_VIDEO = 'SUCCESS_RETRY_UPDATE_TOKEN_CHAT_VIDEO'
export const RETRY_UPDATE_TOKEN_CHAT_VIDEO = 'RETRY_UPDATE_TOKEN_CHAT_VIDEO'
export const FAILURE_RETRY_UPDATE_TOKEN_CHAT_VIDEO = 'FAILURE_RETRY_UPDATE_TOKEN_CHAT_VIDEO'

interface SuccessfulConnectChatVideoActionType {
  type: typeof SUCCESS_CONNECT_CHAT_VIDEO;
  chatVideoService: ChatVideoService;
  chatToken: string;
}

interface FailureConnectChatVideoActionType {
  type: typeof FAILURE_CONNECT_CHAT_VIDEO;
}

interface RequestConnectChatVideoActionType {
  type: typeof REQUEST_CONNECT_CHAT_VIDEO;
}

interface SendMessageActionType {
  type: typeof SEND_MESSAGE_CHAT_VIDEO;
}

export interface AddNewMessageActionType {
  type: typeof ADD_NEW_MESSAGE_CHAT_VIDEO;
  message: Message;
}

interface FetchMessagesActionType {
  type: typeof FETCH_MESSAGES_CHAT_VIDEO;
}

interface SuccessFetchMessagesActionType {
  type: typeof SUCCESS_FETCH_MESSAGES_CHAT_VIDEO;
  messages: Array<Message>;
}

interface FailureFetchMessagesActionType {
  type: typeof FAILURE_FETCH_MESSAGES_CHAT_VIDEO;
}

interface ShowChatActionType {
  type: typeof SHOW_CHAT_VIDEO;
}

interface UpdateTokenChatVideoActionType {
  type: typeof UPDATE_TOKEN_CHAT_VIDEO;
  chatToken: string;
}

interface ForceConnectChatVideoActionType {
  type: typeof FORCE_CONNECT_CHAT_VIDEO;
}

export interface ResetUnreadMessagesCountChatVideoActionType {
  type: typeof RESET_UNREAD_MESSAGES_COUNT_CHAT_VIDEO;
}

export interface UnreadMessagesCountChatVideoActionType {
  type: typeof UNREAD_MESSAGES_COUNT;
  unreadMessagesCount: number;
}

export interface ChatVideoShutdownActionType {
  type: typeof CHAT_VIDEO_SHUTDOWN;
}

interface RetryUpdatedTokenChatVideoActionType {
  type: typeof RETRY_UPDATE_TOKEN_CHAT_VIDEO;
}

interface SuccessRetryUpdatedTokenChatVideoActionType {
  type: typeof SUCCESS_RETRY_UPDATE_TOKEN_CHAT_VIDEO;
}

interface FailureRetryUpdatedTokenChatVideoActionType {
  type: typeof FAILURE_UPDATE_TOKEN_CHAT_VIDEO;
}

export type ChatVideoTypes =
  SendMessageActionType |
  AddNewMessageActionType |
  SuccessfulConnectChatVideoActionType |
  FailureConnectChatVideoActionType |
  RequestConnectChatVideoActionType |
  ForceConnectChatVideoActionType |
  FetchMessagesActionType|
  SuccessFetchMessagesActionType |
  FailureFetchMessagesActionType |
  ResetUnreadMessagesCountChatVideoActionType |
  UnreadMessagesCountChatVideoActionType |
  ChatVideoShutdownActionType |
  UpdateTokenChatVideoActionType |
  RetryUpdatedTokenChatVideoActionType |
  SuccessRetryUpdatedTokenChatVideoActionType |
  FailureRetryUpdatedTokenChatVideoActionType

export const forceConnectChatVideoAction = (): ForceConnectChatVideoActionType => ({
  type: FORCE_CONNECT_CHAT_VIDEO
})

export function fetchOldMessagesAction(anchor?: number): AsyncAppAction {
  return async (dispatch, getState) => {
    const { chatVideo } = getState()
    const { chatVideoService } = chatVideo
    if (!chatVideoService) {
      Sentry.captureException(new Error('Failure fetching the old messages on chat video - without chatVideoService'))
      return
    }
    if (anchor && anchor < 0) return
    if (chatVideoService.needForceReconnect() && !chatVideo.connectingChat) {
      await dispatch(forceConnectChatVideoAction())
      return
    }
    dispatch({ type: FETCH_MESSAGES_CHAT_VIDEO })
    try {
      const messages = await chatVideoService.getMessages(anchor)
      dispatch({ type: SUCCESS_FETCH_MESSAGES_CHAT_VIDEO, messages })
    } catch (error) {
      dispatch({ type: FAILURE_FETCH_MESSAGES_CHAT_VIDEO })
      Sentry.captureException(new Error(`Failure fetching the old messages on chat video - ${error.message}`))
    }
  }
}

export function sendMessageAction(text: string): AsyncAppAction {
  return async (dispatch, getState) => {
    const { chatVideo } = getState()
    const { chatVideoService } = chatVideo

    if (!chatVideoService) {
      Sentry.captureException(new Error('Failure sending message in chat video - without chatVideoService'))
      return
    }
    if (chatVideoService.needForceReconnect() && !chatVideo.connectingChat) {
      await dispatch(forceConnectChatVideoAction())
      return
    }

    try {
      chatVideoService.sendMessage(text)
      dispatch({ type: SEND_MESSAGE_CHAT_VIDEO })
    } catch (error) {
      Sentry.captureException(new Error(`Failure sending message in chat video - ${error.message}`))
    }
  }
}

export function receiveMessageAction(message: Message): AsyncAppAction {
  return async (dispatch) => {
    dispatch({ type: ADD_NEW_MESSAGE_CHAT_VIDEO, message })
  }
}

export function connectChatVideoAction(chatToken: string, id: string):
AsyncAppAction {
  return async (dispatch, _getState, { chatVideoServiceFactory }) => {
    dispatch({ type: REQUEST_CONNECT_CHAT_VIDEO })
    if (!chatToken) return
    try {
      const chatVideoService = await chatVideoServiceFactory.build(
        chatToken,
        id
      )
      dispatch({ type: SUCCESS_CONNECT_CHAT_VIDEO, chatVideoService, chatToken })
      const unreadMessagesCount = await chatVideoService.getUnreadMessages()
      dispatch({ type: UNREAD_MESSAGES_COUNT, unreadMessagesCount })
      dispatch(fetchOldMessagesAction())
      chatVideoService.setOnMessageReceived((message) => dispatch(receiveMessageAction(message)))
    } catch (error) {
      dispatch({ type: FAILURE_CONNECT_CHAT_VIDEO })
      Sentry.captureException(new Error(`Failure connecting to chat video - ${error.message}`))
    }
  }
}

export function messageTypingAction(): AsyncAppAction {
  return async (dispatch, getState) => {
    const { chatVideo } = getState()
    if (chatVideo.chatVideoService) {
      if (chatVideo.chatVideoService.needForceReconnect() && !chatVideo.connectingChat) {
        await dispatch(forceConnectChatVideoAction())
        return
      }
      try {
        chatVideo.chatVideoService.typing()
      } catch (error) {
        Sentry.captureException(new Error(`Failure with typing status in chat video - ${error.message}`))
      }
    }
  }
}

export function logConnectChatVideoErrorAction(): AsyncAppAction {
  return async (dispatch) => {
    dispatch({ type: RETRY_CONNECT_CHAT_VIDEO_SHOW })
    Sentry.captureException(new Error('Failure connect chat video - Retry button was shown'))
  }
}

export function updateTokenChatVideoAction(chatToken: string): AsyncAppAction {
  return async (dispatch) => {
    dispatch({ type: UPDATE_TOKEN_CHAT_VIDEO, chatToken })
  }
}

export function setUpdateTokenChatVideoAction(uuid: string, isFromRetry?: boolean): AsyncAppAction {
  return async (dispatch, _getState, { bookingViewService }) => {
    if (isFromRetry) dispatch({ type: RETRY_UPDATE_TOKEN_CHAT_VIDEO })
    try {
      const booking = await bookingViewService?.getBookingView(uuid)
      if (booking) {
        dispatch(connectChatVideoAction(booking.chatToken, booking.id))
        dispatch(updateTokenChatVideoAction(booking.chatToken))
        if (isFromRetry) dispatch({ type: SUCCESS_RETRY_UPDATE_TOKEN_CHAT_VIDEO })
      }
    } catch (error) {
      dispatch({ type: FAILURE_UPDATE_TOKEN_CHAT_VIDEO })
      Sentry.captureException(new Error('Failure update token chat video'))
    }
  }
}

export const onShowChatAction = (): ShowChatActionType => ({
  type: SHOW_CHAT_VIDEO
})

export const unreadMessagesCountChatVideoAction = (unreadMessagesCount: number):
UnreadMessagesCountChatVideoActionType => ({
  type: UNREAD_MESSAGES_COUNT,
  unreadMessagesCount
})

export const resetUnreadMessagesCountChatVideoAction = ():
ResetUnreadMessagesCountChatVideoActionType => ({
  type: RESET_UNREAD_MESSAGES_COUNT_CHAT_VIDEO
})

export function chatVideoShutdownAction(): AsyncAppAction {
  return async (dispatch, _getState) => {
    const { chatVideo } = _getState()
    try {
      await chatVideo.chatVideoService?.shutdown()
      dispatch({ type: CHAT_VIDEO_SHUTDOWN })
    } catch (error) {
      Sentry.captureException(new Error(`Failure with chat video shutdown - ${error.message}`))
    }
  }
}
