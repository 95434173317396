import {
  RecaptchaTypes,
  POST_RECAPTCHA_GOOGLE,
  SUCCESS_POST_RECAPTCHA_GOOGLE,
  FAILURE_POST_RECAPTCHA_GOOGLE
} from './actions'

export interface RecaptchaSate {
  isSuccess: boolean;
  isFailure: boolean;
}

const initialRecaptchaSate: RecaptchaSate = {
  isSuccess: false,
  isFailure: false
}

export function recaptcha(state = initialRecaptchaSate, action: RecaptchaTypes): RecaptchaSate {
  switch (action.type) {
    case POST_RECAPTCHA_GOOGLE: {
      return {
        ...state,
        isSuccess: false,
        isFailure: false
      }
    }
    case SUCCESS_POST_RECAPTCHA_GOOGLE: {
      return {
        ...state,
        isSuccess: true,
        isFailure: false
      }
    }
    case FAILURE_POST_RECAPTCHA_GOOGLE: {
      return {
        ...state,
        isSuccess: false,
        isFailure: true
      }
    }
    default:
      return state;
  }
}
