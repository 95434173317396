import { ErrorFriendlyMessage } from './ApiResponseErrors'

export class LoginFriendlyError extends Error {
  constructor(
    error: string,
    public statusCode?: number,
    public blocked?: boolean,
    public recaptcha?: boolean,
    public friendlyMessage?: ErrorFriendlyMessage
  ) {
    super(error)
    Object.setPrototypeOf(this, LoginFriendlyError.prototype)
    this.statusCode = statusCode
    this.blocked = blocked
    this.recaptcha = recaptcha
    this.friendlyMessage = friendlyMessage
  }
}
